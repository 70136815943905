import { SALES_ADMIN_ROLES } from '../restrictions.consts';
import { DiscountName } from './discounts.types';

// main

type IRestrictionsFull = {
  lock: Record<DiscountName, boolean>;
  max: Record<DiscountName, number>;
  limit: Record<DiscountName, number>;
};
export type IRestrictions = Partial<IRestrictionsFull>;
export type IRestrictionsSingle = {
  [K in keyof IRestrictionsFull]: IRestrictionsFull[K][keyof IRestrictionsFull[K]];
};

export type RestrictionName = keyof IRestrictionsFull;
export type IRestrictionsRecord = NonNullable<IRestrictions[RestrictionName]>;
export type RestrictionValue = IRestrictionsRecord[keyof IRestrictionsRecord];

// defaults

export const restrictionDefaults = {
  lock: false,
  max: 1,
  limit: 0,
};
/**
 * used in merging
 * - ⚠️ Warning: must be in this specific order otherwise values won't be accurate
 */
export const restrictionKeysInOrder: RestrictionName[] = [
  'lock',
  'max',
  'limit',
];

type RestrictionDefaults = typeof restrictionDefaults;
export type RestrictionValueDefault =
  RestrictionDefaults[keyof RestrictionDefaults];

export type RestrictorRole = (typeof SALES_ADMIN_ROLES)[number];
