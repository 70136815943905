import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, SxProps, IconButtonProps } from '@mui/material';
import { Icon } from '../Icon';
import { CaretDown, IconProps } from '@phosphor-icons/react';
import If from '../If';
import styled from 'styled-components';

interface ActionIconButtonProps {
  onClick: (e?: any) => void;
  title: string;
  icon: JSX.Element;
  iconProps?: IconProps;
  disabled?: boolean;
  buttonSx?: SxProps;
  withArrow?: boolean;
  buttonProps?: IconButtonProps;
}
export const ActionIconButton = ({
  onClick,
  title,
  icon,
  disabled,
  iconProps,
  buttonSx,
  withArrow,
}: ActionIconButtonProps) => {
  const { t } = useTranslation();

  return (
    <ManagedTooltip title={t(title)}>
      <Stack direction="row" gap="2px" alignItems="center" position="relative">
        <IconButton
          disabled={disabled}
          onClick={onClick}
          sx={{
            height: '32px',
            marginRight: withArrow ? '4px' : 0,
            ...buttonSx,
          }}
        >
          <Icon icon={icon} {...iconProps} />
        </IconButton>

        <If condition={!!withArrow}>
          <ArrowIconWrapper>
            <Icon icon={<CaretDown />} weight="fill" size="12px" />
          </ArrowIconWrapper>
        </If>
      </Stack>
    </ManagedTooltip>
  );
};

const ArrowIconWrapper = styled.div`
  position: absolute;
  right: 0;
`;
