import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

export const ProductsDetailsPage = lazyLoad(
  () => import('./ProductsDetailsInfo'),
  (module) => module.ProductsDetailsInfo,
  {
    fallback: <LoadingIndicator />,
  },
);
