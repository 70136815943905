import { TwoViewModal } from 'app/components/TwoViewModal';
import { Form } from 'app/components/Form/styles';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { Grid, Stack } from '@mui/material';
import { FormWrapper } from 'app/components/Form/styles';
import FormTextField from 'app/components/Form/TextField';
import { Button } from '@mui/material';
import { FormActions } from 'app/components/Form/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { useForm } from 'react-hook-form';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';

interface props {
  open: boolean;
  onClose: () => void;
  title: string;
  onActionCall: (val: number) => void;
  defaultVal: number;
}

export const MinMarginModal = ({
  open,
  onClose,
  title,
  onActionCall,
  defaultVal,
}: props) => {
  const { t } = useTranslation();
  const { control, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      min_margin: defaultVal,
    },
  });
  const minMargin = watch('min_margin');
  const MAX_VALUE = 100;
  const { callApi } = useAsyncDataV2();

  const onActionClick = async () => {
    const minMarginVal: number = +minMargin / 100;
    await callApi(
      async () => {
        await onActionCall(minMarginVal);
      },
      {
        successMessage: t('min_margin_update_success', { x: minMargin }),
        onClose: () => {},
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <TwoViewModal
      title={t(title)}
      onOpen={open}
      onClose={onClose}
      defaultMode="floated"
      autoHeight
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Alert
          sx={{
            width: '90%',
            margin: 'auto',
            marginTop: '20px',
            '&.MuiPaper-root .MuiAlert-icon': { paddingTop: '4px' },
            '&.MuiPaper-root  .MuiAlert-message': {
              marginLeft: '-8px',
              fontWeight: '500',
            },
          }}
          severity="info"
        >
          {t('productDisconts.marign_alert')}
        </Alert>
        <FormWrapper>
          <Stack spacing="20px">
            <Grid container>
              <Grid item xs={12}>
                <FormTextField
                  name="min_margin"
                  rules={{ required: true, min: 0, max: MAX_VALUE }}
                  inputProps={{
                    inputProps: { min: 0, max: MAX_VALUE },
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  control={control}
                  errors={
                    minMargin > MAX_VALUE
                      ? t('productDiscounts.min_margin') +
                        ' ' +
                        t('max_percentage_error') +
                        MAX_VALUE
                      : ''
                  }
                  num={true}
                  type="number"
                  label={t('productDiscounts.min_margin')}
                />
              </Grid>
            </Grid>
          </Stack>
        </FormWrapper>
        <FormActions>
          <Button onClick={() => {}} color="inherit">
            {t('common.buttons.cancel')}
          </Button>
          <Button
            onClick={onActionClick}
            disabled={minMargin > MAX_VALUE}
            variant="contained"
            autoFocus
          >
            {t('save')}
          </Button>
        </FormActions>
      </Form>
    </TwoViewModal>
  );
};
