import { useModal } from 'app/components/Dialog/hooks';
import { GridActionMenu } from 'app/components/Grid/components/GridActionMenu';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { themes } from 'styles/theme/themes';
import { useHelpers } from '../../../useHelpers';
import { useNavLock } from 'hooks/useNavLock';

export const ContractActionsCell = (params) => {
  const { disabled, _id } = params.value;

  const { openModal, closeModal } = useModal();
  const { handleContractToggle, handleDeleteContract } = useHelpers();
  const { navLocked } = useNavLock();

  const handleConfirmToggleClick = useCallback(() => {
    closeModal();
    handleContractToggle({ disabled: !disabled, selectedRows: [_id] });
  }, [_id, closeModal, disabled, handleContractToggle]);

  const handleDisableClick = useCallback(() => {
    openModal({
      title: t('title.action_discount', { action: t('buttons.disable') }),
      content: (
        <>
          {t('description.action_discount', {
            action: t('buttons.disable').toLowerCase(),
          })}
        </>
      ),
      action: {
        actionText: t('buttons.disable'),
        actionCallback: () => handleConfirmToggleClick(),
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      hasCloseIcon: true,
      cancel: true,
    });
  }, [handleConfirmToggleClick, openModal]);

  const handleEnableClick = useCallback(() => {
    openModal({
      title: t('title.action_discount', { action: t('buttons.enable') }),
      content: (
        <>
          {t('description.action_discount', {
            action: t('buttons.enable').toLowerCase(),
          })}
        </>
      ),
      action: {
        actionText: t('buttons.enable'),
        actionCallback: () => handleConfirmToggleClick(),
        buttonProps: {
          sx: { background: themes?.default?.primary },
        },
      },
      hasCloseIcon: true,
      cancel: true,
    });
  }, [handleConfirmToggleClick, openModal]);

  const handleDeleteConfirm = useCallback(
    (selectedRows: string[]) => {
      closeModal();
      handleDeleteContract(selectedRows);
    },
    [closeModal, handleDeleteContract],
  );

  const handleDeleteClick = useCallback(() => {
    openModal({
      title: t('title.action_discount', { action: t('common.remove') }),
      content: <>{t('message.remove_discount')}</>,
      action: {
        actionText: t('common.remove'),
        actionCallback: () => handleDeleteConfirm([_id]),
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      hasCloseIcon: true,
      cancel: true,
    });
  }, [_id, handleDeleteConfirm, openModal]);

  const options = useMemo(() => {
    const allowedOptions: {
      label: string;
      action: () => void;
      isDelete?: boolean;
    }[] = [];

    if (disabled) {
      allowedOptions.push({
        label: t('common.enable'),
        action: () => handleEnableClick(),
      });
    } else {
      allowedOptions.push({
        label: t('common.disable'),
        action: () => handleDisableClick(),
      });
    }

    allowedOptions.push({
      label: t('common.remove'),
      isDelete: true,
      action: () => handleDeleteClick(),
    });

    return allowedOptions;
  }, [disabled, handleDeleteClick, handleDisableClick, handleEnableClick]);

  return <GridActionMenu options={options} disabled={navLocked} />;
};
