import { Chip, ChipProps, MenuItem, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import If from '../If';

interface Props {
  list?: string[];
  chipProps?: ChipProps;
  more?: string;
}

export const MorePopup = ({ list, chipProps, more = 'more' }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  return (
    <If condition={!!list?.length}>
      <Chip
        label={`+${list?.length} ${more ? t(more) : ''}`}
        onClick={(e) => setAnchorEl(e?.currentTarget)}
        {...chipProps}
        sx={{
          ...chipProps?.sx,
          height: 'auto',
          textTransform: 'lowercase',
        }}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        BackdropProps={{
          style: { backgroundColor: 'transparent' },
        }}
      >
        {list?.map((listItem, index) => {
          return (
            <MenuItem key={index}>
              <Typography fontSize="0.8125rem">{listItem}</Typography>
            </MenuItem>
          );
        })}
      </Popover>
    </If>
  );
};
