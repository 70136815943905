import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AppState,
  Application,
  NavMenuPreferences,
  ToastSeverity,
} from './types';
import { Position } from '../../types/Position';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { NavigateOptions } from 'react-router-dom';
import authApi from '../../services/authApi';
import { StatusToastVariant } from '../../types/StatusToasterNotification';
import { Snackbar } from '../../types/Snackbar';

export const BampteeApplications: Application[] = [
  {
    id: 'ordoria',
    displayName: 'Ordoria',
    url: '/ordoria',
  },
  {
    id: 'gescom',
    displayName: 'Gescom',
    url: '/gescom',
  },
  {
    id: 'stockee',
    displayName: 'Stockee',
    url: '/stockee',
  },
  {
    id: 'iam',
    displayName: 'IAM',
    url: '/iam',
  },
  {
    id: 'bianta',
    displayName: 'Bamptee',
    url: '/',
  },
];
// hello

export const defaultMenuNavItems: NavMenuPreferences = {
  ordoria: {
    menuItems: [
      'dashboard',
      'catalog',
      'orders',
      'balance-accounting',
      'reports',
    ],
    menuAllItems: ['dashboardAll', 'reportAll'],
    menuClientItems: ['dashboard', 'claims', 'orders', 'transactions'],
    generalItems: ['customers', 'calllist'],
    docsItems: ['claims', 'transactions'],
  },
  bianta: {
    menuItems: ['dashboard', 'roundtrips'],
    menuAllItems: ['dashboard-all', 'reports-all'],
    generalItems: [
      'orders',
      'customers',
      'inbox',
      'checkDocuments',
      'payments-uploadFiles',
      'uploads-uploadFiles',
      'reports',
    ],
    resourcesItems: [
      'employees',
      'vehicles',
      'accessories',
      'tools',
      'paymentcards',
      'supportunits',
    ],
  },
  stockee: {
    menuItems: ['dashboard', 'reports'],
    menuAllItems: ['dashboardAll', 'reportAll'],
    stockItems: ['categories', 'products', 'storagelocation', 'stock'],
    relationsItems: ['suppliers', 'clientgroup', 'customers'],
    pricingItems: ['pricing', 'discountgrid'],
  },
  IAM: {
    menuItems: ['employees', 'documents', 'visitors'],
    menuAllItems: ['dashboardAll', 'reportAll'],
  },
};

export const initialState: AppState = {
  isOpenedDatePicker: false,
  currentDate: new Date().toISOString(),
  toDate: new Date().toISOString(),
  fromDate: new Date().toISOString(),
  calendarDates: null,
  lastPosition: null,
  lastToasterNotification: null,
  lastStatusToasterNotification: null,
  selectedLanguage: 'fr',
  currentApplication: BampteeApplications[0],
  previousApplication: BampteeApplications[0],
  dropDownStatus: false,
  termsReaded: false,
  cachedQueries: {},
  mutationCache: [],
  mutationResponseCache: {},
  isConnected: undefined,
  cameraScan: false,
  cameraScanKey: '',
  codeScanner: {},
  hasCameraPermission: false,
  snackbar: {
    svg: null,
    show: true,
    description: '',
    title: '',
    status: 'unset',
  },
  gridPreferences: {
    customers: [
      'code',
      'legalName',
      'publicName',
      'status',
      'address',
      'phone',
      'email',
      'legalId',
      'branches',
      'amountPerWeight',
      '_salesman',
      '_customerType',
      'score',
      'invitation',
      'action',
    ],
    suppliers: [
      'supplierID',
      'supplierName',
      'category',
      'address',
      'phone',
      'email',
      'branches',
      'status',
      'legalId',
      'amountPerWeight',
      '_salesman',
      '_supplierType',
      'score',
      'invitation',
      'action',
    ],
    orders: [
      'code',
      'customerCode',
      'customer_code',
      'customer_name',
      'address',
      'createdBy',
      'quantity',
      'countLines',
      'weight',
      'delivery_date',
      'salesman',
      'accountingBalanceTotal',
      'accountingBalanceOverdue',
      'accountingBalanceDue',
      'payment',
      'notes',
      'status',
      'action',
    ],
    ordoriaOrders: [
      'code',
      'customerCode',
      'customer_code',
      'customer_name',
      'branch',
      'address',
      'quantity',
      'countLines',
      'weight',
      'order_discount',
      'createdBy',
      'createdOn',
      'delivery_date',
      'accountingBalanceTotal',
      'accountingBalanceOverdue',
      'accountingBalanceDue',
      'priority',
      'totalAmountTax',
      'caKg',
      'status',
      'action',
    ],
  },
  lastUserNotification: null,
  tableColumnsConfig: {},
  chatDraftMessages: {},
  notifications: {
    totalImportant: 0,
    totalOther: 0,
    totalUnread: 0,
  },
  drawerPreferences: [],
  roundTripBar: 'top',
  navMenuPreferences: defaultMenuNavItems,
  displayFeaturePopup: true,
  featureVersion: '1.0',
  loadingApp: false,
};

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.locale('fr');

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDateRange: (
      state,
      {
        payload: { from_date, to_date },
      }: PayloadAction<{ from_date: string; to_date: string }>,
    ) => {
      state.fromDate = new Date(from_date).toISOString();
      state.toDate = new Date(to_date).toISOString();
    },
    setCurrentDate: (
      state,
      { payload: { date } }: PayloadAction<{ date: string }>,
    ) => {
      state.currentDate = new Date(date).toISOString();
    },
    setCalendarDates: (
      state,
      { payload: { calendarDates } }: PayloadAction<{ calendarDates: any }>,
    ) => {
      state.calendarDates = calendarDates;
    },
    setDatePickerStatus: (
      state,
      {
        payload: { isOpenedDatePicker },
      }: PayloadAction<{ isOpenedDatePicker: boolean }>,
    ) => {
      state.isOpenedDatePicker = isOpenedDatePicker;
    },
    setLastPosition: (
      state,
      { payload: { position } }: PayloadAction<{ position: Position }>,
    ) => {
      state.lastPosition = position;
    },
    setSelectedLanguage: (
      state,
      { payload: { language } }: PayloadAction<{ language: string }>,
    ) => {
      state.selectedLanguage = language;

      if (window?.dayjs) {
        window?.dayjs.locale(language);
      }

      dayjs.locale(language);
    },
    setLastToasterNotification: (
      state,
      {
        payload: { duration, severity, message, content },
      }: PayloadAction<{
        duration: number;
        severity: ToastSeverity;
        message: string;
        content?: JSX.Element;
      }>,
    ) => {
      state.lastToasterNotification = {
        duration: duration || 5000,
        severity,
        message,
        content,
      };
    },
    setLastStatusToasterNotification: (
      state,
      {
        payload: {
          duration,
          variant,
          message,
          spacingRight = '362px',
          spacingBottom = '0px',
        },
      }: PayloadAction<{
        duration: number;
        variant: StatusToastVariant;
        message: string;
        spacingRight?: string;
        spacingBottom?: string;
      }>,
    ) => {
      state.lastStatusToasterNotification = {
        duration,
        variant,
        message,
        spacingRight,
        spacingBottom,
      };
    },
    cleanLastStatusToasterNotification: state => {
      state.lastStatusToasterNotification = null;
    },
    cleanLastToasterNotification: state => {
      state.lastToasterNotification = null;
    },
    setdecreaseImportantActivities: state => {
      state.notifications.totalImportant -= 1;
    },
    setCurrentApplication: (
      state,
      { payload: { application } }: PayloadAction<{ application: Application }>,
    ) => {
      state.currentApplication = application;
    },
    setPreviousApplication: (
      state,
      { payload: { application } }: PayloadAction<{ application: Application }>,
    ) => {
      state.previousApplication = application;
    },
    setDropdownStatus: (
      state,
      { payload: { status } }: PayloadAction<{ status: boolean }>,
    ) => {
      state.dropDownStatus = status;
    },
    setSnackbar: (
      state,
      { payload: { snackbar } }: PayloadAction<{ snackbar: Snackbar }>,
    ) => {
      state.snackbar = snackbar;
    },
    setRoundTripBar: (
      state,
      { payload: { roundTripBar } }: PayloadAction<{ roundTripBar: string }>,
    ) => {
      state.roundTripBar = roundTripBar;
    },
    resetSnackbar: state => {
      if (!state.snackbar) return;
      state.snackbar.show = false;
    },
    updateCache: (
      state,
      { payload: { key, data } }: PayloadAction<{ key: string; data: any }>,
    ) => {
      if (!state.cachedQueries) {
        state.cachedQueries = { [key]: { data } };
      } else {
        state.cachedQueries[key] = { data };
      }
    },
    resetCache: state => {
      state.cachedQueries = {};
    },
    setIsConnected: (
      state,
      { payload: { isConnected } }: PayloadAction<{ isConnected: boolean }>,
    ) => {
      state.isConnected = isConnected;
    },

    updateMutationResponseCache: (
      state,
      {
        payload: { identifier, data },
      }: PayloadAction<{ identifier: string; data: any }>,
    ) => {
      if (!state.mutationResponseCache[identifier]) {
        state.mutationResponseCache[identifier] = [data];
      } else {
        state.mutationResponseCache[identifier].push(data);
      }
    },
    resetIdentifierMutationResponseCache: (
      state,
      { payload: { identifier } }: PayloadAction<{ identifier: string }>,
    ) => {
      delete state.mutationResponseCache[identifier];
    },

    resetMutationResponseCache: state => {
      state.mutationResponseCache = {};
    },

    resetMutationCache: state => {
      state.mutationCache = [];
    },

    sliceMutationCache: (
      state,
      { payload: { count } }: PayloadAction<{ count: number }>,
    ) => {
      state.mutationCache = state.mutationCache.slice(count);
    },

    setCustomerGridPreferences: (
      state,
      { payload: { columns } }: PayloadAction<{ columns: string[] }>,
    ) => {
      state.gridPreferences = {
        ...initialState.gridPreferences,
        customers: columns,
      };
    },

    setSupplierGridPreferences: (
      state,
      { payload: { columns } }: PayloadAction<{ columns: string[] }>,
    ) => {
      state.gridPreferences = {
        ...initialState.gridPreferences,
        suppliers: columns,
      };
    },

    setOrderGridPreferences: (
      state,
      { payload: { columns } }: PayloadAction<{ columns: string[] }>,
    ) => {
      state.gridPreferences = {
        ...initialState.gridPreferences,
        orders: columns,
      };
    },

    setOrdoriaOrderGridPreferences: (
      state,
      { payload: { columns } }: PayloadAction<{ columns: string[] }>,
    ) => {
      state.gridPreferences = {
        ...initialState.gridPreferences,
        ordoriaOrders: columns,
      };
    },

    updateCameraScan: (
      state,
      {
        payload: { cameraScan, cameraScanKey },
      }: PayloadAction<{ cameraScan: boolean; cameraScanKey?: string }>,
    ) => {
      state.cameraScan = cameraScan;
      if (cameraScanKey) state.cameraScanKey = cameraScanKey;
    },

    updateCodeSCanner: (
      state,
      {
        payload: { codeScanner, key },
      }: PayloadAction<{ codeScanner: any; key: string }>,
    ) => {
      state.codeScanner[key] = codeScanner;
    },

    setCameraPermission: (
      state,
      {
        payload: { hasCameraPermission },
      }: PayloadAction<{ hasCameraPermission: boolean }>,
    ) => {
      state.hasCameraPermission = hasCameraPermission;
    },

    setTermsReaded: (
      state,
      { payload: { isRead } }: PayloadAction<{ isRead: boolean }>,
    ) => {
      state.termsReaded = isRead;
    },

    setLastUserNotification: (
      state,
      {
        payload: {
          duration,
          content,
          url,
          activityName,
          activityKey,
          objectId,
          _id,
        },
      }: PayloadAction<{
        duration: number;
        content?: any;
        url?: { path: string; options?: NavigateOptions };
        activityName?: string;
        activityKey?: string;
        objectId?: string;
        _id?: string;
      }>,
    ) => {
      state.lastUserNotification = {
        duration: duration || 5000,
        content,
        url,
        activityName,
        activityKey,
        objectId,
        _id,
      };
    },
    cleanLastUserNotification: state => {
      state.lastUserNotification = null;
    },

    addMutationCache: (
      state,
      {
        payload: {
          slice,
          endpoint,
          param,
          dependencies,
          identifier,
          aditionalParameters,
          optimisticUpdate,
          formDataKeys,
          updateCacheStrategy, // string
          updateCacheParameters, // any

          updateParamsStrategy,
          updateParamsParameters,
        },
      }: PayloadAction<{
        slice: any;
        endpoint: string;
        param: any;
        dependencies: any[];
        identifier?: string;
        aditionalParameters?: any;
        updateCacheStrategy?: string;
        updateCacheParameters?: any;
        updateParamsStrategy?: string;
        updateParamsParameters?: any;
        optimisticUpdate?: boolean;
        formDataKeys?: string[];
      }>,
    ) => {
      state.mutationCache.push({
        slice,
        endpoint,
        param,
        dependencies,
        identifier,
        aditionalParameters,
        updateCacheStrategy,
        updateCacheParameters,
        updateParamsStrategy,
        updateParamsParameters,
        optimisticUpdate,
        formDataKeys,
      });
    },

    setTableColumnsConfig: (state, action) => {
      state.tableColumnsConfig = action.payload;
    },

    setChatDraftMessages: (state, action) => {
      state.chatDraftMessages = action.payload;
    },

    setDrawerPreferences: (state, action) => {
      state.drawerPreferences = action.payload;
    },
    setNavMenuPreferences: (state, action) => {
      state.navMenuPreferences = action.payload;
    },
    setDisplayFeaturePopup: (state, { payload }: PayloadAction<boolean>) => {
      state.displayFeaturePopup = payload;
    },
    setFeatureVersion: (state, { payload }: PayloadAction<string>) => {
      state.featureVersion = payload;
    },
    setLoadingApp: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingApp = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        authApi.endpoints.check.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const { user } = response.payload;

            state.selectedLanguage = user.language;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.me.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            state.selectedLanguage = response.payload?.language;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.getAuthActivitiesStats.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            state.notifications = response.payload;
          }

          return state;
        },
      );
  },
});

const appReducer = slice.reducer;

export const { actions: appActions } = slice;

export default appReducer;
