import { Box, Stack, Typography } from '@mui/material';
import { ActivityTimeline } from 'app/components/ActivityTimeline';
import { StatusSupplier } from 'app/components/StatusSupplier';
import {
  LeftSection,
  MainSection,
  RightSection,
} from 'app/styledComponents/DetailsSection.styled';
import {
  useGetSupplierActivitiesByDateQuery,
  useLazyGetSupplierActivitiesQuery,
} from 'common/services/supplierApi';
import { SupplierActions } from 'common/types/Supplier';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  supplierId: string;
}

export const HistoryTab = ({ supplierId }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [trigger] = useLazyGetSupplierActivitiesQuery();
  const { data, isLoading } = useGetSupplierActivitiesByDateQuery(
    {
      id: supplierId,
      query: `?page=${page}&limit=20`,
    },
    { skip: !supplierId },
  );

  const titlesMap = (record) => {
    const titles = {
      [SupplierActions.SUPPLIER_EDIT]: t('action.supplier-edit'),
      [SupplierActions.SUPPLIER_ADD]: t('action.supplier-add'),
      [SupplierActions.SUPPLIER_EDIT_DECK]: t('action.supplier-edit-deck'),
      [SupplierActions.SUPPLIER_ADD_DECK]: t('action.supplier-add-deck'),
      [SupplierActions.SUPPLIER_CANCEL_SEPA]: t('action.supplier-cancel-sepa'),
      [SupplierActions.SUPPLIER_VALIDATE_SEPA]: t(
        t('action.supplier-validate-sepa'),
      ),
      [SupplierActions.SUPPLIER_DELETE_DECK]: t('action.supplier-delete-deck'),
      [SupplierActions.SUPPLIER_STATUS_UPDATED]: (
        <Stack direction="row" gap="4px" alignItems="center">
          <Box>{t('status_set_to')}</Box>
          <StatusSupplier status={record?.data?.newStatus} small />
        </Stack>
      ),
    };

    return titles[record.name];
  };

  const getActivities = async (date: string, limit: number) => {
    return await trigger({
      id: supplierId,
      query: `?date=${date}&limit=${limit}&offset=2`,
    }).unwrap();
  };

  return (
    <MainSection id="scrollable-timeline">
      <LeftSection>
        <Typography fontSize="0.875rem" fontWeight={500}>
          {t('common.history')}
        </Typography>

        <Typography fontSize="0.75rem">
          {t('supplier_history_tab_text')}
        </Typography>
      </LeftSection>
      <RightSection>
        <ActivityTimeline
          data={data}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          getActivities={getActivities}
          titlesMap={titlesMap}
        />
      </RightSection>
    </MainSection>
  );
};
