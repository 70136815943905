import { Typography } from '@mui/material';
import EllipsisText from 'app/components/EllipsisText';
import { MouseEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';

interface Props {
  name: string;
  startAdornament: JSX.Element;
  value: JSX.Element | string | number;
  selected: boolean;
  onClick: (e: MouseEvent) => void;
}

export const VetricalTabItem = ({
  name,
  startAdornament,
  value,
  selected,
  onClick,
}: Props) => {
  const theme = useSelector(selectTheme);
  const tabTheme = useMemo(() => {
    if (selected) {
      return {
        stroke: theme.strokePrimary,
        fill: theme.primaryLight1,
        color: theme.primary,
      };
    } else {
      return {
        stroke: theme.silver,
        fill: theme.accordionWhiteBg,
        color: theme.black2,
      };
    }
  }, [selected, theme]);

  return (
    <Wrapper
      $stroke={tabTheme.stroke}
      $fill={tabTheme.fill}
      $color={tabTheme.color}
      $selected={selected}
      theme={theme}
      onClick={onClick}
    >
      <LeftSection $selected={selected}>
        {startAdornament}
        <Typography
          fontSize="0.8125rem"
          fontWeight="500"
          lineHeight="20px"
          letterSpacing="0.1px"
          color={selected ? theme.primary : theme.iconColor}
        >
          <EllipsisText text={name} width={130} />
        </Typography>
      </LeftSection>
      {value}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  $stroke: string;
  $fill: string;
  $color: string;
  $selected: boolean;
}>`
  width: 100%;
  min-height: 44px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.$stroke};
  background: ${(props) => props.$fill};
  color: ${(props) => props.$color};
  transition: all 0.15s;
  cursor: pointer;

  :hover {
    background-color: ${(props) =>
      props.$selected ? props.$fill : props.theme.grey};
  }
`;

const LeftSection = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    fill: ${(props) =>
      props.$selected ? props.theme.primary : props.theme.iconColor};
  }
`;
