import api from './api';

export const visitorsApi = api.injectEndpoints({
  endpoints: builder => ({
    createVisitor: builder.mutation<any, any>({
      query: ({ body, organizationId }) => ({
        url: `/visitors/${organizationId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Visitors'],
    }),
    createQrCodeVisitor: builder.mutation<any, any>({
      query: () => ({
        url: `/visitors/form/generateCode`,
        method: 'POST',
      }),
      invalidatesTags: ['Visitors'],
    }),
    patchUpdateVisitor: builder.mutation<any, any>({
      query: body => ({
        url: `/visitors`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Visitors'],
    }),
    getVisitors: builder.query<any, any>({
      query: queryParams => ({
        url: `/visitors/list${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['Visitors'],
    }),
    getOrganizationVisitors: builder.query<any, any>({
      query: organizationId => ({
        url: `/visitors/organization/${organizationId}`,
        method: 'GET',
      }),
      providesTags: ['Visitors'],
    }),
    getOrganizationUsers: builder.query<any, any>({
      query: organizationId => ({
        url: `/organization/${organizationId}/users`,
        method: 'GET',
      }),
      providesTags: ['Visitors'],
    }),
    patchCancelVisitor: builder.mutation<any, any>({
      query: body => ({
        url: `/visitors/cancel`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['Visitors'],
    }),
    patchCheckoutVisitor: builder.mutation<any, any>({
      query: body => ({
        url: `/visitors/checkout`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['Visitors'],
    }),
    patchCheckinVisitor: builder.mutation<any, any>({
      query: body => ({
        url: `/visitors/checkin`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['Visitors'],
    }),
    getVisitorsListElements: builder.query<any, any>({
      query: queryParams => ({
        url: `/visitors/list${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['Visitors'],
    }),
    getVisitorsStatus: builder.query<any, any>({
      query: () => ({
        url: `/visitors/count-status`,
        method: 'GET',
      }),
      providesTags: ['Visitors'],
    }),
    getVisitorsFacets: builder.query<any, any>({
      query: status => ({
        url: `/visitors/facets${status}`,
        method: 'GET',
      }),
      providesTags: ['Visitors'],
    }),
    getOrganizationVisit: builder.query<any, any>({
      query: ({ organizationId, uuid }) => ({
        url: `/visitors/organization/${organizationId}/visit/${uuid}`,
        method: 'GET',
      }),
    }),
    getOrganizationVisitorAgents: builder.query<any, any>({
      query: ({ organizationId, text }) => ({
        url: `/visitors/agents-basic-list?organizationId=${organizationId}&text=${text}`,
        method: 'GET',
      }),
    }),
    getVisitUuid: builder.query<any, any>({
      query: uuid => ({
        url: `/visitors/uuid/${uuid}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateVisitorMutation,
  useCreateQrCodeVisitorMutation,
  usePatchUpdateVisitorMutation,
  useGetVisitorsQuery,
  useGetOrganizationVisitorsQuery,
  useGetOrganizationUsersQuery,
  usePatchCancelVisitorMutation,
  usePatchCheckoutVisitorMutation,
  usePatchCheckinVisitorMutation,
  useGetVisitorsListElementsQuery,
  useGetVisitorsStatusQuery,
  useGetVisitorsFacetsQuery,
  useGetOrganizationVisitQuery,
  useGetOrganizationVisitorAgentsQuery,
  useLazyGetVisitUuidQuery,
} = visitorsApi;

export default visitorsApi;
