import {
  TextField,
  IconButton,
  TextFieldProps,
  Slide,
  Stack,
} from '@mui/material';
import { useDebounce } from 'common/hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { StateSetter } from 'types';
import { ArrowLeft, MagnifyingGlass } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import If from 'app/components/If';

export interface SearchFieldProps {
  value: string;
  setValue: StateSetter<string>;
  debounce?: number;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  suppressMarginBottom?: boolean;
  height?: number;
  moduleName?: string;
}

export function SearchField({
  value,
  setValue,
  debounce = 500,
  disabled,
  textFieldProps,
  suppressMarginBottom,
  height = 24,
  moduleName = '',
}: SearchFieldProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(value);

  const debouncedValue = useDebounce(inputValue, debounce);

  useEffect(() => {
    const trimmedValue = debouncedValue?.trim();
    if (trimmedValue === value?.trim()) return;
    setValue(trimmedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (value === inputValue) return;
    setInputValue(value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Stack
      sx={{
        '& .MuiFormControl-root': {
          marginBottom: !suppressMarginBottom ? '0 !important' : undefined,
        },
      }}
    >
      <TextField
        size="small"
        margin="normal"
        placeholder={`${t('common.place_holders.search')} ${t(moduleName)}`}
        label=""
        id="standard-bare"
        sx={{
          backgroundColor: themes?.default?.accordionWhiteBg,
          marginBottom: !suppressMarginBottom ? '0 !important' : undefined,
          '& .MuiInputBase-root': {
            height: `${height}px`,
            fontSize: '0.85rem',
            fontWeight: 400,
          },
          ...textFieldProps?.sx,
        }}
        onChange={onChange}
        value={inputValue}
        InputProps={{
          startAdornment: (
            <IconButton
              sx={{
                marginLeft: '-10px',
                padding: '4px',
                position: 'relative',
                overflow: 'hidden',
              }}
              disableRipple={!inputValue}
              disabled={disabled || false}
              onClick={() => {
                if (!inputValue) return;
                setInputValue('');
              }}
            >
              <If condition={!!inputValue}>
                <Slide direction="right" in={true}>
                  <Stack>
                    <Icon icon={<ArrowLeft />} />
                  </Stack>
                </Slide>
              </If>
              <If condition={!inputValue}>
                <Slide direction="right" in={true}>
                  <Stack>
                    <Icon icon={<MagnifyingGlass />} />
                  </Stack>
                </Slide>
              </If>
            </IconButton>
          ),
        }}
        disabled={disabled ?? false}
        {...textFieldProps}
      />
    </Stack>
  );
}

export default SearchField;
