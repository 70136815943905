import React, { useEffect } from 'react';
import { useManagedContext } from '../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useMenu } from '../CustomMenu/hooks/useMenu';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../styles/theme/slice/selectors';
import { DotsThree } from '@phosphor-icons/react';
import styled, { css } from 'styled-components';
import { Icon } from '../Icon';
import { MenuButtonContainer } from './index';
import { ITabsContext } from '../ManagedGrid/types';

function ManagedTab({ tab }) {
  const tabsContext = useManagedContext<ITabsContext>('tabs');
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const {
    openMenu,
    Menu: OptionsMenu,
    // isMenuOpen,
  } = useMenu(tabsContext?.menuActions?.(tab));
  const handleTabClick = (event, tabId) => {
    if (event.target.closest('.three-dots-btn')) return;
    tabsContext.updateData('selectedTab', tabId);
  };
  const handleInputBlur = (id: string) => {
    console.log('handleInputBlur', tabsContext.api?.onInputBlur);
    if (
      tabsContext.editValue?.length &&
      tabsContext.api?.onInputBlur &&
      tabsContext.editValue !== tab?.label
    ) {
      tabsContext.api.onInputBlur(tabsContext.editValue, id);
    }
    tabsContext.updateDataWithFunction((prev) => {
      prev.currentTabEdit = null;
      prev.editValue = null;
      if (prev.visibleTabs.some((t) => t.value === '')) {
        if (prev.hiddenTabs.length === 0) {
          prev.visibleTabs = prev.visibleTabs.filter((t) => t.value !== '');
          return;
        }
        prev.visibleTabs = [
          ...prev.visibleTabs.filter((t) => t.value !== ''),
          ...(prev.hiddenTabs.length > 0 ? [prev.hiddenTabs.pop()!] : []),
        ];
      }
    });
  };
  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  const theme = useSelector(selectTheme);

  return (
    <>
      <TabItem
        isSelected={tabsContext.selectedTab === tab?.value}
        onClick={(e) => handleTabClick(e, tab?.value)}
        onMouseEnter={() => tabsContext.updateData('isHovering', tab?.value)}
        onMouseLeave={() => tabsContext.updateData('isHovering', null)}
      >
        {tab?.value === tabsContext.currentTabEdit && tabsContext.editable ? (
          <TabInput
            ref={inputRef}
            id={`input-${tab?.value}`}
            value={tabsContext.editValue ?? tab?.label}
            onChange={(event) => {
              tabsContext.updateData('editValue', event.target.value);
            }}
            onBlur={() => handleInputBlur(tab?.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleInputBlur(tab?.value);
              }
            }}
          />
        ) : (
          <TabTitle
            // onClick={() => gridContext.updateData('selectedTab', tab?.value)}
            onDoubleClick={() =>
              tabsContext.canEdit &&
              (() => {
                tabsContext.updateDataWithFunction((prev) => {
                  prev.currentTabEdit = tab?.value;
                  prev.editValue = tab?.label;
                });
              })()
            }
            selected={tabsContext.selectedTab === tab?.value}
            theme={theme}
            className={
              tabsContext.selectedTab === tab?.value
                ? 'tab-label-active'
                : 'tab-label'
            }
          >
            {tab?.label}
          </TabTitle>
        )}
        {tabsContext.editable && (
          <MenuButtonContainer
            menuBtnVisible={
              tabsContext.isHovering === tab?.value &&
              (tabsContext.canEdit || tabsContext.hasViewOption)
            }
            canEdit={tabsContext.canEdit}
            onClick={(e) => {
              e.stopPropagation();
              openMenu(e);
            }}
          >
            <Icon icon={<DotsThree />} />
          </MenuButtonContainer>
        )}
      </TabItem>
      {OptionsMenu}
    </>
  );
}
const TabItem = styled.div<{
  isSelected: boolean;
}>(({ isSelected, theme }) => ({
  padding: '12px 2px',

  cursor: 'pointer',
  maxHeight: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  position: 'relative',
  borderBottom: isSelected
    ? `2px solid ${theme.primaryActiveColor}`
    : '2px solid transparent',
  fontWeight: isSelected ? 'bold' : 'normal',
  backgroundColor: 'inherit',
  transition: 'all 0.3s ease',
  userSelect: 'none',

  ':hover': {
    '.tab-label': {
      backgroundColor: theme.grey,
    },
    '.tab-label-active': {
      backgroundColor: theme.lightBackground,
    },
  },
}));
const TabInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  // focus the input after mount
  React.useEffect(() => {
    (ref as React.RefObject<HTMLInputElement>)?.current?.focus();
  }, [ref]);
  return (
    <input
      {...props}
      ref={ref}
      style={{
        height: '32px',
        color: 'var(--Light-Text-Secondary, rgba(0, 0, 0, 0.6))',
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '1.5rem',
        letterSpacing: '0.025rem',
        // textTransform: 'capitalize',
        width: `${props.width}ch`,
        minWidth: '40px',

        outline: 'none',
        border: 'none',
        textAlign: 'center',
        padding: '0 8px',
        background: 'transparent',
      }}
    />
  );
});

const TabTitle = styled.p<{
  selected: boolean;
  theme: any;
}>`
  color: var(--Text-color, #000);
  font-family: Roboto;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem; /* 116.667% */
  cursor: pointer;
  padding: 5px 8px;
  white-space: nowrap;
  transition-duration: 0.2s;
  margin: 0;
  border-radius: 4px;
  transition: all 0.3s;

  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.primary};
      font-weight: 500;
    `};

  &:hover {
    .tab-label {
      background-color: ${(props) => props.theme.grey};
    }
    .tab-label-active {
      background-color: ${(props) => props.theme.lightBackground};
    }
  }
`;

export default ManagedTab;
