import { useGrid } from 'app/components/Grid/hooks';
import {
  useCreateRoleMutation,
  useEditRoleMutation,
  useBulkDeleteMutation,
} from 'common/services/roleApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { useCallback, useMemo } from 'react';
import { useGetApplicationsQuery } from 'common/services/appApi';
import {
  useDeleteSupplierBranchMutation,
  useGetSupplierBranchesQuery,
  useUpdateSupplierBranchStatusMutation,
} from 'common/services/supplierApi';

export const useApiCalls = (id: string) => {
  const {
    page,
    rowsPerPage,
    debouncedSearchTerm,
    order,
    orderBy,
    debouncedFilters: filters,
    selected,
  } = useGrid();

  const queryParams = useQueryParams({
    page,
    limit: rowsPerPage,
    text: debouncedSearchTerm,
    sort: order + (!!orderBy ? orderBy : 'name'),
    status:
      filters?.status
        ?.map((filter) => {
          return filter.value;
        })
        ?.join(',') || undefined,
  });

  const {
    data: branches,
    isLoading,
    // refetch,
  } = useGetSupplierBranchesQuery({
    supplierId: id,
    urlQuery: queryParams,
  });

  const [createRoleMutation, { isLoading: isAdding }] = useCreateRoleMutation();
  const [editRoleMutation, { isLoading: isEditing }] = useEditRoleMutation();

  const [deleteBranch, { isLoading: isDeleting }] =
    useDeleteSupplierBranchMutation();

  const [bulkDeleteMutation, { isLoading: isBulkDeleting }] =
    useBulkDeleteMutation();

  const [changeBranchStatusMutation, { isLoading: isChangingStatus }] =
    useUpdateSupplierBranchStatusMutation();

  const { data: applicationsData } = useGetApplicationsQuery('');
  const applications = useMemo(() => {
    return applicationsData?.applications;
  }, [applicationsData]);
  const isSubmitting = useMemo(() => {
    return (
      isAdding || isEditing || isChangingStatus || isDeleting || isBulkDeleting
    );
  }, [isAdding, isEditing, isChangingStatus, isDeleting, isBulkDeleting]);

  const createRole = useCallback(
    async (data) => {
      await createRoleMutation(data).unwrap();
    },
    [createRoleMutation],
  );
  const editRole = useCallback(
    async (data) => {
      await editRoleMutation(data).unwrap();
    },
    [editRoleMutation],
  );
  const bulkDelete = useCallback(async () => {
    return await bulkDeleteMutation({ Ids: [...selected] }).unwrap();
  }, [bulkDeleteMutation, selected]);

  const changeStatus = useCallback(
    async (supplierId: string, branchId: string, status: string) => {
      await changeBranchStatusMutation({
        supplierId,
        branchId,
        status,
      }).unwrap();
    },
    [changeBranchStatusMutation],
  );
  return {
    isLoading,
    isSubmitting,
    branches,
    deleteBranch,
    applications,
    createRole,
    editRole,
    bulkDelete,
    changeStatus,
    // refetch,
  };
};
