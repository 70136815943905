import { ArrowBendDownLeft, Package } from '@phosphor-icons/react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import If from 'app/components/If';
import { RefundType } from '../config';

function TypeCell(params: CustomCellRendererProps) {
  const type = params?.value?.typeData;
  const { t } = useTranslation();

  return (
    <div>
      <RefundTypeContainer type={type}>
        <If
          condition={type === RefundType.RETURN}
          otherwise={
            <Tooltip title={t('customer.documentTypes.credit')}>
              <Package
                color={themes?.default.orange700}
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
            </Tooltip>
          }
        >
          <Tooltip title={t('customer.documentTypes.return')}>
            <ArrowBendDownLeft
              color={themes?.default.lightGreen800}
              style={{
                width: '16px',
                height: '16px',
              }}
            />
          </Tooltip>
        </If>
      </RefundTypeContainer>
    </div>
  );
}

const RefundTypeContainer = styled.div<{ type: string }>`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ type }) =>
    type === 'credit' ? themes.default.orange50 : themes.default.lightGreen55};
`;

export default TypeCell;
