import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Badge, Tooltip } from '@mui/material';
import { Note } from '@phosphor-icons/react';
import If from 'app/components/If';
import { isEmpty } from 'lodash';
import { themes } from 'styles/theme/themes';

function NoteCell(params: CustomCellRendererProps) {
  return (
    <div>
      <If
        condition={!isEmpty(params.value.notesData)}
        otherwise={
          <Note
            color={themes?.default?.iconColor}
            style={{
              width: '18px',
              height: '18px',
            }}
          />
        }
      >
        <Tooltip title={params.value.notesData}>
          <Badge color="primary" variant="dot">
            <Note
              style={{
                width: '18px',
                height: '18px',
              }}
            />
          </Badge>
        </Tooltip>
      </If>
    </div>
  );
}

export default NoteCell;
