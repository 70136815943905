import styled, { CSSObject } from 'styled-components';
import { themes } from 'styles/theme/themes';
import { StatusChipCell } from './components/Cells/StatusChipCell';
import { ApproveCell } from './components/Cells/ApproveCell';
import { NotesCell } from './components/Cells/NotesCell';
import { ContractActionsCell } from './components/Cells/ContractActionsCell';
import { IColDef } from 'app/components/ManagedGrid/types';
import { ContractNameCell } from './components/Cells/ContractNameCell';
import { DiscountHeader } from 'app/components/Restrictions/Components/DiscountHeader';
import { DiscountInputCell } from 'app/components/Restrictions/Components/DiscountInputCell';
import { LockHeaderOption } from 'app/components/Restrictions/Components/HeaderOption/LockHeaderOption';
import { MaxHeaderOption } from 'app/components/Restrictions/Components/HeaderOption/MaxHeaderOption';
import {
  discountIndices,
  DiscountIndex,
  DiscountName,
} from 'app/components/Restrictions/types/discounts.types';

const headCellStyles: CSSObject = {
  fontSize: '0.625rem',
  lineHeight: '16px',
  fontWeight: '700',
  letterSpacing: '0.17px',
  color: themes.default.clientMarkerBg,
};

export const getColumnConfig = (t: (key: string) => string): IColDef[] => [
  {
    cellRenderer: ContractNameCell,
    minWidth: 273,
    flex: 1,
    sortField: 'name',
    valueFormatter: (params) => params.value?.name,
    headerName: '',
    CustomHeaderComponent: <HeaderTextComponent title={t('')} />,
    colId: 'subcategory-name',
  },
  {
    cellRenderer: ContractNameCell,
    minWidth: 273,
    flex: 1,
    sortField: 'parentCategory',
    valueFormatter: (params) => params.value?.name,
    headerName: t('contracts.category_name'),
    CustomHeaderComponent: (
      <HeaderTextComponent title={t('contracts.category_name')} />
    ),
  },
  ...discountIndices.map<IColDef>((i) => {
    const ri: DiscountName = `r${i}`;
    return {
      cellRenderer: DiscountInputCell,
      flex: 1,
      sortField: ri,
      valueFormatter: (params) => params.value?.cellData,
      headerName: t('discount_abbrev') + i,
      CustomHeaderComponent: <HeaderComponent discountIndex={i} />,
      discountIndex: i,
      customHeaderOptions: [LockHeaderOption, MaxHeaderOption],
      minWidth: 190,
    };
  }),
  {
    cellRenderer: NotesCell,
    maxWidth: 66,
    flex: 1,
    headerName: t('field.notes'),
    CustomHeaderComponent: <HeaderTextComponent title={t('field.notes')} />,
  },
  {
    cellRenderer: ApproveCell,
    maxWidth: 64,
    flex: 1,
    headerName: t('approve'),
    CustomHeaderComponent: <HeaderTextComponent title={t('approve')} />,
    colId: 'approve',
    pinned: 'right',
    initialPinned: true,
  },
  {
    cellRenderer: StatusChipCell,
    minWidth: 154,
    flex: 1,
    sortField: 'status',
    headerName: t('checkDocument.status'),
    CustomHeaderComponent: (
      <HeaderTextComponent title={t('checkDocument.status')} />
    ),
    pinned: 'right',
    initialPinned: true,
  },
  {
    cellRenderer: ContractActionsCell,
    cellStyle: {
      padding: 0,
    },
    headerClass: 'noPaddingHeadCell',
    width: 38,
    flex: 1,
    lockPinned: true,
    pinned: 'right',
    initialPinned: true,
    suppressMovable: true,
  },
];

export const HeaderComponent = ({
  discountIndex,
  applyCustomStyles = true,
}: {
  discountIndex: DiscountIndex;
  applyCustomStyles?: boolean;
}) => {
  return (
    <HeaderCellWrapper>
      <DiscountHeader
        i={discountIndex}
        titleSx={applyCustomStyles ? headCellStyles : undefined}
        hasLimit={true}
      />
    </HeaderCellWrapper>
  );
};

export const HeaderTextComponent = ({ title }: { title: string }) => {
  return (
    <HeaderCellWrapper style={{ ...headCellStyles }}>
      <span>{title}</span>
    </HeaderCellWrapper>
  );
};

const HeaderCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    color: ${themes.default.clientMarkerBg};
  }
`;
