import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { AcceptedFiles } from 'app/components/AcceptedFiles/Index';
import { FormActions } from 'app/components/Form/styles';
import { UploadedFile } from 'app/components/UploadComponent';
import { UploadZone } from 'app/components/UploadFiles';
import { CLAIMS_TYPES } from 'app/pages/Ordoria/Claims/config';
import { useDebounce } from 'common/hooks/useDebounce';
import { usePostConversationMutation } from 'common/services/conversationApi';
import {
  useGetCustomersBasicQuery,
  useLazyGetCustomersBasicsBranchesQuery,
  useLazyGetCustomersOrdersBasicsQuery,
} from 'common/services/customerApi';
import {
  selectClaimReasons,
  selectContactAddresses,
} from 'common/store/organization/selectors';
import { useQueryParams } from 'hooks/useQueryParams';
import { useToaster } from 'hooks/useToaster';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ClaimIcon } from '../../../ClaimIcon';
import { useUploadMutation } from 'common/services/files';
import { stringAvatar } from 'app/helpers/helpers';
import CancelIcon from '@mui/icons-material/Cancel';
import If from 'app/components/If';
import { usePostCustomerPortalClaimsMutation } from 'common/services/customerPortalApi';
import { LoadingButton } from '@mui/lab';
import { FileRejection } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { FieldComponent } from 'app/components/FieldComponent';
import FormTextField from 'app/components/Form/TextField';
import { usePatchConversationMutation } from 'common/services/conversationApi';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useGetBasicAgentsListQuery } from 'common/services/agents';

interface ClaimFormProps {
  handleClose: () => void;
  customer?: any;
  order?: any;
  isUserCustomer: boolean;
  isEdit: boolean;
  row?: any;
}

type Payload = {
  contactAddressId: any;
  customerId?: any;
  customerDeckId?: any;
  orderId: any;
  reasonKey: any;
  _agents?: any[];
  activateReminder: any;
  _files?: string[];
  reasonDetail?: string;
  content?: string;
};

export function ClaimForm({
  handleClose,
  customer,
  order,
  isUserCustomer,
  isEdit,
  row,
}: ClaimFormProps) {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  const toast = useToaster();

  const contactAddresses = useSelector(selectContactAddresses);
  const claimReasons = useSelector(selectClaimReasons);

  const [selectedDeck, setSelectedDeck] = useState<any>(() => {
    if (isEdit) {
      return row?._customerDeck;
    }
    return {};
  });

  /** SEARCH STATES */
  const [customersSearch, setCustomersSearch] = useState<string>(
    isEdit ? row?._customer?.legalName : '',
  );
  const [ordersSearch, setOrdersSearch] = useState<string>('');
  const [agentsSearch, setAgentsSearch] = useState<string>('');
  const [branchesSearch, setBranchesSearch] = useState<string>('');

  /** DEBOUNCED SEARCH */
  const debouncedCustomersSearch = useDebounce(customersSearch, 200);
  const debouncedOrdersSearch = useDebounce(ordersSearch, 200) || '';
  const debouncedBranchesSearch = useDebounce(branchesSearch, 200);
  const debouncedAgentsSearch = useDebounce(agentsSearch, 200);

  /** QUERY PARAMS */
  const branchesQueryParams = useQueryParams({ text: debouncedBranchesSearch });
  const queryParams = useQueryParams({ text: debouncedOrdersSearch });
  const agentsParams = useQueryParams({
    text: debouncedAgentsSearch,
    excludeRoleId: '65004500f91988461dbfc398', //Exclude customer role
    permissionEditSection: 'claims', //Allow only agents with claims permission edit
  });

  /** QUERY */
  const { data: customers, refetch } = useGetCustomersBasicQuery(
    `text=${debouncedCustomersSearch}`,
    { skip: isUserCustomer },
  );

  const { data: agents } = useGetBasicAgentsListQuery(agentsParams);

  const [postConversation, { isLoading }] = usePostConversationMutation();
  const [postClientConversation, { isLoading: isLoadingClient }] =
    usePostCustomerPortalClaimsMutation();
  const [uploadFiles, { isLoading: isLoadingFilesUpload }] =
    useUploadMutation();
  const [triggerOrder, { data: orders }] =
    useLazyGetCustomersOrdersBasicsQuery();
  const [trigger, { data: branches }] =
    useLazyGetCustomersBasicsBranchesQuery();
  const [updateClaim, { isLoading: isLoadingPatch }] =
    usePatchConversationMutation();

  /** FORM */
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: isEdit
      ? {
          type:
            contactAddresses?.find((address) => address.topic === row?.topic)
              ?._id || '',
          customerId: row?._customer || '',
          customerDeckId: row?._customerDeck || {},
          orderId: row?._order || '',
          reason:
            claimReasons?.find((reason) => reason.key === row?.reasonKey) || '',
          reasonDetail: row?.reasonDetail || '',
          _agents: row?._agents || [],
          content: row?._firstMessage?.content,
          activateReminder: row?.activateReminder,
        }
      : {
          type: '',
          customerId: customer ? customer._id || customer?.id : '',
          customerDeckId: {},
          orderId: order ?? {},
          reason: {
            key: '',
            i18n: {
              en: '',
              fr: '',
            },
          },
          reasonDetail: '',
          _agents: [],
          content: '',
          activateReminder: false,
        },
  });

  const { control, watch, setValue, getValues, reset } = methods;

  const authUser: any = useSelector(selectAuthUser);

  /** WATCH */
  const customerId = isUserCustomer
    ? authUser?._currentAgent._customer._id
    : watch('customerId')?._id;
  const reasonKey = watch('reason')?.key;
  const type = watch('type');
  const reasonDetail = watch('reasonDetail');

  const [files, setFiles] = useState<any[]>(isEdit ? row?._files : []);

  const onFileChange = useCallback(async (newFiles: UploadedFile[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const handleFileDelete = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  function resetRowOnClose() {
    reset();
    handleClose();
  }

  async function handleUploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const response = await uploadFiles({
      objectType: 'attachment',
      objectId: customerId,
      formData: formData,
      persist: true,
      preserveName: false,
    }).unwrap();

    return response?._id;
  }

  async function handlePostConversation() {
    try {
      let uploadedFiles: Array<string> = []; //Array of files id
      if (files.length > 0) {
        uploadedFiles = await Promise.all(
          files.map((file) => handleUploadFile(file)),
        );
      }

      const payload: Payload = {
        contactAddressId: getValues('type'),
        customerId: getValues('customerId')?._id,
        customerDeckId: selectedDeck?._id,
        orderId: getValues('orderId')?._id,
        reasonKey: getValues('reason')?.key,
        _agents: getValues('_agents').map((agent) => agent._id),
        activateReminder: getValues('activateReminder'),
        content: getValues('content'),
        _files: uploadedFiles,
      };

      if (getValues('reason')?.key === 'other') {
        payload.reasonDetail = getValues('reasonDetail');
      }

      await postConversation(payload)
        .then(() => {
          resetRowOnClose();
          toast(3000, 'success', t('claims.post.toast.success'));
        })
        .catch(() => {
          toast(3000, 'error', t('claims.post.toast.error'));
        });
    } catch (error) {
      toast(3000, 'error', t('claims.post.toast.error'));
    }
  }

  async function handlePostClientConversation() {
    try {
      let uploadedFiles: Array<string> = []; //Array of files id
      if (files.length > 0) {
        uploadedFiles = await Promise.all(
          files.map((file) => handleUploadFile(file)),
        );
      }

      const payload: Payload = {
        customerId: customerId,
        orderId: getValues('orderId')?._id,
        contactAddressId: getValues('type'),
        reasonKey: getValues('reason')?.key,
        activateReminder: getValues('activateReminder'),
        content: getValues('content'),
        _files: uploadedFiles,
      };

      if (getValues('reason')?.key === 'other') {
        payload.reasonDetail = getValues('reasonDetail');
      }

      await postClientConversation(payload)
        .then(() => {
          resetRowOnClose();
          toast(3000, 'success', t('claims.post.toast.success'));
        })
        .catch(() => {
          toast(3000, 'error', t('claims.post.toast.error'));
        });
    } catch (error) {
      toast(3000, 'error', t('claims.post.toast.error'));
    }
  }

  async function handlePatchConversation() {
    try {
      const uploadedFiles: Array<string> = [];
      const newFiles: any[] = [];

      files?.map((file) => {
        if (file.url) {
          uploadedFiles.push(file._id);
        } else {
          newFiles.push(file);
        }
      });

      const newFileIds = await Promise.all(
        newFiles.map((file) => handleUploadFile(file)),
      );

      uploadedFiles.push(...newFileIds);

      const payload: Payload = {
        contactAddressId: getValues('type'),
        customerId: getValues('customerId')?._id,
        customerDeckId: selectedDeck?._id,
        orderId: getValues('orderId')?._id || null,
        reasonKey: getValues('reason')?.key,
        _agents: getValues('_agents').map((agent) => agent._id),
        activateReminder: getValues('activateReminder'),
        content: getValues('content'),
        _files: uploadedFiles,
      };

      if (getValues('reason')?.key === 'other') {
        payload.reasonDetail = getValues('reasonDetail');
      }

      updateClaim({ conversationId: row._id, body: payload })
        .then(() => {
          resetRowOnClose();
          toast(3000, 'success', t('claim.edit.success'));
        })
        .catch(() => {
          toast(3000, 'error', t('server_error_message'));
        });
    } catch (e) {
      toast(3000, 'error', t('claims.post.toast.error'));
    }
  }

  function handleClearFields() {
    setBranchesSearch('');
    setOrdersSearch('');
    setValue('orderId', {});
    setSelectedDeck(null);
    return;
  }

  /** DISABLE FORM */
  const handleDisableForm = () => {
    if (isUserCustomer) {
      return !type || !reasonKey || (reasonKey === 'other' && !reasonDetail);
    } else {
      return (
        !type ||
        !customerId ||
        !selectedDeck ||
        !reasonKey ||
        (reasonKey === 'other' && !reasonDetail)
      );
    }
  };

  const handleClick = () => {
    if (isEdit ? isLoadingPatch : isLoading || isLoadingClient) return;

    if (isEdit) {
      handlePatchConversation();
    } else {
      isUserCustomer
        ? handlePostClientConversation()
        : handlePostConversation();
    }
  };

  const isLoadingButton = () => {
    if (isEdit) {
      return isLoadingPatch || isLoadingFilesUpload;
    }
    return isLoading || isLoadingClient || isLoadingFilesUpload;
  };
  /** */

  useEffect(() => {
    if (customerId) {
      !isUserCustomer &&
        trigger({ customerId: customerId, queryParams: branchesQueryParams });
      triggerOrder({ customerId: customerId, queryParams });
    }
  }, [branchesSearch, ordersSearch, customerId]);

  return (
    <Wrapper>
      <div
        className="firstSection"
        style={{
          marginBottom: '24px',
        }}
      >
        <Typography fontWeight={500} mb="16px">
          {t('reportabug-details')}
        </Typography>
        <FieldComponent
          control={control}
          name="type"
          label={t('field.type')}
          select
          rules={{ required: true }}
          required={true}
          error={type === null}
          InputProps={{
            size: 'small',
          }}
        >
          {contactAddresses?.map((option) => (
            <MenuItem key={option._id} value={option._id}>
              <Chip
                label={
                  <Grid container alignItems="center" gap="6px">
                    <ClaimIcon claimType={option?.topic} />
                    <Typography fontSize="0.825rem">
                      {t(`claims.type.${option?.topic}`)}
                    </Typography>
                  </Grid>
                }
                size="small"
                sx={{
                  backgroundColor:
                    CLAIMS_TYPES[option?.topic?.toUpperCase()]?.backgroundColor,
                  color: CLAIMS_TYPES[option?.topic?.toUpperCase()]?.color,
                  borderRadius: '4px',
                }}
              />
            </MenuItem>
          ))}
        </FieldComponent>
        <If condition={!isUserCustomer}>
          <Controller
            name="customerId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={customers?.docs || []}
                getOptionLabel={(option: any) => option?.legalName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('common.customer')}
                    onChange={(e) => {
                      setCustomersSearch(e?.target?.value);
                      if (!e?.target?.value) {
                        handleClearFields();
                        setCustomersSearch('');
                        setSelectedDeck(null);
                        refetch();
                        setValue('customerId', null);
                      }
                    }}
                    error={getValues('customerId') === null}
                    required={true}
                  />
                )}
                renderOption={(props, option: any) => (
                  <li {...props}>
                    <Typography>{option.legalName}</Typography>
                  </li>
                )}
                onChange={(event, value: any) => {
                  field.onChange(value);
                  setValue('customerId', value);
                  setValue('orderId', null);

                  if (!value) {
                    handleClearFields();
                    setSelectedDeck(null);
                    return;
                  }

                  triggerOrder({ customerId: value?._id, queryParams });
                  trigger({
                    customerId: value?._id,
                    queryParams: branchesQueryParams,
                  })
                    .then(({ data }) => {
                      if (data && data.length === 1) {
                        const singleBranch = data[0];
                        setSelectedDeck(singleBranch);
                      } else {
                        setSelectedDeck(null);
                      }
                    })
                    .catch(() => {
                      setValue('customerDeckId', {});
                      setSelectedDeck(null);
                    });
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
              />
            )}
          />
          <Autocomplete
            options={branches || []}
            getOptionLabel={(option: any) =>
              option?.location?.shortAddress || option?.location?.name || ''
            }
            value={selectedDeck || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('claims.field.client.branch')}
                onChange={(e) => {
                  setBranchesSearch(e?.target?.value);
                }}
                error={selectedDeck === null}
                required={true}
              />
            )}
            renderOption={(props, option: any) => (
              <li {...props}>
                <Typography>
                  {option.location.shortAddress || option.location.name}
                </Typography>
              </li>
            )}
            onChange={(event, value: any) => {
              setSelectedDeck(value);
            }}
          />
        </If>
        <Controller
          name="orderId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={orders?.docs || []}
              getOptionLabel={(option: any) => option.code || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('autocomplete.title.orders')}
                  onChange={(e) => {
                    setOrdersSearch(e?.target?.value);
                  }}
                />
              )}
              onChange={(event, value) => {
                field.onChange(value);
              }}
              renderOption={(props, option: any) => (
                <li {...props}>
                  <Typography>{option.code}</Typography>
                </li>
              )}
              isOptionEqualToValue={(option, value) => option._id === value._id}
            />
          )}
        />
        <Controller
          name="reason"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={claimReasons}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('common.reason')}
                  error={!watch('reason')}
                  required={true}
                />
              )}
              getOptionLabel={(option) => option?.i18n[locale]}
              onChange={(event, value) => {
                if (value?.key === 'other') {
                  setValue('reasonDetail', '');
                }
                field.onChange(value);
              }}
              isOptionEqualToValue={(option, value) => option.key === value.key}
            />
          )}
        />
        <If condition={reasonKey === 'other'}>
          <Controller
            name="reasonDetail"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('common.specify_reason')}
                required={true}
                onChange={(e) =>
                  field.onChange(e.target.value === '' ? null : e.target.value)
                }
                style={{
                  width: '100%',
                }}
                error={watch('reasonDetail') === null}
              />
            )}
          />
        </If>
        <If condition={!isUserCustomer}>
          <Controller
            name="_agents"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={agents?.docs || []}
                getOptionLabel={(option) =>
                  option?.firstName + ' ' + option?.lastName || ''
                }
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('accessories.assignee')}
                    onChange={(e) => {
                      setAgentsSearch(e?.target?.value);
                    }}
                  />
                )}
                onChange={(event, value: any[]) => {
                  field.onChange(value);
                }}
                renderTags={(values) => {
                  return values.map((option) => (
                    <Chip
                      key={option?._id || option.id}
                      size="small"
                      sx={{
                        height: '30px',
                        mr: '4px',
                        '.MuiChip-label': {
                          padding: '0px 6px',
                        },
                      }}
                      label={
                        <Grid container alignItems="center" gap="6px">
                          <If
                            condition={option?.pictureUrl}
                            otherwise={
                              <Avatar
                                sx={{
                                  width: '22px',
                                  height: '22px',
                                  fontSize: '0.725rem',
                                }}
                              >
                                {stringAvatar(
                                  option?.firstName + ' ' + option?.lastName,
                                )}
                              </Avatar>
                            }
                          >
                            <Avatar
                              src={option?.pictureUrl}
                              sx={{
                                width: '22px',
                                height: '22px',
                                fontSize: '0.725rem',
                              }}
                            />
                          </If>
                          <Typography fontSize="0.825rem">
                            {option?.firstName + ' ' + option?.lastName}
                          </Typography>
                        </Grid>
                      }
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onDelete={() => {
                        setAgentsSearch('');
                        const values = getValues('_agents');
                        const newValues = values.filter(
                          (agent) => agent._id !== option._id,
                        );
                        field.onChange(newValues);
                      }}
                    />
                  ));
                }}
                isOptionEqualToValue={(option, value) => {
                  return option._id === value._id;
                }}
              />
            )}
          />
        </If>
        <FormTextField
          name="content"
          label="Message"
          control={control}
          multiline
          rows={4}
          style={{
            width: '100%',
          }}
        />
        <Controller
          name="activateReminder"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              control={<Switch defaultChecked={field.value} />}
              label={t('claims.ask-reminders')}
              onChange={() => {
                field.onChange(!field.value);
              }}
            />
          )}
        />
      </div>
      <div className="secondSection">
        <Typography fontWeight={500} mb="16px">
          {t('common.attachments')}
        </Typography>
        <UploadZone
          onOk={onFileChange}
          onUnOk={(rejection: FileRejection) => {
            toast(5000, 'error', rejection?.errors?.[0]?.message);
          }}
          description="JPEG, TIFF, or PNG (max. 3MB)"
          acceptFile={{
            'image/png': [],
            'image/jpg': ['.jpeg', '.jpg'],
            'image/jpeg': [],
            'image/tiff': ['.tiff'],
          }}
        />
        <div style={{ marginBottom: '20px' }}>
          {files?.map((file) => (
            <AcceptedFiles
              key={file.name}
              file={file}
              statusFile={{ [file.name]: true }}
              hideProgressBar={{ [file.name]: true }}
              fileProgress={{ [file.name]: true }}
              deleteFile={() => handleFileDelete(file.name)}
              canView={true}
              newFile={file instanceof File}
            />
          ))}
        </div>
      </div>
      <FormActions>
        <Button
          disableElevation
          sx={{ color: themes.default.black60, mr: '16px' }}
          onClick={resetRowOnClose}
        >
          {t('common.buttons.cancel')}
        </Button>

        <LoadingButton
          variant="contained"
          loading={isLoadingButton()}
          onClick={handleClick}
          disabled={handleDisableForm()}
        >
          {isEdit ? t('common.buttons.save') : t('add')}
        </LoadingButton>
      </FormActions>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 24px 20px;
`;
