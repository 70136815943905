import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { Flag as FlagIcon, Star } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { ImagesSquare, Notebook } from '@phosphor-icons/react';

import {
  useUpdateShowToCatalogMutation,
  useUpdateShowToGalleryMutation,
} from 'common/services/productApi';
import { useState } from 'react';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';
import { ActionIconButton } from 'app/components/ActionIconButton';
import { useTheme } from 'common/hooks/useTheme';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { IProductRowData } from '../../types';
import If from 'app/components/If';

interface FlagProps {
  orderFreq: string;
  id: string;
  showInCatalog?: boolean;
  showInGallery?: boolean;
  isNew?: boolean;
}

export const Flag = ({
  orderFreq,
  id,
  showInCatalog,
  showInGallery,
  isNew = false,
}: FlagProps) => {
  const { t } = useTranslation();
  const [addToCatalog] = useUpdateShowToCatalogMutation();
  const [addToGallery] = useUpdateShowToGalleryMutation();

  const [addedToCatalog, setAddedToCatalog] = useState(showInCatalog);
  const [addedToGallery, setAddedToGallery] = useState(showInGallery);

  const theme = useTheme();
  const { callApi } = useAsyncDataV2();

  const gridContext = useManagedContext<IGridContext<IProductRowData>>('grid');
  const canEdit = gridContext?.canEdit;

  const color =
    orderFreq === 'high'
      ? themes?.default.greenStatus
      : orderFreq === 'medium' || orderFreq === 'not-ordered'
        ? themes?.default.greyFlag
        : orderFreq === 'low'
          ? themes?.default.redFlag
          : '';

  const flagTooltip =
    orderFreq === 'high'
      ? t('products.tooltip.order.high')
      : orderFreq === 'medium'
        ? t('products.tooltip.order.medium')
        : orderFreq === 'low'
          ? t('products.tooltip.order.low')
          : orderFreq === 'not-ordered'
            ? t('products.tooltip.order.not_ordered')
            : '';

  const catalogTooltip = addedToCatalog
    ? 'products.hide_from_catalog'
    : 'products.show_in_catalog';

  const galleryTooltip = addedToGallery
    ? 'products.hide_from_gallery'
    : 'products.show_in_gallery';

  const updateStatus = async () => {
    await callApi(
      async () => {
        addToCatalog({
          id: id,
          show: !addedToCatalog,
        }).unwrap();
        setAddedToCatalog(!addedToCatalog);
      },
      {
        successMessage: t(
          !addedToCatalog
            ? 'products.catalog_add_success'
            : 'products.catalog_remove_success',
        ),
      },
    );
  };

  const updateGalleryStatus = async () => {
    await callApi(
      async () => {
        addToGallery({
          id: id,
          show: !addedToGallery,
        }).unwrap();
        setAddedToGallery(!addedToGallery);
      },
      {
        successMessage: t(
          !addedToGallery
            ? 'products.gallery_add_success'
            : 'products.gallery_remove_success',
        ),
      },
    );
  };

  return (
    <Stack direction="row" alignItems="center" gap="4px">
      <Tooltip title={flagTooltip}>
        <Stack direction="row" alignItems="center">
          <Typography
            fontSize="1.25rem"
            lineHeight="1.5rem"
            color={theme.Cinnabar}
            sx={{ opacity: orderFreq === 'not-ordered' ? 1 : 0 }}
          >
            !
          </Typography>
          <FlagIcon color={color} size={20} weight="fill" />
        </Stack>
      </Tooltip>
      <If condition={!!gridContext?.extraData.showNewFlag}>
        <Box sx={{ paddingLeft: '4px', opacity: isNew ? 1 : 0 }}>
          <Chip
            size="small"
            sx={{
              backgroundColor: theme.primaryActiveColor,
              color: 'white',
              borderRadius: '3px',
              height: 'auto',
              padding: '2px 0',
            }}
            label={
              <Stack direction="row" alignItems="center" gap="4px">
                <Star weight="fill" size="10" />
                <Typography fontSize="0.625rem" lineHeight="normal">
                  {t('new')}
                </Typography>
              </Stack>
            }
          />
        </Box>
      </If>

      {(!!showInCatalog || !!showInGallery) && (
        <Stack direction="row">
          <ActionIconButton
            title={catalogTooltip}
            icon={<Notebook />}
            iconProps={{
              color: addedToCatalog
                ? theme.primaryActiveColor
                : themes?.default.greyFlag,
              size: 20,
              weight: addedToCatalog ? 'fill' : 'regular',
            }}
            buttonSx={{ padding: '4px', height: '32px', width: '32px' }}
            onClick={updateStatus}
            disabled={!canEdit}
          />
          <ActionIconButton
            title={galleryTooltip}
            icon={<ImagesSquare />}
            iconProps={{
              color: addedToGallery
                ? theme.primaryActiveColor
                : themes?.default.greyFlag,
              size: 20,
              weight: 'fill',
            }}
            buttonSx={{ padding: '4px', width: '32px' }}
            onClick={updateGalleryStatus}
            disabled={!canEdit}
          />
        </Stack>
      )}
    </Stack>
  );
};
