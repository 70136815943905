import { Typography, Button } from '@mui/material';
import { TrashSimple } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { useDeleteProductsFromCartMutation } from 'common/services/cartApi';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';
import { useSelector } from 'react-redux';
import {
  getCountOfSelectedProducts,
  getProductSelectionIds,
} from 'common/store/cart/selectors';
import { useDispatch } from 'react-redux';
import {
  deleteProductsFromSelectedObject,
  removeAllProductsFromCart,
} from 'common/store/cart';
import { themes } from 'styles/theme/themes';
import If from 'app/components/If';
import { useModal } from 'app/components/Dialog/hooks';

export const generateParamsString = (selectedIds: string[], key = 'ids') => {
  let params = '';
  selectedIds.forEach((ProductId) => {
    params += `${key}[]=${ProductId}&`;
  });
  return params.slice(0, params.length - 1);
};

export const DeleteFromCartDialog = () => {
  const [deleteProductsFromCart] = useDeleteProductsFromCartMutation();
  const { t } = useTranslation();
  const toaster = useToaster();
  const dispatch = useDispatch();
  const { openModal, closeModal } = useModal();

  const countOfSelectedProducts = useSelector(getCountOfSelectedProducts);
  const selectedIds = useSelector(getProductSelectionIds);

  const deleteProducts = () => {
    try {
      const paramsString = generateParamsString(selectedIds);
      deleteProductsFromCart(paramsString);
      // Deletion Side Effects
      dispatch(removeAllProductsFromCart());
      closeModal();
      dispatch(deleteProductsFromSelectedObject(selectedIds));
      toaster(2000, 'success', 'Products deleted from cart Successfully');
    } catch (e) {
      toaster(2000, 'error', t('cart.products_not_deleted'));
      console.log('delete Products From Cart', e);
    }
  };

  const triggerDeleteDialog = () => {
    openModal({
      title: t('empty_cart'),
      content:
        t('cart.text.dialog_body', {
          count: countOfSelectedProducts,
        }) +
        ' ' +
        t('cart.text.undone_step'),
      action: {
        actionText: t('delete'),
        actionCallback: () => deleteProducts(),
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  };

  return (
    <If condition={countOfSelectedProducts > 0}>
      <Button
        sx={{
          alignItems: 'center',
          flexDirection: 'row',
          gap: '4px',
          marginRight: '-8px',
        }}
        onClick={triggerDeleteDialog}
      >
        <Icon icon={<TrashSimple />} size={16} />
        <Typography
          sx={{
            fontSize: '0.75rem',
            lineHeight: '16px',
            color: themes.default.iconColor,
            textTransform: 'uppercase',
            fontWeight: '500',
          }}
        >
          {t('cart.button.remove')}
        </Typography>
      </Button>
    </If>
  );
};
