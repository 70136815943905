import { Backdrop, Fade } from '@mui/material';
import AnimationFileComponent from '../AnimationFileComponent';
import loaderData from 'assets/json/application-loader.json';
import { themes } from 'styles/theme/themes';
import { useSelector } from 'react-redux';
import { selectLoadingApp } from 'common/store/app/selectors';

const ApplicationLoader = ({}) => {
  const display = useSelector(selectLoadingApp);
  return (
    <Fade in={display} unmountOnExit>
      <Backdrop
        open
        sx={{
          backgroundColor: themes.default.baseWhite,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AnimationFileComponent animationData={loaderData} />
      </Backdrop>
    </Fade>
  );
};

export default ApplicationLoader;
