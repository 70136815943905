import api from './api';
import {
  IProductDiscountBody,
  ICustomerProductDiscount,
  UpdateProductDiscountPayload,
  AddProductDiscountNotePayload,
  AddProductDiscountNoteRes,
  DeleteProductDiscountNoteRes,
  EditProductDiscountNotePayload,
  EditProductDiscountNoteRes,
  DeleteProductDiscountNotePayload,
  resetProductDiscountPayload,
} from '../types/ProductsDiscounts';
import { UpdateContractDiscountRes } from 'common/types/customerCategoryDiscounts';

export const productsDiscountsApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductsDiscounts: builder.query<ICustomerProductDiscount, string>({
      query: urlParams => ({
        url: `/customer-product-discount${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['ProductsDiscounts'],
    }),
    addProductDiscount: builder.mutation<void, { body: IProductDiscountBody }>({
      query: body => ({
        url: '/customer-product-discount',
        method: 'POST',
        body: body.body,
      }),
      invalidatesTags: ['ProductsDiscounts', 'CustomerContractStats'],
    }),
    removeDiscount: builder.mutation<void, string>({
      query: urlParams => ({
        url: `/customer-product-discount/delete${urlParams}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProductsDiscounts', 'CustomerContractStats'],
    }),
    toggleActiveDiscount: builder.mutation<
      void,
      { body: { ids: string[]; disabled: boolean; _customer: string } }
    >({
      query: ({ body }) => ({
        url: `/customer-product-discount/disable`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ProductsDiscounts', 'CustomerContractStats'],
    }),
    toggleStatusDiscount: builder.mutation<
      void,
      { body: { _customer: string; ids: string[]; status: string } }
    >({
      query: ({ body }) => ({
        url: `/customer-product-discount/approve`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ProductsDiscounts', 'CustomerContractStats'],
    }),
    updateContractProductDiscount: builder.mutation<
      UpdateContractDiscountRes,
      UpdateProductDiscountPayload
    >({
      query: ({ body }) => ({
        url: `/customer-product-discount/discounts`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['ProductsDiscounts'],
    }),
    addProducDiscountNote: builder.mutation<
      AddProductDiscountNoteRes,
      AddProductDiscountNotePayload
    >({
      query: ({ productId, body }) => ({
        url: `/customer-product-discount/${productId}/notes/add`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['ProductsDiscounts'],
    }),
    deleteProductDiscountNote: builder.mutation<
      DeleteProductDiscountNoteRes,
      DeleteProductDiscountNotePayload
    >({
      query: ({ productId, body }) => ({
        url: `/customer-product-discount/${productId}/notes/delete?_customer=${body._customer}&_note=${body._note}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProductsDiscounts'],
    }),
    editProductDiscountNote: builder.mutation<
      EditProductDiscountNoteRes,
      EditProductDiscountNotePayload
    >({
      query: ({ productId, body }) => ({
        url: `/customer-product-discount/${productId}/notes/edit`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['ProductsDiscounts'],
    }),
    resetContractProductDiscounts: builder.mutation<
      any,
      resetProductDiscountPayload
    >({
      query: ({ body }) => ({
        url: `/customer-product-discount/reset-discounts`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['ProductsDiscounts'],
    }),
  }),
});

export const {
  useGetProductsDiscountsQuery,
  useAddProductDiscountMutation,
  useRemoveDiscountMutation,
  useToggleActiveDiscountMutation,
  useToggleStatusDiscountMutation,
  useUpdateContractProductDiscountMutation,
  useAddProducDiscountNoteMutation,
  useDeleteProductDiscountNoteMutation,
  useEditProductDiscountNoteMutation,
  useResetContractProductDiscountsMutation,
} = productsDiscountsApi;

export default productsDiscountsApi;
