import { useRestrictionsSingle } from '../../hooks/useRestrictionsSingle';
import { useRestrictionsHelpers } from '../../hooks/useRestrictionsHelpers';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { Box, Collapse, LinearProgress } from '@mui/material';
import { ICustomHeaderOption } from 'app/components/ManagedGrid/types';
import { DiscountIndex } from '../../types/discounts.types';

export const LockHeaderOption: ICustomHeaderOption = ({
  field,
  closeMenu,
  ComponentSuggested,
  sxSuggested,
}) => {
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);

  const i = useMemo(
    () => Number(field.match(/^r(\d+)$/i)!.pop()) as DiscountIndex,
    [field],
  );

  // null = no permission
  const discountHeaderTools = useRestrictionsHelpers(i);
  const restrictions = useRestrictionsSingle(i);

  return (
    <ComponentSuggested //
      sx={sxSuggested}
      disabled={!discountHeaderTools || busy}
      onClick={async (_) => {
        setBusy(true);
        await discountHeaderTools?.lock(!restrictions.lock);
        setBusy(false);
        closeMenu();
      }}
    >
      <Box flexGrow={1}>
        <Collapse in={busy}>
          <LinearProgress sx={{ width: '100%' }} />
        </Collapse>
        <Collapse in={!busy}>
          {t(`common.${restrictions.lock ? 'un' : ''}lock`)}
        </Collapse>
      </Box>
    </ComponentSuggested>
  );
};
