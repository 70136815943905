import { ADMIN, SALES_ADMINISTRATION } from 'app/pages/DiscountGroups/consts';
import useDiscountPermission from 'app/pages/DiscountGroups/hooks/useDiscountPermission';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { Permission } from 'hooks/Abilities/usePermission';
import { usePreferredAgent } from 'hooks/PreferredAgent/usePreferredAgent';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useRestrictionsPermission = () => {
  const can = useDiscountPermission();
  const authUser = useSelector(selectAuthUser);
  const { preferredAgent } = usePreferredAgent({ user: authUser });

  return useMemo<boolean>(() => {
    switch (preferredAgent?._role?.key) {
      case ADMIN:
      case SALES_ADMINISTRATION:
        return can(Permission.EDIT);
      default:
        return false;
    }
  }, [can, preferredAgent]);
};
