import React, { useMemo, useState } from 'react';
import { Divider, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import NestedMenuItem from 'mui-nested-menu-item';
import styled, { css } from 'styled-components';
import { CaretRight } from '@phosphor-icons/react';
import { DotsThree } from '@phosphor-icons/react';
import If from '../If';
import { red } from '@mui/material/colors';
import { Icon } from '../Icon';
import { useTheme } from 'common/hooks/useTheme';
import { ActionProps, NestedMenuProps, QuickActionProps } from './types';
import Each from 'common/UtilityComponents/Each';

export const NestedMenu: React.FC<NestedMenuProps> = ({
  icon,
  actions,
  hover,
  iconOpen,
  quickActions,
  customAnchor,
  menuProps,
  buttonProps,
  variant = 'squared',
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.type === 'touchend') return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconElement = useMemo(() => {
    return open ? iconOpen || icon || <DefaultIcon /> : icon || <DefaultIcon />;
  }, [icon, open, iconOpen]);

  const buttonSx = useMemo(() => {
    return {
      borderRadius: variant === 'rounded' ? '50%' : '4px',
      width: variant === 'rounded' ? '32px' : '18px',
      height: variant === 'rounded' ? '32px' : '18px',
      padding: variant === 'rounded' ? 'auto' : '0 !important',
      marginRight: '6px',
    };
  }, [variant]);

  const renderMenuItem = (action: ActionProps, index: number) => {
    if (!action.element) return null;

    const handleItemClick = () => {
      action.onClick?.();
      handleClose();
    };

    if (action.nested) {
      return (
        // @ts-ignore
        <NestedListItem
          key={index}
          label={action.element}
          parentMenuOpen={open}
          rightIcon={<CaretRight size={14} />}
          onClick={handleItemClick}
        >
          {action.actions?.map((nestedAction, nestedIndex) =>
            renderMenuItem(nestedAction, nestedIndex),
          )}
        </NestedListItem>
      );
    }

    return (
      <>
        <If condition={!!action.withSeparator}>
          <Divider
            orientation="horizontal"
            sx={{ height: '1px', margin: '0px 1rem !important' }}
          />
        </If>
        <ListItem
          key={index}
          onClick={handleItemClick}
          danger={!!action.danger}
        >
          {action.element}
        </ListItem>
      </>
    );
  };

  return (
    <>
      <If
        condition={!!quickActions?.length}
        otherwise={
          <If
            condition={!!customAnchor}
            otherwise={
              <IconButton
                className="menu-trigger-icon"
                size="small"
                onMouseEnter={(e) => hover && handleClick(e)}
                onClick={(e) => !hover && handleClick(e)}
                sx={buttonSx}
                {...buttonProps}
              >
                {iconElement}
              </IconButton>
            }
          >
            <div
              onClick={(e) => !hover && handleClick(e)}
              onMouseEnter={(e) => hover && handleClick(e)}
            >
              {customAnchor}
            </div>
          </If>
        }
      >
        <Stack
          direction="row"
          gap="2px"
          padding="4px"
          alignItems="center"
          justifyContent="center"
          className={`actions-menu-wrapper ${open ? 'open' : ''}`}
          sx={{
            border: '1px solid lightGrey',
            position: 'relative',
          }}
        >
          <Stack
            direction="row"
            gap="2px"
            className="quick-actions"
            alignItems="center"
          >
            <Each
              of={quickActions as QuickActionProps[]}
              render={(quickAction, index) => (
                <IconButton
                  key={index}
                  size="small"
                  onClick={(e) => quickAction?.onClick(e)}
                  sx={{
                    ...buttonSx,
                    borderRadius: '4px',
                    width: '18px',
                    height: '18px',
                    padding: '0 !important',
                    marginRight: '6px',
                  }}
                >
                  {quickAction.icon}
                </IconButton>
              )}
            />
          </Stack>
          <IconButton
            className="menu-trigger-icon"
            size="small"
            onMouseEnter={(e) => hover && handleClick(e)}
            onClick={(e) => !hover && handleClick(e)}
            sx={buttonSx}
            {...buttonProps}
          >
            {iconElement}
          </IconButton>
        </Stack>
      </If>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{ disablePadding: true }}
        {...menuProps}
      >
        {actions.map(renderMenuItem)}
      </Menu>
    </>
  );
};

const DefaultIcon = () => {
  const theme = useTheme();

  return <Icon icon={<DotsThree />} weight="bold" size={theme.dotsIconSize} />;
};

const ListItemCss = css`
  font-size: 0.8125rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.01063rem;
  min-width: 175px;
  padding: 0.75rem 1rem !important;
`;

const NestedListItem = styled(NestedMenuItem)`
  ${ListItemCss};
  width: 100%;
  justify-content: space-between !important;
`;

interface OptionProps {
  danger?: boolean;
}

const ListItem = styled(MenuItem)<OptionProps>`
  ${ListItemCss}

  color: ${(props) =>
    props.danger
      ? props.theme.redStatus
      : props.theme.textColorPrimary} !important;

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.danger ? red[50] : props.theme.rippleGrey} !important;
  }
`;
