import { IconButton, styled, Badge } from '@mui/material';
import { themes } from 'styles/theme/themes';
import AnimationFileComponent from '../AnimationFileComponent';
import { useState } from 'react';
import { pink } from '@mui/material/colors';
import { formatNumber } from '../../../utils/helpers';

interface IAnimationIconButtonProps {
  onClick: () => void;
  animation: object;
  count?: number;
  disabled?: boolean;
}

export const AnimationIconButton = ({
  onClick,
  animation,
  count,
  disabled,
}: IAnimationIconButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Badge
      badgeContent={formatNumber(count || 0)}
      color="secondary"
      overlap="circular"
      invisible={!count}
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: pink[500],
          color: themes.default.baseWhite,
          border: `1px solid ${themes.default.baseWhite}`,
          fontSize: '0.5rem',
          height: '11px',
          padding: '2px 4.5px',
          lineHeight: 'normal',
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Button onClick={onClick} disableRipple disabled={!!disabled}>
        <AnimationFileComponent
          animationData={animation}
          stopped={!isHovered}
          reverseAfter={900}
        />
      </Button>
    </Badge>
  );
};

const Button = styled(IconButton)`
  background-color: transparent;
  height: 26px;
  width: 26px;
  padding: 0;
  transition: all 0.3s;
`;
