import { useCallback, useMemo } from 'react';
import { useRestrictionsPermission } from './useRestrictionsPermission';
import { useRestrictMaxModal } from './useRestrictMaxModal';
import { useRestrictionsContextOf } from './useRestrictionsContextOf';
import { DiscountIndex, DiscountName } from '../types/discounts.types';
import {
  IRestrictions,
  RestrictionName,
  RestrictionValue,
} from '../types/restrictions.types';
import { RestrictionsCallApiHandler } from '../restrictions.consts';

/**
 * null means no permission
 * @param discountGroupId target discount group
 * @param ri target discount
 * @returns null if no permission
 */
export const useRestrictionsHelpers = (i: DiscountIndex) => {
  const ri = useMemo<DiscountName>(() => `r${i}`, [i]);

  const { show: showModal } = useRestrictMaxModal(i);
  const canRestrict = useRestrictionsPermission();

  const { value: restrictions, set: setRestrictions } =
    useRestrictionsContextOf('restrictions');
  const { value: apiCaller } = useRestrictionsContextOf('apiCaller');

  /** change all restrictions at once */
  const all = useCallback<RestrictionsCallApiHandler>(
    async (restrictionsUpdated, name?) => {
      const end = () => restrictions as IRestrictions;
      if (!canRestrict || !apiCaller) return end();
      const updated = await apiCaller(restrictionsUpdated, name);
      if (updated) setRestrictions(updated);
      return end();
    },
    [apiCaller, canRestrict, restrictions, setRestrictions],
  );

  /** change a single restriction */
  const single = useCallback(
    async (
      value: RestrictionValue,
      name: RestrictionName,
    ): Promise<IRestrictions | null> => {
      return await all({ [name]: { [ri]: value } }, name);
    },
    [all, ri],
  );

  /** set discount lock flag */
  const lock = useCallback(
    async (flag: boolean): Promise<IRestrictions | null> => {
      const updated = await single(flag, 'lock');
      if (!flag) showModal(true); // show with warning
      return updated;
    },
    [single, showModal],
  );

  /** set max discount value */
  const max = useCallback(
    async (rate: number): Promise<IRestrictions | null> => {
      return await single(rate, 'max');
    },
    [single],
  );

  const limit = useCallback(
    async (rate: number): Promise<IRestrictions | null> => {
      return await single(rate, 'limit');
    },
    [single],
  );

  const setMaxAndLimit = useCallback(
    async (
      rateMax: number,
      rateLimit: number,
    ): Promise<IRestrictions | null> => {
      return await all({ max: { [ri]: rateMax }, limit: { [ri]: rateLimit } });
    },
    [all, ri],
  );

  return useMemo(() => {
    if (!canRestrict) return null;
    return { all, single, lock, max, limit, setMaxAndLimit };
  }, [canRestrict, all, single, lock, max, limit, setMaxAndLimit]);
};
