import api from './api';

export const transactionsApi = api.injectEndpoints({
  endpoints: builder => ({
    getRefunds: builder.query<any, any>({
      query: queryParams => ({
        url: `/transactions/customer/refunds${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['CustomerPortalInvoices'],
    }),
    getInvoices: builder.query<any, any>({
      query: queryParams => ({
        url: `/transactions/customer/invoices${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['CustomerPortalInvoices'],
    }),
    getInvoicesByList: builder.query<any, any>({
      query: urlQuery => ({
        url: `/transactions/customer/invoices-by-list${urlQuery}`,
      }),
      providesTags: ['CustomerPortalInvoices'],
    }),
    getInvoicesCountByList: builder.query<any, string>({
      query: urlQuery => ({
        url: `/transactions/customer/invoices-count-by-list${urlQuery}`,
      }),
      providesTags: ['CustomerPortalInvoices'],
    }),
    patchTransactionBranch: builder.mutation<any, any>({
      query: ({ fileId, customerDeckId }) => ({
        url: `/transactions/customer/invoices/${fileId}`,
        method: 'PATCH',
        body: { customerDeckId },
      }),
      invalidatesTags: ['CustomerPortalInvoices'],
    }),
    getTransactionsPayments: builder.query<any, any>({
      query: queryParams => ({
        url: `/transactions/customer/payments${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['CustomerPortalInvoices'],
    }),
    getTransactionsDataByType: builder.query<any, any>({
      query: ({ type, queryParams }) => ({
        url: `/transactions/customer/${type}${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['Transactions'],
    }),
    getAdminInvoices: builder.query<any, any>({
      query: queryParams => ({
        url: `/transactions/invoices${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['Transactions'],
    }),
    getAdminRefunds: builder.query<any, any>({
      query: queryParams => ({
        url: `/transactions/refunds${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['Transactions'],
    }),
    updateArchiveInvoice: builder.mutation<any, any>({
      query: body => ({
        url: `/transactions/invoices/update-archive-value`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transactions'],
    }),
    getInvoicesAdminFacets: builder.query<any, any>({
      query: queryParams => ({
        url: `/transactions/invoices/status-facets${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['Transactions'],
    }),
    getInvoiceFacets: builder.query<any, any>({
      query: queryParams => ({
        url: `/transactions/customer/invoices-status-facets${queryParams}`,
        method: 'GET',
      }),
      providesTags: ['Transactions'],
    }),
    uploadInvoices: builder.mutation<any, any>({
      query: ({ file }) => ({
        url: `/transactions/invoices/upload-file`,
        method: 'POST',
        body: file,
      }),
      invalidatesTags: ['Transactions'],
    }),
    updateArchiveRefunds: builder.mutation<any, any>({
      query: body => ({
        url: `/transactions/refunds/update-archive-value`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transactions'],
    }),
    uploadRefunds: builder.mutation<any, any>({
      query: ({ file }) => ({
        url: `/transactions/refunds/upload-file`,
        method: 'POST',
        body: file,
      }),
      invalidatesTags: ['Transactions'],
    }),
    patchRefund: builder.mutation<any, any>({
      query: ({ refundId, body }) => ({
        url: `/transactions/refunds/${refundId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Transactions'],
    }),
  }),
});

export const {
  useGetRefundsQuery,
  useLazyGetRefundsQuery,
  useLazyGetInvoicesQuery,
  useGetInvoicesQuery,
  useGetInvoicesByListQuery,
  useLazyGetInvoicesByListQuery,
  useGetInvoicesCountByListQuery,
  usePatchTransactionBranchMutation,
  useGetTransactionsPaymentsQuery,
  useGetTransactionsDataByTypeQuery,
  useGetAdminInvoicesQuery,
  useGetAdminRefundsQuery,
  useUpdateArchiveInvoiceMutation,
  useGetInvoicesAdminFacetsQuery,
  useGetInvoiceFacetsQuery,
  useUploadInvoicesMutation,
  useUpdateArchiveRefundsMutation,
  useUploadRefundsMutation,
  usePatchRefundMutation,
} = transactionsApi;
