import api from './api';

export const userDocumentsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllDocuments: builder.query<any, string>({
      query: arg => `user-documents/list?${arg}`,
      providesTags: ['UserDocuments'],
    }),
    getDocumentsByUser: builder.query<any, any>({
      query: ({ id, arg }) => `user-documents/list/${id}?${arg}`,
      providesTags: ['UserDocuments'],
    }),
    createDocument: builder.mutation<any, any>({
      query: ({ id, newDocument }) => ({
        url: `user-documents/${id}`,
        method: 'POST',
        body: newDocument,
      }),
      invalidatesTags: ['UserDocuments'],
    }),
    updateDocument: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `user-documents/update/${id}?iam-checker=true`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['UserDocuments'],
    }),
    changeDocumentStatus: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `user-documents/status/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['UserDocuments'],
    }),
    deleteDocuments: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `user-documents/${id}/attachment?iam-checker=true`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['UserDocuments'],
    }),
    getFacetsAllDocuments: builder.query<any, string>({
      query: arg => `user-documents/facets?${arg}`,
      providesTags: ['UserDocuments'],
    }),
    getFacetsUserDocuments: builder.query<any, any>({
      query: id => `user-documents/facets/${id}?`,
      providesTags: ['UserDocuments'],
    }),
    getStatusesUserDocuments: builder.query<any, string>({
      query: id => `user-documents/statuses/${id}`,
      providesTags: ['UserDocuments'],
    }),
    archiveDocuments: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `user-documents/archive/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['UserDocuments'],
    }),
  }),
});

export const {
  useGetAllDocumentsQuery,
  useGetDocumentsByUserQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useChangeDocumentStatusMutation,
  useDeleteDocumentsMutation,
  useGetFacetsAllDocumentsQuery,
  useGetFacetsUserDocumentsQuery,
  useGetStatusesUserDocumentsQuery,
  useArchiveDocumentsMutation,
} = userDocumentsApi;

export default userDocumentsApi;
