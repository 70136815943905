export interface Accessory {
  _id: string;
  name: string;
  code: string;
  department: string;
  departmentOwner: string;
  warehouse: string;
  serialNumber: string;
  _supplierInfo: Supplier[];
  _accessoryResources: AccessoryResource[];
  brand: string;
  model: string;
  color: string;
  createdAt: string;
  updatedAt: string;
  price: number;
  unavailableReason: string;
  _assignee: any;
  _assigneeType: string;
  notes: string;
  status: AccessoryStatus | string;
  Status: AccessoryStatus | string;
  outofservice: boolean;
  qrCode: string;
  pictureUrl: { url: string; size: number; name: string }[];
  documentUrl: { url: string; size: number; name: string }[];
  _createdBy?: any;
  type: string;
}
export interface Supplier {
  _id?: string;
  legalName?: string;
  publicName?: string;
  phonebooks?: Array<{
    name?: string;
    email?: string;
    phone?: string;
  }>;
  activityCode?: number;
  status?: string;
  // Add other fields as necessary
}
export interface AccessoryResource {
  id: number;
  type: AccessoryType;
  _accessoryCategory: string;
  _id: string;
}
export enum AssigneeType {
  employee = 'employee',
  vehicle = 'vehicle',
}

export enum AccessoryStatus {
  available = 'available',
  outOfService = 'outofservice',
  inUse = 'inuse',
}

export enum AccessoryType {
  accessories = 'accessories',
  tools = 'tools',
  paymentCards = 'paymentcards',
}

export enum UnavailableReason {
  maintenance = 'maintenance',
  outOfService = 'outofservice',
  lost = 'lost',
  other = 'other',
}
