import React, { CSSProperties } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';

export const TimePickerForm = <T extends FieldValues>(
  props: UseControllerProps<T> & {
    label: string;
    name: string;
    disabled?: boolean;
    inputProps?: CSSProperties;
    required?: boolean;
  },
) => {
  const controllerProps = _.omit(props, ['label', 'inputProps']);
  const { t } = useTranslation();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController(controllerProps);
  const { formatError } = useErrorFormatter();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={t(props.label)}
        onChange={onChange}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={
              (!!props.required && !!error) || error?.message
                ? formatError({
                    ...error,
                    ref: { name: props.name, label: props.label },
                  })
                : ''
            }
            name={props.name}
            sx={{ width: '100%' }}
            required={!!props.required}
          />
        )}
        InputProps={{
          style: props.inputProps,
        }}
        disabled={!!props.disabled}
      />
    </LocalizationProvider>
  );
};

export default TimePickerForm;
