import { Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContractRowStatus, ContractRowStatusChipConfig } from '../../../types';

export const StatusChipCell = (params) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        params.value === ContractRowStatus.PENDING_APPROVAL
          ? t('client.contracts.pending.tooltip')
          : ''
      }
    >
      <Chip
        {...params}
        variant="outlined"
        label={t(`contract.status.${params.value}`)}
        sx={{
          border: 'none',
          height: '24px',
          background: ContractRowStatusChipConfig?.[params.value]?.fill,
          color: ContractRowStatusChipConfig?.[params.value]?.color,
        }}
      />
    </Tooltip>
  );
};
