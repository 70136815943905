import { Lock } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { Stack, SxProps, Typography } from '@mui/material';
import { useRestrictionsSingle } from '../hooks/useRestrictionsSingle';
import { DiscountIndex } from '../types/discounts.types';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useHelpers } from 'app/pages/Customers/components/ViewCustomer/components/Tabs/ContractsTab/useHelpers';

export type DiscountHeaderProps = {
  i: DiscountIndex;
  customTitle?: React.JSX.Element;
  titleSx?: SxProps;
  hasLimit?: boolean;
};
export const DiscountHeader = ({
  i,
  customTitle,
  titleSx,
  hasLimit = false,
}: DiscountHeaderProps) => {
  const restrictions = useRestrictionsSingle(i);
  const { t } = useTranslation();

  const { formatDiscountValue } = useHelpers();

  const hintText = useMemo(() => {
    const max = formatDiscountValue(restrictions.max);
    const limit = formatDiscountValue(restrictions.limit);

    const maxStr = max < 100 ? `max=${max}%` : '';
    const limitStr = limit < 100 ? `lim=${limit}%` : '';

    if (hasLimit && max < 100) {
      if (max === limit) {
        return `(${maxStr}, ${limitStr})`;
      }
      if (max > limit) {
        return `(${[maxStr, limitStr].join(', ')})`;
      }
    }

    if (limitStr) return `(${limitStr})`;
    if (maxStr) return `(${maxStr})`;
  }, [formatDiscountValue, hasLimit, restrictions.limit, restrictions.max]);

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {!!restrictions.lock && <Icon icon={<Lock />} size={16} />}

      <Typography variant="body2" sx={titleSx}>
        {customTitle || t('discount_abbrev') + i}
      </Typography>

      {(hasLimit || restrictions.max < 1) && (
        <Typography
          variant="caption"
          color={titleSx ? 'text.secondary' : ''}
          sx={titleSx}
        >
          {hintText}
        </Typography>
      )}
    </Stack>
  );
};
