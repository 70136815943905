import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Badge } from '@mui/material';
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  Money,
} from '@phosphor-icons/react';
import If from 'app/components/If';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

function IconTypeCell(params: CustomCellRendererProps) {
  const type = params?.value?.typeData;

  return (
    <TypeContainer>
      <Badge
        badgeContent={
          <If
            condition={type === 'invoice'}
            otherwise={
              <IconContainer>
                <ArrowCircleRight
                  size={18}
                  color={themes?.default?.chipRedBg}
                  weight="fill"
                />
              </IconContainer>
            }
          >
            <IconContainer>
              <ArrowCircleLeft
                size={18}
                color={themes?.default?.green3}
                weight="fill"
              />
            </IconContainer>
          </If>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Money
          color={themes?.ordoria.primary}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      </Badge>
    </TypeContainer>
  );
}

export default IconTypeCell;

const TypeContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themes?.ordoria?.primaryBackgroundColor};
`;

const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${themes?.default?.accordionWhiteBg};
`;
