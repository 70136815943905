import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  FormControlLabel,
  Switch,
  Button,
  TextField,
  Stack,
  Box,
} from '@mui/material';

export default function ChorusForm({ isActive, serviceCode, onSubmit }) {
  const { t } = useTranslation();
  const methods = useForm({
    //mode: 'onChange',
    //reValidateMode: 'onBlur',
    defaultValues: {
      chorusIsActive: isActive,
      chorusServiceCode: serviceCode,
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const isChorusActive = watch('chorusIsActive');
  const formIsOk = isChorusActive && !isEmpty(watch('chorusServiceCode'));

  useEffect(() => {
    if (isDirty) {
      if (!isChorusActive) {
        onSubmit({
          chorusIsActive: false,
        });
      } else if (isChorusActive && formIsOk) {
        onSubmit({
          chorusIsActive: true,
        });
      }
    }
  }, [isChorusActive]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="4px">
        <Controller
          name="chorusIsActive"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              label="Active"
              control={<Switch {...field} checked={isChorusActive} />}
              sx={{
                '& .MuiTypography-root': { fontSize: '0.875rem' },
              }}
            />
          )}
        />

        {isChorusActive && (
          <Stack alignItems="flex-start" gap="10px">
            <Controller
              name="chorusServiceCode"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  label="Code de service"
                  {...field}
                  required
                  sx={{
                    marginBottom: '0 !important',
                  }}
                />
              )}
            />

            <Box>
              <Button
                disabled={!formIsOk}
                variant="contained"
                disableElevation
                size="small"
                type="submit"
              >
                {t('common.buttons.save')}
              </Button>
            </Box>
          </Stack>
        )}
      </Stack>
    </form>
  );
}
