import {
  Alert,
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  useGetWarehousesQuery,
  useLazyGetConfigurationQuery,
} from 'common/services/organizationApi';
import {
  useFileImportMutation,
  useImportFileValidateMutation,
  useLazyGetRoundTripsClusterQuery,
  usePostInitMutation,
} from 'common/services/roundtripApi';
import styled from 'styled-components';
import {
  checkExistingRoundtrips,
  triggerClusterByMode,
} from 'app/components/RoundTrips/function';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { useCheckWarehouses } from 'hooks/RoundTrips/useCheckWarehouses';
import { useModel } from '../useModel';
import { TabsContext } from 'app/pages/RoundtripsTabs';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { ApiIntegration } from '../ApiIntegration';
import { useModal } from 'app/components/Dialog/hooks';
import AddByFileForm from 'app/components/AddByFile/AddByFileForm';
import { useToaster } from 'hooks/useToaster';
import { updateFormData } from 'utils/upload/updateFormFileType';
import { ROUNDTRIP_ADD } from 'utils/routes';
import { useCountRoundtrip } from '../../../hooks/useCountRoundtrip';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useSubscribeObject } from 'common/hooks/ws';
import { useSelector } from 'react-redux';
import { selectOrganization } from 'common/store/organization/selectors';

export interface Props {
  allRoundtrip?: any;
  roundTripCopy?: any;
  warehouseId: any;
  dateValue: Dayjs | null;
  setWarehouse: Dispatch<
    SetStateAction<{
      code: string;
      location: string;
      name: string;
      type: string;
      _id: string;
    }>
  >;
  warehouse: {
    code: string;
    location: string;
    name: string;
    type: string;
    _id: string;
    shortName?: string;
  };
  begin: string;
  end: string;
  open: boolean;
  warehouseMainId: string;
  handleClose: () => void;
}
export const Existing: React.FC<Props> = ({
  dateValue,
  setWarehouse,
  warehouse,
  begin,
  end,
  warehouseMainId,
  warehouseId,
  open,
  handleClose,
  roundTripCopy,
  allRoundtrip,
}) => {
  const { t, i18n } = useTranslation();
  const [getConfirguration, { data: warehouses }] =
    useLazyGetConfigurationQuery();
  const { data: warehouseDataFetched } = useGetWarehousesQuery('');
  const organization = useSelector(selectOrganization);

  const subscribedOrganization = organization?.id;

  const rawMessage = useSubscribeObject(
    'organization-decks',
    subscribedOrganization,
  );
  const wsDecksMessage = rawMessage ? JSON.parse(rawMessage) : null;

  const [warehouseData, setWarehouseData] = useState({
    warehouses: Array.isArray(warehouses) ? warehouses : [],
  });

  useEffect(() => {
    if (
      warehouseDataFetched &&
      Array.isArray(warehouseDataFetched.warehouses)
    ) {
      setWarehouseData(warehouseDataFetched);
    } else {
      console.error(
        'Invalid warehouseDataFetched structure:',
        warehouseDataFetched,
      );
    }
  }, [warehouseDataFetched]);

  useEffect(() => {
    if (warehouseDataFetched) {
      setWarehouseData(warehouseDataFetched);
    }
  }, [warehouseDataFetched]);

  useEffect(() => {
    if (
      wsDecksMessage?.objectType === 'organization-decks' &&
      wsDecksMessage?.data?.deckId
    ) {
      const { deckId, status } = wsDecksMessage.data;

      setWarehouseData((prev) => {
        const updatedWarehouses = prev.warehouses.map((item) =>
          item.id === deckId ? { ...item, status } : item,
        );
        return { ...prev, warehouses: updatedWarehouses };
      });
    }
  }, [rawMessage]);

  const [trigger, { data: automaticRoundtrips, isFetching }] =
    useLazyGetRoundTripsClusterQuery();

  const [importValidate] = useImportFileValidateMutation();
  const [importRoundtrip] = useFileImportMutation();
  const {
    disabledProceed,
    setDisabledProceed,
    alreadyExists,
    setAlreadyExists,
    isLoading,
    defaultWarehouse,
    setIsLoading,
    setDefaultWarehouse,
    handleCloseActivate,
    setSelectedIndex,
  } = useModel(handleClose, warehouses);
  const wareHousesProps = {
    options: Array.isArray(warehouseData?.warehouses)
      ? warehouseData.warehouses.filter((item) => item.status !== 'inactive')
      : [],
    getOptionLabel: (option) => option.name,
  };
  const defaultWarehouseExists = wareHousesProps.options.some(
    (warehouse) => warehouse._id === defaultWarehouse?._id,
  );
  const updatedDefaultWarehouse = defaultWarehouseExists
    ? defaultWarehouse
    : null;
  const result = warehouseMainId
    ? wareHousesProps?.options?.find((item) => item._id === warehouseMainId)
    : updatedDefaultWarehouse;
  const [warehouseValue, setWarehouseValue] = useState(result || null);

  useEffect(() => {
    setWarehouseValue(result || null);
  }, [result]);
  const tabsContext = useContext(TabsContext);
  const userContext = tabsContext?.userContext || AgentCategories.Logistic;

  const { openModal, closeModal } = useModal();
  const toast = useToaster();

  const [modalOpen, setModalOpen] = useState(open);
  const [radio, setRadio] = useState('API');
  const [dateSyncedAt, setDateSyncedAt] = useState('');
  const [openApi, setOpenApi] = useState(false);
  const [countRoundtrip, setCountRoundtrip] = useState(0);
  useCountRoundtrip({
    roundTripCopy,
    warehouse,
    allRoundtrip,
    setCountRoundtrip,
  });
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadio((event.target as HTMLInputElement).value);
  };

  const handleImportDone = (resp) => {
    if (!resp.clusterId) {
      toast(5000, 'error', 'alerts.orders.import_byFile_failed');
      return;
    }
    toast(5000, 'success', 'alerts.orders.import_byFile_success');

    const url = new URL(ROUNDTRIP_ADD, window.location.origin);
    url.searchParams.append('warehouse', JSON.stringify(warehouse));
    url.searchParams.append('dateValue', JSON.stringify(dateValue));
    url.searchParams.append('cluster', JSON.stringify(resp.clusterId));
    url.searchParams.append('resultNbr', countRoundtrip + '');
    url.searchParams.append('userContext', AgentCategories.Logistic);
    url.searchParams.append('mode', 'byFile');
    window.open(url.toString(), '_blank');
  };

  const addByFile = () => {
    openModal({
      title: t('add-by-file.dialog.title'),
      content: (
        <AddByFileForm
          onValidate={async (props) => {
            props?.append('beginAt', begin);
            props?.append('endAt', begin);
            props?.append('update', 'false');
            props?.append('role', AgentCategories.Logistic);
            return await importValidate(props).unwrap();
          }}
          onImport={async (props) => {
            const formData = updateFormData(props);
            formData?.append('beginAt', begin);
            formData?.append('endAt', begin);
            formData?.append('update', 'false');
            formData?.append('role', AgentCategories.Logistic);
            const resp = await importRoundtrip(formData).unwrap();
            handleImportDone(resp);
          }}
          module="order"
          sampleFile={{
            filename: 'roundtrip-sample.csv',
            href: '/csv/roundtrip-sample.csv',
          }}
          defaultWarehouse={warehouse}
          backAction={() => {
            closeModal();
            setModalOpen(true);
          }}
        />
      ),
      cancel: true,
      action: {
        actionText: t('common.buttons.proceed'),
        actionCallback() {},
        buttonProps: { disabled: true },
      },
    });
  };

  const handleProceed = () => {
    if (radio === 'Upload') {
      handleCloseActivate();
      setModalOpen(false);
      addByFile();
      return;
    }

    setOpenApi(true);
    requestWarehouseData();
  };

  const [postInit] = usePostInitMutation();

  const requestWarehouseData = async () => {
    try {
      const resp = await postInit({
        begin: '',
        end: '',
        clusterId: '',
        _deck: warehouse?._id,
      }).unwrap();
      if (resp) {
        const locale = i18n?.language ?? 'en';
        const dataSyncedAt = resp?.deck?.dataSyncedAt;
        const formattedDate = dataSyncedAt
          ? dayjs(dataSyncedAt)
              .locale(locale)
              .format(`dddd DD/MM/YYYY [${t('common.at')}] hh:mma`)
          : t('never_synced');
        if (setDateSyncedAt) setDateSyncedAt(formattedDate);
      }
    } catch (e: any) {}
  };

  useEffect(() => {
    getConfirguration();
  }, [getConfirguration]);

  const selectedMode = useMemo(() => {
    return radio === 'Upload' ? 'byFile' : 'automatic';
  }, [radio]);

  useCheckWarehouses({
    triggerByMode: trigger,
    begin,
    end,
    roundTripsByMode: automaticRoundtrips,
    openActivate: open || modalOpen,
    warehouse,
    setDisabledProceed,
    setAlreadyExists,
    mode: selectedMode,
    setIsLoading,
    warehouses,
    setDefaultWarehouse,
    setWarehouse,
    warehouseId,
    isFetching,
    userContext: AgentCategories.Logistic,
  });

  return (
    <>
      <TwoViewModal
        onOpen={open || modalOpen}
        onClose={() => {
          handleCloseActivate();
          setModalOpen(false);
        }}
        title={
          t('cluster_will_be_type_logistic') +
          ' ' +
          `(${dayjs(dateValue).format('D MMM. YYYY')})`
        }
        width="550px"
      >
        <DialogContent>
          <AlertContainer
            style={{ visibility: isFetching ? 'hidden' : 'visible' }}
          >
            {!alreadyExists && !!warehouses?.decks?.length && (
              <Alert variant="standard" severity="info" sx={{ width: '100%' }}>
                {t('cluster_will_be_type_logistic')}
              </Alert>
            )}
            {alreadyExists && (
              <Alert variant="standard" severity="error" sx={{ width: '100%' }}>
                {t('drawer.modal.roundtrips_already_exist')}
              </Alert>
            )}
            {!warehouses?.decks?.length && (
              <Alert
                variant="standard"
                severity="error"
                sx={{ width: '480px' }}
              >
                {t('drawer.modal.roundtrip_no_warehouses')}
              </Alert>
            )}
          </AlertContainer>
          <DialogContentText id="alert-dialog-description">
            <Autocomplete
              disabled={!warehouses?.decks?.length}
              defaultValue={warehouseValue}
              style={{ flex: 1, marginTop: '25px' }}
              {...wareHousesProps}
              autoComplete={false}
              onChange={async (e, value) => {
                if (setDateSyncedAt) setDateSyncedAt('');
                setAlreadyExists(false);
                setDisabledProceed(true);
                setWarehouse(value || '');

                if (value && value.name) {
                  const selectedIndex = warehouses?.decks.findIndex(
                    (deck) => deck._id === value._id,
                  );

                  setSelectedIndex(selectedIndex);
                  setDisabledProceed(true);
                  setIsLoading(true);
                  await triggerClusterByMode(
                    trigger,
                    selectedMode,
                    value?._id,
                    begin,
                    end,
                    userContext,
                  );
                  if (
                    automaticRoundtrips &&
                    automaticRoundtrips?.docs?.[0]?._deck?._id === value?._id &&
                    !warehouse?._id
                  ) {
                    checkExistingRoundtrips({
                      warehouse,
                      setDisabledProceed,
                      setAlreadyExists,
                      mode: selectedMode,
                      data: automaticRoundtrips,
                      setIsLoading,
                    });
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="Warehouse"
                  size="small"
                  label={t('select_warehouse')}
                  variant="outlined"
                  required
                />
              )}
            />
          </DialogContentText>
          <DialogContentText>
            <RadioGroup
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                marginLeft: '11px',
              }}
              aria-labelledby="demo-radio-buttons-group-label"
              value={radio}
              onChange={handleChangeRadio}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="API"
                control={<Radio />}
                label={t(`api_integration`)}
              />
              <FormControlLabel
                value="Upload"
                control={<Radio />}
                label={t(`upload_mass_files`)}
              />
            </RadioGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'right',
            width: '100%',
          }}
          sx={{ padding: 0 }}
        >
          <Button
            onClick={() => {
              handleCloseActivate();
              setModalOpen(false);
            }}
            color="inherit"
          >
            {t('common.buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              handleCloseActivate();
              setModalOpen(false);
              handleProceed();
            }}
            disabled={disabledProceed}
            variant="contained"
            autoFocus
            sx={{ height: '33px', width: '88px' }}
          >
            {isLoading ? (
              <LoadingContainer>
                <LoadingPulse />
              </LoadingContainer>
            ) : (
              t('common.buttons.proceed')
            )}
          </Button>
        </DialogActions>
      </TwoViewModal>

      <ApiIntegration
        dateValue={dateValue}
        open={openApi}
        warehouse={warehouse}
        begin={begin}
        end={end}
        dateSyncedAt={dateSyncedAt}
        handleClose={() => {
          setOpenApi(false);
        }}
      />
    </>
  );
};

export const AlertContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const LoadingContainer = styled.div`
  padding: 6px;
  margin-left: -10px;
`;
