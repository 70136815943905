import { appActions } from 'common/store/app';
import { StatusToastVariant } from 'common/types/StatusToasterNotification';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

interface StatusToastProps {
  variant: StatusToastVariant;
  duration?: number;
  message?: string;
  spacingBottom?: string;
  spacingRight?: string;
}

export const useStatusToaster = () => {
  const dispatch = useDispatch();

  const statusToast = useCallback(
    ({
      variant,
      duration = 2000,
      message = '',
      spacingBottom = '-2px',
      spacingRight = '362px',
    }: StatusToastProps) => {
      dispatch(
        appActions.setLastStatusToasterNotification({
          duration: duration,
          variant: variant,
          message: message,
          spacingBottom,
          spacingRight,
        }),
      );
    },
    [dispatch],
  );

  return statusToast;
};
