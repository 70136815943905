import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { Question } from '@phosphor-icons/react';
import { Icon } from '../../../../components/Icon';
import { useTranslation } from 'react-i18next';

function RountripMappingInfoSection() {
  const { t } = useTranslation();
  return (
    <Stack width="334px">
      <Stack direction="row" alignItems="center" gap="4px">
        <Typography fontSize="0.9rem" fontWeight="500" letterSpacing="0.1px">
          {t('roundtrip.mapping.title')}
        </Typography>
        <Icon icon={<Question />} tooltip="Logistics roundtrips only" />
      </Stack>
      <Stack pt="18px">
        <Typography
          fontSize="12px"
          lineHeight="19.2px"
          fontWeight="400"
          letterSpacing="0.15px"
        >
          {t('roundtrip.mapping.info')}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default RountripMappingInfoSection;
