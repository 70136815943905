import { useGetProductsDiscountsQuery } from 'common/services/productsDiscountsApi';
import { useEffect, useMemo, useRef } from 'react';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { IToolbarConfig } from 'app/components/ManagedGrid/types';
import { createPortal } from 'react-dom';
import { ManagedGridActions } from 'app/components/ManagedGrid/components/ManagedGridActions';
import { useQueryArray } from 'common/hooks/useQueryArray';
import { useParams } from 'react-router-dom';
import { Offer } from 'common/types/Customer';
import ManagedGrid from 'app/components/ManagedGrid';
import { INoDataConfig } from 'app/components/ManagedGrid/types';
import NoItemsImage from 'assets/img/customers/Contracts/NoItemsImage.svg';
import {
  Contract,
  UpdateCategoryDiscounts,
} from '../../../../ContractsTab/types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { RowClassRules } from '@ag-grid-community/core';
import { useHelpers as useProductDiscountHelper } from '../useHelpers';
import { StateSetter } from 'types';
import { AddDiscountProduct } from './AddDiscountProduct/AddDiscountProduct';
import { Wrapper } from '../../../components/ContractsGrid';
import { RestrictMaxModal } from 'app/components/Restrictions/RestrictMaxModal';
import { useCallback } from 'react';
import {
  DiscountIndex,
  DiscountName,
} from 'app/components/Restrictions/types/discounts.types';
import { ProductsDiscountsFilter } from './Filters/ProductsDiscountsFilter';
import { getColumnConfig } from '../config';
import { getColumnDefs } from 'app/components/ManagedGrid/helpers';
import { useHelpers as useContractGridHelper } from '../../../../ContractsTab/useHelpers';
import { useRestrictionsPermission } from 'app/components/Restrictions/hooks/useRestrictionsPermission';
import { MinMarginModal } from 'app/components/ManagedGrid/components/CustomHeaderModals';
import { useEditCustomerMinMarginMutation } from 'common/services/customerApi';
import { useRestrictions } from '../../hooks/useRestrictions';

export interface Category {
  name: string;
  _id: string;
  products: Offer[];
  key: string;
}

interface Props {
  selectedTab: Contract;
  rowsEditedBySales: UpdateCategoryDiscounts[];
  setRowsEditedBySales: StateSetter<UpdateCategoryDiscounts[]>;
  minMargin?: number;
}

export const ProductsDiscountsGrid = ({
  selectedTab,
  rowsEditedBySales,
  setRowsEditedBySales,
  minMargin,
}: Props) => {
  const [updateCustomerMinMargin] = useEditCustomerMinMarginMutation();
  const isAdmin = useRestrictionsPermission();
  const { getProcessedProductsFunction } = useProductDiscountHelper();
  const gridActionsRef = useRef<HTMLElement | null>(null);
  const { id: customerId } = useParams();
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const gridContext = useManagedContext<IGridContext>('grid');
  const {
    queryParams: { search, order, filters, orderBy },
  } = gridContext;
  const { levelData } = useContractGridHelper();
  const { initiateRestrictions } = useRestrictions();
  const sort = orderBy ? `${order}${orderBy}` : 'cache._product.name';

  const rowClassRules: RowClassRules = useMemo(
    () => ({
      'disabled-row': (params) => {
        return !params.data?.__actions.isActive;
      },
    }),
    [],
  );
  const categoriesFetchQueryParams = useQueryArray({
    categories: [
      ...(filters?.parentCategories?.map((cat) => cat?.value) || []),
      ...(filters?.subCategories?.map((cat) => cat?.value) || []),
      ...(filters?.childCategories?.map((cat) => cat?.value) || []),
    ],
    _customer: customerId,
    text: search,
    sort: sort,
    hasNotes: !!filters?.hasNotes,
    lastEditedBy: filters?.lastEditedBy
      ? filters?.lastEditedBy?.map((option) => option?.value)
      : [],
    status: filters?.status?.map((cat) => cat?.value) || [],
  });

  const {
    data: ContractsData,
    isLoading,
    isFetching,
  } = useGetProductsDiscountsQuery(categoriesFetchQueryParams, {
    skip: !customerId,
  });

  const can = usePermission('customers');

  useEffect(() => {
    initiateRestrictions(selectedTab.key);
  }, [selectedTab.key]);

  const gridData = useMemo(() => {
    if (ContractsData?.docs?.length) {
      return ContractsData.docs;
    } else {
      return [];
    }
  }, [ContractsData?.docs]);

  const checkMaxExceeded = useCallback(
    (max: number, i: DiscountIndex) => {
      const ri: DiscountName = `r${i}`;
      return gridData.some((row) => (row?.discounts?.[ri] || 0) > max / 100);
    },
    [gridData],
  );

  useEffect(() => {
    gridActionsRef.current = document.getElementById('contracts-grid-actions');
  }, []);

  useEffect(() => {
    if (isLoading || isFetching) {
      gridContext.updateDataWithFunction((prev) => {
        prev.loading = isFetching;
      });
      return;
    }
    if (!ContractsData || !ContractsData.docs) return;

    gridContext.updateDataWithFunction((prev) => {
      const onClick = () =>
        gridContext.updateDataWithFunction((prev) => {
          prev.extraData.openMarginModal = true;
        });
      const columnConfig = getColumnConfig(
        t,
        isAdmin,
        levelData?.maxLevel,
        minMargin,
        onClick,
      );
      const processedProducts = getProcessedProductsFunction({
        data: ContractsData.docs,
        rowsEditedBySales,
        setRowsEditedBySales,
      });
      const defs = getColumnDefs(processedProducts, columnConfig);

      prev.totalRows = ContractsData.docs.length;
      prev.loading = isLoading || isFetching;
      prev.rowData = processedProducts;
      prev.columnDefs = defs;
    });
  }, [ContractsData, isLoading, isFetching, minMargin, rowsEditedBySales]);

  const noDataConfig = useMemo<INoDataConfig>(() => {
    return {
      icon: NoItemsImage,
      onClick: () => {
        gridContext.updateDataWithFunction((prev) => {
          prev.extraData.openAddModal = true;
        });
      },
      text: t('product_discounts.empty_title'),
      textStyle: {
        fontSize: '1rem',
        fontWeight: '500',
        lineHeight: '26px',
        letterSpacing: '0.15px',
        textAlign: 'center',
        color: theme.black2,
        margin: '0px',
        padding: '0px',
      },
      description: t('product_discounts.empty_subtitle'),
      textButton: t('add_product_discount'),
      buttonStyle: { width: 'fit-content' },
      containerStyle: { marginTop: '-16px' },
      emptyCenterStyle: { top: '50%' },
    };
  }, [selectedTab]);

  const toolbarConfig = useMemo<IToolbarConfig>(() => {
    return {
      disabled: gridContext.disableToolBar || !can(Permission.EDIT),
      onAddClick: () =>
        gridContext.updateDataWithFunction((prev) => {
          prev.extraData.openAddModal = true;
        }),
      FilterComponent: ProductsDiscountsFilter,
      BulkActionsComponent: undefined,
    };
  }, [gridContext.disableToolBar]);

  const onActionClick = async (val) => {
    await updateCustomerMinMargin({
      customerId: customerId || '',
      body: {
        minMargin: val,
      },
    }).unwrap();
  };

  return (
    <Wrapper>
      <ManagedGrid
        toolbarConfig={toolbarConfig}
        noDataConfig={noDataConfig}
        rowClassRules={rowClassRules}
      />
      <AddDiscountProduct
        open={gridContext.extraData.openAddModal}
        onClose={() =>
          gridContext.updateDataWithFunction((prev) => {
            prev.extraData.openAddModal = false;
          })
        }
      />
      <RestrictMaxModal
        title="discounts.action_limit_max"
        hasLimit={true}
        checkMaxExceeded={checkMaxExceeded}
      />
      <MinMarginModal
        defaultVal={minMargin ? minMargin * 100 : 0}
        onActionCall={onActionClick}
        title="productDisconts.edit_marign"
        onClose={() =>
          gridContext.updateDataWithFunction((prev) => {
            prev.extraData.openMarginModal = false;
          })
        }
        open={gridContext.extraData.openMarginModal}
      />

      {gridActionsRef?.current &&
        createPortal(
          <ManagedGridActions {...(toolbarConfig || {})} />,
          gridActionsRef.current,
        )}
    </Wrapper>
  );
};
