import api from './api';

export const agentsApi = api.injectEndpoints({
  endpoints: builder => ({
    getBasicAgentsList: builder.query<any, any>({
      query: urlQuery => ({
        url: `/agents/basic/list${urlQuery}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetBasicAgentsListQuery } = agentsApi;
