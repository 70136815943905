import React, { useState } from 'react';
import { Button, Grid, Typography, Box, Alert } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

import { VisitorsForm } from '../../../VisitorsPublic/components/VisitorsForm';

export const VisitorsCancelVisitDetails = ({
  organization,
  visit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [displayForm, setDisplayForm] = useState(false);
  const isCanceled = visit?.status === 'canceled';

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {isCanceled && displayForm ? (
        <VisitorsForm visit={visit} organization={organization} />
      ) : (
        <>
          <Box
            sx={{
              width: '100%',
              maxWidth: '563px',
              height: 'auto',
              padding: '16px 32px',
              borderRadius: '12px',
              marginBottom: '27px',
              border: `0.5px solid ${themes.default.silver}`,
              alignItems: 'center',
              backgroundColor: `${themes.default.accordionWhiteBg}`,
            }}
          >
            {isCanceled && (
              <Alert
                severity="error"
                sx={{
                  mb: '16px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('visitor.cancel-appointment')}
              </Alert>
            )}

            <Grid container>
              <Grid item sx={{ mr: '8px', mb: '8px' }}>
                <Typography fontWeight={500}>{t('common.date')}</Typography>
                <Typography fontWeight={500}>
                  {t('visitor.person-to-meet')}
                </Typography>
                <Typography fontWeight={500}>
                  {t('visitor.visit-reason')}
                </Typography>
                <Typography fontWeight={500}>
                  {t('comments.comment')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  :{' '}
                  {visit?.plannedAt
                    ? dayjs(visit.plannedAt)
                        .format('D MMM YYYY [at] h:mmA')
                        .replace('.', ', ')
                    : '-'}
                </Typography>
                <Typography>
                  :{' '}
                  {visit?.cache?._relatedAgent?.firstName ||
                  visit?.cache?._relatedAgent?.lastName
                    ? `${visit?.cache?._relatedAgent?.firstName || ''} ${visit?.cache?._relatedAgent?.lastName || ''}`
                    : '-'}
                </Typography>
                <Typography>: {t(visit?.reason) || '-'}</Typography>
                <Typography>: {visit?.comment || '-'}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              onClick={isCanceled ? () => setDisplayForm(true) : onCancel}
              sx={{
                background: isCanceled
                  ? themes.default.azurePurple
                  : themes.default.red300,
                color: `${themes.default.accordionWhiteBg}`,
                width: '168px',
                height: '45px',
                borderRadius: '12px !important',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                boxShadow: `0px 8px 12px ${themes.default.purpleTint}`,
                '&:hover': {
                  background: isCanceled
                    ? themes.default.azurePurple
                    : themes.default.red300,
                },
              }}
            >
              {isCanceled
                ? t('visitor.new-appointment')
                : t('visitor.cancel-my-visit')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
