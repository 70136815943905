/* eslint-disable @typescript-eslint/no-unused-vars */
import { Collapse } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { themes } from 'styles/theme/themes';
import { Category } from '../..';
import styled from 'styled-components';
import { CatalogContext } from 'app/pages/Ordoria/Catalog';
import { useLazyGetProductsByCategoryQuery } from 'common/services/productApi';
import { useQueryParams } from 'hooks/useQueryParams';
import Each from 'common/UtilityComponents/Each';
import { ProductCard } from '../ProductCard';
import { useOnScreen } from 'app/components/ProductCard/hook';
import { useDebounce } from '@uidotdev/usehooks';
import { getCartVisibility } from 'common/store/cart/selectors';
import { useSelector } from 'react-redux';
import { GroupHeader } from 'app/components/ManagedGridList/components/GroupHeader';

export const CardCollapsableCardCategory = ({
  category,
  expandedByDefault = false,
}: {
  category: Category;
  expandedByDefault?: boolean;
}) => {
  /* ------------------------------ Use States ------------------------------ */
  const [expanded, setExpanded] = useState<boolean>(expandedByDefault || false);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [selectedCategoryProducts, setSelectedCategoryProducts] = useState<
    any[]
  >([]);
  const [products, setProducts] = useState<any[]>([]);

  /* ------------------------------- Use Refs ------------------------------- */
  const gridEndRef = useRef<any>(null);

  /* ------------------------------- Variables ------------------------------ */
  const cartIsVisible = useSelector(getCartVisibility);
  const debouncedCartIsVisible = useDebounce(cartIsVisible, 300);

  /* -------------------------------- Contexts ------------------------------ */
  const { setSelectedProducts } = useContext(CatalogContext);

  /* --------------------------------- Hooks -------------------------------- */
  const isScrollEndReached = useOnScreen(gridEndRef);
  const urlQuery = useQueryParams({
    sort: 'code',
    page: page,
    limit: limit,
  });

  /* ------------------------------ API Calls ------------------------------- */
  const [triggerGetProducts, { data: productsData, isLoading, isFetching }] =
    useLazyGetProductsByCategoryQuery();

  /* ------------------------------ Use Effects ----------------------------- */
  useEffect(() => {
    if (productsData?.docs) {
      setProducts((prevState) => [...prevState, ...productsData.docs]);
    }
  }, [productsData]);

  useEffect(() => {
    if (!expanded || !category?._id) return;
    triggerGetProducts({
      categoryId: category?._id || '',
      urlQuery: urlQuery,
    });
  }, [expanded, category, urlQuery]);

  /**
   * Add selected products of current category to
   * selectedProducts.categories object
   *
   * Each category has an object in selectedProducts.categories
   * with key equal to its id and the value is an array of product ids.
   */
  useEffect(() => {
    if (!category?._id) return;
    setSelectedProducts((prevState) => ({
      ...prevState,
      categories: {
        ...(prevState?.categories || {}),
        [category._id]: selectedCategoryProducts,
      },
    }));
  }, [selectedCategoryProducts]);

  useEffect(() => {
    if (expanded && isScrollEndReached && products?.length > 0) {
      handleLoadMoreRows();
    }
  }, [isScrollEndReached]);

  /* ------------------------------- Functions ------------------------------ */
  const handleLoadMoreRows = useCallback(() => {
    if (products?.length < (category?.productsCount || 0)) {
      setPage((prev) => prev + 1);
    }
  }, [products, category]);

  const handleCategoryExpand = () => {
    setExpanded((prevState) => {
      return !prevState;
    });
  };

  const handleSelectionChange = useCallback(
    (value) => {
      setSelectedCategoryProducts((prev) => {
        const alreadySelected = prev.includes(value);

        if (alreadySelected) {
          return prev.filter((id) => id !== value);
        } else {
          return [...prev, value];
        }
      });
    },
    [setSelectedCategoryProducts],
  );

  /* ------------------------------------------------------------------------ */
  return (
    <GridWrapper>
      <TitleContainer headerBackground={themes?.default?.roundtripGreyBg}>
        <GroupHeader
          onClick={handleCategoryExpand}
          title={category?.name}
          itemsCount={category?.productsCount || 0}
          expanded={expanded}
        />
      </TitleContainer>

      <Collapse in={expanded}>
        <BodyContainer
          spacingTop={8}
          cartIsVisible={cartIsVisible || debouncedCartIsVisible}
        >
          <Each
            of={products || []}
            render={(product, index) => (
              <ProductCard
                key={index}
                product={product}
                categoryId={category?._id}
                handleSelectionChange={handleSelectionChange}
              />
            )}
          />
        </BodyContainer>
      </Collapse>
      <div ref={gridEndRef} />
    </GridWrapper>
  );
};

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TitleContainer = styled.div<{ headerBackground }>`
  background: ${(props) => props.headerBackground};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`;

const BodyContainer = styled.div<{
  spacingTop: number;
  cartIsVisible?: boolean;
}>`
  padding-top: ${(props) => props.spacingTop}px;
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.cartIsVisible ? 6 - 1 : 6)},
    minmax(0, 1fr)
  );
  column-gap: 21px;
  row-gap: 20px;

  @media screen and (max-width: 670px) {
    & {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
  @media screen and (min-width: 670px) and (max-width: 930px) {
    & {
      grid-template-columns: repeat(
        ${(props) => (props.cartIsVisible ? 2 - 1 : 2)},
        minmax(0, 1fr)
      );
    }
  }
  @media screen and (min-width: 930px) and (max-width: 1200px) {
    & {
      grid-template-columns: repeat(
        ${(props) => (props.cartIsVisible ? 3 - 1 : 3)},
        minmax(0, 1fr)
      );
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1460px) {
    & {
      grid-template-columns: repeat(
        ${(props) => (props.cartIsVisible ? 4 - 1 : 4)},
        minmax(0, 1fr)
      );
    }
  }
  @media screen and (min-width: 1460px) and (max-width: 1700px) {
    & {
      grid-template-columns: repeat(
        ${(props) => (props.cartIsVisible ? 5 - 1 : 5)},
        minmax(0, 1fr)
      );
    }
  }
`;
