import { FormLabel, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useTranslation } from 'react-i18next';

export const GridWrapper = ({
  children,
  label,
  customMargin = '8px',
}: {
  children: JSX.Element;
  label: string;
  customMargin?: string;
}) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  return (
    <Grid container sx={{ marginTop: customMargin }}>
      <Grid item xs={12} sx={{ marginBottom: '8px' }}>
        <FormLabel
          required={true}
          sx={{
            color: theme.black,

            '.MuiFormLabel-asterisk': {
              color: theme.progressRed,
            },
          }}
        >
          {t(label)}
        </FormLabel>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
