import { Stack, Typography, Button, Box } from '@mui/material';
// import { grey } from '@mui/material/colors';
import { Flag, PencilSimpleLine, Plus, Trash } from '@phosphor-icons/react';
import { ActionIconButton } from 'app/components/ActionIconButton';
import { SectionTitle } from 'app/components/Form/styles';
import If from 'app/components/If';
import { MainTypo } from 'app/pages/Ordoria/CallPlan/components/CallDetailsDrawer/components/Info/styles';
import { useTheme } from 'common/hooks/useTheme';
import { Contact } from 'common/types/Customer';
import Each from 'common/UtilityComponents/Each';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AddPhoneForm } from '../../../ViewCustomer/components/Tabs/AddPhoneForm';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useModal } from 'app/components/Dialog/hooks';
import { CustomerFormcontext } from '../../context';
import { useWatch } from 'react-hook-form';
import { hasUpdatedValues } from 'app/helpers/helpers';

export const PhoneBook = ({
  control,
  onChange,
  suppressConfirmation,
  setValue,
}: {
  control: any;
  //Control<CustomerFields>;
  onChange?: (contacts: Contact[]) => void;
  suppressConfirmation?: boolean;
  setValue?: any;
  //UseFormSetValue<CustomerFields>;
}) => {
  const contacts = useWatch({
    control,
    name: 'contacts',
    defaultValue: undefined,
  });
  const [contactsValue, setContactsValue] = useState<Contact[]>(contacts || []);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editedContact, setEditedContact] = useState<Contact | null>(null);
  const { t } = useTranslation();
  const theme = useTheme();

  const { canEdit } = useContext(CustomerFormcontext);

  const { openModal, closeModal } = useModal();

  const ContactTypes = useMemo(() => {
    return _.chain(contactsValue)
      .groupBy('jobType')
      .map((contactGroup, jobType) => ({
        type: jobType,
        contacts: contactGroup,
      }))
      .value();
  }, [contactsValue]);

  useEffect(() => {
    if (!contacts || !hasUpdatedValues(contacts, contactsValue)) return;
    setContactsValue(contacts);
  }, [contacts]);

  useEffect(() => {
    setValue?.('contacts', contactsValue);
    onChange?.(contactsValue);
  }, [contactsValue, onChange, setValue]);

  const handleFormSubmit = (data: Contact) => {
    const { main: isMain, _id, jobType } = data;

    setContactsValue((prev) => {
      const existingContactIndex = prev.findIndex((c) => c._id === _id);

      if (existingContactIndex !== -1) {
        const updatedContacts = [...prev];

        if (isMain) {
          updatedContacts.forEach((contact, index) => {
            if (contact.jobType === jobType) {
              updatedContacts[index] = {
                ...contact,
                main: index === existingContactIndex,
              };
            }
          });
        }

        updatedContacts[existingContactIndex] = { ...data };

        return updatedContacts;
      } else {
        if (isMain) {
          return prev
            .map((contact) => ({
              ...contact,
              main: contact.jobType === jobType ? false : contact.main,
            }))
            .concat(data);
        }

        return [...prev, { ...data }];
      }
    });

    handleCloseForm();
  };

  const handleOpenForm = (contact?: Contact) => {
    setEditedContact(null);
    if (contact) {
      setEditedContact(contact);
    }
    setDrawerOpen(true);
  };

  const handleCloseForm = () => {
    setEditedContact(null);
    setDrawerOpen(false);
  };

  const toggleMain = (contact: Contact) => {
    setContactsValue((prev) => {
      return prev.map((c) => ({
        ...c,
        main:
          c._id === contact._id
            ? !c.main
            : c.main && c.jobType !== contact.jobType,
      }));
    });
  };

  const removeConfirmation = (contact: Contact) => {
    if (!!suppressConfirmation) {
      remove(contact);
      return;
    }

    openModal({
      title: t('delete_subject', { subject: t('contact') }),
      content: t('delete_subject_confirmation', { subject: t('contact') }),
      action: {
        actionText: t('delete'),
        actionCallback: () => {
          remove(contact);
          closeModal();
        },
        buttonProps: {
          sx: { background: theme.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  };

  const remove = (contact: Contact) => {
    setContactsValue((prev) => prev.filter((c) => c._id !== contact._id));
  };

  return (
    <>
      <SectionTitle>{t('phone_book')}</SectionTitle>
      <Stack gap="8px" alignItems="flex-start">
        <Each
          of={ContactTypes}
          render={(contactType) => (
            <Stack gap="5px" width="100%">
              <Typography
                fontSize="0.8125rem"
                textTransform="capitalize"
                fontWeight="500"
              >
                {contactType.type}
              </Typography>
              <Box>
                <Row>
                  <HeadCell width="40%">{t('contact_name')}</HeadCell>
                  <HeadCell>{t('mobile_number')}</HeadCell>
                  <HeadCell>{t('email_address')}</HeadCell>
                  <HeadCell width="10%" />
                </Row>
                <Each
                  of={contactType.contacts}
                  render={(contact, index) => (
                    <Row hover key={index}>
                      <Cell width="40%">
                        <Stack
                          direction="row"
                          gap="12px"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Typography fontSize="0.75rem">
                            {contact.name}
                          </Typography>
                          <Typography
                            fontSize="0.75rem"
                            color={theme.clientMarkerBg}
                          >
                            {contact.role}
                          </Typography>
                          <If condition={contact.main}>
                            <MainTypo
                              sx={{
                                backgroundColor: theme?.primaryLight1,
                                border: `1px solid ${theme.strokePrimary}`,
                                fontSize: '0.625rem',
                              }}
                            >
                              {t('main')}
                            </MainTypo>
                          </If>
                        </Stack>
                      </Cell>
                      <Cell>{contact.phone}</Cell>
                      <Cell>{contact.email}</Cell>
                      <Cell className="actions-cell" width="10%">
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="0px"
                          justifyContent="flex-end"
                        >
                          <ActionIconButton
                            title={
                              contact.main
                                ? 'customer.main.remove'
                                : 'customer.main.markAsMain'
                            }
                            onClick={() => {
                              toggleMain(contact);
                            }}
                            icon={<Flag />}
                            iconProps={{
                              weight: contact.main ? 'fill' : 'regular',
                              color: contact.main ? 'goldenrod' : 'inherit',
                              size: 12,
                            }}
                          />
                          <ActionIconButton
                            title="edit"
                            onClick={() => {
                              handleOpenForm(contact);
                            }}
                            icon={<PencilSimpleLine />}
                            iconProps={{
                              size: 12,
                            }}
                          />
                          <ActionIconButton
                            title="delete"
                            onClick={() => {
                              removeConfirmation(contact);
                            }}
                            icon={<Trash />}
                            iconProps={{
                              color: theme?.chipRedBg,
                              size: 12,
                            }}
                          />
                        </Stack>
                      </Cell>
                    </Row>
                  )}
                />
              </Box>
            </Stack>
          )}
        />

        <Button
          onClick={() => {
            !!canEdit && handleOpenForm();
          }}
          sx={{
            textTransform: 'none',
          }}
          disabled={!canEdit}
        >
          <Stack direction="row" gap="5px" alignItems="center">
            <Plus size={16} />
            <Typography
              fontSize="1rem"
              fontWeight={700}
              sx={{
                textDecoration: 'underline',
              }}
            >
              {t('common.addNew')}
            </Typography>
          </Stack>
        </Button>
      </Stack>

      <TwoViewModal
        title={t(editedContact ? 'edit.contact' : 'customer.contact.add_new')}
        onOpen={drawerOpen}
        onClose={handleCloseForm}
        scrollY={false}
        defaultMode="floated"
        bodyPadding="0px"
        autoHeight
      >
        <Box padding="24px">
          <AddPhoneForm
            onSubmit={handleFormSubmit}
            closeModal={handleCloseForm}
            main={
              !contactsValue?.length ||
              (!!editedContact && contactsValue?.length === 1)
            }
            data={editedContact}
          />
        </Box>
      </TwoViewModal>
    </>
  );
};

const Cell = styled.div<{ width?: string }>`
  font-size: 0.75rem;
  letter-spacing: 0.025rem;
  width: ${({ width }) => width ?? '25%'};
  line-height: normal;
`;

const HeadCell = styled(Cell)`
  font-weight: 500;
`;

const Row = styled.div<{ hover?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 2px 16px;
  border: 0.5px solid ${(props) => props.theme.grey400};

  &:not(:last-child) {
    border-bottom: none;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.grey400};
    border-radius: 0 0 4px 4px;
  }

  .actions-cell {
    opacity: 0;
  }
  &:hover {
    background-color: ${({ theme, hover }) =>
      hover ? `${theme.lightBlueBg} !important` : 'inherit'};

    .actions-cell {
      opacity: 1;
    }
  }
`;
