import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import If from 'app/components/If';
import { FilterName, ToggleIcon } from '../../styles';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'common/hooks/useDebounce';
import MultiSelectAutocomplete from './components/Autocomplete';

interface CheckboxGrid {
  openByDefault?: boolean;
  filterName: string;
  filterItems: {
    value: string;
    label: string;
    count?: number;
    disabled?: boolean;
    color?: string;
    backgroundColor?: string;
    icon?: any;
  }[];
  selectedOptions: { value: string; label: string }[];
  placeholder?: string;
  handleFilterChange: (options: { value: string; label: string }[]) => void;
  onSearch?: (search: string) => void;
  checkboxesOptions?: boolean;
  autoCompleteProps?: any;
}

const MultiSelect = ({
  openByDefault,
  filterName,
  filterItems,
  selectedOptions,
  placeholder,
  handleFilterChange,
  onSearch,
  checkboxesOptions,
  autoCompleteProps,
}: CheckboxGrid) => {
  const isOpen = !!openByDefault;
  const [open, setOpen] = useState<boolean>(isOpen || false);

  const debouncedSearch = useDebounce('', 300);

  useEffect(() => {
    onSearch && onSearch(debouncedSearch);
  }, [debouncedSearch]);

  const selectCount = useMemo(() => {
    return selectedOptions?.length || 0;
  }, [selectedOptions]);

  return (
    <>
      <div className="title_action" onClick={() => setOpen(!open)}>
        {selectCount > 0 ? (
          <FilterName isHighlighted={true}>
            {filterName} ({selectCount})
          </FilterName>
        ) : (
          <FilterName>{filterName}</FilterName>
        )}
        <ToggleIcon disableRipple>
          {open ? <RemoveIcon /> : <AddIcon />}
        </ToggleIcon>
      </div>
      <If condition={open}>
        <MultiSelectAutocomplete
          filterItems={filterItems}
          selectedOptions={selectedOptions}
          placeholder={placeholder || filterName}
          handleFilterChange={handleFilterChange}
          onSearch={onSearch}
          checkboxesOptions={checkboxesOptions}
          autoCompleteProps={autoCompleteProps}
        />
      </If>
    </>
  );
};

export default MultiSelect;
