import { NameGridCell } from 'app/components/NameGridCell';
import { getImgCdn } from 'common/helpers/cdn';
import { useCallback, useState, useEffect } from 'react';
import { ProductDetailsDrawer } from '../../../../pages/Ordoria/Orders/components/ProductDetailsDrawer';
import { IProduct } from '../../types';
import { toTitleCase } from 'common/helpers/stringFormatter';
import styled from 'styled-components';
import { useLazyGetProductsDetailsQuery } from 'common/services/productApi';
import { generatePath, useNavigate } from 'react-router-dom';
import { A } from 'app/components/A';
import { STOCKEE_PRODUCTS_INFO } from 'utils/routes';

export const ProductCell = (params) => {
  const product = params.value.cellData;

  const [triggerGetProducts, { data: productDetails, isFetching }] =
    useLazyGetProductsDetailsQuery();

  useEffect(() => {
    if (productDetails) {
      setSelectedProduct(productDetails);
    }
  }, [productDetails, isFetching]);

  const handleOpenProductDetailsDrawer = useCallback(async () => {
    await triggerGetProducts({ id: product?.productId });
  }, [productDetails, product]);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null); // product to show in product details drawer

  const navigate = useNavigate();
  const openDetails = useCallback(() => {
    const path = generatePath(STOCKEE_PRODUCTS_INFO, {
      id: product?.productId,
    });
    navigate(path);
  }, [navigate, product?.productId]);

  return (
    <Wrapper>
      {product?.redirectToInfoScreen ? (
        <A role="button" className="link" onClick={openDetails}>
          <>
            <NameGridCell
              hasDisableIcon={params.value?.hasDisableIcon}
              boxingUnits={params.value?.boxingUnits}
              isNew={false}
              name={toTitleCase(product.name)}
              pictureUrl={
                product.pictureUrl
                  ? product.pictureUrl
                  : product.coverImage
                    ? getImgCdn(product.coverImage)
                    : ''
              }
              code={product.code}
              onClick={handleOpenProductDetailsDrawer}
            />
            {/* TODO: reactivate when feature ok */}
            <ProductDetailsDrawer
              open={false}
              product={selectedProduct}
              handleClose={() => setSelectedProduct(null)}
            />
          </>
        </A>
      ) : (
        <>
          <NameGridCell
            hasDisableIcon={params.value?.hasDisableIcon}
            boxingUnits={params.value?.boxingUnits}
            isNew={false}
            name={toTitleCase(product.name)}
            pictureUrl={
              product.pictureUrl
                ? product.pictureUrl
                : product.coverImage
                  ? getImgCdn(product.coverImage, 64)
                  : ''
            }
            code={product.code}
            onClick={handleOpenProductDetailsDrawer}
          />
          <ProductDetailsDrawer
            open={false}
            product={selectedProduct}
            handleClose={() => setSelectedProduct(null)}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  div:hover {
    text-decoration: none;
    cursor: pointer !important;
  }
`;
