/*eslint-disable */
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeft, Storefront } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Tooltip } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { isEmpty } from 'lodash';
import { GetCustomerFrequency } from 'app/pages/Customers/components/CustomerFrequencyIcons';
import {
  useGetCustomerFinancialsQuery,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from 'common/services/customerApi';
import { CreditScoreBadge } from 'app/components/CreditScoreBadge';
import If from 'app/components/If';
import { GridProvider } from 'app/components/Grid/context';
import { CustomerInfoCard } from './components/CustomerInfoCard';
import { CustomerTabs } from './components/Tabs';
import { PlaceProvider } from './context';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { CustomerStatus } from 'common/types/Customer';
import { InviteEmails } from './components/CustomerInfoCard/components/InviteEmails';
import { GridActionMenu } from 'app/components/Grid/components/GridActionMenu';
import { ActionsWrapper } from 'app/styledComponents/DetailsSection.styled';
import { CustomerFormcontext } from '../Form/context';
import { ManagedCreateOrderDrawer } from 'app/pages/Ordoria/Orders/components/ManagedCreateOrderDrawer';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useSelector } from 'react-redux';
import { MenuOption } from 'app/components/CustomMenu/types';
import { useNavigateToCustomers } from './hooks/useNavigateToCustomers';
import CallButton from '../../../Ordoria/CallPlan/components/ActionBar/components/CallButton';
import { usePhoneCallHook } from '../../../../../hooks/VoiceCall/useVoiceCallHook';
//import { ORDORIA_CUSTOMER, CUSTOMER, STOCKEE_CUSTOMER } from 'utils/routes';
import { useNavLock } from 'hooks/useNavLock';
import { useGuardedAction } from 'hooks/useGuardedAction';

interface ContextProps {
  customer: any;
  canEdit?: boolean;
  canExport?: boolean;
}

export const ViewCustomerContext = createContext<ContextProps>({
  customer: null,
  canEdit: false,
  canExport: false,
});

export const ViewCustomer = () => {
  const navigateToCustomers = useNavigateToCustomers();

  const { t } = useTranslation();
  const { id } = useParams();
  const { navLocked } = useNavLock();
  const { executeAction } = useGuardedAction();
  const location = useLocation();
  const navigate = useNavigate();

  const can = usePermission('customers');
  const canAccounting = usePermission('accounting');

  const canEdit = can(Permission.EDIT);
  const canExport = can(Permission.EXPORT);

  const authUser = useSelector(selectAuthUser);
  const isCustomer = !isEmpty(authUser?.currentAgent?._customer);

  const canCall =
    !isCustomer &&
    canAccounting(Permission.MANAGE_PERSONAL) &&
    window.location.pathname?.includes('/ordoria/');

  const [openCreateOrderDrawer, setOpenCreateOrderDrawer] = useState(false);
  const [customer, setCustomer] = useState<any>(null);
  const [openInviteEmails, setOpenInviteEmails] = useState<boolean>(false);

  const { data: customerDetail, isLoading } = useGetCustomerQuery(id, {
    skip: !id,
  });

  const [updateCustomer] = useUpdateCustomerMutation();

  const { data: customerFinancials } = useGetCustomerFinancialsQuery(
    {
      id: id,
    },
    { skip: !!isCustomer },
  );

  const {
    status: callStatus,
    handleCall,
    handleHangup,
    loading: voiceIsLoading,
  } = usePhoneCallHook(customerDetail?._id, customerDetail?.phone);

  function handleChangeStatus(id: string) {
    updateCustomer({ id, status: CustomerStatus.ARCHIVED }).unwrap();
  }

  function handleCreateOrder(customer) {
    setCustomer(customer);
    setOpenCreateOrderDrawer(true);
  }

  const handleBack = () => {
    const currentUrl = window.location.pathname;
    const fromUrl: string | undefined = location?.state?.from;
    navigateToCustomers(currentUrl, fromUrl);
  };

  useEffect(() => {
    const oldId = id;
    const newId = authUser?.currentAgent?._customer?._id;
    if (oldId && newId && newId !== oldId) {
      navigate(
        `${location.pathname.replace(id, authUser.currentAgent._customer._id)}`,
      );
    }
  }, [authUser?.currentAgent?._customer?._id, navigate]);

  const menuOptions = useMemo(() => {
    const options: MenuOption[] = [
      {
        label: 'orders.create_orders',
        action: () => handleCreateOrder(customerDetail),
      },
      {
        label: 'delete',
        action: () => handleChangeStatus(customerDetail?._id),
        isDelete: true,
      },
    ];

    return options;
  }, [customerDetail?._id, handleChangeStatus]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ViewCustomerContext.Provider
      value={{
        customer: customerDetail,
        canEdit: canEdit,
        canExport: canExport,
      }}
    >
      <StyledForm>
        <TopSection>
          <Stack direction="row" gap="8px" alignItems="center">
            <If condition={!isCustomer}>
              <IconButton onClick={() => executeAction(handleBack, navLocked)}>
                <Icon icon={<ArrowLeft />} size="24px" />
              </IconButton>
            </If>
            <If
              condition={!!isCustomer}
              otherwise={
                <Avatar
                  src={customerDetail?.logo?.[0]?.url}
                  sx={{ width: '36px', height: '36px' }}
                />
              }
            >
              <Avatar
                sx={{
                  width: '36px',
                  height: '36px',
                  background: themes.default.whisper,
                }}
              >
                <Icon icon={<Storefront />} size="24px" />
              </Avatar>
            </If>

            <Typography fontWeight={600}>
              {customerDetail?.publicName}
            </Typography>
            <If condition={!isCustomer}>
              <If condition={customerDetail?.creditScore}>
                <Tooltip title={t('customer.creditScore')}>
                  <div>
                    <CreditScoreBadge
                      score={customerDetail?.creditScore?.commonValue}
                      size="small"
                    />
                  </div>
                </Tooltip>
              </If>
              {customerDetail?.cache?.financials?.frequencyStatus ===
              'noOrder' ? (
                <Tooltip title={t('customer-info-no-orders-yet')}>
                  <div>
                    {customerDetail?.cache?.financials?.frequencyStatus && (
                      <GetCustomerFrequency
                        size={24}
                        frequency={
                          customerDetail?.cache?.financials?.frequencyStatus
                        }
                      />
                    )}
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={t('customer-order-frequency')}>
                  <div>
                    {customerDetail?.cache?.financials?.frequencyStatus && (
                      <GetCustomerFrequency
                        size={24}
                        frequency={
                          customerDetail?.cache?.financials?.frequencyStatus
                        }
                      />
                    )}
                  </div>
                </Tooltip>
              )}
            </If>

            {canCall && !isEmpty(customerDetail?.phone) ? (
              <CallButton
                onCall={handleCall}
                onHangup={handleHangup}
                isLoading={voiceIsLoading}
                status={callStatus}
                disabled={!customerDetail?.phone}
              />
            ) : null}

            <If condition={canEdit && !isCustomer}>
              <ActionsWrapper>
                <GridActionMenu options={menuOptions} disabled={navLocked} />
              </ActionsWrapper>
            </If>
          </Stack>

          <CustomerInfoCard
            customerDetail={customerDetail}
            customerFinancials={customerFinancials}
            setInviteEmails={setOpenInviteEmails}
            isCustomer={isCustomer}
          />

          <InviteEmails
            customerDetail={customerDetail}
            open={openInviteEmails}
            onClose={() => setOpenInviteEmails(false)}
          />
        </TopSection>

        <GridProvider>
          <PlaceProvider>
            <CustomerFormcontext.Provider
              value={{
                customer: customerDetail,
                canEdit:
                  canEdit ||
                  authUser?.currentAgent?._customer?._id ===
                    customerDetail?._id,
                canExport,
                isCustomer,
              }}
            >
              <CustomerTabs
                customerDetail={customerDetail}
                from={location?.state?.from}
              />
            </CustomerFormcontext.Provider>
          </PlaceProvider>
        </GridProvider>

        <ManagedCreateOrderDrawer
          open={openCreateOrderDrawer}
          onClose={() => setOpenCreateOrderDrawer(false)}
          selectedCustomer={customer?._id}
        />
      </StyledForm>
    </ViewCustomerContext.Provider>
  );
};

const TopSection = styled.div`
  background-color: white;
  padding: 8px 20px 5px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledForm = styled.form`
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  background-color: ${themes?.default?.pageGreyBg};
`;
