import { SectionTitle } from 'app/components/Form/styles';
import {
  Typography,
  FormControlLabel,
  Switch,
  Button,
  Stack,
  Box,
} from '@mui/material';
import { useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LeftSection,
  MainSection,
  RightSection,
  TabCard,
} from 'app/styledComponents/DetailsSection.styled';
import { FormTextField } from 'app/components/Form/TextField';
import { SupplierFormcontext } from 'app/pages/Suppliers/components/Form/context';
import { useForm, Controller } from 'react-hook-form';
import { useUpdateSupplierSettingsMutation } from 'common/services/supplierApi';
import { useToaster } from 'hooks/useToaster';
import { SupplierSettingsFields } from 'common/types/Supplier';
import { useDebounce } from 'common/hooks/useDebounce';
// import { omit, isEmpty } from 'lodash';

export const SettingsTab = ({ supplierDetail }) => {
  const { isSupplier, canEdit } = useContext(SupplierFormcontext);
  const { control, watch, reset } = useForm<SupplierSettingsFields>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      showPricesDeliveryNote:
        supplierDetail?.supplier?.settings?.showPricesDeliveryNote || false,
      email:
        supplierDetail?.supplier?.settings?.documentReceivePreferences?.email ||
        false,
      postalEmail:
        supplierDetail?.supplier?.settings?.documentReceivePreferences
          ?.postalEmail || false,
      chorusPro: supplierDetail?.supplier?.settings?.chorusPro || false,
      serviceCode:
        supplierDetail?.supplier?.settings?.chorusProServiceCode || '',
      // showInternalCodeInOrders: supplierDetail?.settings?.showInternalCodeInOrders || false,
    },
  });
  const { t } = useTranslation();
  const [updateSupplierSettings] = useUpdateSupplierSettingsMutation();
  const toast = useToaster();

  const watchedFields = watch();

  const payload = useMemo(() => {
    return {
      showPricesDeliveryNote: watchedFields.showPricesDeliveryNote,
      documentReceivePreferences: {
        email: watchedFields.email,
        postalEmail: watchedFields.postalEmail,
      },
      chorusPro: watchedFields.chorusPro,
      chorusProServiceCode: watchedFields.serviceCode,
      // showInternalCodeInOrders: watchedFields.showInternalCodeInOrders,
    };
  }, [watchedFields]);

  const debouncedPayload = useDebounce(payload, 300);

  const saveSettings = async (props: string) => {
    if (props === 'add') {
      try {
        await updateSupplierSettings({
          id: supplierDetail.supplier._id,
          ...debouncedPayload,
        })
          .unwrap()
          .then(() => {
            toast(3000, 'success', t('supplier.settings.update.toast'));
          })
          .catch((err) => {
            toast(3000, 'error', err.data.error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  // useEffect(() => {
  //   if (canEdit && !isValidating && isEmpty(errors)) {
  //     saveSettings('add');
  //   }
  // }, [debouncedPayload, canEdit, isValidating, errors]);

  useEffect(() => {
    reset({
      showPricesDeliveryNote: supplierDetail?.settings?.showPricesDeliveryNote,
      email: supplierDetail?.settings?.documentReceivePreferences?.email,
      postalEmail:
        supplierDetail?.settings?.documentReceivePreferences?.postalEmail,
      chorusPro: supplierDetail?.settings?.chorusPro,
      serviceCode: supplierDetail?.settings?.chorusProServiceCode,
    });
  }, [supplierDetail, reset]);

  return (
    <MainSection>
      <LeftSection>
        <Typography fontSize="0.875rem" fontWeight={500}>
          {t('settings')}
        </Typography>

        <Typography fontSize="0.75rem">
          {t(isSupplier ? 'supplier_settings' : 'supplier.settings_tab_text')}
        </Typography>
      </LeftSection>

      <RightSection>
        <TabCard height="auto">
          <SectionTitle>{t('general.settings')}</SectionTitle>
          <Stack direction="column" gap="12px" alignItems="flex-start">
            <Controller
              name="showPricesDeliveryNote"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={field.value} size="small" />
                  }
                  label={t(`show_prices_on_delivery_note`)}
                  sx={{
                    marginLeft: 0,
                    marginTop: '10px',
                    letterSpacing: '0.15px',
                  }}
                />
              )}
            />
            <Controller
              name="showInternalCodeInOrders"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={field.value} size="small" />
                  }
                  label={t(`show_internal_code_in_orders`)}
                  sx={{
                    marginLeft: 0,
                    marginTop: '10px',
                    letterSpacing: '0.15px',
                  }}
                />
              )}
            />
          </Stack>
        </TabCard>
        <TabCard height="auto">
          <SectionTitle>{t('preference.receiving.documents')}</SectionTitle>
          <Stack direction="column" gap="12px" alignItems="flex-start">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={field.value} size="small" />
                  }
                  label={t('supplier.settings.email')}
                  sx={{
                    marginLeft: 0,
                    marginTop: '10px',
                    letterSpacing: '0.15px',
                  }}
                />
              )}
            />
            <Controller
              name="postalEmail"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={field.value} size="small" />
                  }
                  label={t('courrier_postal')}
                  sx={{
                    marginLeft: 0,
                    marginTop: '10px',
                    letterSpacing: '0.15px',
                  }}
                />
              )}
            />
          </Stack>
        </TabCard>
        <TabCard height="auto">
          <SectionTitle>{t('chorus.pro')}</SectionTitle>
          <Stack direction="column" gap="12px" alignItems="flex-start">
            <Controller
              name="chorusPro"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={field.value} size="small" />
                  }
                  label={t(`active`)}
                  sx={{
                    marginLeft: 0,
                    marginTop: '10px',
                    letterSpacing: '0.15px',
                  }}
                />
              )}
            />
            <Box sx={{ width: '300px' }}>
              <FormTextField
                name="serviceCode"
                label="common.code.service"
                control={control}
                disabled={!canEdit}
                rules={{
                  required: true,
                }}
              />
            </Box>
            <Button
              variant="contained"
              onClick={() => {
                saveSettings('add');
              }}
              disabled={!canEdit}
            >
              {t('common.buttons.save')}
            </Button>
          </Stack>
        </TabCard>
      </RightSection>
    </MainSection>
  );
};
