import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Grid, Typography } from '@mui/material';
import { themes } from 'styles/theme/themes';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import { generatePath, useNavigate } from 'react-router-dom';
import { ORDORIA_CLAIMS, ORDORIA_VIEWCUSTOMER } from 'utils/routes';

function ClientCell(params: CustomCellRendererProps) {
  const navigate = useNavigate();

  function handleViewCustomer() {
    const path = generatePath(ORDORIA_VIEWCUSTOMER, {
      id: params.value.clientIdData,
    });
    navigate(path, { state: { from: ORDORIA_CLAIMS } });
  }

  return (
    <Grid container flexDirection="column">
      <Grid container alignItems="center">
        <Grid3x3Icon
          sx={{
            width: '10.5px',
            height: '10.5px',
            color: themes?.default?.grey2,
          }}
        />
        <Typography fontSize="0.75rem" color={themes?.default?.grey2}>
          {params.value.clientCodeData ?? '__'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontSize="0.75rem"
          onClick={handleViewCustomer}
          sx={{
            ':hover': {
              cursor: 'pointer',
              color: themes?.ordoria?.primary,
              textDecoration: 'underline',
            },
          }}
        >
          {params.value.fullNameData ?? '__'}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ClientCell;
