import { Stack, Tooltip, IconButton, Typography, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Note } from '../../../../../types';
import moment from 'moment';
import { Icon } from 'app/components/Icon';
import { Trash, PencilSimple } from '@phosphor-icons/react';
import If from 'app/components/If';
import { stringAvatar } from 'app/helpers/helpers';

const formatDate = (date: string, locale: string) => {
  moment.locale(locale);
  return moment(date).format('D MMM, YYYY [at] h:mm A');
};

export const CurrentUserNote = ({
  note,
  onDelete,
  onEdit,
  editingNoteId,
  canEdit,
}: {
  note: Note;
  onDelete: (noteId) => void;
  onEdit: (noteId: string, content: string) => void;
  editingNoteId: string;
  canEdit: boolean;
}) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  return (
    <If condition={editingNoteId !== note._id}>
      <NoteWrapper $fill={theme.accordionWhiteBg} $stroke={theme.primaryLight1}>
        <NoteAuthorAvatar
          fullName={note?._userAgent?.fullName}
          pictureUrl={note?._userAgent?.pictureUrl}
        />
        <Stack gap="4px">
          <NoteAuthorInfo name={t('you')} createdAt={note.createdAt} />
          <Typography
            fontWeight="400"
            fontSize="0.875rem"
            lineHeight="18px"
            letterSpacing="0.17px"
            color={theme.black2}
          >
            {note.content}
          </Typography>
        </Stack>
        {canEdit && (
          <NoteActions note={note} onDelete={onDelete} onEdit={onEdit} />
        )}
      </NoteWrapper>
    </If>
  );
};

export const OtherUserNote = ({ note }: { note: Note }) => {
  const theme = useSelector(selectTheme);

  return (
    <NoteWrapper $fill={theme.lightGrey} $stroke={theme.primaryLight1}>
      <NoteAuthorAvatar
        fullName={note?._userAgent?.fullName}
        pictureUrl={note?._userAgent?.pictureUrl}
      />
      <Stack gap="4px">
        <NoteAuthorInfo
          name={note._userAgent.fullName}
          createdAt={note.createdAt}
        />
        <Typography
          fontWeight="400"
          fontSize="0.875rem"
          lineHeight="18px"
          letterSpacing="0.17px"
          color={theme.black2}
        >
          {note.content}
        </Typography>
      </Stack>
    </NoteWrapper>
  );
};

// -------------- SUB COMPONENTS -------------- //

const NoteAuthorInfo = ({
  name,
  createdAt,
}: {
  name: string;
  createdAt: string;
}) => {
  const theme = useSelector(selectTheme);
  const { i18n } = useTranslation();

  return (
    <Stack gap="4px">
      <Typography
        fontSize="0.75rem"
        fontWeight="500"
        lineHeight="12px"
        letterSpacing="0.15px"
        color={theme.black2}
      >
        {name}
      </Typography>
      <Typography
        fontSize="0.625rem"
        fontWeight="400"
        lineHeight="10px"
        letterSpacing="0.14px"
        color={theme.clientMarkerBg}
      >
        {formatDate(createdAt, i18n.language)}
      </Typography>
    </Stack>
  );
};

const NoteActions = ({
  note,
  onDelete,
  onEdit,
}: {
  note: Note;
  onDelete: (noteId: string) => void;
  onEdit: (noteId: string, content: string) => void;
}) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  return (
    <NoteActionsWrapper className="note-actions" $fill={theme.accordionWhiteBg}>
      <Tooltip title={t('delete')}>
        <IconButton sx={{ padding: '0px' }} onClick={() => onDelete(note._id)}>
          <Icon icon={<Trash />} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('common.edit')}>
        <IconButton
          sx={{ padding: '0px' }}
          onClick={() => onEdit(note._id, note.content)}
        >
          <Icon icon={<PencilSimple />} />
        </IconButton>
      </Tooltip>
    </NoteActionsWrapper>
  );
};

export const NoteAuthorAvatar = ({
  pictureUrl,
  fullName,
  size = '40px',
  fontSize = '0.875rem',
}: {
  pictureUrl?: string;
  fullName?: string;
  size?: string;
  fontSize?: string;
}) => {
  return (
    <If
      condition={!!pictureUrl}
      otherwise={
        <Avatar sx={{ width: size, height: size }}>
          <span style={{ fontSize: fontSize, lineHeight: size }}>
            {stringAvatar(fullName ?? '')}
          </span>
        </Avatar>
      }
    >
      <Avatar src={pictureUrl} sx={{ width: size, height: size }} />
    </If>
  );
};

// ------------ STYLED COMPONENTS ------------ //

const NoteWrapper = styled.div<{ $fill: string; $stroke: string }>`
  position: relative;
  display: flex;
  gap: 16px;
  padding: 12px;
  background: ${(props) => props.$fill};
  border: 1px solid ${(props) => props.$stroke};
  border-radius: 8px;

  :hover {
    .note-actions {
      opacity: 1;
    }
  }
`;

const NoteActionsWrapper = styled.div<{ $fill: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${(props) => props.$fill};
  position: absolute;
  top: 12px;
  right: 12px;
  opacity: 0;
  transition-duration: 0.15s;
  border-radius: 4px;
`;
