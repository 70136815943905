import React, { useCallback, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';

import { useNavigate } from 'react-router-dom';
import { ORDERS } from 'utils/routes';
import { ActionProps } from 'app/components/CustomMenu/types';
import { useTranslation } from 'react-i18next';
import { Branch, CustomerStatus } from 'common/types/Customer';
import { useTheme } from 'common/hooks/useTheme';
import { ItemStatusComponent } from 'app/pages/AddRoundTrips/data/RoundTripData/components/components/ItemStatusComponent';
import {
  useDeleteCustomerBranchMutation,
  useUpdateCustomerBranchStatusMutation,
} from 'common/services/customerApi';
import { useToaster } from 'hooks/useToaster';
import { useModal } from 'app/components/Dialog/hooks';
import If from 'app/components/If';
import styled from 'styled-components';
import { Icon } from 'app/components/Icon';
import {
  CurrencyCircleDollar,
  DotsSixVertical,
  Note,
  ShoppingBagOpen,
  WarningCircle,
} from '@phosphor-icons/react';
import { ActionIconButton } from 'app/components/ActionIconButton';
import { NestedMenu } from 'app/components/CustomMenu/NestedMenu';
import { Application } from 'common/store/app/types';
import { useApplication } from 'hooks/useApplication';

interface BranchRowProps {
  branch: Branch;
  setSelectedBranchId: (id: string | null) => void;
  setSelectedBranch: (branch: Branch | null) => void;
  getBranchDetails: (id: string) => void;
  customerId?: string;
  customerName?: string;
  setBranches: (branches: any) => void;
  isSelected: boolean;
}

export const BranchRow: React.FC<BranchRowProps> = ({
  branch,
  setSelectedBranchId,
  setSelectedBranch,
  getBranchDetails,
  customerId,
  customerName,
  setBranches,
  isSelected,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const toast = useToaster();
  const { openModal, closeModal, setActionDisabled } = useModal();
  const currentApplication: Application = useApplication();

  const [changeBranchStatusMutation] = useUpdateCustomerBranchStatusMutation();
  const [deleteBranch] = useDeleteCustomerBranchMutation();

  const handlViewBranchOrders = (branchId: string) => {
    if (currentApplication.id === 'bianta') {
      navigate(
        `${ORDERS}?branchFilter=${branchId}&customerId=${customerId}&customerName=${customerName}`,
      );
    } else {
      navigate(
        `/${currentApplication.id}${ORDERS}?branchFilter=${branchId}&customerId=${customerId}&customerName=${customerName}`,
      );
    }
  };

  const handleChangeStatus = useCallback(
    async (
      customerId: string,
      branchId: string,
      status: string,
      isNew?: boolean,
    ) => {
      if (!isNew) {
        await changeBranchStatusMutation({
          customerId,
          branchId,
          status,
        }).unwrap();
      }

      setBranches((prev) =>
        prev?.map((b) => {
          if (b.id === branchId) {
            if (isSelected) {
              setSelectedBranch({ ...b, status });
            }

            return { ...b, status };
          }
          return b;
        }),
      );
      toast(5000, 'success', t('status_updated_successfully'));
    },
    [
      changeBranchStatusMutation,
      isSelected,
      setBranches,
      setSelectedBranch,
      t,
      toast,
    ],
  );

  const handleDelete = useCallback(
    (customerId: string, branchId: string) => {
      openModal({
        title: t(`client.deleteBranch`, {
          count: 1,
        }),
        content: t(`branch_delete_confirmation`),
        action: {
          actionText: t('delete'),
          actionCallback: async () => {
            try {
              setActionDisabled(true);
              await deleteBranch({
                customerId: customerId,
                branchId: branchId,
              }).unwrap();
              setActionDisabled(false);
              toast(5000, 'success', t('branch.delete_one'));
            } catch (e) {}
            if (isSelected) {
              setSelectedBranch(null);
              setSelectedBranchId(null);
            }
            setBranches((prev) => prev.filter((b) => b.id !== branchId));
            closeModal();
          },
          buttonProps: {
            sx: { background: theme?.Cinnabar },
            color: 'error',
          },
        },
        cancel: true,
      });
    },
    [
      openModal,
      t,
      theme?.Cinnabar,
      isSelected,
      setBranches,
      closeModal,
      setActionDisabled,
      deleteBranch,
      toast,
      setSelectedBranch,
      setSelectedBranchId,
    ],
  );

  const status = useMemo(
    () => [
      {
        label: t('customer.status.in-review'),
        value: CustomerStatus.IN_REVIEW,
        color: theme.tapa,
      },
      {
        label: t('user.status.active'),
        value: CustomerStatus.ACTIVE,
        color: theme.greenStatus,
      },
      {
        label: t('customer.status.on-watch'),
        value: CustomerStatus.ON_WATCH,
        color: theme.warningMain,
      },
      {
        label: t('customer.status.blocked'),
        value: CustomerStatus.BLOCKED,
        color: theme.brown,
      },
      {
        label: t('customer.status.archived'),
        value: CustomerStatus.ARCHIVED,
        color: theme.errorMain,
      },
    ],
    [t, theme],
  );

  const menuActions = useCallback(
    (branch: Branch) => {
      const actions: ActionProps[] = [
        {
          element: t('common.change_status'),
          nested: true,
          actions: status
            .filter((item) => item.value !== branch.status)
            .map((status: any) => ({
              element: (
                <ItemStatusComponent
                  action={async () => {
                    if (!customerId) return;
                    handleChangeStatus(
                      customerId,
                      branch.id,
                      status.value,
                      branch?.new,
                    );
                  }}
                  label={status.label}
                  color={status.color}
                  style={{ padding: 0 }}
                />
              ),
            })),
        },
        {
          element: t('delete'),
          onClick: () => {
            if (!branch?.new) {
              if (!customerId) return;
              handleDelete(customerId, branch.id);
            } else {
              if (isSelected) {
                setSelectedBranch(null);
                setSelectedBranchId(null);
              }
              setBranches((prev) => prev.filter((b) => b.id !== branch.id));
            }
          },
          withSeparator: true,
          danger: true,
        },
      ];

      return actions;
    },
    [
      customerId,
      handleChangeStatus,
      handleDelete,
      isSelected,
      setBranches,
      setSelectedBranch,
      setSelectedBranchId,
      status,
      t,
    ],
  );

  return (
    <If
      condition={!branch.new}
      otherwise={
        <Row
          direction="row"
          key={branch.index}
          selected={isSelected}
          isNew={true}
          onClick={() => {
            setSelectedBranchId(branch?.id);
            setSelectedBranch(branch);
          }}
          sx={{
            borderRadius: '4px !important',
          }}
        >
          <Typography color={theme.clientMarkerBg} sx={{ textWrap: 'noWrap' }}>
            {branch.shortName}
          </Typography>

          <Icon icon={<WarningCircle />} color={theme.redStatus} />

          <Stack
            direction="row"
            alignItems="center"
            gap="0px"
            className="row-actions"
            sx={{ marginLeft: 'auto' }}
          >
            <Box onClick={(e) => e.stopPropagation()}>
              <NestedMenu
                actions={[
                  {
                    element: 'Delete',
                    onClick: () => {
                      if (isSelected) {
                        setSelectedBranch(null);
                        setSelectedBranchId(null);
                      }
                      setBranches((prev) =>
                        prev.filter((b) => b.id !== branch.id),
                      );
                    },
                    withSeparator: true,
                    danger: true,
                  },
                ]}
                variant="rounded"
                menuProps={{
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              />
            </Box>
          </Stack>
        </Row>
      }
    >
      <Draggable
        key={branch.id}
        draggableId={`draggable-branch-${branch.id}`}
        index={branch.index}
        isDragDisabled={!branch?.id}
      >
        {(provided) => (
          <Row
            direction="row"
            key={branch.id}
            selected={isSelected}
            onClick={() => getBranchDetails(branch?.id)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
            }}
            sx={{
              borderRadius: '4px !important',
            }}
          >
            <Box className="drag-handle" {...provided.dragHandleProps}>
              <Icon icon={<DotsSixVertical />} />
            </Box>

            <Stack direction="row" className="row-text" gap="8px">
              <Typography
                fontWeight={isSelected ? 900 : 500}
                sx={{ textWrap: 'noWrap' }}
              >
                {branch.shortName}
              </Typography>
              <Typography
                color={
                  isSelected ? theme.strokePrimary : theme.textColorSecondary
                }
                sx={{ textWrap: 'noWrap' }}
              >
                {branch.code}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              gap="0px"
              className="row-actions"
              sx={{ marginLeft: 'auto' }}
            >
              <ActionIconButton
                title="view_branch_orders"
                onClick={() => handlViewBranchOrders(branch.id)}
                icon={<ShoppingBagOpen />}
              />

              <ActionIconButton
                title=""
                onClick={(e) => e.stopPropagation()}
                icon={<Note />}
                buttonProps={{
                  disableRipple: true,
                  sx: { cursor: 'not-allowed' },
                }}
              />

              <ActionIconButton
                title=""
                onClick={(e) => e.stopPropagation()}
                icon={<CurrencyCircleDollar />}
                buttonProps={{
                  disableRipple: true,
                  sx: { cursor: 'not-allowed' },
                }}
              />

              <Box onClick={(e) => e.stopPropagation()}>
                <NestedMenu
                  actions={menuActions(branch)}
                  variant="rounded"
                  menuProps={{
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                />
              </Box>
            </Stack>
          </Row>
        )}
      </Draggable>
    </If>
  );
};

const Row = styled(Stack)<{ selected?: boolean; isNew?: boolean }>`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: ${({ theme, selected }) => (selected ? 'white' : theme.grey)};
  padding: 8px 12px;
  border: 1px solid
    ${({ theme, selected, isNew }) =>
      isNew && !selected
        ? theme.redStatus
        : selected
          ? theme.primaryActiveColor
          : theme.grey400};
  font-size: 0.75rem;
  color: ${({ theme, selected }) =>
    selected ? theme.primaryActiveColor : theme.textColorPrimary};
  cursor: pointer;
  height: 36px;
  position: relative;

  .drag-handle,
  .row-actions {
    position: absolute;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  .drag-handle {
    left: 12px;
  }

  .row-actions {
    right: 12px;
  }

  .row-text {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    .drag-handle,
    .row-actions {
      opacity: 1;
    }

    .row-text {
      transform: translateX(24px);
    }
  }
`;
