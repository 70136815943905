import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { pdfjs, Document, Page } from 'react-pdf';
import { If } from '../If';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import ArrowBack from '@mui/icons-material/ArrowBack';
import RotateRight from '@mui/icons-material/RotateRight';
import RotateLeft from '@mui/icons-material/RotateLeft';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Close from '@mui/icons-material/Close';
import Download from '@mui/icons-material/Download';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import styled from 'styled-components';
import { LoadingIndicator } from '../LoadingIndicator';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

export function PDFViewer({
  onLoadSuccess,
  children,
  file,
  className,
  inputRef,
}: {
  onLoadSuccess: any;
  children: React.ReactNode;
  file: { url: string };
  className: string;
  inputRef?: any;
}) {
  return (
    <Document
      inputRef={inputRef}
      file={file}
      onLoadSuccess={onLoadSuccess}
      options={options}
      className={className}
      loading={<LoadingIndicator />}
    >
      {children}
    </Document>
  );
}

export function ImageViewer({
  url,
  style,
  rotation,
}: {
  url: string | undefined;
  style: any;
  rotation: number;
}) {
  return (
    <ImageViewerWrapper style={style}>
      <img src={url} style={{ transform: `rotate(${rotation}deg)` }} />
    </ImageViewerWrapper>
  );
}

export default function PDFViewerModal({
  open,
  url,
  url2,
  fileName,
  onClose,
}: {
  open: boolean;
  url: string;
  url2?: string;
  fileName: string;
  onClose: () => void;
}) {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const [rotation, setRotation] = useState(0);

  const handleRotateLeft = () => {
    setRotation(rotation - 90);
  };

  const handleRotateRight = () => {
    setRotation(rotation + 90);
  };

  const isImage = url?.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) !== null;

  const handleClose = () => {
    onClose();
    setPageNumber(1);
    setRotation(0);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      sx={{
        '& .MuiDialog-paper': {
          width: '1000px',
        },
      }}
    >
      <AppBar sx={{ position: 'absolute' }} color="default">
        <Toolbar>
          <ButtonGroup size="small" aria-label="small button group">
            <Button
              key="one"
              disabled={pageNumber <= 1}
              variant="outlined"
              onClick={previousPage}
            >
              <ArrowBack />
            </Button>
            <Button key="two" variant="outlined">
              Page {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            </Button>
            <Button
              key="three"
              variant="outlined"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <ArrowForward />
            </Button>
          </ButtonGroup>

          <a href={url} target="_blank" rel="noreferrer">
            <IconButton
              color="primary"
              size="large"
              aria-label="download"
              sx={{ marginLeft: '8px' }}
            >
              <Download />
            </IconButton>
          </a>

          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            className="viewerActions"
            sx={{ marginLeft: '8px' }}
          >
            <Button onClick={handleRotateLeft}>
              <RotateLeft />
            </Button>
            <Button onClick={handleRotateRight}>
              <RotateRight />
            </Button>
          </ButtonGroup>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {fileName}
          </Typography>

          <Button color="inherit" variant="outlined" onClick={handleClose}>
            <Close />
          </Button>
        </Toolbar>
      </AppBar>
      <DocWrapper>
        <If condition={!isEmpty(url) && !isImage}>
          <PDFViewer
            file={{ url }}
            onLoadSuccess={onDocumentLoadSuccess}
            className="bamptee-pdf-viewer"
          >
            <Page pageNumber={pageNumber} scale={1.5} rotate={rotation} />
          </PDFViewer>
        </If>

        <If condition={!isEmpty(url) && isImage}>
          <div style={{ textAlign: 'center' }}>
            <ImageViewer
              url={url}
              style={{ maxWidth: '80%', margin: 'auto' }}
              rotation={rotation}
            />

            <If condition={!isEmpty(url2)}>
              <ImageViewer
                url={url2}
                style={{ maxWidth: '80%', margin: 'auto', marginTop: '16px' }}
                rotation={rotation}
              />
            </If>
          </div>
        </If>
      </DocWrapper>
    </Dialog>
  );
}

const DocWrapper = styled.div`
  padding-top: 64px;
  height: calc(80vh - 64px);
  overflow: auto;
`;

const ImageViewerWrapper = styled.div`
  position: relative;

  .viewerActions {
    opacity: 0.8;
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 0px;
  }

  img {
    max-width: 100%;
  }

  &:hover {
    .viewerActions {
      opacity: 1;
    }
  }
`;
