import {
  Avatar,
  Checkbox,
  Grid,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { themes } from 'styles/theme/themes';
import { stringAvatar } from 'app/helpers/helpers';

export function AssigneePopover({
  popoverId,
  open,
  onClose,
  wrapperRef,
  data,
  setLimit,
  setSelectedEmployees,
  search,
  setSearch,
  selectedEmployees,
}) {
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setLimit((prevLimit) => prevLimit + 10);
    }
  };

  return (
    <Popover
      id={popoverId}
      open={open}
      onClose={onClose}
      anchorEl={wrapperRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: -5,
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPopover-paper': {
          width: '300px',
        },
      }}
    >
      <Wrapper>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
          placeholder="Search Employee"
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={{
                  color: themes?.default?.grey2,
                }}
              />
            ),
          }}
        />
        <Content onScroll={handleScroll}>
          {data?.map((employee) => (
            <Grid
              container
              alignItems="center"
              key={employee.id}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: themes?.default?.lightGrey,
                },
              }}
              onClick={() => {
                setSelectedEmployees((prev) => {
                  if (prev.includes(employee?._id)) {
                    return prev.filter((agent) => agent !== employee?._id);
                  }
                  return [...prev, employee?._id];
                });
              }}
            >
              <Checkbox checked={selectedEmployees.includes(employee?._id)} />
              <Avatar
                src={employee.pictureUrl}
                sx={{ width: '24px', height: '24px', mr: '12px' }}
              >
                <Typography fontSize="0.75rem">
                  {stringAvatar(
                    employee?.firstName + ' ' + employee?.lastName ?? '--',
                  )}
                </Typography>
              </Avatar>
              <Typography
                sx={{
                  width: '170px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {employee?.firstName + ' ' + employee?.lastName}
              </Typography>
            </Grid>
          ))}
        </Content>
      </Wrapper>
    </Popover>
  );
}

const Wrapper = styled.div`
  padding: 12px 8px;
`;

const Content = styled.div`
  height: 222px;
  overflow-y: auto;
`;
