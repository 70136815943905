import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { DownloadSimple } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { downloadFile } from 'app/helpers/helpers';

export const BulkActionsToolbar = () => {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext>('grid');
  const selectedRows = gridContext.selectedRows;
  const rowDataList = gridContext.rowData;

  function handleDownloadFiles() {
    const selectedFiles = rowDataList.filter((row) =>
      selectedRows?.includes(row?.id),
    );
    selectedFiles?.map((file) => {
      const fileUrl = file?.__download?.downloadData;
      const fileName = fileUrl.split('/').pop()?.split('_')[2];
      downloadFile(fileUrl, fileName);
    });
  }

  return (
    <Wrapper>
      <Tooltip title={t('common.download')}>
        <IconButton
          onClick={handleDownloadFiles}
          sx={{
            ':hover': {
              background: themes?.default?.rippleGrey,
            },
            padding: '4px',
            width: '28px',
            height: '28px',
          }}
        >
          <DownloadSimple />
        </IconButton>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
