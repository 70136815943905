import React, { useState, useEffect } from 'react';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from '../types';
import { FormControlLabel, IconButton, Stack, TextField } from '@mui/material';
import { Columns, PushPin } from '@phosphor-icons/react';
import { themes } from '../../../../styles/theme/themes';
import Typography from '@mui/material/Typography';
import { SearchOutlined } from '@mui/icons-material';
import Popover from '@mui/material/Popover';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../CustomCheckbox';
import Each from '../../../../common/UtilityComponents/Each';
import { Icon } from '../../Icon';
import { useTheme } from 'common/hooks/useTheme';
import { capitalize } from 'lodash';

function ManagedColumnOrderer({ disabled }) {
  const { columnDefs, api } = useManagedContext<IGridContext>('grid');
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [search, setSearch] = React.useState<string>('');

  const [columns, setColumns] = useState(
    columnDefs.filter((column) => {
      return (
        column?.headerName !== '' &&
        (!search ||
          t(column?.headerName!)?.toLowerCase().includes(search?.toLowerCase()))
      );
    }),
  );
  useEffect(() => {
    setColumns(
      columnDefs.filter((column) => {
        return (
          column?.headerName !== '' &&
          (!search ||
            t(column?.headerName!)
              ?.toLowerCase()
              .includes(search?.toLowerCase()))
        );
      }),
    );
  }, [columnDefs, search]);
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const srcIndex = result?.source?.index;
    const destIndex = result?.destination?.index;
    if (destIndex === srcIndex) return;
    if (columns[srcIndex].lockPinned) return;
    if (columns[srcIndex].pinned)
      return api.pinColumn(columns[srcIndex].field!, columns[destIndex].pinned);
    if (!columns[srcIndex].pinned && columns[destIndex].pinned)
      return api.pinColumn(columns[srcIndex].field!, columns[destIndex].pinned);

    const updatedColumns = Array.from(columns);
    const [movedColumn] = updatedColumns.splice(srcIndex, 1);
    updatedColumns.splice(destIndex, 0, movedColumn);

    setColumns((prev) => ({ ...prev, draggable: updatedColumns }));
    api.moveColumnByField(columns[srcIndex].field!, columns[destIndex].field!);
  };

  return (
    <Stack justifyContent="center">
      <IconButton
        onClick={(e) => {
          if (!disabled) {
            setAnchorEl(e.currentTarget);
          }
        }}
        disabled={disabled}
        sx={{
          fontSize: '0.875rem',
          opacity: disabled ? 0.3 : 1,
          fontWeight: '500',
          cursor: disabled ? 'default' : 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Columns
          size={themes?.default?.iconSize}
          color={themes?.default?.iconColor}
        />
      </IconButton>
      <Popover
        id={!!anchorEl ? 'simple-popover' : undefined}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div>
          <Typography padding="12px 12px 12px 16px" minWidth={123}>
            <TextFieldContainer
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <TextField
                size="small"
                margin="normal"
                id="standard-bare"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                InputProps={{
                  startAdornment: <SearchOutlined sx={{ cursor: 'pointer' }} />,
                }}
              />

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="columns-wrapper">
                  {(provided) => (
                    <div
                      style={{ display: 'flex', flexDirection: 'column' }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <Each
                        of={columns}
                        render={(column, index) => (
                          <ColumnControl
                            key={column.field}
                            column={column}
                            index={index}
                          />
                        )}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </TextFieldContainer>
          </Typography>
          <PopOverFotter>
            <SelectClearEL
              onClick={() =>
                api.setColumnsVisible(
                  columns.map((column) => column.field!),
                  false,
                )
              }
            >
              {t('common.buttons.clear_all')}
            </SelectClearEL>
            <SelectClearEL
              onClick={() =>
                api.setColumnsVisible(
                  columns.map((column) => column.field!),
                  true,
                )
              }
            >
              {t('common.buttons.select_all')}
            </SelectClearEL>
          </PopOverFotter>
        </div>
      </Popover>
    </Stack>
  );
}

const ColumnControl = ({ column, index }) => {
  const gridContext = useManagedContext<IGridContext>('grid');

  const theme = useTheme();

  const { t } = useTranslation();
  if (!column.field || !!column.disabled) return null;
  return (
    <Draggable
      draggableId={column.field}
      index={index}
      isDragDisabled={column.lockPinned || column.pinned === 'right'}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            userSelect: 'none',
            cursor: 'grab',
          }}
        >
          <FormControlLabel
            key={column.field}
            style={{
              cursor: 'grab',
              width: '100%',
            }}
            sx={{
              '& .MuiFormControlLabel-label': {
                width: '100%',
              },
            }}
            control={<Checkbox checked={!column.hide} />}
            label={
              <Stack
                direction="row"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                {capitalize(t(column.headerName!))}
                {column.pinned && (
                  <Icon icon={<PushPin />} color={theme.primaryActiveColor} />
                )}
              </Stack>
            }
            disabled={column.lockVisible}
            onChange={(e: any) => {
              gridContext.api.setColumnsVisible(
                [column.field!],
                e.target.checked,
              );
            }}
          />
        </div>
      )}
    </Draggable>
  );
};

const PopOverFotter = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '16px',
  gap: '0.125rem',
  height: '52px',
  background: props.theme.lightGrey,
  flex: 'none',
  order: 1,
  alignSelf: 'stretch',
  flexGrow: 0,
}));

const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectClearEL = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export default ManagedColumnOrderer;
