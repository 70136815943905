import { DrawerComponent } from 'app/components/DrawerComponent';
import { useTranslation } from 'react-i18next';
import { PaymentForm } from './PaymentForm';

interface PaymentDrawerProps {
  open: boolean;
  handleClose: () => void;
  customer?: any;
  order?: any;
}

export function PaymentDrawer({ open, handleClose }: PaymentDrawerProps) {
  const { t } = useTranslation();

  const isEdit = false; ///Todo implement edit functionality

  return (
    <DrawerComponent
      title={
        isEdit
          ? t('transaction.payment.edit.title')
          : t('transaction.payment.add.title')
      }
      open={open}
      handleClose={handleClose}
    >
      <PaymentForm handleClose={handleClose} />
    </DrawerComponent>
  );
}
