import { themes } from 'styles/theme/themes';

export enum ProductsStatus {
  RESTRICTED = 'restricted',
  TERMINATED = 'terminated',
  PAUSED = 'paused',
  ACTIVE = 'active',
  STOPPED = 'stopped',
  DRAFT = 'draft',
  PRE_ORDER = 'pre-order',
}

export const ProductsStatuses = [
  {
    status: ProductsStatus.ACTIVE,
    classNameCircle: 'orange_circle',
    background: themes?.default?.whisper,
    color: themes?.default?.greenStatus,
    label: 'active',
  },
  {
    status: ProductsStatus.DRAFT,
    background: themes?.default?.whisper,
    color: themes?.default?.nobel,
    label: 'orders.status.initiated',
  },
  {
    status: ProductsStatus.RESTRICTED,
    background: themes?.default?.whisper,
    color: themes?.default?.lightBlue300,
    label: 'status.restricted',
  },

  {
    status: ProductsStatus.STOPPED,
    classNameCircle: 'orange_circle',
    background: themes?.default?.bgPendingCustomer,
    color: themes?.default?.lightBrown,
    label: 'status.stopped',
  },

  {
    status: ProductsStatus.PAUSED,
    label: 'status.paused',
    classNameCircle: 'yellow_circle',
    background: themes?.default?.teal50,
    color: themes?.default?.teal800,
    dotColor: themes?.default?.OrangeDark,
  },
  {
    status: ProductsStatus.TERMINATED,
    classNameCircle: 'orange_circle',
    background: themes?.default?.bgPending,
    color: themes?.default?.chipRedBg,
    label: 'status.terminated',
  },
  {
    status: ProductsStatus.PRE_ORDER,
    classNameCircle: 'purple_circle',
    background: themes?.default?.purple50,
    color: themes?.default?.purpleLight400,
    label: 'status.pre_order',
  },
];

export const ProductStatsStatus = {
  ACTIVE: ProductsStatus.ACTIVE,
  PAUSED: ProductsStatus.PAUSED,
  PRE_ORDER: ProductsStatus.PRE_ORDER,
  STOPPED: ProductsStatus.STOPPED,
};

export const ProductStatsColors = {
  PAUSED: {
    thumbColor: themes?.default?.orange600,
    backgroundColor: themes?.default?.orange50,
    railColor: themes?.default?.orange100,
    tooltip: 'status.paused',
  },
  ACTIVE: {
    thumbColor: themes?.default?.fruitSaladGreen,
    backgroundColor: themes?.default?.teal50,
    railColor: themes?.default?.green100,
    tooltip: 'active',
  },
  PRE_ORDER: {
    thumbColor: themes?.default?.purpleLight400,
    backgroundColor: themes?.default?.purple50,
    railColor: themes?.default?.purple100,
    tooltip: 'status.pre_order',
  },
  STOPPED: {
    thumbColor: themes?.default?.brown800,
    backgroundColor: themes?.default?.brown50,
    railColor: themes?.default?.brown100,
    tooltip: 'status.stopped',
  },
};

export enum ProductOrderFequency {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NOT_ORDERED = 'not-ordered',
}

export enum ProductImageActions {
  PRIMARY = 'primary',
  DELETE = 'delete',
  NEW = 'new',
}
