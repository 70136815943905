import React, { useEffect, useMemo } from 'react';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import styled from 'styled-components';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Collapse, LinearProgress, TextField } from '@mui/material';
import { IGridContext } from 'app/components/ManagedGrid/types';
import InputAdornment from '@mui/material/InputAdornment';
import { themes } from 'styles/theme/themes';

export const CustomInputCell = (params: CustomCellRendererProps) => {
  const gridContext = useManagedContext<IGridContext>('grid');
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [busy, setBusy] = React.useState(false);
  const [displayValue, setDisplayValue] = React.useState(
    params.value?.cellData,
  );

  const setValue = (setter: number | ((previous: number) => number)) => {
    const { min = 0, max = 100 } = params.value ?? {};
    const minValue = Number.isFinite(min) ? min : 0;
    const maxValue = Number.isFinite(max) ? max : 100;
    gridContext.updateDataWithFunction((prev) => {
      prev.supressLoadingState = true;
      const v =
        setter instanceof Function //
          ? setter(prev.extraData.currentVal)
          : setter;
      const bounded = Math.max(minValue, Math.min(maxValue, v));
      prev.extraData.currentVal = bounded;
      setDisplayValue(bounded); // Update display value immediately
    });
  };

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (ev) => {
    const v = ev.target.value;
    const cleaned = v.replace(/\.$/, '') || '0';
    const parsed = parseFloat(cleaned);
    const customValidation = params.value?.customValidation;
    if (!!customValidation && !customValidation(parsed))
      return ev.preventDefault();

    if (isNaN(parsed)) return ev.preventDefault();
    setValue(parsed);
    ev.preventDefault();
    ev.stopPropagation();
  };

  const onScroll: React.WheelEventHandler<HTMLDivElement> = (ev) => {
    const step = ev.deltaY < 0 ? 1 : -1;
    setValue((p) => p + step);
    ev.preventDefault();
    ev.stopPropagation();
  };

  const onBlur = async () => {
    // if value is not changed => do nothing
    if (params.value?.cellData === gridContext.extraData.currentVal)
      return gridContext.updateDataWithFunction((ctx) => {
        ctx.extraData.onBlur = false;
      });

    setBusy(true);
    gridContext.updateDataWithFunction((ctx) => {
      ctx.supressLoadingState = true;
      ctx.extraData.onBlur = false;
    });
    await params.value?.onAction(gridContext.extraData.currentVal);
    setBusy(false);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    setDisplayValue(params.value?.cellData);
  }, [params.value?.cellData]);

  const isEditing = useMemo(
    () =>
      gridContext.extraData.onBlur &&
      gridContext.extraData.selectedRow === params.value?.id &&
      gridContext.extraData.currentInput === params.value?.field &&
      params.value?.canEdit,
    [
      gridContext.extraData.currentInput,
      gridContext.extraData.onBlur,
      gridContext.extraData.selectedRow,
      params.value?.canEdit,
      params.value?.field,
      params.value?.id,
    ],
  );

  return (
    <TextItem
      canEdit={params.value?.canEdit || false}
      isSelected={gridContext.extraData.selectedRow === params.value?.id}
      color={themes?.default?.silver}
      onClick={() => {
        if (params.value?.canEdit && !gridContext.extraData.onBlur) {
          gridContext.updateDataWithFunction((prev) => {
            prev.extraData.selectedRow = params.value?.id;
            prev.extraData.currentInput = params.value?.field;
            prev.extraData.onBlur = true;
            prev.extraData.currentVal = parseFloat(params.value?.cellData);
          });
        }
      }}
    >
      {isEditing ? (
        <TextField
          autoFocus
          disabled={busy}
          required
          ref={inputRef}
          id={`input-${params.value?.id}`}
          onChange={onChange}
          onBlur={onBlur}
          onWheel={onScroll}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') onBlur();
            else if (ev.key.startsWith('Arrow')) ev.stopPropagation();
          }}
          variant="outlined"
          type="number"
          value={gridContext.extraData.currentVal.toString()}
          size="small"
          InputProps={{
            inputProps: {
              min: params.value?.min ?? 0,
              max: params.value?.max ?? 100,
            },
            endAdornment: params.value?.inputAdornment && (
              <InputAdornment position="end">
                {params.value?.inputAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            marginBottom: '0 !important',
            width: '100%',
          }}
        />
      ) : (
        <TextTitle key={params.value?.field}>
          {displayValue === '0' || displayValue === 0
            ? '-'
            : displayValue + (params.value?.inputAdornment || '')}
        </TextTitle>
      )}
      <Collapse
        in={busy}
        sx={{ position: 'absolute', bottom: 0, width: '100%' }}
      >
        <LinearProgress />
      </Collapse>
    </TextItem>
  );
};

const TextTitle = styled.span`
  font-weight: 400;
  padding-left: 10px;
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextItem = styled.div<{
  isSelected: boolean;
  color: string;
  canEdit: boolean;
}>`
  margin-right: 8px;
  width: 100%;
  cursor: ${(props) => (props.canEdit ? 'pointer' : 'unset')};
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  position: relative;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  background-color: inherit;
  border-radius: 3px;
  max-height: 80%;

  border-width: 0.8px;
  border-style: solid;
  border-color: #0000;
  transition: border-color 0.15s ease-out;
  :hover {
    border-color: ${(props) => (props.canEdit ? props.color : '#0000')};
  }
`;
