import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import { FilterSection } from 'app/components/FilterComponents/styles';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useCallback, useMemo, useState } from 'react';
import { ContractRowStatus, ContractTabs } from '../../types';
import {
  AutocompleteRenderGetTagProps,
  Box,
  Chip,
  FormControlLabel,
  Stack,
  Switch,
} from '@mui/material';
import { useHelpers } from '../../useHelpers';
import { useSearchParams } from 'react-router-dom';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { Cancel } from '@mui/icons-material';
import { NoteAuthorAvatar } from '../Cells/NotesCell/components/Note';
import { StatusChipCell } from '../Cells/StatusChipCell';

interface FilterOptions {
  value: string;
  label: string;
  count?: number;
  backgroundColor?: string;
  color?: string;
  pictureUrl?: string;
}

export const ContractsFilter = () => {
  const { t } = useTranslation();
  const { getContractFacets } = useHelpers();
  const [searchParams] = useSearchParams();
  const [searchUsers, setSearchUsers] = useState('');

  const gridContext = useManagedContext<IGridContext>('grid');

  const fetchQueryParams = useQueryParams({
    limit: 20,
    text: searchUsers,
  });

  const { data: users } = useGetUsersBasicQuery(fetchQueryParams);

  const handleOptionsChange = useCallback(
    (filterKey: string, options: { value: string; label: string }[]) => {
      gridContext.updateDataWithFunction((prev) => {
        prev.queryParams.filters[filterKey] = options;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridContext?.queryParams?.filters],
  );

  const handleClearAll = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters = {
        status: [],
        lastEditedBy: [],
        hasNotes: false,
      };
    });
  };

  const usersOptions = useMemo<FilterOptions[]>(() => {
    if (!users?.docs) return [];

    return users.docs.map((user) => ({
      value: user?._currentAgent?._id || user?._id,
      label: user.fullName,
      pictureUrl: user.pictureUrl,
    }));
  }, [users?.docs]);

  const statusOptions = useMemo<FilterOptions[]>(() => {
    const facets = getContractFacets(
      searchParams.get('contract') as ContractTabs,
    );
    return Object.values(ContractRowStatus).map((status) => ({
      value: status,
      label: t(`contract.status.${status}`),
      count: facets?.[status],
    }));
  }, [getContractFacets, searchParams, t]);

  const handleSelectFilterChange = useCallback(
    (filterKey: string, options: FilterOptions[]) => {
      if (filterKey === 'lastEditedBy') {
        return handleOptionsChange(
          filterKey,
          options.map(
            (option) =>
              usersOptions.find((user) => user.value === option.value) ||
              option,
          ),
        );
      }
      handleOptionsChange(filterKey, options);
    },
    [handleOptionsChange, usersOptions],
  );

  return (
    <>
      <FilterHeader onClearAll={handleClearAll} />
      <FilterSection>
        <MultiSelect
          filterName={t('common.status')}
          selectedOptions={gridContext.queryParams.filters?.status || []}
          handleFilterChange={(options) =>
            handleSelectFilterChange('status', options)
          }
          filterItems={statusOptions}
          openByDefault
          autoCompleteProps={{
            renderTags: renderStatusTags,
          }}
        />
        <MultiSelect
          filterName={t('filters.last_edited_by')}
          selectedOptions={gridContext.queryParams.filters?.lastEditedBy || []}
          handleFilterChange={(options) =>
            handleSelectFilterChange('lastEditedBy', options)
          }
          filterItems={usersOptions}
          openByDefault
          onSearch={(value) => setSearchUsers(value)}
          autoCompleteProps={{
            renderTags: renderUsersTags,
            renderOption: renderUserOptions,
          }}
        />
        <Stack gap="24px">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                size="small"
                checked={gridContext.queryParams.filters.hasNotes}
                onChange={(e) => {
                  gridContext.updateDataWithFunction((prev) => {
                    prev.queryParams.filters.hasNotes = e.target.checked;
                  });
                }}
              />
            }
            label={t('filters.has_notes')}
            sx={{ marginRight: '0px', marginLeft: '0px' }}
          />
        </Stack>
      </FilterSection>
    </>
  );
};

const renderUsersTags = (
  selectedOptions: FilterOptions[],
  getTagProps: AutocompleteRenderGetTagProps,
) => {
  return selectedOptions.map((option, index) => (
    <Chip
      size="small"
      label={option.label}
      {...getTagProps({ index })}
      deleteIcon={
        <Cancel
          sx={{
            color: `${option?.color} !important`,
          }}
        />
      }
      sx={{
        background: option?.backgroundColor
          ? option?.backgroundColor
          : `${option?.color}20`,
        color: option?.color,
      }}
      avatar={
        <Box>
          <NoteAuthorAvatar
            pictureUrl={option.pictureUrl}
            fullName={option.label}
            size="18px"
            fontSize="0.625rem"
          />
        </Box>
      }
    />
  ));
};

const renderUserOptions = (props, option) => (
  <li {...props}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <NoteAuthorAvatar
        pictureUrl={option.pictureUrl}
        fullName={option.label}
        size="24px"
        fontSize="0.75rem"
      />
      {option.label}
    </Box>
  </li>
);

const renderStatusTags = (
  selectedOptions: FilterOptions[],
  getTagProps: AutocompleteRenderGetTagProps,
) => {
  return selectedOptions.map((option, index) => (
    <StatusChipCell
      {...getTagProps({ index })}
      value={option.value}
      sx={{ marginRight: '6px' }}
      size="small"
    />
  ));
};
