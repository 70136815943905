import {
  useLazyGetRoundtripsQuery,
  usePostRoundtripsMutation,
} from 'common/services/aiApi';
import { useRoundtripProcessor } from './roundtripProcessor';
import { useQueryParams } from 'hooks/useQueryParams';
import { useCallback } from 'react';

export const RoundtripsData = () => {
  const queryParams = useQueryParams({
    classification: 'Type A',
  });

  const [triggerGetRoundtrips] = useLazyGetRoundtripsQuery();
  const { processRoundtripsData } = useRoundtripProcessor();

  const fetchData = useCallback(async () => {
    try {
      const response = await triggerGetRoundtrips(queryParams).unwrap();
      if (response?.data && response.data.length > 0) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }, [triggerGetRoundtrips, queryParams]);

  const processRoundtripsDataBtn = useCallback(async () => {
    const fetchedData = await fetchData();
    return processRoundtripsData(fetchedData);
  }, [fetchData, processRoundtripsData]);

  return { processRoundtripsDataBtn };
};

export const PostRoundtripsData = () => {
  const [postRoundtrips] = usePostRoundtripsMutation();
  const { processRoundtripsData } = useRoundtripProcessor();

  const fetchData = useCallback(
    async (text) => {
      try {
        const response = await postRoundtrips({ text }).unwrap();
        if (response?.data && response.data.length > 0) {
          return response.data;
        } else {
          return response;
        }
      } catch (error) {
        return [];
      }
    },
    [postRoundtrips],
  );

  const processRoundtripsDataBtn = useCallback(
    async (text) => {
      const fetchedData = await fetchData(text);
      return processRoundtripsData(fetchedData);
    },
    [fetchData, processRoundtripsData],
  );

  return { processRoundtripsDataBtn };
};
