import React from 'react';
import { Stack, Typography } from '@mui/material';
import { UploadDocument } from '../../../../../UploadDocument';

interface Props {
  setUploadedDocuments: Function;
}

const DocumentAttachments = ({ setUploadedDocuments }: Props) => {
  return (
    <Stack marginTop={2} spacing={2}>
      <Typography fontSize={14} fontWeight={600}>
        Attachments *
      </Typography>
      <UploadDocument
        documents={[]}
        showUploadZone={true}
        disabled={false}
        setUploadedDocuments={setUploadedDocuments}
      />
    </Stack>
  );
};

export default DocumentAttachments;
