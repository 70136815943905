import { Stack, Typography } from '@mui/material';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { ViewAddNotes } from '../ViewAddNotes';
import { StateSetter } from 'types';
import { Note } from '../../../../../types';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { NoResults } from 'app/components/NoData/NoResults';
import styled from 'styled-components';

interface Props {
  open: boolean;
  onClose: () => void;
  subtitle?: JSX.Element | string;
  notes: Note[];
  onAddNote: (content: string) => void;
  addNoteOpen: boolean;
  setAddNoteOpen: StateSetter<boolean>;
  onCancel: () => void;
  newNote: string;
  onNoteChange: (value: string) => void;
  onDelete: (noteId: string) => void;
  onEdit: (noteId: string, content: string) => void;
  editingNoteId: string;
  isLoading: boolean;
}

export const NotesModal = ({
  open,
  onClose,
  subtitle,
  notes,
  onAddNote = () => {},
  addNoteOpen,
  setAddNoteOpen,
  onCancel,
  newNote,
  onNoteChange,
  onDelete,
  onEdit,
  editingNoteId,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const can = usePermission('customers');
  const canEdit = can(Permission.EDIT);

  return (
    <TwoViewModal
      onOpen={open}
      onClose={onClose}
      defaultMode="floated"
      title={t('common.notes')}
      headerStyle={{ padding: '10px 10px 10px 24px' }}
      hasArrowBack={false}
      autoHeight
    >
      <Stack
        gap="8px"
        padding="8px 0px"
        sx={{
          pointerEvents: isLoading ? 'none' : 'auto',
          opacity: isLoading ? '0.5' : '1',
        }}
      >
        {subtitle && canEdit && (
          <Typography
            fontWeight="400"
            fontSize="1rem"
            lineHeight="24px"
            letterSpacing="0.15px"
            color={theme.textBlack}
            sx={{ span: { fontWeight: '500' } }}
            padding="0px 24px"
          >
            {subtitle}
          </Typography>
        )}
        {notes?.length === 0 && !canEdit ? (
          <EmptyCenter>
            <NoResults />
          </EmptyCenter>
        ) : (
          <ViewAddNotes
            notes={notes}
            addNoteOpen={addNoteOpen}
            setAddNoteOpen={setAddNoteOpen}
            onCancel={onCancel}
            onAdd={onAddNote}
            newNote={newNote}
            onNoteChange={onNoteChange}
            onDelete={onDelete}
            onEdit={onEdit}
            editingNoteId={editingNoteId}
            canEdit={canEdit}
          />
        )}
      </Stack>
    </TwoViewModal>
  );
};

const EmptyCenter = styled.div`
  position: relative;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  height: 250px;
  top: 35px;
`;
