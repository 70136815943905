import { Receipt } from '@phosphor-icons/react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function TypeCell(params: CustomCellRendererProps) {
  const type = params?.value?.typeData;
  const { t } = useTranslation();

  return (
    <Tooltip title={t(`payments.${type}`)}>
      <TypeContainer>
        <Receipt
          color={themes?.ordoria?.primary}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      </TypeContainer>
    </Tooltip>
  );
}

const TypeContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themes?.ordoria?.primaryBackgroundColor};
`;

export default TypeCell;
