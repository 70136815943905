import { TableLegendWrapperProps } from 'app/pages/AddRoundTrips';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const TableNavWrapper = styled.div<TableLegendWrapperProps>`
  user-select: none;
  width: 100%;
  height: 100%;
  padding: 0 0;
  overflow: hidden;
  & .table-nav {
    height: ${(props) => props.theme.tableNavHeight};
    padding: 3px 0;
    background-color: ${themes?.default?.accordionWhiteBg};
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${themes?.default?.lightFill};
  }
  .on_label {
    border-bottom: groove 2px;
    padding: 8px;
    background: ${themes?.default.primary}0A;
    color: ${(props) => props?.theme?.primaryActiveColor};
  }
  .MuiCollapse-hidden {
    ${({ hide }) =>
      !!hide &&
      `
  display: none;
  `}
    .MuiAccordionDetails-root {
      padding: 0;
    }
  }
`;
export const ShadowTable = styled.div`
  border-right: 0.063rem solid rgba(42, 89, 255, 0.08);
  box-shadow: 0 0.125rem 15px rgb(139 139 139 / 10%);
  z-index: 3;
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
`;

export const TableNavTitle = styled.div`
  color: ${(props) => props.theme.textColorPrimary};
  font-weight: 500;
  font-size: 1.125rem;
  margin-left: 17px;
`;

export const StopsHeader = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  backgroundColor: themes?.default?.whiteSmoke,
  fontSize: '0.75rem',
  height: 25,
  borderBottom: '0.063rem solid rgb(224,224,224)',
  borderTop: '0.063rem solid rgb(224,224,224)',
  '& .title': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    color: themes.default.black,
    fontWeight: 'bold',
    minWidth: '130px',
    marginLeft: '25px',
  },
  '& .title-md': {
    minWidth: '70px',
    maxWidth: '70px',
  },
  '& .title-sm': {
    minWidth: '60px',
    maxWidth: '60px',
  },
  '& .title-xs': {
    minWidth: '40px',
    maxWidth: '40px',
  },
  '& .sticky-title': {
    maxWidth: '145px',
    minWidth: '145px',
    marginLeft: '0',
    position: 'sticky',
    left: '67px',
    backgroundColor: themes?.default?.whiteSmoke,
  },
  '.clientcode': {
    minWidth: '223px',
    marginLeft: '48px',
  },
  '& .stops-title': {
    display: 'flex',
    alignItems: 'center',
    color: themes.default.black,
    fontWeight: 'bold',
    width: '180px',
    marginRight: '20px',
  },
  '& .stops-title-xl': {
    width: '220px',
  },
  '& .stops-title-lg': {
    width: '160px',
  },
  '& .stops-title-md': {
    width: '120px',
  },
  '& .stops-title-sm': {
    width: '80px',
  },
  '& .sticky-stops-title': {
    maxWidth: '145px',
    minWidth: '145px',
    marginLeft: '0',
    position: 'sticky',
    left: '67px',
    backgroundColor: themes?.default?.whiteSmoke,
  },
});

export const AccordionList = styled.div((props) => ({
  backgroundColor: 'rgb(255,255,255)',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '0.75rem',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderBottom: `0.063rem solid ${props.theme.tableBorder}`,
  '.time div': {
    maxWidth: '100px',
    paddingTop: '2px',
    paddingLeft: '10px',
    paddingRight: '10px',
    placeContent: 'center',
    textAlign: 'center',
    height: '22px',
    border: `1px solid ${themes?.default?.greyDisabled}`,
    borderRadius: '16px',
    color: themes?.default?.textColorPrimary,
    display: 'flex',
    alignItems: 'center',
  },
  '& .accordion-data': {
    display: 'flex',
    gap: '3px',
    alignItems: 'center',
    flex: 1,
    minWidth: '130px',
    marginLeft: '25px',
  },
  '& .accordion-data-sm': {
    minWidth: '55px',
    maxWidth: '60px',
  },
  '& .accordion-data-xs': {
    minWidth: '40px',
    maxWidth: '40px',
  },
  '& .sticky-accordion-data': {
    fontSize: '0.875rem',
    maxWidth: '145px',
    position: 'sticky',
    left: '67px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    marginLeft: '0',
    zIndex: '2',
  },
  '& .stops-data': {
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    width: '180px',
    marginRight: '20px',
  },
  '& .stops-data-md': {
    width: '120px',
  },
  '& .stops-data-sm': {
    width: '80px',
  },
  '& .stops-data-lg': {
    width: '160px',
  },
  '& .stops-data-xl': {
    width: '220px',
  },
  '& .sticky-stops-data': {
    maxWidth: '145px',
    position: 'sticky',
    left: '67px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    marginLeft: '0',
    zIndex: '2',
  },
  '& .code:hover': {
    textDecoration: 'underline !important',
  },
}));

export const CustomDatePicker = styled.div({
  '& .custom-date-picker': {
    marginBottom: 'unset !important',
    '& .MuiInputBase-root': {
      height: 34,

      backgroundColor: 'rgba(0, 0, 0, 0.02) !important',
      '&:before': {
        borderBottom: 'unset !important',
      },
      '&:after': {
        borderBottom: 'unset !important',
      },
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
});

export const TableNavTitleWrapper = styled.div`
  display: flex;
  column-gap: 7px;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
`;

export const TableNavRightIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 17px;
  column-gap: 17px;
  @media (min-width: 1000px) {
    flex: 1;
  }
  justify-content: end;
  z-index: 1200;
`;

interface LoadStatusProps {
  status: 'fully' | 'partially' | 'empty';
}

export const LoadStatus = styled.div<LoadStatusProps>((props) => ({
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  width: 118,
  '& span': {
    width: '100%',
    color:
      props.status === 'fully'
        ? props.theme.green
        : props.status === 'partially'
          ? props.theme.orangePre
          : props.theme.textColorSecondary,
    borderRadius: 100,
    border: `0.063rem solid ${
      props.status === 'fully'
        ? props.theme.lightSuccessShade
        : props.status === 'partially'
          ? props.theme.orangePre
          : props.theme.textColorSecondary
      // props.theme.lightSuccessShade
    }`,
    padding: '0 10px',
  },
}));

export const ContainerCollected = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ParkingPlace = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ResultDetails = styled.div({
  display: 'flex',
  flex: '1',
  justifyContent: 'center',
  alignItems: 'center',

  '& div': {
    padding: '0 15px',
    color: themes.default.black,
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '1.5rem',
    letterSpacing: '0.00938rem',

    '& svg': {
      marginRight: '4px',
    },

    '& span': {
      fontWeight: '600',
    },

    '&:last-child': {
      borderRight: 'none',
    },
  },
});

export const TableWrapper = styled.div`
  width: calc(100% - 43px);
`;

export const LeftBar = styled.div`
  width: 43px;
  height: 100vh;
  background: ${themes?.default?.accordionWhiteBg};

  writing-mode: horizontal-tb;
  border-right: 1px solid ${themes?.default?.lightFill};
  box-shadow: 0 3px 15px rgb(139 139 139 / 10%);
`;

export const TopBar = styled.div`
  width: 100%;
  height: 43px;
  background: ${themes?.default?.accordionWhiteBg};
  gap: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${themes?.default?.lightFill};
  box-shadow: 0 3px 15px rgb(139 139 139 / 10%);
`;

export const Rotate = styled.div`
  transform: rotate(270deg);
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
`;
