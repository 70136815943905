import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import { useModal } from 'app/components/Dialog/hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

type GuardedActionContextType = {
  setOnDiscard: (fn: () => void) => void;
  executeAction: (action: () => void, hasChanges: boolean) => void;
};

const GuardedActionContext = createContext<
  GuardedActionContextType | undefined
>(undefined);

export const GuardedActionProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [onDiscard, setOnDiscard] = useState<() => void>(() => {});
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const executeAction = useCallback(
    async (action: () => void, hasChanges: boolean) => {
      if (!hasChanges || !onDiscard) {
        action();
        return;
      }

      openModal({
        title: t('title.discard_changes'),
        content: <>{t('discard_changes.confirmation')}</>,
        action: {
          actionText: t('actions.discard'),
          actionCallback: () => {
            onDiscard();
            closeModal();
            action();
          },
          buttonProps: {
            sx: { background: theme?.Cinnabar },
            color: 'error',
          },
        },
        hasCloseIcon: true,
        cancel: {
          actionText: t('go_back'),
          actionCallback: () => {
            closeModal();
          },
        },
      });
    },
    [openModal, t, theme?.Cinnabar, onDiscard, closeModal],
  );

  return (
    <GuardedActionContext.Provider value={{ setOnDiscard, executeAction }}>
      {children}
    </GuardedActionContext.Provider>
  );
};

export const useGuardedAction = () => {
  const context = useContext(GuardedActionContext);
  if (!context) {
    throw new Error(
      'useGuardedAction must be used within GuardedActionProvider',
    );
  }
  return context;
};
