import React, { useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Box, Button, Fade, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import ROCKET from 'assets/img/rocket.png';
import SHADOW from 'assets/img/yellow-shadow.svg';
import { X } from '@phosphor-icons/react';
import { ActionIconButton } from '../ActionIconButton';
import If from '../If';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import {
  selectDisplayFeaturePopup,
  selectFeatureVersion,
} from 'common/store/app/selectors';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { appActions } from 'common/store/app';

export const FeaturesPopup = () => {
  const { t } = useTranslation();
  const display = useSelector(selectDisplayFeaturePopup);
  const featureVersion = useSelector(selectFeatureVersion);
  const dispatch = useDispatch();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [blurEffect, setBlurEffect] = useState(false);

  const close = () => {
    setBlurEffect(true);
    dispatch(appActions.setDisplayFeaturePopup(false));
  };

  const slides = [
    {
      title: t('features.slide_1.title'),
      subtitle: t('features.slide_1.subtitle', { version: featureVersion }),
      content: t('features.slide_1.content'),
      background: themes.default.purpleLinearGradiant4,
      image: ROCKET,
      gradiant: themes.default.purpleLinearGradiant5,
    },
    {
      title: t('features.slide_2.title'),
      content: t('features.slide_2.content'),
      color: themes.default.purpleYellowLinearGradiant,
    },
    {
      title: t('features.slide_3.title'),
      content: t('features.slide_3.content'),
      color: themes.default.purpleYellowLinearGradiant,
    },
    {
      title: t('features.slide_4.title'),
      content: t('features.slide_4.content'),
      color: themes.default.purpleYellowLinearGradiant,
    },
    {
      title: t('features.slide_5.title'),
      content: t('features.slide_5.content'),
      color: themes.default.purpleYellowLinearGradiant,
    },
  ];

  const NavButtons = () => {
    const swiper = useSwiper();
    const isLastSlide = currentSlide === slides.length - 1;

    return (
      <Stack direction="row" gap="8px">
        <Button
          onClick={() => {
            swiper.slidePrev();
          }}
          variant="outlined"
          sx={{
            color: themes.default.textColorSecondary,
            borderColor: `${themes.default.textColorSecondary} !important`,
            opacity: currentSlide === 0 ? 0 : 1,
            transition: 'all 0.5s ease-in-out',
          }}
        >
          {t('common.previous')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (isLastSlide) {
              close();
            } else {
              swiper.slideNext();
            }
          }}
        >
          {t(isLastSlide ? 'get_started' : 'next')}
        </Button>
      </Stack>
    );
  };

  return (
    <Box
      position="fixed"
      bottom="20px"
      left="20px"
      zIndex="10"
      sx={{
        filter: blurEffect ? 'blur(6px)' : 'none',
        transition: 'all 0.5s ease-in-out',
      }}
    >
      <Fade in={!!display} timeout={{ enter: 1000, exit: 1500 }}>
        <Wrapper>
          <Swiper
            pagination={{
              el: '.custom-pagination',
              type: 'custom',
              renderCustom: (swiper, current, total) => {
                return `${current} ${t('common.of')} ${total}`;
              },
            }}
            modules={[Pagination]}
            onSlideChange={({ activeIndex }) => setCurrentSlide(activeIndex)}
          >
            {slides.map((slide, index) => {
              return (
                <SwiperSlide key={index}>
                  <Stack gap="12px">
                    <Box
                      padding="12px 8px 12px 20px"
                      sx={{
                        background: slide.background || '',
                      }}
                      borderRadius="8px 8px 0px 0px"
                      position="relative"
                      overflow="hidden"
                      height="118px"
                    >
                      <Stack
                        direction="row"
                        gap="8px"
                        alignItems="center"
                        justifyContent="space-between"
                        height="100%"
                        sx={{
                          background: slide.color || '',
                          backgroundClip: 'text',
                        }}
                      >
                        <Stack
                          direction="row"
                          gap="16px"
                          // paddingTop="16px"
                          sx={{
                            color: slide.color ? 'transparent' : 'white',
                          }}
                        >
                          <Typography fontSize="1.375rem" fontWeight="700">
                            {slide.title}
                          </Typography>
                          <If condition={!!slide.image}>
                            <img
                              src={slide.image}
                              alt=""
                              height="65px"
                              width="62px"
                            />
                          </If>
                        </Stack>
                        <Box height="100%">
                          <ActionIconButton
                            icon={<X />}
                            title=""
                            onClick={close}
                            iconProps={{ color: slide.color || 'white' }}
                          />
                        </Box>
                      </Stack>
                      <GradiantBlock color={slide.gradiant} />
                      <Shadow src={SHADOW} alt="" />
                    </Box>
                    <Box paddingX="12px">
                      <Typography fontSize="1rem" fontWeight="700">
                        {slide.subtitle || t('what_is_it')}
                      </Typography>
                      <Typography fontSize="0.875rem" color="text.secondary">
                        {slide.content || ''}
                      </Typography>
                    </Box>
                  </Stack>
                </SwiperSlide>
              );
            })}

            <Stack
              direction="row"
              justifyContent="space-between"
              padding="8px 12px 16px 12px"
              alignItems="center"
            >
              <Typography
                fontSize="0.875rem"
                color="text.secondary"
                className="custom-pagination"
                width="fit-content"
              />
              <NavButtons />
            </Stack>
          </Swiper>
        </Wrapper>
      </Fade>
    </Box>
  );
};

const Wrapper = styled(Box)`
  width: 300px;
  border-radius: 8px;
  background: ${({ theme }) => theme.baseWhite};
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

const GradiantBlock = styled.div<{ color?: string }>`
  height: 20px;
  position: absolute;
  bottom: -10px;
  width: 100%;
  left: 0;
  opacity: ${({ color }) => (!!color ? '1' : '0.5')};
  background: ${({ color, theme }) => color || theme.purpleLinearGradiant3};
  filter: blur(6px);
`;

const Shadow = styled.img`
  filter: blur(4.5px);
  position: absolute;
  bottom: -30px;
  right: -120px;
  animation: move 6s linear infinite alternate;
  animation-delay: 2s;

  @keyframes move {
    0% {
      right: -120px;
    }
    100% {
      right: 100%;
    }
  }
`;
