import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
  Stack,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState, useRef, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useGetCustomerQuery } from 'common/services/customerApi';
import { useNavigate } from 'react-router-dom';
import {
  useCreateOrderMutation,
  useLazyGetOrdersQuery,
  useUpdateCustomerOrderMutation,
} from 'common/services/orderApi';
import { TemplatesCards } from 'app/components/TemplatesCards';
import { filterItems } from 'functions/searchItemInList';
import moment from 'moment';
import 'moment/locale/fr';
import { useLazyGetTemplatesOrderQuery } from 'common/services/templates';
import _without from 'lodash/without';
import React from 'react';
import { DrawerComponent } from 'app/components/DrawerComponent';
import { ChipWithRadio } from 'app/pages/Customers/components/ChipWithRadio';
import { UpdateOrderFormData } from 'app/pages/Ordoria/SalesOrderDetail';
import { themes } from 'styles/theme/themes';
import { Info, User } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import {
  InputsContainer,
  WarningBadge,
  ErrorBadge,
  SalesPersonBadge,
  ButtonLoadingContainer,
  activeOrderStatuses,
  getDeliveryDaysFromRountripDays,
} from '../CreateOrderDrawerSales';
// import { toTitleCase } from 'common/helpers/stringFormatter';
import { generateParamsString } from 'app/pages/Ordoria/cart/components/DeleteBtn';
import { deleteProductsFromSelectedObject } from 'common/store/cart';
import { useDispatch } from 'react-redux';
import { useDeleteProductsFromCartMutation } from 'common/services/cartApi';
import { DeliveryDateCalendar } from '../DeliveryCalendar/DeliveryDateCalendar';
import { Form, FormActions, FormWrapper } from 'app/components/Form/styles';
import If from 'app/components/If';
import styled from 'styled-components';
import EllipsisText from 'app/components/EllipsisText';
import { selectTheme } from 'styles/theme/slice/selectors';
import { uniq } from 'lodash';

interface CreateOrderDrawerProps {
  open: boolean;
  closeDrawer: () => void;
  selectedProducts?: any[];
  hideTemplates?: boolean;
}

export function CreateOrderDrawer({
  open,
  closeDrawer,
  selectedProducts,
  hideTemplates = false,
}: CreateOrderDrawerProps) {
  const authUser = useSelector(selectAuthUser);
  const contentWrapperRef = useRef(null);
  const selectBranchRef = useRef<HTMLDivElement | null>(null);
  const { t, i18n } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const customerId = authUser?.currentAgent?._customer?.id || null;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [isCreatingOrder, setIsCreatingOrder] = useState<boolean>(false);
  const [orderOnSameDate, setOrderOnSameDate] = useState<boolean>(false);
  const [isPendingOrderData, setIsPendingOrderData] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [internalOrderCode, setInternalOrderCode] = useState<string>('');
  const [isNotTypicalDate, setIsNotTypicalDate] = useState(false);
  const [radioValue, setRadioValue] = useState<string>('delivery');
  const [dateValue, setDateValue] = useState<moment.Moment>(moment());
  const [searchTemplateIsFocused, setSearchTemplateIsFocused] = useState(false);
  const theme = useSelector(selectTheme);

  const handleTemplateClick = (templateId) => {
    setSelectedTemplate(templateId);
  };

  const handleSearchTextChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  };

  const { data } = useGetCustomerQuery(customerId);
  const decks = data?.decks;

  const decksId = decks?.map((deck) => deck._id) || [];

  const [triggerGetOrder] = useLazyGetOrdersQuery();

  useEffect(() => {
    if (!open || !selectedBranch?._id) return;
    const formattedDateValue = moment(dateValue).format('YYYY/MM/DD');

    const orderQuery = `?&beginAt=${formattedDateValue}&endAt=${formattedDateValue}&ordoria=true&customerDeck=${selectedBranch?._id}`;

    triggerGetOrder(orderQuery);
  }, [dateValue, open, selectedBranch?._id, triggerGetOrder]);

  useEffect(() => {
    if (!open || !selectedBranch?._id) return;
    const formattedDateValue = dateValue.format('YYYY/MM/DD');

    const orderQuery = `?&beginAt=${formattedDateValue}&endAt=${formattedDateValue}&customerDeck=${selectedBranch?._id}&ordoria=true&status=${activeOrderStatuses}`;

    if (dateValue && selectedBranch?._id) {
      setOrderOnSameDate(true);
      triggerGetOrder(orderQuery).then((res) => {
        if (!(res?.data.docs.length > 0)) {
          setOrderOnSameDate(false);
        }
        setIsPendingOrderData(false);
      });
    }
  }, [open, dateValue, selectedBranch?._id, triggerGetOrder]);

  const handleDateValueChange = (newValue) => {
    setIsPendingOrderData(true);
    setDateValue(newValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleOrderDeckChange = (event) => {
    setSelectedBranch(event.target.value);
    setIsPendingOrderData(true);
  };

  const [createOrderBtnDisabled, setCreateOrderBtnDisabled] =
    useState<boolean>(true);

  const handlecreateOrderBtnDisable = () => {
    if (dateValue === null || radioValue === '' || !selectedBranch) {
      setCreateOrderBtnDisabled(true);
    } else {
      setCreateOrderBtnDisabled(false);
    }
  };

  useEffect(() => {
    handlecreateOrderBtnDisable();
  }, [dateValue, radioValue, selectedBranch]);

  const [createOrder] = useCreateOrderMutation();
  const [updateOrder] = useUpdateCustomerOrderMutation();
  const [deleteProductsFromCart] = useDeleteProductsFromCartMutation();
  const dispatch = useDispatch();

  const handleCreateOrder = async () => {
    if (isCreatingOrder) return;
    else if (!dateValue || !radioValue) {
      setOpenAlert(true);
    } else {
      try {
        setIsCreatingOrder(true);
        const result = await createOrder({
          deliveryDate: dateValue.format('YYYY-MM-DD'),
          type: radioValue,
          deck: selectedBranch._id,
          templateId: selectedTemplate === null ? null : selectedTemplate,
          products: [],
          linkedDecks: [selectedBranch._id],
          customerId: customerId,
          internalOrderCode: internalOrderCode,
        }).unwrap();

        if (selectedProducts) {
          const formData: UpdateOrderFormData = {
            products: selectedProducts,
            discount: 0,
            customerId: customerId,
          };

          await updateOrder({
            id: result.createdOrder.id,
            formData,
          });

          const selectedProductIds = selectedProducts.map(
            (prod) => prod._product,
          );

          const paramsString = generateParamsString(selectedProductIds);
          dispatch(deleteProductsFromSelectedObject(selectedProductIds));

          await deleteProductsFromCart(paramsString);
        }
        setIsCreatingOrder(false);
        navigate(`${result.createdOrder.id}`);
      } catch (err) {
        console.error(err);
        setIsCreatingOrder(false);
      }
    }
  };

  const [trigger, { data: templates }] = useLazyGetTemplatesOrderQuery();

  useEffect(() => {
    trigger('');
  }, []);

  const filteredTemplates = filterItems(
    templates?.templates,
    searchText.trim(),
    ['name'],
  );

  ///Multiples Branches
  const [triggerActiveOrder] = useLazyGetOrdersQuery();

  const [orderStatusArray, setOrderStatusArray] = useState<
    { branchId: string; isActive: boolean }[]
  >([]);

  useEffect(() => {
    const formattedDateValue = moment(dateValue).format('YYYY/MM/DD');

    if (dateValue) {
      const updatedOrderStatusArray: { branchId: string; isActive: boolean }[] =
        [];

      decksId.forEach((id) => {
        if (!id) return;
        triggerActiveOrder(
          `?&beginAt=${formattedDateValue}&endAt=${formattedDateValue}&ordoria=true&status=${[
            'pending',
            'pending-updates',
            'pending-customer',
            'validated',
            'confirmed',
            'prepared',
          ]}&customerDeck=${id}`,
        )
          .then((res) => {
            updatedOrderStatusArray.push({
              branchId: id,
              isActive: res.data.docs.length > 0,
            });

            setOrderStatusArray(updatedOrderStatusArray);
          })
          .catch((error) => {
            console.error('Error fetching orders:', error);
          });
      });
    }
  }, [dateValue]);

  function handleBranches(event) {
    setSelectedBranch(event.target.value);
  }

  useEffect(() => {
    if (decks?.length === 1) {
      setSelectedBranch(decks[0]);
    }
  }, [decks]);

  useEffect(() => {
    if (selectedBranch?._id && dateValue) {
      const weekDayName = dateValue.locale('en').format('dddd');

      const deliveryDaysLower = selectedBranch?.roundtripDays
        ? getDeliveryDaysFromRountripDays(selectedBranch.roundtripDays)
        : [];

      setIsNotTypicalDate(
        !deliveryDaysLower.includes(weekDayName.toLowerCase()),
      );
    } else {
      setIsNotTypicalDate(false);
    }
  }, [dateValue, selectedBranch]);

  const allDeliveryDays = useMemo(() => {
    const allDays: string[] = selectedBranch?.roundtripDays
      ? getDeliveryDaysFromRountripDays(selectedBranch.roundtripDays)
      : [];
    const uniqueDaysArray = uniq(allDays);

    const dayIndexMap = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };

    moment.locale(i18n.language);

    const translatedDays = uniqueDaysArray.map((day) => {
      const dayIndex = dayIndexMap[day.toLowerCase()];
      return moment().day(dayIndex).format('dddd');
    });

    const titleCasedDays = translatedDays.map((day) => {
      return day.charAt(0).toUpperCase() + day.slice(1);
    });

    return titleCasedDays.join(', ');
  }, [selectedBranch, i18n.language]);

  return (
    <DrawerComponent
      open={open}
      handleClose={closeDrawer}
      title={t('orders.create_orders')}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormWrapper ref={contentWrapperRef} style={{ gap: '16px' }}>
          <If condition={openAlert}>
            <Snackbar
              open={openAlert}
              onClose={() => setOpenAlert(false)}
              autoHideDuration={9000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              sx={{
                boxShadow:
                  '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
              }}
            >
              <Alert
                severity="warning"
                sx={{ width: '506px' }}
                onClose={() => setOpenAlert(false)}
              >
                <AlertTitle>{t('order.typicalDate.title')}</AlertTitle>
                {t('order.typicalDate.subtitle')}
              </Alert>
            </Snackbar>
          </If>
          <FormSection>
            <SectionTitle>
              <Typography fontWeight="500" fontSize="0.9375rem">
                {t('clients.orderDetails.orderType')}
              </Typography>
            </SectionTitle>
            <FormControl sx={{ margin: '0 !important' }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue="delivery"
                onChange={handleChange}
              >
                <Grid container sx={{ rowGap: '12px' }}>
                  <FormControlLabel
                    value="delivery"
                    label=""
                    control={
                      <ChipWithRadio
                        value="delivery"
                        label={t('common.delivery')}
                        selectedValue={radioValue}
                      />
                    }
                  />
                  <FormControlLabel
                    value="collection"
                    label=""
                    control={
                      <ChipWithRadio
                        value="collection"
                        label={t('roundtrips.tooltip.collection')}
                        selectedValue={radioValue}
                      />
                    }
                  />
                </Grid>
              </RadioGroup>
            </FormControl>
          </FormSection>

          <FormSection>
            <SectionTitle>
              <Typography
                fontSize="0.9375rem"
                lineHeight="1.5rem"
                fontWeight="500"
                letterSpacing="0.15px"
                color={themes.default.textBlack}
              >
                {t('orders.title.dateBranch')}
              </Typography>
            </SectionTitle>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{
                marginBottom: '5px !important',
              }}
            >
              <Grid
                container
                flexDirection="column"
                gap="12px"
                sx={{ '.MuiFormControl-root': { margin: '0 !important' } }}
              >
                <InputsContainer>
                  <DeliveryDateCalendar
                    handleDateValueChange={handleDateValueChange}
                    dateValue={dateValue}
                    error={orderOnSameDate}
                    sx={{
                      flex: 1,
                    }}
                  />

                  <FormControl sx={{ flex: '1' }}>
                    <InputLabel id="branch-select-label">
                      {t('orders.branch')}
                    </InputLabel>
                    <Select
                      label={t('orders.branch')}
                      labelId="branch-select-label"
                      id="branch-select"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        handleBranches(e);
                        handleOrderDeckChange(e);
                      }}
                      ref={selectBranchRef}
                      value={selectedBranch}
                      renderValue={(selected: any) => (
                        <Grid container alignItems="center">
                          <EllipsisText
                            text={
                              '#' +
                              selected?.code +
                              ' - ' +
                              (selected?.location.shortAddress ||
                                selected?.location.name)
                            }
                            width={
                              (selectBranchRef?.current?.clientWidth || 150) -
                              32
                            }
                          />
                        </Grid>
                      )}
                    >
                      {decks?.map((deck) => (
                        <MenuItem
                          key={deck._id}
                          value={deck}
                          disabled={orderStatusArray.some(
                            (status) =>
                              status.branchId === deck._id && status.isActive,
                          )}
                        >
                          <Stack>
                            <Typography
                              fontFamily="Roboto"
                              fontWeight="400"
                              fontSize="10px"
                              lineHeight="12px"
                              letterSpacing="0.16px"
                              color={theme.textColorDashboard}
                            >
                              {'#' + deck?.code}
                            </Typography>
                            <Typography
                              fontWeight="400"
                              fontSize="14px"
                              lineHeight="16px"
                              letterSpacing="0.17px"
                              color={theme.textBlack}
                            >
                              {deck?.location.shortAddress ||
                                deck?.location.name}
                            </Typography>
                          </Stack>
                          {selectedBranch?._id === deck._id &&
                          !orderStatusArray.some(
                            (status) =>
                              status.branchId === deck._id && status.isActive,
                          ) ? (
                            <IconButton
                              key={selectedBranch?._id}
                              sx={{
                                p: 0,
                                ml: 'auto',
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          ) : null}
                          {orderStatusArray.some(
                            (status) =>
                              status.branchId === deck._id && status.isActive,
                          ) && (
                            <Typography
                              fontSize="0.75rem"
                              fontWeight={400}
                              sx={{
                                color: '#E65100',
                                fontStyle: 'italic',
                                ml: 'auto',
                              }}
                            >
                              Has an active order
                            </Typography>
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputsContainer>
                <InputsContainer>
                  <TextField
                    label={t('internal_order_code')}
                    size="small"
                    value={internalOrderCode}
                    onChange={(e) => setInternalOrderCode(e.target.value || '')}
                    sx={{ width: 'calc(50% - 8px)' }}
                  />
                </InputsContainer>
                <Stack flexDirection="row" gap="10px" flexWrap="wrap">
                  <SalesPersonBadge>
                    <Icon icon={<User />} size={14} />
                    <Typography
                      fontSize="0.75rem"
                      lineHeight="1.5rem"
                      letterSpacing="0.0094rem"
                      fontWeight="300"
                      color={themes.default.textBlack}
                    >
                      {t('sale_person')}{' '}
                      <span style={{ fontWeight: '500' }}>
                        {data?.cache?._salesman?.fullName || ''}
                      </span>
                    </Typography>
                  </SalesPersonBadge>
                  {isNotTypicalDate && (
                    <WarningBadge>
                      <Icon
                        icon={<Info />}
                        size={14}
                        color={themes.default.warningMain}
                      />
                      <Typography
                        fontSize="0.75rem"
                        lineHeight="1.5rem"
                        fontWeight="500"
                        letterSpacing="0.025rem"
                        color={themes.default.warningMain}
                      >
                        {t('orders.create_order.typical_ordering_days', {
                          days: allDeliveryDays,
                        })}
                      </Typography>
                    </WarningBadge>
                  )}
                  {!isPendingOrderData && orderOnSameDate && (
                    <ErrorBadge>
                      <Icon
                        icon={<Info />}
                        size={14}
                        color={themes.default.red800}
                      />
                      <Typography
                        fontSize="0.75rem"
                        lineHeight="1.5rem"
                        fontWeight="500"
                        letterSpacing="0.025rem"
                        color={themes.default.red800}
                      >
                        {t('orders.order_with_same_data')}
                      </Typography>
                    </ErrorBadge>
                  )}
                </Stack>
              </Grid>
            </LocalizationProvider>
          </FormSection>

          <If condition={!hideTemplates}>
            <FormSection>
              <div className="template">
                <Grid container alignItems="center">
                  <Typography
                    fontWeight="500"
                    fontSize="0.9375rem"
                    sx={{
                      mb: 2,
                    }}
                  >
                    {t('orders.title.chooseTemplate')}
                  </Typography>
                  <TextField
                    size="small"
                    label={t('orders.search_templates')}
                    value={searchText}
                    onChange={handleSearchTextChange}
                    sx={{
                      width: '276px',
                      ml: 'auto',
                    }}
                    onFocus={() => setSearchTemplateIsFocused(true)}
                    onBlur={() => setSearchTemplateIsFocused(false)}
                    InputProps={{
                      startAdornment: searchTemplateIsFocused ? (
                        <InputAdornment position="start">
                          <SearchIcon color="action" />
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                </Grid>
                <Grid
                  container
                  sx={{
                    paddingRight: '24px',
                    overflow: 'auto',
                    maxHeight: 'calc(100% - 55px)',
                  }}
                >
                  <Card
                    sx={{
                      width: '170px',
                      mr: '13px',
                      height: '208px',
                      border:
                        selectedTemplate === null
                          ? '1px solid #B682FF'
                          : '1px solid transparent',
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => handleTemplateClick(null)}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F5EEFF',
                        height: '77px',
                        margin: '0',
                        padding: '0',
                      }}
                    >
                      <AddIcon
                        sx={{
                          height: '46px',
                          width: '46px',
                          color: '#6600F4',
                        }}
                      />
                    </CardContent>
                    <CardContent>
                      <Typography fontWeight="500" fontSize="0.9375rem">
                        {t('orders.blank')}
                      </Typography>
                      <Typography
                        fontSize="0.857rem"
                        fontWeight="400"
                        sx={{
                          color: 'grey',
                          mt: '1px',
                        }}
                      >
                        {t('orders.subtitle.create_order_groundUp')}
                      </Typography>
                    </CardContent>
                  </Card>

                  {filteredTemplates?.map((template) => (
                    <div key={template?._id}>
                      <TemplatesCards
                        name={template?.name}
                        description={template?.description}
                        weekDays={template?.weekDays}
                        productsNumber={template?.products.length}
                        isSelected={selectedTemplate === template?._id}
                        select={() => handleTemplateClick(template?._id)}
                        createdBy={template?._createdBy?.fullName}
                        type="orders"
                      />
                    </div>
                  ))}
                </Grid>
              </div>
            </FormSection>
          </If>

          <FormActions style={{ gap: '12px' }}>
            <Button
              sx={{ color: themes.default.lightGrayLabel }}
              onClick={closeDrawer}
            >
              {t('common.buttons.cancel')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={createOrderBtnDisabled || orderOnSameDate}
              onClick={handleCreateOrder}
              sx={{ color: isCreatingOrder ? 'transparent' : '' }}
            >
              {isCreatingOrder && (
                <ButtonLoadingContainer>
                  <CircularProgress
                    size="16px"
                    sx={{ color: themes.default.accordionWhiteBg }}
                  />
                </ButtonLoadingContainer>
              )}
              {t('common.buttons.proceed')}
            </Button>
          </FormActions>
        </FormWrapper>
      </Form>
    </DrawerComponent>
  );
}

const SectionTitle = styled.div`
  font-size: 0.9375rem;
  font-weight: 500;
  color: ${(props) => props.theme.black};
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
