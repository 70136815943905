import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import { TabCard } from 'app/styledComponents/DetailsSection.styled';

const BranchTabSkeleton = () => {
  return (
    <>
      <TabCard height="auto">
        <Skeleton variant="text" width="50px" height={30} component="div" />
        <Stack direction="row" gap="12px" height={40}>
          <Skeleton
            variant="rectangular"
            height={40}
            width="100%"
            component="div"
          />
          <Skeleton
            variant="rectangular"
            height={40}
            width="100%"
            component="div"
          />
          <Skeleton
            variant="rectangular"
            height={40}
            width="100%"
            component="div"
          />
        </Stack>
      </TabCard>

      <TabCard height="auto">
        <Skeleton variant="text" width="40%" height={30} component="div" />
        <Skeleton variant="rectangular" height={150} component="div" />
      </TabCard>

      <TabCard height="auto">
        <Skeleton variant="text" width="40%" height={30} component="div" />
        <Stack direction="row" gap="12px">
          <Skeleton
            variant="rectangular"
            height={56}
            width="100%"
            component="div"
          />
          <Skeleton
            variant="rectangular"
            height={56}
            width="100%"
            component="div"
          />
          <Skeleton
            variant="rectangular"
            height={56}
            width="100%"
            component="div"
          />
        </Stack>
      </TabCard>

      <TabCard height="auto">
        <Skeleton variant="text" width="40%" height={30} component="div" />
        <Skeleton variant="rectangular" height={150} component="div" />
      </TabCard>

      <TabCard height="auto">
        <Skeleton variant="text" width="40%" height={30} component="div" />
        <Stack direction="row" gap="12px">
          <Skeleton
            variant="rectangular"
            height={56}
            width="100%"
            component="div"
          />
          <Skeleton
            variant="rectangular"
            height={56}
            width="100%"
            component="div"
          />
          <Skeleton
            variant="rectangular"
            height={56}
            width="100%"
            component="div"
          />
        </Stack>
      </TabCard>
    </>
  );
};

export default BranchTabSkeleton;
