import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Avatar, Tooltip, Typography } from '@mui/material';
import If from 'app/components/If';
import { stringAvatar } from 'app/helpers/helpers';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ClaimsContext } from '../ClaimsProvider';
import { useContext } from 'react';

function ReportedByCell(params: CustomCellRendererProps) {
  const { t } = useTranslation();
  const { clientEmail, pictureUrlData, reportedByData, reportedByEmail } =
    params.value;

  const { isUserCustomer } = useContext(ClaimsContext);

  return (
    <If
      condition={clientEmail === reportedByEmail && !isUserCustomer}
      otherwise={
        <If
          condition={!isEmpty(pictureUrlData)}
          otherwise={
            <Tooltip title={reportedByData}>
              <Avatar
                sx={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer',
                }}
              >
                <Typography fontSize="0.75rem">
                  {stringAvatar(reportedByData || '')}
                </Typography>
              </Avatar>
            </Tooltip>
          }
        >
          <Tooltip title={reportedByData}>
            <Avatar
              src={pictureUrlData}
              sx={{
                width: '24px',
                height: '24px',
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </If>
      }
    >
      <Typography fontSize="0.875rem" fontWeight={400}>
        {t('common.customer')}
      </Typography>
    </If>
  );
}

export default ReportedByCell;
