import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IGridContext, ITabsContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';
import ConfirmPaymentsModal from './ConfirmPaymentsModal';
import { ListPaymentContext } from '../ListComponent/ListPaymentProvider';
import { LIST } from 'app/components/TableComponent/constants';
import Button from '@mui/material/Button';
import { IconButton, Tooltip } from '@mui/material';
import { Archive, DownloadSimple } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { downloadFile } from 'app/helpers/helpers';
import If from 'app/components/If';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import { useUpdateArchiveInvoiceMutation } from 'common/services/transactions';
import { useModal } from 'app/components/Dialog/hooks';

export const BulkActionsToolbar = () => {
  const { selectedTab } = useManagedContext<ITabsContext>('tabs');

  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const gridContext = useManagedContext<IGridContext>('grid');

  const { selectedPaymentsIds, rowDataList } = useContext(ListPaymentContext);

  const isUnpaid = selectedRows.some(
    (row) => row?.accountingPaymentStatus === 'pending',
  );

  function handlePayment() {
    setDisplayConfirmationModal(true);
  }

  const { isCustomer } = useGetUserAcl();

  useEffect(() => {
    if (gridContext.viewMode === LIST) {
      setSelectedRows(
        rowDataList.filter(
          (row) =>
            selectedPaymentsIds?.includes(row?._id) &&
            row?.status?.paymentStatusData?.accountingPaymentStatus !== 'paid',
        ) || [],
      );
    } else {
      setSelectedRows(
        gridContext?.responseData?.filter(
          (row) =>
            gridContext.selectedRows?.includes(row._id) &&
            row?.status?.paymentStatusData?.accountingPaymentStatus !== 'paid',
        ) || [],
      );
    }
  }, [rowDataList, gridContext.selectedRows, selectedPaymentsIds]);

  function handleDownloadFiles() {
    if (gridContext.viewMode === LIST) {
      const selectedFiles = rowDataList.filter((row) =>
        selectedPaymentsIds?.includes(row?._id),
      );
      selectedFiles?.map((bill) => {
        downloadFile(
          bill?.initialUrl,
          bill?.metadata?.invoiceCode || 'file.pdf',
        );
      });
    } else {
      const selectedFiles = gridContext?.rowData?.filter((row) =>
        gridContext.selectedRows.includes(row.id),
      );

      if (selectedTab === 'bills') {
        selectedFiles?.map((bill) => {
          const { initialUrlData, invoiceCodeData } = bill?.invoiceCode;
          downloadFile(initialUrlData, invoiceCodeData + '.pdf' || 'file.pdf');
        });
      } else if (selectedTab === 'refund') {
        selectedFiles?.map((refund) => {
          const { initialUrlData, nameData } = refund?.name;
          downloadFile(initialUrlData, nameData + '.pdf' || 'file.pdf');
        });
      }
    }
  }

  function handleClose() {
    setDisplayConfirmationModal(false);
  }

  const [updateArchive] = useUpdateArchiveInvoiceMutation();
  const { openModal, closeModal } = useModal();

  const {
    queryParams: { filters },
  } = gridContext;

  function bulkArchiveBills() {
    const rowsIds: Array<string> = selectedRows?.map((row) => row._id) || [];
    const isSingle: boolean = rowsIds.length === 1;

    openModal({
      title: isSingle
        ? t('invoice.archive.title')
        : t('invoice.archive.title-bulk'),
      content: isSingle
        ? t('invoice.archive.content.single')
        : t('invoice.archive.content.bulk'),
      action: {
        actionText: t('messages.archive'),
        actionCallback: () => {
          updateArchive({ invoiceIds: rowsIds, isArchived: true });
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }

  function bulkUnarchiveBills() {
    const rowsIds: Array<string> = selectedRows?.map((row) => row._id) || [];
    const isSingle: boolean = rowsIds.length === 1;

    openModal({
      title: isSingle
        ? t('invoice.unarchive.title')
        : t('invoice.unarchive.title-bulk'),
      content: isSingle
        ? t('invoice.unarchive.content.single')
        : t('invoice.unarchive.content.bulk'),
      action: {
        actionText: t('messages.unarchive'),
        actionCallback: () => {
          updateArchive({ invoiceIds: rowsIds, isArchived: false });
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }

  return (
    <Wrapper>
      <ConfirmPaymentsModal
        open={displayConfirmationModal}
        onClose={handleClose}
        items={selectedRows}
      />

      <Tooltip title={t('common.download')}>
        <IconButton
          onClick={handleDownloadFiles}
          sx={{
            padding: '4px',
            width: '24px',
            height: '24px',
          }}
        >
          <DownloadSimple />
        </IconButton>
      </Tooltip>

      <If condition={!isCustomer() && !filters.archived}>
        <Tooltip title={t('messages.archive')} onClick={bulkArchiveBills}>
          <IconButton
            sx={{
              padding: '4px',
              width: '24px',
              height: '24px',
            }}
          >
            <Archive color={themes?.default?.chipRedBg} />
          </IconButton>
        </Tooltip>
      </If>

      <If condition={!isCustomer() && filters.archived}>
        <Tooltip title={t('messages.unarchive')} onClick={bulkUnarchiveBills}>
          <IconButton
            sx={{
              padding: '4px',
              width: '24px',
              height: '24px',
            }}
          >
            <Archive color={themes?.default?.chipRedBg} />
          </IconButton>
        </Tooltip>
      </If>

      <If condition={selectedTab === 'bills' && isUnpaid}>
        <Button
          variant="contained"
          onClick={handlePayment}
          size="small"
          sx={{
            minWidth: 'fit-content',
            height: '20px',
            background: 'linear-gradient(to bottom, #5B26EA, #AD87F7)',
          }}
        >
          {t('customerPortal.documents.payOnline')}
        </Button>
      </If>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
