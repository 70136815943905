import React, { useEffect, useState } from 'react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Avatar, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GridActionMenu } from 'app/components/Grid/components/GridActionMenu';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import UserAvatar from 'app/components/UserAvatar';
import { Check, X, Info, Paperclip, ArchiveBox } from '@phosphor-icons/react';
import { formatCustomDate } from 'common/utils/dates';
import { useParams } from 'react-router-dom';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useSelector } from 'react-redux';
import { useGetStatusesUserDocumentsQuery } from 'common/services/userDocumentsApi';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { t } from 'i18next';
import { useToaster } from 'hooks/useToaster';
import { useModal } from 'app/components/Dialog/hooks';
import { DOCUMENT_STATUSES } from '../../documentStatuses';
import { AttachmentModal } from 'app/components/DocumentTabComponents/AttachmentsModal';
import { DocumentsKeys } from 'common/utils/enum';
import { documentTypes } from 'common/utils/documentTypes';
import { A } from 'app/components/A';
import { useTheme } from 'common/hooks/useTheme';
import If from 'app/components/If';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import {
  useChangeDocumentStatusMutation,
  useUpdateDocumentMutation,
  useArchiveDocumentsMutation,
} from 'common/services/userDocumentsApi';

export const DocumentTypeCell = (params: CustomCellRendererProps) => {
  const theme = useTheme();
  const gridContext = useManagedContext('grid');
  const [documentData, setDocumentData] = useState(params?.data);
  const [documents, setDocuments] = useState<any>([]);
  const [doc, setDoc] = useState<any>({});
  const { t } = useTranslation();
  useEffect(() => {
    const document = gridContext?.rowData?.find(
      (data) => data.id === params.data.id,
    );

    if (document) {
      setDocumentData(document);
    }
  }, [params?.data, gridContext?.rowData]);
  useEffect(() => {
    setDocuments(gridContext?.data?.responseData[0]?.docs);
  }, [gridContext]);
  useEffect(() => {
    const document = documents?.find((data) => data._id === params.data.id);
    setDoc(document);
  }, [documents, params]);
  return (
    <A
      role="button"
      className="link"
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        cursor: 'pointer',
      }}
      onClick={() => {
        gridContext.updateDataWithFunction((prev) => {
          prev.selectedRow = documentData;
          prev.openDrawer = true;
          prev.handleReject = false;
        });
      }}
    >
      {getIconForType(
        params?.data?.type,
        16,
        true,
        params?.data?.status?.archived,
      )}
      {params?.data?.type !== DocumentsKeys.OTHER
        ? t(params?.data?.type)
        : doc?.specifyOtherType || t(doc?.type)}
      <If condition={params?.data?.status?.archived}>
        <div
          style={{
            position: 'absolute',
            bottom: '-10px',
            left: '32px',
          }}
        >
          <ArchiveBox size={14} color={theme.redStatus} />
        </div>
      </If>
    </A>
  );
};

export const DocumentSubmittedByCell = (params: CustomCellRendererProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {params?.data?.user?.submitted_by?.fullName || ' '}
    </div>
  );
};

export const DocumentEmployeeNameCell = (params: CustomCellRendererProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {params.data?.employee?.pictureUrl ? (
        <Avatar
          src={params?.data?.employee?.pictureUrl}
          sx={{ width: '28px', height: '28px' }}
        />
      ) : (
        <UserAvatar
          name={params?.data?.employee?.fullName || ''}
          size={28}
          fontSize="12px"
        />
      )}
      {params?.data?.employee?.fullName ?? ' '}
    </div>
  );
};

export const DocumentSubmissionDateCell = (params: CustomCellRendererProps) => {
  return (
    <>{formatCustomDate(new Date(params?.data?.submission_date)) ?? ' '}</>
  );
};

export const DocumentAttachmentsCell = (params: CustomCellRendererProps) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1px',
        color: theme.paperClipColor,
        width: 'fit-content',
        cursor: 'pointer',
      }}
      onClick={() => handleOpenModal()}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleOpenModal();
        }
      }}
      tabIndex={0}
      role="button"
    >
      <Paperclip size={16} />
      {params?.data?.attachments?.length}
      <AttachmentModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        files={params?.data?.attachments}
      />
    </span>
  );
};

export const DocumentStatusCell = (params: CustomCellRendererProps) => {
  const theme = useTheme();
  const gridContext = useManagedContext<IGridContext>('grid');
  const name = params?.data?.employee?.fullName;
  const authUser = useSelector(selectAuthUser);
  const can = usePermission('documents');

  const canEdit =
    gridContext.canEdit && authUser?.fullName !== name && can(Permission.EDIT);

  const { id: documentId, type, user } = params.data;
  const toast = useToaster();

  const [updateStatus] = useChangeDocumentStatusMutation();

  const handleApprove = () => {
    updateStatus({
      id: `${documentId}?iam-checker=true`,
      body: { status: DOCUMENT_STATUSES.APPROVED, reason: ' ' },
    });
    toast(3000, 'success', 'messages.document.approved', { type: t(type) });
  };

  const handleReject = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.selectedRow = params.data;
      prev.openDrawer = true;
      prev.handleReject = true;
    });
  };
  const capitalizedStatus = params?.data?.status?.status
    ? params?.data?.status?.status.charAt(0).toUpperCase() +
      params?.data?.status?.status.slice(1)
    : '';

  return (
    <div>
      <If
        condition={params.data?.status?.status === DOCUMENT_STATUSES.PENDING}
        otherwise={
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Tooltip
              title={`${capitalizedStatus} by ${
                authUser?.fullName !== user?.updated_by?.fullName
                  ? user?.updated_by?.fullName
                  : 'you'
              }`}
            >
              <div
                style={{
                  backgroundColor: `${
                    params.data?.status?.status === DOCUMENT_STATUSES.APPROVED
                      ? theme.statusApprovedColor?.background
                      : theme.statusRejectedColor?.background
                  }`,
                  color: `${
                    params.data?.status?.status === DOCUMENT_STATUSES.APPROVED
                      ? theme.statusApprovedColor.color
                      : theme.statusRejectedColor.color
                  }`,
                  width: '63px',
                  borderRadius: '100px',
                  textTransform: 'capitalize',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  maxHeight: '24px',
                  fontSize: '10px',
                }}
              >
                {params?.data?.status?.status}
              </div>
            </Tooltip>
            <If
              condition={
                params?.data?.status?.status === DOCUMENT_STATUSES.REJECTED
              }
            >
              <Tooltip title={t(params?.data?.status?.reason).toString()}>
                <Info size={16} color={`${theme.statusRejectedColor.color}`} />
              </Tooltip>
            </If>
          </div>
        }
      >
        <If
          condition={canEdit}
          otherwise={
            <div
              style={{
                backgroundColor: `${theme.statusPendingColor?.background}`,
                color: `${theme.statusPendingColor.color}`,
                width: '63px',
                borderRadius: '100px',
                textTransform: 'capitalize',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: '24px',
                fontSize: '10px',
              }}
            >
              {params?.data?.status?.status}
            </div>
          }
        >
          <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
            <Check
              size={24}
              style={{
                backgroundColor: theme.approveColor?.background,
                color: theme.approveColor.color,
                borderRadius: '50%',
                padding: 5,
                fontWeight: '900',
                cursor: 'pointer',
              }}
              onClick={handleApprove}
            />
            <X
              size={24}
              style={{
                backgroundColor: theme.rejectColor?.background,
                color: theme.rejectColor.color,
                borderRadius: '50%',
                padding: 5,
                fontWeight: '900',
                cursor: 'pointer',
              }}
              onClick={handleReject}
            />
          </div>
        </If>
      </If>
    </div>
  );
};

export const DocumentActionsCell = (params: CustomCellRendererProps) => {
  const theme = useTheme();
  const gridContext = useManagedContext('grid');
  const { t } = useTranslation();
  const [documentData, setDocumentData] = useState(params?.data);
  const [updateDocument] = useUpdateDocumentMutation();
  const [archiveDocument] = useArchiveDocumentsMutation();
  const idParams = useParams();
  const id = idParams.id || params?.data?.employee?.id || '';
  const { id: documentId, type } = params?.data;
  const authUser = useSelector(selectAuthUser);

  const can = usePermission('documents');

  const CanChangeStatus =
    gridContext.canEdit && authUser?._id !== id && can(Permission.EDIT);

  const currentUser = authUser?.fullName === params?.data?.employee?.fullName;

  const toast = useToaster();

  const { data: facets } = useGetStatusesUserDocumentsQuery(`${authUser?._id}`);
  const { openModal, closeModal } = useModal();

  const handleDelete = () => {
    openModal({
      title: t(`delete_document_${type}`),
      content: t(`delete_document_confirmation_${type}`),
      action: {
        actionText: t('delete'),
        actionCallback: () => {
          updateDocument({
            id: `${documentId}?iam-checker=true`,
            body: { deleted: true },
          });
          closeModal();
          toast(3000, 'success', 'messages.document.deleted', {
            type: t(type),
          });
        },
        buttonProps: {
          sx: { background: theme?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  };

  const handleArchive = () => {
    openModal({
      title: `Archive ${t(`${type}`)} Document`,
      content: `${t('archive_document_confirmation_1')} "${t(`${type}`)}"? ${t(
        'archive_document_confirmation_2',
      )}`,
      action: {
        actionText: t('archive'),
        actionCallback: () => {
          archiveDocument({
            id: `${documentId}?iam-checker=true`,
            body: { isArchived: true },
          }).unwrap();
          closeModal();
          toast(3000, 'success', 'messages.document.archived', {
            type: t(type),
          });
        },
        buttonProps: {
          sx: { background: theme?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  };

  useEffect(() => {
    const document = gridContext?.rowData?.find(
      (data) => data.id === params.data.id,
    );

    if (document) {
      setDocumentData(document);
    }
  }, [params?.data, gridContext?.rowData]);

  const options = [
    {
      label:
        (documentData.status.status === DOCUMENT_STATUSES.APPROVED &&
          gridContext.canEdit) ||
        (authUser?.fullName === documentData?.employee?.fullName &&
          // !CanChangeStatus &&
          documentData.status.status === DOCUMENT_STATUSES.PENDING &&
          gridContext.canEdit)
          ? t('edit')
          : t('document.view_details'),
      action: () => {
        gridContext.updateDataWithFunction((prev) => {
          prev.selectedRow = documentData;
          prev.openDrawer = true;
          prev.handleReject = false;
        });
      },
    },
    {
      label: t('resubmit'),
      action: () => {
        gridContext.updateDataWithFunction((prev) => {
          prev.selectedRow = null;
          prev.openDrawer = true;
          prev.handleReject = false;
          prev.resubmitType = type;
        });
      },
      show:
        (gridContext.canEdit || gridContext.canView) &&
        // !CanChangeStatus &&
        currentUser &&
        documentData.status.status === DOCUMENT_STATUSES.REJECTED &&
        !facets?.some(
          (item) =>
            item.type === type &&
            (item.type !== DocumentsKeys.OTHER
              ? item.status === DOCUMENT_STATUSES.PENDING ||
                item.status === DOCUMENT_STATUSES.APPROVED
              : false),
        ) &&
        !documentData.status.archived,
    },
    {
      label: t('delete'),
      action: () => {
        handleDelete();
      },
      show:
        gridContext.canEdit &&
        CanChangeStatus &&
        currentUser &&
        documentData.status.status === DOCUMENT_STATUSES.PENDING &&
        !documentData.status.archived,
      isDelete: true,
    },
    {
      label: t('archive'),
      action: () => {
        handleArchive();
      },
      show:
        CanChangeStatus &&
        documentData.status.status === DOCUMENT_STATUSES.REJECTED &&
        !documentData.status.archived,
      isDelete: true,
    },
    {
      label: t('reject'),
      action: () => {
        gridContext.updateDataWithFunction((prev) => {
          prev.selectedRow = documentData;
          prev.openDrawer = true;
          prev.handleReject = true;
        });
      },
      show:
        CanChangeStatus &&
        documentData.status.status === DOCUMENT_STATUSES.APPROVED &&
        authUser?.fullName !== params?.data?.employee?.fullName,
      isDelete: true,
    },
  ];

  return <GridActionMenu options={options} />;
};

export const getIconForType = (
  type: DocumentsKeys,
  iconSize: number = 16,
  backgroundColor: boolean = true,
  archived: boolean = false,
) => {
  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '5.33px',
  };

  const color = documentTypes[type] || documentTypes.otherDocuments;
  const archivedColor = documentTypes.archived;

  const IconComponent = color.icon;

  const iconProps = {
    style: {
      ...iconStyle,
      backgroundColor: backgroundColor
        ? archived
          ? archivedColor.background
          : color.background
        : '',
      color: archived ? archivedColor.color : color.color,
    },
  };

  return (
    <div {...iconProps}>
      <IconComponent size={iconSize} />
    </div>
  );
};
