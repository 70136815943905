import { t } from 'i18next';

export enum DocumentRejectReason {
  LowQuality = 'the_document_is_of_low_quality',
  IncorrectType = 'the_document_type_is_incorrect',
  Expired = 'the_document_is_expired',
  InformationMismatch = 'the_information_mismatch_with_another_document',
  MissingInformation = 'the_document_has_missing_information',
  MissingSignOrStamp = 'the_document_is_missing_an_official_sign_or_stamp',
  SuspectedFraud = 'the_document_is_suspected_of_fraud',
  Other = 'document.other',
}

export const DOCUMENT_REJECT_REASONS = [
  {
    label: t(DocumentRejectReason.LowQuality)?.toString(),
    value: DocumentRejectReason.LowQuality,
  },
  {
    label: t(DocumentRejectReason.IncorrectType)?.toString(),
    value: DocumentRejectReason.IncorrectType,
  },
  {
    label: t(DocumentRejectReason.Expired)?.toString(),
    value: DocumentRejectReason.Expired,
  },
  {
    label: t(DocumentRejectReason.InformationMismatch)?.toString(),
    value: DocumentRejectReason.InformationMismatch,
  },
  {
    label: t(DocumentRejectReason.MissingInformation)?.toString(),
    value: DocumentRejectReason.MissingInformation,
  },
  {
    label: t(DocumentRejectReason.MissingSignOrStamp)?.toString(),
    value: DocumentRejectReason.MissingSignOrStamp,
  },
  {
    label: t(DocumentRejectReason.SuspectedFraud)?.toString(),
    value: DocumentRejectReason.SuspectedFraud,
  },
  {
    label: t(DocumentRejectReason.Other)?.toString(),
    value: DocumentRejectReason.Other,
  },
];
