import React from 'react';
import { ReactComponent as DotIndicator } from 'assets/img/chat/DotIndicator.svg';

const TypingIndicator: React.FC = () => {
  return (
    <div
      style={{
        position: 'relative',
        height: '24px',
      }}
    >
      <DotIndicator />
    </div>
  );
};

export default TypingIndicator;
