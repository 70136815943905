import { AutoCompleteSearch } from 'app/components/AutoCompleteSearch';
import { Avatar } from '@mui/material';
import UserAvatar from '../UserAvatar';
import { useUsersAutoComplete } from './hooks/useUsersAutoComplete';

export const UsersAutoComplete = ({
  control,
  name,
  fieldName,
  onChange,
  disabled,
  hasProfile = false,
  useWebQuery = false,
}) => {
  const { users, isFetching, searchUsers, setSearch } =
    useUsersAutoComplete(useWebQuery);

  return (
    <AutoCompleteSearch
      control={control}
      propsVal={{
        options: users,
        getOptionLabel: (option) => option.label,
      }}
      onChange={onChange}
      setSearch={(val) => {
        setSearch(val);
        searchUsers(val);
      }}
      isFetching={isFetching}
      disabled={disabled}
      name={name}
      fieldName={fieldName}
      hasProfile={hasProfile}
      renderOption={(option) =>
        hasProfile && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '8px',
            }}
          >
            {option?.pictureUrl ? (
              <Avatar
                src={option?.pictureUrl}
                sx={{ width: '28px', height: '28px' }}
              />
            ) : (
              <UserAvatar
                name={option?.label || ''}
                size={28}
                fontSize="0.75rem"
              />
            )}
          </div>
        )
      }
    />
  );
};
