import { IColDef } from 'app/components/ManagedGrid/types';
import {
  DocumentActionsCell,
  DocumentAttachmentsCell,
  DocumentEmployeeNameCell,
  DocumentStatusCell,
  DocumentSubmissionDateCell,
  DocumentSubmittedByCell,
  DocumentTypeCell,
} from './components/Cells';
import { themes } from 'styles/theme/themes';

export const DOCUMENTS_STATS_COLORS = {
  REJECTED: {
    thumbColor: themes.default?.progressRed,
    backgroundColor: themes.default?.chipRedFont,
    railColor: themes.default?.redRail,
  },
  APPROVED: {
    thumbColor: themes.default?.progressGreen,
    backgroundColor: themes.default?.chipGreenFont,
    railColor: themes.default?.green100,
  },
  PENDING: {
    thumbColor: themes.default?.progressOrange,
    backgroundColor: themes.default?.orange50,
    railColor: themes.default?.orange100,
  },
};

export const CUSTOM_DOCUMENTS_COLUMN_CONFIG: IColDef[] = [
  {
    cellRenderer: DocumentTypeCell,
    minWidth: 240,
    flex: 1,
    lockVisible: true,
    sortField: 'document.heads.type',
    headerName: 'document.heads.type',
    pinned: 'left',
    lockPinned: true,
  },
  {
    cellRenderer: DocumentEmployeeNameCell,
    minWidth: 120,
    flex: 1,
    sortField: 'document.heads.employee_name',
    headerName: 'document.heads.employee_name',
  },
  {
    cellRenderer: DocumentSubmittedByCell,
    minWidth: 100,
    flex: 1,
    sortField: 'document.heads.submitted_by',
    headerName: 'document.heads.submitted_by',
  },
  {
    cellRenderer: DocumentSubmissionDateCell,
    minWidth: 250,
    flex: 1,
    sortField: 'document.heads.submission_date',
    headerName: 'document.heads.submission_date',
  },
  {
    cellRenderer: DocumentAttachmentsCell,
    minWidth: 140,
    flex: 1,
    sortField: 'document.heads.attachments',
    headerName: 'document.heads.attachments',
  },
  {
    cellRenderer: DocumentStatusCell,
    minWidth: 120,
    flex: 1,
    sortField: 'document.heads.status',
    headerName: 'status',
  },
  {
    cellRenderer: DocumentActionsCell,
    minWidth: 50,
    maxWidth: 70,
    headerName: '',
    resizable: false,
    lockPinned: true,
    lockPosition: 'right',
  },
];
