import { TableCell, Tooltip } from '@mui/material';
import { useGrid } from '../../hooks';
import { Checkbox } from 'app/components/CustomCheckbox';
import styled from 'styled-components';
import If from 'app/components/If';

export const GridCheckbox = ({
  id,
  withWarning,
  warningMessage,
}: {
  id: string;
  withWarning?: boolean;
  warningMessage?: string;
}) => {
  const { selected, setSelected, withCheckbox } = useGrid();
  const toggleCheck = () => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  return withCheckbox ? (
    <TableCell
      padding="checkbox"
      className="checkbox-cell"
      sx={{
        maxWidth: '50px',
        paddingLeft: withWarning ? '24px !important' : 'auto',
      }}
    >
      <If condition={!!warningMessage}>
        <Tooltip title={warningMessage || ''} placement="top">
          <RedPulse />
        </Tooltip>
      </If>
      <Checkbox
        color="primary"
        checked={selected?.includes(id)}
        inputProps={{
          'aria-labelledby': `table-checkbox-${id}`,
        }}
        onClick={toggleCheck}
        sx={{
          padding: 0,
        }}
      />
    </TableCell>
  ) : null;
};

const RedPulse = styled.div`
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.theme.redStatus};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: redPulse 1s infinite;
  z-index: 10;

  @keyframes redPulse {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.theme.redShadow};
    }
    50% {
      box-shadow: 0 0 0 5px ${(props) => props.theme.redShadow};
    }
    100% {
      box-shadow: 0 0 0 0 ${(props) => props.theme.redShadow};
    }
  }
`;
