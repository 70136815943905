import { useSelector } from 'react-redux';
import {
  useGetAdminInvoicesQuery,
  useGetInvoicesQuery,
} from 'common/services/transactions';
import { selectAuthUser } from 'app/slices/auth/selectors';

const useInvoices = (queryParams: any) => {
  const authUser = useSelector(selectAuthUser);
  const isUserCustomer = authUser?.currentAgent?._role?.key === 'customer';

  const {
    data: customerInvoices,
    isLoading: isLoadingCustomer,
    isFetching: isFetchingCustomer,
    refetch: refetchCustomer,
  } = useGetInvoicesQuery(queryParams, { skip: !isUserCustomer });

  const {
    data: invoices,
    isLoading,
    isFetching,
    refetch,
  } = useGetAdminInvoicesQuery(queryParams, { skip: isUserCustomer });

  return {
    invoices: isUserCustomer ? customerInvoices : invoices,
    isLoading: isUserCustomer ? isLoadingCustomer : isLoading,
    isFetching: isUserCustomer ? isFetchingCustomer : isFetching,
    refetch: isUserCustomer ? refetchCustomer : refetch,
  };
};

export default useInvoices;
