import { Grid } from '@mui/material';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import FormTextField from 'app/components/Form/TextField';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SupplierFormcontext } from '../context';

export const BusinessInformation = ({ control }) => {
  const { t } = useTranslation();
  const { canEdit, isSupplier } = useContext(SupplierFormcontext);

  const disabled = isSupplier || !canEdit;

  return (
    <FormSection>
      <SectionTitle>{t('business_information')}</SectionTitle>
      <Grid container spacing="12px">
        <Grid item sm={4} xs={12}>
          <FormTextField
            name="legalName"
            label={t('legal_name')}
            control={control}
            disabled={disabled}
            rules={{
              required: {
                value: true,
                message: `${t('brand_name')} ${t('is_required')}`,
              },
            }}
            inputProps={{
              placeholder: t('enter_legal_name'),
            }}
            required
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormTextField
            name="activityCode"
            label={t('supplier.activityCode')}
            control={control}
            disabled={disabled}
            inputProps={{
              placeholder: t('enter_NAF'),
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormTextField
            name="vatNumber"
            label={t('intracommunity_vat')}
            control={control}
            disabled={disabled}
            inputProps={{
              placeholder: t('enter_intracommunity_vat'),
            }}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
