import If from 'app/components/If';
import styled, { CSSObject } from 'styled-components';

interface Props {
  title: string;
  value: string | JSX.Element | null;
  icon?: JSX.Element;
  iconBg?: string;
  width?: string;
  style?: CSSObject;
  fontSize?: string;
  customBackground?: string;
  customBorder?: string;
}

export const InfoCard = ({
  title,
  value,
  icon,
  iconBg,
  width = '32px',
  fontSize = '0.75rem',
  customBackground,
  customBorder,
}: Props) => {
  return (
    <Wrapper customBackground={customBackground} customBorder={customBorder}>
      <If condition={!!icon}>
        <IconContainer color={iconBg} width={width}>
          {icon}
        </IconContainer>
      </If>
      <div>
        <Title style={{ fontSize: fontSize }}>{title}</Title>
        <Value style={{ fontSize: fontSize }}>{value}</Value>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  customBackground?: string;
  customBorder?: string;
}>`
  display: flex;
  align-items: center;
  height: 61px;
  padding: 12px;
  gap: 12px;
  background-color: ${(props) =>
    props.customBackground || props.theme.lightGrey};
  border: ${(props) =>
    props.customBorder || `1px solid ${props.theme.gainsboro2}`};
  border-radius: 8px;
  flex: 1;
`;

const Title = styled.div`
  color: ${(props) => props.theme.nobel};
`;

const Value = styled.div`
  font-weight: 500;
  text-transform: capitalize;
`;

interface IconContainerProps {
  color?: string;
  width?: string;
}
const IconContainer = styled.div<IconContainerProps>`
  width: ${(props) => props.width};
  height: 32px;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
