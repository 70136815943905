import { Grid } from '@mui/material';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import FormTextField from 'app/components/Form/TextField';
import { useTranslation } from 'react-i18next';
import { CustomerFormcontext } from '../../../Form/context';
import { useContext } from 'react';

export const BusinessInfo = ({ control }) => {
  const { canEdit } = useContext(CustomerFormcontext);
  const { t } = useTranslation();
  return (
    <FormSection>
      <SectionTitle>{t('business_information')}</SectionTitle>
      <Grid container spacing="12px">
        <Grid item md={4} sm={4} xs={12}>
          <FormTextField
            name="siretNumber"
            label="clientModule.fields.siret"
            control={control}
            disabled={!canEdit}
            rules={{
              required: true,
              // validate(value) {
              //   return validate({
              //     code: value?.toString() || '',
              //     id: customer?._id,
              //   });
              // },
            }}
            required
          />
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <FormTextField
            name="vatNumber"
            label="common.vat_number"
            control={control}
            rules={{ required: true }}
            required
            disabled={!canEdit}
          />
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <FormTextField
            name="apeCode"
            label="code_ape"
            control={control}
            disabled={!canEdit}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
