import { IColDef } from 'app/components/ManagedGrid/types';
import { UCCell } from 'app/pages/Ordoria/Catalog/components/Cells/UCCell';
import {
  ProductCell,
  MainCell,
} from 'app/components/ManagedGrid/components/Cells';
import { StatusCell } from './Cells/StatusCell';
import { ApproveCell } from './Cells/ApproveCell';
import { NotesCell } from '../../../ContractsTab/components/Cells/NotesCell';
import { AppliedOnCell } from './Cells/AppliedOnCell';
import { ActionsCell } from './Cells/ActionCell';
import { OfferIdCell } from './Cells/OfferIdCell';

export const getSharedColumnConfig = (
  t: (key: string) => string,
  type: string,
  isAdmin: boolean,
): IColDef[] => [
  {
    cellRenderer: MainCell,
    minWidth: 116,
    flex: 1,
    sortField: 'purchaseCondition.value',
    headerName: t('offers.value_quantity'),
  },
  {
    cellRenderer: MainCell,
    minWidth: 116,
    flex: 1,
    headerName: `${t('offers.total')} €`,
    hide: type === 'refund_items' || type === 'refund_anything',
  },

  {
    cellRenderer: MainCell,
    minWidth: 200,
    flex: 1,
    sortField: 'period.type',
    headerName: t('when'),
  },
  {
    cellRenderer: StatusCell,
    minWidth: 100,
    flex: 1,
    sortField: 'status',
    headerName: t('status'),
    pinned: 'right',
  },
  {
    cellRenderer: ApproveCell,
    minWidth: 40,
    flex: 1,
    headerName: t('approve'),
    pinned: 'right',
    hide: !isAdmin,
  },
  {
    cellRenderer: NotesCell,
    minWidth: 180,
    flex: 1,
    sortField: 'notes',
    headerName: t('notes'),
  },
  {
    cellRenderer: ActionsCell,
    width: 50,
    resizable: false,
    lockPinned: true,
    pinned: 'right',
    lockPosition: 'right',
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
];

export const getColumnConfig = (
  t: (key: string) => string,
  type: string,
  isAdmin: boolean,
): IColDef[] => [
  {
    cellRenderer: OfferIdCell,
    minWidth: 70,
    resizable: true,
    flex: 1,
    lockVisible: true,
    sortField: 'code',
    pinned: true,
    initialPinned: true,
    headerName: `${t('offers.offer_id')}`,
  },
  {
    cellRenderer: ProductCell,
    minWidth: 280,
    resizable: true,
    flex: 1,
    lockVisible: true,
    sortField: 'cache._offeredProduct.name',
    headerName: `${t('offers.offered_products')}`,
    hide: type === 'refund_anything' || type === 'refund_items',
  },
  {
    cellRenderer: MainCell,
    minWidth: 180,
    resizable: true,
    flex: 1,
    sortField: 'discount',
    headerName: `${t('refund_discount')}`,
    hide: type === 'free_anything' || type === 'free_items',
  },

  {
    cellRenderer: UCCell,
    minWidth: 60,
    flex: 1,
    sortField: 'cache._offeredProduct.boxingUnit',
    headerName: 'UC',
  },

  {
    cellRenderer: MainCell,
    minWidth: 116,
    flex: 1,
    sortField: 'offeredQuantity.quantity',
    headerName: t('offers.offered_quantity'),
    hide: type === 'refund_items' || type === 'refund_anything',
  },
  {
    cellRenderer: MainCell,
    minWidth: 80,
    flex: 1,
    sortField: 'purchaseCondition.type',
    headerName: t('applied_on'),
    hide: type === 'free_items' || type === 'refund_items',
  },
  {
    cellRenderer: MainCell,
    minWidth: 80,
    flex: 1,
    sortField: 'purchaseCondition.type',
    headerName: t('offers.applied_on_type'),
    hide: type === 'free_anything' || type === 'free_anything',
  },
  {
    cellRenderer: AppliedOnCell,
    minWidth: 280,
    flex: 1,
    sortField: 'purchaseCondition.type',
    headerName: t('offers.applied_on_items'),
    hide: type === 'free_anything' || type === 'refund_anything',
  },

  ...getSharedColumnConfig(t, type, isAdmin),
];
