import { getImgCdn } from 'common/helpers/cdn';
import { IProductRowData, Product } from '../types';
import { formatNumber } from 'utils/helpers';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';
import {
  // useUpdateProductBoxBuyingPriceMutation,
  useUpdateProductDiscountMutation,
  useUpdateStatusMutation,
  useArchiveProductMutation,
  useUnArchiveProductMutation,
  useUpdateProductImageMutation,
  useUpdateProductMutation,
  useUpdateProductTagsMutation,
  useUpdateProductCodeMutation,
  useUpdateProductBrochureMutation,
  useUpdateProductsTagsMutation,
  useUpdateStorageLocationMutation,
  useUpdateProductNameMutation,
} from 'common/services/productApi';
import { useTranslation } from 'react-i18next';
import {
  ProductsStatus,
  ProductImageActions,
} from '../Components/Shared/consts';
import { themes } from 'styles/theme/themes';
import { IProductCacheCategory } from 'common/types/Categories';
import { useCallback } from 'react';
import {
  Flag as FlagIcon,
  DownloadSimple,
  TrashSimple,
} from '@phosphor-icons/react';
import { useToaster } from 'hooks/useToaster';
import { downloadFile } from 'app/helpers/helpers';

export const useHelpers = () => {
  const { t } = useTranslation();
  const [updateStatus] = useUpdateStatusMutation();
  const { callApi } = useAsyncDataV2();
  const gridContext = useManagedContext<IGridContext>('grid');
  const { selectedRows } = gridContext;
  const [archiveProduct] = useArchiveProductMutation();
  const [unArchiveProduct] = useUnArchiveProductMutation();

  const [updateProductImg] = useUpdateProductImageMutation();
  const [patchDiscount] = useUpdateProductDiscountMutation();
  const toaster = useToaster();
  const [updateProduct] = useUpdateProductMutation();
  const [updateTag] = useUpdateProductTagsMutation();
  const [updateCode] = useUpdateProductCodeMutation();
  const [updateProductBrochure] = useUpdateProductBrochureMutation();
  const [updateBulkTags] = useUpdateProductsTagsMutation();
  const [updateStoragelocations] = useUpdateStorageLocationMutation();
  const [updateProductName] = useUpdateProductNameMutation();

  // const [patchPrice] = useUpdateProductBoxBuyingPriceMutation();

  const updateInfo = async (id, body) => {
    await callApi(async () => {
      await updateProduct({
        id: id || '',
        body: body,
      }).unwrap();
    });
  };

  const updateTags = async (id: string, type: string, tagsIds: string[]) => {
    //products.tags.success
    await callApi(async () => {
      await updateTag({
        id: id || '',
        dataType: type,
        tags: tagsIds,
      }).unwrap();
    });
  };

  const updateLocations = async (id: string, locationIds: string[]) => {
    await callApi(async () => {
      await updateStoragelocations({
        id: id || '',
        locationsIds: locationIds,
      }).unwrap();
    });
  };

  const updateName = async (id: string, name: string) => {
    await callApi(async () => {
      await updateProductName({
        id: id || '',
        name: name,
      }).unwrap();
    });
  };

  const updateBulkProductsTags = async (
    ids: string[],
    type: string,
    tagsIds: string[],
  ) => {
    await callApi(
      async () => {
        await updateBulkTags({
          productIds: ids,
          dataType: type,
          tags: tagsIds,
        }).unwrap();
      },
      {
        onSuccess: () => {
          uncheckRows();
        },
        successMessage: t('products.tags.success'),
      },
    );
  };
  const updateProductCode = async (id: string, code: string) => {
    await callApi(async () => {
      await updateCode({
        id: id || '',
        code: code,
      }).unwrap();
    });
  };

  const updateBrochure = async (id: string, url: string) => {
    await callApi(async () => {
      await updateProductBrochure({
        id: id || '',
        brochure: url,
      }).unwrap();
    });
  };

  const processProducts = (products: Product[]): IProductRowData[] => {
    return products?.map((product) => {
      const categoryHierarchy = getCategoryHierarchy(product.cache?._category);

      return {
        id: product._id,
        __flag: {
          id: product._id,
          orderFreq: product?.orderFrequency,
          showInCatalog: product?.showInCatalog,
          showInGallery: product?.showInGallery,
          isNew: !!product?.new,
        },
        product_name: {
          cellData: {
            ...product,
            pictureUrl: product?.pictures?.[0]?.key
              ? getImgCdn(product?.pictures[0]?.key)
              : undefined,
            productId: product?._id,
            redirectToInfoScreen: true,
          },
        },
        packing: {
          boxingUnit: product.boxingUnit,
          weight: product.weight,
        },
        supplier: product.supplierCode,
        category: {
          cellData: categoryHierarchy?.[0]?.name || '-',
        },
        sub_category: {
          cellData: categoryHierarchy?.[1]?.name || '-',
        },
        child_category: {
          cellData: categoryHierarchy?.[2]?.name || '-',
        },
        location: '-',
        boxBuyingPrice: product.boxingBuyingPrice || 0,
        sellingPrices: product?.sellingPrice
          ? formatNumber(product.sellingPrice)
          : 0,

        // boxBuyingPrice: {
        //   cellData: formatNumber(product.boxingBuyingPrice || 0),
        //   id: product._id,
        //   field: 'boxingBuyingPrice',
        //   onAction: (newValue: string) => {
        //     if (product.boxingBuyingPrice?.toString() === newValue) return;

        //     updatePrice(product._id, product.boxingBuyingPrice, newValue);
        //   },
        //   canEdit: gridContext.canEdit,
        //   inputAdornment: '€',
        //   customValidation: (value: number) => value >= 0,
        // },
        discount: {
          cellData: formatNumber((product.discount || 0) * 100),
          id: product._id,
          field: 'discount',
          onAction: (newValue: string) => {
            if (product.discount?.toString() === newValue) return;
            updateDiscount(product._id, product.discount, newValue);
          },
          canEdit: gridContext.canEdit,
          inputAdornment: '%',
        },
        stock: product.stock || 0,
        status: product.status,
        __actions: {
          id: product._id,
          status: product.status,
        },
      };
    });
  };

  const updateDiscount = async (
    id: string,
    currentVal: number,
    discount?: string,
  ) => {
    const val = discount;

    await callApi(async () => {
      await patchDiscount({
        discount: !val ? 0 : parseFloat(val) / 100,
        productIds: [id],
      }).unwrap();
    });
  };

  // const updatePrice = async (
  //   id: string,
  //   currentVal: number,
  //   price?: string,
  // ) => {
  //   const val = price
  //     ? parseFloat(price)
  //     : gridContext.extraData.currentVal
  //     ? parseFloat(gridContext.extraData.currentVal)
  //     : currentVal;

  //   await callApi(async () => {
  //     await patchPrice({
  //       boxBuyingPrice: val,
  //       productIds: [id],
  //     }).unwrap();
  //   });
  // };

  const changeStatus = async (id: string, status: string) => {
    const productsIds = id === '' ? selectedRows : [id];
    await callApi(
      async () => {
        await updateStatus({
          productIds: productsIds,
          status: status,
        }).unwrap();
      },
      {
        onSuccess: () => {
          uncheckRows();
        },
        successMessage:
          productsIds.length > 1
            ? t('product.statuses.success')
            : t('product.status.success'),
      },
    );
  };

  const getStatusChipColor = (status: string) => {
    switch (status) {
      case ProductsStatus?.DRAFT:
        return {
          background: themes?.default?.whisper,
          color: themes?.default?.tapa,
        };
      case ProductsStatus.ACTIVE:
        return {
          background: themes.default.lightGreen55,
          color: themes.default.green3,
        };
      case ProductsStatus.TERMINATED:
        return {
          background: themes?.default?.errorBackground,
          color: themes?.default?.errorMain,
        };
      case ProductsStatus.PAUSED:
        return {
          background: themes?.default?.teal50,
          color: themes?.default?.teal800,
        };
      case ProductsStatus.PRE_ORDER:
        return {
          background: themes?.default?.purple50,
          color: themes?.default?.purple,
        };
      case ProductsStatus.STOPPED:
        return {
          background: themes?.default?.linen,
          color: themes?.default?.brown,
        };

      case ProductsStatus.RESTRICTED:
        return {
          background: themes?.default?.blue50,
          color: themes?.default?.blue900,
        };
      default:
        return {
          backgroundColor: themes.default.lightGrey,
          color: themes.default.grey2,
        };
    }
  };

  const openAssignModal = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.extraData.showReassignModal = true;
    });
  };

  const getCategoryHierarchy = (category: IProductCacheCategory | null) => {
    const hierarchy: IProductCacheCategory[] = [];
    let currentCategory = category;

    while (currentCategory) {
      hierarchy.unshift(currentCategory);
      currentCategory = currentCategory._parentCategory;
    }

    return hierarchy;
  };

  const openDiscountModal = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.extraData.showBulkDiscountModal = true;
    });
  };
  const openTagsModal = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.extraData.showBulkTagsModal = true;
    });
  };
  const archiveProducts = useCallback(
    async (id: string, isArchived: boolean) => {
      let title = '';
      const successName =
        selectedRows.length === 1 || id !== ''
          ? t('product.heads.product')
          : selectedRows.length + ' ' + t('common.products');

      if (selectedRows.length === 1 || id !== '') {
        const productId = id || selectedRows?.[0];
        const selectedRowData = gridContext.rowData.find(
          (row) => row.id === productId,
        );
        title = selectedRowData.product_name.cellData.name;
      } else {
        title = selectedRows.length + ' ' + t('common.products');
      }

      callApi(
        () => {
          isArchived
            ? unArchiveProduct({
                ids: id !== '' ? [id] : selectedRows,
              }).unwrap()
            : archiveProduct({
                ids: id !== '' ? [id] : selectedRows,
              }).unwrap();
        },
        {
          onSuccess: () => {
            uncheckRows();
          },
          confirmationProps: {
            title: `${
              isArchived
                ? t('products.unarchive_title')
                : t('products.archive_title')
            }?`,
            message: `${
              isArchived
                ? t('modal.unarchive.subtitle') + ' ' + title
                : t('modal.archive.subtitle') + ' ' + title
            }?`,
          },
          actionText: isArchived
            ? t('buttons.unarchive')
            : t('buttons.archive'),
          successMessage: isArchived
            ? t('toast.unarchive.success', {
                name: successName,
              })
            : t('toast.archive.success', {
                name: successName,
              }),

          buttonProps: { color: 'error' },
        },
      );
    },
    [callApi, selectedRows],
  );

  const updateProductImgs = useCallback(
    async (
      id: string,
      imgId: string,
      action: string,
      type: string,
      key = '',
    ) => {
      const otherImgs = gridContext?.extraData?.productDetails?.[type].filter(
        (item) => item._id !== imgId,
      );
      let newOtherImgUpdates = otherImgs?.map((otherImg) => {
        return {
          originalFileName: otherImg?.originalFileName || otherImg?.key,
          key: otherImg?.key,
          primary:
            action === ProductImageActions.PRIMARY ? false : otherImg?.primary,
          _id: otherImg?._id,
        };
      });

      const currentSelectedPicture = gridContext?.extraData?.productDetails?.[
        type
      ]?.find((pic) => pic?._id === imgId);

      if (action === ProductImageActions.NEW) {
        const newUploadedImg = {
          originalFileName: key,
          key: key,
          primary: newOtherImgUpdates?.length === 0,
        };
        if (newOtherImgUpdates?.length === 0) {
          newOtherImgUpdates = [newUploadedImg];
        } else {
          newOtherImgUpdates.push(newUploadedImg);
        }
      } else if (
        action === ProductImageActions.DELETE &&
        !!otherImgs.length &&
        currentSelectedPicture?.primary
      ) {
        toaster(3000, 'error', t('products.images.main.forbidden'));
        return;
      } else if (action === ProductImageActions.PRIMARY) {
        const newImgUpdate = {
          originalFileName:
            currentSelectedPicture?.originalFileName ||
            currentSelectedPicture?.key,
          key: currentSelectedPicture?.key,
          primary: true,
          _id: currentSelectedPicture?._id,
        };
        newOtherImgUpdates = [newImgUpdate, ...newOtherImgUpdates];
      }

      await callApi(async () => {
        await updateProductImg({
          id: id,
          dataType: type,
          pictures: newOtherImgUpdates,
        }).unwrap();
      });
    },
    [callApi, gridContext.extraData.productDetails],
  );

  const handleDownload = async (imgId, type) => {
    const currentSelectedPicture = gridContext?.extraData?.productDetails?.[
      type
    ]?.find((pic) => pic?._id === imgId);

    const imageUrl = getImgCdn(currentSelectedPicture?.key);
    const name = imageUrl
      ?.substring(imageUrl.lastIndexOf('/'), imageUrl.length)
      ?.substring(1);
    downloadFile(imageUrl || '', name || '');
  };

  const productImageActions = useCallback(
    (id, type) => {
      const currentSelectedPicture = gridContext?.extraData?.productDetails?.[
        type
      ]?.find((pic) => pic?._id === id);
      return [
        {
          icon: <FlagIcon size={12} />,
          onClick: (id, imgId) => {
            updateProductImgs(id, imgId, ProductImageActions.PRIMARY, type);
          },
          canEdit: gridContext.canEdit,
          tooltip: {
            title: t('mark_as_main'),
          },
          hide: currentSelectedPicture?.primary,
        },
        {
          icon: <DownloadSimple size={12} />,
          onClick: (id, imgId) => {
            handleDownload(imgId, type);
          },
          tooltip: {
            title: t('common.download'),
          },
          canEdit: gridContext.canEdit,
        },
        {
          icon: <TrashSimple size={12} />,
          canEdit: gridContext.canEdit,
          color: themes?.default?.progressRed,
          tooltip: {
            title: t('common.buttons.dialogue_delete'),
          },
          onClick: (id, imgId) => {
            updateProductImgs(id, imgId, ProductImageActions.DELETE, type);
          },
        },
      ];
    },
    [gridContext.extraData.productDetails],
  );

  const uncheckRows = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.extraData.showBulkTagsModal = false;
    });
    gridContext.updateData('selectedRows', []);
    gridContext.api?.clearSelection();
  };

  return {
    changeStatus,
    processProducts,
    openAssignModal,
    getStatusChipColor,
    getCategoryHierarchy,
    openDiscountModal,
    patchDiscount,
    archiveProducts,
    productImageActions,
    updateProductImgs,
    updateInfo,
    updateTags,
    updateProductCode,
    updateBrochure,
    openTagsModal,
    updateBulkProductsTags,
    handleDownload,
    updateProductImg,
    uncheckRows,
    updateLocations,
    updateName,
  };
};
