import { useState } from 'react';
import { useDebounce } from 'common/hooks/useDebounce';
import { useGetProductsOptionsQuery } from 'common/services/productApi';
import { useQueryArray } from 'common/hooks/useQueryArray';

export const useApiCalls = () => {
  const [productOptionsSearch, setOptionsSearch] = useState<string>('');
  const [productsSearch, setProductsSearch] = useState<string>('');
  const debouncedOptionsSearch = useDebounce(productOptionsSearch, 200);
  const debouncedProductsSearch = useDebounce(productsSearch, 200);

  const fetchQueryParams = useQueryArray({
    page: 1,
    limit: 100,
    text: debouncedOptionsSearch,
  });
  const fetchQueryProductsParams = useQueryArray({
    page: 1,
    limit: 100,
    text: debouncedProductsSearch,
    sort: 'name',
  });

  const { data: productsOptions, isFetching } = useGetProductsOptionsQuery({
    urlQuery: fetchQueryParams,
  });

  const { data: products, isFetching: isFetchingProducts } =
    useGetProductsOptionsQuery({ urlQuery: fetchQueryProductsParams });

  return {
    setOptionsSearch,
    setProductsSearch,
    productsOptions,
    products,
    isFetching,
    isFetchingProducts,
  };
};
