import { Button, Stack, Typography } from '@mui/material';
import {
  CalendarBlank,
  CalendarCheck,
  CaretLeft,
  CaretRight,
  RoadHorizon,
  File,
  DownloadSimple,
  Crosshair,
} from '@phosphor-icons/react';
import { Marker } from '@vis.gl/react-google-maps';

import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import If from 'app/components/If';
import { InfoCard } from 'app/components/InfoCard';
import MapPopover from 'app/components/MapPopover';

import { TwoViewModal } from 'app/components/TwoViewModal';
import { StatusOrder } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import dayjs from 'dayjs';

import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { themes } from 'styles/theme/themes';

const FuelStopDetails = ({
  selectedOrder,
  handleCloseOrderDrawer,
  selectedStopDetails,
  isFirstStop,
  isLastStop,
  handleNextStop,
  handlePreviousStop,
  handleDisplayMap,
}: {
  selectedOrder: any;
  handleCloseOrderDrawer: () => void;
  matchingStop: any;
  selectedStopDetails: any;
  isFirstStop: boolean;
  isLastStop: boolean;
  handleNextStop: () => void;
  handlePreviousStop: () => void;
  handleDisplayMap: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const [mapPopoverOpen, setMapPopoverOpen] = useState(false);
  const downloadFile = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };
  const handleDownload = (e: React.MouseEvent, doc: any, index: number) => {
    e.preventDefault();
    e.stopPropagation();

    const fileName =
      doc.fileName ||
      `receipt_${index + 1}.${doc.type === 'picture' ? 'jpg' : 'pdf'}`;
    downloadFile(doc.url, fileName);
  };

  const coordinates = {
    lng: selectedOrder?._place?.location?.geometry?.coordinates[0] || 0,
    lat: selectedOrder?._place?.location?.geometry?.coordinates[1] || 0,
  };
  const customMarkers = <Marker position={coordinates} />;
  return (
    <TwoViewModal
      onOpen={!isEmpty(selectedOrder)}
      onClose={() => {
        handleCloseOrderDrawer();
      }}
      headerContent={
        <Stack direction="row" alignItems="center" gap="8px">
          <Crosshair
            weight="bold"
            size={16}
            color={themes?.default?.primaryActiveColor}
            onClick={() => setMapPopoverOpen(true)}
          />
          <Typography
            fontSize="1rem"
            fontWeight="bold"
            sx={{
              maxWidth: '321px',
              width: 'fit-content',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {selectedOrder?._place?.name || '-'}
          </Typography>
          <MapPopover
            longitude={coordinates.lng}
            latitude={coordinates.lat}
            open={mapPopoverOpen}
            handleClose={() => setMapPopoverOpen(false)}
            customMarkers={customMarkers}
          />

          <Stack
            direction="row"
            gap="4px"
            width="3.875rem"
            alignItems="center"
            justifyContent="center"
            sx={{
              border: '1px solid',
              borderRadius: '3px !important',
              borderColor: themes?.default?.silver,
              '&.MuiStack-root': {
                borderRadius: '3px !important',
              },
            }}
            onClick={() => handleDisplayMap(selectedStopDetails?._id)}
          >
            <RoadHorizon
              weight="bold"
              size={16}
              color={themes?.default?.primaryActiveColor}
              style={{ cursor: 'pointer' }}
            />
            <Typography
              fontSize="0.75rem"
              color={themes?.default?.clientMarkerBg}
            >
              {selectedStopDetails?.code}
            </Typography>
          </Stack>
          <VerticalDivider />
          <StatusOrder status={selectedOrder?.status} />
          <Stack
            direction="row"
            alignItems="center"
            gap="12px"
            marginLeft="auto"
          >
            <NavigationButton
              disabled={isFirstStop}
              onClick={!isFirstStop ? handlePreviousStop : undefined}
            >
              <CaretLeft size={16} color={themes.default.black} />
            </NavigationButton>
            <NavigationButton
              disabled={isLastStop}
              onClick={!isLastStop ? handleNextStop : undefined}
            >
              <CaretRight size={16} color={themes.default.black} />
            </NavigationButton>
          </Stack>
        </Stack>
      }
      width="740px"
    >
      <DrawerContent>
        <Wrapper>
          <Stack direction="row" gap="12px">
            <InfoCard
              icon={
                <CalendarBlank
                  weight="bold"
                  color={themes?.default?.deepPurple800}
                />
              }
              iconBg={themes?.default?.purple50}
              title={t('common.created_on')}
              value={
                <Typography
                  noWrap
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontSize: '0.75rem',
                    fontWeight: 500,
                  }}
                >
                  {selectedOrder?.createdAt
                    ? dayjs(selectedOrder?.createdAt)
                        .format('DD MMM, YYYY hh:mm A')
                        .replace(/\./g, '')
                    : '-'}
                </Typography>
              }
            />
            <InfoCard
              icon={
                <CalendarCheck
                  weight="bold"
                  color={themes?.default?.amber1000}
                />
              }
              iconBg={themes?.default?.amber100}
              title="Completed on"
              value={
                <Typography
                  noWrap
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontSize: '0.75rem',
                    fontWeight: 500,
                  }}
                >
                  {selectedOrder?.status === 'completed' &&
                  selectedOrder?.updatedStatusAt
                    ? dayjs(selectedOrder?.updatedStatusAt)
                        .format('DD MMM, YYYY hh:mm A')
                        .replace(/\./g, '')
                    : '-'}
                </Typography>
              }
            />
          </Stack>{' '}
          <Typography fontWeight={700} fontSize="0.875rem">
            Stop Outcome{' '}
          </Typography>
          <ContentContainer>
            <ContentTitle width="200px" fontSize="0.75rem">
              Gas
            </ContentTitle>
            <If
              condition={Boolean(selectedOrder?.fuelQuantity)}
              otherwise={
                <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                  -
                </Typography>
              }
            >
              <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                {`${selectedOrder?.fuelQuantity} L`}
              </Typography>
            </If>
          </ContentContainer>
          <ContentContainer>
            <ContentTitle width="200px" fontSize="0.75rem">
              Amount
            </ContentTitle>
            <If
              condition={Boolean(selectedOrder?.fuelAmount)}
              otherwise={
                <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                  -
                </Typography>
              }
            >
              <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                {`€ ${Number(selectedOrder?.fuelAmount).toFixed(2)}`}
              </Typography>
            </If>
          </ContentContainer>
          <Typography fontWeight={700} fontSize="0.875rem">
            Attachment
          </Typography>
          <AttachmentContainer>
            {selectedOrder?.documents?.length > 0 ? (
              selectedOrder.documents.map((doc, index) => (
                <Stack width="100%" key={index}>
                  <ImageStack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {doc.type === 'picture' ? (
                      <>
                        <img
                          src={doc.url}
                          alt={doc.fileName}
                          style={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                        <StyledDownloadButton
                          onClick={(e) => handleDownload(e, doc, index)}
                        >
                          <DownloadSimple
                            size={16}
                            color={themes.default.black}
                          />
                        </StyledDownloadButton>
                      </>
                    ) : (
                      <File size={48} color={themes.default.grey400} />
                    )}
                  </ImageStack>
                  <FileTypeStack>
                    <Typography fontWeight={700} fontSize="0.875rem">
                      Receipt
                    </Typography>
                    <Stack direction="row" alignItems="center" gap="8px">
                      <Typography
                        fontSize="0.75rem"
                        color={themes.default.clientMarkerBg}
                      >
                        {doc.fileName.split('.').pop()?.toUpperCase() ||
                          'UNKNOWN'}
                      </Typography>
                    </Stack>
                  </FileTypeStack>
                </Stack>
              ))
            ) : (
              <Typography fontSize="0.75rem" color={themes.default.grey400}>
                No attachment available
              </Typography>
            )}
          </AttachmentContainer>
        </Wrapper>
      </DrawerContent>
      <div>
        <div>
          <Button
            variant="contained"
            onClick={() => {
              handleCloseOrderDrawer();
            }}
          >
            {t('common.buttons.done')}
          </Button>
        </div>
      </div>
    </TwoViewModal>
  );
};

export default FuelStopDetails;

const NavigationButton = styled.span<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border: 1px solid ${themes.default.silver};
  border-radius: 3px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${themes.default.lightGrey};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;
const DrawerContent = styled.div`
  max-width: 100%;
  margin: auto;
  overflow-y: scroll;
`;
export const Wrapper = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
`;
export const ContentContainer = styled.div`
  width: 100%;
  min-height: 40px;
  background: ${themes?.default?.lightGrey};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
`;
export const AttachmentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  gap: 16px;
  width: 100%;
  border-radius: 4px;
  justify-content: start;
`;
export const ContentTitle = styled(Typography)`
  color: ${themes?.default?.clientMarkerBg};
`;

const StyledDownloadButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: ${themes.default.accordionWhiteBg};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
`;

// Add these styled components at the bottom of the file with the other styled components
const ImageStack = styled(Stack)`
  height: 127px;
  background: ${themes.default.lightGrey};
  border-radius: 4px;
  border: 1px solid ${themes.default.silver};
  position: relative;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  border-bottom: 0px;

  &.MuiStack-root {
    border-radius: 4px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`;

const FileTypeStack = styled(Stack)`
  padding: 8px;
  height: 53px;
  background: ${themes.default.lightGrey};
  border: 1px solid ${themes.default.silver};
  border-top-width: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 0px;
  &.MuiStack-root {
    border-radius: 4px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
`;

// Then replace the existing Stack components with:
