import { useDebounce } from 'common/hooks/useDebounce';
import { useGetUsersWebQuery } from 'common/services/userApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { useMemo, useState, useEffect } from 'react';
import { AssigneeAutoComplete } from '../..';
import { AssigneeType } from 'app/pages/Accessories/Types';
import { selectOrganization } from 'common/store/organization/selectors';
import { useSelector } from 'react-redux';
import { getAgent } from 'common/helpers/agent';
import i18next from 'i18next';

interface Props {
  assignee?: any;
  control?: any;
  disabled?: boolean;
  title?: string;
  required?: boolean;
  department?: string;
  width?: string;
}

export const UserAutoComplete = ({
  assignee,
  control,
  disabled,
  title,
  required,
  department,
  width,
}: Props) => {
  const [userSearch, setUserSearch] = useState('');
  const [prevDepartment, setPrevDepartment] = useState(department);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const debouncedUserSearch = useDebounce(userSearch, 300);
  const queryParams = useQueryParams({
    page: 1,
    limit: 50,
    sort: 'lastName',
    isActive: true,
    isLocked: false,
    isArchived: false,
    text: debouncedUserSearch,
    _department: department,
  });
  const {
    data: userData,
    isFetching,
    isLoading,
  } = useGetUsersWebQuery(queryParams);

  const authOrganization = useSelector(selectOrganization);
  const users = useMemo(() => {
    if (isLoading || !userData) return [];
    return userData.docs.map((user) => {
      const preferredAgent = getAgent(user, authOrganization);
      const currentLang = i18next?.resolvedLanguage || 'en';
      const roleLocaleName =
        user?.currentAgent?._role?.localeName?.[currentLang];

      return {
        value: user._id,
        label: preferredAgent.firstName + ' ' + preferredAgent.lastName,
        subLabel: roleLocaleName || '',
      };
    });
  }, [isLoading, userData, authOrganization, department]);

  const defaultValue = useMemo(() => {
    if (!assignee) return undefined;
    return {
      value: assignee?._id,
      label: assignee?.fullName,
      subLabel: assignee?.currentAgent?._role?.key || '',
    };
  }, [assignee]);

  // Set initial department on first render
  useEffect(() => {
    if (isInitialRender) {
      setPrevDepartment(department);
      setIsInitialRender(false);
    }
  }, [department, isInitialRender]);

  // Reset the employee value when department changes (but not on initial render)
  useEffect(() => {
    // Skip the effect on initial render to preserve default values for editing
    if (!isInitialRender && department !== prevDepartment) {
      setPrevDepartment(department);
      setUserSearch('');
    }
  }, [department, prevDepartment, isInitialRender]);

  // Determine if we should reset the value
  const shouldResetValue = !isInitialRender && department !== prevDepartment;

  return (
    <>
      {/*<If condition={!isLoading}>*/}
      <AssigneeAutoComplete
        options={users}
        setSearch={setUserSearch}
        isFetching={isFetching}
        control={control}
        disabled={disabled}
        title={title || 'common.employee'}
        type={AssigneeType.employee}
        defaultValue={shouldResetValue ? undefined : defaultValue}
        required={required}
        width={width}
        key={shouldResetValue ? `department-${department}` : 'stable-key'}
      />
      {/*</If>*/}
    </>
  );
};
