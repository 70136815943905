import { CustomCellRendererProps } from '@ag-grid-community/react';
import { IconButton, Tooltip } from '@mui/material';
import { DownloadSimple } from '@phosphor-icons/react';
import { downloadFile } from 'app/helpers/helpers';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

function DownloadCell(params: CustomCellRendererProps) {
  const initialUrl = params.value.downloadData;
  const { t } = useTranslation();

  return (
    <Tooltip title={t('common.download')}>
      <IconButton
        onClick={() => {
          const fileName = initialUrl.split('/').pop() || 'file.pdf';
          downloadFile(initialUrl, fileName);
        }}
        sx={{
          ':hover': {
            background: themes?.default?.rippleGrey,
          },
          padding: '4px',
          width: '28px',
          height: '28px',
        }}
      >
        <DownloadSimple />
      </IconButton>
    </Tooltip>
  );
}

export default DownloadCell;
