import React from 'react';
import { Table, TableRow, TableCell, Box, Stack } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from 'app/components/Form/styles';
import { KeyOutlined } from '@mui/icons-material';
import { useTheme } from 'common/hooks/useTheme';

interface Door {
  name: string;
  code: string;
}

interface DoorsProps {
  doors: Door[];
  branch?: {
    doors?: Door[];
  };
}

const StyledTable = styled(Table)`
  border: 1px solid ${({ theme }) => theme.gainsboro2};
  width: 100%;
  margin: 0;
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 1px solid ${({ theme }) => theme.gainsboro2};
`;

const StyledTableCell = styled(TableCell)<{ borderRight?: boolean }>`
  padding: 2px 4px !important;
  width: 50%;
  border-right: 1px solid ${({ theme }) => theme.gainsboro2} !important;
  font-size: 0.75rem;
`;

const Doors: React.FC<DoorsProps> = ({ doors }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      {!!doors?.length && (
        <>
          <SectionTitle>{t('doors')}</SectionTitle>
          <Box width="50%">
            <StyledTable>
              {doors.map((door, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    borderRight
                    sx={{ color: theme.textColorSecondary }}
                  >
                    {door.name}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack direction="row" justifyContent="space-between">
                      {door.code || '--'}

                      <KeyOutlined
                        sx={{
                          color: !!door.code
                            ? theme.greenStatus
                            : theme.iconColor,
                        }}
                      />
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </StyledTable>
          </Box>
        </>
      )}
    </>
  );
};

export default Doors;
