import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TextField,
  InputAdornment,
  Stack,
  Checkbox,
  Box,
  Tooltip,
} from '@mui/material';
import {
  validateInputNumberPostive,
  validateInputNumberPaste,
  validateDecimalPoints,
} from 'app/helpers/helpers';
import { DiscountInputProps, FormDataValue } from '../../types';
import { useRestrictionsSingle } from 'app/components/Restrictions/hooks/useRestrictionsSingle';
import { Icon } from 'app/components/Icon';
import { Lock } from '@phosphor-icons/react';
import If from 'app/components/If';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import { DiscountName } from 'app/components/Restrictions/types/discounts.types';

export const DiscountInput = ({
  i,
  initialValue,
  onChanged,
  hideLockIcon = false,
  canEditLockedFields = false,
}: DiscountInputProps) => {
  const { t } = useTranslation();

  const { ri, label } = useMemo(() => {
    const ri = `r${i}` as DiscountName;
    const label = `${t('discount_abbrev') + i}`;
    return { ri, label };
  }, [i]);

  const restrictions = useRestrictionsSingle(i);
  const [disabled, setDisabled] = useState(
    initialValue === null || restrictions.lock,
  );
  const [value, setValue] = useState(initialValue);
  const [inputChecked, setInputChecked] = useState(
    canEditLockedFields || !disabled,
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const update = useCallback(
    (n: FormDataValue) => {
      const n100: FormDataValue =
        n && Math.min(restrictions.max * 100, Math.max(0, n));
      setValue(n100);
      onChanged(ri, n100);
    },
    [onChanged, restrictions.max, ri],
  );

  const onChangedField = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const v = ev.target.value;
      const cleaned = v.toString().replace(/\.$/, '') || '0';
      const n = parseFloat(cleaned);
      if (isNaN(n)) return ev.preventDefault();
      update(n);
    },
    [update],
  );

  const onCheckedField = useCallback(
    (_, checked: boolean) => {
      setInputChecked(checked);
      if (restrictions.lock) return; // failsafe
      update(
        checked
          ? isNumber(initialValue)
            ? initialValue
            : 0 // default discount is 0
          : null,
      );
      setDisabled(!checked);
    },
    [restrictions.lock, restrictions.max, update, initialValue],
  );

  const onWheel = useCallback(
    (ev) => {
      if (value === null) return;
      const plus = ev.deltaY < 0;
      update(value + (plus ? 1 : -1));
    },
    [update, value],
  );

  return (
    <Tooltip
      title={
        !canEditLockedFields && restrictions.lock ? t('common.locked') : ''
      }
      placement="left"
      disableInteractive
      arrow
      slotProps={{
        popper: {
          modifiers: [{ name: 'offset', options: { offset: [0, -16] } }],
        },
      }}
    >
      <Stack direction="row" alignItems="center">
        <CheckBoxWrapper>
          <If
            condition={hideLockIcon || !restrictions.lock}
            otherwise={<Icon icon={<Lock />} size={20} />}
          >
            <Checkbox
              disabled={!canEditLockedFields && restrictions.lock}
              defaultChecked={canEditLockedFields || !disabled}
              onChange={onCheckedField}
            />
          </If>
        </CheckBoxWrapper>

        {/* // because Stack spacing is overridden */}
        <Box sx={{ mx: 0.5 }} />

        <TextField
          type="number"
          label={label}
          value={
            inputChecked &&
            (canEditLockedFields || !restrictions.lock) &&
            value !== null
              ? value.toString()
              : ''
          }
          disabled={
            !inputChecked ||
            (!canEditLockedFields && (disabled || restrictions.lock))
          }
          sx={{ flexGrow: 1, margin: '0px !important' }}
          InputProps={{
            inputProps: { min: 0, max: 100 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          onWheel={onWheel}
          onChange={onChangedField}
          onKeyDown={(e) => {
            validateInputNumberPostive(e);
            validateDecimalPoints(e);
          }}
          onPaste={validateInputNumberPaste}
        />
      </Stack>
    </Tooltip>
  );
};

const CheckBoxWrapper = styled(Box)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
