import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { ActionsProps, NestedMenuComponent } from 'app/components/NestedMenu';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { PopoverText } from 'hooks/Orders/useFormatOrderData';
import { Grid, Typography } from '@mui/material';
import { Icon } from 'app/components/Icon';
import {
  Package,
  Heart,
  CurrencyCircleDollar,
  FilePdf,
} from '@phosphor-icons/react';
import { CustomerOrders } from '../../BulkActionsToolbar/components/CustomerOrdersPopover';
import { useCallback, useContext, useMemo } from 'react';
import { CatalogContext } from '../../..';
import { useToggleFavoriteItemsMutation } from 'common/services/customerApi';
import { useToaster } from 'hooks/useToaster';
import { useHelpers } from '../../../userHelpers';
import { OrderProduct } from 'types';

export function ActionsMenuCell(params) {
  const { t } = useTranslation();
  const toast = useToaster();

  const { handleAddProductToOrder } = useHelpers();

  const {
    setFormatedSelectedProducts,
    setOpenCreateOrderDrawer,
    setFavoriteItems,
    isCustomer,
    setSelectedProducts,
  } = useContext(CatalogContext);

  const handleCreateOrder = useCallback(() => {
    setFormatedSelectedProducts([
      {
        _product: params?.value?.cellData?.product,
        quantity: 1,
        discount: 0,
      },
    ]);
    setOpenCreateOrderDrawer(true);
  }, [
    params?.value?.cellData?.product,
    setFormatedSelectedProducts,
    setOpenCreateOrderDrawer,
  ]);

  const [toggleFavoriteItems] = useToggleFavoriteItemsMutation();

  const toggleFavorite = useCallback(async () => {
    try {
      const response = await toggleFavoriteItems({
        products: [params?.value?.cellData?.product?._id],
      }).unwrap();
      setFavoriteItems(response.result);
      toast(3000, 'success', t('toggle_fav.success'));
    } catch (error: any) {
      toast(3000, 'error', error?.data?.message);
    }
  }, [
    params?.value?.cellData?.product?._id,
    setFavoriteItems,
    t,
    toast,
    toggleFavoriteItems,
  ]);

  const handleAddToOrderClick = useCallback(
    (orderId: string, orderCode: string) => {
      const products: OrderProduct[] = [
        {
          _product: params?.value?.cellData?.product?._id,
          quantity: 1,
          discount: 0,
          salePrice: params?.value?.cellData?.product?.boxPrice || 0,
          amount: params?.value?.cellData?.product?.boxPrice || 0,
        },
      ];
      handleAddProductToOrder(orderId, orderCode, products);
      setSelectedProducts({ grid: [] });
    },
    [
      handleAddProductToOrder,
      params?.value?.cellData?.product,
      setSelectedProducts,
    ],
  );

  const menuActions = useMemo<ActionsProps[]>(() => {
    const actions: ActionsProps[] = [];

    actions.push({
      action: 'addToOrder',
      nested: true,
      actions: [
        {
          action: '',
          disableClick: true,
          closeOnClick: true,
          element: (
            <CustomerOrders
              onCreateClick={handleCreateOrder}
              onOrderClick={handleAddToOrderClick}
            />
          ),
        },
      ],
      element: (
        <PopoverText>
          <Grid
            sx={{
              margin: '0px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Icon icon={<Package />} size={18} />
            <Typography>{t('orders.title.addToOrder')}</Typography>
          </Grid>
        </PopoverText>
      ),
    });

    if (isCustomer) {
      actions.push({
        action: 'addToFavorites',
        nested: false,
        onClick: () => toggleFavorite(),
        element: (
          <PopoverText>
            <Grid
              sx={{
                margin: '0px 16px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Icon icon={<Heart />} size={18} />
              <Typography>{t('add_to_favorites')}</Typography>
            </Grid>
          </PopoverText>
        ),
      });
    }

    actions.push({
      action: 'requestQuotation',
      nested: false,
      onClick: () => {},
      element: (
        <PopoverText>
          <Grid
            sx={{
              margin: '0px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Icon icon={<CurrencyCircleDollar />} size={18} />
            <Typography>{t('request_quotation')}</Typography>
          </Grid>
        </PopoverText>
      ),
    });

    actions.push({
      action: 'downloadPDF',
      nested: false,
      onClick: () => {},
      element: (
        <PopoverText>
          <Grid
            sx={{
              margin: '0px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Icon icon={<FilePdf />} size={18} />
            <Typography>{t('download_as_PDF')}</Typography>
          </Grid>
        </PopoverText>
      ),
    });

    return actions;
  }, [handleCreateOrder, isCustomer, t, toggleFavorite]);

  return (
    <NestedMenuComponent
      id={1}
      anchorOriginVertical="bottom"
      anchorOriginHorizontal="right"
      transformOriginVerical="top"
      transformOriginHorizontal="right"
      leftPosition={true}
      iconAction={
        <MoreHorizOutlinedIcon
          style={{
            width: '16px',
            height: '16px',
            color: themes?.default?.iconColor,
          }}
        />
      }
      iconOpen={
        <MoreHorizOutlinedIcon
          style={{
            width: '16px',
            height: '16px',
            color: themes?.ordoria?.primary,
          }}
        />
      }
      actions={menuActions}
    />
  );
}
