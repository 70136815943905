import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { IGridContext, ITabsContext, IToolbarConfig } from '../types';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import ManagedTabs from '../../ManagedTabs';
import { ManagedGridActions } from './ManagedGridActions';
import { useTranslation } from 'react-i18next';

function ManagedToolbar<T>({
  toolbarConfig,
}: {
  toolbarConfig?: IToolbarConfig;
}) {
  const gridContext = useManagedContext<IGridContext<T>>('grid');
  const tabsContext = useManagedContext<ITabsContext>('tabs');
  useEffect(() => {
    tabsContext.onTabChange?.(tabsContext.selectedTab);
  }, [tabsContext.selectedTab]);
  const { t } = useTranslation();
  return (
    <ContentWrapper style={{ ...toolbarConfig?.wrapperStyle }}>
      <Stack direction="row" gap="12px">
        <Stack justifyContent="center">
          <Typography
            fontSize="1rem"
            fontWeight="500"
            alignItems="center"
            paddingLeft="10px"
          >
            {t(gridContext.title)}
          </Typography>
        </Stack>
        <Stack height="40px" alignItems="center">
          <ManagedTabs />
        </Stack>
      </Stack>
      <ManagedGridActions {...(toolbarConfig || {})} />
    </ContentWrapper>
  );
}
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #e0e0e0;
  height: 40px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 11;
`;
export default ManagedToolbar;
