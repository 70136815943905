import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsMenu } from 'app/components/TabsMenu';
import { InfoForm } from './InfoTab';
import { BranchTab } from './BranchTab';
import { useSelector } from 'react-redux';
import If from 'app/components/If';
import { Stack } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { usePlaceContext } from '../../hooks/usePlaceContext';
import {
  bindShortcut,
  unbindShortcut,
} from 'app/pages/RoundTrips/components/KeyboardShortcuts/functions';
import { useTabs, TabType } from 'app/components/TabsMenu/hooks/useTabs';
import {
  PageWrapper,
  TabsSection,
} from 'app/styledComponents/DetailsSection.styled';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';
import { HistoryTab } from './HistoryTab';
import { NotesTab } from './NotesTab';
import { SettingsTab } from './SettingsTab';
import { SupplierFormcontext } from '../../../Form/context';
// import { SupplierFormcontext } from 'app/pages/Suppliers/Form/context';
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

export const SupplierTabs = ({ supplierDetail }) => {
  const { t } = useTranslation();
  const zoomedIn = useSelector(selectIsZoomedIn);
  const { isSupplier } = useContext(SupplierFormcontext);

  const { setDrawerOpen, setSelectedBranchId } = usePlaceContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState('info');
  const handleChangeTab = useCallback(
    (tab: string) => {
      const params = new URLSearchParams(location.search);
      params.set('tab', tab);
      navigate(`?${params.toString()}`);

      setSelectedTab(tab);
    },
    [location?.search, navigate],
  );
  const handleOnSwitchTab = (event, newValue) => {
    handleChangeTab(newValue);
  };

  const branches: any = {};

  const shortcuts = [
    {
      keybinds: ['ctrl+1'],
      callback: () => handleChangeTab('info'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+2'],
      callback: () => handleChangeTab('branches'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+3'],
      callback: () => handleChangeTab('accounting'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+4'],
      callback: () => handleChangeTab('contracts'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+a'],
      callback: () => {
        setSelectedBranchId('');
        setDrawerOpen((drawerOpen) => !drawerOpen);
      },
      listener: 'keydown',
    },
  ];

  const handleBindShortcuts = useMemo(() => {
    return () => {
      shortcuts.map((shortcut) => {
        bindShortcut(shortcut.keybinds, shortcut.callback);
      });
    };
  }, []);

  const handleUnbindShortcuts = useMemo(() => {
    return () => {
      shortcuts.map((shortcut) => {
        unbindShortcut(shortcut.keybinds);
      });
    };
  }, []);

  useEffect(() => {
    handleBindShortcuts();
    return () => {
      handleUnbindShortcuts();
    };
  }, [handleBindShortcuts, handleUnbindShortcuts]);

  const showInfoError = useMemo(() => {
    return !supplierDetail?.mainLocation?.formattedAddress;
  }, [supplierDetail?.mainLocation?.formattedAddress]);

  const showBranchError = useMemo(() => {
    return branches?.docs?.find((b) => !b?.location?.name);
  }, [branches?.docs]);

  const defaultTabs: TabType[] = useMemo(() => {
    if (isSupplier) {
      return [
        {
          value: 'info',
          label: t('info'),
          errorMessage: showInfoError ? t('missing_required_fields') : '',
        },
        {
          value: 'branches',
          label: t('common.branches'),
          errorMessage: showBranchError ? t('missing_required_fields') : '',
        },
        { value: 'settings', label: t('nav.appHeader.settings') },
      ];
    }
    return [
      {
        value: 'info',
        label: t('info'),
      },
      {
        value: 'branches',
        label: t('common.branches'),
      },
      // {
      //   value: 'accounting',
      //   label: t('supplier_Accounting'),
      // },
      // {
      //   value: 'contracts',
      //   label: t('supplier_Contracts'),
      // },
      {
        value: 'Settings',
        label: t('supplier_Settings'),
      },
      {
        value: 'history',
        label: t('common.history'),
      },
      {
        value: 'note',
        label: t('common.label.note'),
      },
    ];
  }, [t]);

  const [tabs, setTabs] = useTabs({
    defaultTabs: defaultTabs,
    moduleName: 'supplierInfo',
  });

  const height = useMemo(() => {
    if (zoomedIn) {
      return `calc(100vh - 185px)`;
    }
    return `calc(100vh - 185px - ${themes.default?.topBarHeight})`;
  }, [zoomedIn]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="0 20px"
        borderBottom={`1px solid ${themes.default.lightFill}`}
        sx={{
          backgroundColor: 'white',
          height: themes.default.tabsBarHeight,
        }}
      >
        <TabsMenu
          handleOnSwitchTab={handleOnSwitchTab}
          selectedTab={selectedTab}
          defaultTabs={defaultTabs}
          tabs={tabs}
          setTabs={setTabs}
        />
      </Stack>
      <PageWrapper>
        <TabsSection height={height} padding="0">
          <If condition={selectedTab === 'info' && !!supplierDetail}>
            <InfoForm supplierDetail={supplierDetail} />
          </If>
          <If condition={selectedTab === 'branches'}>
            <BranchTab />
          </If>
          <If condition={selectedTab === 'Settings'}>
            <SettingsTab supplierDetail={supplierDetail} />
          </If>
          <If condition={selectedTab === 'history'}>
            <HistoryTab supplierId={supplierDetail?.supplier?._id} />
          </If>
          <If condition={selectedTab === 'note'}>
            <NotesTab supplierId={supplierDetail?.supplier?._id} />
          </If>
        </TabsSection>
      </PageWrapper>
    </>
  );
};
