import { Grid, Box, Typography, Chip } from '@mui/material';
import { At, PhoneCall, Globe } from 'phosphor-react';
import { themes } from 'styles/theme/themes';

export const VisitorsInfo = ({ organization }) => {
  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={8}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'center', sm: 'flex-start', md: 'flex-start' },
        textAlign: { xs: 'center', sm: 'left', md: 'left' },
        marginBottom: { xs: '24px', sm: '0', md: '0' },
      }}
    >
      <Box
        component="img"
        alt="Fedipat"
        src={organization?.pictureUrl}
        sx={{
          width: 107,
          height: 62,
          objectFit: 'contain',
          marginBottom: '32px',
        }}
      />
      <Typography
        fontWeight={700}
        sx={{
          width: '100%',
          marginBottom: '24px',
          fontSize: { xs: '28px', sm: '32px', md: '42px' },
          lineHeight: { xs: '32px', sm: '40px', md: '50.4px' },
        }}
      >
        Prenez un rendez-vous chez{' '}
        <span style={{ color: themes?.ordoria?.primary }}>
          {organization?.name}
        </span>
      </Typography>

      <Typography
        fontWeight={400}
        sx={{
          width: '100%',
          marginBottom: '32px',
          fontSize: { md: '18px' },
          lineHeight: { md: '25.2px' },
        }}
      >
        Planifiez votre visite facilement en remplissant ce formulaire. Notre
        équipe vous accueillera dans les meilleurs délais pour répondre à vos
        besoins.
      </Typography>
      <Grid item mb={1}>
        <Chip
          sx={{
            height: '40px',
            borderRadius: '12px',
            backgroundColor: themes.default.lavender,
          }}
          label={
            <Grid container alignItems="center">
              <At size={19.5} color={themes.default.ordoriaPrimary} />
              <Typography marginLeft="12px">{organization?.email}</Typography>
            </Grid>
          }
        />
      </Grid>
      <Grid item mb={1}>
        <Chip
          sx={{
            height: '40px',
            borderRadius: '12px',
            backgroundColor: themes.default.lavender,
          }}
          label={
            <Grid container alignItems="center">
              <PhoneCall size={19.5} color={themes.default.ordoriaPrimary} />
              <Typography marginLeft="12px">{organization?.phone}</Typography>
            </Grid>
          }
        />
      </Grid>
      <Grid item mb={1}>
        <Chip
          sx={{
            height: '40px',
            borderRadius: '12px',
            backgroundColor: themes.default.lavender,
          }}
          label={
            <Grid container alignItems="center">
              <Globe size={19.5} color={themes.default.ordoriaPrimary} />
              <Typography marginLeft="12px">
                <a
                  href="https://www.fedipat.com"
                  target="_blank"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  rel="noreferrer"
                >
                  www.fedipat.com
                </a>
              </Typography>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};
