import { SectionTitle } from 'app/components/Form/styles';
import { Typography } from '@mui/material';
import { useState, useEffect, useMemo, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'common/hooks/useDebounce';
import { useUpdateSupplierMutation } from 'common/services/supplierApi';
import { StatusSupplier } from 'app/components/StatusSupplier';
import { Contact, SupplierFields, SupplierStatus } from 'common/types/Supplier';
import { isEmpty, omit } from 'lodash';
import { Address } from 'types';
import LocationPicker from 'app/components/LocationPicker';
import {
  LeftSection,
  MainSection,
  RightSection,
  TabCard,
} from 'app/styledComponents/DetailsSection.styled';
import FormTextField from 'app/components/Form/TextField';
import { hasUpdatedValues } from 'app/helpers/helpers';
import { MainDetails } from 'app/pages/Suppliers/components/Form/MainDetails';
import { SupplierInformation } from 'app/pages/Suppliers/components/Form/SupplierInformation';
import { ContactInformation } from 'app/pages/Suppliers/components/Form/ContactInformation';
import { BusinessInformation } from 'app/pages/Suppliers/components/Form/BusinessInformation';
import { SupplierLogo } from 'app/pages/Suppliers/components/Form/SupplierLogo';
import { SupplierFormcontext } from 'app/pages/Suppliers/components/Form/context';
import If from 'app/components/If';
import { EmailList } from 'app/pages/Suppliers/components/Form/EmailList';
import { PhoneBook } from 'app/pages/Suppliers/components/Form/PhoneBook';

export const InfoForm = ({ supplierDetail }) => {
  const { isSupplier, canEdit } = useContext(SupplierFormcontext);

  const [mainlocationValue, setMainLocationvalue] = useState<Address>({
    name: supplierDetail?.supplier.mainLocation?.name || '',
    city: supplierDetail?.supplier.mainLocation?.city || '',
    department: supplierDetail?.supplier.mainLocation?.department || '',
    region: supplierDetail?.supplier.mainLocation?.region || '',
    continent: supplierDetail?.supplier.mainLocation?.continent || '',
    zipCode: supplierDetail?.supplier.mainLocation?.zipCode || '',
    formattedAddress:
      supplierDetail?.supplier.mainLocation?.formattedAddress || '',
    shortAddress: supplierDetail?.supplier.mainLocation?.shortAddress || '',
    countryCode: supplierDetail?.supplier.mainLocation?.countryCode || '',
    geometry: {
      type: supplierDetail?.supplier.mainLocation?.geometry?.type || 'Point',
      coordinates: supplierDetail?.supplier.mainLocation?.geometry
        ?.coordinates || [0, 0],
    },
  });

  const { t } = useTranslation();
  const methods = useForm<SupplierFields>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      professionType: supplierDetail?.supplier.profession ?? null,
      code: supplierDetail?.supplier.code ?? '',
      publicName: supplierDetail?.supplier.publicName ?? '',
      legalName: supplierDetail?.supplier.legalName ?? '',
      emailInput: supplierDetail?.supplier.emails ?? [],
      vatNumber: supplierDetail?.supplier.vatNumber ?? '',
      status: supplierDetail?.supplier.status ?? '',
      mainLocation: supplierDetail?.supplier.mainLocation ?? {},
      legalIdAlt: supplierDetail?.supplier.legalIdAlt ?? null,
      notes: supplierDetail?.supplier.notes ?? '',
      mobilePhone: supplierDetail?.supplier.mobilePhone ?? '',
      companyEmail: supplierDetail?.supplier.companyEmail ?? '',
      activityCode: supplierDetail?.supplier.activityCode || '',
      tags: supplierDetail?.supplier._tags || [],
      contacts: supplierDetail?.supplier.phonebooks || [],
    },
  });

  const {
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isValidating },
  } = methods;

  const watchedFields = watch();

  const [updateSupplier] = useUpdateSupplierMutation();

  const payload = useMemo(() => {
    return {
      _id: supplierDetail?.supplier._id,
      profession: watchedFields.professionType || null,
      code: watchedFields.code,
      publicName: watchedFields.publicName,
      legalName: watchedFields.legalName,
      emails: supplierDetail?.supplier.emails,
      vatNumber: watchedFields.vatNumber,
      status: watchedFields.status,
      mainLocation: mainlocationValue,
      legalIdAlt: Number(watchedFields.legalIdAlt),
      notes: watchedFields.notes,
      mobilePhone: watchedFields.mobilePhone,
      companyEmail: watchedFields.companyEmail,
      activityCode: Number(watchedFields.activityCode),
      _tags: watchedFields.tags,
      phonebooks: watchedFields.contacts?.map((c: Contact) => omit(c, '_id')),
    };
  }, [
    supplierDetail?.supplier._id,
    watchedFields.professionType,
    watchedFields.code,
    watchedFields.publicName,
    watchedFields.legalName,
    watchedFields.vatNumber,
    watchedFields.status,
    mainlocationValue,
    watchedFields.legalIdAlt,
    watchedFields.notes,
    watchedFields.mobilePhone,
    watchedFields.companyEmail,
    watchedFields.activityCode,
    watchedFields.tags,
    watchedFields.contacts,
  ]);

  const debouncedPayload = useDebounce(payload, 300);

  function handleSaveSupplierData() {
    const updatedValues = omit(payload, ['mainLocation']);
    const supplierValues = omit(supplierDetail?.supplier, ['mainLocation']);
    const hasChanges = hasUpdatedValues(updatedValues, {
      ...supplierValues,
      contacts: supplierDetail?.supplier.phonebooks?.map((c: Contact) =>
        omit(c, '_id'),
      ),
    });

    const hasLocationChanges = hasUpdatedValues(
      omit(payload?.mainLocation, ['geometry']),
      omit(supplierDetail?.supplier.mainLocation, ['geometry']),
    );
    if (
      supplierDetail?.supplier._id !== payload?._id ||
      (isSupplier && !mainlocationValue) ||
      !isEmpty(errors) ||
      (!hasChanges && !hasLocationChanges)
    ) {
      return;
    } else {
      updateSupplier({
        id: supplierDetail.supplier._id,
        ...debouncedPayload,
      });
    }
  }

  function handleSaveSiren() {
    if (
      watchedFields.legalIdAlt !== supplierDetail?.supplier.legalIdAlt &&
      supplierDetail?.supplier._id === payload?._id
    ) {
      updateSupplier({
        id: supplierDetail.supplier._id,
        code: watchedFields.code,
        legalName: watchedFields.legalName,
        publicName: watchedFields.publicName,
        legalIdAlt: Number(watchedFields.legalIdAlt),
      });
    }
  }
  function handleSaveCode() {
    if (
      watchedFields.code !== supplierDetail?.supplier.code &&
      supplierDetail?.supplier._id === payload?._id
    ) {
      updateSupplier({
        id: supplierDetail.supplier._id,
        code: watchedFields.code,
        legalName: watchedFields.legalName,
        legalIdAlt: Number(watchedFields.legalIdAlt),
        publicName: watchedFields.legalName,
      });
    }
  }

  useEffect(() => {
    setMainLocationvalue(supplierDetail?.supplier.mainLocation);
    reset({
      professionType: supplierDetail?.supplier.profession ?? '',
      companyEmail: supplierDetail?.supplier.companyEmail ?? '',
      code: supplierDetail?.supplier.code ?? '',
      publicName: supplierDetail?.supplier.publicName ?? '',
      legalName: supplierDetail?.supplier.legalName ?? '',
      emailInput: supplierDetail?.supplier.emails ?? [],
      vatNumber: supplierDetail?.supplier.vatNumber ?? '',
      _supplierType: supplierDetail?.supplier._supplierType ?? '',
      status: supplierDetail?.supplier.status ?? '',
      mainLocation: supplierDetail?.supplier.mainLocation ?? {},
      legalIdAlt: supplierDetail?.supplier.legalIdAlt ?? null,
      type: supplierDetail?.supplier.type ?? '',
      notes: supplierDetail?.supplier.notes ?? '',
      mobilePhone: supplierDetail?.supplier.mobilePhone ?? '',
      tags: supplierDetail?.supplier._tags || [],
    });
  }, [supplierDetail?.supplier._id, reset]);

  useEffect(() => {
    if (canEdit && !isValidating && isEmpty(errors.code)) {
      handleSaveCode();
    }
  }, [canEdit, watchedFields.code, errors.code, isValidating]);
  useEffect(() => {
    if (canEdit && !isValidating && isEmpty(errors.legalIdAlt)) {
      handleSaveSiren();
    }
  }, [canEdit, errors.legalIdAlt, isValidating, watchedFields.legalIdAlt]);

  useEffect(() => {
    if (canEdit) {
      handleSaveSupplierData();
    }
  }, [debouncedPayload]);

  const supplierStatus: {
    label: string;
    value: SupplierStatus;
    render: () => React.ReactNode;
  }[] = [];
  Object.values(SupplierStatus).forEach((status) => {
    supplierStatus.push({
      label: t(`supplier.status.${status}`),
      value: status,
      render: () => <StatusSupplier status={status} />,
    });
  });

  return (
    <MainSection>
      <LeftSection>
        <Typography fontSize="0.875rem" fontWeight={500}>
          {t('info')}
        </Typography>

        <Typography fontSize="0.75rem">
          {t(isSupplier ? 'supplier_profile_info' : 'supplier.info_tab_text')}
        </Typography>
        <TabCard height="auto" style={{ flex: 'none' }}>
          <SectionTitle>{t('common.address')}</SectionTitle>
          <LocationPicker
            defaultAddress={mainlocationValue}
            onChange={(address) => {
              !!address && setMainLocationvalue(address);
            }}
            mapContainerSx={{ height: '150px' }}
            disabled={!canEdit}
            isRequired={!!isSupplier}
            autocompleteProps={{
              textFieldProps: {
                InputLabelProps: {
                  required: true,
                },
              },
            }}
          />
        </TabCard>
      </LeftSection>

      <RightSection>
        <TabCard height="auto">
          <MainDetails control={control} errors={errors} />
        </TabCard>

        <TabCard height="auto">
          <SupplierInformation
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </TabCard>

        <TabCard height="auto">
          <ContactInformation control={control} />
        </TabCard>

        <TabCard height="auto">
          <PhoneBook control={control} setValue={setValue} />
        </TabCard>

        <TabCard height="auto">
          <EmailList
            control={control}
            errors={errors}
            reset={() => {
              setValue('emailInput', '');
            }}
          />
        </TabCard>
        <TabCard height="auto">
          <BusinessInformation control={control} />
        </TabCard>

        <If condition={!isSupplier}>
          <TabCard height="auto">
            <SectionTitle>{t('common.notes')}</SectionTitle>
            <FormTextField
              name="notes"
              label="common.notes"
              control={control}
              disabled={!canEdit}
              multiline
              rows={4}
            />
          </TabCard>
        </If>
        <TabCard height="auto">
          <SupplierLogo />
        </TabCard>
      </RightSection>
    </MainSection>
  );
};
