import api from './api';
// import { omit } from 'lodash';

export const supplierApis = api.injectEndpoints({
  endpoints: builder => ({
    getSuppliers: builder.query<any, string>({
      query: urlQuery => ({
        url: `/suppliers${urlQuery}`,
      }),
      providesTags: ['Suppliers'],
    }),
    getSupplier: builder.query<any, any>({
      query: id => ({
        url: `/suppliers/${id}`,
        method: 'GET',
      }),
      providesTags: ['Supplier', 'Suppliers'],
    }),
    getSuppliersUnrestricted: builder.query<any, any>({
      query: urlQuery => ({
        url: `/suppliers/unrestricted${urlQuery}`,
        method: 'GET',
      }),
      providesTags: ['Suppliers'],
    }),
    // post Supplier
    postSupplier: builder.mutation<void, any>({
      query: ({ ...body }) => ({
        url: `/suppliers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Suppliers'],
    }),
    //Edit Supplier Section
    //Update Form info
    updateSupplier: builder.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `/suppliers/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Supplier', 'Suppliers'],
    }),
    updateSupplierSettings: builder.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `/suppliers/${id}/settings`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Supplier', 'Suppliers'],
    }),

    //get SupplierFinancails
    // getCustomerFinancials: builder.query<any, any>({
    //   query: ({ id }) => ({
    //     url: `/suppliers/${id}/financials`,
    //     method: 'GET',
    //   }),
    // }),
    //Branch Tab
    getSupplierBranches: builder.query<any, any>({
      query: ({ supplierId, urlQuery }) => ({
        url: `/suppliers/${supplierId}/decks${urlQuery}`,
        method: 'GET',
      }),
    }),
    getSupplierBranch: builder.query<any, any>({
      query: ({ supplierId, branchId }) => ({
        url: `/suppliers/${supplierId}/decks/${branchId}`,
        method: 'GET',
      }),
    }),
    deleteSupplierBranch: builder.mutation<void, any>({
      query: ({ supplierId, branchId }) => ({
        url: `/suppliers/${supplierId}/deck/${branchId}`,
        method: 'DELETE',
      }),
    }),
    updateSupplierBranchStatus: builder.mutation<void, any>({
      query: ({ supplierId, branchId, status }) => ({
        url: `/suppliers/${supplierId}/decks/${branchId}/status`,
        method: 'PATCH',
        body: { status },
      }),
    }),
    addSupplierBranch: builder.mutation<void, any>({
      query: ({ body }) => ({
        url: `/suppliers/${body.supplierId}/decks`,
        method: 'POST',
        body,
      }),
    }),
    updateSupplierStatus: builder.mutation<void, any>({
      query: ({ id, status }) => ({
        url: `/suppliers/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
    }),
    patchSupplierDeck: builder.mutation<void, any>({
      query: ({ supplierId, deckId, body }) => ({
        url: `/suppliers/${supplierId}/deck/${deckId}`,
        method: 'PATCH',
        body,
      }),
    }),
    reorderSupplierDecks: builder.mutation<unknown, any>({
      query: ({ supplierId, body }) => ({
        url: `/suppliers/${supplierId}/reorder-decks`,
        method: 'PATCH',
        body,
      }),
    }),

    //historyTab
    getSupplierActivities: builder.query<any, any>({
      query: ({ id, query = '' }) => ({
        url: `/suppliers/${id}/activities${query}`,
      }),
      providesTags: ['Supplier'],
    }),

    getSupplierActivitiesByDate: builder.query<any, any>({
      query: ({ id, query = '' }) => ({
        url: `/suppliers/${id}/date-activities${query}`,
      }),
      providesTags: ['Supplier'],
    }),
  }),
});

export const {
  useGetSuppliersQuery,
  useLazyGetSuppliersQuery,
  useGetSupplierQuery,
  usePostSupplierMutation,
  useUpdateSupplierMutation,
  useGetSuppliersUnrestrictedQuery,
  //branchTab
  useGetSupplierBranchesQuery,
  useGetSupplierBranchQuery,
  useDeleteSupplierBranchMutation,
  useUpdateSupplierBranchStatusMutation,
  useLazyGetSupplierBranchQuery,
  useAddSupplierBranchMutation,
  usePatchSupplierDeckMutation,
  useReorderSupplierDecksMutation,
  //setetingsTab
  useUpdateSupplierSettingsMutation,
  // useToggleFavoriteStatusMutation,
  // useToggleFavoriteItemsMutation,

  //historyTab
  useGetSupplierActivitiesQuery,
  useLazyGetSupplierActivitiesQuery,
  useGetSupplierActivitiesByDateQuery,
  useLazyGetSupplierActivitiesByDateQuery,
} = supplierApis;
export default supplierApis;
