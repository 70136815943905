import { IColDef } from 'app/components/ManagedGrid/types';
import TypeCell from './cells/TypeCell';
import InvoiceCodeCell from './cells/InvoiceCodeCell';
import NameCell from './cells/NameCell';
import BranchCell from './cells/BranchCell';
import DateCell from './cells/DateCell';
import AmountCell from './cells/AmountCell';
import DueDateCell from './cells/DueDateCell';
import PayedAmountCell from './cells/PayedAmountCell';
import LateDaysCell from './cells/LateDaysCell';
import StatusCell from './cells/StatusCell';
import ActionCell from './cells/ActionCell';
import ClientCell from './cells/ClientCell';
import ArchivedCell from './cells/ArchivedCell';
import ActionsMenuCell from './cells/ActionsMenuCell';
import { themes } from 'styles/theme/themes';
import RemainingCell from './cells/RemainingCell';

export enum TransactionsTab {
  BILLS = 'bills',
  REFUND = 'refund',
  PAYMENTS = 'payments',
}

export enum BillsStatus {
  PENDING = 'pending',
  PARTIALLYPAID = 'partially-paid',
  PAID = 'paid',
  REJECTED = 'rejected',
}

export const BILLS_STATS_COLORS = {
  PENDING: {
    thumbColor: themes.default?.progressOrange,
    backgroundColor: themes.default?.orange50,
    railColor: themes.default?.orange100,
  },
  PARTIALLYPAID: {
    thumbColor: themes.default?.blue600,
    backgroundColor: themes.default?.blue50,
    railColor: themes.default?.blue200,
  },
  PAID: {
    thumbColor: themes.default?.fruitSaladGreen,
    backgroundColor: themes.default?.chipGreenFont,
    railColor: themes.default?.green200,
  },
  REJECTED: {
    thumbColor: themes.default?.errorDark,
    backgroundColor: themes.default?.chipRedFont,
    railColor: themes.default?.chipRedLine,
  },
};

export const BILLS_STATUS_CHIPS_COLORS = {
  PENDING: {
    backgroundColor: themes.default?.orangeBackground,
    color: themes.default?.safetyOrange,
    label: 'events.status.pending',
    key: 'pending',
  },
  PARTIALLYPAID: {
    backgroundColor: themes.default?.lightblue900,
    color: themes.default?.lightblue50,
    label: 'events.status.pending',
    key: 'pending',
  },
  PAID: {
    backgroundColor: themes.default?.greenPaidChip,
    color: themes.default?.greenStatus,
    label: 'events.status.pending',
    key: 'pending',
  },
  REJECTED: {
    backgroundColor: themes.default?.red800,
    color: themes.default?.red,
    label: 'events.status.rejected',
    key: 'rejected',
  },
};

export const getCustomInvoicesColumnConfig = (
  t: (key: string) => string,
  isUserCustomer: boolean,
): IColDef[] =>
  isUserCustomer
    ? [
        {
          cellRenderer: ArchivedCell,
          width: 52,
          lockPinned: true,
          pinned: 'left',
          hide: true,
        },
        {
          cellRenderer: TypeCell,
          width: 20,
          resizable: false,
          pinned: 'left',
          lockPinned: true,
        },
        {
          cellRenderer: InvoiceCodeCell,
          width: 100,
          resizable: true,
          headerName: t('transaction.invoice.code'),
          flex: 1,
          pinned: 'left',
        },
        {
          cellRenderer: NameCell,
          minWidth: 382,
          resizable: true,
          headerName: t('warehouse.heads.name'),
          flex: 1,
          hide: true,
        },
        {
          cellRenderer: BranchCell,
          minWidth: 140,
          resizable: true,
          headerName: t('orders.branch'),
          flex: 1,
        },
        {
          cellRenderer: DateCell,
          minWidth: 80,
          resizable: true,
          headerName: t('common.date'),
          flex: 1,
        },
        {
          cellRenderer: AmountCell,
          width: 80,
          resizable: true,
          headerName: t('roundtrips.roundtripDetails.amount'),
          flex: 1,
        },
        {
          cellRenderer: DueDateCell,
          width: 80,
          resizable: true,
          headerName: t('common.due_date'),
          flex: 1,
        },
        {
          cellRenderer: LateDaysCell,
          minWidth: 61,
          resizable: true,
          headerName: t('transaction.late-days'),
          flex: 1,
        },
        {
          cellRenderer: StatusCell,
          minWidth: 160,
          resizable: true,
          headerName: t('payments.status'),
          flex: 1,
          pinned: 'right',
        },
        {
          cellRenderer: ActionCell,
          minWidth: 102,
          resizable: false,
          pinned: 'right',
          lockPinned: true,
          cellStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
        {
          cellRenderer: ActionsMenuCell,
          width: 70,
          resizable: false,
          lockPinned: true,
          pinned: 'right',
        },
      ]
    : [
        {
          cellRenderer: ArchivedCell,
          width: 52,
          lockPinned: true,
          pinned: 'left',
          hide: true,
        },
        {
          cellRenderer: TypeCell,
          width: 20,
          resizable: false,
          pinned: 'left',
          lockPinned: true,
        },
        {
          cellRenderer: InvoiceCodeCell,
          width: 100,
          resizable: true,
          headerName: t('transaction.invoice.code'),
          flex: 1,
          pinned: 'left',
        },
        {
          cellRenderer: NameCell,
          minWidth: 382,
          resizable: true,
          headerName: t('warehouse.heads.name'),
          flex: 1,
          hide: true,
        },
        {
          cellRenderer: ClientCell,
          minWidth: 220,
          resizable: true,
          headerName: 'Client',
          flex: 1,
          hide: false,
        },
        {
          cellRenderer: BranchCell,
          minWidth: 218,
          resizable: true,
          headerName: t('orders.branch'),
          flex: 1,
        },
        {
          cellRenderer: DateCell,
          minWidth: 120,
          resizable: true,
          headerName: t('common.date'),
          flex: 1,
        },
        {
          cellRenderer: AmountCell,
          width: 80,
          resizable: true,
          headerName: t('roundtrips.roundtripDetails.amount'),
          flex: 1,
        },
        {
          cellRenderer: DueDateCell,
          width: 80,
          resizable: true,
          headerName: t('common.due_date'),
          flex: 1,
        },
        {
          cellRenderer: PayedAmountCell,
          width: 80,
          resizable: true,
          headerName: t('transaction.payed.amount'),
          flex: 1,
        },
        {
          cellRenderer: LateDaysCell,
          minWidth: 61,
          resizable: true,
          headerName: t('transaction.late-days'),
          flex: 1,
        },
        {
          cellRenderer: RemainingCell,
          width: 80,
          resizable: true,
          headerName: t('transaction.remaining'),
          flex: 1,
        },
        {
          cellRenderer: StatusCell,
          minWidth: 160,
          resizable: true,
          headerName: t('payments.status'),
          flex: 1,
          pinned: 'right',
        },
        {
          cellRenderer: ActionCell,
          minWidth: 102,
          resizable: false,
          pinned: 'right',
          lockPinned: true,
          cellStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
        {
          cellRenderer: ActionsMenuCell,
          width: 70,
          resizable: false,
          lockPinned: true,
          pinned: 'right',
        },
      ];
