import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RoundtripStatusIndicator } from '../../../../components/RoundtripStatus';
import { Stops } from '../../../AddRoundTrips/data/RoundTripData/components/StopsBar';
import { RouteData } from '../../../AddRoundTrips/data/RoundTripData/components/RouteData';
import Constraints from '../../../../components/RoundTrips/components/Constraint';
import { Rstatus } from 'app/pages/AddRoundTrips/data/RoundTripData/components/RoundtripStatus';
import VehicleSelector from 'app/pages/AddRoundTrips/data/RoundTripData/components/VehicleSelector';
import { getVehicleLabel } from 'utils/pages/vehicles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  usePostInitMutation,
  useUpdateRoundTripMutation,
  useUpdateRoundTripsClusterMutation,
} from 'common/services/roundtripApi';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import If from 'app/components/If';
import UserSelector from 'app/pages/AddRoundTrips/data/RoundTripData/components/UserSelector';
import {
  Driver,
  Ripper,
  Sales,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { useSelector } from 'react-redux';
import { selectConfiguration } from 'common/store/organization/selectors';
import { useUsersData } from 'app/pages/AddRoundTrips/hooks/useUsersData';
import { disabledVehicle } from 'app/components/RoundTrips/function';
import { SupportUnitsPopover } from 'app/components/SupportUnitsPopover';
import { getIsRoundtripOverLoaded } from 'app/helpers/helpers';
import { Shapes } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import NoPhoneNumberIcon from 'assets/img/map/LeftMenu/NoPhoneNumberIcon.svg';
import PhoneNumberIcon from 'assets/img/map/LeftMenu/PhoneNumberIcon.svg';
import { Icon } from 'app/components/Icon';
import { ButtonBase } from '@mui/material';
import {
  usePhoneCallHook,
  useVoiceCallHook,
} from 'hooks/VoiceCall/useVoiceCallHook';
import { useGetUserQuery } from 'common/services/userApi';

interface Props {
  roundtrip: any;
  displayCode?: boolean;
  displayStatus?: boolean;
  userContext?: AgentCategories;
  canEdit?: boolean;
  roundtrips?: any[];
}

const getUserByRole = (agents: any[], roleKey: string) => {
  return agents?.find((data) => data?._role?.key === roleKey)?._user;
};

export default function RoundtripPopover({
  roundtrip,
  displayCode = true,
  displayStatus = false,
  userContext = AgentCategories.Logistic,
  canEdit = false,
  roundtrips = [],
}: Props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const configuration = useSelector(selectConfiguration);

  const [updateRoundtripData] = useUpdateRoundTripMutation();
  const [updateRoundtripCluster] = useUpdateRoundTripsClusterMutation();
  const [postInit] = usePostInitMutation();

  const [vehicles, setVehicles] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  const [vehiclesData, setVehiclesData] = useState<any[]>([]);
  const [usersData, setUsersData] = useState<any[]>([]);

  const warehouse = useMemo(() => {
    return JSON.parse(searchParams.get('warehouse') as string);
  }, [searchParams]);
  const cluster = useMemo(() => {
    return JSON.parse(searchParams.get('cluster') as string);
  }, [searchParams]);

  const VehiculeProps = {
    options: !vehicles ? [{ label: 'Loading...', id: 0 }] : vehicles,
    getOptionLabel: (option: any) => getVehicleLabel(option),
  };

  const addFunction = async (data: any) => {
    const resp = await updateRoundtripData(data).unwrap();
    return resp;
  };

  const getData = useCallback(async () => {
    const resp = await postInit({
      begin: dayjs()?.format('YYYY-MM-DD'),
      end: dayjs()?.format('YYYY-MM-DD'),
      clusterId: cluster,
      agentCategory: userContext,
      _deck: warehouse?._id,
    }).unwrap();
    if (resp) {
      setUsersData(resp.agents);
      setVehiclesData(resp.vehicles);
    }
  }, [cluster, postInit, userContext, warehouse?._id]);

  useUsersData(users, roundtrips, usersData, null, setUsers);

  useEffect(() => {
    disabledVehicle(vehicles, vehiclesData, setVehicles, null, roundtrips);
  }, [vehiclesData, roundtrips, setVehicles]);

  useEffect(() => {
    if (canEdit) getData();
  }, [canEdit, getData]);

  const handleFarDeliveredOrdersCount = useCallback(() => {
    var count = 0;
    roundtrip?.stops?.map((order) => {
      if (
        order?._order?.deliveryInfo?.distance &&
        order?._order?.deliveryInfo?.distance > 500
      ) {
        count++;
      }
    });
    return count;
  }, [roundtrip]);

  const farDeliveredOrdersCount = useMemo(() => {
    return handleFarDeliveredOrdersCount();
  }, [handleFarDeliveredOrdersCount]);

  const userRole = userContext === AgentCategories.Sales ? Sales : Driver;
  const { data: driverUser } = useGetUserQuery(
    getUserByRole(roundtrip?.agents, userRole)?._id,
    {
      skip: !getUserByRole(roundtrip?.agents, userRole)?._id,
    },
  );
  const { data: ripperUser } = useGetUserQuery(
    getUserByRole(roundtrip?.agents, Ripper)?._id,
    {
      skip: !getUserByRole(roundtrip?.agents, Ripper)?._id,
    },
  );

  const { handleCall: driverVoiceCall } = useVoiceCallHook(
    getUserByRole(roundtrip?.agents, userRole)?._id,
  );
  const { handleCall: ripperVoiceCall } = useVoiceCallHook(
    getUserByRole(roundtrip?.agents, Ripper)?._id,
  );

  const { handleCall: driverPhoneCall } = usePhoneCallHook(
    null,
    driverUser?.currentAgent?.companyPhone,
  );
  const { handleCall: ripperPhoneCall } = usePhoneCallHook(
    null,
    ripperUser?.currentAgent?.companyPhone,
  );

  const handleDriverCall = () => {
    if (driverUser?.currentAgent?.voiceStatus === 'online') {
      driverVoiceCall();
    } else if (driverUser?.currentAgent?.companyPhone) {
      driverPhoneCall();
    }
  };

  const handleRipperCall = () => {
    if (ripperUser?.currentAgent?.voiceStatus === 'online') {
      ripperVoiceCall();
    } else if (ripperUser?.currentAgent?.companyPhone) {
      ripperPhoneCall();
    }
  };

  const handleSalesCall = () => {
    if (driverUser?.currentAgent?.voiceStatus === 'online') {
      driverVoiceCall();
    } else if (driverUser?.currentAgent?.companyPhone) {
      driverPhoneCall();
    }
  };

  if (!roundtrip) return null;

  return (
    <RoundtripPopoverWrapper>
      {displayCode && (
        <RowInfo>
          <Label>{t('info.roundtrip')}</Label>
          <Value>
            <RoundtripStatusIndicator
              status={roundtrip.status}
              style={{ marginRight: '8px' }}
            />
            <span>{roundtrip.code}</span>
          </Value>
        </RowInfo>
      )}

      {displayStatus && (
        <RowInfo>
          <Label>{t('common.status')}</Label>
          <Value>
            <Rstatus
              style={{
                fontSize: '0.7rem',
                padding: '0.15938rem 0.2125rem',
                height: 'auto',
                width: 'auto',
              }}
              status={roundtrip.status}
            />
          </Value>
        </RowInfo>
      )}

      <RowInfo>
        <Label>{t('common.vehicle')}</Label>
        <Value>
          <If
            condition={!!canEdit}
            otherwise={roundtrip._vehicle?.numberPlate || 'N/A'}
          >
            <VehicleSelector
              vehicle={roundtrip._vehicle}
              VehiculeProps={VehiculeProps}
              vehicles={vehicles}
              addFuction={addFunction}
              roundtripId={roundtrip.id}
              updateRoundtripData={updateRoundtripData}
              schedule={updateRoundtripCluster}
              clusterId={roundtrip._cluster}
              userContext={userContext}
              minimal={true}
            />
          </If>
        </Value>
      </RowInfo>

      <RowInfo>
        <Label>{t(userRole === Sales ? 'sales' : 'common.driver')}</Label>
        <Value>
          <If
            condition={!!canEdit}
            otherwise={
              <span
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                {roundtrip?.agents?.find(
                  (data) => data?._role?.key === userRole,
                )?._user?.fullName || 'N/A'}
                {roundtrip?.agents?.find(
                  (data) => data?._role?.key === userRole,
                )?._user?.fullName && (
                  <If
                    condition={
                      roundtrip?.agents?.find(
                        (data) => data?._role?.key === userRole,
                      )?._user?.companyPhone
                    }
                    otherwise={
                      <Icon
                        icon={<img src={NoPhoneNumberIcon} alt="noPhone" />}
                      />
                    }
                  >
                    <PhoneIconWrapper>
                      <ButtonBase
                        sx={{
                          height: '22px',
                          minWidth: '22px',
                          borderRadius: '22px',
                        }}
                        onClick={() => {}}
                      >
                        <Icon
                          icon={<img src={PhoneNumberIcon} alt="PhoneNumber" />}
                          tooltip={
                            roundtrip?.agents?.find(
                              (data) => data?._role?.key === userRole,
                            )?._user?.companyPhone
                          }
                          tooltipWithArrow
                          tooltipPosition="top"
                        />
                      </ButtonBase>
                    </PhoneIconWrapper>
                  </If>
                )}
              </span>
            }
          >
            <UserSelector
              user={
                roundtrip?.agents?.find((data) => data?._role?.key === userRole)
                  ?._user
              }
              userRole={userRole}
              updateRoundtripData={updateRoundtripData}
              roundTripCopy={roundtrip}
              users={users}
              roundtripId={roundtrip.id}
              addFuction={addFunction}
              agentId={
                configuration?.roles.find((role) => role.key === userRole)?._id
              }
              schedule={updateRoundtripCluster}
              clusterId={roundtrip._cluster}
              runningVehicle={roundtrip?._vehicle?.engineRunning}
              minimal={true}
            />
            {roundtrip?.agents?.find((data) => data?._role?.key === userRole)
              ?._user?.fullName && (
              <If
                condition={driverUser?.currentAgent?.companyPhone}
                otherwise={
                  <Icon icon={<img src={NoPhoneNumberIcon} alt="noPhone" />} />
                }
              >
                <PhoneIconWrapper>
                  <ButtonBase
                    sx={{
                      height: '22px',
                      minWidth: '22px',
                      borderRadius: '22px',
                    }}
                    onClick={
                      userRole === Sales ? handleSalesCall : handleDriverCall
                    }
                  >
                    <Icon
                      icon={<img src={PhoneNumberIcon} alt="PhoneNumber" />}
                      tooltip={
                        roundtrip?.agents?.find(
                          (data) => data?._role?.key === userRole,
                        )?._user?.companyPhone
                      }
                      tooltipWithArrow
                      tooltipPosition="top"
                    />
                  </ButtonBase>
                </PhoneIconWrapper>
              </If>
            )}
          </If>
        </Value>
      </RowInfo>

      <If condition={userRole === Driver}>
        <RowInfo>
          <Label>{t('ripper')}</Label>
          <Value>
            <If
              condition={!!canEdit}
              otherwise={
                <span
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  {roundtrip.cache?.ripperUser?.fullName || 'N/A'}
                  {roundtrip.cache?.ripperUser?.fullName && (
                    <If
                      condition={
                        roundtrip?.agents?.find(
                          (data) => data?._role?.key === Ripper,
                        )?._user?.companyPhone
                      }
                      otherwise={
                        <Icon
                          icon={<img src={NoPhoneNumberIcon} alt="noPhone" />}
                        />
                      }
                    >
                      <PhoneIconWrapper>
                        <ButtonBase
                          sx={{
                            height: '22px',
                            minWidth: '22px',
                            borderRadius: '22px',
                          }}
                          onClick={() => {}}
                        >
                          <Icon
                            icon={
                              <img src={PhoneNumberIcon} alt="PhoneNumber" />
                            }
                            tooltip={
                              roundtrip?.agents?.find(
                                (data) => data?._role?.key === Ripper,
                              )?._user?.companyPhone
                            }
                            tooltipWithArrow
                            tooltipPosition="top"
                          />
                        </ButtonBase>
                      </PhoneIconWrapper>
                    </If>
                  )}
                </span>
              }
            >
              <UserSelector
                user={
                  roundtrip?.agents?.find((data) => data?._role?.key === Ripper)
                    ?._user
                }
                userRole={Ripper}
                updateRoundtripData={updateRoundtripData}
                roundTripCopy={roundtrip}
                users={users}
                roundtripId={roundtrip.id}
                addFuction={addFunction}
                agentId={
                  configuration?.roles.find((role) => role.key === Ripper)?._id
                }
                schedule={updateRoundtripCluster}
                clusterId={roundtrip._cluster}
                runningVehicle={roundtrip?._vehicle?.engineRunning}
                minimal={true}
              />
              {roundtrip.cache?.ripperUser?.fullName && (
                <If
                  condition={ripperUser?.currentAgent?.companyPhone}
                  otherwise={
                    <Icon
                      icon={<img src={NoPhoneNumberIcon} alt="noPhone" />}
                    />
                  }
                >
                  <PhoneIconWrapper>
                    <ButtonBase
                      sx={{
                        height: '22px',
                        minWidth: '22px',
                        borderRadius: '22px',
                      }}
                      onClick={handleRipperCall}
                    >
                      <Icon
                        icon={<img src={PhoneNumberIcon} alt="PhoneNumber" />}
                        tooltip={
                          roundtrip?.agents?.find(
                            (data) => data?._role?.key === Ripper,
                          )?._user?.companyPhone
                        }
                        tooltipWithArrow
                        tooltipPosition="top"
                      />
                    </ButtonBase>
                  </PhoneIconWrapper>
                </If>
              )}
            </If>
          </Value>
        </RowInfo>
      </If>

      <RowInfo>
        <Label>{t('common.stops')}</Label>
        <Value>
          <StopsWrapper>
            <Stops
              stats={roundtrip.cache?.stats}
              roundtripStatus={roundtrip.status}
              stopCount={roundtrip.stopCount}
              farDeliveredOrdersCount={farDeliveredOrdersCount}
            />
          </StopsWrapper>
        </Value>
      </RowInfo>

      <RowInfo>
        <Label>{t('roundtrips.tooltip.weight')}</Label>
        <Value>
          <Constraints weight={roundtrip?.cache?.stats?.totalWeight} />
        </Value>
      </RowInfo>

      <RowInfo>
        <Label>{t('roundtrips.tooltip.volume')}</Label>
        <Value>
          <Constraints volume={0} />
        </Value>
      </RowInfo>

      <RowInfo>
        <Label>{t('roundtrip.support_units')}</Label>
        <Value>
          <Shapes size={13} color={themes?.default?.iconColor} />
          {roundtrip?._vehicle
            ? `${roundtrip?.supportUnitsRequestedCapacity || 0} / ${
                roundtrip?._vehicle?.capacity?.supportUnit
              } `
            : `${roundtrip?.supportUnitsRequestedCapacity || 0} `}
          <SupportUnitsPopover
            canEdit={false}
            widthButton={20}
            supportUnits={{
              ...roundtrip?.supportUnitsList,
              unloaded: roundtrip?.supportUnitsListCollected,
            }}
            isOverLoaded={getIsRoundtripOverLoaded({
              vehicle: roundtrip?._vehicle,
              capacity: roundtrip?.supportUnitsRequestedCapacity,
            })}
            roundtripStatus={roundtrip?.status}
          />
        </Value>
      </RowInfo>

      <RowInfo>
        <Label>{t('roundtrips.tooltip.distance')}</Label>
        <Value>
          <RouteData distance={roundtrip.cache?.directions?.distance || 0} />
        </Value>
      </RowInfo>

      <RowInfo className="no-border">
        <Label>{t('roundtrips.tooltip.time')}</Label>
        <Value>
          <RouteData
            duration={roundtrip.cache?.directions?.estimatedTimeWithStop || 0}
          />
        </Value>
      </RowInfo>
    </RoundtripPopoverWrapper>
  );
}

const RoundtripPopoverWrapper = styled.div`
  padding: 0 1.5px 0 8px;
  height: calc(100% - 40px);

  @supports selector(::-webkit-scrollbar) {
    scrollbar-gutter: stable;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  scrollbar-color: transparent !important;

  overflow-y: overlay;
  overflow-x: hidden;

  &:hover {
    overflow-y: overlay;
    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.scrollBarColor} !important;
    }
    scrollbar-color: ${(props) => props.theme.scrollBarColor} !important;
  }
`;

const RowInfo = styled.div((props) => ({
  display: 'flex',
  width: '100%',
  borderBottom: `1px solid ${props.theme.whisper}`,
  padding: '3px 0',
  '&.no-border': {
    borderBottom: 'none',
  },
  fontSize: '0.75rem',
  alignItems: 'center',
  minHeight: '40px',
}));

const Label = styled.div(() => ({
  fontWeight: 'bold',
  padding: '3px',
  width: '100px',
}));

const Value = styled.div(() => ({
  display: 'flex',
  padding: '3px',
  flexGrow: 1,
  alignItems: 'center',
  width: '125px',
  gap: '5px',
}));

const StopsWrapper = styled.div`
  & div {
    min-width: auto;
  }

  & .container_progress {
    width: 115px;
  }
`;

const PhoneIconWrapper = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 22px;
  cursor: pointer;
`;
