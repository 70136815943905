import { IconButton } from '@mui/material';
import { ContractRowStatus } from '../../../types';
import If from 'app/components/If';
import { CheckCircle } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { themes } from 'styles/theme/themes';
import { useHelpers } from '../../../useHelpers';

export const ApproveCell = (params) => {
  const { status, _id, disabled } = params.value;
  const { handleApproveRows } = useHelpers();

  return (
    <If condition={status === ContractRowStatus.PENDING_APPROVAL}>
      <IconButton
        sx={{ padding: '2px' }}
        onClick={() => handleApproveRows([_id])}
        disabled={disabled}
      >
        <Icon
          icon={<CheckCircle weight={disabled ? 'thin' : 'fill'} />}
          size={24}
          color={disabled ? '' : themes.default.green800}
        />
      </IconButton>
    </If>
  );
};
