import styled from 'styled-components';
interface AIATableProps {
  headers: string[];
  data: any[];
}

export const AIATable: React.FC<AIATableProps> = ({ headers, data }) => (
  <Table>
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableHeaderCell key={header}>{header}</TableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((row, index) => (
        <TableRow key={index}>
          {headers.map((header) => (
            <TableCell key={header}>{row[header]}</TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

// Table design

export const Table = styled.table`
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: ${({ theme }) => theme.baseWhite};
  box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
  margin-top: 0.4rem;
  border-radius: 0.3rem;
  overflow-x: auto;
`;
export const ScrollableSection = styled.div`
  flex-grow: 1;
  overflow-x: auto;
  height: 100%;

  &::-webkit-scrollbar {
    height: 0.2rem;
  }
`;
export const TableHead = styled.thead`
  background: ${({ theme }) => theme.lightGrey};
`;

export const TableHeaderCell = styled.th`
  height: 0.625rem;
  padding: 0.2rem 0.6rem;
  font-size: 0.5625rem;

  font-weight: 500;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.gainsboro2};
  color: ${({ theme }) => theme.darkGreyColor};
  text-align: left;
  vertical-align: middle;
`;

export const TableRow = styled.tr`
  height: 0.625rem;
`;

export const TableCell = styled.td`
  padding: 0.125rem 0.5rem;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.gainsboro2};
  vertical-align: middle;

  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.0725rem;
  letter-spacing: 0.010625rem;
  text-align: left;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
`;

export const TableBody = styled.tbody`
  background-color: ${({ theme }) => theme.baseWhite};
`;
