import { Typography } from '@mui/material';
import { InfosList } from 'app/components/InfosList';
import { CustomerFormcontext } from 'app/pages/Customers/components/Form/context';
import {
  LeftSection,
  MainSection,
  RightSection,
} from 'app/styledComponents/DetailsSection.styled';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const SettingsTab = () => {
  const { t } = useTranslation();
  const { customer } = useContext(CustomerFormcontext);

  return (
    <MainSection>
      <LeftSection>
        <Typography fontSize="0.875rem" fontWeight={500}>
          {t('nav.appHeader.settings')}
        </Typography>
        <Typography fontSize="0.75rem">
          {t('customers.settingsTab.description')}
        </Typography>
      </LeftSection>
      <RightSection>
        <InfosList
          customer={customer}
          token={customer?.token}
          password="fedipat" //??
        />
      </RightSection>
    </MainSection>
  );
};
