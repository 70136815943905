import { useTranslation } from 'react-i18next';
import { StatusChip } from 'app/components/StatusChip';
import { Button, Chip, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import BampteeLogo from 'assets/img/Menu/MainBampteeLogo.svg';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { useCreatePaymentMutation } from 'common/services/payments';
import { themes } from 'styles/theme/themes';

export function FileTypeChip({ type }: { type: string }) {
  const { t } = useTranslation();
  const label = t(`customer.documentTypes.${type}`);

  return (
    <Tooltip title={t(`customer.documentTypes.${type}`)}>
      <Chip size="small" label={label.charAt(0).toUpperCase()} />
    </Tooltip>
  );
}
export function FilePaymentStatus({ file }) {
  const { t } = useTranslation();

  if (file?.metadata?.type !== 'invoice') {
    return null;
  }

  const totalAmount =
    file.metadata?.structured?.totalAmountTax || file.accountingAmount;

  let payment = file._payment;

  if (payment?.deleted || payment?.status === 'draft') {
    payment = null;
  }

  if (payment?.status === 'success') {
    return (
      <Tooltip
        placement="top"
        title={`Payée en ligne le ${window
          .dayjs(payment?.statusUpdatedAt || payment?.updatedAt)
          .format('D MMM. YYYY')}`}
      >
        <span>
          <StatusChip
            text={t('paid_by', { company: 'Bamptee' })} //TODO: Change to the company name
            status="success"
            color="black"
            background={themes.default.purpleLinearGradiant2}
            icon={
              <img width="8px" height="12px" src={BampteeLogo} alt="Bamptee" />
            }
          />
        </span>
      </Tooltip>
    );
  }

  if (file.accountingPaymentStatus === 'paid') {
    return <StatusChip status="success" text={t('invoice.status.paid')} />;
  }

  if (file.accountingPaymentStatus === 'rejected') {
    return <StatusChip status="error" text={t('invoice.status.rejected')} />;
  }

  if (file.accountingPaymentStatus === 'partially-paid') {
    return (
      <Tooltip
        placement="top"
        title={`Reste à payer: ${totalAmount - file.accountingPayedAmount} €`}
      >
        <span>
          <StatusChip status="blue" text={t('invoice.status.partially-paid')} />
        </span>
      </Tooltip>
    );
  }

  if (payment?.status === 'initiated') {
    return (
      <Tooltip
        placement="top"
        title={`Payée en ligne le ${window
          .dayjs(payment?.statusUpdatedAt || payment?.updatedAt)
          .format('D MMM YYYY')}`}
      >
        <span>
          <StatusChip text="En cours de traitement" status="warning" />
        </span>
      </Tooltip>
    );
  }

  return <StatusChip status="warning" text="A payer" />;
}

export default function DocumentRow({ row, paymentEnable, onOpenDocument }) {
  const { t } = useTranslation();

  const [createPayment, { isLoading: paymentIsLoading }] =
    useCreatePaymentMutation();

  const handlePay = async (document) => {
    const res = await createPayment({
      okUrl: `${window.location.origin}/ordoria/portal?payment=ok`,
      koUrl: `${window.location.origin}/ordoria/portal?payment=ko`,
      fileId: document._id,
    }).unwrap();

    if (res?.paymentUrl) {
      window.open(res.paymentUrl, '_blank');
    }
  };

  const document = row;
  let payment = document._payment;

  if (payment?.deleted || payment?.status === 'draft') {
    payment = null;
  }

  const totalAmount =
    document.metadata?.structured?.totalAmountTax || document.accountingAmount;

  const displayPaymentButton =
    paymentEnable &&
    document.metadata.type === 'invoice' &&
    document.accountingPaymentStatus !== 'paid' &&
    ((payment?.status !== 'success' && payment?.status !== 'initiated') ||
      !payment?.status);

  let toPay =
    totalAmount >= 0 && document.accountingPayedAmount >= 0
      ? totalAmount - document.accountingPayedAmount
      : totalAmount;

  if (payment?.status === 'success' || payment?.status === 'initiated') {
    toPay = totalAmount - payment.amount;
  }

  const dueDate = window.dayjs(row?.metadata?.dueDate || row.accountingDueDate);
  let isOverdue = false;

  if (document?.accountingPaymentStatus !== 'paid') {
    isOverdue = dueDate.isBefore(window.dayjs(), 'day');
  }

  return (
    <TableRow
      key={row.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <FileTypeChip type={row?.metadata?.type || ''} />
      </TableCell>
      <TableCell align="left">
        {row.viewAt ? (
          <Tooltip
            placement="top"
            title={t('customerPortal.documents.viewed', {
              date: window.dayjs(row.viewAt).format('D MMM YYYY'),
            })}
          >
            <a
              role="button"
              onClick={() => onOpenDocument(row)}
              style={{ cursor: 'pointer' }}
            >
              <Typography fontSize="0.9rem" fontWeight="400" color="primary">
                {row.name}
              </Typography>
            </a>
          </Tooltip>
        ) : (
          <a
            role="button"
            style={{ cursor: 'pointer' }}
            onClick={() => onOpenDocument(row)}
          >
            <Typography fontSize="0.9rem" fontWeight="500">
              {row.name}
            </Typography>
          </a>
        )}
      </TableCell>
      <TableCell align="left">
        {window.dayjs(row?.metadata?.date || row.createdAt).format('L')}
      </TableCell>
      <TableCell align="left" style={isOverdue ? { color: 'red' } : {}}>
        {isOverdue ? (
          <Typography fontSize="0.9rem" color="error">
            {dueDate.format('L')}
          </Typography>
        ) : (
          <Typography fontSize="0.9rem" color="default">
            {dueDate.format('L')}
          </Typography>
        )}
      </TableCell>
      <TableCell align="right">
        {row.metadata.type === 'invoice'
          ? new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            }).format(totalAmount)
          : 'N/A'}
      </TableCell>
      <TableCell align="center">
        <FilePaymentStatus file={row} />
      </TableCell>
      <TableCell align="right">
        {row.metadata.type === 'invoice'
          ? new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            }).format(toPay)
          : 'N/A'}
      </TableCell>
      <TableCell align="center">
        <>
          {displayPaymentButton && (
            <>
              {paymentIsLoading ? (
                <LoadingButton variant="contained" size="small">
                  {t('customerPortal.documents.payOnlineInProgress')}
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => handlePay(document)}
                  size="small"
                >
                  {t('customerPortal.documents.payOnline')}
                </Button>
              )}
            </>
          )}
        </>
      </TableCell>
    </TableRow>
  );
}
