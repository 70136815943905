import { Grid, MenuItem, TextField } from '@mui/material';
import { useCheckSiren } from 'hooks/Customers/useCheckSiren';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import { useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SupplierFormcontext } from '../context';
import { FieldComponent } from 'app/components/FieldComponent';
import { SupplierStatus } from 'common/types/Supplier';
import { StatusSupplier } from 'app/components/StatusSupplier';

export const MainDetails = ({ control, errors }) => {
  const { t } = useTranslation();
  const { supplier, canEdit, isSupplier } = useContext(SupplierFormcontext);
  const { formatError } = useErrorFormatter();
  const { validate } = useCheckSiren();

  const statuses = useMemo(() => {
    return Object.values(SupplierStatus).map((status) => {
      return {
        label: t(`supplier.status.${status}`),
        value: status,
      };
    });
  }, [t]);

  return (
    <Grid container spacing="12px">
      <Grid item sm={6} xs={12}>
        <Controller
          name="legalIdAlt"
          control={control}
          rules={{
            required: {
              value: true,
              message: `${t('Add_Client_SIREN.number')} ${t('is_required')}`,
            },
            validate(value) {
              return validate({
                siren: value?.toString() || '',
                id: supplier?._id,
              });
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              type="text"
              label={`${t('Add_Client_SIREN.number')}`}
              variant="outlined"
              error={!!errors?.legalIdAlt}
              helperText={formatError(
                errors?.legalIdAlt,
                t('Add_Client_SIREN.number'),
              )}
              fullWidth
              disabled={!canEdit || isSupplier}
              onChange={(value) => field.onChange(value)}
              inputProps={{
                placeholder: t('Add_Supplier_SIREN.number'),
              }}
              required
            />
          )}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <FieldComponent
          control={control}
          name="status"
          label={t('client.statusTitle')}
          select
          InputProps={{
            disabled: !canEdit || isSupplier,
            size: 'small',
          }}
        >
          {statuses?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <StatusSupplier status={option.value} />
            </MenuItem>
          ))}
        </FieldComponent>
      </Grid>
    </Grid>
  );
};
