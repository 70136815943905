import If from 'app/components/If';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { DownloadSimple, File } from '@phosphor-icons/react';
import { FormActions } from 'app/components/Form/styles';
import { Button, Grid, Typography } from '@mui/material';
import EllipsisText from 'app/components/EllipsisText';

export function AttachmentModal({ open, handleClose, files }) {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState<string>('');
  const handleDownloadFile = (fileUrl) => {
    const link = document.createElement('a');
    link.href = fileUrl.url;
    link.download = fileUrl.url.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadAll = () => {
    files.forEach((fileUrl, index) => {
      setTimeout(() => {
        const link = document.createElement('a');
        link.href = fileUrl.url;
        link.download = fileUrl.url.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, index * 100);
    });

    handleClose();
  };

  return (
    <TwoViewModal
      onClose={handleClose}
      onOpen={open}
      title={t('common.attachments')}
      width="748px"
    >
      <Wrapper>
        {files?.map((file, index) => (
          <Card
            key={index}
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered('')}
            onClick={() => handleDownloadFile(file)}
          >
            <If condition={index === hovered}>
              <DownloadContainer>
                <DownloadSimple
                  style={{
                    cursor: 'pointer',
                    color: themes?.default?.grey2,
                  }}
                />
              </DownloadContainer>
            </If>
            <FileIconContainer>
              <File size={100} color={themes?.default?.primary} />
              <Typography
                variant="body2"
                sx={{
                  marginTop: '8px',
                  textAlign: 'center',
                  color: themes?.default?.primary,
                  wordBreak: 'break-word',
                }}
              >
                <EllipsisText width={140} text={file.name} tooltip="" />
              </Typography>
            </FileIconContainer>
          </Card>
        ))}
      </Wrapper>
      <FormActions>
        <Button
          sx={{ color: themes.default.black60 }}
          onClick={handleDownloadAll}
        >
          <Grid container alignItems="center" gap="8px">
            <DownloadSimple
              style={{
                color: themes?.default?.clientMarkerBg,
              }}
            />
            <Typography color={themes?.default?.clientMarkerBg}>
              {t('common.download.all')}
            </Typography>
          </Grid>
        </Button>
      </FormActions>
    </TwoViewModal>
  );
}

const Wrapper = styled.div`
  padding: 20px 24px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const Card = styled.div`
  height: 180px;
  width: 160px;
  border-radius: 4px;
  border: 1px solid ${themes?.default?.grey400};
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const DownloadContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: ${themes?.default?.accordionWhiteBg};
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
`;

const FileIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 8px;
`;
