import React, { useState, createContext, useEffect, useMemo } from 'react';
import { LIST, GRID, CARD, MAP } from 'app/components/TableComponent/constants';
import { StateSetter } from 'types';
import GridContext from '../../../components/ManagedGrid/GridContext';
import { FunctionalCatalog } from './components/FunctionalCatalog';
import { getColumnConfig } from './config';
import { ListComponent } from './components/ListComponent';
import { CardComponent } from './components/CardComponent';
import { ManagedCreateOrderDrawer } from '../Orders/components/ManagedCreateOrderDrawer';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import { useTranslation } from 'react-i18next';
import { Product } from 'app/pages/Products/types';

interface ContextProps {
  selectedCategories: string[];
  setSelectedCategories: StateSetter<string[]>;
  selectedProducts: {
    grid: string[];
    categories?: any;
  };
  setSelectedProducts: StateSetter<{
    grid: string[];
    categories?: any;
  }>;
  viewMode: typeof GRID | typeof LIST | typeof CARD | typeof MAP | string;
  setViewMode: StateSetter<
    typeof GRID | typeof LIST | typeof CARD | typeof MAP | string
  >;
  setOpenCreateOrderDrawer: StateSetter<boolean>;
  setFormatedSelectedProducts: StateSetter<
    {
      _product: any;
      quantity: number;
      discount: number;
    }[]
  >;
  favoriteItems: string[] | null;
  setFavoriteItems: StateSetter<string[] | null>;
  isCustomer: boolean;
  drawerOpenForProduct: Product | null;
  setDrawerOpenForProduct: StateSetter<Product | null>;
}

export const CatalogContext = createContext<ContextProps>({
  selectedCategories: [],
  setSelectedCategories: () => {},
  selectedProducts: { grid: [] },
  setSelectedProducts: () => {},
  viewMode: GRID,
  setViewMode: () => {},
  setOpenCreateOrderDrawer: () => {},
  setFormatedSelectedProducts: () => {},
  favoriteItems: [],
  setFavoriteItems: () => {},
  isCustomer: false,
  drawerOpenForProduct: null,
  setDrawerOpenForProduct: () => {},
});

interface Props {}

export const Catalog: React.FC<Props> = () => {
  /* ------------------------------- Variables ------------------------------ */
  const { t } = useTranslation();

  /* ------------------------------ Use States ------------------------------ */
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<{
    grid: string[]; // for grid view
    categories?: any; // for list and card views
  }>({ grid: [] });
  const [formatedSelectedProducts, setFormatedSelectedProducts] = useState<
    {
      _product: any;
      quantity: number;
      discount: number;
    }[]
  >([]);
  const [viewMode, setViewMode] = useState<
    typeof GRID | typeof LIST | typeof CARD | typeof MAP | string
  >(GRID);
  const [openCreateOrderDrawer, setOpenCreateOrderDrawer] = useState(false);
  const [favoriteItems, setFavoriteItems] = useState<string[] | null>(null);
  const [drawerOpenForProduct, setDrawerOpenForProduct] =
    useState<Product | null>(null);

  const { isCustomer } = useGetUserAcl();

  /* ------------------------------- Use Memos ------------------------------- */
  const drawerSelectedProducts = useMemo(() => {
    return !!drawerOpenForProduct
      ? {
          _product: drawerOpenForProduct,
          quantity: 1,
          salesApprovedQuantity: 1,
          discount: 0,
        }
      : formatedSelectedProducts;
  }, [drawerOpenForProduct, formatedSelectedProducts]);

  /* ------------------------------ Use Effects ------------------------------ */
  useEffect(() => {
    if (viewMode === GRID) {
      const products = selectedProducts.grid.map((product) => ({
        _product: product,
        quantity: 1,
        salesApprovedQuantity: 1,
        discount: 0,
      }));
      setFormatedSelectedProducts(products);
    } else if (selectedProducts?.categories) {
      const allCategoryIds: any[] = Object.values(
        selectedProducts.categories,
      ).flat();
      const products = allCategoryIds.map((product) => ({
        _product: product,
        quantity: 1,
        salesApprovedQuantity: 1,
        discount: 0,
      }));
      setFormatedSelectedProducts(products);
    }
  }, [selectedProducts, viewMode]);

  /* ------------------------------------------------------------------------- */
  return (
    <CatalogContext.Provider
      value={{
        selectedCategories,
        setSelectedCategories,
        selectedProducts,
        setSelectedProducts,
        viewMode,
        setViewMode,
        setOpenCreateOrderDrawer,
        setFormatedSelectedProducts,
        favoriteItems,
        setFavoriteItems,
        isCustomer: isCustomer(),
        drawerOpenForProduct,
        setDrawerOpenForProduct,
      }}
    >
      <GridContext
        data={[]}
        customColumnConfig={getColumnConfig(t)}
        title="modules.catalog"
        rowHeight={52}
        canEdit={true}
        listComponent={ListComponent}
        cardComponent={CardComponent}
      >
        <FunctionalCatalog />
        <ManagedCreateOrderDrawer
          open={openCreateOrderDrawer}
          onClose={() => setOpenCreateOrderDrawer(false)}
          selectedProducts={drawerSelectedProducts}
          hideTemplates={true}
        />
      </GridContext>
    </CatalogContext.Provider>
  );
};
