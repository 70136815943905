import { IColDef } from 'app/components/ManagedGrid/types';

import {
  ProductCell,
  MainCell,
  EllipsisCell,
} from 'app/components/ManagedGrid/components/Cells';
import { CustomHeaderOptions } from 'app/components/ManagedGrid/components/CustomHeaderOptions';
import { NotesCell } from '../../../ContractsTab/components/Cells/NotesCell';
import { formatNumber } from 'utils/helpers';
import { discountIndices } from 'app/components/Restrictions/types/discounts.types';
import { DiscountName } from 'app/components/Restrictions/types/discounts.types';
import { DiscountInputCell } from 'app/components/Restrictions/Components/DiscountInputCell';
import { LockHeaderOption } from 'app/components/Restrictions/Components/HeaderOption/LockHeaderOption';
import { MaxHeaderOption } from 'app/components/Restrictions/Components/HeaderOption/MaxHeaderOption';
import { StatusCell } from '../../../ContractsTab/components/OffersGrid/Cells/StatusCell';
import { ActionsCell, ApproveCell } from './Cells';
import { themes } from 'styles/theme/themes';
import { HeaderComponent } from '../../config';

export const getColumnConfig = (
  t: (key: string) => string,
  isAdmin: boolean,
  maxLevel: number,
  minMargin?: number,
  onClick?: () => void,
): IColDef[] => [
  {
    cellRenderer: ProductCell,
    minWidth: 290,
    resizable: true,
    flex: 1,
    lockVisible: true,
    sortField: 'cache._product.name',
    headerName: t('common.name'),
  },
  {
    cellRenderer: EllipsisCell,
    minWidth: 180,
    resizable: true,
    flex: 1,
    sortField: '_product._category._parentCategory.name',
    headerName: t('category'),
  },
  {
    cellRenderer: EllipsisCell,
    minWidth: 180,
    resizable: true,
    flex: 1,
    sortField: '_product._category._parentCategory.name',
    headerName: t('subcategory'),
    hide: maxLevel < 1,
  },
  {
    cellRenderer: EllipsisCell,
    minWidth: 180,
    resizable: true,
    flex: 1,
    sortField: '_product._category.name',
    headerName: t('childcategory'),
    hide: maxLevel < 2,
  },
  {
    cellRenderer: MainCell,
    minWidth: 60,
    flex: 1,
    sortField: 'sellingPrice',
    headerName: `${'pricingGroups.selling_price'}`,
  },

  ...discountIndices.map<IColDef>((i) => {
    const ri: DiscountName = `r${i}`;
    return {
      cellRenderer: DiscountInputCell,
      flex: 1,
      sortField: ri,
      valueFormatter: (params) => params.value?.cellData,
      headerName: t('discount_abbrev') + i,
      discountIndex: i,
      CustomHeaderComponent: (
        <HeaderComponent discountIndex={i} applyCustomStyles={false} />
      ),
      customHeaderOptions: [LockHeaderOption, MaxHeaderOption],
    };
  }),

  {
    cellRenderer: MainCell,
    minWidth: 100,
    resizable: true,
    flex: 1,
    sortField: 'discountedPrice',
    headerName: `${t('net_ht')}`,
  },
  {
    cellRenderer: MainCell,
    minWidth: 180,
    resizable: true,
    flex: 1,
    sortField: 'profit',
    headerName: `${t('profit_value')}`,
  },
  {
    cellRenderer: MainCell,
    minWidth: 70,
    resizable: true,
    flex: 1,
    sortField: 'margin',
    headerName: `${t('margin')} % ${
      minMargin ? `(min ${formatNumber(minMargin * 100)}%)` : ''
    }`,
    cellStyle: (params) => {
      if (minMargin && params.value.margin > minMargin) {
        return {
          paddingTop: '16px',
          color: themes?.default?.errorDark,
          backgroundColor: themes?.default?.chipRedFont,
        };
      }
      return {
        paddingTop: '16px',
        color: '',
        backgroundColor: 'transparent',
      };
    },
    customHeaderOptions: !isAdmin ? undefined : [CustomHeaderOptions],
    customHeaderProps: [
      {
        title: 'colDef.edit_marign',
        onClick: onClick,
        disabled: !isAdmin,
      },
    ],
  },
  {
    cellRenderer: StatusCell,
    minWidth: 110,
    sortField: 'status',
    headerName: t('common.status'),
    pinned: 'right',
  },
  {
    cellRenderer: ApproveCell,
    minWidth: 30,
    headerName: t('approve'),
    pinned: 'right',
    hide: !isAdmin,
  },
  {
    cellRenderer: NotesCell,
    minWidth: 50,
    flex: 1,
    sortField: 'notes',
    headerName: t('common.notes'),
  },
  {
    cellRenderer: ActionsCell,
    width: 50,
    resizable: false,
    lockPinned: true,
    pinned: 'right',
    lockPosition: 'right',
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
];
