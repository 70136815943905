import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColumnResizedEvent,
  GetRowIdParams,
  ModuleRegistry,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { IGridContext } from './types';
import { useManagedContext } from '../../../common/UtilityComponents/ManagedContext/useManagedContext';
import './index.css';
import HeaderComponent from './components/HeaderComponent';
import { useTheme } from 'common/hooks/useTheme';
import { debounce } from 'lodash';
import { getColumnOrderPriority } from './helpers';
import { GRID } from '../TableComponent/constants';
import styled from 'styled-components';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

function DataGrid<T, U>({ customGridElement, rowClassRules }) {
  const theme = useTheme();
  const gridWrapperRef = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<AgGridReact<U[]>>(null);
  const gridContext = useManagedContext<IGridContext<T, U>>('grid');

  useEffect(() => {
    if (gridContext.extraData.getRowHeight) {
      setGroupHeight(
        gridContext.extraData.newExpanded,
        gridContext.extraData.getRowHeight(),
      );
    }
  }, [gridContext.extraData.newExpanded, gridContext.extraData.getRowHeight]);

  const setGroupHeight = (height: number, catId) => {
    gridRef.current!.api.forEachNode(function (rowNode: any) {
      if (catId === rowNode?.data?.name?.cellData.record._id) {
        rowNode.setRowHeight(height);
      }
    });
    gridRef.current!.api.onRowHeightChanged();
    gridRef.current!.api!.resetRowHeights();
  };

  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      suppressHeaderFilterButton: true,
      width: 150,
      flex: 1,
      cellStyle: {
        border: 'none',
        // borderRight: '1px solid #000',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontFamily: 'Roboto, sans-serif',
      },
    };
  }, []);

  const showCheckbox = useMemo(() => {
    return (
      (gridContext.canEdit || gridContext.canExport) &&
      !gridContext.suppressCheckbox
    );
  }, []);
  const rowSelection = useMemo(() => {
    if (!showCheckbox) {
      return undefined;
    }
    return {
      mode: 'multiRow' as 'multiRow',
      headerCheckbox: true,
    };
  }, [showCheckbox]);

  const moveColumnByField = (srcField, destField) => {
    const cols: any = gridRef
      .current!.api.getAllDisplayedColumns()
      .slice(!!showCheckbox ? 1 : 0);
    const srcIndex = cols.findIndex(
      (column) => column?.colDef.field === srcField,
    );

    const destIndex = cols.findIndex(
      (column) => column?.colDef.field === destField,
    );

    gridRef.current!.api.moveColumnByIndex(srcIndex!, destIndex!);

    gridContext.updateDataWithFunction((prev) => {
      prev.columnDefs = gridRef
        .current!.api.getAllDisplayedColumns()
        .slice(!!showCheckbox ? 1 : 0)
        .map((col: any) => {
          return col.colDef;
        });
    });
  };

  const handlePinColumn = useCallback(
    (field: string, pinned: 'left' | 'right' | boolean | null | undefined) => {
      if (!gridRef.current) return;

      gridRef.current!.api.setColumnsPinned([field], pinned);

      gridContext.updateDataWithFunction((prev) => {
        prev.columnDefs = gridRef.current!.api.getColumnDefs()!.sort((a, b) => {
          // Define priority for column positioning

          const priorityA = getColumnOrderPriority(a);
          const priorityB = getColumnOrderPriority(b);

          if (priorityA !== priorityB) return priorityA - priorityB;

          // If priorities are equal, maintain original order
          return 0;
        });
      });
    },
    [],
  );

  useEffect(() => {
    if (gridContext.viewMode !== GRID) return;
    gridContext.updateDataWithFunction((prev) => {
      prev.api = {
        ...prev.api,
        moveColumnByField,
        setColumnsVisible: (fields: string[], visible: boolean) => {
          const filteredFields = fields.filter((field) => {
            const column = gridRef.current!.api.getColumnDef(field);
            return !column?.lockVisible;
          });
          gridRef.current!.api.setColumnsVisible(filteredFields, visible);
          gridContext.updateDataWithFunction((prev) => {
            prev.columnDefs = gridRef.current!.api.getColumnDefs()!;
          });
        },
        disableToolBar: (extraCondition) => {
          return (
            gridContext.totalRows === 0 &&
            !gridContext.queryParams.search &&
            gridContext.filtersCount === 0 &&
            extraCondition
          );
        },
        clearSelection: () => {
          gridRef.current!.api.deselectAll();
        },
        getCurrentColumns: () => {
          if (!gridRef.current) return gridContext.columnDefs;

          return (
            gridRef
              .current!.api.getAllGridColumns()
              // @ts-ignore
              .map((col) => col.colDef)
              .slice(!!showCheckbox ? 1 : 0)
          );
        },
        pinColumn: (
          field: string,
          pinned: 'left' | 'right' | boolean | null | undefined,
        ) => {
          handlePinColumn(field, pinned);
        },
      };
    });
  }, [showCheckbox, handlePinColumn]);

  const getRowId = useCallback((params: GetRowIdParams) => {
    return String(params.data.id);
  }, []);
  const components = useMemo<{
    [p: string]: any;
  }>(() => {
    return {
      agColumnHeader: HeaderComponent,
    };
  }, []);

  const themeColors = useMemo(() => {
    return {
      'active-color': theme.primaryActiveColor,
    };
  }, [theme.primaryActiveColor]);

  useEffect(() => {
    const gridElement = gridWrapperRef.current;
    if (!gridElement) return;

    const colorEntries = Object.entries(themeColors);

    colorEntries.forEach(([key, value]) => {
      gridElement.style.setProperty(`--ag-${key}`, value);
    });

    return () => {
      colorEntries.forEach(([key]) => {
        gridElement.style.removeProperty(`--ag-${key}`);
      });
    };
  }, [themeColors]);

  const queryElements = () => ({
    centerViewport: document.querySelector<HTMLElement>(
      '.ag-center-cols-viewport',
    ),
    centerContainer: document.querySelector<HTMLElement>(
      '.ag-center-cols-container',
    ),
    headerViewport: document.querySelector<HTMLElement>('.ag-header-viewport'),
    headerContainer: document.querySelector<HTMLElement>(
      '.ag-header-container',
    ),
    horizontalScrollViewport: document.querySelector<HTMLElement>(
      '.ag-body-horizontal-scroll-viewport',
    ),
    horizontalScrollContainer: document.querySelector<HTMLElement>(
      '.ag-body-horizontal-scroll-container',
    ),
    pinnedLeftContainer: document.querySelector<HTMLElement>(
      '.ag-pinned-left-cols-container',
    ),
    pinnedRightContainer: document.querySelector<HTMLElement>(
      '.ag-pinned-right-cols-container',
    ),
    bodyContainer: document.querySelector<HTMLElement>('.ag-body-viewport'),
  });

  const calculateActualWidth = (gridApi: any, containers: any) => {
    const {
      centerContainer,
      pinnedLeftContainer,
      pinnedRightContainer,
      bodyContainer,
    } = containers;
    if (!centerContainer) return 0;

    const pinnedLeftWidth = pinnedLeftContainer?.offsetWidth || 0;
    const pinnedRightWidth = pinnedRightContainer?.offsetWidth || 0;

    // Calculate the total width of all columns
    const totalColumnsWidth = gridApi
      .getAllDisplayedColumns()
      ?.reduce((total: number, col: any) => total + col.getActualWidth(), 0);

    // Get the visible width of the grid (viewport width)
    const gridViewportWidth = bodyContainer?.offsetWidth || 0;

    // Calculate the width needed for scrolling
    const scrollNeededWidth = Math.max(
      totalColumnsWidth,
      centerContainer.scrollWidth + pinnedLeftWidth + pinnedRightWidth,
    );

    return Math.max(scrollNeededWidth, gridViewportWidth);
  };

  const syncScrolls = (containers: any) => {
    const { centerViewport, headerViewport } = containers;

    if (centerViewport && headerViewport) {
      headerViewport.scrollLeft = centerViewport.scrollLeft;
    }
  };

  const adjustScrollWidth = (gridApi: any) => {
    const containers = queryElements();

    const {
      centerViewport,
      centerContainer,
      headerViewport,
      headerContainer,
      horizontalScrollViewport,
      horizontalScrollContainer,
    } = containers;

    if (!centerContainer || !horizontalScrollContainer) return;

    const actualWidth = calculateActualWidth(gridApi, containers);

    // Apply calculated width
    horizontalScrollContainer.style.width = `${actualWidth}px`;
    horizontalScrollContainer.style.minWidth = `${actualWidth}px`;
    //@ts-ignore
    horizontalScrollViewport.style.width = '100%';

    // Synchronize header and body widths
    if (centerViewport && headerViewport && headerContainer) {
      centerViewport.style.width = '100%';
      headerViewport.style.width = '100%';

      headerContainer.style.width = `${centerContainer.scrollWidth}px`;
      headerContainer.style.minWidth = `${centerContainer.scrollWidth}px`;

      // Ensure center viewport accounts for pinned columns
      const centerWidth =
        (containers.bodyContainer?.offsetWidth || 0) -
        (containers.pinnedLeftContainer?.offsetWidth || 0) -
        (containers.pinnedRightContainer?.offsetWidth || 0);
      centerViewport.style.maxWidth = `${centerWidth}px`;
      headerViewport.style.maxWidth = `${centerWidth}px`;
    }

    // Add scroll synchronization
    centerViewport?.removeEventListener('scroll', () =>
      syncScrolls(containers),
    );
    centerViewport?.addEventListener('scroll', () => syncScrolls(containers));
  };

  const onGridReady = (params: any) => {
    const gridApi = params.api;

    const handleResize = () => {
      adjustScrollWidth(gridApi);

      // Additional adjustment after resize completes
      setTimeout(() => {
        adjustScrollWidth(gridApi);
      }, 50);
    };

    // Initial adjustments
    setTimeout(() => adjustScrollWidth(gridApi), 0);
    setTimeout(() => adjustScrollWidth(gridApi), 100);
    setTimeout(() => adjustScrollWidth(gridApi), 300);

    // Event listeners
    const debouncedAdjustScrollWidth = debounce(
      () => adjustScrollWidth(gridApi),
      200,
    );
    gridApi.addEventListener('columnResized', handleResize);
    gridApi.addEventListener('columnDragged', debouncedAdjustScrollWidth);
    gridApi.addEventListener('columnVisible', () => {
      console.log('columnVisiblecolumnVisible');
      debouncedAdjustScrollWidth();
    });
    gridApi.addEventListener('columnPinned', debouncedAdjustScrollWidth);
    window.addEventListener('resize', debouncedAdjustScrollWidth);

    return () => {
      const { centerViewport } = queryElements();
      centerViewport?.removeEventListener('scroll', () =>
        syncScrolls(queryElements()),
      );
      window.removeEventListener('resize', debouncedAdjustScrollWidth);
    };
  };

  const onColumnResized = (e: ColumnResizedEvent) => {
    const resizedColumn = e.column;
    if (!resizedColumn) return;

    const colId = resizedColumn.getColId();
    const isPinnedRight = resizedColumn.getPinned() === 'right';
    const isPinnedLeft = resizedColumn.getPinned() === 'left';

    const headerElement = document.querySelector<HTMLElement>(
      `.ag-header-cell[col-id="${colId}"]`,
    );
    const columnElements = document.querySelectorAll<HTMLElement>(
      `.ag-cell[col-id="${colId}"]`,
    );
    const centerViewport = document.querySelector<HTMLElement>(
      '.ag-center-cols-viewport',
    );
    const headerViewport = document.querySelector<HTMLElement>(
      '.ag-header-viewport',
    );
    const centerContainer = document.querySelector<HTMLElement>(
      '.ag-center-cols-container',
    );

    // During resize, maintain right pinned columns position
    if (!e.finished && isPinnedLeft) {
      const rightContainer = document.querySelector<HTMLElement>(
        '.ag-pinned-right-cols-container',
      );
      const rightHeader = document.querySelector<HTMLElement>(
        '.ag-pinned-right-header',
      );

      if (rightContainer && rightHeader) {
        rightContainer.style.position = 'absolute';
        rightContainer.style.right = '0';
        rightHeader.style.position = 'absolute';
        rightHeader.style.right = '0';
      }
    }

    if (e.finished) {
      // Reset right pinned columns position
      const rightContainer = document.querySelector<HTMLElement>(
        '.ag-pinned-right-cols-container',
      );
      const rightHeader = document.querySelector<HTMLElement>(
        '.ag-pinned-right-header',
      );

      if (rightContainer && rightHeader) {
        rightContainer.style.position = '';
        rightContainer.style.right = '';
        rightHeader.style.position = '';
        rightHeader.style.right = '';
      }

      // Remove resize classes
      headerElement?.classList.remove(
        'ag-resize-active',
        'ag-resize-pinned-right',
      );
      columnElements?.forEach((el) => {
        el.classList.remove('ag-resize-active', 'ag-resize-pinned-right');
      });

      // Ensure final alignment after resize
      if (centerViewport && headerViewport && centerContainer) {
        requestAnimationFrame(() => {
          // Calculate maximum valid scroll position
          const maxScroll =
            centerContainer.scrollWidth - centerViewport.offsetWidth;
          const currentScroll = centerViewport.scrollLeft;

          // Ensure we don't exceed the maximum scroll position
          const finalScroll = Math.min(currentScroll, maxScroll);

          // Synchronize both viewports
          centerViewport.scrollLeft = finalScroll;
          headerViewport.scrollLeft = finalScroll;
        });
      }
    } else {
      // During resize
      //eslint-disable-next-line
      if (isPinnedRight) {
        headerElement?.classList.add(
          'ag-resize-active',
          'ag-resize-pinned-right',
        );
        columnElements?.forEach((el) => {
          el.classList.add('ag-resize-active', 'ag-resize-pinned-right');
        });
      } else {
        headerElement?.classList.add('ag-resize-active');
        columnElements?.forEach((el) => {
          el.classList.add('ag-resize-active');
        });
      }
    }
  };

  const onGridSizeChanged = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  return (
    <>
      <GridContainer
        ref={gridWrapperRef}
        className={`${
          gridContext.isFullWidth ? 'customFullWidth' : ''
        } ag-theme-quartz${
          gridContext.theme === 'dark' ? '-' + gridContext.theme : ''
        }`}
      >
        <AgGridReact
          onRowDragEnd={gridContext?.onRowDragEnd}
          onGridReady={onGridReady}
          rowData={gridContext.rowData}
          ref={gridRef}
          columnDefs={gridContext.columnDefs}
          domLayout={gridContext.defaultLayoutHeight ? 'autoHeight' : 'normal'}
          loading={gridContext.loading && !gridContext.supressLoadingState}
          getRowId={getRowId}
          suppressNoRowsOverlay={true}
          alwaysShowHorizontalScroll={false}
          components={components}
          alwaysShowVerticalScroll={false}
          defaultColDef={defaultColDef}
          onColumnMoved={(e) => {
            if (!e.finished) {
              return;
            }

            const movedColumn = e.column;
            const colDef = movedColumn?.getColDef();
            if (!colDef || !movedColumn) return;
            const adjustedIndex =
              e.toIndex !== undefined
                ? showCheckbox
                  ? e.toIndex - 1
                  : e.toIndex
                : 0;
            if (adjustedIndex === 0) {
              colDef.pinned = 'left';
            } else if (adjustedIndex === 1) {
              colDef.pinned = 'right';
            } else {
              colDef.pinned = undefined;
            }
            gridContext.updateDataWithFunction((prev) => {
              prev.columnDefs = gridRef.current!.api.getColumnDefs()!;
            });
          }}
          onColumnPinned={(e) => {
            const pinnedColumn = e.column;
            if (!pinnedColumn) return;
            const colDef = pinnedColumn.getColDef();
            handlePinColumn(colDef.field!, pinnedColumn.getPinned());
          }}
          rowDragManaged={true}
          onColumnResized={onColumnResized}
          rowSelection={rowSelection}
          selectionColumnDef={{
            pinned: 'left',
            lockPinned: true,
            width: 50,
          }}
          onSortChanged={(e: any) => {
            gridContext.updateDataWithFunction((prev) => {
              if (!e.columns?.[0]) return;
              prev.queryParams.order =
                e.columns[e.columns.length - 1].sort === 'asc' ? '-' : '';
              prev.queryParams.orderBy =
                e.columns[
                  e.columns.length - 1
                ].getUserProvidedColDef()?.sortField;
            });
          }}
          onSelectionChanged={(e) => {
            gridContext.updateData(
              'selectedRows',
              e.api.getSelectedRows().map((r) => r.id),
            );
          }}
          rowHeight={gridContext.rowHeight}
          //getRowHeight={getRowHeight}
          // onDragStopped={() => {
          //   const newColumnDefs = gridRef.current!.api.getColumnDefs()!;
          //   gridContext.updateDataWithFunction((prev) => {
          //     prev.columnDefs = newColumnDefs;
          //   });
          // }}
          rowClassRules={rowClassRules}
          getRowStyle={gridContext.extraData.getRowStyle}
          onGridSizeChanged={onGridSizeChanged}
        />
      </GridContainer>
      {customGridElement && customGridElement}
    </>
  );
}
export default DataGrid;

export const GridContainer = styled.div`
  .ag-center-cols-viewport {
    min-height: unset !important;
  }
  height: 100%;
  &.customFullWidth {
    .ag-cell {
      padding: 0px !important;
    }
    .ag-cell-wrapper {
      width: 100%;
      padding: 0px !important;
    }

    .ag-header-cell:hover {
      background: ${(props) => props.theme.baseWhite} !important;
    }
    .ag-drag-handle.ag-row-drag {
      padding: 0px !important;
      position: absolute;
      top: -5px;
      z-index: 1;
      padding-left: 17px !important;
    }
    .ag-row {
      padding: 0px !important;
    }
  }
`;
