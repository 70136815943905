import ManagedGrid from 'app/components/ManagedGrid';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import {
  IGridContext,
  INoDataConfig,
  IToolbarConfig,
} from 'app/components/ManagedGrid/types';
import NoItemsImage from 'assets/img/customers/Contracts/NoItemsImage.svg';
import { useHelpers } from '../../useHelpers';
import styled from 'styled-components';
import { Contract, UpdateCategoryDiscounts } from '../../types';
import { RowClassRules } from '@ag-grid-community/core';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { RestrictMaxModal } from 'app/components/Restrictions/RestrictMaxModal';
import { AddContractsModal } from '../AddContractsModal';
import { createPortal } from 'react-dom';
import { ManagedGridActions } from 'app/components/ManagedGrid/components/ManagedGridActions';
import { ContractsFilter } from '../ContractsFilter';
import { useGetAllCustomerCategoryDiscountsQuery } from 'common/services/customerCategoryDiscountApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { isNumber } from 'lodash';
import { useParams } from 'react-router-dom';
import { ContractsBulkActions } from '../ContractsBulkActions';
import { StateSetter } from 'types';
import { useNavLock } from 'hooks/useNavLock';
import { generateParamsString } from 'app/pages/Ordoria/cart/components/DeleteBtn';
import { HeaderTextComponent } from '../../config';
import { useRestrictions } from '../hooks/useRestrictions';
import { usePermission, Permission } from 'hooks/Abilities/usePermission';
import {
  DiscountIndex,
  DiscountName,
} from 'app/components/Restrictions/types/discounts.types';

interface Props {
  selectedTab: Contract;
  rowsEditedBySales: UpdateCategoryDiscounts[];
  setRowsEditedBySales: StateSetter<UpdateCategoryDiscounts[]>;
}

export const ContractsGrid = ({
  selectedTab,
  rowsEditedBySales,
  setRowsEditedBySales,
}: Props) => {
  /* ------------------------------------- Contexts ------------------------------------ */
  const gridContext = useManagedContext<IGridContext>('grid');

  const { initiateRestrictions } = useRestrictions();

  /* ------------------------------------ Variables ------------------------------------ */
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const gridActionsRef = useRef<HTMLElement | null>(null);
  const can = usePermission('customers');

  const {
    queryParams: { search, filters },
  } = gridContext;

  /* ------------------------------------ Use States ----------------------------------- */
  const [lastEditedByString, setLastEditedByString] = useState('');
  const [statusFilterString, setStatusFilterString] = useState('');

  /* -------------------------------------- Hooks -------------------------------------- */
  const { id: customerId } = useParams();
  const { getProcessedItemsFunction, isAdmin } = useHelpers();

  const categoriesFetchQueryParams = useQueryParams({
    _customer: customerId,
    level: selectedTab.level,
    search: search,
    hasNotes: !!filters?.hasNotes,
  });
  const { navLocked } = useNavLock();

  /* ------------------------------------ Api Calls ------------------------------------ */
  const {
    data: ContractsData,
    isLoading,
    isFetching,
  } = useGetAllCustomerCategoryDiscountsQuery(
    categoriesFetchQueryParams + lastEditedByString + statusFilterString,
    {
      skip: !isNumber(selectedTab?.level),
      refetchOnMountOrArgChange: true,
    },
  );

  /* ---------------------------------- Use Callbacks ---------------------------------- */
  const handleToggleAddItemsModal = useCallback(
    () => {
      gridContext.updateDataWithFunction((prev) => {
        prev.openDrawer = !prev.openDrawer;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /* ------------------------------------ Use Memos ------------------------------------ */
  const gridData = useMemo(() => {
    if (ContractsData?.docs?.length) {
      return ContractsData.docs;
    } else {
      return [];
    }
  }, [ContractsData?.docs]);

  const toolbarConfig = useMemo<IToolbarConfig>(() => {
    return {
      disabled: gridContext.disableToolBar,
      onAddClick: () => handleToggleAddItemsModal(),
      FilterComponent: ContractsFilter,
      BulkActionsComponent: ContractsBulkActions,
    };
  }, [gridContext.disableToolBar, handleToggleAddItemsModal]);

  const noDataConfig = useMemo<INoDataConfig>(() => {
    return {
      icon: NoItemsImage,
      onClick: () => handleToggleAddItemsModal(),
      text: t('contracts.no_data.title', {
        type: t(`contracts.${selectedTab.key}`),
      }),
      textStyle: {
        fontSize: '1rem',
        fontWeight: '500',
        lineHeight: '26px',
        letterSpacing: '0.15px',
        textAlign: 'center',
        color: theme.black2,
        margin: '0px',
        padding: '0px',
      },
      description: t('contracts.empty_state.description', {
        type: t(
          isNumber(selectedTab.level)
            ? `discount_level_${selectedTab.level}_s`
            : `contracts.${selectedTab.key}`,
        ).toLowerCase(),
      }),
      textButton: t('contracts.add', {
        type: t(`contracts.${selectedTab.key}`),
      }),
      buttonStyle: { width: 'fit-content' },
      containerStyle: { marginTop: '-16px' },
      emptyCenterStyle: { top: '50%' },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleToggleAddItemsModal, selectedTab.key]);

  const rowClassRules: RowClassRules = useMemo(
    () => ({
      'disabled-row': (params) => {
        return params.data.__actions.disabled;
      },
    }),
    [],
  );

  /* ---------------------------------- Use Callbacks ---------------------------------- */
  const checkMaxExceeded = useCallback(
    (max: number, i: DiscountIndex) => {
      const ri: DiscountName = `r${i}`;
      return gridData.some((row) => (row?.discounts?.[ri] || 0) > max / 100);
    },
    [gridData],
  );

  const handleClearAllFilters = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters = {
        status: [],
        lastEditedBy: [],
        hasNotes: false,
      };
    });
  };

  /* ----------------------------------- Use Effects ----------------------------------- */
  useEffect(() => {
    gridContext.updateDataWithFunction((prev) => {
      prev.responseData = gridData;
      prev.loading = isLoading || isFetching;
      prev.disableToolBar = navLocked;
      prev.rowData = getProcessedItemsFunction({
        data: gridData,
        rowsEditedBySales,
        setRowsEditedBySales,
      });
      prev.totalRows = gridData.length;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData, isLoading, isFetching, rowsEditedBySales, navLocked]);

  useEffect(() => {
    gridContext.updateDataWithFunction((prev) => {
      if (!prev.columnDefs?.length) return;

      prev.columnDefs[prev.columnDefs.length - 1].hide = !can(Permission.EDIT);

      if (!isAdmin()) {
        prev.columnDefs = prev.columnDefs.filter(
          (col) => col.colId !== 'approve',
        );
      }

      if (selectedTab?.level && selectedTab.level > 0) {
        prev.columnDefs = prev.columnDefs.map((col) => {
          if (col.colId === 'subcategory-name') {
            return {
              ...col,
              hide: false,
              headerName: `discount_level_${selectedTab.level}`,
              CustomHeaderComponent: (
                <HeaderTextComponent
                  title={t(`discount_level_${selectedTab.level}`)}
                />
              ),
            };
          }
          return col;
        });
      } else {
        prev.columnDefs = prev.columnDefs.map((col) => {
          if (col.colId === 'subcategory-name') {
            return { ...col, hide: true };
          }
          return col;
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridContext.columnDefs.length, isAdmin, selectedTab.level, t]);

  useEffect(() => {
    gridContext.updateDataWithFunction((prev) => {
      prev.extraData = {
        ...prev.extraData,
        rowsEditedBySales: rowsEditedBySales,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsEditedBySales]);

  useEffect(() => {
    handleClearAllFilters();
    initiateRestrictions(selectedTab.key);
  }, [selectedTab.key]);

  useEffect(() => {
    gridActionsRef.current = document.getElementById('contracts-grid-actions');
  }, []);

  useEffect(() => {
    const ids = filters?.lastEditedBy?.map((filter) => {
      return filter.value;
    });

    const result = generateParamsString(ids || [], 'lastEditedBy');

    setLastEditedByString(result ? `&${result}` : '');
  }, [filters?.lastEditedBy]);

  useEffect(() => {
    const statuses = filters?.status?.map((filter) => {
      return filter.value;
    });

    const result = generateParamsString(statuses || [], 'status');

    setStatusFilterString(result ? `&${result}` : '');
  }, [filters?.status]);

  /* ----------------------------------------------------------------------------------- */
  return (
    <Wrapper>
      <ManagedGrid
        toolbarConfig={toolbarConfig}
        noDataConfig={noDataConfig}
        rowClassRules={rowClassRules}
      />

      <AddContractsModal
        open={gridContext.openDrawer}
        onClose={handleToggleAddItemsModal}
        selectedTab={selectedTab}
      />

      <RestrictMaxModal
        title="discounts.action_limit_max"
        hasLimit={true}
        checkMaxExceeded={checkMaxExceeded}
      />

      {gridActionsRef?.current &&
        createPortal(
          <ManagedGridActions {...(toolbarConfig || {})} />,
          gridActionsRef.current,
        )}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  flex: 1;
  .MuiPaper-root {
    background: transparent !important;
  }

  .noPaddingHeadCell .MuiStack-root {
    padding: 0px !important;
  }

  .disabled-row {
    background: ${themes.default.lightGrey};
    color: ${themes.default.clientMarkerBg} !important;
  }
`;
