import { themes } from 'styles/theme/themes';

export const PRODUCT_STATUSES = [
  {
    label: 'pending_approval',
    key: 'pending_approval',
    background: themes.default.bgPending,
    color: themes?.default?.textPending,
  },
  {
    label: 'approved',
    key: 'approved',
    background: themes?.default?.success,
    color: themes.default.greenMain,
  },
];
