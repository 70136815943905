import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { StatusChip } from 'app/components/StatusChip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import useFormatCurrency from 'common/hooks/useFormatCurrency';

interface TablePaymentsProps {
  payments: any[];
  onSwitchStatus: Function;
  status: string;
  isLoading: boolean;
}

export function NeutralLink({ children, href }) {
  return (
    <span
      onClick={() => window.open(href, '_blank')}
      style={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}
    >
      {children}
    </span>
  );
}

export function Suggestion({ suggestion }) {
  if (suggestion.type === 'customer') {
    return (
      <Tooltip title={`Score: ${suggestion.score}`}>
        <StatusChip
          text={
            <NeutralLink
              href={`${window.location.origin}/customers/${suggestion.customerId}/info`}
            >
              {suggestion.customerCode} {suggestion.customerName}
            </NeutralLink>
          }
          status="warning"
          style={{ marginRight: 5, marginBottom: 5 }}
        />
      </Tooltip>
    );
  }

  let color = 'initiated';

  if (suggestion.score === 1) {
    color = 'success';
  } else if (suggestion.score >= 0.5) {
    color = 'blue';
  }

  return (
    <Tooltip placement="top" arrow title={`Score: ${suggestion.score}`}>
      <StatusChip
        text={
          <>
            <NeutralLink
              href={`${window.location.origin}/customers/${suggestion.customerId}/info`}
            >
              {suggestion.customerCode}
            </NeutralLink>{' '}
            -{' '}
            <NeutralLink href={`${suggestion.invoiceUrl}`}>
              {suggestion.invoiceCode}
            </NeutralLink>
          </>
        }
        status={color}
        style={{ marginRight: 5, marginBottom: 5 }}
      />
    </Tooltip>
  );
}

export function TablePayments({
  isLoading,
  payments,
  status,
  onSwitchStatus,
}: TablePaymentsProps) {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const formatCurrency = useFormatCurrency();

  if (status === 'imported' || status === 'imported-processed') {
    return (
      <Grid
        sx={{
          m: '10px 20px',
        }}
      >
        <TableContainer
          sx={{
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <Table
            size="small"
            sx={{
              backgroundColor: theme.baseWhite,
            }}
          >
            <TableHead
              sx={{
                backgroundColor: theme.cultured,
                height: '10px',
              }}
            >
              <TableRow>
                <TableCell align="left">
                  {t('payments.transactionId').toUpperCase()}
                </TableCell>
                <TableCell align="left" sx={{ minWidth: '50px' }}>
                  {t('common.date').toUpperCase()}
                </TableCell>
                <TableCell align="left" sx={{ minWidth: '400px' }}>
                  {t('payments.description').toUpperCase()}
                </TableCell>
                <TableCell sx={{ minWidth: '200px' }}>
                  {t('payments.amount').toUpperCase()}
                </TableCell>
                <TableCell>
                  {t('payments.paymentMode.label').toUpperCase()}
                </TableCell>
                <TableCell>{t('common.source').toUpperCase()}</TableCell>
                <TableCell sx={{ minWidth: '300px' }}>
                  {t('payments.client').toUpperCase()}
                </TableCell>
                <TableCell>{t('payments.bank').toUpperCase()}</TableCell>
                <TableCell>{t('payments.suggestions').toUpperCase()}</TableCell>
                {status === 'imported-processed' && (
                  <TableCell sx={{ minWidth: '200px' }}>
                    {t('payments.processedBy').toUpperCase()}
                  </TableCell>
                )}
                <TableCell>{t('common.action').toUpperCase()}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading
                ? payments?.map((doc, index) => {
                    const invoice = doc?._files[0] || null;
                    const suggestions = doc?.suggestions;
                    const processedBy = doc.accountingStatusUpdatedBy;

                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography>{doc.bankTransactionId}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {window.dayjs(doc?.bankMetadata?.date).format('L')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {doc?.bankMetadata?.description}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{formatCurrency(doc?.amount)}</Typography>
                        </TableCell>
                        <TableCell>
                          {t(`payments.paymentMode.${doc.type}`)}
                        </TableCell>
                        <TableCell>{doc.source}</TableCell>
                        <TableCell>
                          {invoice?.cache?.customerCode ? (
                            <Typography
                              key={index}
                              sx={{
                                display: 'inline',
                                ':hover': {
                                  textDecoration: 'underline',
                                  color: theme.primary,
                                },
                              }}
                              onClick={() => {
                                window.open(
                                  `${window.location.origin}/customers/${invoice.cache?.customerId}/info`,
                                  '_blank',
                                );
                              }}
                            >
                              {invoice.cache?.customerCode} -{' '}
                              {invoice.cache?.customerName}
                            </Typography>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {doc?.bankMetadata?.bankAccount || ''}
                        </TableCell>
                        <TableCell>
                          <div>
                            {suggestions?.map((suggestion, index) => (
                              <Suggestion key={index} suggestion={suggestion} />
                            ))}
                          </div>
                        </TableCell>
                        {status === 'imported-processed' ? (
                          <TableCell>
                            <Tooltip
                              arrow
                              placement="top"
                              title={processedBy?.fullName || ''}
                            >
                              <span>
                                {window
                                  .dayjs(doc.accountingStatusUpdatedAt)
                                  .format('LLL')}
                              </span>
                            </Tooltip>
                          </TableCell>
                        ) : null}
                        <TableCell>
                          <Button
                            variant="outlined"
                            color={
                              doc.accountingStatus === 'imported'
                                ? 'primary'
                                : 'error'
                            }
                            sx={{ marginRight: 5 }}
                            onClick={() => onSwitchStatus(doc)}
                          >
                            {doc.accountingStatus === 'imported'
                              ? t('payments.processAccounting')
                              : t('common.cancel')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }

  return (
    <Grid
      sx={{
        m: '10px 20px',
      }}
    >
      <TableContainer>
        <Table
          size="small"
          sx={{
            backgroundColor: theme.baseWhite,
          }}
        >
          <TableHead
            sx={{
              backgroundColor: theme.cultured,
              height: '10px',
            }}
          >
            <TableRow>
              <TableCell align="left">
                {t('common.date').toUpperCase()}
              </TableCell>
              <TableCell align="left">
                {t('common.customer').toUpperCase()}
              </TableCell>
              <TableCell>{t('payments.amount').toUpperCase()}</TableCell>
              <TableCell>
                {t('payments.paymentMode.label').toUpperCase()}
              </TableCell>
              <TableCell>{t('payments.status').toUpperCase()}</TableCell>
              <TableCell>{t('payments.invoice').toUpperCase()}</TableCell>
              <TableCell>{t('payments.orderCode').toUpperCase()}</TableCell>
              {(status === 'processed' || status === 'imported-processed') && (
                <TableCell>{t('payments.processedBy').toUpperCase()}</TableCell>
              )}
              <TableCell>{t('common.action').toUpperCase()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading
              ? payments?.map((doc, index) => {
                  const invoice = doc?._files?.[0] || null;

                  const processedBy = doc.accountingStatusUpdatedBy;

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography>
                          {window.dayjs(doc?.statusUpdatedAt).format('L')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            display: 'inline',
                            ':hover': {
                              textDecoration: 'underline',
                              color: theme.primary,
                            },
                          }}
                        >
                          {doc?._customer?.code} - {doc?._customer?.legalName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{formatCurrency(doc?.amount)}</Typography>
                      </TableCell>
                      <TableCell>
                        {t(`payments.paymentMode.${doc.type}`)}
                      </TableCell>
                      <TableCell>
                        <StatusChip
                          text={t(`payments.status.${doc.status}`)}
                          status={doc.status}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            display: 'inline',
                            ':hover': {
                              textDecoration: 'underline',
                              color: theme.primary,
                            },
                          }}
                          onClick={() => {
                            window.open(invoice.url);
                          }}
                        >
                          {invoice?.metadata?.invoiceCode}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{invoice?.metadata?.orderCode}</Typography>
                      </TableCell>
                      {status === 'processed' ||
                      status === 'imported-processed' ? (
                        <TableCell>
                          <Tooltip
                            arrow
                            placement="top"
                            title={window
                              .dayjs(doc.accountingStatusUpdatedAt)
                              .format('L')}
                          >
                            <span>{processedBy?.fullName || ''}</span>
                          </Tooltip>
                        </TableCell>
                      ) : null}
                      <TableCell>
                        <Button
                          variant="outlined"
                          color={
                            doc.accountingStatus === 'unprocessed'
                              ? 'primary'
                              : 'error'
                          }
                          sx={{ marginRight: 5 }}
                          onClick={() => onSwitchStatus(doc)}
                        >
                          {doc.accountingStatus === 'unprocessed'
                            ? t('payments.processAccounting')
                            : t('common.cancel')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
