import React from 'react';
import FunctionalDepartments from './Components/FunctionalDepartments';
import { GridProvider } from '../../../../../components/Grid/context';
import { Department } from '../../../../../../common/types/CompanyDepartments';
import ManagedContext from '../../../../../../common/UtilityComponents/ManagedContext/ManagedContext';

export interface IDepartmentsContext {
  openDrawer: boolean;
  selectedDepartment: Department | null;
  editMode: boolean;
  canEdit: boolean;
}
export const Departments = () => {
  return (
    <GridProvider>
      <ManagedContext
        contextName="departmentsContext"
        defaultValue={
          {
            openDrawer: false,
            selectedDepartment: null,
            editMode: false,
            canEdit: false,
          } as IDepartmentsContext
        }
      >
        <FunctionalDepartments />
      </ManagedContext>
    </GridProvider>
  );
};
