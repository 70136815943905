import { CustomCellRendererProps } from '@ag-grid-community/react';

function SourceCell(params: CustomCellRendererProps) {
  return (
    <div>
      <span>{params.value.sourceData || '-'}</span>
    </div>
  );
}

export default SourceCell;
