import React, { useState } from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Typography,
  Popover,
  TextField,
  Autocomplete,
  Button,
} from '@mui/material';
import styled from 'styled-components';
import { X } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { selectConfiguration } from 'common/store/organization/selectors';
import { useSelector } from 'react-redux';
import { Control, useWatch } from 'react-hook-form';
import { Days } from 'app/pages/Customers/components/ViewCustomer/data/data';

interface RoundtripTableProps {
  control: Control<any>;
  setValue: any;
}

export const RoundtripTable: React.FC<RoundtripTableProps> = ({
  control,
  setValue,
}) => {
  const { t } = useTranslation();
  const configuration = useSelector(selectConfiguration);
  const roundTripCode = configuration?.roundtripCodes || [];

  // Use useWatch to get real-time form values
  const currentRoundtripValues = useWatch({
    control,
    name: 'roundtripDays',
    defaultValue: {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
  });

  const [openPopover, setOpenPopover] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [clickedDay, setClickedDay] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const canEdit = true;

  const handleClick = (event, day: string) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPopoverPosition({ top: rect.bottom, left: rect.left });
    setOpenPopover(true);
    setClickedDay(day);
  };

  const handleClosePop = () => {
    setOpenPopover(false);
    setClickedDay(null);
    setSelectedValue(null);
  };

  const handleAdd = () => {
    if (clickedDay && selectedValue) {
      // Update form state
      setValue(
        'roundtripDays',
        {
          ...currentRoundtripValues,
          [clickedDay]: selectedValue,
        },
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );

      handleClosePop();
    }
  };

  const handleDeleteCellValue = (day: string) => {
    // Update form state by setting the day's value to null
    setValue(
      'roundtripDays',
      {
        ...currentRoundtripValues,
        [day]: null,
      },
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  };

  return (
    <Grid container>
      <TableContainer
        style={{ border: `1px solid ${themes?.default?.gainsboro2}` }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {Days.map((dayObj) => (
                <StyledTableCell
                  key={dayObj.value}
                  style={{
                    backgroundColor: currentRoundtripValues[dayObj.value]
                      ? '#E5EBFF'
                      : 'transparent',
                    color: currentRoundtripValues[dayObj.value]
                      ? '#37383D'
                      : '#A9A9A9',
                  }}
                >
                  {dayObj.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {Days.map((dayObj, index) => {
                const dayValue = currentRoundtripValues[dayObj.value];
                return (
                  <StyledTableCell
                    key={dayObj.value}
                    style={{
                      border:
                        openPopover && popoverPosition.left === index * 120
                          ? '2px solid blue'
                          : `1px solid ${themes?.default?.gainsboro2}`,
                    }}
                    onClick={(e) => {
                      if (!dayValue && canEdit) {
                        handleClick(e, dayObj.value);
                      }
                    }}
                  >
                    <Grid container alignItems="center">
                      <span
                        style={{
                          color: dayValue
                            ? themes?.default?.primaryActiveColor
                            : themes?.default?.textColorDashboard,
                        }}
                      >
                        {dayValue || '-'}
                      </span>
                      {dayValue && canEdit && (
                        <IconButton
                          onClick={() => handleDeleteCellValue(dayObj.value)}
                          sx={{ p: '2px', ml: 'auto' }}
                        >
                          <X size={12} />
                        </IconButton>
                      )}
                    </Grid>
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        open={canEdit && openPopover}
        onClose={handleClosePop}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: popoverPosition.top,
          left: popoverPosition.left,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '266.33px',
            height: 'fit-content',
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            p: '10px 8px',
            borderBottom: `1px solid ${themes?.default?.gainsboro2}`,
          }}
        >
          <Typography>
            {t('roundtrips.edit_roundtrip.add_roundtrip')}
          </Typography>
          <IconButton
            onClick={handleClosePop}
            sx={{
              marginLeft: 'auto',
              padding: '2px',
            }}
          >
            <X size={12} />
          </IconButton>
        </Grid>

        <Grid
          sx={{
            borderBottom: `1px solid ${themes?.default?.gainsboro2}`,
            p: '12px 8px 0px 8px',
          }}
        >
          <Autocomplete
            onChange={(event, newValue) => setSelectedValue(newValue || null)}
            options={roundTripCode}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="addRoundTrip"
                size="small"
                variant="outlined"
                placeholder={t('Select Roundtrip')}
              />
            )}
          />
        </Grid>
        <Button
          onClick={handleAdd}
          style={{
            float: 'right',
            margin: '12px 8px',
          }}
          variant="contained"
        >
          {t('common.buttons.add')}
        </Button>
      </Popover>
    </Grid>
  );
};

const StyledTableCell = styled.td`
  padding: 2px 4px;
  font-size: 0.75rem;
  border: 1px solid #efefef;
  cursor: pointer;
  width: 120px;
  height: 25px;

  &:hover {
    background-color: ${themes.default.lightGrey};
  }
`;
