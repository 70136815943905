import { CustomCellRendererProps } from '@ag-grid-community/react';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';
import { themes } from '../../../../../styles/theme/themes';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { PopoverEl } from 'app/components/Popover';
import { ActionComponent } from 'app/components/ManagedGrid/components/ActionComponent';
import { DownloadSimple } from '@phosphor-icons/react';
import If from 'app/components/If';
import { IconButton, Tooltip } from '@mui/material';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import { downloadFile } from 'app/helpers/helpers';
import { useModal } from 'app/components/Dialog/hooks';
import { useUpdateArchiveInvoiceMutation } from 'common/services/transactions';

const ActionsMenuCell = (params: CustomCellRendererProps) => {
  const billsObj = params.value.actionMenuData;

  const { isCustomer } = useGetUserAcl();

  const gridContext = useManagedContext<IGridContext>('grid');
  const { t } = useTranslation();

  const [updateArchive] = useUpdateArchiveInvoiceMutation();
  const { openModal, closeModal } = useModal();

  function archiveBill() {
    openModal({
      title: t('invoice.archive.title'),
      content: t('invoice.archive.content.single'),
      action: {
        actionText: t('messages.archive'),
        actionCallback: () => {
          updateArchive({ invoiceIds: [billsObj?._id], isArchived: true });
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }

  function unarchiveBill() {
    openModal({
      title: t('invoice.unarchive.title'),
      content: t('invoice.unarchive.content.single'),
      action: {
        actionText: t('messages.unarchive'),
        actionCallback: () => {
          updateArchive({ invoiceIds: [billsObj?._id], isArchived: false });
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }

  const isArchivedFilter = gridContext.queryParams.filters.archived;

  const allowedActions: any = [];

  if (!isArchivedFilter) {
    allowedActions.push({
      action: 'archive',
      onClick: () => archiveBill(),
      element: (
        <PopoverEl
          redStatus={themes.default.redStatus}
          style={{ width: '100%' }}
        >
          <div className="delete">{t('buttons.archive')}</div>
        </PopoverEl>
      ),
    });
  }

  if (isArchivedFilter) {
    allowedActions.push({
      action: 'unarchived',
      onClick: () => unarchiveBill(),
      element: (
        <PopoverEl
          redStatus={themes.default.redStatus}
          style={{ width: '100%' }}
        >
          <div className="delete">{t('messages.unarchive')}</div>
        </PopoverEl>
      ),
    });
  }

  return (
    <>
      <If condition={isCustomer()}>
        <Tooltip title={t('common.download')}>
          <IconButton
            onClick={() => {
              const fileName =
                billsObj?.initialUrl.split('/').pop() || 'file.pdf';
              downloadFile(billsObj?.initialUrl, fileName);
            }}
            sx={{
              padding: '4px',
              width: '28px',
              height: '28px',
            }}
          >
            <DownloadSimple />
          </IconButton>
        </Tooltip>
      </If>

      <If condition={!isCustomer()}>
        <ActionComponent
          actions={allowedActions}
          quickActions={[
            {
              icon: (
                <Tooltip title={t('common.download')}>
                  <DownloadSimple />
                </Tooltip>
              ),
              onClick: () => {
                const fileName =
                  billsObj?.initialUrl.split('/').pop() || 'file.pdf';
                downloadFile(billsObj?.initialUrl, fileName);
              },
            },
          ]}
        />
      </If>
    </>
  );
};
export default ActionsMenuCell;
