import { useEffect, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { UploadZone } from 'app/components/UploadFiles';
import { RejectedFiles } from 'app/components/RejectedFiles';
import { AcceptedFiles } from 'app/components/AcceptedFiles/Index';
import If from 'app/components/If';

interface Document {
  _id?: string;
  url: string;
  name: string;
  size: number;
}

interface Props {
  documents?: Document[];
  disabled?: boolean;
  options?: DropzoneOptions;
  showUploadZone?: boolean;
  setUploadedDocuments?: Function;
  setDeletedDocuments?: Function;
}

export const UploadDocument = ({
  documents = [],
  disabled = false,
  options,
  showUploadZone = true,
  setUploadedDocuments = () => {},
  setDeletedDocuments = () => {},
}: Props) => {
  const [documentsList, setDocumentsList] = useState<Document[]>(documents);
  const [filesList, setFilesList] = useState<any[]>([]);
  const [deletedDocumentIds, setDeletedDocumentIds] = useState<string[]>([]);
  const [rejectedDocuments, setRejectedDocuments] = useState<any>([]);

  const handleUpload = (files: File[]) => {
    const newFiles = files.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
    }));

    setDocumentsList((prev) => [...prev, ...newFiles]);
    setFilesList((prev) => [...prev, ...files]);
  };

  const handleDelete = (fileName: string, _id?: string) => {
    if (_id) {
      setDeletedDocumentIds((prev) => [...prev, _id]);
    }

    setDocumentsList((prev) => prev.filter((file) => file.name !== fileName));
    setFilesList((prev) => prev.filter((file) => file.name !== fileName));
  };

  useEffect(() => {
    setUploadedDocuments(filesList);
    setDeletedDocuments(deletedDocumentIds);
  }, [filesList, deletedDocumentIds]);

  return (
    <>
      <If condition={showUploadZone}>
        <UploadZone
          maxFiles={10}
          type=""
          title=""
          description="Supported formats are: PDF, DOC, JPG, JPEG and PNG (max. 3MB)"
          onOk={(files: File[]) => handleUpload(files || [])}
          onUnOk={(rejection) => {
            setRejectedDocuments((prevState) => [...prevState, rejection]);
          }}
          acceptFile={{
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              ['.docx'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
          }}
          style={{ height: '150px', padding: '12px' }}
          disabled={!!disabled}
          {...options}
        />
      </If>

      <div>
        {documentsList.map((file, index) => (
          <AcceptedFiles
            key={index}
            file={file}
            statusFile={{ [file.name]: 'Uploaded' }}
            hideProgressBar={{ [file.name]: true }}
            fileProgress={{ [file.name]: 0 }}
            deleteFile={() => handleDelete(file?.name, file?._id)}
            canView={!!file.url}
            disabled={disabled}
          />
        ))}
      </div>
      <RejectedFiles
        files={rejectedDocuments}
        deleteFile={(name: string) => {
          const filteredDocuments = [...rejectedDocuments];
          setRejectedDocuments(
            filteredDocuments?.filter((file) => file?.file?.name !== name),
          );
        }}
      />
    </>
  );
};
