import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { TwoViewModal } from '../TwoViewModal';
import { Header } from './components/Header';
import { useTranslation } from 'react-i18next';
import { Tabs } from './components/Tabs';
import ManagedContext from 'common/UtilityComponents/ManagedContext/ManagedContext';
import { useLazyGetOrdersIdQuery } from 'common/services/orderApi';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { OrderInfo } from './components/OrderInfo';
import If from '../If';
import { LoadingPulse } from '../LoadingPulse';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import MapPopover from '../MapPopover';
import { RoundtripStatus } from 'common/utils/enum';
import { debounce } from 'lodash';
import { OrderStatus } from 'types';
import { themes } from 'styles/theme/themes';

export interface IOrderDetailsContext {
  order: any;
  canEdit: boolean;
  isFetching: boolean;
  refetch: (id: string) => void;
  defaultTabIndex?: number;
  handleOpenRoundtripMap: () => void;
  height: number;
}
console.log('AAAAAAAAAAAA');

const OrderDetailsComponent = ({
  open,
  onClose,
  order,
  isFetching,
  handleOpenRoundtripMap,
}) => {
  console.log('order:', order);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const [showMap, setShowMap] = useState(false);

  const orderDetailsContext = useManagedContext('orderDetails');

  const showCanceledReason = useMemo(() => {
    return order?.status === OrderStatus.Canceled && !!order?.cancelReason;
  }, [order]);

  useEffect(() => {
    orderDetailsContext.updateDataWithFunction((prev) => {
      prev.order = order;
      prev.handleOpenRoundtripMap = handleOpenRoundtripMap;
    });
  }, [order]);
  useEffect(() => {
    orderDetailsContext.updateDataWithFunction((prev) => {
      prev.isFetching = isFetching;
    });
  }, [isFetching]);

  //TODO: adjust TwoViewModal to make this global
  useEffect(() => {
    if (modalRef.current) {
      const updateHeight = debounce((height) => {
        orderDetailsContext.updateDataWithFunction((prev) => {
          prev.height = !!showCanceledReason ? height - 40 : height;
        });
      }, 100);

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          updateHeight(entry.contentRect.height);
        }
      });

      resizeObserver.observe(modalRef.current);

      return () => {
        resizeObserver.disconnect();
        updateHeight.cancel();
      };
    }
  }, [modalRef.current, open, showCanceledReason]);

  const closeModal = () => {
    onClose();
    setShowMap(false);
    orderDetailsContext.updateDataWithFunction((prev) => {
      prev.defaultTabIndex = 0;
    });
  };

  return (
    <TwoViewModal
      onOpen={open}
      onClose={closeModal}
      width="1300px"
      scrollY={false}
      bodyPadding="0"
      headerContent={<Header showMap={() => setShowMap(true)} />}
      ref={modalRef}
    >
      <Stack height="100%">
        <If condition={!!showCanceledReason}>
          <Alert
            variant="standard"
            severity="error"
            sx={{
              borderRadius: '0px !important',
              background: themes.default.lightCoral,
              height: '40px',
              alignItems: 'center',
            }}
          >
            <Typography
              color={themes.default.maroon}
              fontWeight="500"
              fontSize="0.8125rem"
            >
              {`${t('reason_for_cancellation')}: ${t(order?.cancelReason)}`}
            </Typography>
          </Alert>
        </If>
        <Stack direction="row" alignItems="center" height="100%">
          <If
            condition={!orderDetailsContext.isFetching}
            otherwise={<LoadingPulse />}
          >
            <Box height="100%" padding="0" width="65%">
              <Tabs />
            </Box>
            <Box height="100%" padding="0" width="35%">
              <OrderInfo />
            </Box>
          </If>

          <MapPopover
            open={showMap}
            handleClose={() => {
              setShowMap(false);
            }}
            latitude={order?._deck?.location?.geometry?.coordinates[1]}
            longitude={order?._deck?.location?.geometry?.coordinates[0]}
            driverLat={
              order?.roundtrip?._vehicle?.lastPosition?.geometry
                ?.coordinates?.[1]
            }
            driverLong={
              order?.roundtrip?._vehicle?.lastPosition?.geometry
                ?.coordinates?.[0]
            }
          />
        </Stack>
      </Stack>

      <Button variant="contained" onClick={closeModal}>
        {t('common.buttons.done')}
      </Button>
    </TwoViewModal>
  );
};

interface OrderDetailsProps {
  open: boolean;
  onClose: () => void;
  orderId?: string;
  viewMode?: boolean;
  refreshRoundtrip?: (roundtripId: string) => void;
  defaultTabIndex?: number;
  handleDisplayMap?: (roundtripId: string) => void;
}

export const OrderDetails = ({
  open,
  onClose,
  orderId,
  viewMode = false,
  refreshRoundtrip,
  defaultTabIndex = 0,
  handleDisplayMap = () => {},
}: OrderDetailsProps) => {
  const can = usePermission('orders');
  const [loading, setLoading] = useState(true);
  const [triggerGetOrder, { data: order }] = useLazyGetOrdersIdQuery();
  const [currentOrder, setCurrentOrder] = useState<any>(null);

  const getOrder = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const resp = await triggerGetOrder(id).unwrap();
        setCurrentOrder(resp);
      } catch (err) {
        console.error('Error fetching order:', err);
      } finally {
        setLoading(false);
      }
    },
    [triggerGetOrder],
  );

  useEffect(() => {
    if (!orderId || !open) {
      return;
    }
    getOrder(orderId);
  }, [getOrder, orderId, open]);

  useEffect(() => {
    if (!open) {
      setCurrentOrder(null);
      setLoading(true);
    }
  }, [open]);

  function handleOpenRoundtripMap() {
    handleDisplayMap(currentOrder.roundtrip._id || '');
  }

  const canEdit = useMemo(() => {
    const nonEditableStatus = [
      RoundtripStatus.LOADED,
      RoundtripStatus.ON_WAY,
      RoundtripStatus.COMPLETED,
      RoundtripStatus.ON_DOCK,
      RoundtripStatus.UNLOADED,
      RoundtripStatus.PARKED,
      RoundtripStatus.PAUSED,
      RoundtripStatus.CANCELED,
    ];

    return (
      can(Permission.EDIT) &&
      !viewMode &&
      !nonEditableStatus?.includes(order?.roundtrip?.status)
    );
  }, [order?.status, viewMode]);

  if (!open) {
    return null;
  }
  console.log('order:', order);

  return (
    <ManagedContext
      contextName="orderDetails"
      defaultValue={
        {
          order: order,
          canEdit,
          isFetching: loading,
          refetch: async (id: string) => {
            const resp = await triggerGetOrder(id).unwrap();
            setCurrentOrder(resp);
            !!resp?.roundtrip?._id && refreshRoundtrip?.(resp.roundtrip._id);
          },
          defaultTabIndex,
          handleOpenRoundtripMap,
          height: 0,
        } as IOrderDetailsContext
      }
    >
      <OrderDetailsComponent
        open={open}
        onClose={() => {
          setCurrentOrder(null);
          setLoading(true);
          onClose();
        }}
        order={currentOrder}
        isFetching={loading}
        handleOpenRoundtripMap={handleOpenRoundtripMap}
      />
    </ManagedContext>
  );
};
