import { createContext } from 'react';

interface ContextProps {
  supplier: any;
  canEdit?: boolean;
  canExport?: boolean;
  isSupplier?: boolean;
}

export const SupplierFormcontext = createContext<ContextProps>({
  supplier: null,
  canEdit: false,
  canExport: false,
  isSupplier: false,
});
