import { Box, Button, ListItem, TextField, Typography } from '@mui/material';
import styled, { CSSObject } from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDeleteProductNoteMutation,
  useLazyGetProductNotesQuery,
  usePatchProductNoteMutation,
  useCreateProductNoteMutation,
} from 'common/services/orderApi';
import { useModal } from 'app/components/Dialog/hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../../../../../slices/auth/selectors';
import { usePreferredAgent } from 'hooks/PreferredAgent/usePreferredAgent';
import If from 'app/components/If';
import { ViewAddNotes } from 'app/pages/Customers/components/ViewCustomer/components/Tabs/ContractsTab/components/Cells/NotesCell/components/ViewAddNotes';
import { Note } from 'app/pages/Customers/components/ViewCustomer/components/Tabs/ContractsTab/types';
import { selectOrganization } from 'common/store/organization/selectors';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';

function formatTime(dateInput: Date): string {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // Use 12-hour format with AM/PM
  } as Intl.DateTimeFormatOptions;

  const date = new Date(dateInput);
  return date.toLocaleTimeString(undefined, options);
}

interface Props {
  orderId: string;
  style?: CSSObject;
}

export function OrderNotesTab({ orderId, style }: Props) {
  const can = usePermission('orders');
  const [formattedNotes, setFormattedNotes] = useState<Note[]>([]);
  const [openTextArea, setOpenTextArea] = useState(false);
  const [note, setNote] = useState('');
  const [editingNoteId, setEditingNoteId] = useState('');

  const authOrganization = useSelector(selectOrganization);
  const { t } = useTranslation();
  const { closeModal, openModal } = useModal();

  const [trigger, { data: notesData, isFetching }] =
    useLazyGetProductNotesQuery();
  const [createNotes] = useCreateProductNoteMutation();
  const [patchNote] = usePatchProductNoteMutation();
  const [deleteNote] = useDeleteProductNoteMutation();

  const productId = '';

  const fetchNotes = useCallback(() => {
    trigger({ orderId, productId });
  }, [orderId, trigger]);

  useEffect(() => {
    if (orderId) fetchNotes();
  }, [orderId]);

  useEffect(() => {
    if (!notesData?.docs) return;
    const formatted = notesData.docs.map((note) => {
      const agent = note?._author?.agents?.find(
        (a) => a._organization === authOrganization?._id,
      );
      return {
        _id: note._id,
        _user: note._author._id,
        _userAgent: {
          _id: agent?.id,
          fullName: `${agent?.firstName || ''} ${agent?.lastName || ''}`.trim(),
          pictureUrl: agent?.pictureUrl || '',
        },
        content: note.content,
        createdAt: note.createdAt,
        updatedAt: note.updatedAt,
      };
    });
    setFormattedNotes(formatted);
  }, [notesData, authOrganization]);

  const handleAddNote = async () => {
    if (!note) return;
    try {
      await createNotes({ orderId, content: note }).unwrap();
      setNote('');
      fetchNotes();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteConfirmNote = (noteId) => {
    openModal({
      title: t('orders.notes.delete_confirmation'),
      action: {
        actionText: t('common.buttons.ok'),
        actionCallback: () => handleDeleteNote(noteId),
      },
      cancel: true,
    });
  };

  const handleDeleteNote = async (noteId) => {
    await deleteNote({ orderId, noteId });
    fetchNotes();
    closeModal();
  };

  const handleEditNoteClick = (noteId, content) => {
    setOpenTextArea(true);
    setNote(content);
    setEditingNoteId(noteId);
  };

  const handleUpdateNote = async () => {
    if (!editingNoteId) return;
    await patchNote({ orderId, noteId: editingNoteId, content: note }).unwrap();
    fetchNotes();
    handleCancelAddNote();
  };

  const handleCancelAddNote = () => {
    setOpenTextArea(false);
    setNote('');
    setEditingNoteId('');
  };

  const handleAddNoteClick = async () => {
    editingNoteId ? await handleUpdateNote() : await handleAddNote();
  };

  if (isFetching) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          marginTop: '30px',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ overflowY: 'auto', ...style }}>
      <ViewAddNotes
        notes={formattedNotes}
        addNoteOpen={openTextArea}
        setAddNoteOpen={setOpenTextArea}
        onCancel={handleCancelAddNote}
        onAdd={handleAddNoteClick}
        newNote={note}
        onNoteChange={setNote}
        onDelete={handleDeleteConfirmNote}
        onEdit={handleEditNoteClick}
        editingNoteId={editingNoteId}
        sx={{ padding: '16px 16px', overflow: 'visible' }}
        canEdit={can(Permission.EDIT)}
      />
    </div>
  );
}

export function RenderNote({
  note,
  index,
  handleDeleteConfirmNote,
  handleUpdateNote,
}) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedComment, setEditedComment] = useState<string>(note.content);

  const { t } = useTranslation();

  const authUser = useSelector(selectAuthUser);
  const { preferredAgent } = usePreferredAgent({ user: note._author });
  if (!authUser) {
    return null;
  }

  const userId = authUser.id;
  const isSuperAdmin = authUser.isSuperAdmin;

  const startEditing = () => {
    setEditedComment(note.content);
    setIsEditing(true);
  };

  const handleUpdate = () => {
    handleUpdateNote(note._id, editedComment);
    setIsEditing(false);
  };

  const handleCancel = () => {
    // setEditedComment(note.content);
    setIsEditing(false);
  };

  return (
    <ListItem
      key={index}
      sx={{ paddingLeft: 0, display: 'block', marginTop: '10px' }}
    >
      <NoteHeader>
        {formatTime(note.createdAt)}
        {(note?._author?.id === userId || isSuperAdmin) && (
          <Actions>
            <EditIcon
              onClick={startEditing}
              sx={{ color: 'rgba(129, 145, 152, 1)' }}
            />
            {/* <DeleteIcon onClick={() => { handleDeleteConfirmNote(note._id) }} sx={{ color: 'rgba(129, 145, 152, 1)', marginLeft: '18px' }} /> */}
            <DeleteIcon
              onClick={() => {
                handleDeleteConfirmNote(note._id);
              }}
              sx={{ color: 'rgba(129, 145, 152, 1)', marginLeft: '18px' }}
            />
          </Actions>
        )}
      </NoteHeader>
      {isEditing ? (
        <div style={{ marginBottom: '15px' }}>
          <TextField
            value={editedComment}
            onChange={(e) => setEditedComment(e.target.value)}
            variant="standard"
            fullWidth
            sx={{ marginBottom: '10px !important' }}
          />
          <Button
            onClick={handleUpdate}
            variant="outlined"
            size="small"
            sx={{ marginRight: '10px' }}
          >
            {t('common.buttons.save')}
          </Button>
          <Button onClick={handleCancel} variant="outlined" size="small">
            {t('common.buttons.cancel')}
          </Button>
        </div>
      ) : (
        <Comment>{note.content}</Comment>
      )}
      <If
        condition={note._authorAgent}
        otherwise={
          <CommentedBy>
            <Circle>
              <Letter>{preferredAgent?.firstName?.[0]?.toUpperCase()}</Letter>
            </Circle>
            <CommentedByUser>{preferredAgent?.email}</CommentedByUser>
          </CommentedBy>
        }
      >
        <CommentedBy>
          <Circle>
            <Letter>{note?._authorAgent?.firstName[0]?.toUpperCase()}</Letter>
          </Circle>
          <CommentedByUser>{note?._authorAgent?.email}</CommentedByUser>
        </CommentedBy>
      </If>
    </ListItem>
  );
}

interface CustomTextFieldProps {
  handleNoteChange: (note: string) => void;
  handleOpenTextArea: (bool: boolean) => void;
  handleSave: () => void;
  validationError: string | null;
  value?: string;
  optionalFields?: JSX.Element;
}

export function CustomTextField({
  handleNoteChange,
  handleOpenTextArea,
  handleSave,
  validationError,
  value,
  optionalFields,
}: CustomTextFieldProps) {
  const { t } = useTranslation();

  return (
    <StyledBox>
      <StyledTextField
        multiline
        rows={5}
        placeholder={t('order_details.order_notes.textinput.placeholder')}
        variant="outlined"
        fullWidth
        value={value}
        onChange={(e) => handleNoteChange(e.target.value)}
        error={Boolean(validationError)}
        helperText={validationError}
      />
      <ButtonGroup>
        {optionalFields}
        <Button
          onClick={() => {
            handleOpenTextArea(false);
            handleNoteChange('');
          }}
          size="small"
          variant="text"
          sx={{
            fontSize: '0.929rem',
            color: 'rgba(0, 0, 0, 0.6)',
            minWidth: 'auto',
          }}
          disabled={true}
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={() => {
            handleSave();
            handleNoteChange('');
          }}
          size="small"
          variant="text"
          sx={{ fontSize: '0.929rem', minWidth: 'auto' }}
          color="primary"
          startIcon={<AddIcon />}
          disabled={true}
        >
          {t('common.add')}
        </Button>
      </ButtonGroup>
    </StyledBox>
  );
}

const NoteHeader = styled(Typography)`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500 !important;
`;

const Actions = styled(Typography)`
  margin-left: auto !important;
`;

const CommentedByUser = styled(Typography)`
  color: rgba(0, 0, 0, 0.38);
  margin-left: 8px !important;
`;

const CommentedBy = styled(Typography)`
  font-weight: 500 !important;
  display: flex;
  margin-top: 5px !important;
`;

const Comment = styled(Typography)`
  font-size: 1.07rem !important;
  color: rgba(0, 0, 0, 0.6);
`;

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(240, 229, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Letter = styled.span`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9rem;
`;

const StyledBox = styled(Box)`
  margin-top: 25px;
  position: relative;
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 8px;
  bottom: 24px;
  display: flex;
  gap: 8px;
`;

const StyledTextField = styled(TextField)`
  background-color: transparent;

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -25px;
  }
`;
