import { useGetCategoryOptionsQuery } from 'common/services/categoriesApi';

export const useFetchCategoriesLevels = () => {
  const { data: categories } = useGetCategoryOptionsQuery({
    level: 0,
  });
  const { data: subCategories } = useGetCategoryOptionsQuery({
    level: 1,
  });
  const { data: childCategories } = useGetCategoryOptionsQuery({
    level: 2,
  });

  return {
    categories,
    subCategories,
    childCategories,
  };
};
