import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import RoundtripMappingHeader from './RoundtripMappingHeader';
import RountripMappingInfoSection from './RountripMappingInfoSection';
import WarehouseCollapseCard from './WarehouseCollapseCard';
import AddMapping from './AddMapping';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useGetRoundtripMappingsQuery } from '../../../../../common/services/roundtripMappings';
import { IRoundtripMappingsContext } from '../index';
import { useGetUsersBasicQuery } from '../../../../../common/services/userApi';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { useGetUnrestrictedVehiclesQuery } from '../../../../../common/services/vehicleApi';
import { useSelector } from 'react-redux';
import {
  selectDecks,
  selectInactiveDecks,
} from '../../../../../common/store/organization/selectors';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { useNavigate } from 'react-router-dom';
function FunctionalRoundtripMappings() {
  const { roundtripMappings, updateDataWithFunction, currentWarehouse } =
    useManagedContext<IRoundtripMappingsContext>('RoundtripMappingsContext');

  const { data, isLoading, isFetching } = useGetRoundtripMappingsQuery('');
  const {
    data: driversData,
    isLoading: loadingDrivers,
    isFetching: fetchingDrivers,
  } = useGetUsersBasicQuery('?roles=driver&limit=1000&isLocked=false');
  const warehouses = useSelector(selectDecks);
  const inactiveDecks = useSelector(selectInactiveDecks);
  const isFirstRender = React.useRef(true);
  useEffect(() => {
    if (isLoading || isFetching) return;
    const sortedData = [...(data || [])].sort((a, b) => {
      if (a.deckName < b.deckName) return -1;
      if (a.deckName > b.deckName) return 1;
      return 0;
    });
    if (isFirstRender.current) {
      updateDataWithFunction((prev) => {
        prev.roundtripMappings = sortedData?.map((warehouse) => {
          return {
            id: warehouse.deckId,
            name: warehouse.deckName,
            nbOfRoundtrips: warehouse.mappings.length,
            shortName: warehouse.initials,
            color: warehouse.color,
            loading: false,
            status: warehouses
              .concat(inactiveDecks)
              .find((w) => w._id === warehouse.deckId)?.status,
            roundtrips: warehouse.mappings.map((roundtrip) => {
              return {
                id: roundtrip._id,
                area: roundtrip.area,
                roundtripCode: roundtrip._roundtripGescom,
                driver: roundtrip._driver,
                vehicle: roundtrip._vehicle,
              };
            }),
          };
        });
      });
    } else {
      updateDataWithFunction((prev) => {
        const updatedWarehouse = sortedData?.find(
          (warehouse) => warehouse.deckId === currentWarehouse,
        );

        if (updatedWarehouse) {
          prev.roundtripMappings = prev.roundtripMappings?.map((w) => {
            if (w.id === prev.currentWarehouse) {
              const index = roundtripMappings.findIndex(
                (item) => item.id === w.id,
              );
              return {
                id: updatedWarehouse.deckId,
                name: updatedWarehouse.deckName,
                shortName: updatedWarehouse.initials,
                color: updatedWarehouse.color,
                loading: false,
                status: warehouses
                  .concat(inactiveDecks)
                  .find((w) => w._id === updatedWarehouse.deckId)?.status,
                roundtrips: [
                  ...updatedWarehouse.mappings.map((roundtrip) => ({
                    id: roundtrip._id,
                    area: roundtrip.area,
                    roundtripCode: roundtrip._roundtripGescom,
                    driver: roundtrip._driver,
                    vehicle: roundtrip._vehicle,
                  })),
                  ...roundtripMappings[index]?.roundtrips.filter(
                    (existingRoundtrip) =>
                      existingRoundtrip.id.includes('New') &&
                      !existingRoundtrip.roundtripCode,
                  ),
                ],
                nbOfRoundtrips:
                  updatedWarehouse.mappings.length +
                  (roundtripMappings[index]?.roundtrips?.filter(
                    (existingRoundtrip) =>
                      existingRoundtrip.id.includes('New') &&
                      !existingRoundtrip.roundtripCode,
                  ).length || 0), // Fallback to 0 if undefined
              };
            }
            return w;
          });
        }
      });
    }
    isFirstRender.current = false;
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    updateDataWithFunction((prev) => {
      const incomingRoundripGescomCodes = data?.map((warehouse) =>
        warehouse.mappings.map((roundtrip) => roundtrip._roundtripGescom),
      );
      prev.roundtripCodes = prev.roundtripCodes?.filter(
        (code) => !incomingRoundripGescomCodes?.flat().includes(code),
      );
    });
  }, [roundtripMappings, data, isLoading, isFetching]);
  useEffect(() => {
    if (loadingDrivers || fetchingDrivers) return;

    updateDataWithFunction((prev) => {
      prev.drivers = driversData?.docs?.map((d) => ({
        fullName: d.fullName,
        id: d._id,
      }))!;

      // Extract driver IDs from the roundtrips
      const usedDriverIds = roundtripMappings
        .flatMap((mapping) =>
          mapping.roundtrips.map((roundtrip) => roundtrip.driver),
        )
        .filter(Boolean); // Remove null or undefined

      // Filter drivers not in usedDriverIds
      const filteredDrivers = driversData?.docs?.filter(
        (driver) => !usedDriverIds.includes(driver._id),
      );

      prev.filteredDrivers = filteredDrivers?.map((d) => ({
        fullName: d.fullName,
        id: d._id,
      }))!;
    });
  }, [driversData, loadingDrivers, fetchingDrivers, data, roundtripMappings]);

  const {
    data: vehicles,
    isLoading: vehiclesLoading,
    isFetching: vehiclesFetching,
  } = useGetUnrestrictedVehiclesQuery('?limit=100');

  useEffect(() => {
    if (vehiclesLoading || vehiclesFetching) return;

    updateDataWithFunction((prev) => {
      // Map vehicles to include fullName and id
      prev.vehicles = vehicles.docs.map((element) => ({
        fullName:
          element?.model +
          ' / ' +
          element?.numberPlate +
          ' / ' +
          element.parkNumber,
        id: element._id,
      }));

      // Extract used vehicle IDs from roundtrips
      const usedVehicleIds = roundtripMappings
        .flatMap((mapping) =>
          mapping.roundtrips.map((roundtrip) => roundtrip.vehicle),
        )
        .filter(Boolean); // Remove null or undefined

      // Filter vehicles not in usedVehicleIds
      const filteredVehicles = vehicles?.docs?.filter(
        (vehicle) => !usedVehicleIds.includes(vehicle._id),
      );

      // Map filtered vehicles to the desired structure
      prev.filteredVehicles = filteredVehicles?.map((element) => ({
        fullName:
          element?.model +
          ' / ' +
          element?.numberPlate +
          ' / ' +
          element.parkNumber,
        id: element._id,
      }))!;
    });
  }, [vehicles, vehiclesLoading, vehiclesFetching, data, roundtripMappings]);
  const mappingCan = usePermission('mappings')(Permission.VIEW);
  const navigate = useNavigate();

  useEffect(() => {
    if (!mappingCan) {
      navigate('/settings'); // Redirect to the settings page or any other valid route
    }
  }, [mappingCan, navigate]);

  if (isLoading || loadingDrivers || vehiclesLoading)
    return <LoadingIndicator />;
  return (
    <Stack sx={{ marginBottom: '80px' }}>
      <RoundtripMappingHeader />
      <Stack direction="row" px="20px" justifyContent="space-evenly">
        <RountripMappingInfoSection />
        <Stack flex="1" px="20px">
          <Stack gap={3}>
            {roundtripMappings.map((warehouse) => {
              return <WarehouseCollapseCard warehouse={warehouse} />;
            })}
          </Stack>
          <AddMapping />
        </Stack>
        <Stack />
      </Stack>
    </Stack>
  );
}

export default FunctionalRoundtripMappings;
