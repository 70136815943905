import NoRoundtrip from 'assets/img/roundTrips/no-data-roundtrip.svg';
import { IconButtonComponent } from 'app/components/IconButtonComponent';
import { Text } from 'app/pages/AddRoundTrips/components/RightBarNoData';
import { themes } from 'styles/theme/themes';
import React, { useCallback, useEffect, useState } from 'react';
import { IGridContext, INoDataConfig } from '../types';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { Menu } from '../../CustomMenu';
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';

const ManagedNoData: React.FC<INoDataConfig> = ({
  icon,
  onClick,
  text,
  textStyle,
  description,
  IconButton,
  textButton = '',
  imgStyle,
  buttonBackground = '',
  buttonStyle,
  containerStyle,
}) => {
  const {
    canEdit,
    openDrawer,
    updateDataWithFunction,
    updateData,
    menuOptions,
  } = useManagedContext<IGridContext>('grid');
  const [menuAnchor, setMenuAcnhor] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);
  const { t } = useTranslation();
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAcnhor(event.currentTarget);
    updateData('menuIsOpen', true);
  };

  useEffect(() => {
    updateDataWithFunction((prev) => {
      prev.api.openNoDataMenu = openMenu;
    });
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAcnhor(null);
    updateData('menuIsOpen', false);
  }, []);
  return (
    <>
      <img src={icon || NoRoundtrip} alt="" style={imgStyle || {}} />
      <Stack gap="8px" style={containerStyle}>
        <Text
          className="NoDataComponentText"
          style={{ padding: '5px', ...textStyle }}
        >
          {t(text)}
        </Text>
        <Typography
          fontSize="1rem"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.15px"
          color={themes.default.clientMarkerBg}
        >
          {description}
        </Typography>
        {canEdit && (
          <IconButtonComponent
            iconComponent={IconButton}
            size="small"
            style={{
              fontWeight: '500',
              //margin: '0 auto',
            }}
            text={t(textButton)}
            iconStyle={{ color: themes?.default?.accordionWhiteBg }}
            variant="contained"
            aria-controls={openDrawer ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openDrawer ? 'true' : undefined}
            onClick={
              onClick ??
              (() => {
                updateDataWithFunction((prev) => {
                  prev.selectedRow = null;
                  prev.openDrawer = true;
                });
              })
            }
            invert={true}
            aria-label="add_roundtrip"
            sx={{ width: '100%', background: buttonBackground, ...buttonStyle }}
          />
        )}
      </Stack>
      {menuOptions && (
        <Menu
          options={menuOptions}
          open={open}
          anchorEl={menuAnchor}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            marginTop: '10px', // Add a margin or adjust positioning via sx prop for custom offset
          }}
        />
      )}
    </>
  );
};
export default ManagedNoData;
