import { CheckCircle } from '@phosphor-icons/react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { themes } from 'styles/theme/themes';
import { IconButton } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { useHelpers } from '../useHelpers';
import { useRestrictionsPermission } from 'app/components/Restrictions/hooks/useRestrictionsPermission';

export const ApproveCell = (params: CustomCellRendererProps) => {
  const rowData = params?.value?.cellData;
  const isAdmin = useRestrictionsPermission();

  const { approveOffer } = useHelpers();

  return (
    <>
      {rowData.status === 'pending_approval' && isAdmin ? (
        <IconButton
          sx={{ padding: '2px' }}
          onClick={() => approveOffer([rowData._id])}
        >
          <Icon
            icon={<CheckCircle weight="fill" />}
            size={24}
            color={themes.default.green800}
          />
        </IconButton>
      ) : (
        '-'
      )}
    </>
  );
};
