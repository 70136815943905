import { SupportUnit } from 'app/pages/SupportUnits/types';
import { StateSetter } from 'types';

export interface IViewEditOrderContext {
  order: any;
  customer: any;
  handleOpenOrderDetailsDrawer: (tab: '1' | '2' | '3') => void;
  handleOpenDeleteOrderDialog: () => void;
  handleOpenTemplateDialog: () => void;
  handleOpenPlaceOrderDialog: () => void;
  handleOpenProductDetailsDrawer: (product: any) => void;
  submitDisabled: boolean;
  setSubmitDisabled: StateSetter<boolean>;
  orderProductsCategoryData: Category[];
  orderProducts: any[];
  setOrderProducts: StateSetter<any[]>;
  canEdit: boolean;
  addDisabled: boolean;
  selectedBranch: any;
  setSelectedBranch: StateSetter<any>;
  subTotal: number;
  totalDiscountedPrice: number;
  salesTaxes: number;
  totalTTC: number;
  expressFee: number;
  discountOnTotalPrice: number;
  setSubTotal: StateSetter<number>;
  setTotalDiscountedPrice: StateSetter<number>;
  setSalesTaxes: StateSetter<number>;
  setTotalTTC: StateSetter<number>;
  setExpressFee: StateSetter<number>;
  setDiscountOnTotalPrice: StateSetter<number>;
  selectedDeliveryDateValue: moment.Moment | null;
  setSelectedDeliveryDateValue: StateSetter<moment.Moment | null>;
  isCustomer: boolean;
  isSales: boolean;
  isPlanner: boolean;
  isEditedBySales: boolean;
  isSwitchingOrders: boolean;
  setIsSwitchingOrders: StateSetter<boolean>;
  isLoading: boolean;
  plannedSupportUnits: SupportUnit[] | null;
  setPlannedSupportUnits: StateSetter<SupportUnit[] | null>;
}

export interface Category {
  id: string;
  name: string;
  itemsCount: number;
  products: any[];
}

export enum OrderFrequency {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NOT_ORDERED = 'not-ordered',
}
