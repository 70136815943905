import { Skeleton, Stack } from '@mui/material';
import { GridContainer } from '../DataGrid';
import { AgGridReact } from '@ag-grid-community/react';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from '../types';
import HeaderComponent from './HeaderComponent';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import '../index.css';
import { useTheme } from 'common/hooks/useTheme';
import { useEffect, useMemo, useRef } from 'react';

interface TableSkeletonProps {
  rows?: number;
  columns?: number;
}

export function GridSkeleton<T, U>({ rows = 30 }: TableSkeletonProps) {
  const gridContext = useManagedContext<IGridContext<T, U>>('grid');
  const theme = useTheme();
  const gridWrapperRef = useRef<HTMLDivElement | null>(null);

  const themeColors = useMemo(() => {
    return {
      'active-color': theme.primaryActiveColor,
    };
  }, [theme.primaryActiveColor]);

  useEffect(() => {
    const gridElement = gridWrapperRef.current;
    if (!gridElement) return;

    const colorEntries = Object.entries(themeColors);

    colorEntries.forEach(([key, value]) => {
      gridElement.style.setProperty(`--ag-${key}`, value);
    });

    return () => {
      colorEntries.forEach(([key]) => {
        gridElement.style.removeProperty(`--ag-${key}`);
      });
    };
  }, [themeColors]);

  // Generate column definitions based on customColumnConfig
  const skeletonColumnDefs =
    gridContext.customColumnConfig?.map((col, index) => ({
      ...col,
      field: `col${index}`,
      headerName: col.headerName || '',
      cellRenderer: () => (
        <Skeleton
          variant="text"
          height={24}
          animation="wave"
          sx={{ my: 1 }}
          width="50%"
        />
      ),
      // Remove any custom renderers or editors
      cellEditor: undefined,
      valueFormatter: undefined,
      valueParser: undefined,
      sortable: false,
      lockPinned: true,
    })) ?? [];

  // Generate dummy data
  const generateSkeletonData = () => {
    return Array.from({ length: rows }, () => {
      const rowData: Record<string, any> = {};
      skeletonColumnDefs.forEach((col) => {
        rowData[col.field] = ' ';
      });
      return rowData;
    });
  };

  return (
    <GridContainer ref={gridWrapperRef} className="ag-theme-quartz">
      <AgGridReact
        rowData={generateSkeletonData()}
        columnDefs={skeletonColumnDefs}
        domLayout="normal"
        suppressNoRowsOverlay={true}
        alwaysShowHorizontalScroll={false}
        alwaysShowVerticalScroll={false}
        rowHeight={40}
        headerHeight={32}
        components={{
          agColumnHeader: HeaderComponent,
        }}
      />
    </GridContainer>
  );
}

export const PaginationSkeleton = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      columnGap="20px"
      sx={{ px: 2, py: 1 }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Skeleton variant="text" width={300} height={32} />
        <Skeleton variant="rectangular" width={200} height={28} />
      </Stack>
    </Stack>
  );
};
