/*eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  IconButton,
  Typography,
  Popover,
  TextField,
  Button,
  Stack,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Days } from 'app/pages/Customers/components/ViewCustomer/data/data';
import { useTranslation } from 'react-i18next';
import { Control, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import { X } from '@phosphor-icons/react';

interface DeliveryTimeSlot {
  day: string;
  value: string;
  _id?: string;
}

interface CallDaysTableProps {
  control: Control<any>;
  setValue: any;
}

export const CallDaysTable: React.FC<CallDaysTableProps> = ({
  control,
  setValue,
}) => {
  const { t } = useTranslation();

  // Use useWatch to get real-time form values
  const currentCallValues = useWatch({
    control,
    name: 'deliveryDaysHours',
    defaultValue: [],
  });

  const [cellCallValues, setCellCallValues] =
    useState<DeliveryTimeSlot[]>(currentCallValues);
  const [openCallPopover, setOpenCallPopover] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [clickedDay, setClickedDay] = useState<string | null>(null);
  const [selectedCallValue, setSelectedCallValue] = useState({
    first: null as dayjs.Dayjs | null,
    second: null as dayjs.Dayjs | null,
  });

  const canEdit = true;

  // Sync form values with local state when they change
  useEffect(() => {
    setCellCallValues(currentCallValues);
  }, [currentCallValues]);

  const handleCallClick = (event, day: string) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPopoverPosition({ top: rect.bottom, left: rect.left });
    setOpenCallPopover(true);
    setClickedDay(day);
  };

  const handleCallClosePop = () => {
    setOpenCallPopover(false);
    setClickedDay(null);
    setSelectedCallValue({ first: null, second: null });
  };

  const handleAddCall = () => {
    if (clickedDay && selectedCallValue.first && selectedCallValue.second) {
      const newTimeSlot: DeliveryTimeSlot = {
        day: clickedDay,
        value: `${selectedCallValue.first.format(
          'HH:mm',
        )}-${selectedCallValue.second.format('HH:mm')}`,
      };

      const newCellValues = [...cellCallValues];

      // Remove existing time slot for the same day if it exists
      const existingDayIndex = newCellValues.findIndex(
        (slot) => slot.day === clickedDay,
      );
      if (existingDayIndex !== -1) {
        newCellValues.splice(existingDayIndex, 1);
      }

      // Add new time slot
      newCellValues.push(newTimeSlot);

      // Update form state
      setValue('deliveryDaysHours', newCellValues, {
        shouldValidate: true,
        shouldDirty: true,
      });

      handleCallClosePop();
    }
  };

  const handleDeleteCellValue = (day: string) => {
    const newCellValues = cellCallValues.filter((slot) => slot.day !== day);

    // Update form state
    setValue('deliveryDaysHours', newCellValues, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const getTimeSlotForDay = (day: string) => {
    const daySlot = cellCallValues.find(
      (slot) => slot.day.toLowerCase() === day.toLowerCase(),
    );
    return daySlot ? daySlot.value : '-';
  };

  return (
    <div>
      <TableContainer
        sx={{
          border: `1px solid ${themes?.default?.gainsboro2}`,
        }}
      >
        <Table
          size="small"
          style={{ border: `1px solid ${themes?.default?.gainsboro2}` }}
        >
          <TableHead>
            <StyledTableRow>
              {Days.map((day, index) => (
                <StyledTableCell
                  key={index}
                  style={{
                    backgroundColor:
                      getTimeSlotForDay(day.value) !== '-'
                        ? '#E5EBFF'
                        : 'transparent',
                    color:
                      getTimeSlotForDay(day.value) !== '-'
                        ? '#37383D'
                        : '#A9A9A9',
                  }}
                >
                  {day.label}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              {Days.map((day, index) => (
                <StyledTableCell
                  key={index}
                  id={`${index}`}
                  style={{
                    border: `1px solid ${themes?.default?.gainsboro2}`,
                    boxSizing: 'border-box',
                  }}
                  onClick={(e) => {
                    if (canEdit) {
                      handleCallClick(e, day.value);
                    }
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      fontSize="0.75rem"
                      fontWeight={400}
                      sx={{
                        color:
                          getTimeSlotForDay(day.value) !== '-'
                            ? themes?.default?.primaryActiveColor
                            : themes?.default?.textColorDashboard,
                        width: '100%',
                      }}
                    >
                      {getTimeSlotForDay(day.value)}
                    </Typography>
                    {getTimeSlotForDay(day.value) !== '-' && canEdit && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteCellValue(day.value);
                        }}
                        sx={{
                          padding: '2px',
                          ml: 'auto',
                        }}
                      >
                        <X size={12} />
                      </IconButton>
                    )}
                  </Stack>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        open={canEdit && openCallPopover}
        onClose={handleCallClosePop}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: popoverPosition.top,
          left: popoverPosition.left,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '266.33px',
            height: 'fit-content',
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            p: '10px 8px',
            borderBottom: `1px solid ${themes?.default?.gainsboro2}`,
          }}
        >
          <Typography>{t('customer.addBranch.popover.title')}</Typography>
          <IconButton
            onClick={handleCallClosePop}
            sx={{
              padding: '2px',
              marginLeft: 'auto',
            }}
          >
            <X size={12} />
          </IconButton>
        </Grid>

        <Grid
          container
          alignItems="center"
          sx={{
            borderBottom: `1px solid ${themes?.default?.gainsboro2}`,
            p: '12px 8px 0px 8px ',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label={t('common.from')}
              value={selectedCallValue.first}
              onChange={(newValue) => {
                setSelectedCallValue((prevState) => ({
                  ...prevState,
                  first: newValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: '50%',
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label={t('common.to')}
              value={selectedCallValue.second}
              onChange={(newValue) => {
                setSelectedCallValue((prevState) => ({
                  ...prevState,
                  second: newValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    !!selectedCallValue.first &&
                    !!selectedCallValue.second &&
                    selectedCallValue.first > selectedCallValue.second
                  }
                  sx={{
                    width: '50%',
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Button
          onClick={handleAddCall}
          style={{ float: 'right', margin: '12px 8px' }}
          variant="contained"
          disabled={
            selectedCallValue.first === null ||
            selectedCallValue.second === null ||
            selectedCallValue.first > selectedCallValue.second
          }
        >
          {t('common.buttons.add')}
        </Button>
      </Popover>
    </div>
  );
};

const StyledTableRow = styled.tr`
  height: 25px;
`;

const StyledTableCell = styled.td`
  padding: 2px 4px;
  font-size: 0.75rem;
  border: 1px solid #efefef;
  cursor: pointer;
  width: 120px;
  height: 25px;

  &:hover {
    background-color: ${themes.default.lightGrey};
  }
`;
