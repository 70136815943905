import { AccountSettingsNav } from 'app/pages/Settings/SettingsNav';
import { toolsActions } from 'app/slices/tools';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { themes } from 'styles/theme/themes';

const settingsTheme = createTheme({
  palette: {
    primary: {
      main: themes?.default?.primary,
    },
  },
});

export function Settings() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toolsActions.setZoomIn({ status: true }));
  }, []);

  return (
    <>
      <AccountSettingsNav />
      <Wrapper>
        <MuiThemeProvider theme={settingsTheme}>
          <Outlet />
        </MuiThemeProvider>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  width: ${(props) => `calc(100% - ${props.theme.settingsNavWidth})`};
  height: ${(props) => `calc(100vh - ${props.theme.topBarHeight})`};
  overflow: auto;
  background: #fff;
  top: ${(props) => props.theme.topBarHeight};
  display: flex;
  flex-direction: column;
`;
