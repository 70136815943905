import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Typography } from '@mui/material';
import If from 'app/components/If';
import { isEmpty } from 'lodash';
import { themes } from 'styles/theme/themes';

function InvoiceCodeCell(params: CustomCellRendererProps) {
  const handleOpenFile = () => {
    if (params.value?.initialUrlData) {
      window.open(params.value.initialUrlData, '_blank');
    }
  };

  return (
    <If condition={!isEmpty(params?.value?.invoiceCodeData)} otherwise="_">
      <Typography
        fontSize="0.75rem"
        fontWeight={500}
        onClick={handleOpenFile}
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
          ':hover': {
            color: themes.ordoria.primary,
          },
        }}
      >
        #{params?.value?.invoiceCodeData}
      </Typography>
    </If>
  );
}

export default InvoiceCodeCell;
