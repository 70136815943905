import * as React from 'react';
import { ThemeProvider as OriginalThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { useThemeSlice } from './slice';
import { selectTheme } from './slice/selectors';

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

const borderRadius = {
  outer: 8,
  inner: 4,
  nested: 2,
};

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  useThemeSlice();

  const theme = useSelector(selectTheme);

  const customTheme = createTheme({
    palette: {
      common: {
        black: theme.lightTextPrimary,
      },
      primary: {
        main: theme.primary,
        contrastText: theme.accordionWhiteBg,
      },
      secondary: {
        main: theme.accordionWhiteBg,
        contrastText: theme.accordionWhiteBg,
      },
    },
    shape: {
      borderRadius: borderRadius.inner,
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // Disable ripple globally
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true, // Ensure no ripple on buttons
        },
        styleOverrides: {
          root: {
            borderRadius: `${borderRadius.inner}px !important`,
            lineHeight: 'normal',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            lineHeight: 'normal',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            borderRadius: `${borderRadius.inner}px !important`,
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            borderRadius: `${borderRadius.inner}px !important`,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: theme.backdropColor,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: `${borderRadius.outer}px !important`,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: `${borderRadius.outer}px !important`,
          },
        },
      },

      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: `${borderRadius.inner}px !important`,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: `${borderRadius.inner}px !important`,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderRadius: `${borderRadius.outer}px !important`,
          },
        },
      },

      MuiContainer: {
        styleOverrides: {
          root: {
            borderRadius: `${borderRadius.outer}px !important`,
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            borderRadius: `${borderRadius.outer}px !important`,
          },
        },
      },
    },
  });

  return (
    <OriginalThemeProvider theme={theme}>
      <MuiThemeProvider theme={customTheme}>
        {React.Children.only(props.children)}
      </MuiThemeProvider>
    </OriginalThemeProvider>
  );
};
