import api from './api';

export const notesApi = api.injectEndpoints({
  endpoints: builder => ({
    addNote: builder.mutation<any, any>({
      query: body => ({
        url: `/notes`,
        method: 'POST',
        body,
      }),
    }),
    getNotes: builder.query<any, any>({
      query: ({ objectType, customerId, page }) => ({
        url: `/notes/${objectType}/${customerId}?page=${page}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useAddNoteMutation, useGetNotesQuery, useLazyGetNotesQuery } =
  notesApi;
