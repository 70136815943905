import {
  Box,
  Stack,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { themes } from '../../../../../styles/theme/themes';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
// import Avatar from '@mui/material/Avatar';
import { Icon } from '../../../../components/Icon';
import { TrashSimple, WarningCircle } from '@phosphor-icons/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IRoundtripMappingsContext } from '..';
import {
  useGetWarehousesQuery,
  useLazyGetConfigurationQuery,
} from 'common/services/organizationApi';
import { useSelector } from 'react-redux';
import { selectOrganization } from 'common/store/organization/selectors';
import { useSubscribeObject } from 'common/hooks/ws';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteRoundtripMappingMutation } from 'common/services/roundtripMappings';
import CloseIcon from '@mui/icons-material/Close';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const WarehouseStatusChip = ({ status }: { status: string }) => {
  if (!status) return null;
  return (
    <span
      style={{
        backgroundColor: themes.default.chipRedFont,
        borderRadius: '100px',
        fontSize: '0.68rem',
        width: '59px',
        height: '20px',
        color: themes.default.chipRedBg,
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {status}
    </span>
  );
};

const RoundtripsCountChip = ({ count }: { count: number }) => {
  if (!count) return null;
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        borderRadius: '4px',
        color: themes.default.chipTextColor,
        fontSize: '0.75rem',
        fontWeight: '400',
        lineHeight: '17.16px',
        letterSpacing: '0.17px',
        textAlign: 'left',
      }}
    >
      <Typography
        fontSize="0.625rem"
        fontWeight="400"
        lineHeight="14px"
        textAlign="left"
      >
        {count + ' roundtrips'}
      </Typography>
    </Stack>
  );
};

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export const WarehouseCardHeader = ({
  onExpandClick,
  isExpanded,
  warehouse,
}) => {
  const [deleteMapping] = useDeleteRoundtripMappingMutation();
  const roundtripsMapingsContext = useManagedContext<IRoundtripMappingsContext>(
    'RoundtripMappingsContext',
  );
  const { updateDataWithFunction } =
    useManagedContext<IRoundtripMappingsContext>('RoundtripMappingsContext');

  const [open, setOpen] = useState(false);
  const mappingCan = usePermission('mappings')(Permission.EDIT);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDelete = async (warehouse) => {
    await handleDeleteWarehouse(warehouse);
    handleClose();
  };

  async function handleDeleteWarehouse(warehouse) {
    try {
      roundtripsMapingsContext.updateDataWithFunction((prev) => {
        prev.roundtripMappings = prev.roundtripMappings.map((w) => {
          if (warehouse.id === w.id) w.loading = true;
          return w;
        });
      });
      const roundtripIds = warehouse.roundtrips.map(
        (roundtrip) => roundtrip.id,
      );

      if (roundtripIds.length > 0) {
        await deleteMapping({ ids: roundtripIds });
      }

      updateDataWithFunction((prev) => {
        prev.roundtripMappings = prev.roundtripMappings.filter(
          (item) => item.id !== warehouse.id,
        );
      });
    } catch (error) {
      console.error('Error deleting roundtrip mappings:', error);
    } finally {
      roundtripsMapingsContext.updateDataWithFunction((prev) => {
        prev.roundtripMappings = prev.roundtripMappings.map((w) => {
          if (warehouse.id === w.id) w.loading = false;
          return w;
        });
      });
    }
  }
  const { t } = useTranslation();

  const [getConfirguration, { data: warehouses }] =
    useLazyGetConfigurationQuery();
  const { data: warehouseDataFetched } = useGetWarehousesQuery('');
  const organization = useSelector(selectOrganization);

  const subscribedOrganization = organization?.id;

  const rawMessage = useSubscribeObject(
    'organization-decks',
    subscribedOrganization,
  );
  const wsDecksMessage = rawMessage ? JSON.parse(rawMessage) : null;

  const [warehouseData, setWarehouseData] = useState({
    warehouses: Array.isArray(warehouses) ? warehouses : [],
  });

  useEffect(() => {
    if (
      warehouseDataFetched &&
      Array.isArray(warehouseDataFetched.warehouses)
    ) {
      setWarehouseData(warehouseDataFetched);
    } else {
      console.error(
        'Invalid warehouseDataFetched structure:',
        warehouseDataFetched,
      );
    }
  }, [warehouseDataFetched]);

  useEffect(() => {
    if (warehouseDataFetched) {
      setWarehouseData(warehouseDataFetched);
    }
  }, [warehouseDataFetched]);

  useEffect(() => {
    if (
      wsDecksMessage?.objectType === 'organization-decks' &&
      wsDecksMessage?.data?.deckId
    ) {
      const { deckId, status } = wsDecksMessage.data;

      setWarehouseData((prev) => {
        const updatedWarehouses = prev.warehouses.map((item) =>
          item.id === deckId ? { ...item, status } : item,
        );
        return { ...prev, warehouses: updatedWarehouses };
      });
    }
  }, [rawMessage]);
  // const { data: warehouseData } = useGetWarehousesQuery('');
  useEffect(() => {
    getConfirguration();
  }, [getConfirguration]);

  const selectedWarehouseId = warehouse?.id;

  const currentwarehouse = warehouseData?.warehouses?.find(
    (warehouse) => warehouse._id === selectedWarehouseId,
  );

  // const filteredWarehouse = roundtripMappings.find(
  //   (mapping) => mapping.id === warehouseId,
  // );
  return (
    <Stack
      direction="row"
      // px={2}
      // py={1}
      justifyContent="space-between"
      // gap={2}
      alignItems="center"
      borderRadius="0px !important"
    >
      <Stack
        direction="row"
        px="16px"
        pt="12px" // justifyContent="space-between"
        pb="8px"
        gap={0.7}
        alignItems="center"
        borderRadius="0px !important"
      >
        {/* <Avatar
          sx={{ bgcolor: warehouse.color, width: '24px', height: '24px' }}
          aria-label="recipe"
        >
          {warehouse.name && warehouse.name.split(' ')[1]
            ? warehouse.name.split(' ')[1].charAt(0).toUpperCase()
            : warehouse.name
            ? warehouse.name.charAt(0).toUpperCase()
            : ''}{' '}
        </Avatar> */}
        <Stack direction="row" gap={1}>
          <Typography
            fontSize="0.75rem"
            fontWeight="700"
            letterSpacing="0.15px"
            sx={{
              backgroundColor: `${warehouse.color}`,
              width: '45px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '20px',
            }}
          >
            {warehouse.shortName}
          </Typography>
          {warehouse.roundtrips?.some((r) => !r.roundtripCode) && (
            <Tooltip
              title={`${
                warehouse.roundtrips.filter((r) => !r.roundtripCode).length
              } ${t('roundtrips-are-missing-gescom-code')}`}
              placement="top"
              arrow
            >
              <span>
                <Icon
                  icon={<WarningCircle />}
                  color={themes.default.errorMain}
                  aria-label="warning"
                />
              </span>
            </Tooltip>
          )}
          <RoundtripsCountChip count={warehouse.nbOfRoundtrips} />
          {currentwarehouse?.status === 'inactive' && (
            <WarehouseStatusChip status={currentwarehouse?.status} />
          )}
        </Stack>
      </Stack>
      <Box>
        {mappingCan && (
          <TrashSimple
            onClick={handleClickOpen}
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
              color: '#757575',
              padding: '2px',
              borderRadius: '4px',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = '#EEEEEE')
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = 'transparent')
            }
          />
        )}

        <ExpandMore
          expand={isExpanded}
          onClick={onExpandClick}
          aria-expanded={isExpanded}
          disableRipple
          aria-label="show more"
          sx={{ fontWeight: '400', color: '#757575' }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            padding: '32px 24px',
            width: '450px',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: '1.25rem',
            fontWeight: '500',
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '5px',
          }}
          id="alert-dialog-title"
        >
          {t('delete-warehouse-mapping')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              marginLeft: '8px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: '0px !important',
            marginBottom: '20px',
          }}
        >
          <DialogContentText
            sx={{
              fontSize: '0.75rem',
              fontWeight: '400',
            }}
            id="alert-dialog-description"
          >
            {t('delete-warehouse-mapping-confirm', {
              warehouseName: warehouse.shortName,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '0px !important',
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              color: `${themes.default.clientMarkerBg}`,
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: `${themes.default.grey}`,
              },
            }}
          >
            {t('common.buttons.cancel')}
          </Button>
          <Button
            onClick={() => confirmDelete(warehouse)}
            sx={{
              color: 'white',
              backgroundColor: `${themes.default.errorMain}`,
              '&:hover': {
                backgroundColor: `${themes.default.red800}`,
              },
              padding: '6px 16px',
              borderRadius: '4px',
            }}
            autoFocus
          >
            {t('orders.actions.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
