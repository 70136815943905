import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

function StatusCell(params: CustomCellRendererProps) {
  const { t } = useTranslation();

  return (
    <div>
      <Chip //Since the add is not yet implemented, the status is always paid -> static
        size="small"
        label={t(`common.${params?.value?.statusData}`)}
        sx={{
          backgroundColor: themes?.default?.chipGreenFont,
          color: themes?.default?.greenMain,
        }}
      />
    </div>
  );
}

export default StatusCell;
