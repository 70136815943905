import { getImgCdn } from 'common/helpers/cdn';
import NoPhoto from 'assets/img/ORDORIA/Catalog/NoPhoto.svg';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import moment from 'moment';
import { formatNumber } from 'utils/helpers';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';
import { useStatusToaster } from 'hooks/useStatusToaster';
import { useHelpers as useContractHelper } from '../../useHelpers';

import {
  useRemoveOfferMutation,
  useToggleActiveOfferMutation,
  useToggleStatusOfferMutation,
  useAddOfferNoteMutation,
  useEditOfferNoteMutation,
  useDeleteOfferNoteMutation,
} from 'common/services/customerOffersApi';
import { useCallback, useMemo } from 'react';
import { StatusToastVariant } from 'common/types/StatusToasterNotification';
import { Contract } from './Components/AddOffer/interfaces';
import { CustomerOfferPeriodType } from './Components/AddOffer/consts';
import { CustomerOfferPurchaseConditionType } from './Components/AddOffer/consts';

export const useHelpers = () => {
  const { callApi } = useAsyncDataV2();
  const [removeOffer] = useRemoveOfferMutation();
  const [toggleActiveOffer] = useToggleActiveOfferMutation();
  const [toggleStatusOffer] = useToggleStatusOfferMutation();
  const { checkLevel } = useContractHelper();
  const [addOfferNote] = useAddOfferNoteMutation();
  const [deleteOfferNote] = useDeleteOfferNoteMutation();
  const [editOfferNote] = useEditOfferNoteMutation();

  const statusToaster = useStatusToaster();

  function getProcessedProductsFunction(data) {
    return data?.map((prod) => {
      const currentLevel: number =
        prod?.purchaseCondition?.type === 'category'
          ? prod?.purchaseCondition?._items?.[0]?.level
          : 0;

      const pictureKey =
        prod?.offeredProduct?._product?.pictures?.length > 0
          ? prod?.offeredProduct?._product?.pictures[0]?.key
          : '';

      const pictureUrl = !!pictureKey ? getImgCdn(pictureKey, 64) : NoPhoto;

      return {
        id: prod._id || prod._product,
        customerOfferId: {
          cellData: {
            code: prod.code,
            isActive: prod.isActive,
          },
        },
        product: {
          cellData: {
            ...prod,
            ...prod.offeredProduct._product,
            pictureUrl,
            productId: prod.offeredProduct._product?._id,
          },
        },
        discount: {
          cellData: `${prod.offeredQuantity.quantity} ${
            prod.offeredQuantity.type === 'amount' ? '€' : '%'
          }`,
        },
        uc: {
          cellData: prod?.offeredProduct._product.boxingUnit || 0,
        },
        quantity: {
          cellData: prod.offeredQuantity.quantity,
        },
        applied_on: {
          cellData: capitalize(prod.purchaseCondition.type),
        },
        applied_on_type: {
          cellData:
            prod.purchaseCondition.type === 'category'
              ? capitalize(t(getTitleByLevel(currentLevel)))
              : capitalize(prod.purchaseCondition.type),
        },
        applied_on_items: {
          cellData: prod.purchaseCondition,
        },
        value: {
          cellData: prod.purchaseCondition.value
            ? `${prod.purchaseCondition.valueType === 'amount' ? ' €' : ''} ${
                prod.purchaseCondition.value
              } ${
                prod.purchaseCondition.valueType !== 'amount' ? t('boxes') : ''
              } `
            : '',
        },
        total: {
          cellData: formatNumber(prod?.totalOffer || 0),
        },

        when: {
          cellData:
            prod.period.type === 'custom'
              ? moment(prod.period.startDate).format('YYYY-MM-DD') +
                ' ' +
                t('common.to') +
                ' ' +
                moment(prod.period.endDate).format('YYYY-MM-DD')
              : capitalize(t(prod.period.type)),
        },
        status: {
          cellData: prod.status,
        },
        approve: {
          cellData: prod,
        },
        notes: {
          _id: prod._id,
          name: prod.code || '__',
          notes: prod.notes || [],
          handleAddNote: handleAddNote,
          handleEditNote: handleEditNote,
          handleDeleteNote: handleDeleteNote,
          getIsLoadingNotes: () => {
            return false;
          },
          subTitle: t('offers.notes.add_sub_title'),
        },
        __actions: {
          cellData: prod,
          isActive: prod.isActive,
        },
      };
    });
  }

  const getOffersCategories = () => {
    return [
      {
        _id: '',
        products: [],
        name: t('free_anything'),
        key: 'free_anything',
      },
      {
        _id: '',
        products: [],
        name: t('free_items'),
        key: 'free_items',
      },
      {
        _id: '',
        products: [],
        name: t('refund_anything'),
        key: 'refund_anything',
      },
      {
        _id: '',
        products: [],
        name: t('refund_items'),
        key: 'refund_items',
      },
    ];
  };

  const getTitleByLevel = (level: number) => {
    switch (level) {
      case 0:
        return 'category';
      case 1:
        return 'subcategory';
      case 2:
        return 'childcategory';
      default:
        return '';
    }
  };

  const deleteOffer = useCallback(
    async (ids: string[]) => {
      callApi(
        () => {
          removeOffer({ body: { offerIds: ids } }).unwrap();
        },
        {
          confirmationProps: {
            title: t('offers.delete_title'),
            message: t('offers.delete_sub_title'),
          },
          actionText: 'Delete',
          successMessage: t('offers.delete_success'),
          buttonProps: { color: 'error' },
        },
      );
    },
    [callApi],
  );

  const enableDisableOffer = useCallback(
    async (ids: string[], isActive: boolean) => {
      callApi(
        async () => {
          await toggleActiveOffer({
            body: { offerIds: ids, isActive: !isActive },
          }).unwrap();
        },
        {
          confirmationProps: {
            title: isActive
              ? t('offers.disable.title')
              : t('offers.enable.title'),
            message: isActive
              ? t('offers.disable.body')
              : t('offers.enable.body'),
          },
          actionText: isActive ? t('common.disable') : t('common.enable'),
          successMessage: isActive
            ? t('offers.disable.success')
            : t('offers.enable.success'),
          buttonProps: { color: isActive ? 'error' : 'primary' },
        },
      );
    },
    [callApi],
  );

  const approveOffer = useCallback(
    async (ids: string[]) => {
      await callApi(
        async () => {
          statusToaster({
            variant: StatusToastVariant.Loading,
            spacingRight: '0px',
          });
          return await toggleStatusOffer({
            body: { status: 'approved', offerIds: ids },
          }).unwrap();
        },
        {
          onSuccess: () =>
            statusToaster({
              variant: StatusToastVariant.Success,
              message: t('approved'),
              spacingRight: '0px',
            }),
          onError: () =>
            statusToaster({
              variant: StatusToastVariant.Error,
              spacingRight: '0px',
            }),
        },
      );
    },
    [callApi],
  );

  const periods = useMemo(() => {
    return [
      {
        value: CustomerOfferPeriodType.PER_ORDER,
        label: t('common.per_order'),
      },
      {
        value: CustomerOfferPeriodType.MONTHLY,
        label: t('common.monthly'),
      },
      {
        value: CustomerOfferPeriodType.QUARTERLY,
        label: t('common.quarterly'),
      },
      {
        value: CustomerOfferPeriodType.ANNUALLY,
        label: t('common.annually'),
      },
      {
        value: CustomerOfferPeriodType.CUSTOM,
        label: t('common.custom'),
      },
    ];
  }, []);

  const purchaseItems = useMemo<Contract[]>(() => {
    const result: Contract[] = [
      {
        value: CustomerOfferPurchaseConditionType.ANYTHING,
        label: t('common.anything'),
      },
      {
        value: CustomerOfferPurchaseConditionType.CATEGORY,
        label: t('common.category'),
      },
    ];
    if (!!checkLevel(1)) {
      result.push({
        value: CustomerOfferPurchaseConditionType.SUBCATEGORY,
        label: t('subcategory'),
      });
    }
    if (!!checkLevel(2)) {
      result.push({
        value: CustomerOfferPurchaseConditionType.ChildCATEGORY,
        label: t('childcategory'),
      });
    }
    result.push({
      value: 'product',
      label: t('common.product'),
    });
    return result;
  }, [checkLevel]);

  const handleAddNote = useCallback(
    async (_id: string, content: string) => {
      await callApi(async () => {
        return await addOfferNote({
          offerId: _id,
          body: { content: content },
        }).unwrap();
      });
    },
    [callApi],
  );

  const handleEditNote = useCallback(
    async (_id: string, note: string, content: string) => {
      await callApi(async () => {
        return await editOfferNote({
          offerId: _id,
          noteId: note,
          body: {
            content: content,
          },
        }).unwrap();
      });
    },
    [callApi],
  );

  const handleDeleteNote = useCallback(
    async (_id: string, note: string) => {
      await callApi(async () => {
        return await deleteOfferNote({
          offerId: _id,
          noteId: note,
        }).unwrap();
      });
    },
    [callApi],
  );

  return {
    getProcessedProductsFunction,
    getOffersCategories,
    getTitleByLevel,
    deleteOffer,
    enableDisableOffer,
    approveOffer,
    periods,
    purchaseItems,
  };
};
