import React, { useContext } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Autocomplete,
  Stack,
  InputAdornment,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MultiSelectAutocomplete from 'app/components/FilterComponents/components/MultiSelect/components/Autocomplete';
import { useGetCarriersQuery } from 'common/services/carrierApi';
import { selectConfiguration } from 'common/store/organization/selectors';
import { Days } from '../../../ViewSupplier/data/data';
import { RoundtripTable } from './components/RoundtripTable';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import { CallDaysTable } from './components/CallDaysTable';
import styled from 'styled-components';
import TimePickerForm from 'app/components/Form/TimePickerForm';
import FormTextField from 'app/components/Form/TextField';
import { SupplierFormcontext } from '../../../Form/context';

interface DeliverySpecificationsProps {
  control: any;
  setValue: any;
}

export const DeliverySpecifications: React.FC<DeliverySpecificationsProps> = ({
  control,
  setValue,
}) => {
  const { canEdit } = useContext(SupplierFormcontext);
  const { t } = useTranslation();
  const carrierData = useGetCarriersQuery();
  const configuration = useSelector(selectConfiguration);

  const carriers = carrierData.data
    ? carrierData.data.data.map((item) => ({
        label: item.publicName,
        value: item._id,
      }))
    : [];

  const supportUnits =
    configuration?.supportUnitsList?.map((s) => s.name) || [];
  const vehicleConditions = ['PL', 'VL'];

  return (
    <Wrapper gap="24px">
      <FormSection>
        <SectionTitle>
          {t('roundtrips.clients.delivery_specification')}
        </SectionTitle>

        <SectionTitle>{`${t('delivery_type')} *`}</SectionTitle>
        <Grid container spacing={1.5}>
          <Grid item md={4} sm={4} xs={12}>
            <DeliveryTypeCheckboxes control={control} canEdit={canEdit} />
          </Grid>
        </Grid>

        <Grid container spacing={1.5}>
          <Grid item xs={3}>
            <ThirdPartyCollectorCheckbox control={control} canEdit={canEdit} />
          </Grid>

          <Grid item xs={9}>
            <Grid container spacing={1.5} alignItems="center">
              <Grid item xs={6}>
                <CarrierAutocomplete
                  control={control}
                  carriers={carriers}
                  t={t}
                  canEdit={canEdit}
                />
              </Grid>

              <Grid item xs={6}>
                <DeliveryDaysMultiSelect
                  control={control}
                  t={t}
                  canEdit={canEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSection>

      <FormSection>
        <SectionTitle>{t('supplier.field.supportUnitPreferred')}</SectionTitle>
        <Grid container alignItems="center">
          <RequestedSupportCheckboxes
            control={control}
            supportUnits={supportUnits}
            t={t}
            canEdit={canEdit}
          />
        </Grid>
      </FormSection>

      <FormSection>
        <SectionTitle>{t('supplier.field.accessConditions')}</SectionTitle>
        <Grid container alignItems="center">
          <VehicleConditionCheckboxes
            control={control}
            vehicleConditions={vehicleConditions}
            t={t}
            canEdit={canEdit}
          />
        </Grid>
      </FormSection>

      <FormSection>
        <SectionTitle>{t('delivery_days')}</SectionTitle>
        <RoundtripTable control={control} setValue={setValue} />
      </FormSection>

      <FormSection>
        <SectionTitle>{t('call_days')}</SectionTitle>
        <CallDaysTable control={control} setValue={setValue} />
      </FormSection>

      <FormSection>
        <SectionTitle>{t('supplier.field.timeslot')}</SectionTitle>

        <Stack direction="row" gap="12px">
          <FormSection>
            <SectionTitle>{t('supplier.field.availableTimeslot')}</SectionTitle>
            <Stack direction="row" gap="4px">
              <TimePickerForm
                control={control}
                name="availableTimeSlotFrom"
                label={t('common.from')}
                disabled={!canEdit}
              />
              <TimePickerForm
                control={control}
                name="availableTimeSlotTo"
                label={t('common.to')}
                disabled={!canEdit}
              />
            </Stack>
          </FormSection>
          <FormSection>
            <SectionTitle>{t('supplier.field.preferredTimeslot')}</SectionTitle>

            <Stack direction="row" gap="4px">
              <TimePickerForm
                control={control}
                name="preferredTimeSlotFrom"
                label={t('common.from')}
                disabled={!canEdit}
              />
              <TimePickerForm
                control={control}
                name="preferredTimeSlotTo"
                label={t('common.to')}
                disabled={!canEdit}
              />
            </Stack>
          </FormSection>
        </Stack>
      </FormSection>

      <FormSection>
        <SectionTitle>{t('supplier.field.minimumToOrder')}</SectionTitle>
        <Stack direction="row" gap="12px">
          <FormTextField
            control={control}
            name="minOrderWeight"
            type="number"
            label={t('supplier.field.minimumWeight')}
            disabled={!canEdit}
            inputProps={{
              disabled: !canEdit,
              endAdornment: (
                <InputAdornment position="end">
                  <p>Kg</p>
                </InputAdornment>
              ),
            }}
            num
          />
          <FormTextField
            control={control}
            name="minOrderPrice"
            type="number"
            label={t('supplier.field.minimumPrice')}
            disabled={!canEdit}
            inputProps={{
              disabled: !canEdit,
              endAdornment: (
                <InputAdornment position="end">
                  <p>EUR</p>
                </InputAdornment>
              ),
            }}
            num
          />
          <FormTextField
            control={control}
            name="minOrderBoxes"
            type="number"
            label={t('supplier.field.minimumBoxes')}
            disabled={!canEdit}
            inputProps={{
              disabled: !canEdit,
              endAdornment: (
                <InputAdornment position="end">
                  <p>Boxes</p>
                </InputAdornment>
              ),
            }}
            num
          />
        </Stack>
      </FormSection>
    </Wrapper>
  );
};

// Separate components for each section
const DeliveryTypeCheckboxes = ({ control, canEdit }) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="deliveryTypes"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={(field.value || []).includes('collection')}
                onChange={() => {
                  const currentValues = field.value || [];
                  const newValues = currentValues.includes('collection')
                    ? currentValues.filter((v: string) => v !== 'collection')
                    : [...currentValues, 'collection'];
                  field.onChange(newValues);
                }}
                disabled={!canEdit}
              />
            }
            label={t('common.collection')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={(field.value || []).includes('delivery')}
                onChange={() => {
                  const currentValues = field.value || [];
                  const newValues = currentValues.includes('delivery')
                    ? currentValues.filter((v: string) => v !== 'delivery')
                    : [...currentValues, 'delivery'];
                  field.onChange(newValues);
                }}
                disabled={!canEdit}
              />
            }
            label={t('delivery')}
          />
        </>
      )}
    />
  );
};

const ThirdPartyCollectorCheckbox = ({ control, canEdit }) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="thirdPartyCollector"
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={Boolean(field.value)}
              onChange={(e) => field.onChange(e.target.checked)}
              disabled={!canEdit}
            />
          }
          label={t('supplier.addBranch.field.thirdPartyCollector')}
        />
      )}
    />
  );
};

const CarrierAutocomplete = ({ control, carriers, t, canEdit }) => {
  return (
    <Controller
      name="carrier"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={carriers}
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              name="carrier"
              label={t('supply_units.carrier')}
              size="small"
              placeholder={t('Select Carrier')}
              variant="outlined"
              disabled={!canEdit}
            />
          )}
          onChange={(event, newValue) => onChange(newValue)}
          disabled={!control._getWatch()?.thirdPartyCollector || !canEdit}
        />
      )}
    />
  );
};

const DeliveryDaysMultiSelect = ({ control, t, canEdit }) => {
  return (
    <Controller
      name="deliveryDays"
      control={control}
      render={({ field: { onChange, value } }) => (
        <MultiSelectAutocomplete
          filterItems={Days}
          selectedOptions={value}
          placeholder={t('supplier.addBranch.fields.collectionDays')}
          handleFilterChange={onChange}
          textFieldProps={{
            disabled: !control._getWatch()?.thirdPartyCollector || !canEdit,
          }}
          autoCompleteProps={{
            disabled: !control._getWatch()?.thirdPartyCollector || !canEdit,
          }}
        />
      )}
    />
  );
};

const RequestedSupportCheckboxes = ({ control, supportUnits, t, canEdit }) => {
  return (
    <Controller
      name="requestedSupport"
      control={control}
      render={({ field }) => (
        <>
          {supportUnits?.map((support) => (
            <FormControlLabel
              key={support}
              control={
                <Checkbox
                  checked={(field.value || []).includes(support)}
                  onChange={() => {
                    const currentValues = field.value || [];
                    const newValues = currentValues.includes(support)
                      ? currentValues.filter((v: string) => v !== support)
                      : [...currentValues, support];
                    field.onChange(newValues);
                  }}
                  disabled={!canEdit}
                />
              }
              label={t(support)}
            />
          ))}
        </>
      )}
    />
  );
};

const VehicleConditionCheckboxes = ({
  control,
  vehicleConditions,
  t,
  canEdit,
}) => {
  return (
    <Controller
      name="vehicleCondition"
      control={control}
      render={({ field }) => (
        <>
          {vehicleConditions?.map((condition) => (
            <FormControlLabel
              key={condition}
              control={
                <Checkbox
                  checked={(field.value || []).includes(condition)}
                  onChange={() => {
                    const currentValues = field.value || [];
                    const newValues = currentValues.includes(condition)
                      ? currentValues.filter((v: string) => v !== condition)
                      : [...currentValues, condition];
                    field.onChange(newValues);
                  }}
                  disabled={!canEdit}
                />
              }
              label={t(condition)}
            />
          ))}
        </>
      )}
    />
  );
};

const Wrapper = styled(Stack)`
  span {
    font-size: 0.875rem !important;
  }
`;
