import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';
import { DOCUMENT_STATUSES } from '../../../../../../../pages/Settings/UserInfo/components/UserTabs/DocumentTab/documentStatuses';
import styled from 'styled-components';
import { Check } from '@phosphor-icons/react';
import { DocumentsKeys } from 'common/utils/enum';
import { useTheme } from 'common/hooks/useTheme';
import If from 'app/components/If';
import { useGetStatusesUserDocumentsQuery } from 'common/services/userDocumentsApi';

interface OptionType {
  type: string;
  status: any;
}

interface Props {
  setSelectedType: Function;
  setValue: Function;
  resubmitType?: string;
  getValues: Function;
  facets: { statuses: any };
  disabled?: boolean;
  userId: string;
}

const DocumentTypeSelector = ({
  setSelectedType,
  setValue,
  resubmitType,
  getValues,
  facets,
  disabled = false,
  userId,
}: Props) => {
  const theme = useTheme();
  const [hoveredOption, setHoveredOption] = React.useState<string | null>(null);

  const facetsData: OptionType[] = React.useMemo(() => {
    if (facets) {
      return Object.entries(facets?.statuses)?.map(([type, status]) => ({
        type,
        status: type === DocumentsKeys.OTHER ? '' : status,
      }));
    }
    return [];
  }, [facets]);

  React.useEffect(() => {
    if (resubmitType && facetsData.length > 0) {
      const resubmitOption = facetsData.find(
        (option) => option.type === resubmitType,
      );
      if (
        resubmitOption &&
        ![DOCUMENT_STATUSES.APPROVED, DOCUMENT_STATUSES.PENDING].includes(
          resubmitOption.status as DOCUMENT_STATUSES,
        ) &&
        resubmitType !== DocumentsKeys.OTHER
      ) {
        setSelectedType(resubmitType);
        setValue('documentType', resubmitType);
      }
    }
  }, [resubmitType, facetsData]);

  const { data: statusesFacets } = useGetStatusesUserDocumentsQuery(
    `${userId}`,
  );

  return (
    <Autocomplete
      options={facetsData}
      disabled={disabled}
      key={getValues('documentType')}
      getOptionLabel={(option: OptionType) => t(option.type)}
      renderOption={(props, option) => {
        const opt =
          statusesFacets?.filter(
            (item) =>
              item.type === option.type &&
              item.type !== DocumentsKeys.OTHER &&
              item.status !== DOCUMENT_STATUSES.REJECTED,
          )[0] || {};
        const isHovered = hoveredOption === option.type;
        const isOther = option.type === DocumentsKeys.OTHER;
        const isDisabled = statusesFacets?.some(
          (item) =>
            item.type === option.type &&
            item.type !== DocumentsKeys.OTHER &&
            item.status !== DOCUMENT_STATUSES.REJECTED,
        );
        return (
          <OptionItem
            {...props}
            selected={getValues('documentType') === option.type}
            isHovered={isHovered}
            isOther={isOther}
            theme={theme}
            onMouseEnter={() => setHoveredOption(option.type)}
            onMouseLeave={() => setHoveredOption(null)}
            style={{ opacity: 1 }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                height: 40,
              }}
            >
              <span style={{ opacity: isDisabled ? 0.5 : 1 }}>
                {String(t(option.type))}
              </span>
              <If condition={opt}>
                <div
                  style={{
                    backgroundColor: `${
                      option.status === DOCUMENT_STATUSES.APPROVED
                        ? theme.statusApprovedColor.background
                        : option.status === DOCUMENT_STATUSES.REJECTED
                          ? theme.statusRejectedColor.background
                          : option.status === DOCUMENT_STATUSES.PENDING
                            ? theme.statusPendingColor.background
                            : opt.status === DOCUMENT_STATUSES.APPROVED
                              ? theme.statusApprovedColor.background
                              : opt.status === DOCUMENT_STATUSES.REJECTED
                                ? theme.statusRejectedColor.background
                                : theme.statusPendingColor.background
                    }`,
                    color: `${
                      option.status === DOCUMENT_STATUSES.APPROVED
                        ? theme.statusApprovedColor.color
                        : option.status === DOCUMENT_STATUSES.REJECTED
                          ? theme.statusRejectedColor.color
                          : option.status === DOCUMENT_STATUSES.PENDING
                            ? theme.statusPendingColor.color
                            : opt.status === DOCUMENT_STATUSES.APPROVED
                              ? theme.statusApprovedColor.color
                              : opt.status === DOCUMENT_STATUSES.REJECTED
                                ? theme.statusRejectedColor.color
                                : theme.statusPendingColor.color
                    }`,
                    width: '63px',
                    borderRadius: '100px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: '24px',
                    fontSize: '10px',
                    zIndex: '1',
                  }}
                >
                  {opt.status}
                </div>
              </If>
              <If
                condition={
                  getValues('documentType') === option.type || isHovered
                }
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 24,
                    width: 24,
                    backgroundColor: theme.primaryLight1,
                    borderRadius: '50%',
                  }}
                >
                  <Check size={12} color={theme.primary} />
                </div>
              </If>
            </div>
          </OptionItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={String(t('select_document_type'))}
          variant="outlined"
          required
          placeholder="Select Type"
        />
      )}
      onChange={(event, newValue) => {
        if (
          newValue &&
          newValue.status !== DOCUMENT_STATUSES.APPROVED &&
          newValue.status !== DOCUMENT_STATUSES.PENDING
        ) {
          setSelectedType(newValue.type || '');
          setValue('documentType', newValue.type || '');
        }
      }}
      getOptionDisabled={(option) =>
        statusesFacets?.some(
          (item) =>
            item.type === option.type &&
            item.type !== 'otherDocuments' &&
            item.status !== DOCUMENT_STATUSES.REJECTED,
        )
      }
      value={facetsData.find(
        (option) => option.type === getValues('documentType'),
      )}
      fullWidth
    />
  );
};

interface OptionItemProps {
  selected: boolean;
  isHovered: boolean;
  isOther: boolean;
}

const OptionItem = styled.li<OptionItemProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  justify-content: center;
  padding: 8px 16px;
  color: ${({ selected, theme }) => (selected ? theme.primary : '')};
  border-top: ${({ isOther }) => (isOther ? '1px solid #ccc' : 'none')};
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export default DocumentTypeSelector;
