import {
  Popover,
  Grid,
  Switch,
  Typography,
  ButtonBase,
  SxProps,
  Stack,
} from '@mui/material';
import _without from 'lodash/without';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { Heart, Star, Tag, X, Flag } from '@phosphor-icons/react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import ValueSlider from 'app/components/FilterComponents/components/ValueSlider';
import { handleSelectFilterChange } from 'app/helpers/helpers';
import { StateSetter } from 'types';

export interface IOrderFilter {
  favorite: boolean;
  hasDiscount: boolean;
  new: boolean;
  frequentlyOrdered: boolean;
  categories: { value: string; label: string }[];
  price: { minPrice: number; maxPrice: number };
}

interface Props {
  anchorEl: HTMLButtonElement | null;
  closeModal: () => void;
  filters: IOrderFilter;
  setFilters: StateSetter<IOrderFilter>;
  categories: string[];
  categoriesData?: { value: string; label: string }[];
}

export function Filter({
  anchorEl,
  closeModal,
  filters,
  setFilters,
  categories,
  categoriesData,
}: Props) {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const categoriesArray: string[] = Array.from(categories);

  const handleOptionsChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    handleSelectFilterChange(filterKey, options, setFilters);
  };

  function handleClearFilter() {
    setFilters({
      favorite: false,
      hasDiscount: false,
      new: false,
      frequentlyOrdered: false,
      categories: [],
      price: { minPrice: 0, maxPrice: 0 },
    });
  }

  const toggleFavorite = () => {
    setFilters((prevState) => ({
      ...prevState,
      favorite: !prevState.favorite,
    }));
  };

  const toggleHasDiscount = () => {
    setFilters((prevState) => ({
      ...prevState,
      hasDiscount: !prevState.hasDiscount,
    }));
  };

  const toggleNew = () => {
    setFilters((prevState) => ({
      ...prevState,
      new: !prevState.new,
    }));
  };

  const toggleFrequentlyOrdered = () => {
    setFilters((prevState) => ({
      ...prevState,
      frequentlyOrdered: !prevState.frequentlyOrdered,
    }));
  };

  const handlePriceSliderChange = (sliderValue: number[]) => {
    setFilters((prevState) => ({
      ...prevState,
      price: {
        minPrice: sliderValue?.[0] || 0,
        maxPrice: sliderValue?.[1] || 500,
      },
    }));
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={closeModal}
      slotProps={{
        paper: {
          sx: {
            padding: '16px',
            borderRadius: '4px',
            minWidth: '383px',
          },
        },
      }}
    >
      <Stack gap="8px">
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography
            fontSize="0.875rem"
            lineHeight="1.5rem"
            fontWeight="500"
            letterSpacing="0.4px"
            color={themes.default.clientMarkerBg}
          >
            {t('common.filter_by')}
          </Typography>
          <ButtonBase sx={CloseButtonSx} onClick={handleClearFilter}>
            <Icon icon={<X />} color={themes.default.clientMarkerBg} />
            <Typography
              fontSize="0.875rem"
              lineHeight="1.5rem"
              fontWeight="500"
              letterSpacing="0.4px"
              color={themes.default.clientMarkerBg}
            >
              {t('common.buttons.clear_all')}
            </Typography>
          </ButtonBase>
        </Grid>
        <Stack gap="16px">
          <FilterItem onClick={toggleFavorite}>
            <FilterItemTitle>
              <Icon
                icon={
                  <Heart
                    weight={filters.favorite ? 'fill' : 'regular'}
                    size={24}
                  />
                }
                color={filters.favorite ? theme.primary : ''}
              />
              <FilterTitleText text={t('order.filter.options.favorites')} />
            </FilterItemTitle>
            <Switch checked={filters.favorite} size="small" />
          </FilterItem>
          <FilterItem onClick={toggleHasDiscount}>
            <FilterItemTitle>
              <Icon
                icon={
                  <Tag
                    weight={filters.hasDiscount ? 'fill' : 'regular'}
                    size={24}
                  />
                }
                color={filters.hasDiscount ? theme.primary : ''}
              />
              <FilterTitleText text={t('orders.has_discount')} />
            </FilterItemTitle>
            <Switch checked={filters.hasDiscount} size="small" />
          </FilterItem>
          <FilterItem onClick={toggleNew}>
            <FilterItemTitle>
              <Icon
                icon={
                  <Star weight={filters.new ? 'fill' : 'regular'} size={24} />
                }
                color={filters.new ? theme.primary : ''}
              />
              <FilterTitleText text={t('order.filter.options.items')} />
            </FilterItemTitle>
            <Switch checked={filters.new} size="small" />
          </FilterItem>
          <FilterItem onClick={toggleFrequentlyOrdered}>
            <FilterItemTitle>
              <Icon
                icon={
                  <Flag
                    weight={filters.frequentlyOrdered ? 'fill' : 'regular'}
                    size={24}
                  />
                }
                color={filters.frequentlyOrdered ? theme.primary : ''}
              />
              <FilterTitleText
                text={t('order.filter.options.frequentlyOrdered')}
              />
            </FilterItemTitle>
            <Switch checked={filters.frequentlyOrdered} size="small" />
          </FilterItem>
          <CollapsableSectionContainer>
            <MultiSelect
              filterName={t('order.filter.options.productCat')}
              placeholder={t('label.select_categories')}
              selectedOptions={filters.categories}
              handleFilterChange={(options) => {
                handleOptionsChange('categories', options);
              }}
              filterItems={
                !!categoriesData?.length
                  ? categoriesData?.map((category) => {
                      return {
                        value: category.value,
                        label: category.label,
                      };
                    })
                  : categoriesArray?.map((category) => {
                      return {
                        value: category,
                        label: category,
                      };
                    })
              }
              autoCompleteProps={{
                sx: {
                  '.MuiFormControl-root': {
                    margin: '0px !important',
                  },
                },
              }}
            />
          </CollapsableSectionContainer>
          <CollapsableSectionContainer>
            <ValueSlider
              sliderValue={[filters.price?.minPrice, filters.price?.maxPrice]}
              filterName={t('order.filter.price')}
              marks={[
                { value: 0, label: '0' },
                { value: 500, label: '500' },
              ]}
              handleFilterChange={handlePriceSliderChange}
              minQuantity={0}
              maxQuantity={500}
            />
          </CollapsableSectionContainer>
        </Stack>
      </Stack>
    </Popover>
  );
}

const CloseButtonSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
};

const FilterItem = styled.div`
  width: 100%;
  padding: 6px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  cursor: pointer;
`;

const CollapsableSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title_action {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const FilterItemTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FilterTitleText = ({ text }: { text: string }) => {
  return (
    <Typography
      fontSize="0.875rem"
      lineHeight="1.5rem"
      letterSpacing="0.17px"
      color={themes.default.textBlack}
    >
      {text}
    </Typography>
  );
};
