import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ClientOrderMarker } from './components/ClientOrderMarker';
import {
  useGetRoundTripsClusterQuery,
  useLazyGetDriverLocationsQuery,
  useGetWarehouseInfoQuery,
  useGetMapMarkersDataQuery,
} from 'common/services/roundtripApi';
import { useSelector } from 'react-redux';
import { MapWrapper } from '../styles/map';
import { MarkerStep, MarkerVehicle } from 'app/components/MapMarkers';
import { useSubscribeObject } from 'common/hooks/ws';
import { defaultMapStyle } from '../../../../../styles/mapStyles';
import { centroidLabel } from '../../../../../utils/geo';
import { getMostRecentItem } from '../../../../helpers/helpers';
import { LeftMenu } from './components/LeftMenu';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { filterRoundtripBySearch } from 'app/helpers/roundtripHelper';
import { useTranslation } from 'react-i18next';
import { StateSetter } from 'types';
import If from 'app/components/If';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import { useGetClientsOrdersQuery } from 'common/services/roundtripApi';
import { themes } from 'styles/theme/themes';
import styled from 'styled-components';
import { CustomTooltip } from './components/CustomTooltip';
import AssignedRoundtripsPopover from './components/AssigneClientOrderPopover';
import { PolygonOptionsMenu } from './components/PolygonOptionsMenu';
import { AddClientOrderPopover } from './components/AddClientOrderPopover';
import { RoundtripsType } from '../../data/types';
import { WeatherTag } from './components/WeatherTag';
import { useDebounce } from 'common/hooks/useDebounce';
import { MapActionButton } from './components/MapActionButton';
import { MapZoomButton } from './components/MapZoomButton';
import IsolateRoundtripIcon from 'assets/img/map/buttons/IsolateRoundtrip.svg';
import IsolateRoundtripActive from 'assets/img/map/buttons/IsolateRoundtripActive.svg';
import EyeSlash from 'assets/img/map/buttons/EyeSlash.svg';
import EyeSlashActive from 'assets/img/map/buttons/EyeSlashActive.svg';
import TrackTruck from 'assets/img/map/buttons/TrackTruck.svg';
import TrackTruckActive from 'assets/img/map/buttons/TrackTruckActive.svg';
import ShowStatus from 'assets/img/map/buttons/ShowStatus.svg';
import ShowStatusActive from 'assets/img/map/buttons/ShowStatusActive.svg';
import PolylinePoints from 'assets/img/map/marker/PolylinePoints.svg';
import PolylineArrow from 'assets/img/map/marker/PolylineArrow.svg';
import UnlinkWarehouse from 'assets/img/map/buttons/UnlinkWarehouse.svg';
import ShowTrucks from 'assets/img/map/buttons/ShowTrucks.svg';
import HideTrucks from 'assets/img/map/buttons/HideTrucks.svg';
import LinkWarehouse from 'assets/img/map/buttons/LinkWarehouse.svg';
import RouteIcon from 'assets/img/map/buttons/Route.svg';
import RouteActiveIcon from 'assets/img/map/buttons/RouteActive.svg';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';
import { FilterChips } from 'app/components/FilterChips';
import { bindShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/functions';
import dayjs from 'dayjs';
import {
  Map as MapComponent,
  useMap,
  AdvancedMarker,
} from '@vis.gl/react-google-maps';
import { PolygonComponent } from './components/PolygonComponent';
import { PolylineComponent } from './components/PolylineComponent';
import { CircleComponent } from './components/CircleComponent';
import { RectangleComponent } from './components/RectangleComponent';
import { ENV } from 'common/constants';
import LightSwitch from 'assets/sounds/EditRoundtrip/LightSwitch.webm';
import { functionRoundTripStatus } from 'app/components/RoundTrips/function/ApiFunctions';
import { VehicleData } from 'app/pages/Vehicles/types';
import RoundtripDirections from './components/RoundtripDirections';

export type MapConfig = {
  id: string;
  label: string;
  mapId?: string;
  mapTypeId?: string;
  styles?: google.maps.MapTypeStyle[];
};

const MAP_CONFIG: MapConfig = {
  id: 'light',
  label: 'Light',
  styles: defaultMapStyle,
};

const calculateBearing = (point1, point2) => {
  const lat1 = (point1.lat * Math.PI) / 180;
  const lon1 = (point1.lng * Math.PI) / 180;
  const lat2 = (point2.lat * Math.PI) / 180;
  const lon2 = (point2.lng * Math.PI) / 180;

  const dLon = lon2 - lon1;
  const y = Math.sin(dLon) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

  let brng = Math.atan2(y, x);
  brng = ((brng * 180) / Math.PI + 360) % 360;

  return brng;
};

interface ClusterMapQueryDto {
  noStops?: boolean;
  noRipper?: boolean;
  noDriver?: boolean;
  noSales?: boolean;
  noVehicle?: boolean;
  unlinkWarehouse: boolean;
  begin: string;
  end: string;
  sort?: string;
  text?: string;
  weight?: number;
  status?: any;
  orderType?: any;
  orderStatus?: any;
  orderPriority?: any;
  customerStatus?: any;
  vehicleCondition?: string;
  clusterId: string;
  _deck: string;
  limit: number; // limit for init orders ( unassigned orders )
  application: string;
  page: number;
  agentCategory: string;
}

interface Props {
  clusterId: string;
  defaultLocation?: any;
  unassignedOrders?: any;
  onDisplayOrder?: any;
  accordionStatus?: any;
  setAccordionStatus?: any;
  roundTripCopy?: any;
  requestOrderData?: any;
  orders?: any;
  setOrders?: any;
  setOrderInitCount?: any;
  orderInitCount?: any;
  roundTripsCount?: any;
  updateRoundtripStatus?: Function;
  userContext?: AgentCategories;
  handleClickOpen?: () => void;
  handleClickOpenDelete?: () => void;
  viewMode?: boolean;
  search?: string;
  setAddModalOpen?: Function;
  LegendComponent?: JSX.Element;
  setRoundtripEdit?: StateSetter<any>;
  date: string;
  filters?: any;
  setFilters?: any;
  setStopsToAssign?: (value) => void;
  setFullStopsToAssign?: StateSetter<any[]>;
  clearSelectionCount?: number;
  handleClearSelection?: Function;
}

export const Map: React.FC<Props> = ({
  clusterId,
  defaultLocation,
  unassignedOrders,
  onDisplayOrder,
  accordionStatus,
  setAccordionStatus,
  roundTripCopy,
  requestOrderData,
  orders,
  setOrders,
  setOrderInitCount,
  orderInitCount,
  roundTripsCount,
  updateRoundtripStatus = () => {},
  userContext = AgentCategories.Logistic,
  handleClickOpenDelete = () => {},
  handleClickOpen = () => {},
  viewMode,
  search = '',
  setAddModalOpen,
  LegendComponent,
  setRoundtripEdit = () => {},
  date,
  filters,
  setFilters,
  setStopsToAssign = () => {},
  setFullStopsToAssign = () => {},
  clearSelectionCount = 0,
  handleClearSelection = () => {},
}) => {
  const { t } = useTranslation();

  const map = useMap(ENV.REACT_APP_GOOGLE_MAP_ID);
  // const clusterer = useMemo(() => {
  //   if (!map) return null;
  //   return new MarkerClusterer({ map });
  // }, [map]);

  // useEffect(() => {
  //   if (!clusterer) return;

  //   clusterer.clearMarkers();
  //   clusterer.addMarkers(Object.values(mapMarkers));
  // }, [clusterer, mapMarkers]);

  // const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
  //   setMapMarkers(markers => {
  //     if ((marker && markers[key]) || (!marker && !markers[key]))
  //       return markers;

  //     if (marker) {
  //       return { ...markers, [key]: marker };
  //     } else {
  //       const { [key]: _, ...newMarkers } = markers;

  //       return newMarkers;
  //     }
  //   });
  // }, []);

  const [selectedRoundtrips, setSelectedRoundtrips] = useState<string[]>([]);
  const [unlinkWarehouse, setUnlinkWarehouse] = useState(false);
  const [hideTrucks, setHideTrucks] = useState(true);
  const [center, setCenter] = useState({
    lat: defaultLocation?.[1] || 48,
    lng: defaultLocation?.[0] || 2,
  });
  const [zoom, setZoom] = useState(11);
  const [mapDraggable, setMapDraggable] = useState(false);
  const [spaceKeyPressed, setSpaceKeyPressed] = useState(false);
  const [scrollWheelPressed, setScrollWheelPressed] = useState(false);
  const [hKeyToggled, setHKeyToggled] = useState(false);
  const [upKeyToggled, setUpKeyToggled] = useState(false);
  const [leftMenuCollapsed, setLeftMenuCollapsed] = useState(true);
  const [displaySelectedPolygons, setDisplaySelectedPolygons] = useState(true);
  const [displaySelectedRoute, setDisplaySelectedRoute] = useState(true);

  const wsMsg = useSubscribeObject('roundtrip-cluster', clusterId);
  const wsDateMessage = useSubscribeObject('roundtrip-cluster-date', date);

  const zoomedIn = useSelector(selectIsZoomedIn);

  const debouncedFilters = useDebounce(filters, 500);

  const commonQueryParams = {
    device: 'web',
  };

  const rtQueryParams = useQueryParams({
    ...commonQueryParams,
    assigned: debouncedFilters?.assigned
      ? debouncedFilters?.assigned
          ?.map((filter) => {
            return filter.value;
          })
          ?.join(',')
      : '',
    orderPriority: debouncedFilters?.priority
      ? debouncedFilters?.priority
          ?.map((filter) => {
            return filter.value;
          })
          ?.join(',')
      : '',
    orderStatus: debouncedFilters?.orderStatus
      ? debouncedFilters?.orderStatus
          ?.map((filter) => {
            return filter.value;
          })
          ?.join(',')
      : '',
    customerStatus: debouncedFilters?.clientStatus
      ? debouncedFilters?.clientStatus
          ?.map((filter) => {
            return filter.value;
          })
          ?.join(',')
      : '',
    orderType: debouncedFilters?.deliveryType
      ? debouncedFilters?.deliveryType
          ?.map((filter) => {
            return filter.value;
          })
          ?.join(',')
      : '',
    vehicleCondition: debouncedFilters?.truckType
      ? debouncedFilters?.truckType
          ?.map((filter) => {
            return filter.value;
          })
          ?.join(',')
      : '',
  });

  const {
    data: clusterData,
    refetch,
    isLoading: isloadindCluster,
  } = useGetRoundTripsClusterQuery(`/${clusterId}${rtQueryParams}`, {
    skip: !clusterId,
  });

  const commonMapDataQueryParams: ClusterMapQueryDto = {
    begin: dayjs()?.format('YYYY-MM-DD'),
    end: dayjs()?.format('YYYY-MM-DD'),
    unlinkWarehouse: unlinkWarehouse,
    clusterId: clusterId,
    _deck: clusterData?._deck?._id,
    limit: 100,
    application: 'bianta',
    page: 1,
    agentCategory: 'logistic',
    sort: 'deliveryDate',
  };

  const MapDataQueryParams = useQueryParams({
    ...commonMapDataQueryParams,
  });

  const { data: mapData, refetch: refetchMapData } = useGetMapMarkersDataQuery(
    { id: clusterId, params: MapDataQueryParams },
    {
      skip: !clusterId || !clusterData?._deck?._id,
    },
  );

  useEffect(() => {
    if (!clusterId || !clusterData?._deck?._id) return;
    refetchMapData();
  }, [unlinkWarehouse]);

  const { data: warehouseData_, refetch: refetchWarehouseInfo } =
    useGetWarehouseInfoQuery(
      { id: clusterId },
      {
        skip: !clusterId,
      },
    );

  const [warehouseData, setWarehouseData] = useState(warehouseData_);

  useEffect(() => {
    setWarehouseData(warehouseData_);
  }, [warehouseData_]);

  const [getDriverLocations, { data: driverLocations }] =
    useLazyGetDriverLocationsQuery();

  useEffect(() => {
    if (wsMsg && clusterId) {
      refetch();
    }
  }, [wsMsg]);

  useEffect(() => {
    if (wsDateMessage && clusterId) {
      refetch();
    }
  }, [wsDateMessage]);

  const roundtrips = useMemo(() => {
    return clusterData?.roundtrips || [];
  }, [clusterData]);

  useEffect(() => {
    if (clusterId && clusterData?._deck?._id) {
      refetch();
      refetchWarehouseInfo();
      refetchMapData();
    }
  }, [clusterId]);

  const ClientsOrdersQuery = useQueryParams({
    limit: '1000',
    _deck: clusterData?._deck?._id,
    clusterId: clusterId,
    application: 'bianta',
    page: 1,
  });
  const { data: clientsOrders, refetch: refetchClientsOrders } =
    useGetClientsOrdersQuery(
      { query: ClientsOrdersQuery },
      { skip: !clusterData?._deck?._id },
    );

  useEffect(() => {
    if (!clientsOrders && clusterData?._deck?._id) {
      refetchClientsOrders();
    }
  }, [clientsOrders]);

  const [showOrderStatusActive, setShowOrderStatusActive] = useState(false);

  const markers = useMemo(() => {
    const m: any = {
      vehicles: [],
      routes: [],
      clientsOrders: [],
      unAssignedRoundtrips: {
        center: {
          lng: clusterData?._deck?.location?.geometry?.coordinates[0],
          lat: clusterData?._deck?.location?.geometry?.coordinates[1],
        },
        roundtrips: [],
      },
    };

    if (clusterData?.roundtrips) {
      clusterData?.roundtrips?.forEach((roundtrip) => {
        const polygonPath: any = [];
        const canceledPolygonPath: any = [];
        const lastPosition: any =
          roundtrip.status === 'parked'
            ? roundtrip._vehicle?.lastPosition
            : getMostRecentItem(
                [roundtrip._vehicle?.lastPosition, roundtrip.lastAgentPosition],
                'date',
              );

        if (lastPosition?.geometry) {
          m.vehicles.push({
            roundtripId: roundtrip._id,
            numberPlate: roundtrip._vehicle?.numberPlate,
            lastPosition,
            roundtrip,
          });
        }

        // Start place
        if (roundtrip._startPlace && !unlinkWarehouse) {
          polygonPath.push({
            type: 'start',
            lat: roundtrip._startPlace.location.geometry.coordinates[1],
            lng: roundtrip._startPlace.location.geometry.coordinates[0],
          });
        } else if (roundtrip._startPlace && roundtrip?.stops?.length === 0) {
          polygonPath.push({
            type: 'start',
            lat: roundtrip._startPlace.location.geometry.coordinates[1],
            lng: roundtrip._startPlace.location.geometry.coordinates[0],
          });
        }

        if (!roundtrip.stops?.length) {
          m.unAssignedRoundtrips.roundtrips.push(roundtrip);
        }

        roundtrip.stops.forEach((stop, index) => {
          if (stop?._place?.location?.geometry?.coordinates) {
            polygonPath.push({
              type: stop?._order?.status !== 'canceled' ? 'canceled' : '',
              lat: stop?._place.location.geometry.coordinates[1],
              lng: stop?._place.location.geometry.coordinates[0],
            });
          }

          if (
            stop?._place?.location?.geometry?.coordinates &&
            stop?._order?.status?.toLowerCase() === 'canceled'
          ) {
            canceledPolygonPath.push([
              // stop before the canceled
              {
                lat: unlinkWarehouse
                  ? roundtrip.stops[
                      index === 0
                        ? roundtrip.stops?.length - 1
                        : index === 1
                          ? 0
                          : index - 1
                    ]?._place.location.geometry.coordinates[1]
                  : roundtrip.stops[index - 1]?._place.location.geometry
                      .coordinates[1] ||
                    roundtrip._startPlace.location.geometry.coordinates[1],
                lng: unlinkWarehouse
                  ? roundtrip.stops[
                      index === 0
                        ? roundtrip.stops?.length - 1
                        : index === 1
                          ? 0
                          : index - 1
                    ]?._place.location.geometry.coordinates[0]
                  : roundtrip.stops[index - 1]?._place.location.geometry
                      .coordinates[0] ||
                    roundtrip._startPlace.location.geometry.coordinates[0],
              },
              // canceled stop
              {
                lat: stop?._place.location.geometry.coordinates[1],
                lng: stop?._place.location.geometry.coordinates[0],
              },
              // stop after the canceled
              {
                lat: unlinkWarehouse
                  ? roundtrip.stops[
                      index === 0
                        ? 1
                        : roundtrip.stops?.length - 1 === index
                          ? 0
                          : index + 1
                    ]?._place.location.geometry.coordinates[1]
                  : roundtrip.stops[index + 1]?._place.location.geometry
                      .coordinates[1] ||
                    roundtrip._endPlace.location.geometry.coordinates[1],
                lng: unlinkWarehouse
                  ? roundtrip.stops[
                      index === 0
                        ? 1
                        : roundtrip.stops?.length - 1 === index
                          ? 0
                          : index + 1
                    ]?._place.location.geometry.coordinates[0]
                  : roundtrip.stops[index + 1]?._place.location.geometry
                      .coordinates[0] ||
                    roundtrip._endPlace.location.geometry.coordinates[0],
              },
            ]);
          }
        });

        if (roundtrip._endPlace && !unlinkWarehouse) {
          polygonPath.push({
            type: 'end',
            lat: roundtrip._endPlace.location.geometry.coordinates[1],
            lng: roundtrip._endPlace.location.geometry.coordinates[0],
          });
        } else if (roundtrip._endPlace && roundtrip?.stops?.length === 0) {
          polygonPath.push({
            type: 'start',
            lat: roundtrip._endPlace.location.geometry.coordinates[1],
            lng: roundtrip._endPlace.location.geometry.coordinates[0],
          });
        }

        m.routes.push({
          options: {
            fillColor: '#9E9E9E',
            fillOpacity: 0.2,
            strokeColor: '#9E9E9E',
            strokeOpacity: 1,
            strokeWeight: 1,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
          },
          center: centroidLabel(polygonPath, false, false),
          paths: polygonPath,
          canceledPaths: canceledPolygonPath,
          code: roundtrip.code,
          roundtripId: roundtrip._id,
        });
      });

      if (clientsOrders?.docs) {
        clientsOrders?.docs?.forEach((client) => {
          m.clientsOrders.push({
            clientId: client?._id,
            position: client?._deck?.location?.geometry?.coordinates || [],
            inSrts: false,
          });
        });
      }
    }
    return m;
  }, [
    clusterData?.roundtrips,
    clientsOrders,
    selectedRoundtrips,
    unlinkWarehouse,
  ]);

  const circleRadius = useMemo(() => {
    const zoomFactor = zoom - 11;
    return 1000 / Math.pow(2, zoomFactor);
  }, [zoom]);

  const circleOptions = {
    strokeColor: 'rgba(0,0,0,0)',
    fillColor: themes.default.primaryActiveColor,
    fillOpacity: 0.4,
    zIndex: 1,
    radius: circleRadius,
  };

  const handleSelectRoundtrip = useCallback(
    (roundtripId: string) => {
      if (selectedRoundtrips.includes(roundtripId)) {
        setSelectedRoundtrips(
          selectedRoundtrips.filter((id) => id !== roundtripId),
        );
      } else {
        setSelectedRoundtrips([...selectedRoundtrips, roundtripId]);

        const findVehicle = markers.vehicles.find(
          (v) => v.roundtripId === roundtripId,
        );

        if (findVehicle) {
          setCenter({
            lat: findVehicle.lastPosition.geometry.coordinates[1],
            lng: findVehicle.lastPosition.geometry.coordinates[0],
          });
        }
      }
    },
    [selectedRoundtrips, markers.vehicles],
  );

  const [selectedVehicle, setSelectedVehicle] = useState<VehicleData | null>(
    null,
  );

  const handleClickVehicle = useCallback(
    (vehicle: any) => {
      if (!selectedVehicle || vehicle?._id !== selectedVehicle?._id) {
        let driverName: string = '';
        if (vehicle?.roundtrip?.agents?.length > 0) {
          const agents = vehicle?.roundtrip?.agents?.filter(
            (agent) => !!agent?._user?.fullName,
          );
          if (agents?.length > 0) {
            driverName = agents[0]?._user?.fullName;
          }
        }

        setSelectedVehicle({
          ...vehicle?.roundtrip?._vehicle,
          primaryDriver: {
            fullName: driverName || t('vehicle.edit.driverAssigned.disabled'),
          },
        });
      } else {
        setSelectedVehicle(null);
      }

      if (!selectedRoundtrips.includes(vehicle.roundtripId)) {
        setSelectedRoundtrips([...selectedRoundtrips, vehicle.roundtripId]);
      }

      setCenter({
        lat: vehicle.lastPosition.geometry.coordinates[1],
        lng: vehicle.lastPosition.geometry.coordinates[0],
      });
    },
    [selectedRoundtrips, center, selectedVehicle],
  );

  const displayRoutes = useMemo(() => {
    return markers.routes;
  }, [markers]);

  const [pinnedLayers, setPinnedLayers]: any = useState([]);

  const filteredRoundtrips = useMemo(() => {
    if (isEmpty(search)) {
      return roundtrips;
    }

    return roundtrips ? filterRoundtripBySearch(roundtrips, search, t) : [];
  }, [roundtrips, search]);

  const [hoverableRoute, setHoverableRoute] = useState<string | null>(null);

  const [selectedRoundtripDetails, setSelectedRoundtripDetails] =
    useState<any>(null);

  const handleSetSelectedRoundtripDetails = useCallback(
    (value) => {
      setSelectedRoundtripDetails(value);
    },
    [selectedRoundtripDetails],
  );

  const [roundtripClickOnMap, setRoundtripClickOnMap] = useState<string | null>(
    null,
  );

  // const [, setUpdatedDisplayRoutes] = useState(displayRoutes);

  //On click on roundtrip on map update the selectedRoundtripDetails state to render the details pannel
  const handleSetSelectedRoundtrip = useMemo(() => {
    return () => {
      setRoundtripClickOnMap(null);
      roundtrips.map((rt) => {
        if (rt._id === roundtripClickOnMap) {
          setSelectedRoundtripDetails(rt);
          handleRoundtripFocus(rt);
        }
      });
    };
  }, [roundtripClickOnMap]);

  useEffect(() => {
    handleSetSelectedRoundtrip();
  }, [handleSetSelectedRoundtrip]);

  const [selectedOrders, setSelectedOrders]: any = useState([]);
  const [selectedFullOrders, setSelectedFullOrders]: any = useState([]);

  const handleSetSelectedTripDetails = useMemo(() => {
    return () => {
      if (!roundtrips.find((rt) => rt._id === selectedRoundtripDetails?._id)) {
        setSelectedRoundtripDetails(null);
      }
    };
  }, [roundtrips]);

  useEffect(() => {
    handleSetSelectedTripDetails();
  }, [handleSetSelectedTripDetails]);

  const mapContainerRef: any = useRef(null);
  const zoomCooldown = useRef(false);

  const handleSelectOrders = useCallback(
    async (e, orderId, clientOrder, preserveSelected) => {
      if (!e || !orderId || !clientOrder) return;
      const selectedOrdersCopy = [...selectedOrders];
      const ctrlKey = e?.domEvent?.ctrlKey || e?.ctrlKey;
      const metaKey = e?.domEvent?.metaKey || e?.metaKey;
      const selectedOrdersCount = selectedOrdersCopy.length;
      const alreadySelected = selectedOrdersCopy.includes(orderId);

      if (
        !(ctrlKey || metaKey) &&
        alreadySelected &&
        selectedOrdersCount === 1
      ) {
        await setSelectedOrders([]);
      }
      // else if (!ctrlKey && selectedOrdersCount > 1 && alreadySelected) {
      //   await setSelectedOrders([orderId]);
      // }
      else if ((ctrlKey || metaKey) && !alreadySelected) {
        await setSelectedOrders((prevState) => [...prevState, orderId]);
      } else if ((ctrlKey || metaKey) && alreadySelected && !preserveSelected) {
        await setSelectedOrders((prevState) => [
          ...prevState.filter((s) => s !== orderId),
        ]);
      }

      // if (selectedOrdersCopy.includes(clientOrder._id) && ctrlKey) {
      //   if (preserveSelected) return;
      //   await setSelectedFullOrders(prevState => [
      //     ...prevState.filter(s => s._id !== clientOrder._id),
      //   ]);
      // } else if (ctrlKey) {
      //   await setSelectedFullOrders(prevFullOrders => [
      //     ...prevFullOrders,
      //     clientOrder,
      //   ]);
      // }
    },
    [setSelectedOrders, setSelectedFullOrders, selectedOrders],
  );

  useEffect(() => {
    if (!mapData) return;
    const allOrders = [
      ...mapData?.markers?.assignedOrders?.map((order) => order?._order),
      ...mapData?.markers?.unassignedOrders,
    ];

    const fullSelected = allOrders?.filter((order) =>
      selectedOrders?.some((selectedOrderId) => selectedOrderId === order?._id),
    );

    setSelectedFullOrders(fullSelected);
  }, [selectedOrders]);

  const handleLeftMenuOrderClick = useCallback(
    (id: string) => {
      const stop = mapData?.markers?.assignedOrders?.find((s) => s._id === id);
      if (stop?._place?.location?.geometry?.coordinates) {
        const coordinates = {
          lat: stop?._place.location.geometry.coordinates?.[1],
          lng: stop?._place.location?.geometry.coordinates?.[0],
        };
        map?.panTo(coordinates);
      }
      if (!pinnedLayers?.includes(id)) {
        setPinnedLayers([id]);
      }
    },
    [setPinnedLayers, pinnedLayers, mapData?.markers?.assignedOrders, map],
  );

  const [contextMenu, setContextMenu] = useState<boolean>(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<any>(null);
  const [openRoundtrip, setOpenRoundtrip] = useState<boolean>(false);
  const [, setLastMousePosition] = useState({ x: 0, y: 0 });

  window.oncontextmenu = (e) => {
    handleContextMenu(e);
  };

  const handleContextMenu = (e) => {
    if (selectedOrders.length > 0) {
      setContextMenu(true);
      setContextMenuPosition({ x: e?.clientX, y: e?.clientY });
    }
  };

  // if more than 2 orders are selected by selection rectangle, open the menu
  // const handleOpenContextMenu = useMemo(() => {
  //   return () => {
  //     if (selectedOrders.length > 1 && !lastSelectByClick) {
  //       setContextMenu(true);
  //       setContextMenuPosition({
  //         x: lastMousePosition?.x + 109,
  //         y: lastMousePosition?.y + 76,
  //       });
  //     }
  //   };
  // }, [selectedOrders]);

  // useEffect(() => {
  //   handleOpenContextMenu();
  // }, [handleOpenContextMenu]);

  const [startLatLng, setStartLatLng] = useState(null);
  const [gridBoundingBox, setGridBoundingBox] = useState<any>(null);
  const [centerLatLng, setCenterLatLng] = useState<any>(null);
  const weatherLatLng = useDebounce(centerLatLng, 1000);
  const [selectionGridBoundingBox, setSelectionGridBoundingBox] =
    useState<any>(null);
  const [overlayWarehouseVisible, setOverlayWarehouseVisible] = useState(false);

  const handleShowRectangle = useMemo(() => {
    return () => {
      if (map) {
        google.maps.event.addListener(map, 'mousedown', (e) => {
          if (e?.domEvent?.button === 1) {
            return setScrollWheelPressed(true);
          }

          if (mapDraggable) return;
          if (e?.domEvent?.buttons === 1) {
            setStartLatLng(e.latLng);
            setGridBoundingBox(null);
          }
        });

        google.maps.event.addListener(map, 'mousemove', (e) => {
          if (mapDraggable) return;
          if (e?.domEvent?.buttons === 1 && startLatLng) {
            setLastMousePosition({ x: e?.pixel?.x, y: e?.pixel?.y });
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(startLatLng);
            bounds.extend(e.latLng);
            if (bounds) {
              setSelectionGridBoundingBox({
                bounds: {
                  north: bounds.getNorthEast().lat(),
                  south: bounds.getSouthWest().lat(),
                  east: bounds.getNorthEast().lng(),
                  west: bounds.getSouthWest().lng(),
                },
              });
            }
          } else if (startLatLng) {
            setSelectionGridBoundingBox(null);
          }
        });

        google.maps.event.addListener(map, 'mouseup', (e) => {
          setScrollWheelPressed(false);
          if (mapDraggable) return;
          if (!!e.latLng)
            setCenterLatLng({ lat: e?.latLng.lat(), lng: e?.latLng.lng() });

          if (startLatLng && e?.domEvent?.button === 0) {
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(startLatLng);
            bounds.extend(e.latLng);

            if (bounds) {
              setGridBoundingBox({
                bounds: {
                  north: bounds.getNorthEast().lat(),
                  south: bounds.getSouthWest().lat(),
                  east: bounds.getNorthEast().lng(),
                  west: bounds.getSouthWest().lng(),
                },
              });
            }
            // map.setOptions({ draggable: true });
            setSelectionGridBoundingBox(null);
            setLastSelectByClick(false);
          }
        });

        ///Remove rectangle on map click
        google.maps.event.addListener(map, 'click', () => {
          setGridBoundingBox(null);
          setSelectedFullOrders([]);
          setSelectedOrders([]);
          setFocusingAtMarkerId('');
          setFocusingAtMarkerIndex(-1);
          setOverlayWarehouseVisible(false);
          handleSetItemOrderOpenFor('');

          // close context menu
          setContextMenu(false);

          // unselect selected vehicle
          setSelectedVehicle(null);
        });
      }
    };
  }, [map, startLatLng, mapDraggable]);

  useEffect(() => {
    if (map && mapDraggable) {
      google.maps.event.clearListeners(map, 'mousedown');
      google.maps.event.clearListeners(map, 'mousemove');
      google.maps.event.clearListeners(map, 'mouseup');
    }
  }, [mapDraggable]);

  ///Multi selection drag -> show rectangle
  useEffect(() => {
    handleShowRectangle();
  }, [handleShowRectangle]);

  const handleShowSelectionBox = useCallback(
    (e) => {
      if (mapDraggable || !map || !startLatLng) return;
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(startLatLng);
      bounds.extend(e.latLng);
      if (bounds) {
        setGridBoundingBox({
          bounds: {
            north: bounds.getNorthEast().lat(),
            south: bounds.getSouthWest().lat(),
            east: bounds.getNorthEast().lng(),
            west: bounds.getSouthWest().lng(),
          },
        });
      }
      // map.setOptions({ draggable: true });
      setSelectionGridBoundingBox(null);
    },
    [mapDraggable, map, startLatLng],
  );

  const handleGridBoundingBox = useMemo(() => {
    return () => {
      if (gridBoundingBox) {
        mapData?.markers?.unassignedOrders?.filter((order) => {
          const lat = order?.coordinates[1];
          const lng = order?.coordinates[0];
          const isFilteredOrder =
            lat < gridBoundingBox.bounds.north &&
            lat > gridBoundingBox.bounds.south &&
            lng < gridBoundingBox.bounds.east &&
            lng > gridBoundingBox.bounds.west;

          if (isFilteredOrder) {
            handleSelectOrders({ ctrlKey: true }, order?._id, order, true);
          }
        });

        mapData?.markers?.assignedOrders?.filter((order) => {
          const lat = order?.coordinates[1];
          const lng = order?.coordinates[0];
          const isFilteredOrder =
            lat < gridBoundingBox.bounds.north &&
            lat > gridBoundingBox.bounds.south &&
            lng < gridBoundingBox.bounds.east &&
            lng > gridBoundingBox.bounds.west;

          if (isFilteredOrder) {
            handleSelectOrders(
              { ctrlKey: true },
              order?._order?._id,
              order?._order,
              true,
            );
          }
        });
      }
    };
  }, [gridBoundingBox, unassignedOrders, mapData?.markers?.assignedOrders]);

  useEffect(() => {
    handleGridBoundingBox();
  }, [handleGridBoundingBox]);

  // Update displayRoutes whenever the search changes
  const handleUpdateDisplayRoutes = useMemo(() => {
    return () => {
      if (
        (search !== '' && search?.startsWith('R')) ||
        search?.startsWith('r')
      ) {
        const filteredCodes = filteredRoundtrips.map((trip) => trip.code);
        displayRoutes.map((trip) => ({
          ...trip,
          filtered: filteredCodes.includes(trip.code),
          options: filteredCodes.includes(trip.code)
            ? {
                ...trip.options,
                fillColor: '#2A59FF',
                fillOpacity: 0.2,
                strokeColor: '#2A59FF',
                strokeWeight: 3,
              }
            : { ...trip.options },
        }));

        handleRoundtripFocus(filteredRoundtrips[0]);
      } else {
        displayRoutes.map((trip) => ({
          ...trip,
          filtered: false,
        }));
      }
    };
  }, [filteredRoundtrips]);

  useEffect(() => {
    handleUpdateDisplayRoutes();
  }, [handleUpdateDisplayRoutes]);

  const [isDragging, setIsDragging] = useState(false);
  const [hoveringOverRt, setHoveringOverRt] = useState('');
  const [hoveredStop, setHoveredStop] = useState('');

  const handleSetHoveringOverRt = useCallback(
    (value) => {
      setHoveringOverRt(value);
    },
    [hoveringOverRt],
  );

  const handleSetHoveredStop = useCallback(
    (value) => {
      setHoveredStop(value);
    },
    [hoveredStop],
  );

  const [lastInteractedWithOrder, setLastInteractedWithOrder] =
    useState<any>(null);
  const [mouseOverOrder, setMouseOverOrder] = useState<any>(null);

  const handleUnselectAll = useCallback(() => {
    if (selectedRoundtripDetails !== null && pinnedLayers?.length > 0) {
      return setPinnedLayers([]);
    }
    setGridBoundingBox(null);
    setSelectedFullOrders([]);
    setSelectedOrders([]);
    setPinnedLayers([]);
    setRoundtripClickOnMap(null);
    setSelectedRoundtrips([]);
    setSelectedRoundtripDetails(null);
    setMouseOverOrder(null);
    setFocusingAtMarkerId('');
    setFocusingAtMarkerIndex(-1);
    setSelectedVehicle(null);
  }, []);

  const handlePinnedLayersChange = useMemo(() => {
    return () => {
      if (selectedFullOrders?.length > 0) {
        const order = selectedFullOrders[selectedFullOrders.length - 1];
        setLastInteractedWithOrder(order);
        setMouseOverOrder(order);
        return;
      } else if (pinnedLayers.length === 0 || viewMode) {
        return setLastInteractedWithOrder(null);
      } else {
        const orderId = pinnedLayers[pinnedLayers.length - 1];
        const result1 = orders?.filter((order) => order?._id === orderId);
        const result2 = mapData?.markers?.assignedOrders?.filter(
          (order) => order?._order?._id === orderId,
        );
        const result = [result1[0] || result2[0]?._order];
        setLastInteractedWithOrder(result[0]);
        setMouseOverOrder(result[0]);
      }
    };
  }, [pinnedLayers, selectedFullOrders]);

  useEffect(() => {
    handlePinnedLayersChange();
  }, [handlePinnedLayersChange]);

  const handleOpenOrder = useCallback(() => {
    if (mouseOverOrder) {
      onDisplayOrder(mouseOverOrder);
    } else if (lastInteractedWithOrder) {
      onDisplayOrder(lastInteractedWithOrder);
    }
  }, [mouseOverOrder, lastInteractedWithOrder]);

  const allStopsIds = useMemo(() => {
    if (
      !mapData?.markers?.assignedOrders &&
      !mapData?.markers?.unassignedOrders
    )
      return;

    let allAssignedIds: any[] = [];
    if (mapData?.markers?.assignedOrders?.length > 0) {
      allAssignedIds = mapData?.markers?.assignedOrders?.map(
        (order) => order?._order?._id,
      );
    }

    let allUnassignedIds: any[] = [];
    if (mapData?.markers?.unassignedOrders?.length > 0) {
      allUnassignedIds = mapData?.markers?.unassignedOrders?.map(
        (order) => order?._id,
      );
    }

    return [...allAssignedIds, ...allUnassignedIds];
  }, [mapData?.markers?.assignedOrders, mapData?.markers?.unassignedOrders]);

  const [focusingAtMarkerId, setFocusingAtMarkerId] = useState('');
  const [focusingAtMarkerIndex, setFocusingAtMarkerIndex] = useState(-1);

  const handleSelectStop = useCallback(
    (direction) => {
      if (!allStopsIds || allStopsIds?.length === 0) return;
      setUpKeyToggled(false);

      if (
        direction === 'right' &&
        focusingAtMarkerIndex === allStopsIds.length - 1
      ) {
        setFocusingAtMarkerIndex(0);
        setFocusingAtMarkerId(allStopsIds[0]);
      } else if (direction === 'right') {
        setFocusingAtMarkerIndex(focusingAtMarkerIndex + 1);
        setFocusingAtMarkerId(allStopsIds[focusingAtMarkerIndex + 1]);
      } else if (direction === 'left' && focusingAtMarkerIndex === 0) {
        setFocusingAtMarkerIndex(allStopsIds.length - 1);
        setFocusingAtMarkerId(allStopsIds[allStopsIds.length - 1]);
      } else if (direction === 'left') {
        setFocusingAtMarkerIndex(focusingAtMarkerIndex - 1);
        setFocusingAtMarkerId(allStopsIds[focusingAtMarkerIndex - 1]);
      }

      audioRef?.current?.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.volume = 0.1;
      audioRef?.current?.play();
    },
    [
      allStopsIds,
      selectedRoundtripDetails,
      pinnedLayers,
      focusingAtMarkerId,
      focusingAtMarkerIndex,
    ],
  );

  const handleRoundtripSelection = useCallback(
    (num) => {
      roundtrips.map((rt) => {
        if (rt?.code) {
          const roundtripNumber = parseInt(rt?.code.replace('R', ''));
          if (roundtripNumber === num) {
            setRoundtripClickOnMap(rt?._id);
          }
        }
      });
    },
    [roundtrips],
  );

  const audioRef: any = useRef(null);

  const handleBindShortcuts = useMemo(() => {
    return () => {
      const shortcuts: any[] = [];
      for (let i = 1; i < 10; i++) {
        shortcuts.push({
          keybinds: [`ctrl+${i}`, `command+${i}`],
          callback: () => handleRoundtripSelection(i),
        });
      }
      shortcuts.map((shortcut) => {
        bindShortcut(shortcut.keybinds, shortcut.callback);
      });
    };
  }, [roundtrips]);

  useEffect(() => {
    handleBindShortcuts();
  }, [handleBindShortcuts]);

  const handleRoundtripFocus = useCallback(
    (roundtrip) => {
      if (!map) return;

      const allOrdersCoordinates: any[] = [];
      roundtrip?.stops?.map((order) => {
        if (order?.type === 'fuel' || order?.type === 'maintenance') {
          return allOrdersCoordinates.push(
            order?._place?.location?.geometry?.coordinates,
          );
        }
        allOrdersCoordinates.push(
          order?._order?._deck?.location?.geometry?.coordinates,
        );
      });
      allOrdersCoordinates.push(
        roundtrip?._startPlace?.location?.geometry?.coordinates,
      );

      if (allOrdersCoordinates.length === 0) setMapCenter('');

      const bounds = new window.google.maps.LatLngBounds();
      allOrdersCoordinates.forEach((location) => {
        if (!location) return;
        bounds.extend({
          lat: parseFloat(location[1]),
          lng: parseFloat(location[0]),
        });
      });
      map.fitBounds(bounds);
    },
    [map],
  );

  const [mapCenter, setMapCenter] = useState<any>(center);
  const [mapZoom, setMapZoom] = useState<any>(11);

  const handleMapFocus = useCallback(() => {
    if (!map) return;
    const allOrdersCoordinates: any[] = [];

    mapData?.markers?.fuelStops?.map((stop) => {
      allOrdersCoordinates.push(stop.coordinates);
    });

    mapData?.markers?.maintenanceStops?.map((stop) => {
      allOrdersCoordinates.push(stop.coordinates);
    });

    mapData?.markers?.assignedOrders?.map((stop) => {
      allOrdersCoordinates.push(stop.coordinates);
    });

    unassignedOrders?.map((order) => {
      allOrdersCoordinates.push(order?._deck?.location?.geometry?.coordinates);
    });

    allOrdersCoordinates.push(
      clusterData?._deck?.location?.geometry?.coordinates,
    );

    if (allOrdersCoordinates.length === 0) {
      setMapCenter(center);
      map?.setZoom(11);
    } else if (allOrdersCoordinates.length <= 1) {
      setMapCenter(allOrdersCoordinates[0]);
      map?.setZoom(11);
    } else if (allOrdersCoordinates.length > 1) {
      const bounds = new window.google.maps.LatLngBounds();
      allOrdersCoordinates.forEach((location) => {
        if (!location) return;
        bounds.extend({
          lat: parseFloat(location[1]),
          lng: parseFloat(location[0]),
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, mapCenter, mapZoom]);

  useEffect(() => {
    if (!map) return;
    const currentZoom = map?.getZoom();
    if (mapZoom && mapZoom !== currentZoom) {
      map?.setZoom(mapZoom);
    }
  }, [mapZoom]);

  const [count, setCount] = useState(0);

  const handleInitialMapFocus = useMemo(() => {
    return () => {
      if (!map || count > 2 || !mapData) return;
      handleMapFocus();
      setCount(count + 1);
    };
  }, [map, mapData, unassignedOrders, viewMode]);

  useEffect(() => {
    handleInitialMapFocus();
  }, [handleInitialMapFocus]);

  const handleMapZoom = useCallback(
    (direction) => {
      if (!map) return;
      const currentZoom = map.getZoom() || 1;
      if (direction === 'in') map?.setZoom(currentZoom + 1);
      else if (direction === 'out') map?.setZoom(currentZoom - 1);
    },
    [map],
  );

  const orderCardRef = useRef<any>(null);

  const shortcutsCallbacks = useMemo(() => {
    return {
      unselectAll: () => handleUnselectAll(),
      openOrder: () => handleOpenOrder(),
      selectStop: (direction: string) => handleSelectStop(direction),
      mapFocus: () => handleMapFocus(),
      mapZoom: (direction: string) => handleMapZoom(direction),
    };
  }, [
    handleUnselectAll,
    handleOpenOrder,
    handleSelectStop,
    handleMapFocus,
    handleMapZoom,
  ]);

  const [isolateRoundtrip, setIsolateRoundtrip] = useState(true);
  const [trackTruck, setTrackTruck] = useState(false);
  // const [trackTruckDisabled, setTrackTruckDisabled] = useState(false);

  const toggleIsolateRt = useCallback(() => {
    setIsolateRoundtrip(!isolateRoundtrip);
  }, [isolateRoundtrip]);

  const toggleTrackTruck = useCallback(() => {
    setTrackTruck(!trackTruck);
  }, [trackTruck]);

  useEffect(() => {
    if (!selectedRoundtripDetails) return;
    getDriverLocations({ tripId: selectedRoundtripDetails._id });
  }, [selectedRoundtripDetails]);

  const [truckCoordinates, setTruckCoordinates] = useState<
    { lat: number; lng: number }[]
  >([]);
  const [arrowRotation, setArrowRotation] = useState(0);

  const handleBearingCalculation = useMemo(() => {
    return () => {
      const formattedCoordinates: { lat: number; lng: number }[] = [];
      if (driverLocations?.length > 0) {
        driverLocations?.map((location) => {
          formattedCoordinates.push({ lat: location[1], lng: location[0] });
        });
        setTruckCoordinates(formattedCoordinates);
        if (formattedCoordinates.length > 1) {
          const bearing = calculateBearing(
            formattedCoordinates[formattedCoordinates.length - 2],
            formattedCoordinates[formattedCoordinates.length - 1],
          );
          setArrowRotation(bearing);
        }
      }
    };
  }, [driverLocations]);

  useEffect(() => {
    handleBearingCalculation();
  }, [handleBearingCalculation]);

  const trackTruckDisabled = useMemo(() => {
    if (selectedRoundtripDetails && driverLocations?.length > 0) {
      const vehicleInfo = selectedRoundtripDetails?._vehicle;
      return !(
        vehicleInfo?.gpsTrackingSystem?.toLowerCase() === 'fleetgo' &&
        selectedRoundtripDetails?.startAt
      );
    } else {
      return true;
    }
  }, [selectedRoundtripDetails, driverLocations]);

  const toggleUnlinkWarehouse = useCallback(() => {
    setUnlinkWarehouse(!unlinkWarehouse);
  }, [unlinkWarehouse]);

  const toggleHideTrucks = useCallback(() => {
    setHideTrucks(!hideTrucks);
  }, [hideTrucks]);

  const toggleShowOrderStatus = useCallback(() => {
    setShowOrderStatusActive(!showOrderStatusActive);
  }, [showOrderStatusActive]);

  useEffect(() => {
    if (!selectedRoundtripDetails?._id) return setIsolateRoundtrip(false);
    else if (selectedRoundtripDetails?._id) {
      setIsolateRoundtrip(true);
      handleRoundtripFocus(selectedRoundtripDetails);
    }
  }, [selectedRoundtripDetails]);

  useEffect(() => {
    setGridBoundingBox(null);
    setArrowRotation(0);
    setTruckCoordinates([]);
    setTrackTruck(false);
  }, [selectedRoundtripDetails]);

  useEffect(() => {
    if (!map) return;
    map.setOptions({
      keyboardShortcuts: false,
      mapTypeControl: false,
      clickableIcons: false,
    });
    map.setClickableIcons(false);
  }, [map]);

  const emptyRoundtripsCount = useMemo(() => {
    let total = 0;

    roundtrips.forEach((trip) => {
      if (trip?.stops?.length === 0) {
        total++;
      }
    });

    return total;
  }, [roundtrips, mapData]);

  useEffect(() => {
    if (
      selectedFullOrders?.length > 0 &&
      selectedOrders?.length > 0 &&
      setStopsToAssign
    ) {
      const formattedOrders = selectedFullOrders?.map((order) => {
        const id = order?._order?._id ?? order?._id;
        return {
          type: order?.type,
          _id: id,
          _order: order?._order?._id ?? order?._id,
          _place: order?._deck?._id,
        };
      });

      // remove duplicates
      const uniqueFormattedOrders = Array.from(new Set(formattedOrders));
      setStopsToAssign(uniqueFormattedOrders);
    } else {
      setStopsToAssign([]);
    }
  }, [selectedFullOrders, selectedOrders, setStopsToAssign]);

  const handleUnselectAllOrders = () => {
    setGridBoundingBox(null);
    setSelectedFullOrders([]);
    setSelectedOrders([]);
    setStopsToAssign([]);
  };

  useEffect(() => {
    handleUnselectAllOrders();
    setFocusingAtMarkerId('');
    setFocusingAtMarkerIndex(-1);
  }, [clearSelectionCount]);

  useEffect(() => {
    setFullStopsToAssign(selectedFullOrders);
  }, [selectedFullOrders]);

  const handleSelectFocusedOrder = useCallback(() => {
    if (!focusingAtMarkerId) return;

    const alreadySelected = selectedOrders?.some(
      (order) => order === focusingAtMarkerId,
    );

    if (alreadySelected) {
      setSelectedOrders((prevState) =>
        prevState?.filter((selected) => selected !== focusingAtMarkerId),
      );
    } else {
      setSelectedOrders((prevState) => [...prevState, focusingAtMarkerId]);
    }
  }, [focusingAtMarkerId, selectedOrders, setSelectedOrders]);

  useEffect(() => {
    // handle enter key press
    bindShortcut(['enter'], () => handleSelectFocusedOrder(), true, 'keydown');
  }, [handleSelectFocusedOrder]);

  useEffect(() => {
    // handle space key hold
    bindShortcut(['space'], () => setSpaceKeyPressed(true), true, 'keydown');
    bindShortcut(['space'], () => setSpaceKeyPressed(false), true, 'keyup');

    // handle h press
    bindShortcut(
      ['h'],
      () => setHKeyToggled((prevState) => !prevState),
      false,
      'keyup',
    );

    // handle up down arrow keys press
    bindShortcut(
      ['up'],
      () => setUpKeyToggled((prevState) => !prevState),
      true,
      'keydown',
    );
    bindShortcut(['down'], () => setUpKeyToggled(false), true, 'keydown');

    // disable tab key navigation
    bindShortcut(['tab'], () => {}, true, 'keydown');
    bindShortcut(['tab'], () => {}, true, 'keypress');
    bindShortcut(['tab'], () => {}, true, 'keyup');
  }, []);

  useEffect(() => {
    if (!map) return;
    setMapDraggable(spaceKeyPressed || scrollWheelPressed || hKeyToggled);
  }, [spaceKeyPressed, scrollWheelPressed, hKeyToggled]);

  useEffect(() => {
    if (mapDraggable || !mapContainerRef.current || !map) return;
    const handleScrollZoom = (e) => {
      e.preventDefault();
      if (zoomCooldown.current) return;
      const delta = Math.sign(e.deltaY);
      const currentZoom = map.getZoom();
      if (!currentZoom) return;
      map.setZoom(currentZoom - delta);

      zoomCooldown.current = true;
      setTimeout(() => {
        zoomCooldown.current = false;
      }, 250);
    };

    mapContainerRef?.current?.addEventListener('wheel', handleScrollZoom);

    return () => {
      mapContainerRef?.current?.removeEventListener('wheel', handleScrollZoom);
    };
  }, [mapDraggable, mapContainerRef, map]);

  const panIntervalRef: any = useRef(null);
  const speedIntervalRef: any = useRef(null);
  const currentPanSpeedRef = useRef(2.5);
  // const [, setCurrentPanSpeed] = useState(2.5);

  const panMap = (xPan, yPan) => {
    if (map && (xPan !== 0 || yPan !== 0)) {
      map.panBy(
        xPan * currentPanSpeedRef.current,
        yPan * currentPanSpeedRef.current,
      );
    }
  };

  const startPanning = (xPan, yPan) => {
    if (!panIntervalRef.current) {
      panIntervalRef.current = setInterval(() => panMap(xPan, yPan), 10);
    }
  };

  const stopPanning = () => {
    if (panIntervalRef.current) {
      clearInterval(panIntervalRef.current);
      panIntervalRef.current = null;
    }
    if (speedIntervalRef.current) {
      clearInterval(speedIntervalRef.current);
      speedIntervalRef.current = null;
    }
    currentPanSpeedRef.current = 2.5;
  };

  useEffect(() => {
    if (!map || !mapContainerRef.current) return;

    const handleMouseMove = (event) => {
      const topThreshold = 132;
      const bottomThreshold = 90;
      const leftThreshold =
        !!selectedRoundtripDetails || !leftMenuCollapsed ? 300 : 160;
      const rightThreshold = 50;

      const { clientX, clientY } = event;
      const { innerWidth, innerHeight } = window;

      let xPan = 0;
      let yPan = 0;

      // Pan left
      if (clientX < leftThreshold) {
        xPan = -1;
      }
      // Pan right
      if (clientX > innerWidth - rightThreshold) {
        xPan = 1;
      }
      // Pan up
      if (clientY < topThreshold) {
        yPan = -1;
      }
      // Pan down
      if (clientY > innerHeight - bottomThreshold) {
        yPan = 1;
      }

      if (xPan !== 0 || yPan !== 0) {
        startPanning(xPan, yPan);
      } else {
        stopPanning();
      }
    };

    const handleMouseOut = () => {
      stopPanning();
    };

    mapContainerRef.current.addEventListener('mousemove', handleMouseMove);
    mapContainerRef.current.addEventListener('mouseout', handleMouseOut);

    return () => {
      mapContainerRef?.current?.removeEventListener(
        'mousemove',
        handleMouseMove,
      );
      mapContainerRef?.current?.removeEventListener('mouseout', handleMouseOut);
      stopPanning();
    };
  }, [map, mapContainerRef, leftMenuCollapsed, selectedRoundtripDetails]);

  const ordersCount = useMemo(() => {
    var total = 0;
    total += mapData?.markers?.assignedOrders?.length || 0;
    // total += unassignedOrders_?.length || 0;
    return total;
  }, [roundtrips, mapData?.markers?.assignedOrders /*unassignedOrders_*/]);

  // used to help show the itemOrder card over the marker
  const [lastSelectByClick, setLastSelectByClick] = useState<boolean>(false);

  const handleCloseClientOrderPopover = useCallback(() => {
    setOpenRoundtrip(false);
    setContextMenu(false);
  }, []);

  const [itemOrderOpenFor, setItemOrderOpenFor] = useState('');

  const handleSetItemOrderOpenFor = useCallback(
    (id) => {
      setItemOrderOpenFor(id);
    },
    [setItemOrderOpenFor],
  );

  const [polygonContextMenu, setPolygonContextMenu] = useState(false);
  const [polygonContextMenuPosition, setPolygonContextMenuPosition] =
    useState<any>(null);
  const [menuOpenRoundtrip, setMenuOpenRoundtrip] = useState('');

  const handlePolygonRightClick = (e, roundtripId) => {
    // open polygon options menu
    const roundtrip = roundtrips?.filter(
      (trip) => trip?._id === roundtripId,
    )[0];
    setMenuOpenRoundtrip(roundtrip);
    setPolygonContextMenuPosition({
      x: e?.domEvent?.clientX + 2,
      y: e?.domEvent?.clientY + 2,
    });
    setPolygonContextMenu(true);
  };

  const handleClosePolygonContextMenu = () => {
    setPolygonContextMenu(false);
    setMenuOpenRoundtrip('');
  };

  const AssignedOrdersMarkers = useMemo(() => {
    var viewAssignedOrders = true;
    if (debouncedFilters?.assigned?.length > 0) {
      const result = debouncedFilters?.assigned?.filter(
        (filter) => filter?.value === 'yes',
      );
      viewAssignedOrders = result?.length > 0;
    } else {
      viewAssignedOrders = true;
    }
    return mapData?.markers?.assignedOrders?.map((order) => {
      const places = {
        lat: order?.coordinates[1],
        lng: order?.coordinates[0],
      };

      let isRoundtripSelected = false;
      if (selectedRoundtripDetails?._id === order?.roundtripId) {
        isRoundtripSelected = true;
      }

      const isFilteredStop = search?.toUpperCase() === order?._order?.code;

      const isSelected = isRoundtripSelected;
      // const isHighlighted = pinnedLayers?.includes(order?._order?._id);
      return (
        <React.Fragment key={order?._order?._id}>
          <ClientOrderMarker
            markerKey={order?._order?._id}
            position={places}
            isSelected={isSelected}
            type={RoundtripsType.Assigned}
            clusterer={clusterData}
            clientsOrders={order?._order}
            stop={order}
            index={order?.stopNumber}
            onDisplayOrder={onDisplayOrder}
            accordionStatus={accordionStatus}
            setAccordionStatus={setAccordionStatus}
            roundTripCopy={clusterData?.roundtrips || roundTripCopy}
            clusterId={clusterId}
            requestOrderData={requestOrderData}
            orders={orders}
            setOrders={setOrders}
            setOrderInitCount={setOrderInitCount}
            orderInitCount={orderInitCount}
            pinnedLayers={pinnedLayers}
            setPinnedLayers={setPinnedLayers}
            selectedOrders={selectedOrders}
            handleSelectOrders={handleSelectOrders}
            setAssignedOrders={() => {}}
            isDragging={isDragging}
            hoveredRtId={hoveringOverRt}
            setMouseOverOrder={setMouseOverOrder}
            orderCardRef={orderCardRef}
            viewMode={!!viewMode}
            viewAssignedOrders={viewAssignedOrders}
            lastSelectByClick={lastSelectByClick}
            showOrderStatusActive={showOrderStatusActive}
            // setMarkerRef={setMarkerRef}
            map={map}
            focusingAtMarkerId={focusingAtMarkerId}
            circleOptions={circleOptions}
            upKeyToggled={upKeyToggled}
            itemOrderOpenFor={itemOrderOpenFor}
            handleSetItemOrderOpenFor={handleSetItemOrderOpenFor}
          />
          {isFilteredStop && (
            <PulsingCircle>
              <CircleComponent
                center={places}
                options={{
                  ...circleOptions,
                }}
                map={map}
              />
            </PulsingCircle>
          )}
          {hoveredStop === order?._id && (
            <CircleComponent
              center={places}
              options={{
                ...circleOptions,
              }}
              map={map}
            />
          )}
        </React.Fragment>
      );
    });
  }, [
    mapData,
    pinnedLayers,
    hoverableRoute,
    selectedOrders,
    orders,
    debouncedFilters,
    showOrderStatusActive,
    selectedRoundtripDetails,
    hoveredStop,
    hoveringOverRt,
    upKeyToggled,
    focusingAtMarkerId,
    itemOrderOpenFor,
    handleSetItemOrderOpenFor,
    search,
  ]);

  const UnassignedOrdersMarkers = useMemo(() => {
    var viewUnassignedOrders = true;
    if (debouncedFilters?.assigned?.length > 0) {
      const result = debouncedFilters?.assigned?.filter(
        (filter) => filter?.value === 'no',
      );
      viewUnassignedOrders = result?.length > 0;
    } else {
      viewUnassignedOrders = true;
    }

    return mapData?.markers?.unassignedOrders?.map((order, index) => {
      const places = {
        lat: order?.coordinates[1],
        lng: order?.coordinates[0],
      };
      const isFilteredStop = search?.toUpperCase() === order?.code;

      return (
        <>
          <ClientOrderMarker
            markerKey={order._id}
            position={places}
            type={RoundtripsType.Unassigned}
            clusterer={clusterData}
            clientsOrders={order}
            stop={order}
            index={index}
            onDisplayOrder={onDisplayOrder}
            accordionStatus={accordionStatus}
            setAccordionStatus={setAccordionStatus}
            roundTripCopy={roundTripCopy}
            clusterId={clusterId}
            requestOrderData={requestOrderData}
            orders={orders}
            setOrders={setOrders}
            setOrderInitCount={setOrderInitCount}
            orderInitCount={orderInitCount}
            pinnedLayers={pinnedLayers}
            setPinnedLayers={setPinnedLayers}
            selectedOrders={selectedOrders}
            handleSelectOrders={handleSelectOrders}
            isDragging={isDragging}
            setMouseOverOrder={setMouseOverOrder}
            orderCardRef={orderCardRef}
            viewMode={!!viewMode}
            viewUnassignedOrders={viewUnassignedOrders}
            lastSelectByClick={lastSelectByClick}
            // setMarkerRef={setMarkerRef}
            map={map}
            focusingAtMarkerId={focusingAtMarkerId}
            circleOptions={circleOptions}
            upKeyToggled={upKeyToggled}
            itemOrderOpenFor={itemOrderOpenFor}
            handleSetItemOrderOpenFor={handleSetItemOrderOpenFor}
          />
          {isFilteredStop && (
            <PulsingCircle>
              <CircleComponent
                center={places}
                options={{
                  ...circleOptions,
                }}
                map={map}
              />
            </PulsingCircle>
          )}
        </>
      );
    });
  }, [
    mapData?.markers?.unassignedOrders,
    pinnedLayers,
    selectedOrders,
    hoveringOverRt,
    orders,
    debouncedFilters,
    upKeyToggled,
    focusingAtMarkerId,
    itemOrderOpenFor,
    handleSetItemOrderOpenFor,
    search,
  ]);

  const Polygons = useMemo(() => {
    var viewAssignedOrdersRoutes = true;
    if (debouncedFilters?.assigned?.length > 0) {
      const result = debouncedFilters?.assigned?.filter(
        (filter) => filter?.value === 'yes',
      );
      viewAssignedOrdersRoutes = result?.length > 0;
    } else {
      viewAssignedOrdersRoutes = true;
    }

    return mapData?.polygons?.map((p, index) => {
      let selected = false;
      if (selectedRoundtripDetails?._id === p.roundtripId) {
        selected = true;
      }
      if (p?.paths?.length > 2 || p?.canceledPaths?.length > 0) {
        return (
          <React.Fragment key={index}>
            {(hoverableRoute === p.roundtripId && hoverableRoute !== null) ||
            hoveringOverRt === p.roundtripId ||
            selectedRoundtripDetails?._id === p.roundtripId ? (
              <>
                {p.canceledPaths.length === 0 || !selected ? (
                  <PolygonComponent
                    paths={p.paths}
                    fillColor={
                      selected
                        ? themes?.default?.primary
                        : themes?.default?.nobel
                    }
                    fillOpacity={viewAssignedOrdersRoutes ? 0.2 : 0.1}
                    strokeColor={themes?.default?.strokePrimary}
                    strokeOpacity={viewAssignedOrdersRoutes ? 1 : 0.5}
                    strokeWeight={2}
                    map={map}
                    onMouseOver={() => {
                      setHoverableRoute(p.roundtripId);
                      setHoveringOverRt(p.roundtripId);
                    }}
                    onMouseOut={() => {
                      setHoverableRoute(null);
                      setHoveringOverRt('');
                    }}
                    onClick={() => {
                      setRoundtripClickOnMap(p.roundtripId);
                    }}
                    onRightClick={(e) =>
                      handlePolygonRightClick(e, p?.roundtripId)
                    }
                    visible={true}
                    clickable={selectionGridBoundingBox === null}
                  />
                ) : (
                  p.canceledPaths.length > 0 &&
                  selected && (
                    <>
                      {p.canceledPaths.map((cp, idx) => (
                        <PolygonComponent
                          key={idx}
                          paths={cp}
                          fillColor={
                            selected ? 'transparent' : themes?.default?.nobel
                          }
                          fillOpacity={0.2}
                          strokeColor={themes?.default?.redA400}
                          strokeOpacity={0.4}
                          strokeWeight={3}
                          map={map}
                          onMouseOut={() => {
                            setHoverableRoute(null);
                            setHoveringOverRt('');
                          }}
                          onClick={() => {
                            setRoundtripClickOnMap(p.roundtripId);
                          }}
                          onRightClick={(e) =>
                            handlePolygonRightClick(e, p?.roundtripId)
                          }
                          visible={true}
                          clickable={selectionGridBoundingBox === null}
                        />
                      ))}
                      <PolygonComponent
                        paths={p.paths.filter(
                          (path) => path.type !== 'canceled',
                        )}
                        fillColor={
                          selected
                            ? themes?.default?.primary
                            : themes?.default?.nobel
                        }
                        fillOpacity={0.2}
                        strokeColor={themes?.default?.strokePrimary}
                        strokeOpacity={1}
                        strokeWeight={4}
                        zIndex={3}
                        map={map}
                        onMouseOut={() => {
                          setHoverableRoute(null);
                          setHoveringOverRt('');
                        }}
                        onClick={() => {
                          setRoundtripClickOnMap(p.roundtripId);
                        }}
                        onRightClick={(e) =>
                          handlePolygonRightClick(e, p?.roundtripId)
                        }
                        visible={true}
                        clickable={selectionGridBoundingBox === null}
                      />
                    </>
                  )
                )}
                <CustomTooltip text={p.code} coordinates={p.center} />
              </>
            ) : !isolateRoundtrip ? (
              <PolygonComponent
                paths={p.paths}
                fillColor={p?.options?.fillColor}
                fillOpacity={
                  selectedRoundtripDetails
                    ? selectedRoundtripDetails?._id !== p.roundtripId
                      ? 0.125
                      : p?.options?.fillOpacity
                    : p?.options?.fillOpacity
                }
                strokeColor={p?.options?.strokeColor}
                strokeOpacity={p?.options?.strokeOpacity}
                strokeWeight={p?.options?.strokeWeight}
                map={map}
                onMouseOver={() => {
                  setHoverableRoute(p.roundtripId);
                  setHoveringOverRt(p.roundtripId);
                }}
                onMouseOut={() => {
                  setHoverableRoute(null);
                  setHoveringOverRt('');
                }}
                visible={true}
                clickable={selectionGridBoundingBox === null}
              />
            ) : null}
          </React.Fragment>
        );
      }
    });
  }, [
    mapData,
    selectedRoundtripDetails,
    hoverableRoute,
    hoveringOverRt,
    isDragging,
    isolateRoundtrip,
    orders,
    debouncedFilters,
    circleRadius,
  ]);

  const Polylines = useMemo(() => {
    if (map && trackTruck && truckCoordinates?.length > 0)
      return (
        <>
          <PolylineComponent
            path={truckCoordinates}
            options={{
              strokeColor: themes?.default?.purple400,
              strokeOpacity: 1.0,
              strokeWeight: 1.4,
              zIndex: 4,
            }}
            map={map}
          />
          {truckCoordinates?.length > 0 &&
            truckCoordinates?.map((point) => {
              return (
                <AdvancedMarker position={point}>
                  <img
                    style={{ marginBottom: '-8px' }}
                    src={PolylinePoints}
                    width={8}
                    height={8}
                  />
                </AdvancedMarker>
              );
            })}
          <AdvancedMarker
            position={truckCoordinates[truckCoordinates?.length - 1 || 0]}
          >
            <span>
              <img
                src={PolylineArrow}
                alt=""
                style={{
                  transform: `rotate(${arrowRotation}deg)`,
                  transformOrigin: 'bottom',
                }}
              />
            </span>
          </AdvancedMarker>
        </>
      );
    else return null;
  }, [
    trackTruck,
    driverLocations,
    truckCoordinates,
    selectedRoundtripDetails,
    arrowRotation,
  ]);

  const VehiclesMarkers = useMemo(() => {
    if (hideTrucks)
      return mapData?.markers?.vehicles?.map((v) => (
        <MarkerVehicle
          key={v._id}
          vehicle={v}
          selectedVehicle={selectedVehicle}
          // setSelectedVehicle={setSelectedVehicle}
          onClick={handleClickVehicle}
        />
      ));
    else return null;
  }, [hideTrucks, mapData, selectedRoundtrips, selectedVehicle]);

  const WarehouseMarker = useMemo(() => {
    return (
      <>
        {mapData?.markers?.warehouses?.map((warehouse) => (
          <MarkerStep
            key={warehouse?._id}
            type="deck"
            place={warehouse}
            warehouseData={warehouseData}
            roundTripsCount={roundTripsCount}
            ordersCount={ordersCount}
            overlayWarehouseVisible={overlayWarehouseVisible}
            setOverlayWarehouseVisible={setOverlayWarehouseVisible}
            emptyRoundtripsCount={emptyRoundtripsCount}
          />
        ))}
      </>
    );
  }, [
    mapData?.markers?.warehouses,
    warehouseData,
    ordersCount,
    overlayWarehouseVisible,
    emptyRoundtripsCount,
  ]);

  const FuelStopsMarkers = useMemo(() => {
    return mapData?.markers?.fuelStops?.map((s) => {
      return (
        <MarkerStep
          type="stop"
          stopType={s.type}
          key={s._id}
          place={s}
          index={s.place}
          roundTripCopy={roundTripCopy}
          setAssignedOrders={() => {}}
        />
      );
    });
  }, [mapData]);

  const MaintenanceStopsMarkers = useMemo(() => {
    return mapData?.markers?.maintenanceStops?.map((s) => {
      return (
        <MarkerStep
          type="stop"
          stopType={s.type}
          key={s._id}
          place={s}
          data={s}
          index={s.place}
          roundTripCopy={roundTripCopy}
          setAssignedOrders={() => {}}
        />
      );
    });
  }, [mapData]);

  // const GridBoundingBox = useMemo(() => {
  //   return (
  //     gridBoundingBox !== null && (
  //       <RectangleComponent
  //         bounds={gridBoundingBox.bounds}
  //         fillColor={'#2983D5'}
  //         fillOpacity={0.2}
  //         strokeColor={'#348BD8'}
  //         strokeOpacity={1}
  //         strokeWeight={2}
  //         map={map}
  //       />
  //     )
  //   );
  // }, [gridBoundingBox]);

  const SelectionGridBoundingBox = useMemo(() => {
    return (
      selectionGridBoundingBox !== null && (
        <RectangleComponent
          bounds={selectionGridBoundingBox.bounds}
          fillColor="#2983D5"
          fillOpacity={0.1}
          strokeColor="#348BD8"
          strokeOpacity={1}
          strokeWeight={2}
          map={map}
          onMouseUp={(e) => handleShowSelectionBox(e)}
        />
      )
    );
  }, [selectionGridBoundingBox]);

  return !isloadindCluster ? (
    <MapWrapper
      id="mapContainer"
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={() => {
        setIsDragging(false);
      }}
    >
      <Stack direction="row" sx={{ height: '100%' }}>
        <If condition={!isloadindCluster}>
          <LeftMenu
            roundtrips={filteredRoundtrips}
            updateRoundtripStatus={updateRoundtripStatus}
            userContext={userContext}
            selectedRoundtrips={selectedRoundtrips}
            handleSelectRoundtrip={handleSelectRoundtrip}
            handleClickOpen={handleClickOpen}
            handleClickOpenDelete={handleClickOpenDelete}
            viewMode={viewMode || false}
            setAddModalOpen={setAddModalOpen}
            selectedRoundtripDetails={selectedRoundtripDetails}
            setSelectedRoundtripDetails={handleSetSelectedRoundtripDetails}
            orders={orders}
            handleOrderClick={handleLeftMenuOrderClick}
            setRoundtripEdit={setRoundtripEdit}
            pinnedOrders={pinnedLayers}
            hoveringOverRt={hoveringOverRt}
            setHoveringOverRt={handleSetHoveringOverRt}
            hoveredStop={hoveredStop}
            setHoveredStop={handleSetHoveredStop}
            assignedOrders={mapData?.markers?.assignedOrders}
            setLeftMenuCollapsed={setLeftMenuCollapsed}
          />
        </If>

        <WeatherTagWrapper viewMode={!!viewMode} zoomedIn={zoomedIn}>
          <WeatherTag latLng={weatherLatLng || center} />
        </WeatherTagWrapper>
        <div ref={mapContainerRef} style={{ height: '100%', width: '100%' }}>
          <MapComponent
            id={ENV.REACT_APP_GOOGLE_MAP_ID}
            mapId={ENV.REACT_APP_GOOGLE_MAP_ID}
            styles={MAP_CONFIG.styles}
            defaultCenter={mapCenter}
            defaultZoom={mapZoom}
            disableDefaultUI={true}
            onZoomChanged={() => {
              setZoom(map?.getZoom() || 11);
              setMapZoom(map?.getZoom() || 11);
            }}
            draggable={mapDraggable}
          >
            {!viewMode && (
              <FilterChipsWrapper>
                <FilterChips filters={filters} setFilters={setFilters} />
              </FilterChipsWrapper>
            )}

            {AssignedOrdersMarkers}

            {UnassignedOrdersMarkers}

            {displaySelectedPolygons === true || !selectedRoundtripDetails
              ? Polygons
              : null}

            {Polylines}

            {WarehouseMarker}

            {VehiclesMarkers}

            {FuelStopsMarkers}

            {MaintenanceStopsMarkers}

            {/* {GridBoundingBox} */}

            {SelectionGridBoundingBox}

            {contextMenu && (
              <AssignedRoundtripsPopover
                open={contextMenu}
                position={contextMenuPosition}
                setOpenAddRoundtrip={setOpenRoundtrip}
                setAddModalOpen={setAddModalOpen}
                handleMenuClose={() => setContextMenu(false)}
                handleClearSelection={() => handleClearSelection()}
                roundtripsCount={roundtrips?.length || 0}
                selectedItemsCount={selectedOrders.length || 0}
              />
            )}

            {polygonContextMenu && (
              <PolygonOptionsMenu
                open={polygonContextMenu}
                position={polygonContextMenuPosition}
                handleMenuClose={() => handleClosePolygonContextMenu()}
                viewMode={viewMode}
                roundtrip={menuOpenRoundtrip}
                functionRoundTripStatus={functionRoundTripStatus}
                handleClickOpen={handleClickOpen}
                handleClickOpenDelete={handleClickOpenDelete}
                setRoundtripEdit={setRoundtripEdit}
              />
            )}

            {openRoundtrip && (
              <AddClientOrderPopover
                openAddRoundtrip={openRoundtrip}
                setOpenAddRoundtrip={setOpenRoundtrip}
                roundtrips={roundTripCopy}
                selectedFullOrders={selectedFullOrders}
                handleUnselectAllOrders={handleUnselectAllOrders}
                position={contextMenuPosition}
                closeOnMouseOut={true}
                handleCloseClientOrderPopover={handleCloseClientOrderPopover}
              />
            )}

            {selectedRoundtripDetails && displaySelectedRoute && (
              <RoundtripDirections
                map={map}
                roundtrip={selectedRoundtripDetails}
              />
            )}
          </MapComponent>
        </div>

        <MapActionsWrapper viewMode={!!viewMode}>
          {selectedRoundtripDetails ? (
            <>
              <MapActionButton
                icon={
                  trackTruck ? (
                    <img
                      style={{ height: '16px', width: '16px' }}
                      src={TrackTruckActive}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ height: '16px', width: '16px' }}
                      src={TrackTruck}
                      alt=""
                    />
                  )
                }
                action={toggleTrackTruck}
                active={trackTruck}
                tooltip={
                  trackTruckDisabled
                    ? t('roundtrip.vehicle_paths_unavailable')
                    : t('track_truck')
                }
                disabled={trackTruckDisabled}
              />

              <MapActionButton
                icon={
                  displaySelectedPolygons ? (
                    <img
                      style={{ height: '16px', width: '16px' }}
                      src={IsolateRoundtripActive}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ height: '16px', width: '16px' }}
                      src={IsolateRoundtripIcon}
                      alt=""
                    />
                  )
                }
                action={() =>
                  setDisplaySelectedPolygons(!displaySelectedPolygons)
                }
                active={displaySelectedPolygons}
                tooltip={
                  displaySelectedPolygons
                    ? t('hide_polygons')
                    : t('display_polygons')
                }
              />

              <MapActionButton
                icon={
                  displaySelectedRoute ? (
                    <img
                      style={{ height: '16px', width: '16px' }}
                      src={RouteActiveIcon}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ height: '16px', width: '16px' }}
                      src={RouteIcon}
                      alt=""
                    />
                  )
                }
                action={() => setDisplaySelectedRoute(!displaySelectedRoute)}
                active={displaySelectedRoute}
                tooltip={
                  displaySelectedRoute
                    ? t('hide_actual_route')
                    : t('show_actual_route')
                }
              />

              <MapActionButton
                icon={isolateRoundtrip ? EyeSlashActive : EyeSlash}
                action={toggleIsolateRt}
                active={isolateRoundtrip}
                tooltip={t('map_actions.isolate_roundtrip')}
              />
            </>
          ) : (
            <>
              <MapActionButton
                icon={
                  hideTrucks ? (
                    <img
                      style={{ height: '22px', width: '22px' }}
                      src={HideTrucks}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ height: '22px', width: '22px' }}
                      src={ShowTrucks}
                      alt=""
                    />
                  )
                }
                action={toggleHideTrucks}
                active={hideTrucks}
                tooltip={hideTrucks ? t('hide_trucks') : t('show_trucks')}
              />
              <MapActionButton
                icon={
                  unlinkWarehouse ? (
                    <img
                      style={{ height: '22px', width: '22px' }}
                      src={LinkWarehouse}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ height: '22px', width: '22px' }}
                      src={UnlinkWarehouse}
                      alt=""
                    />
                  )
                }
                action={toggleUnlinkWarehouse}
                active={unlinkWarehouse}
                tooltip={
                  unlinkWarehouse ? t('link_warehouse') : t('unlink_warehouse')
                }
              />
              <MapActionButton
                icon={
                  showOrderStatusActive ? (
                    <img
                      style={{ height: '22px', width: '22px' }}
                      src={ShowStatusActive}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ height: '22px', width: '22px' }}
                      src={ShowStatus}
                      alt=""
                    />
                  )
                }
                action={toggleShowOrderStatus}
                active={showOrderStatusActive}
                tooltip={
                  showOrderStatusActive
                    ? t('hide_stop_status')
                    : t('show_stop_status')
                }
              />
            </>
          )}
          <MapZoomButton mapZoom={mapZoom} setMapZoom={setMapZoom} />
        </MapActionsWrapper>
        <MapLegendWrapper viewMode={!!viewMode}>
          {React.cloneElement(LegendComponent || <div />, {
            shortcutsCallbacks: shortcutsCallbacks,
          })}
        </MapLegendWrapper>
      </Stack>
      <audio src={LightSwitch} ref={audioRef} style={{ display: 'hidden' }} />
    </MapWrapper>
  ) : null;
};

export default React.memo(Map);

const MapLegendWrapper = styled.div<{ viewMode?: boolean }>`
  position: absolute;
  bottom: ${(props) => (props.viewMode ? '1px' : '40px')};
  left: 0;
  right: 0;
  z-index: 100;
`;

export const PulsingCircle = styled.div`
  box-shadow: 0 0 0 0 black;
  animation: pulse 2s infinite;
  cursor: pointer;
  z-index: 99;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 black;
    }
    50% {
      box-shadow: 0 0 0 5px black;
    }
    100% {
      box-shadow: 0 0 0 0 black;
    }
  }
`;

const WeatherTagWrapper = styled.div<{ viewMode: boolean; zoomedIn: boolean }>`
  position: absolute;
  top: ${(props) =>
    props.zoomedIn ? '48px' : props.viewMode ? '98px' : '48px'};
  right: 14px;
  z-index: 2000;
`;

const MapActionsWrapper = styled.div<{ viewMode: boolean }>`
  position: absolute;
  width: 32px;
  height: fit-content;
  bottom: ${(props) => (props.viewMode ? '54px' : '92px')};
  right: 11px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FilterChipsWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 12px;
  > div:first-child {
    padding: 0;
  }
`;
