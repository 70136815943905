import React, { useEffect, useMemo, useState } from 'react';
import { Plus } from '@phosphor-icons/react';
import styled from 'styled-components';

import {
  useGetWarehousesQuery,
  useLazyGetConfigurationQuery,
} from 'common/services/organizationApi';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { NestedMenu } from '../../../../components/CustomMenu/NestedMenu';
import { ActionProps } from '../../../../components/CustomMenu/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { Button, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSubscribeObject } from 'common/hooks/ws';
import { selectOrganization } from 'common/store/organization/selectors';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

function AddMapping() {
  const roundtripMappingsContext = useManagedContext(
    'RoundtripMappingsContext',
  );
  const mappingCan = usePermission('mappings')(Permission.EDIT);
  const [getConfirguration, { data: warehouses }] =
    useLazyGetConfigurationQuery();
  const { data: warehouseDataFetched } = useGetWarehousesQuery('');
  const organization = useSelector(selectOrganization);
  const { t } = useTranslation();

  const subscribedOrganization = organization?.id;

  const rawMessage = useSubscribeObject(
    'organization-decks',
    subscribedOrganization,
  );
  const wsDecksMessage = rawMessage ? JSON.parse(rawMessage) : null;

  const [warehouseData, setWarehouseData] = useState({
    warehouses: Array.isArray(warehouses) ? warehouses : [],
  });

  useEffect(() => {
    if (
      warehouseDataFetched &&
      Array.isArray(warehouseDataFetched.warehouses)
    ) {
      setWarehouseData(warehouseDataFetched);
    } else {
      console.error(
        'Invalid warehouseDataFetched structure:',
        warehouseDataFetched,
      );
    }
  }, [warehouseDataFetched]);

  useEffect(() => {
    if (warehouseDataFetched) {
      setWarehouseData(warehouseDataFetched);
    }
  }, [warehouseDataFetched]);

  useEffect(() => {
    if (
      wsDecksMessage?.objectType === 'organization-decks' &&
      wsDecksMessage?.data?.deckId
    ) {
      const { deckId, status } = wsDecksMessage.data;

      setWarehouseData((prev) => {
        const updatedWarehouses = prev.warehouses.map((item) =>
          item.id === deckId ? { ...item, status } : item,
        );
        return { ...prev, warehouses: updatedWarehouses };
      });
    }
  }, [rawMessage]);
  // const { data: warehouseData } = useGetWarehousesQuery('');
  useEffect(() => {
    getConfirguration();
  }, [getConfirguration]);
  const actions: ActionProps[] = useMemo(() => {
    if (!warehouseData?.warehouses) return [];

    const existingWarehousesMappings =
      roundtripMappingsContext.roundtripMappings.map((w) => w.id);

    return warehouseData.warehouses
      .filter((w) => !existingWarehousesMappings.includes(w._id))
      .map((w) => ({
        element: (
          <WarehouseContainer>
            <ShortName color={w.color}>{w.shortName}</ShortName>
            {w.name}
            {w.status === 'inactive' && (
              <InactiveStatus>{w.status}</InactiveStatus>
            )}
          </WarehouseContainer>
        ),
        onClick: () => {
          roundtripMappingsContext.updateDataWithFunction((prev) => {
            prev.roundtripMappings.push({
              id: w._id as string,
              name: w.name as string,
              nbOfRoundtrips: 0,
              color: w.color as string,
              shortName: w.shortName as string,
              status: w.status as string,
              roundtrips: [],
            });
          });
        },
      }));
  }, [warehouseData, roundtripMappingsContext]);
  const AddWarehouseIcon = () => (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button
        variant="text"
        startIcon={<Plus color={themes.default.clientMarkerBg} size={14} />}
        sx={{
          borderRadius: '4px',
          padding: '2px 8px ',
          boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.16)',
          color: themes.default.clientMarkerBg,
          fontSize: '0.75rem',
          textTransform: 'none',
          letterSpacing: '0.4px',
        }}
      >
        {t('add_warehouse')}
      </Button>
    </Stack>
  );

  return (
    <>
      {actions.length > 0 && mappingCan && (
        <MenuContainer onClick={(e) => e.stopPropagation()}>
          <NestedMenu
            actions={actions}
            customAnchor={<AddWarehouseIcon />}
            menuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              sx: {
                '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
                  borderRadius: '4px !important',
                },
              },
              PaperProps: {
                style: {
                  maxHeight: '250px',
                  marginTop: '10px',
                  boxShadow: `0px 4px 20px -8px ${themes.default.black}`,
                },
              },
            }}
          />
        </MenuContainer>
      )}
    </>
  );
}

export default AddMapping;

const MenuContainer = styled.div`
  display: flex;
  align-items: left;
  cursor: pointer;
  margin-top: 20px;
`;

const WarehouseContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ShortName = styled.span<{ color: string }>`
  font-weight: bold;
  background-color: ${(props) => props.color};
  padding: 2px 8px;
  width: 45px;
  border-radius: 4px;
  text-align: center;
`;

const InactiveStatus = styled.span`
  background-color: #feebee;
  padding: 3px 8px;
  border-radius: 100px;
  font-size: 10px;
  width: 56px;
  color: #c62828;
  text-transform: capitalize;
`;
