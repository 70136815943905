import { Middleware } from '@reduxjs/toolkit';
import api from 'common/services/api';

let previousAuthCustomer: string | null = null;
let previousOrganizationId: string | null = null;

export const authOrganizationMiddleware: Middleware =
  (store) => (next) => (action) => {
    const result = next(action);
    const state = store.getState();

    const currentAuthCustomer =
      state.auth?.user?.currentAgent?._customer?._id || null;
    const currentOrganizationId =
      state.auth?.user?._currentOrganization?._id || null;

    if (
      currentAuthCustomer !== previousAuthCustomer ||
      currentOrganizationId !== previousOrganizationId
    ) {
      previousAuthCustomer = currentAuthCustomer;
      previousOrganizationId = currentOrganizationId;

      store.dispatch(api.util.resetApiState());
    }

    return result;
  };
