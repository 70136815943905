import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, uniqBy } from 'lodash';

import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectAuthUser,
  selectMainMenuSize,
} from '../../slices/auth/selectors';
import { selectTheme } from '../../../styles/theme/slice/selectors';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { Divider, Select, Stack, Tooltip } from '@mui/material';
import styled from 'styled-components/macro';
import { Customization } from '../MainNav/Customizations';
import i18next from 'i18next';
import { LOGOUT, ORDORIA_VIEWCUSTOMER, SETTINGS } from 'utils/routes';
import { handlesetAnchorFunction } from 'functions/setAnchorFunction';
import { themes } from 'styles/theme/themes';
import { useApplication } from 'hooks/useApplication';
import { useModal } from '../Dialog/hooks';
import If from '../If';
import {
  useGetAuthActivitiesStatsQuery,
  useSwitchAgentMutation,
} from 'common/services/authApi';
import { getId } from 'common/helpers/document';
import { Icon } from 'app/components/Icon';
import { Chat } from 'app/components/Chat';
import {
  CaretDown,
  UserCircle,
  Info,
  SignOut,
  FileArrowUp,
  Signature,
} from '@phosphor-icons/react';
import { useDispatch } from 'react-redux';
import { toolsActions } from 'app/slices/tools';
import { MotivationalQuote } from '../MotivationalQuote';
import { Notifications } from '../NotificationModal';
import { selectNotifications } from 'common/store/app/selectors';

import { changeCartVisibilityState } from 'common/store/cart';
import { useGetCartQuery } from 'common/services/cartApi';
import { TwoViewModal } from '../TwoViewModal';

import ReportABug from './components/ReportABug';
import UserAvatar from '../UserAvatar';
import CompaniesIcon from 'assets/img/companies.svg';
import { getCartVisibility } from 'common/store/cart/selectors';
import { RegistrationDocumentsModal } from '../RegistrationDocuments';
import { PaymentModal } from '../PaymentModal';
import { useNavLock } from 'hooks/useNavLock';

import { AskAIButton } from './components/AskAIButton';
import AiaSearchField from '../AskAia/components/AiaSearchField';
import { useGuardedAction } from 'hooks/useGuardedAction';
import { AnimationIconButton } from '../AnimationIconButton';
import convoAnimation from 'assets/json/convo.json';
import cameraAnimation from 'assets/json/camera.json';
import bellAnimation from 'assets/json/notifications.json';
import cartAnimation from 'assets/json/cart.json';

const LeftActionButtonSx = {
  height: '30px',
  width: '30px',
  padding: '0',
  borderRadius: '4px',
  border: `1px solid ${themes?.default?.gainsboro2}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  transition: 'all 0.3s',
};

const LeftActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 0.4rem;
  // z-index: 999;

  .search-field {
    transition:
      opacity 0.3s ease 0.3s,
      transform 0.3s ease 0.3s;
    position: absolute;
    left: 0;
    width: 23rem;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    margin: 0 0.125rem 0 0.625rem;
    font-size: 0.8125rem;
    font-weight: 400;
    gap: 0.25rem;
    border-radius: 0.25rem;
    // border: 0.0625rem solid ${({ theme }) => theme.gainsboro2};
    background: ${({ theme }) => theme.grey};
    outline: none;
  }
  gap: 0.25rem;
  margin-left: 1rem;
`;

const LeftActionsIconProps = {
  size: 20,
  color: themes?.default?.iconColor,
};

export function AppHeader() {
  // AIA Chat SideBar

  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState(30);
  const navigate = useNavigate();
  const theme = useSelector(selectTheme);
  const authUser = useSelector(selectAuthUser);
  const mainMenuSize = useSelector(selectMainMenuSize);
  const currentApplication = useApplication();
  const [chatOpen, setChatOpen] = useState(false);
  const [notifOpen, setNotifOpen] = useState(false);
  const { data: cart } = useGetCartQuery();
  const { navLocked } = useNavLock();
  const { executeAction } = useGuardedAction();

  const [openRegistrationDocuments, setOpenRegistrationDocuments] =
    useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElCustomer, setAnchorElCustomer] =
    React.useState<null | HTMLElement>(null);
  const [settingsDrawerIsOpen, setSettingsDrawerIsOpen] = useState(false);
  const { openModal, closeModal } = useModal();

  const [openMotivationQuote, setOpenMotivationQuote] =
    useState<boolean>(false);

  const [switchAgent] = useSwitchAgentMutation();

  const openCustomer = Boolean(anchorElCustomer);

  const isCustomer = !isEmpty(authUser?.currentAgent?._customer);
  const [ReportABugDrawerIsOpen, setReportABugDrawerIsOpen] = useState(false);
  const handleOpenCustomerMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const action = () => setAnchorElCustomer(event.currentTarget);
    executeAction(action, navLocked);
  };

  const handleOpenCart = useCallback(() => {
    const action = () => dispatch(changeCartVisibilityState(true));
    executeAction(action, navLocked);
  }, [dispatch, executeAction, navLocked]);

  const handleOpenNotifications = useCallback(() => {
    const action = () => setNotifOpen(true);
    executeAction(action, navLocked);
  }, [executeAction, navLocked]);

  const handleSwitchAgent = async (agentId) => {
    setAnchorElCustomer(null);
    await switchAgent({ agentId }).unwrap();
  };

  const handleOpenRegistrationDocuments = () => {
    const action = () => {
      handleClose();
      setOpenRegistrationDocuments(true);
    };
    executeAction(action, navLocked);
  };

  const handleOpenPaymentModal = () => {
    const action = () => {
      handleClose();
      setOpenPaymentModal(true);
    };

    executeAction(action, navLocked);
  };

  const handleOpenUserDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    const action = () => handlesetAnchorFunction(e, setAnchorEl);
    executeAction(action, navLocked);
  };

  useGetAuthActivitiesStatsQuery('');

  const activitiesStats = useSelector(selectNotifications);
  const cartIsVisible = useSelector(getCartVisibility);

  if (!authUser) {
    return null;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasSeenModal = localStorage.getItem('hasSeenModal');

  if (!hasSeenModal) {
    setOpenMotivationQuote(true);
    localStorage.setItem('hasSeenModal', 'true');
  }

  const customerAgents =
    uniqBy(authUser.agents?.filter(
      (a: any) =>
        getId(a._organization) === getId(authUser?._currentOrganization) &&
        a?._customer &&
        !a.isArchived &&
        !a.isLocked,
    ), '_customer._id') || [];

  const handleLogout = () => {
    closeModal();
    handleClose();
    dispatch(toolsActions.setZoomIn({ status: false }));
    navigate(LOGOUT);
  };

  const inSettings = window.location.pathname?.includes(SETTINGS);
  function handleReportABugDrawer() {
    handleClose();
    setReportABugDrawerIsOpen(true);
  }

  return (
    <>
      <RegistrationDocumentsModal
        open={openRegistrationDocuments}
        onClose={() => setOpenRegistrationDocuments(false)}
      />
      <PaymentModal
        open={openPaymentModal}
        onClose={() => setOpenPaymentModal(false)}
      />

      <TwoViewModal
        title={t('Report-a-bug')}
        onOpen={ReportABugDrawerIsOpen}
        onClose={() => setReportABugDrawerIsOpen(false)}
        width="453px"
        onBackClick={() => setReportABugDrawerIsOpen(false)}
        headerTitleProps={{ margin: 0, padding: 0 }}
        hasArrowBack={true}
      >
        <ReportABug onClose={() => setReportABugDrawerIsOpen(false)} />
      </TwoViewModal>
      <Drawer
        anchor="right"
        open={settingsDrawerIsOpen}
        onClose={() => setSettingsDrawerIsOpen(false)}
      >
        <Customization setSettingsDrawerIsOpen={setSettingsDrawerIsOpen} />
      </Drawer>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          transition: '0.15s padding ease-out',
          height: theme.topBarHeight,
          minHeight: theme.topBarHeight,
          paddingLeft:
            mainMenuSize === 'minify'
              ? theme.mainNavWidthMin
              : theme.mainNavWidth,
          background: themes.default.accordionWhiteBg,
          borderBottom: `1px solid ${themes?.default?.lightFill}`,
          /* grey-shadow-web */
          boxShadow: 'none !important',
          zIndex: '2201',
          width: !!inSettings
            ? `calc(100% - ${themes.default.settingsNavWidth} - 2px)`
            : '100%',
        }}
      >
        <Toolbar
          style={{
            paddingRight: 0,
            paddingLeft: mainMenuSize === 'minify' ? '25px' : '285px',
            height: theme.topBarHeight,
            minHeight: theme.topBarHeight,
          }}
        >
          {/* Ai Chat Component  */}

          {/* <form
            className="app-container"
            style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            onSubmit={handleSubmit}
          > */}
          {/* <InputStyle type="text" placeholder="Search" /> */}
          {/* <ChatButton onClick={toggleSidebar} className="chat-btn">
              <Icon icon={<Sparkle weight="fill" />} size={14} color="#fff" />
              <ChatButtonText>{t('ask.aia')}</ChatButtonText>
            </ChatButton> */}
          {/* <AskAIA isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          </form> */}

          <If condition={!inSettings && !isCustomer}>
            {/* <LeftActionsWrapper height={themes.topBarHeight}> */}
            <LeftActionsWrapper>
              <AiaSearchField />

              <AskAIButton />
            </LeftActionsWrapper>
          </If>

          {/* hide this ai old section for now */}
          {/* <If condition={!inSettings && !isCustomer}>
            <LeftActionsWrapper height={theme.topBarHeight}>
              <SearchField
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
                sx={{
                  '& input': {
                    // textAlign: !search ? 'center' : 'left',
                    textAlign: 'center',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <div
                      style={{
                        position: 'absolute',
                        top: '6px',
                        left: !search ? '50%' : '10px',
                        transform: !search
                          ? 'translate(-45%, 0)'
                          : 'translate(0, 0)',
                        transitionDuration: '0.2s',
                        transitionTimingFunction: 'ease-out',
                        margin: !search ? 'auto' : '0',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '0.75rem',
                        color: `${themes?.default?.iconColor}cc`,
                        gap: '4px',
                        userSelect: 'none',
                      }}
                    >
                      <div style={{ paddingBottom: '2px' }}>
                        <Icon
                          icon={<MagnifyingGlass weight="bold" />}
                          size={14}
                          color={themes?.default?.iconColor}
                        />
                      </div>
                      {!search && t('common.place_holders.search')}
                    </div>
                  ),
                }}
              />
              <AskAIButton />
            </LeftActionsWrapper>
          </If> */}

          {authUser.currentAgent?._customer && (
            <CustomerMenuWrapper>
              <CustomerButtonWrapper
                style={{
                  clipPath: 'border-box',
                  border: 'double 1px transparent',
                  backgroundImage: themes.default.purpleBlueBorderGradiant,
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'content-box, border-box',
                }}
              >
                <Button
                  aria-haspopup="true"
                  onClick={handleOpenCustomerMenu}
                  size="small"
                  fullWidth
                  startIcon={<img src={CompaniesIcon} alt="companies" />}
                  endIcon={
                    <If condition={customerAgents?.length > 1}>
                      <Icon
                        icon={<CaretDown />}
                        size={12}
                        style={{
                          rotate: openCustomer ? '180deg' : '',
                          transition: 'rotate 0.1s ease-in-out',
                        }}
                      />
                    </If>
                  }
                  sx={{
                    padding: '3px 6px',
                    color: 'black',
                    background: themes.default.lightPurpleBgGradiant,
                  }}
                >
                  {authUser.currentAgent?._customer.code} -{' '}
                  {authUser.currentAgent?._customer.legalName}
                </Button>
              </CustomerButtonWrapper>

              <If condition={customerAgents.length > 1}>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElCustomer}
                  open={openCustomer}
                  onClose={() => setAnchorElCustomer(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {customerAgents.map((agent: any) => (
                    <MenuItem onClick={() => handleSwitchAgent(agent._id)}>
                      {agent?._customer.code} - {agent?._customer.legalName}
                    </MenuItem>
                  ))}
                </Menu>
              </If>
            </CustomerMenuWrapper>
          )}
          <HemeraTabMenu />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          <Actions>
            {
              currentApplication.id === 'ordoria' && false ? (
                <Select
                  variant="outlined"
                  color="info"
                  defaultValue="eur"
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    marginRight: '10px',
                  }}
                >
                  <MenuItem value="eur">€ EUR</MenuItem>
                  <MenuItem value="usd">$ USD</MenuItem>
                </Select>
              ) : null
              // <IconButton
              //   size="large"
              //   aria-label="account of current user"
              //   aria-controls="menu-appbar"
              //   aria-haspopup="true"
              //   color="inherit"
              //   style={{ marginRight: '10px', marginBottom: '13px' }}
              // >
              //   <ChatRoundedIcon style={{ color: theme.icon }} />
              // </IconButton>
            }

            {/* <Tooltip title={t('nav.appHeader.activity')} arrow>
              <IconButton
                size="large"
                aria-label={t('nav.appHeader.activity')}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                style={{ marginRight: '10px', marginBottom: '13px' }}
              >
                <Notifications style={{ color: theme.icon }} />
              </IconButton>
            </Tooltip> */}
            <RightActionsWrapper>
              <div>
                <If
                  condition={currentApplication.id === 'ordoria' && !isCustomer}
                >
                  <AnimationIconButton
                    onClick={() =>
                      dispatch(changeCartVisibilityState(!cartIsVisible))
                    }
                    animation={cartAnimation}
                    count={cart?.products.length}
                    disabled={navLocked}
                  />
                </If>

                {!isCustomer && !inSettings && (
                  <AnimationIconButton
                    onClick={handleOpenCart}
                    animation={cameraAnimation}
                    count={unreadMessagesCount}
                  />
                )}

                {!isCustomer && (
                  <>
                    <AnimationIconButton
                      onClick={() =>
                        executeAction(() => setChatOpen(true), navLocked)
                      }
                      animation={convoAnimation}
                      count={unreadMessagesCount}
                    />
                    <AnimationIconButton
                      onClick={handleOpenNotifications}
                      animation={bellAnimation}
                      count={activitiesStats?.totalImportant || 0}
                    />
                  </>
                )}
              </div>
              <If condition={!!isCustomer}>
                <Stack direction="row" gap="10px">
                  <Tooltip title={t('sepa_mandate')}>
                    <IconButton
                      sx={LeftActionButtonSx}
                      onClick={handleOpenPaymentModal}
                    >
                      <Icon {...LeftActionsIconProps} icon={<Signature />} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('registration_documents')}>
                    <IconButton
                      sx={LeftActionButtonSx}
                      onClick={handleOpenRegistrationDocuments}
                    >
                      <Icon {...LeftActionsIconProps} icon={<FileArrowUp />} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </If>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => handleOpenUserDropdown(e)}
                color="default"
                disableRipple
                sx={{
                  marginRight: '18px',
                  width: '56px',
                  height: '32px',
                  padding: '4px 8px',
                  gap: '4px',
                  borderRadius: '30px',
                  background: themes?.default?.grey,
                }}
              >
                {/* <UserName>
                {authUser.fullName}
                <UserRole style={{ fontWeight: 'normal' }}>
                  {authUser?.agents?.[0]?.['_role']?.localeName?.[
                    i18next?.resolvedLanguage ? i18next?.resolvedLanguage : 'en'
                  ] || ''}
                </UserRole>
              </UserName> */}
                <UserAvatar showStatus />
                <Icon
                  icon={<CaretDown weight="fill" />}
                  color={themes?.default?.iconColor}
                  size={14}
                />
              </IconButton>
            </RightActionsWrapper>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 50,
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                marginTop: '-10px',
                minWidth: '180px',
                '& .MuiList-root': {
                  padding: '0 !important',
                },
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div
                style={{
                  minHeight: '185px',
                  width: 'max-content',
                  minWidth: '180px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  padding: '12px',
                }}
              >
                <UserProfileBadge>
                  <UserAvatar showStatus />
                  <UserInfoContainer>
                    <h4>{authUser.fullName}</h4>
                    <p>
                      {authUser?.currentAgent?.['_role']?.localeName?.[
                        i18next?.resolvedLanguage
                          ? i18next?.resolvedLanguage
                          : 'en'
                      ] || ''}
                    </p>
                  </UserInfoContainer>
                </UserProfileBadge>
                <Divider sx={{ color: themes?.default?.gainsboro2 }} />
                <UserOptionsContainer>
                  <MenuItem
                    sx={{ padding: '4px' }}
                    onClick={() => {
                      if (!isCustomer) return;
                      handleClose();
                      const path = generatePath(ORDORIA_VIEWCUSTOMER, {
                        id: authUser?.currentAgent?._customer?._id,
                      });
                      navigate(path);
                    }}
                  >
                    <OptionContainer>
                      <Icon
                        icon={<UserCircle />}
                        size={16}
                        color={themes?.default?.iconColor}
                      />
                      <p>{t('drawer.title.profile')}</p>
                    </OptionContainer>
                  </MenuItem>
                  {/* <MenuItem sx={{ padding: '4px' }} onClick={() => {}}>
                    <OptionContainer>
                      <Icon
                        icon={<Gear />}
                        size={16}
                        color={themes?.default?.iconColor}
                      />
                      <p>{t('Add_Client_Settings')}</p>
                    </OptionContainer>
                  </MenuItem> */}
                  <MenuItem
                    sx={{ padding: '4px' }}
                    onClick={() => handleReportABugDrawer()}
                  >
                    <OptionContainer>
                      <Icon
                        icon={<Info />}
                        size={16}
                        color={themes?.default?.iconColor}
                      />
                      <p>{t('report_a_bug')}</p>
                    </OptionContainer>
                  </MenuItem>
                  <If condition={!!isCustomer}>
                    <MenuItem
                      sx={{ padding: '4px' }}
                      onClick={handleOpenRegistrationDocuments}
                    >
                      <OptionContainer>
                        <Icon
                          icon={<FileArrowUp />}
                          size={16}
                          color={themes?.default?.iconColor}
                        />
                        <p>{t('registration_documents')}</p>
                      </OptionContainer>
                    </MenuItem>
                    <MenuItem
                      sx={{ padding: '4px' }}
                      onClick={handleOpenPaymentModal}
                    >
                      <OptionContainer>
                        <Icon
                          icon={<Signature />}
                          size={16}
                          color={themes?.default?.iconColor}
                        />
                        <p>{t('sepa_mandate')}</p>
                      </OptionContainer>
                    </MenuItem>
                  </If>
                </UserOptionsContainer>
                <Divider sx={{ color: themes?.default?.gainsboro2 }} />
                <MenuItem
                  sx={{ padding: '4px' }}
                  onClick={() => {
                    openModal({
                      title: t('auth.pages.logout.confirmTitle'),
                      cancel: true,
                      action: {
                        actionCallback: handleLogout,
                        actionText: t('common.buttons.ok'),
                      },
                    });
                  }}
                >
                  <OptionContainer>
                    <Icon
                      icon={<SignOut />}
                      size={16}
                      color={themes?.default?.iconColor}
                    />
                    <p>{t('nav.appHeader.account.logout')}</p>
                  </OptionContainer>
                </MenuItem>
              </div>
            </Menu>
          </Actions>
        </Toolbar>

        {/* <Button
          variant="contained"
          onClick={() => setSettingsDrawerIsOpen(true)}
          disableElevation
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: theme.topBarHeight,
            height: theme.topBarHeight,
            borderRadius: 0,
          }}
        >
          <DashboardCustomize />
        </Button> */}
        <Chat
          open={chatOpen}
          setChatOpen={setChatOpen}
          unreadMessagesCount={unreadMessagesCount}
          setUnreadMessagesCount={setUnreadMessagesCount}
        />

        <MotivationalQuote
          open={openMotivationQuote}
          handleClose={() => setOpenMotivationQuote(false)}
        />

        <Notifications open={notifOpen} onClose={() => setNotifOpen(false)} />
      </AppBar>
    </>
  );
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomerMenuWrapper = styled.div`
  display: flex;
  padding-left: 24px;
`;

const HemeraTabMenu = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  align-self: end;
  height: 100%;
  .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
    height: 100%;
  }
`;

const RightActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

const UserProfileBadge = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 4px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  h4 {
    margin: 0;
    font-size: 0.92rem;
    line-height: 15.23px;
    letter-spacing: 0.05em;
    color: ${themes?.default?.textBlack};
  }
  p {
    margin: 0;
    font-size: 0.78rem;
    line-height: 12.89px;
    letter-spacing: 0.05em;
    color: ${themes?.default?.nobel};
  }
`;

const UserOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    font-size: 0.92rem;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: ${themes?.default?.textColorPrimary};
  }
`;

const CustomerButtonWrapper = styled.div`
  border-radius: 3px;
`;
