import { Stack, Typography } from '@mui/material';
import EmptyStateIllustration from 'assets/img/ORDORIA/Cart/EmptyStateIllustration.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';

export const EmptyCartLayout = () => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  return (
    <Wrapper>
      <Stack gap="12px">
        <ImageContainer>
          <img src={EmptyStateIllustration} alt="" />
        </ImageContainer>
        <Typography
          // marginTop="-46px"
          fontWeight="500"
          fontSize="16px"
          lineHeight="26px"
          letterSpacing="0.15px"
          textAlign="center"
          color={theme.black2}
        >
          {t('cart.empty')}
        </Typography>
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
