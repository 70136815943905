import { IDiscountsRecord } from 'app/components/Restrictions/types/discounts.types';
import { themes } from 'styles/theme/themes';

export enum ContractTabs {
  CATEGORY = 'category',
  SUB_CATEGORY = 'subcategory',
  CHILD_CATEGORY = 'childcategory',
  PRODUCT = 'product',
  OFFERS = 'offers',
  PRE_ORDER = 'pre-order',
}

export interface Contract {
  key: ContractTabs;
  name: string;
  startAdornament: JSX.Element;
  itemsApproved: number;
  itemsPending: number;
  itemsTotal: number;
  level?: number;
}

export enum ContractRowStatus {
  APPROVED = 'approved',
  PENDING_APPROVAL = 'pending_approval',
}

export type ContractRowStatusType = `${ContractRowStatus}`;

export interface Note {
  _user: string;
  _userAgent: {
    _id: string;
    fullName: string;
    pictureUrl: string;
  };
  content: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface ContractTabData {
  key: ContractTabs;
  name: string;
  icon: React.JSX.Element;
  level?: number;
}

export interface ContractRowData {
  _id: string;
  discounts: IDiscountsRecord;
  status: ContractRowStatusType;
  notes: Note[];
  disabled: boolean;
  _category: {
    _id: string;
    code: string;
    name: string;
    level: number;
    _parentCategory?: {
      _id: string;
      code: string;
      name: string;
      level: number;
    };
  };
}

export const ContractRowStatusChipConfig = {
  [ContractRowStatus.APPROVED]: {
    fill: themes.default.green800 + '14',
    color: themes.default.textDelivered,
  },
  [ContractRowStatus.PENDING_APPROVAL]: {
    fill: themes.default.warningMain + '1F',
    color: themes.default.brown,
  },
};

export type UpdateCategoryDiscounts = {
  _category: string;
  discounts: Record<string, number>;
};

export type CountAtLevels = Record<`${number}`, number>;

export const levelTranslationKeys: Record<number, string> = {
  0: 'discount_level_0', // Category
  1: 'discount_level_1', // Subcategory
  2: 'discount_level_2', // Child Category
};
