import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { ChatContext } from 'app/components/Chat';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { TextField, InputAdornment, ButtonBase, Slide } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import {
  Microphone,
  PencilSimple,
  X,
  Check,
  Paperclip,
  TrashSimple,
  ArrowBendUpLeft,
} from '@phosphor-icons/react';
import PaperPlane from 'assets/img/chat/PaperPlane.svg';
import useWebSocket from 'react-use-websocket';
import { selectAuthToken } from 'common/store/auth/selectors';
import { AudioRecorder } from 'app/components/Chat/components/AudioRecorder';
import { useUploadMutation } from 'common/services/files';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useDebounce } from 'common/hooks/useDebounce';
import { useEditChatMessageMutation } from 'common/services/chatApi';
import { CircularProgress } from '@material-ui/core';
import { useToaster } from 'hooks/useToaster';
import { ENV } from 'common/constants';
import { EmojiPopover } from '../EmojisPopover';
import { FileIcon } from 'app/components/Chat/components/ChatWindow/components/FileIcon';
import EllipsisText from 'app/components/EllipsisText';
import { appActions } from 'common/store/app';
import { useSelector, useDispatch } from 'react-redux';
import { selectChatDraftMessages } from 'common/store/app/selectors';

const allowedFileTypes: string[] = [
  'txt',
  'pdf',
  'csv',
  'xlsx',
  'doc',
  'docx',
  'ppt',
  'pptx',
];

interface Props {
  isNewChat?: boolean;
  editMode: boolean;
  editMessageUuid: string;
  editMessageContent: string;
  handleStopEditing: Function;
  replyMode?: boolean;
  replyTo?: {
    messageUuid: string;
    messageContent: string;
    contentType: string;
    contentName?: string;
    senderName: string;
  } | null;
  handleStopReplying: () => void;
}

export const MessageInput: React.FC<Props> = ({
  isNewChat,
  editMode,
  editMessageUuid,
  editMessageContent,
  handleStopEditing,
  replyMode,
  replyTo,
  handleStopReplying,
}) => {
  /* ------------------------------- Use States ------------------------------- */
  const [message, setMessage] = useState<{ content: string; chatId: string }>({
    content: '',
    chatId: '',
  });
  const [messageInputFocused, setMessageInputFocused] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recorderAudio, setRecorderAudio] = useState<any>(null);
  const inputRef: any = useRef(null);
  const [editedNewMessage, setEditedNewMessage] = useState('');
  const [savingEdits, setSavingEdits] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [selectedFileIndex, setSelectedFileIndex] = useState<any>(null);

  /* ------------------------------- Variables -------------------------------- */
  const { t } = useTranslation();
  const authToken = useSelector(selectAuthToken);
  const theme = useSelector(selectTheme);
  const { selectedChatId } = useContext(ChatContext);
  const toast = useToaster();
  const debouncedEditMode = useDebounce(editMode, 200);
  const debouncedReplyMode = useDebounce(replyMode, 200);
  const fileInputRef = useRef<any>(null);
  const draftMessages = useSelector(selectChatDraftMessages) || {};
  const dispatch = useDispatch();

  /* ---------------------------------- Apis ---------------------------------- */
  const [uploadFile] = useUploadMutation();
  const [editMessage] = useEditChatMessageMutation();

  /* ------------------------------ Web Sockets ------------------------------- */
  const { sendMessage } = useWebSocket(ENV.CHAT_WS_URL, {
    queryParams: {
      token: authToken || '',
      channel: selectedChatId,
    },
  });

  /* ----------------------------- Functions ----------------------------- */
  const handleSendMessage = async () => {
    if (message.content === '' && !messageInputFocused && files?.length === 0)
      return;

    if (files?.length > 0) {
      try {
        for (const file of files) {
          let type: 'file' | 'images' | '' = '';
          if (file?.fileData?.type?.indexOf('image/') > -1) {
            type = 'images';
          } else {
            type = 'file';
          }

          const { url, name } = await handleUploadFile(file?.fileData);
          sendMessage(
            JSON.stringify({
              data: url,
              name: name,
              type: type,
              description: file?.description,
            }),
          );
        }
        setFiles([]);
      } catch (err) {
        console.log(err);
      }
    } else if (replyMode) {
      sendMessage(
        JSON.stringify({
          data: message.content,
          reply: replyTo?.messageUuid,
          type: 'text',
        }),
      );
      handleCancelEditMessage();
    } else if (message.content?.trim()?.length > 0) {
      sendMessage(JSON.stringify({ data: message.content, type: 'text' }));
      handleClearInput();
      deleteDraftForCurrentContact();
    }
  };

  const handleSendAudio = async () => {
    setIsRecording(false);
    try {
      const { url, name } = await handleUploadFile(recorderAudio);
      sendMessage(
        JSON.stringify({
          data: url,
          name: name,
          type: 'audio',
        }),
      );
    } catch (err) {
      console.log(err);
    }
    setRecorderAudio(null);
  };

  const handleSaveMessageEdits = async () => {
    try {
      setSavingEdits(true);
      const newMessageData: any = await editMessage({
        id: editMessageUuid,
        body: { content: editedNewMessage },
      });
      setSavingEdits(false);
      if (newMessageData?.error) {
        toast(5000, 'error', t('chats.message.edit.error'));
      }
      handleStopEditing(newMessageData?.data?.data);
    } catch (error) {
      console.error('error while editing message: ', error);
      setSavingEdits(false);
      return;
    }
  };

  const handleInputFocus = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event?.target?.files);
    if (newFiles?.length === 0) return;

    newFiles.forEach((newFile: any, index) => {
      if (newFile?.type.indexOf('image/') > -1) {
        setFiles((prevState) => [
          ...prevState,
          { id: index, description: '', fileData: newFile },
        ]);
        return;
      }

      const newFileType = getFileNameFromType(newFile?.name);
      if (allowedFileTypes.some((type) => type === newFileType)) {
        setFiles((prevState) => [
          ...prevState,
          { id: index, description: '', fileData: newFile },
        ]);
        return;
      }

      toast(5000, 'error', t('settings.companyProfile.errors.typeFile'));
      return;
    });
  };

  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove),
    );
  };

  const handleUploadFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      const { url } = await uploadFile({
        formData,
        preserveName: false,
        persist: false,
        objectType: 'chat',
        type: 'file',
        objectId: '',
      }).unwrap();
      return { url, name: file.name };
    },
    [uploadFile],
  );

  function getFileNameFromType(fileName) {
    const fileNameSplitted = fileName?.split('.');
    return fileNameSplitted[fileNameSplitted?.length - 1];
  }

  const handleInputChange = (e) => {
    if (editMode) {
      setEditedNewMessage(e.target.value);
    } else if (totalFiles > 0) {
      const filesArrayCopy = [...files];
      filesArrayCopy[selectedFileIndex].description = e.target.value;
      setFiles(filesArrayCopy);
    } else {
      setMessage({
        chatId: selectedChatId,
        content: e.target.value,
      });
    }
  };

  const handleClearInput = () => {
    setMessage({ content: '', chatId: '' });
  };

  const handleCancelEditMessage = () => {
    handleClearInput();
    setEditedNewMessage('');
    handleStopReplying();
  };

  const deleteDraftForCurrentContact = () => {
    const draftMessagesCopy = { ...draftMessages };
    delete draftMessagesCopy[message.chatId];

    dispatch(
      appActions.setChatDraftMessages({
        ...draftMessagesCopy,
      }),
    );
  };

  /* ----------------------------- Use Effects ----------------------------- */
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isNewChat, inputRef, selectedChatId]);

  useEffect(() => {
    if (editMode || replyMode) {
      setEditedNewMessage(editMessageContent);
      handleInputFocus();
    }
  }, [editMode, replyMode]);

  useEffect(() => {
    if (!files) return;
    setTotalFiles(files?.length);
  }, [files]);

  useEffect(() => {
    if (totalFiles === 0) return;
    setSelectedFileIndex(totalFiles - 1);
  }, [totalFiles]);

  useEffect(() => {
    const changedChat =
      selectedChatId && !!message.chatId && selectedChatId !== message.chatId;

    if (changedChat) {
      if (message.content !== '') {
        dispatch(
          appActions.setChatDraftMessages({
            ...draftMessages,
            [message.chatId]: message.content,
          }),
        );
      } else if (draftMessages?.[message.chatId]) {
        const draftMessagesCopy = { ...draftMessages };
        delete draftMessagesCopy[message.chatId];

        dispatch(
          appActions.setChatDraftMessages({
            ...draftMessagesCopy,
          }),
        );
      }
      handleClearInput();
    }
  }, [selectedChatId]);

  useEffect(() => {
    if (JSON.stringify(draftMessages) === JSON.stringify({})) {
      setMessage((prevState) => ({
        ...prevState,
        chatId: selectedChatId,
      }));
      return;
    }

    const draftContent = draftMessages?.[selectedChatId] || '';

    setMessage({
      content: draftContent,
      chatId: selectedChatId,
    });
  }, [selectedChatId, draftMessages]);

  useEffect(() => {
    const draftContent = draftMessages?.[selectedChatId] || '';

    if (message.content === '' && !!draftContent) {
      deleteDraftForCurrentContact();
    }
  }, [message]);

  /* ------------------------------ Use Memos ------------------------------ */
  const UploadedImagesPreview = useMemo(() => {
    const RemoveButtonSx = {
      width: '16px',
      height: '16px',
      position: 'absolute',
      top: '-7px',
      right: '-5px',
      borderRadius: '5px',
      background: themes?.default?.accordionWhiteBg,
      display: 'flex',
      alignItems: 'center',
      justifyContetn: 'center',
      opacity: '0',
      transitionDuration: '0.2s',
    };

    if (files?.length === 0) return null;

    return (
      <FilesPreviewContainer>
        {files?.map((file, index) => {
          if (file?.fileData?.type?.indexOf('image/') > -1) {
            return (
              <ImagePreviewWrapper
                selected={selectedFileIndex === index}
                theme={theme}
              >
                <img
                  src={URL.createObjectURL(file?.fileData)}
                  alt={file?.fileData?.name}
                  onClick={() => setSelectedFileIndex(index)}
                />
                <ButtonBase
                  className="removeButton"
                  sx={RemoveButtonSx}
                  onClick={() => handleRemoveFile(index)}
                >
                  <Icon
                    icon={<TrashSimple />}
                    color={themes?.default?.progressRed}
                    size={10}
                  />
                </ButtonBase>
              </ImagePreviewWrapper>
            );
          } else if (file?.fileData?.type?.indexOf('application/') > -1) {
            const fileType = getFileNameFromType(file?.fileData?.name);
            if (allowedFileTypes.some((type) => type === fileType)) {
              return (
                <div
                  className="documentLeftSection"
                  onClick={() => setSelectedFileIndex(index)}
                  style={
                    selectedFileIndex === index
                      ? {
                          border: `1px solid ${theme.primary}`,
                        }
                      : {}
                  }
                >
                  <FileIcon
                    fileName={file?.fileData?.name || ''}
                    fileType={fileType}
                    size={{ height: 30, width: 30 }}
                  />
                  <div>
                    <EllipsisText
                      fontSize="0.71rem"
                      text={file?.fileData?.name || ''}
                      width={180}
                    />
                    <p className="documentType">{fileType?.toUpperCase()}</p>
                  </div>
                  <ButtonBase
                    className="removeButton"
                    sx={RemoveButtonSx}
                    onClick={() => handleRemoveFile(index)}
                  >
                    <Icon
                      icon={<TrashSimple />}
                      color={themes?.default?.progressRed}
                      size={10}
                    />
                  </ButtonBase>
                </div>
              );
            }
          }
          return null;
        })}
      </FilesPreviewContainer>
    );
  }, [files, selectedFileIndex]);

  /* ----------------------------------------------------------------------- */
  return (
    <Wrapper editMode={editMode || replyMode}>
      {(editMode || debouncedEditMode) && (
        <Slide in={editMode} direction="up">
          <EditMessageContainer>
            <Icon icon={<PencilSimple />} size={20} color={theme.primary} />
            <EditMessageContentWrapper theme={theme}>
              <p className="title">{t('editing')}</p>
              <p className="text">{editMessageContent}</p>
            </EditMessageContentWrapper>
            <ButtonBase
              sx={{
                borderRadius: '50px',
                padding: '4px',
                '&:hover': {
                  background: themes?.default?.hoverLightGray,
                },
              }}
              onClick={() => handleStopEditing()}
            >
              <Icon icon={<X />} size={20} />
            </ButtonBase>
          </EditMessageContainer>
        </Slide>
      )}

      {(replyMode || debouncedReplyMode) && (
        <Slide in={replyMode} direction="up">
          <EditMessageContainer>
            <Icon icon={<ArrowBendUpLeft />} size={20} color={theme.primary} />
            <EditMessageContentWrapper theme={theme}>
              <div className="replyInfoWrapper">
                <div className="leftSection">
                  <p className="title">
                    {t('chat.message.reply_to', { name: replyTo?.senderName })}
                  </p>
                  <p className="text">
                    <EllipsisText
                      width={240}
                      text={
                        replyTo?.contentType === 'images'
                          ? '🖼️ Image'
                          : replyTo?.contentType === 'file'
                            ? replyTo?.contentName
                            : replyTo?.messageContent
                      }
                    />
                  </p>
                </div>
                {replyTo?.contentType === 'images' ? (
                  <img src={replyTo?.messageContent} alt="Image" />
                ) : replyTo?.contentType === 'file' ? (
                  <FileIcon
                    fileName={replyTo?.contentName || ''}
                    size={{ height: 38, width: 38 }}
                  />
                ) : null}
              </div>
            </EditMessageContentWrapper>
            <ButtonBase
              sx={{
                borderRadius: '50px',
                padding: '4px',
              }}
              onClick={handleCancelEditMessage}
            >
              <Icon icon={<X />} size={20} />
            </ButtonBase>
          </EditMessageContainer>
        </Slide>
      )}

      <MessageInputContainer editMode={editMode}>
        <TextField
          fullWidth
          size="small"
          multiline
          minRows={1}
          maxRows={2}
          placeholder={t('chat.type_new_message')}
          margin="none"
          value={
            totalFiles > 0
              ? files[selectedFileIndex]?.description
              : editMode
                ? editedNewMessage
                : message.content
          }
          onChange={(e) => handleInputChange(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (e.shiftKey) {
                e.preventDefault();
                setMessage((prev) => ({
                  ...prev,
                  content: prev.content + '\n',
                }));
              } else {
                e.preventDefault();
                if (!editMode) {
                  handleSendMessage();
                } else {
                  handleSaveMessageEdits();
                }
              }
            }
          }}
          sx={{
            '&.MuiInputBase-root': {
              maxHeight: '100px',
              overflowY: 'auto',
            },
            '&.MuiFormControl-root': {
              margin: '0 !important',
            },
            fieldset: {
              border: `1px solid ${themes?.default?.gainsboro2}`,
            },
            'input::placeholder': {
              fontFamily: 'Roboto',
              fontSize: '0.75rem',
              fontWeight: '400',
              lineHeight: '20px',
              color: themes?.default?.textColorDashboard,
            },
            '.MuiInputBase-root':
              files?.length > 0
                ? {
                    height: '92px !important',
                  }
                : {},
            '.MuiInputAdornment-root':
              files?.length > 0
                ? {
                    marginBottom: '24px !important',
                    alignSelf: 'flex-end !important',
                  }
                : {},
            input:
              files?.length > 0
                ? {
                    alignSelf: 'flex-start !important',
                  }
                : {},
          }}
          inputRef={inputRef}
          InputProps={{
            onFocus: () => setMessageInputFocused(true),
            onBlur: () => setMessageInputFocused(false),
            sx: { height: '48px', paddingRight: '12px' },
            endAdornment: (
              <InputAdornment position="end">
                {!editMode ? (
                  <InputFieldActions>
                    {!isRecording && (
                      <>
                        <EmojiPopover
                          afterClose={() => handleInputFocus()}
                          onEmojiSelect={(e) =>
                            setMessage((prevState) => ({
                              chatId: selectedChatId,
                              content: prevState.content + e?.native,
                            }))
                          }
                        />
                        <ButtonBase
                          sx={{
                            borderRadius: '50px',
                            padding: '4px',
                            '&:hover': {
                              background: themes?.default?.hoverLightGray,
                            },
                          }}
                          onClick={() => setIsRecording(true)}
                        >
                          <Icon icon={<Microphone />} size={24} />
                        </ButtonBase>
                        <ButtonBase
                          sx={{
                            borderRadius: '50px',
                            padding: '4px',
                            '&:hover': {
                              background: themes?.default?.hoverLightGray,
                            },
                          }}
                          onClick={() => {
                            if (fileInputRef?.current) {
                              fileInputRef.current.click();
                            }
                          }}
                        >
                          <Icon icon={<Paperclip />} size={24} />
                          <HiddenInput
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                          />
                        </ButtonBase>
                      </>
                    )}
                    {!isRecording && !recorderAudio ? (
                      <ButtonBase
                        onClick={handleSendMessage}
                        sx={{
                          width: '50px',
                          height: '32px',
                          padding: '8px 9px 8px 7px',
                          borderRadius: '4px',
                          background: themes?.default?.greyDisabled,
                        }}
                      >
                        <Icon icon={<img src={PaperPlane} />} />
                      </ButtonBase>
                    ) : (
                      <ButtonBase
                        onClick={handleSendAudio}
                        sx={{
                          width: '50px',
                          height: '32px',
                          padding: '8px 9px 8px 7px',
                          borderRadius: '4px',
                          background: themes?.default?.greyDisabled,
                          '&.Mui-disabled': {
                            cursor: 'not-allowed !important',
                            pointerEvents: 'auto',
                          },
                        }}
                        disabled={isRecording}
                      >
                        <Icon icon={<img src={PaperPlane} />} />
                      </ButtonBase>
                    )}
                  </InputFieldActions>
                ) : (
                  <InputFieldActions>
                    <ButtonBase
                      sx={{
                        borderRadius: '50px',
                        padding: '4px',
                      }}
                      onClick={() => handleSaveMessageEdits()}
                      disabled={savingEdits}
                    >
                      {!savingEdits ? (
                        <Icon
                          icon={<Check weight="bold" />}
                          size={24}
                          color={theme.primary}
                        />
                      ) : (
                        <CircularProgress color="primary" size={24} />
                      )}
                    </ButtonBase>
                  </InputFieldActions>
                )}
              </InputAdornment>
            ),
          }}
        />
        {(isRecording || recorderAudio) && (
          <AudioRecorder
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            recorderAudio={recorderAudio}
            setRecorderAudio={setRecorderAudio}
          />
        )}
        {UploadedImagesPreview}
      </MessageInputContainer>
    </Wrapper>
  );
};

const HiddenInput = styled.input`
  display: none;
`;

const Wrapper = styled.div<{ editMode }>`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MessageInputContainer = styled.div<{ editMode: boolean }>`
  background: ${themes?.default?.accordionWhiteBg};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 12px 20px;
  border-top: 1px solid ${themes?.default?.gainsboro2};
  box-shadow: ${(props) =>
    props.editMode ? '0px 0px 12px 0px #0000000d' : null};
  position: relative;
  z-index: 1;
`;

const InputFieldActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const EditMessageContainer = styled.div`
  flex: 1;
  min-height: 46px;
  max-height: 46px;
  padding: 4px 28px;
  box-shadow: 0px 0px 12px 0px #0000000d;
  border-top: 1px solid ${themes?.default?.gainsboro2};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const EditMessageContentWrapper = styled.div<{ theme }>`
  flex: 1;
  width: calc(100% - 20px - 8px - 8px - 28px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .replyInfoWrapper {
    display: flex;
    justify-content: space-between;
  }
  .replyInfoWrapper .leftSection {
    flex: 1;
    width: calc(100% - 20px - 8px - 8px - 28px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .replyInfoWrapper img {
    height: 38px;
    width: 38px;
    object-fit: cover;
    border-radius: 0 4px 4px 0;
  }

  p {
    height: 18px;
    margin: 0;
    font-family: Roboto;
    font-size: 0.6875rem;
    line-height: 18px;
    text-align: left;
  }

  .title {
    font-weight: 700;
    color: ${(props) => props.theme.primary};
  }

  .text {
    color: ${themes?.default?.nobel};
  }
`;

const FilesPreviewContainer = styled.div`
  position: absolute;
  bottom: calc(8px + 12px - 4px);
  left: calc(12px + 20px + 2px);
  right: calc(12px + 12px + 182px + 16px);
  height: 48px;
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: auto;
  padding: 4px;

  img {
    height: 100%;
    border-radius: 4px;
  }

  .documentLeftSection {
    display: flex;
    gap: 4px;
    align-items: center;
    height: 100%;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    border: 1px solid ${themes?.default?.gainsboro2};
    position: relative;

    &:hover {
      .removeButton {
        opacity: 1 !important;
      }
    }
  }

  .documentType {
    margin: 0;
    font-size: 0.71rem;
    font-weight: 400;
    color: ${themes?.default?.nobel};
  }
`;

const ImagePreviewWrapper = styled.div<{ selected: boolean; theme?: any }>`
  position: relative;
  height: 100%;
  width: fit-content;

  img {
    box-shadow: ${(props) =>
      props.selected ? `0 0 0 1px ${props.theme.primary}` : ''};
  }

  &:hover {
    .removeButton {
      opacity: 1 !important;
    }
  }
`;
