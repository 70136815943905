import { ICustomHeaderOption } from 'app/components/ManagedGrid/types';
import { useTranslation } from 'react-i18next';

export const CustomHeaderOptions: ICustomHeaderOption = ({
  closeMenu,
  ComponentSuggested,
  sxSuggested,
  customProps,
}) => {
  const { t } = useTranslation();

  return (
    <ComponentSuggested
      sx={sxSuggested}
      disabled={customProps?.disabled}
      onClick={async (_) => {
        customProps?.onClick?.();
        closeMenu();
      }}
    >
      <>{customProps?.title ? t(customProps.title) : ''}</>
    </ComponentSuggested>
  );
};
