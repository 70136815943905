import { Grid, MenuItem } from '@mui/material';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerFormcontext } from '../../../Form/context';
import FormTextField from 'app/components/Form/TextField';
import { FieldComponent } from 'app/components/FieldComponent';
import { CustomerStatus } from 'common/types/Customer';
import { StatusCustomer } from 'app/components/StatusCustomer';

export const OverView = ({ control }) => {
  const { t } = useTranslation();
  const { canEdit } = useContext(CustomerFormcontext);

  const statuses = useMemo(() => {
    return Object.values(CustomerStatus).map((status) => {
      return {
        label: t(`customer.status.${status}`),
        value: status,
      };
    });
  }, [t]);

  return (
    <FormSection>
      <SectionTitle>{t('common.tabs.overview')}</SectionTitle>
      <Grid container spacing="12px">
        <Grid item md={4} sm={4} xs={12}>
          <FormTextField
            name="shortName"
            label="customer.publicName"
            control={control}
            disabled={!canEdit}
            rules={{ required: true }}
            required
          />
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <FormTextField
            name="code"
            label="common.code"
            control={control}
            disabled={!canEdit}
            rules={{
              required: true,
              // validate(value) {
              //   return validate({
              //     code: value?.toString() || '',
              //     id: customer?._id,
              //   });
              // },
            }}
            required
          />
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <FieldComponent
            control={control}
            name="status"
            label={t('client.statusTitle')}
            select
            InputProps={{
              disabled: !canEdit,
              size: 'small',
            }}
          >
            {statuses?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <StatusCustomer status={option.value} />
              </MenuItem>
            ))}
          </FieldComponent>
        </Grid>
      </Grid>
    </FormSection>
  );
};
