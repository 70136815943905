import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useCallback } from 'react';

import {
  SquaresFour,
  Users,
  PresentationChart,
  Note,
  IdentificationBadge,
} from '@phosphor-icons/react';

import { MainNavItem, ScrollableSection, OptionsWrapper } from '../helpers';
import {
  DASHBOARD_ALL,
  DOCUMENTS,
  IAM,
  REPORTS_ALL,
  USERS, VISITORS,
} from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { isActiveRoute } from '../index';
import { IconWrapper } from '../../../../styles/components/muitable/TableViewPopover';
import { MenuTitleWrapper } from '../Components';
import { useSelector } from 'react-redux';
import { selectRoles } from 'common/store/organization/selectors';
import {
  selectCurrentApplication,
  selectNavMenuPreferences,
} from 'common/store/app/selectors';
import { useAbility } from 'hooks/Abilities';
import If from 'app/components/If';
import { Permission } from 'hooks/Abilities/usePermission';
import { useDispatch } from 'react-redux';
import { appActions, defaultMenuNavItems } from 'common/store/app';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MenuNavItem } from '../types';
import { sortNavMenuItems } from 'app/helpers/helpers';
import { selectAuthUser } from 'common/store/auth/selectors';

const dashboardAll = `${IAM}${DASHBOARD_ALL}`;
const reportAll = `${IAM}${REPORTS_ALL}`;

export default function IAMMenu({ theme, mode }) {
  const ability = useAbility();
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = useLocation();
  const isDashboardAll = location.pathname === dashboardAll;
  const isReportAll = location.pathname === reportAll;
  const isMinify = mode === 'minify';
  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>([0]);

  const authUser = useSelector(selectAuthUser);
  const roles = useSelector(selectRoles);
  const currentApplication = useSelector(selectCurrentApplication);
  const navMenuPreferences = useSelector(selectNavMenuPreferences);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkModuleScope = useCallback(
    (application, module, scope) => {
      return authUser?.currentAgent?.privileges?.scopes.includes(
        `${application}.${module}.${scope}`,
      );
    },
    [authUser?.currentAgent?.privileges?.scopes],
  );

  const canViewDocuments = useMemo(() => {
    return checkModuleScope('iam', 'documents', 'canview');
  }, [checkModuleScope]);

  const displayEmployees = useMemo(() => {
    return (
      roles.filter((r) =>
        r.privileges?.roleproperties?.includes(
          `${currentApplication?.id}.showinemployee`,
        ),
      )?.length && ability.can(Permission.VIEW, 'users')
    );
  }, [currentApplication?.id, roles]);

  useEffect(() => {
    if (!navMenuPreferences?.IAM) {
      dispatch(
        appActions.setNavMenuPreferences({
          ...navMenuPreferences,
          IAM: defaultMenuNavItems.IAM,
        }),
      );
    }
  }, [dispatch, navMenuPreferences]);

  const menuMapping = useMemo(() => {
    const IAMPreferences = navMenuPreferences?.IAM || defaultMenuNavItems.IAM;
    const preferences = navMenuPreferences?.IAM
      ? {
          ...navMenuPreferences.IAM,
          menuItems: [...navMenuPreferences.IAM.menuItems],
        }
      : {
          ...defaultMenuNavItems.IAM,
          menuItems: [...defaultMenuNavItems.IAM.menuItems],
        };

    if (!canViewDocuments) {
      preferences.menuItems = preferences.menuItems.filter(
        (item) => item !== 'documents',
      );
    }

    const updateStore = (key, value) => {
      dispatch(
        appActions.setNavMenuPreferences({
          ...navMenuPreferences,
          IAM: {
            ...navMenuPreferences.IAM,
            [key]: value,
          },
        }),
      );
    };

    const sortedMenuItems = canViewDocuments
      ? sortNavMenuItems(
          defaultMenuNavItems.IAM.menuItems,
          IAMPreferences.menuItems || [],
          (sorted) => updateStore('menuItems', sorted),
        )
      : preferences.menuItems;

    const sortedMenuAllItems = sortNavMenuItems(
      defaultMenuNavItems.IAM.menuAllItems,
      IAMPreferences.menuAllItems || [],
      (sorted) => updateStore('menuAllItems', sorted),
    );

    return {
      main:
        isDashboardAll || isReportAll ? sortedMenuAllItems : sortedMenuItems,
    };
  }, [
    dispatch,
    navMenuPreferences,
    isDashboardAll,
    isReportAll,
    canViewDocuments,
  ]);

  const MenuNavItems = useMemo(() => {
    const items: MenuNavItem[] = [
      {
        id: 'IAMMenu1',
        title: t('nav.mainNav.title2'),
        onClick: () => handleRowToggle(0),
        items: menuMapping.main,
      },
    ];

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, menuMapping, isDashboardAll || isReportAll]);

  useEffect(() => {
    if (pathname === IAM) {
      menuMapping.main[0] === 'employees'
        ? navigate(`${IAM}${USERS}`)
        : navigate(`${IAM}/${DOCUMENTS}`);
    }
  }, [pathname, navigate, menuMapping]);

  const getToById = (id: string): string => {
    switch (id) {
      case 'dashboard-all':
        return dashboardAll;

      case 'reports-all':
        return reportAll;

      case 'employee':
        return `${IAM}${USERS}`;

      case 'documents':
        return `${IAM}/${DOCUMENTS}`;

      case 'visitors':
        return `${IAM}/${VISITORS}`;

      default:
        return `${IAM}${USERS}`;
    }
  };

  const getTitleById = (id: string): string => {
    switch (id) {
      case 'dashboardAll':
        return t('nav.mainNav.dashboard');

      case 'reportAll':
        return t('nav.mainNav.reports');

      case 'employees':
        return t('nav.mainNav.employees');

      case 'documents':
        return t('nav.mainNav.documents');

      case 'visitors':
        return t('nav.mainNav.visitors');

      default:
        return t('nav.mainNav.dashboard');
    }
  };

  const getIconById = (id: string): JSX.Element => {
    switch (id) {
      case 'dashboardAll':
        return (
          <Icon
            icon={<SquaresFour />}
            color={
              location.pathname === dashboardAll
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'reportAll':
        return (
          <IconWrapper isActive={isActiveRoute(location, reportAll)}>
            <PresentationChart />
          </IconWrapper>
        );

      case 'employees':
        return (
          <Icon
            icon={<Users />}
            color={
              location?.pathname?.includes(USERS)
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'documents':
        return (
          <Icon
            icon={<Note />}
            color={
              location?.pathname?.includes(DOCUMENTS)
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'visitors':
        return (
          <Icon
            icon={<IdentificationBadge />}
            color={
              location?.pathname?.includes(VISITORS)
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      default:
        return (
          <Icon
            icon={<SquaresFour />}
            color={
              location.pathname === dashboardAll
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );
    }
  };

  const handleDragEnd = (result) => {
    if (
      !result?.destination ||
      result.source.index === result.destination.index ||
      result.source.droppableId !== result.destination.droppableId
    )
      return;

    const menuMappingById = {
      'droppable-nav-menu-0':
        isDashboardAll || isReportAll ? 'menuAllItems' : 'menuItems',
    };

    const menuType = menuMappingById[result.destination.droppableId];

    const currentArrangement = [...navMenuPreferences.IAM[menuType]];
    const [movedItem] = currentArrangement.splice(result.source.index, 1);
    currentArrangement.splice(result.destination.index, 0, movedItem);

    dispatch(
      appActions.setNavMenuPreferences({
        ...navMenuPreferences,
        IAM: {
          ...navMenuPreferences.IAM,
          [menuType]: currentArrangement,
        },
      }),
    );
  };

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [index];
      }
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <ScrollableSection>
        {MenuNavItems.map((menu, menuIndex) => (
          <Droppable
            droppableId={`droppable-nav-menu-${menuIndex}`}
            key={`menu-${menuIndex}`}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <OptionsWrapper
                  withoutBorder={menuIndex >= MenuNavItems.length - 1}
                >
                  <MenuTitleWrapper
                    isMinify={isMinify}
                    onClick={menu.onClick}
                    openSectionIndex={openSectionIndex}
                    theme={theme}
                    title={menu.title}
                    index={menuIndex}
                  >
                    {menu?.items?.map((item: string, itemIndex) => (
                      <If
                        condition={item !== 'employee'}
                        otherwise={
                          <If condition={displayEmployees}>
                            <Draggable
                              draggableId={`${item}-${menuIndex}-${itemIndex}`}
                              index={itemIndex}
                              key={`${item}-${menuIndex}-${itemIndex}`}
                            >
                              {(draggableProvided) => (
                                <div
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  style={{
                                    ...draggableProvided.draggableProps.style,
                                    top: 'auto !important',
                                    left: 'auto !important',
                                  }}
                                >
                                  <MainNavItem
                                    to={getToById(item)}
                                    text={getTitleById(item)}
                                    mode={mode}
                                    icon={getIconById(item)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          </If>
                        }
                      >
                        <Draggable
                          draggableId={`${item}-${menuIndex}-${itemIndex}`}
                          index={itemIndex}
                          key={`${item}-${menuIndex}-${itemIndex}`}
                        >
                          {(draggableProvided) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              style={{
                                ...draggableProvided.draggableProps.style,
                                top: 'auto !important',
                                left: 'auto !important',
                              }}
                            >
                              <MainNavItem
                                to={getToById(item)}
                                text={getTitleById(item)}
                                mode={mode}
                                icon={getIconById(item)}
                              />
                            </div>
                          )}
                        </Draggable>
                      </If>
                    ))}
                    {provided.placeholder}
                  </MenuTitleWrapper>
                </OptionsWrapper>
              </div>
            )}
          </Droppable>
        ))}
      </ScrollableSection>
    </DragDropContext>
  );
}
