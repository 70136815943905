import { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  ShoppingCartSimple,
  Package,
  PushPinSimple,
  CurrencyCircleDollar,
  Heart,
  FilePdf,
  // Printer,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { ToolbarButton } from './components/ToolbarButton';
import { CustomerOrdersPopover } from './components/CustomerOrdersPopover';
import { useBulkAddNewProductsToCartMutation } from 'common/services/cartApi';
import { CatalogContext } from '../..';
import { GRID } from 'app/components/TableComponent/constants';
import { useToaster } from 'hooks/useToaster';
import { changeCartVisibilityState } from 'common/store/cart';
import { useDispatch } from 'react-redux';
import { useToggleFavoriteItemsMutation } from 'common/services/customerApi';
import If from 'app/components/If';
import { OrderProduct } from 'types';
import { useHelpers } from '../../userHelpers';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';

export const BulkActionsToolbar = () => {
  const {
    viewMode,
    selectedProducts,
    setSelectedProducts,
    setFavoriteItems,
    isCustomer,
    setOpenCreateOrderDrawer,
  } = useContext(CatalogContext);
  const gridContext = useManagedContext<IGridContext>('grid');

  const { t } = useTranslation();
  const { handleAddProductToOrder, getFormattedSelectedProducts } =
    useHelpers();

  const [bulkAddNewProductsToCart] = useBulkAddNewProductsToCartMutation();
  const [toggleFavoriteItems] = useToggleFavoriteItemsMutation();

  const toast = useToaster();
  const dispatch = useDispatch();

  const handleOpenCartDrawer = () => {
    dispatch(changeCartVisibilityState(true));
  };

  const handleAddToCart = async () => {
    if (viewMode === GRID) {
      const payload = selectedProducts.grid.map((prod) => ({
        productId: prod,
        quantity: 1,
      }));
      await bulkAddNewProductsToCart({ products: payload });
      toast(5000, 'success', t('cart.products_added'));
      setSelectedProducts((prev) => ({ ...prev, grid: [] }));
      handleOpenCartDrawer();
    } else if (selectedProducts?.categories) {
      const allCategoryIds: any[] = Object.values(
        selectedProducts.categories,
      ).flat();
      const payload = allCategoryIds.map((prod) => ({
        productId: prod,
        quantity: 1,
      }));
      await bulkAddNewProductsToCart({ products: payload });
      toast(5000, 'success', t('cart.products_added'));
      setSelectedProducts((prev) => ({ ...prev, categories: {} }));
      handleOpenCartDrawer();
    }
  };

  const handleToggleFavorite = async () => {
    try {
      if (viewMode === GRID) {
        const response = await toggleFavoriteItems({
          products: selectedProducts.grid,
        }).unwrap();
        setFavoriteItems(response.result);
        setSelectedProducts((prev) => ({ ...prev, grid: [] }));
        toast(3000, 'success', t('toggle_fav.success'));
      } else if (selectedProducts?.categories) {
        const allCategoryIds: string[] = Object.values(
          selectedProducts.categories as Record<string, string[]>,
        ).flat();
        const response = await toggleFavoriteItems({
          products: allCategoryIds,
        }).unwrap();
        setFavoriteItems(response.result);
        setSelectedProducts((prev) => ({ ...prev, categories: {} }));
        toast(3000, 'success', t('toggle_fav.success'));
      }
    } catch (error: any) {
      toast(3000, 'error', error?.data?.message);
    }
  };

  const [customerOrdersPopoverAnchorEl, setCustomerOrdersPopoverAnchorEl] =
    useState<React.ReactHTMLElement<any> | null>(null);

  const handleCloseCustomerOrderPopover = () => {
    setCustomerOrdersPopoverAnchorEl(null);
  };

  const handleAddToOrderClick = useCallback(
    (orderId: string, orderCode: string) => {
      handleCloseCustomerOrderPopover();

      let selected: string[] = [];

      if (viewMode === GRID) {
        selected = selectedProducts.grid;
      } else if (selectedProducts?.categories) {
        selected = Object.values(
          selectedProducts.categories as Record<string, string[]>,
        ).flat();
      }

      const products: OrderProduct[] = getFormattedSelectedProducts(
        selected,
        gridContext.responseData,
      );

      handleAddProductToOrder(orderId, orderCode, products);
      setSelectedProducts({ grid: [] });
    },
    [
      getFormattedSelectedProducts,
      gridContext.responseData,
      handleAddProductToOrder,
      selectedProducts?.categories,
      selectedProducts?.grid,
      viewMode,
      setSelectedProducts,
    ],
  );

  return (
    <Wrapper>
      <ToolbarButton
        icon={<ShoppingCartSimple />}
        tooltip={t('cart.action.addToCart')}
        onClick={handleAddToCart}
      />
      <ToolbarButton
        icon={<Package />}
        isDropDown={true}
        tooltip={t('orders.title.addToOrders')}
        active={!!customerOrdersPopoverAnchorEl}
        onClick={(e) => setCustomerOrdersPopoverAnchorEl(e.currentTarget)}
      />
      <ToolbarButton
        icon={<PushPinSimple />}
        tooltip={t('common.pin')}
        onClick={() => {}}
      />
      <ToolbarButton
        icon={<CurrencyCircleDollar />}
        tooltip={t('request_quotation')}
        onClick={() => {}}
      />
      <If condition={isCustomer}>
        <ToolbarButton
          icon={<Heart />}
          tooltip={t('add_to_favorites')}
          onClick={() => handleToggleFavorite()}
        />
      </If>
      <ToolbarButton
        icon={<FilePdf />}
        tooltip={t('download_as_PDF')}
        onClick={() => {}}
      />
      {/* <ToolbarButton
        icon={<Printer />}
        tooltip={t('common.buttons.print')}
        onClick={() => {}}
      /> */}
      <CustomerOrdersPopover
        anchorEl={customerOrdersPopoverAnchorEl}
        handleClose={handleCloseCustomerOrderPopover}
        onCreateClick={() => {
          handleCloseCustomerOrderPopover();
          setOpenCreateOrderDrawer(true);
        }}
        onOrderClick={handleAddToOrderClick}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
