import { useRestrictionsSingle } from '../../hooks/useRestrictionsSingle';
import { useRestrictionsHelpers } from '../../hooks/useRestrictionsHelpers';
import { useTranslation } from 'react-i18next';
import { useRestrictMaxModal } from '../../hooks/useRestrictMaxModal';
import { useMemo } from 'react';
import { ICustomHeaderOption } from 'app/components/ManagedGrid/types';
import { isNumber } from 'lodash';
import { DiscountIndex } from '../../types/discounts.types';

export const MaxHeaderOption: ICustomHeaderOption = ({
  field,
  closeMenu,
  ComponentSuggested,
  sxSuggested,
}) => {
  const { t } = useTranslation();

  const i = useMemo(
    () => Number(field.match(/^r(\d+)$/i)!.pop()) as DiscountIndex,
    [field],
  );

  // null = no permission
  const discountHeaderTools = useRestrictionsHelpers(i);
  const restrictions = useRestrictionsSingle(i);
  const { show: showMaxModal } = useRestrictMaxModal(i);

  const optionTitle = useMemo<string>(() => {
    if (isNumber(restrictions?.limit)) {
      return t('discount.limit_max_title', {
        action: t('common.edit'),
      });
    }
    return t('DiscountGroups.max_discount_title', {
      action: restrictions.lock ? t('common.add') : t('common.edit'),
    }).replace(/(max)\w+/i, '$1.');
  }, [restrictions?.limit, restrictions.lock, t]);

  return (
    <ComponentSuggested //
      sx={sxSuggested}
      disabled={!discountHeaderTools || restrictions.lock}
      onClick={async (_) => {
        if (restrictions.lock) return; // failsafe
        showMaxModal();
        closeMenu();
      }}
    >
      {optionTitle}
    </ComponentSuggested>
  );
};
