import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

export const StatusCell = (params: CustomCellRendererProps) => {
  const rawStatus = params?.value?.cellData;

  const { t } = useTranslation();

  return rawStatus ? (
    <Chip
      label={t(rawStatus)}
      size="small"
      sx={{
        backgroundColor:
          rawStatus === 'pending_approval'
            ? themes.default.bgPending
            : themes?.default?.success,
        color:
          rawStatus === 'pending_approval'
            ? themes?.default?.textPending
            : themes.default.greenMain,
      }}
    />
  ) : (
    '__'
  );
};
