import {
  Autocomplete,
  Chip,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import {
  CustomTextField,
  RenderNote,
} from 'app/pages/Ordoria/Orders/components/OrderDetailsDrawer/OrderNotesTab';
import {
  useAddNoteMutation,
  useLazyGetNotesQuery,
} from 'common/services/notes';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import { selectRoles } from 'common/store/organization/selectors';
import Each from 'common/UtilityComponents/Each';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { useQueryParams } from 'hooks/useQueryParams';
import { useToaster } from 'hooks/useToaster';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export function NotesTab({ supplierId }) {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  const toast = useToaster();

  const [note, setNote] = useState<string>('');
  const [validationError, setValidationError] = useState<string | null>(null);
  const [restricted, setRestricted] = useState<boolean>(false);
  const limit: number = 20;
  const [search, setSearch] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<any[]>([]);

  const [notes, setNotes] = useState<any[]>([]);
  const page = useRef(1);

  const [postNote] = useAddNoteMutation();
  const [trigger, { data: notesData }] = useLazyGetNotesQuery();

  useInfiniteScroll({
    responseData: notesData?.docs,
    data: notes,
    setData: setNotes,
    page: page.current,
  });

  const fetchData = useCallback(
    (page: number) => {
      if (!page) {
        return;
      }
      trigger({ objectType: 'supplier', supplierId, page });
    },
    [trigger, supplierId],
  );

  const fetchQueryParams = useQueryParams({
    limit: limit,
    text: search,
  });

  const roles = useSelector(selectRoles);
  const { data: users, isLoading } = useGetUsersBasicQuery(fetchQueryParams);

  function handleSaveNote() {
    if (!validateNote()) {
      return;
    }

    if (note !== '') {
      postNote({
        objectType: 'supplier',
        objectId: supplierId,
        content: note,
        restricted: restricted,
        _sharedWithRoles: selectedRoles?.map((role) => role?._id),
        _sharedWithAgents: selectedAgents?.map(
          (agent) => agent?.currentAgent?._id,
        ),
      }).then(() => {
        setNote('');
        setSelectedRoles([]);
        setSelectedAgents([]);
        fetchData(page.current);
        toast(5000, 'success', 'alerts.notes.add_success');
      });
    }
  }

  function handleNoteChange(note: string) {
    setNote(note);
  }

  function handleOpenTextArea() {
    console.log('NOT IMPLEMENTED: handleOpenTextArea');
  }

  const validateNote = () => {
    if (!note.trim()) {
      setValidationError(t('order_details.order_notes.note_cannot_be_empty'));
      return false;
    }
    setValidationError(null);
    return true;
  };

  function formatDate(dateInput: Date): string {
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    } as Intl.DateTimeFormatOptions;
    const date = new Date(dateInput);
    const locale = i18n.language;
    return date.toLocaleDateString(locale, options);
  }

  const groupedNotes = useMemo(() => {
    if (notes && Array.isArray(notes)) {
      return notes.reduce((acc, note) => {
        const key = formatDate(note.createdAt);

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(note);
        return acc;
      }, {});
    }
  }, [notes]);

  const renderTags = (value: any) => {
    const numTags = value.length;

    return value
      ?.slice(0, 1)
      .map((option, index) => (
        <Chip
          key={index}
          label={option?.localeName?.[locale] || option?.fullName}
          size="small"
        />
      ))
      .concat(
        numTags > 1 ? (
          <Typography marginLeft="10px" color={themes?.default?.primary}>
            +{numTags - 1}
          </Typography>
        ) : (
          []
        ),
      );
  };

  useEffect(() => {
    if (restricted) {
      setSelectedRoles([]);
      setSelectedAgents([]);
    }
  }, [restricted]);

  const loadMore = () => {
    page.current += 1;
    fetchData(page.current);
  };

  // useEffect(() => {
  //   if (page.current !== 1) {
  //     return;
  //   }
  //   fetchData(1);
  // }, [supplierId, fetchData]);

  return (
    <Wrapper>
      <CustomTextField
        handleSave={handleSaveNote}
        handleNoteChange={handleNoteChange}
        handleOpenTextArea={handleOpenTextArea}
        validationError={validationError}
        value={note}
        optionalFields={
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            gap="10px"
          >
            <FormControlLabel
              control={
                <Switch
                  value={restricted}
                  onChange={() => setRestricted(!restricted)}
                />
              }
              label={t('notes.restricted')}
            />
            <Autocomplete
              options={roles}
              value={selectedRoles}
              multiple
              getOptionLabel={(option: any) => option?.localeName?.[locale]}
              renderTags={renderTags}
              renderInput={(params) => <TextField {...params} label="Roles" />}
              renderOption={(props, option: any) => (
                <li {...props}>
                  <Typography>{option?.localeName?.[locale]}</Typography>
                </li>
              )}
              onChange={(event, values: any) => {
                setSelectedRoles(values?.map((value) => value));
              }}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              sx={{
                '& .MuiAutocomplete-inputRoot': {
                  height: '38px',
                },
                width: '300px',
                maxHeight: '38px',
              }}
              disabled={!restricted}
            />
            <Autocomplete
              options={users?.docs || []}
              multiple
              value={selectedAgents}
              getOptionLabel={(option: any) => option?.fullName || ''}
              loading={isLoading}
              renderTags={renderTags}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('modules.users')}
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
              renderOption={(props, option: any) => (
                <li {...props}>
                  <Typography>{option?.fullName}</Typography>
                </li>
              )}
              onChange={(event, values: any) => {
                setSelectedAgents(values);
              }}
              isOptionEqualToValue={(option, value) =>
                option?._id === value?._id
              }
              sx={{
                '& .MuiAutocomplete-inputRoot': {
                  height: '38px',
                },
                width: '300px',
                maxHeight: '38px',
              }}
              disabled={!restricted}
            />
          </Grid>
        }
      />
      <ScrollableListContainer id="scrollable-body">
        <InfiniteScroll
          dataLength={notes?.length || 0}
          next={loadMore}
          hasMore={notesData?.hasNextPage}
          loader={null}
          scrollableTarget="scrollable-body"
        >
          {groupedNotes &&
            Object.keys(groupedNotes).map((date, index) => (
              <Content>
                <Typography
                  fontWeight={500}
                  fontSize="1.25rem"
                  key={index}
                  marginBottom="10px"
                >
                  {date}
                </Typography>
                <Each
                  key={index}
                  of={groupedNotes[date]}
                  render={(note: any, i: number) => (
                    <RenderNote
                      key={i}
                      note={note}
                      index={index}
                      handleDeleteConfirmNote={() => {}}
                      handleUpdateNote={() => {}}
                    />
                  )}
                />
              </Content>
            ))}
        </InfiniteScroll>
      </ScrollableListContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0px 20px 0px 0px;
  height: 100%;
  overflow-y: hidden;
`;

const Content = styled.div`
  background-color: ${themes?.default?.accordionWhiteBg};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
`;

const ScrollableListContainer = styled.div`
  margin-top: 20px;
  height: calc(100% - 200px);
  overflow-y: auto;
`;
