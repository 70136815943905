import api from './api';
import {
  IProduct,
  IPictures,
  IProductForm,
  IProductByTopCategoryRes,
} from '../types/Product';

export const productApi = api.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<any, string>({
      query: urlParams => ({
        url: `/products${urlParams}`,
      }),
      providesTags: ['Products'],
    }),
    getProductsCategories: builder.query<any, { urlQuery: string }>({
      query: ({ urlQuery }) => ({
        url: `/products/categories${urlQuery}`,
      }),
    }),
    getProductsByCategory: builder.query<
      any,
      { categoryId: string; urlQuery: string }
    >({
      query: ({ categoryId, urlQuery }) => ({
        url: `/products/categories/${categoryId}${urlQuery}`,
      }),
    }),
    updateShowToCatalog: builder.mutation<void, { id: string; show: boolean }>({
      query: ({ id, show }) => ({
        url: `/products/${id}/show-in-catalog`,
        method: 'PATCH',
        body: { showInCatalog: show },
      }),
      invalidatesTags: ['Products'],
    }),
    updateShowToGallery: builder.mutation<void, { id: string; show: boolean }>({
      query: ({ id, show }) => ({
        url: `/products/${id}/show-in-gallery`,
        method: 'PATCH',
        body: { showInGallery: show },
      }),
      invalidatesTags: ['Products'],
    }),
    updateStatus: builder.mutation<
      void,
      { productIds: string[]; status: string }
    >({
      query: ({ productIds, status }) => ({
        url: `/products/status`,
        method: 'PATCH',
        body: { productIds: productIds, status: status },
      }),
      invalidatesTags: ['Products'],
    }),
    getProductsStats: builder.query<any, string>({
      query: urlParams => ({
        url: `/products/stats${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Products'],
    }),
    getProductsSubCategories: builder.query<any, string>({
      query: urlQuery => ({
        url: `/products/sub-categories${urlQuery}`,
      }),
    }),
    updateProductDiscount: builder.mutation<
      void,
      { productIds: string[]; discount: number }
    >({
      query: ({ productIds, discount }) => ({
        url: `/products/discount`,
        method: 'PATCH',
        body: { productIds, discount },
      }),
      invalidatesTags: ['Products'],
    }),
    updateProductBoxBuyingPrice: builder.mutation<
      void,
      { productIds: string[]; boxBuyingPrice: number }
    >({
      query: ({ productIds, boxBuyingPrice }) => ({
        url: `/products/box-buying-price`,
        method: 'PATCH',
        body: { productIds, boxBuyingPrice },
      }),
      invalidatesTags: ['Products'],
    }),
    checkProductBarCodeValidity: builder.query<any, string>({
      query: urlQuery => ({
        url: `/products/check-barcode${urlQuery}`,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    checkProductCodeValidity: builder.query<any, string>({
      query: urlQuery => ({
        url: `/products/check-code${urlQuery}`,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    addProduct: builder.mutation<unknown, any>({
      query: body => ({
        url: `/products`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Products'],
    }),
    getProductsCategoriesOptions: builder.query<
      IProduct[],
      { parentCategory: string | null }
    >({
      query: ({ parentCategory }) => ({
        url: `/product-categories/options?_parentCategory=${parentCategory}`,
      }),
    }),
    getProductsDetails: builder.query<IProduct, { id: string }>({
      query: ({ id }) => ({
        url: `/products/${id}`,
      }),
      providesTags: ['ProductDetails'],
    }),
    reassignProduct: builder.mutation<void, { body }>({
      query: ({ body }) => ({
        url: `/products/category`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Products', 'ProductDetails'],
    }),
    archiveProduct: builder.mutation<void, { ids: string[] }>({
      query: ({ ids }) => ({
        url: `/products/archive`,
        method: 'PATCH',
        body: { productIds: ids },
      }),
      invalidatesTags: ['Products'],
    }),
    unArchiveProduct: builder.mutation<void, { ids: string[] }>({
      query: ({ ids }) => ({
        url: `/products/unarchive`,
        method: 'PATCH',
        body: { productIds: ids },
      }),
      invalidatesTags: ['Products'],
    }),
    updateProductImage: builder.mutation<
      void,
      { id: string; dataType: string; pictures: IPictures[] }
    >({
      query: ({ id, dataType, pictures }) => ({
        url: `products/${id}/pictures`,
        method: 'PATCH',
        body: { data: dataType, pictures: pictures },
      }),
      invalidatesTags: ['ProductDetails'],
    }),
    updateProduct: builder.mutation<void, { id: string; body: IProductForm }>({
      query: ({ id, body }) => ({
        url: `products/${id}/info`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['ProductDetails'],
    }),
    updateProductTags: builder.mutation<
      void,
      { id: string; dataType: string; tags: string[] }
    >({
      query: ({ id, dataType, tags }) => ({
        url: `products/${id}/tags`,
        method: 'PATCH',
        body: { data: dataType, tags },
      }),
      invalidatesTags: ['ProductDetails'],
    }),
    updateProductCode: builder.mutation<void, { id: string; code: string }>({
      query: ({ id, code }) => ({
        url: `products/${id}/code`,
        method: 'PATCH',
        body: { code: code },
      }),
    }),
    updateProductBrochure: builder.mutation<
      void,
      { id: string; brochure: string }
    >({
      query: ({ id, brochure }) => ({
        url: `products/${id}/brochure`,
        method: 'PATCH',
        body: { brochure: brochure },
      }),
      invalidatesTags: ['ProductDetails'],
    }),
    updateProductsTags: builder.mutation<
      void,
      { productIds: string[]; dataType: string; tags: string[] }
    >({
      query: ({ productIds, dataType, tags }) => ({
        url: `products/tags`,
        method: 'PATCH',
        body: { data: dataType, tags, productIds },
      }),
      invalidatesTags: ['Products'],
    }),
    getProductsOptions: builder.query<any, { urlQuery: string }>({
      query: ({ urlQuery }) => ({
        url: `/products/options${urlQuery}`,
      }),
    }),
    updateStorageLocation: builder.mutation<
      void,
      { locationsIds: string[]; id: string }
    >({
      query: ({ locationsIds, id }) => ({
        url: `/products/${id}/storage-locations`,
        method: 'PATCH',
        body: { _storageLocations: locationsIds },
      }),
      invalidatesTags: ['ProductDetails'],
    }),
    updateProductName: builder.mutation<void, { id: string; name: string }>({
      query: ({ id, name }) => ({
        url: `/products/${id}/name`,
        method: 'PATCH',
        body: { name: name },
      }),
      invalidatesTags: ['Products', 'ProductDetails'],
    }),
    getProductsByTopCategory: builder.query<
      IProductByTopCategoryRes[],
      { urlQuery: string }
    >({
      query: ({ urlQuery }) => ({
        url: `/products/list-by-top-category${urlQuery}`,
      }),
      providesTags: ['ProductsByTopCategories'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductsCategoriesQuery,
  useGetProductsByCategoryQuery,
  useLazyGetProductsByCategoryQuery,
  useUpdateShowToCatalogMutation,
  useUpdateShowToGalleryMutation,
  useUpdateStatusMutation,
  useGetProductsStatsQuery,
  useGetProductsSubCategoriesQuery,
  useUpdateProductDiscountMutation,
  useUpdateProductBoxBuyingPriceMutation,
  useLazyCheckProductBarCodeValidityQuery,
  useLazyCheckProductCodeValidityQuery,
  useAddProductMutation,
  useGetProductsCategoriesOptionsQuery,
  useLazyGetProductsDetailsQuery,
  useReassignProductMutation,
  useArchiveProductMutation,
  useUnArchiveProductMutation,
  useUpdateProductImageMutation,
  useUpdateProductMutation,
  useUpdateProductTagsMutation,
  useUpdateProductCodeMutation,
  useUpdateProductBrochureMutation,
  useUpdateProductsTagsMutation,
  useLazyGetProductsOptionsQuery,
  useGetProductsOptionsQuery,
  useUpdateStorageLocationMutation,
  useUpdateProductNameMutation,
  useGetProductsByTopCategoryQuery,
} = productApi;

export default productApi;
