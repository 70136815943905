import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { themes } from 'styles/theme/themes';

const DetailRow = React.memo(
  ({
    label,
    value,
    icon,
    statusColor,
    padding = '1px 10px 1px 0px',
  }: {
    label: string;
    value: string;
    icon?: React.ReactNode;
    statusColor?: { background: string; color: string };
    padding?: string;
  }) => (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        backgroundColor: themes.default.lightGrey,
        borderRadius: 4,
        alignItems: 'center',
        height: 40,
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      <Typography
        fontSize={12}
        fontWeight={400}
        color={themes.default.documentDrawerText}
      >
        {label}
      </Typography>
      <Box
        sx={{
          padding: padding,
          backgroundColor: statusColor
            ? statusColor.background
            : themes.default.whisper,
          color: statusColor
            ? statusColor.color
            : themes.default.documentDrawerText,
          alignItems: 'center',
          display: 'flex',
          borderRadius: 100,
          textTransform: 'capitalize',
        }}
      >
        {icon}
        <Typography fontSize={12} fontWeight={400}>
          {value}
        </Typography>
      </Box>
    </Stack>
  ),
);

export default DetailRow;
