import { ListCollapsableCardOffer } from '../components/ListCollapsableCardOffer';
import Each from 'common/UtilityComponents/Each';
import { useLazyGetOffersQuery } from 'common/services/customerOffersApi';
import { useEffect, useState, useMemo, useRef, useContext } from 'react';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { IToolbarConfig } from 'app/components/ManagedGrid/types';
import { createPortal } from 'react-dom';
import { ManagedGridActions } from 'app/components/ManagedGrid/components/ManagedGridActions';
import { useQueryParams } from 'hooks/useQueryParams';
import { useParams } from 'react-router-dom';
import { AddOffer } from '../../../Components/AddOffer';
import { useHelpers } from '../../../useHelpers';
import { Offer } from 'common/types/Customer';
import { IOffersList } from '../../../types';
import ManagedGrid from 'app/components/ManagedGrid';
import { INoDataConfig } from 'app/components/ManagedGrid/types';
import NoItemsImage from 'assets/img/customers/Contracts/NoItemsImage.svg';
import { Contract } from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { OfferContext } from '../../OffersList';

export interface Category {
  name: string;
  _id: string;
  products: Offer[];
  key: string;
}

export const OffersListGrid = ({ selectedTab }: { selectedTab: Contract }) => {
  const { setSelectedOffer, openOfferModal, setOpenOfferModal } =
    useContext(OfferContext);

  const gridActionsRef = useRef<HTMLElement | null>(null);
  const [offers, setOffers] = useState<IOffersList[] | []>([]);
  const [names, setNames] = useState<Category[]>([]);
  const { id: customerId } = useParams();
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const gridContext = useManagedContext<IGridContext>('grid');
  const {
    queryParams: { search, order, orderBy },
  } = gridContext;

  const can = usePermission('customers');
  const sort = orderBy ? `${order}${orderBy}` : 'cache._product.name';

  const [triggerGetProducts, { data: products }] = useLazyGetOffersQuery();

  const { getOffersCategories } = useHelpers();

  const fetchQueryParams = useQueryParams({
    customer: customerId,
    sort: sort,
    text: encodeURIComponent(search) || '',
  });

  useEffect(() => {
    triggerGetProducts({ urlParams: fetchQueryParams });
  }, [search, sort]);
  useEffect(() => {
    if (!products) return;
    const offerslist: IOffersList[] | [] = [];
    const namesList: Category[] = [];
    if (!!products?.length) {
      products.forEach((offer) => {
        const name = offer?._id?.mechanism + '_' + offer?._id?.type;
        offerslist[name] = {
          _id: '',
          name: offer?._id?.mechanism + '_' + offer?._id?.type,
          products: offer.offers,
        };
        namesList.push({
          _id: '',
          products: [],
          name: t(name),
          key: name,
        });
      });

      setNames(namesList);
      setOffers(offerslist);
    } else {
      if (!search) {
        gridContext.updateDataWithFunction((prev) => {
          prev.responseData = [];
          prev.loading = false;
          prev.rowData = [];
          prev.totalRows = 0;
        });
      }

      setOffers([]);
    }
  }, [products, search]);

  useEffect(() => {
    gridActionsRef.current = document.getElementById('contracts-grid-actions');
  }, []);

  const noData = useMemo(() => {
    return products?.length === 0 && search === '';
  }, [search, products]);

  const toolbarConfig = useMemo<IToolbarConfig>(() => {
    return {
      disableToolBar: noData,
      disabled: !can(Permission.EDIT),
      onAddClick: () => {
        setSelectedOffer(null);
        setOpenOfferModal(true);
      },
    };
  }, [noData]);

  const noDataConfig = useMemo<INoDataConfig>(() => {
    return {
      icon: NoItemsImage,
      onClick: () => {
        setSelectedOffer(null);
        setOpenOfferModal(true);
      },
      text: t('contracts.no_data.title', {
        type: t(`contracts.${selectedTab.key}`),
      }),
      textStyle: {
        fontSize: '1rem',
        fontWeight: '500',
        lineHeight: '26px',
        letterSpacing: '0.15px',
        textAlign: 'center',
        color: theme.black2,
        margin: '0px',
        padding: '0px',
      },
      description: t('offers.empty_text', {
        type: t(`contracts.${selectedTab.key}`).toLowerCase(),
      }),
      textButton: t('add_offer'),
      buttonStyle: { width: 'fit-content' },
      containerStyle: { marginTop: '-16px' },
      emptyCenterStyle: { top: '50%' },
    };
  }, [selectedTab]);

  return (
    <>
      {!products || (products?.length === 0 && search === '') ? (
        <ManagedGrid
          toolbarConfig={toolbarConfig}
          noDataConfig={noDataConfig}
        />
      ) : (
        <Each
          of={search !== '' ? names : getOffersCategories()}
          render={(category, index: number) => {
            return (
              <ListCollapsableCardOffer
                categoryName={category.name}
                products={offers?.[category.key]?.products || []}
                index={index}
                categoryKey={category.key}
                totalData={
                  search === '' ? (products ? products?.length : 0) : 1
                }
              />
            );
          }}
        />
      )}

      {gridActionsRef?.current &&
        createPortal(
          <ManagedGridActions {...(toolbarConfig || {})} />,
          gridActionsRef.current,
        )}
      {openOfferModal && (
        <AddOffer
          open={true}
          onClose={() => {
            setOpenOfferModal(false);
            setSelectedOffer(null);
          }}
          customerId={customerId}
        />
      )}
    </>
  );
};
