import { RefundsData } from './data';
import { IRefundsData } from './types';

export function getProcessedRefunds(
  refundsData: RefundsData[],
  isUserCustomer: boolean = false,
): IRefundsData[] {
  return refundsData.map((refundObj, index) => {
    const refund = {
      id: refundObj?.fileId || index,
      __archived: {
        archivedData: refundObj?.isArchived || false,
      },
      type: {
        typeData: refundObj?.type || '',
      },
      name: {
        nameData: refundObj?.name || '',
        initialUrlData: refundObj?.url || '',
      },
      client: {
        clientIdData: refundObj?.customer?.id || '',
        clientCodeData: refundObj?.customer?.code || '',
        clientNameData: refundObj?.customer?.publicName || '',
      },
      branch: {
        branchData: refundObj?.branchLocation?.address || '',
        branchCodeData: refundObj?.branchLocation?.code || '',
      },
      date: {
        dateData: refundObj?.date || '',
      },
      amount: {
        amountData: refundObj?.amount || 0,
      },
      status: {
        statusData: refundObj?.status || '',
      },
      __note: {
        noteData: refundObj?.notes || '',
      },
      __actionMenu: {
        actionMenuData: refundObj || {},
      },
    } as IRefundsData;

    if (isUserCustomer) {
      delete (refund as any).client;
      delete (refund as any).__note;
    }

    return refund;
  });
}
