import { selectCurrentApplication } from 'common/store/app/selectors';
import { Application } from 'common/store/app/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useCurrentApp = () => {
  const currentApplication: Application = useSelector(selectCurrentApplication);

  const appUrl = useMemo(() => {
    return currentApplication?.url === '/' ? '' : currentApplication?.url;
  }, [currentApplication?.url]);

  const appName = useMemo(() => {
    return currentApplication?.displayName;
  }, [currentApplication?.displayName]);

  return { appUrl, appName };
};
