import React, { useState } from 'react';
import SearchField from 'app/components/Search/SearchField';
import { themes } from 'styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { Stack } from '@mui/material';
export const AiaSearchField = (props) => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <SearchField
      {...props}
      value={searchValue}
      setValue={setSearchValue}
      textFieldProps={{
        sx: {
          width: '23rem',
          margin: '0 0.125rem 0 0.625rem',
          fontSize: '0.75rem',
          fontWeight: 400,
          gap: '0.25rem',
          borderRadius: '0.15rem',
          background: themes?.default?.grey,
          position: 'relative',

          '& .MuiInputBase-root': {
            height: '2.25rem',
            '&:before, &:after, &:hover:not(.Mui-disabled):before': {
              border: 'none !important',
            },
            '&.Mui-focused': {
              outline: '1px solid black !important',
              borderRadius: '0.15rem',
            },
            '& input': {
              textAlign: 'start',
              padding: 0,
              '&::placeholder': {
                textAlign: 'start',
              },
            },
          },
        },
        variant: 'standard',
        InputProps: {
          disableUnderline: true,
          startAdornment: (
            <Stack sx={{ padding: '0.5rem' }} direction="row" spacing={1}>
              <Icon icon={<MagnifyingGlass />} />
            </Stack>
          ),
          sx: {
            '& input::placeholder': {
              color: 'inherit',
              opacity: 0.7,
            },
          },
        },
      }}
    />
  );
};

export default AiaSearchField;
