import { Grid, Box, Typography, Chip } from '@mui/material';
import { At, PhoneCall, Globe } from 'phosphor-react';
import { themes } from 'styles/theme/themes';

export const VisitorsCancelVisitInfo = ({ organization, isCanceled }) => {
  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={8}
      lg={8}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'center', sm: 'flex-start', md: 'flex-start' },
        textAlign: { xs: 'center', sm: 'left', md: 'left' },
        marginBottom: { xs: '24px', sm: '0', md: '0' },
      }}
    >
      <Box
        component="img"
        alt="Fedipat"
        src={organization?.pictureUrl}
        sx={{
          width: 107,
          height: 62,
          objectFit: 'contain',
          marginBottom: '32px',
        }}
      />
      <Typography
        fontWeight={700}
        sx={{
          width: '100%',
          marginBottom: '24px',
          fontSize: { xs: '1.75rem', sm: '2rem', md: '2.625rem' },
          lineHeight: { xs: '32px', sm: '40px', md: '50.4px' },
        }}
      >
        {isCanceled
          ? 'Vous avez annulé votre rendez-vous chez '
          : 'Vous êtes sur le point d’annuler votre rendez-vous chez '}
        <span style={{ color: themes?.ordoria?.primary }}>
          {organization?.name}
        </span>
      </Typography>

      <Typography
        fontWeight={400}
        sx={{
          width: '100%',
          marginBottom: '32px',
          fontSize: { md: '1.125rem' },
          lineHeight: { md: '25.2px' },
          minHeight: '120px',
        }}
      >
        {isCanceled
          ? 'Votre rendez-vous a bien été annulé. Nous espérons vous voir bientôt ! N’hésitez pas à en reprendre un quand vous le souhaitez.'
          : 'Confirmez l’annulation de votre rendez-vous en un clic. Vous pourrez toujours en reprendre un plus tard.'}
      </Typography>
      <Grid item mb={1}>
        <Chip
          sx={{
            height: '40px',
            borderRadius: '12px',
            backgroundColor: themes.default.lavender,
          }}
          label={
            <Grid container alignItems="center">
              <At size={19.5} color={themes.default.ordoriaPrimary} />
              <Typography marginLeft="12px">{organization?.email}</Typography>
            </Grid>
          }
        />
      </Grid>
      <Grid item mb={1}>
        <Chip
          sx={{
            height: '40px',
            borderRadius: '12px',
            backgroundColor: themes.default.lavender,
          }}
          label={
            <Grid container alignItems="center">
              <PhoneCall size={19.5} color={themes.default.ordoriaPrimary} />
              <Typography marginLeft="12px">{organization?.phone}</Typography>
            </Grid>
          }
        />
      </Grid>
      <Grid item mb={1}>
        <Chip
          sx={{
            height: '40px',
            borderRadius: '12px',
            backgroundColor: themes.default.lavender,
          }}
          label={
            <Grid container alignItems="center">
              <Globe size={19.5} color={themes.default.ordoriaPrimary} />
              <Typography marginLeft="12px">
                <a
                  href="https://www.fedipat.com"
                  target="_blank"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  rel="noreferrer"
                >
                  www.fedipat.com
                </a>
              </Typography>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};
