import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Typography } from '@mui/material';
import If from 'app/components/If';
import dayjs from 'dayjs';

function LateDaysCell(params: CustomCellRendererProps) {
  const dueDate = params?.value?.dueDateData;

  const lateDays = dayjs().diff(dayjs(dueDate), 'days');

  const displayLateDays = dueDate && lateDays > 0 && !params?.value?.isPaid;

  return (
    <If condition={displayLateDays} otherwise="-">
      <Typography>{lateDays}</Typography>
    </If>
  );
}

export default LateDaysCell;
