import { Tooltip } from '@mui/material';
import { BoxArrowDown } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

function ArchivedCell() {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('customer.status.archived')}>
      <div>
        <BoxArrowDown
          style={{
            width: '16px',
            height: '16px',
            cursor: 'pointer',
            color: themes?.default?.red800,
          }}
        />
      </div>
    </Tooltip>
  );
}

export default ArchivedCell;
