import GridContext from 'app/components/ManagedGrid/GridContext';
import { useTranslation } from 'react-i18next';
import {
  BILLS_STATS_COLORS,
  BillsStatus,
  getCustomInvoicesColumnConfig,
  TransactionsTab,
} from './config';
import { ListComponent } from './ListComponent';
import { InvoicesGrid } from './InvoicesGrid';
import { generatePath, useNavigate } from 'react-router-dom';
import { ORDORIA_TRANSACTIONS } from 'utils/routes';
import { ListPaymentProvider } from './ListComponent/ListPaymentProvider';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useSelector } from 'react-redux';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';

export function Invoices() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authUser = useSelector(selectAuthUser);
  const isUserCustomer = authUser?.currentAgent?._role?.key === 'customer';

  const columns = getCustomInvoicesColumnConfig(t, isUserCustomer);

  function calculateGrandTotal(facet) {
    return facet?.count || 0;
  }

  function calculateStatusMetrics(status: string, facet) {
    const statusTotal = facet?.status?.[status] || 0;
    const totalCount = facet?.count || 1;
    return {
      total: statusTotal,
      percentage: +((statusTotal / totalCount) * 100).toFixed(2),
    };
  }

  const { isCustomer } = useGetUserAcl();

  return (
    <ListPaymentProvider>
      <GridContext
        data={[]}
        customColumnConfig={columns}
        title="nav.mainNav.ordoria.transactions"
        canExport={true}
        canEdit={!isCustomer()} ///since admin flow is isn't implemented yet
        listComponent={isUserCustomer ? ListComponent : undefined}
        tabs={[
          {
            label: t('common.bills'),
            value: TransactionsTab.BILLS,
          },
          {
            label: t('common.refund'),
            value: TransactionsTab.REFUND,
          },
          {
            label: t('common.payments'),
            value: TransactionsTab.PAYMENTS,
          },
        ]}
        onTabChange={(tab) => {
          const path = generatePath(ORDORIA_TRANSACTIONS + `/${tab}`);
          navigate(path);
        }}
        selectedTab={TransactionsTab.BILLS}
        statsConfig={{
          colors: BILLS_STATS_COLORS,
          statuses: BillsStatus,
          title: 'events.status',
          calculateStatusMetrics,
          calculateGrandTotal,
        }}
      >
        <InvoicesGrid />
      </GridContext>
    </ListPaymentProvider>
  );
}
