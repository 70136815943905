import { useGetCustomerPublicDetailsQuery } from 'common/services/customerApi';
import { TwoViewModal } from '../TwoViewModal';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useQuery } from 'common/hooks/router';
import { UploadFiles } from 'app/components/UploadFiles';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';

interface RegistrationDocumentsProps {
  open: boolean;
  onClose: () => void;
}

export const RegistrationDocumentsModal = ({
  open,
  onClose,
}: RegistrationDocumentsProps) => {
  const authUser = useSelector(selectAuthUser);
  const authCustomer = authUser?.currentAgent?._customer;

  const { token: urlToken } = useParams();
  const urlQuery = useQuery();
  const token = authCustomer?.token || urlToken;
  const password =
    !urlQuery.get('password') || urlQuery.get('password') === 'undefined'
      ? 'fedipat'
      : urlQuery.get('password');

  const { data: customer } = useGetCustomerPublicDetailsQuery(
    { token, password },
    {
      skip: isEmpty(token) || isEmpty(password),
    },
  );
  return (
    <TwoViewModal
      onOpen={open}
      onClose={onClose}
      defaultMode="floated"
      bodyPadding="0"
      width="750px"
    >
      <UploadFiles
        customer={customer}
        tokenPassword={password}
        type="id"
        onUploadSuccess={() => {
          onClose();
        }}
      />
    </TwoViewModal>
  );
};
