import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  Collapse,
} from '@mui/material';
import {
  Gift,
  Shapes,
  ShoppingCart,
  Tag,
  TreeStructure,
} from '@phosphor-icons/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';
import { VetricalTabItem } from './components/VetricalTabItem';
import { CategoryValue } from './components/CategoryValue';
import { CategoryIcon } from './components/CategoryIcon';
import GridContext from 'app/components/ManagedGrid/GridContext';
import { getColumnConfig } from './config';
import { ContractsGrid } from './components/ContractsGrid';
import {
  ContractTabs,
  Contract,
  UpdateCategoryDiscounts,
  ContractTabData,
} from './types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import If from 'app/components/If';
import { OrderAlert } from 'app/pages/Ordoria/ViewEditOrder/components/OrderAlert';
import { useHelpers } from './useHelpers';
import { ButtonLoadingContainer } from 'app/pages/Ordoria/Orders/components/CreateOrderDrawerSales';
import { themes } from 'styles/theme/themes';
import { OffersList } from './components/OffersGrid/Components/OffersList';
import { ProductsDiscounts } from './components/ProductsDiscounts/ProductsDiscounts';
import { useNavLock } from 'hooks/useNavLock';
import { ViewCustomerContext } from '../../..';
import moment from 'moment';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { useGuardedAction } from 'hooks/useGuardedAction';

export const ContractsTab = ({ minMargin }: { minMargin?: number }) => {
  /* ------------------------------------ Variables ------------------------------------ */
  const theme = useSelector(selectTheme);
  const { t, i18n } = useTranslation();
  const can = usePermission('customers');

  /* ------------------------------------ Contexts ------------------------------------- */
  const { customer } = useContext(ViewCustomerContext);

  /* -------------------------------------- Hooks -------------------------------------- */
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    getContractFacets,
    handleUpdateRowDiscounts,
    handleUpdateProductsDiscounts,
    getIsLoadingNotes,
    hasContracts,
  } = useHelpers();
  const { navLocked, setNavLock } = useNavLock();
  const { executeAction, setOnDiscard } = useGuardedAction();

  const contractTabsData: ContractTabData[] = [
    {
      key: ContractTabs.CATEGORY,
      name: 'supplier.category',
      icon: <Shapes />,
      level: 0,
    },
    {
      key: ContractTabs.SUB_CATEGORY,
      name: 'subcategory',
      icon: <TreeStructure />,
      level: 1,
    },
    {
      key: ContractTabs.CHILD_CATEGORY,
      name: 'child_category',
      icon: <TreeStructure />,
      level: 2,
    },
    {
      key: ContractTabs.PRODUCT,
      name: 'orderDetail.tab.product',
      icon: <Tag />,
    },
    {
      key: ContractTabs.OFFERS,
      name: 'contracts.offers',
      icon: <Gift />,
    },
    {
      key: ContractTabs.PRE_ORDER,
      name: 'contracts.pre-order',
      icon: <ShoppingCart />,
    },
  ];

  /* ------------------------------------ Use Memos ------------------------------------ */

  const contracts = useMemo<Contract[]>(() => {
    //! dont remove this line
    // - it prevents .map from catching getContractFacets in its context
    // - it allows react to update the reference so map gets the new reference
    const getStats = getContractFacets;
    return contractTabsData.map(({ key, name, icon, ...rest }) => {
      const stats = getStats(key);
      return {
        key,
        name: t(name),
        startAdornament: <CategoryIcon icon={icon} />,
        itemsApproved: stats?.approved || 0,
        itemsPending: stats?.pending_approval || 0,
        itemsTotal: stats?.total || 0,
        ...rest,
      };
    });
  }, [getContractFacets]);

  const showConfirmationWarning = useMemo<boolean>(() => {
    if (contracts.length === 0) return false;

    const contractHasPendingItems = contracts.some(
      (contract) => contract.itemsPending > 0,
    );

    return contractHasPendingItems;
  }, [contracts]);

  const showConfirmationProductInfo = useMemo<boolean>(() => {
    if (contracts.length === 0) return false;

    return contracts.some(
      (contract) =>
        contract.itemsTotal > 0 && contract.key === ContractTabs.PRODUCT,
    );
  }, [contracts]);

  /* ------------------------------------ Use States ----------------------------------- */
  const [selectedTab, setSelectedTab] = useState<Contract>(
    contracts.find(
      (contract) => contract.key === searchParams.get('contract'),
    ) || contracts[0],
  );
  const [rowsEditedBySales, setRowsEditedBySales] = useState<
    UpdateCategoryDiscounts[]
  >([]);

  /* ----------------------------------- Use Effects ----------------------------------- */
  useEffect(() => {
    setSelectedTab(
      contracts.find(
        (contract) => contract.key === searchParams.get('contract'),
      ) || contracts[0],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts]);

  useEffect(() => {
    setNavLock(rowsEditedBySales.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsEditedBySales]);

  /* ----------------------------------- Use Callbacks --------------------------------- */
  const handleChangeTab = useCallback(
    (tab: Contract) => {
      const action = () => {
        const params = new URLSearchParams(location.search);
        params.set('contract', tab.key);
        navigate(`?${params.toString()}`);

        setSelectedTab(tab);
      };

      executeAction(action, navLocked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search, navLocked, navigate],
  );

  const handleResetDiscounts = useCallback(() => {
    setRowsEditedBySales([]);
    setNavLock(false);
  }, [setNavLock]);

  useEffect(() => {
    setOnDiscard(() => handleResetDiscounts);
  }, [handleResetDiscounts, setOnDiscard]);

  const handleSaveDiscounts = useCallback(() => {
    if (selectedTab.key === ContractTabs.PRODUCT) {
      handleUpdateProductsDiscounts(rowsEditedBySales).then(() => {
        handleResetDiscounts();
      });
    } else {
      handleUpdateRowDiscounts(rowsEditedBySales).then(() => {
        handleResetDiscounts();
      });
    }
  }, [
    handleResetDiscounts,
    handleUpdateProductsDiscounts,
    handleUpdateRowDiscounts,
    rowsEditedBySales,
    selectedTab.key,
  ]);

  /* ----------------------------------------------------------------------------------- */
  return (
    <Stack height="100%">
      <StyledTopWrapper>
        <Collapse
          in={
            selectedTab.key === ContractTabs.PRODUCT &&
            showConfirmationProductInfo
          }
        >
          <OrderAlert
            severity="info"
            content={t('productDiscounts.discount_alert')}
          />
        </Collapse>
        <Collapse in={showConfirmationWarning}>
          <OrderAlert
            severity="warning"
            content={t('customers.warnings.pending_contracts')}
          />
        </Collapse>
      </StyledTopWrapper>
      <Wrapper>
        <LeftSection>
          <Typography
            fontSize="0.875rem"
            fontWeight="500"
            lineHeight="22px"
            letterSpacing="0.1px"
            color={theme.black2}
          >
            {t('customer_Contracts')}
          </Typography>
          <Stack gap="8px">
            {contracts.map((contract, index) => (
              <VetricalTabItem
                key={index}
                name={contract.name}
                startAdornament={contract.startAdornament}
                value={
                  <If condition={contract.itemsTotal > 0}>
                    <CategoryValue
                      value={contract.itemsApproved}
                      total={contract.itemsTotal}
                    />
                  </If>
                }
                selected={contract.key === selectedTab.key}
                onClick={() => handleChangeTab(contract)}
              />
            ))}
          </Stack>
          {/* HIDE LAST UPDATED AT DATE */}
          <If condition={false && hasContracts()}>
            <Typography
              padding="12px 8px"
              fontSize="0.8125rem"
              fontWeight="400"
              lineHeight="18px"
              letterSpacing="0.17px"
              color={theme.clientMarkerBg}
            >
              {`${t('common.last_updated')} ${moment(customer?.updatedAt)
                .utc()
                .locale(i18n.language)
                .format('DD/MM/YYYY')}`}
            </Typography>
          </If>
        </LeftSection>
        <RightSection>
          {selectedTab.key !== ContractTabs.OFFERS &&
          selectedTab.key !== ContractTabs.PRODUCT ? (
            <GridContext
              data={[]}
              customColumnConfig={getColumnConfig(t)}
              title="orderProductsGrid"
              rowHeight={46}
              canEdit={can(Permission.EDIT)}
              suppressToolbar={true}
              suppressPagination={true}
              suppressHeader={true}
            >
              <ContractsGrid
                selectedTab={selectedTab}
                rowsEditedBySales={rowsEditedBySales}
                setRowsEditedBySales={setRowsEditedBySales}
              />
            </GridContext>
          ) : selectedTab.key === ContractTabs.OFFERS ? (
            <OffersList selectedTab={selectedTab} />
          ) : (
            <ProductsDiscounts
              selectedTab={selectedTab}
              rowsEditedBySales={rowsEditedBySales}
              setRowsEditedBySales={setRowsEditedBySales}
              minMargin={minMargin}
            />
          )}
        </RightSection>
      </Wrapper>
      {selectedTab.key !== ContractTabs.OFFERS && can(Permission.EDIT) && (
        <ActionsComponent
          isLoading={getIsLoadingNotes()}
          saveDisabled={!rowsEditedBySales.length}
          onSave={handleSaveDiscounts}
          onReset={() => executeAction(handleResetDiscounts, navLocked)}
        />
      )}
    </Stack>
  );
};

const Wrapper = styled.div`
  padding: 20px 0px 0px 20px;
  display: flex;
  gap: 20px;
  height: 100%;
  overflow-y: hidden;
`;

const LeftSection = styled.div`
  flex: 1;
  max-width: 215px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: auto;
`;

const StyledTopWrapper = styled.div`
  position: sticky;
  top: 0;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-grow: 1;
  overflow-y: scroll;
  max-height: 110vh;
`;

const ActionsComponent = ({
  saveDisabled,
  isLoading,
  onSave,
  onReset,
}: {
  saveDisabled: boolean;
  isLoading: boolean;
  onSave: () => void;
  onReset: () => void;
}) => {
  const { t } = useTranslation();
  const { isAdmin } = useHelpers();

  if (isAdmin()) return null;

  return (
    <ActionsWrapper>
      <Button onClick={onReset} color="inherit" disabled={saveDisabled}>
        {t('common.reset')}
      </Button>
      <Button
        onClick={onSave}
        variant="contained"
        autoFocus
        disabled={saveDisabled}
      >
        {isLoading && (
          <ButtonLoadingContainer>
            <CircularProgress
              size="16px"
              sx={{ color: themes.default.accordionWhiteBg }}
            />
          </ButtonLoadingContainer>
        )}
        <span style={{ opacity: isLoading ? 0 : 1 }}>
          {t('common.buttons.save')}
        </span>
      </Button>
    </ActionsWrapper>
  );
};

const ActionsWrapper = styled.div`
  padding: 12px 20px 12px 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  border-top: 1px solid ${themes.default.silver};
`;
