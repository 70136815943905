import React from 'react';
import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { IRoundtripMappingsContext, IWarehouseMapping } from '../../index';
import RoundtripFormRow from '../RoundtripFormRow';
import { Plus } from '@phosphor-icons/react';
import { themes } from '../../../../../../styles/theme/themes';
import { useManagedContext } from '../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';

function WarehouseRoundtripsCardContent({
  warehouse,
}: {
  warehouse: IWarehouseMapping;
}) {
  const RoundtripMappingsContext = useManagedContext<IRoundtripMappingsContext>(
    'RoundtripMappingsContext',
  );
  const { t } = useTranslation();
  const mappingCan = usePermission('mappings')(Permission.EDIT);

  return (
    <CardContent
      sx={{
        borderRadius: '0px !important', // Forces the border-radius to 0
        paddingLeft: '30px', // Adds padding to the left
        paddingRight: '15px', // Adds padding to the right
        display: 'grid', // Sets the layout to CSS Grid
        position: 'relative', // Makes the position relative
        gridTemplateColumns: '35px repeat(3, 2fr) 35px', // Defines the grid structure
        alignItems: 'center', // Centers items vertically
        gap: '0px', // Adds a gap between grid items
        '&:last-child': { paddingBottom: '16px' }, // Removes bottom padding for the last child
      }}
    >
      <Stack />
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '17.16px',
          letterSpacing: '0.17px',
          textAlign: 'left',
        }}
      >
        {t('common.roundtrip') + ' Gescom'}
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '17.16px',
          letterSpacing: '0.17px',
          textAlign: 'left',
        }}
      >
        {t('common.driver')}
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '17.16px',
          letterSpacing: '0.17px',
          textAlign: 'left',
        }}
      >
        {t('common.vehicle')}
      </Typography>
      <Stack />

      {warehouse.roundtrips.map((roundtrip, i) => {
        return (
          <RoundtripFormRow
            index={i}
            warehouseId={warehouse.id}
            roundtrip={roundtrip}
            key={roundtrip.id}
          />
        );
      })}
      {mappingCan ? (
        <Button
          startIcon={<Plus />}
          onClick={() => {
            RoundtripMappingsContext.updateDataWithFunction((prev) => {
              prev.roundtripMappings = prev.roundtripMappings.map((mapping) => {
                if (mapping.id === warehouse.id) {
                  mapping.roundtrips = [
                    ...mapping.roundtrips,
                    {
                      id: `New ${mapping.nbOfRoundtrips + 1}`,
                      area: '',
                      roundtripCode: '',
                      driver: '',
                      vehicle: '',
                    },
                  ];
                  mapping.nbOfRoundtrips += 1;
                }
                return mapping;
              });
            });
          }}
          sx={{
            width: 'fit-content',
            marginLeft: '25px',
            marginTop: '12px',
            color: themes.default.primary,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          disableRipple
        >
          {t('add')}
        </Button>
      ) : (
        <br />
      )}
    </CardContent>
  );
}

export default WarehouseRoundtripsCardContent;
