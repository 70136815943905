import {
  DiscountIndex,
  discountIndices,
  DiscountName,
  IDiscountsRecord,
} from '../types/discounts.types';
import {
  IRestrictions,
  IRestrictionsRecord,
  restrictionDefaults,
  RestrictionValue,
  RestrictionValueDefault,
} from '../types/restrictions.types';

const emptyDiscountIndexedRecord = (value: RestrictionValue) =>
  discountIndices.reduce((all, i: DiscountIndex) => {
    const ri: DiscountName = `r${i}`;
    all[ri] = value;
    return all;
  }, {});

export const emptyDiscountsRecord = () =>
  emptyDiscountIndexedRecord(0) as IDiscountsRecord;

export const emptyRestrictionsRecord = (value: RestrictionValueDefault) =>
  emptyDiscountIndexedRecord(value) as IRestrictionsRecord;

export const emptyRestrictions = () =>
  Object.entries(restrictionDefaults).reduce((all, [k, v]) => {
    all[k] = emptyRestrictionsRecord(v);
    return all;
  }, {} as IRestrictions);
