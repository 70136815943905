import { Slide, SlideProps, Portal, Snackbar, Typography } from '@mui/material';
import { selectLastStatusToasterNotification } from 'common/store/app/selectors';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
// import If from '../If';
import { appActions } from 'common/store/app';
import { StatusToastVariant } from 'common/types/StatusToasterNotification';
import React, { useCallback, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { CheckCircle, XCircle } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { useTranslation } from 'react-i18next';
import If from '../If';

export const StatusToasterNotification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lastStatusToasterNotification = useSelector(
    selectLastStatusToasterNotification,
  );

  const handleCloseNotification = useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      dispatch(appActions.cleanLastStatusToasterNotification());
    },
    [dispatch],
  );

  const getMessageTemplate = useCallback((): {
    defaultMessage: string;
    iconComponent: JSX.Element;
  } => {
    if (lastStatusToasterNotification?.variant === StatusToastVariant.Success) {
      return {
        defaultMessage: t('orders.items.statuses.added'),
        iconComponent: <SuccessIcon />,
      };
    } else if (
      lastStatusToasterNotification?.variant === StatusToastVariant.Loading
    ) {
      return {
        defaultMessage: t('common.saving') + '...',
        iconComponent: <SavingIcon />,
      };
    } else {
      return {
        defaultMessage: t('payments.status.error'),
        iconComponent: <ErrorIcon />,
      };
    }
  }, [lastStatusToasterNotification, t]);

  const messageContent = useMemo(() => {
    return getMessageTemplate();
  }, [getMessageTemplate]);

  return (
    <If condition={!!lastStatusToasterNotification}>
      <Portal>
        <Snackbar
          open
          autoHideDuration={
            lastStatusToasterNotification?.variant ===
            StatusToastVariant.Loading
              ? undefined
              : lastStatusToasterNotification?.duration
          }
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          TransitionComponent={SlideTransition}
        >
          <CustomAlert
            style={{
              right: `${lastStatusToasterNotification?.spacingRight}`,
              bottom: `${lastStatusToasterNotification?.spacingBottom}`,
            }}
          >
            {messageContent.iconComponent}
            <Typography
              fontSize="0.875rem"
              lineHeight="1rem"
              letterSpacing="0.17px"
              color={themes.default.baseWhite}
              width="fit-content"
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {lastStatusToasterNotification?.message ||
                messageContent.defaultMessage}
            </Typography>
          </CustomAlert>
        </Snackbar>
      </Portal>
    </If>
  );
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const SuccessIcon = () => {
  return (
    <Icon
      icon={<CheckCircle weight="fill" />}
      size={20}
      color={themes.default.green3}
    />
  );
};

const SavingIcon = () => {
  return (
    <CircularProgress size={16} sx={{ color: themes.default.biantaPrimary }} />
  );
};

const ErrorIcon = () => {
  return (
    <Icon
      icon={<XCircle weight="fill" />}
      size={20}
      color={themes.default.Cinnabar}
    />
  );
};

const CustomAlert = styled.div`
  min-width: max-content;
  height: 36px;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${themes.default.chipTextColor};
  box-shadow:
    0px 1px 18px 0px ${themes.default.borderLightShadow},
    0px 6px 10px 0px ${themes.default.borderMediumShadow},
    0px 3px 5px -1px ${themes.default.borderDarkShadow};
  position: absolute;
`;
