import { getImgCdn } from 'common/helpers/cdn';
import NoPhoto from 'assets/img/ORDORIA/Catalog/NoPhoto.svg';
import { toTitleCase } from 'common/helpers/stringFormatter';
import { useCallback } from 'react';
import { useAddProductsToOrderMutation } from 'common/services/orderApi';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';
import { OrderProduct } from 'types';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';

export const useHelpers = () => {
  const [addProductsToOrder] = useAddProductsToOrderMutation();

  const { t } = useTranslation();
  const { callApi } = useAsyncDataV2();
  const toast = useToaster();

  function getProcessedCatalogFunction(data) {
    return data?.map((prod, index) => {
      const pictureKey =
        prod?.pictures?.length > 0 ? prod?.pictures[0]?.key : '';

      const id = prod?._id || index;
      const favorite = prod?.favorite || false;

      const pictureUrl = !!pictureKey ? getImgCdn(pictureKey, 64) : NoPhoto;
      const code = prod?.code || '';
      const name = toTitleCase(prod?.name || '');
      const isNew = prod?.new || false;
      const category = prod?._category || '';
      const boxingUnit = prod?.boxingUnit || 0;
      const weight = prod?.weight?.toFixed(2);
      const stock = prod?.stock || 0;
      const discount = prod?.discount || 0;
      const price = prod?.boxPrice;

      return {
        id: prod._id,
        __favoriteIcon: {
          cellData: { favorite, id },
        },
        __flagIcon: {
          orderFrequency: prod?.orderFrequency,
        },
        product: {
          cellData: {
            id,
            pictureUrl,
            code,
            isNew,
            name,
            fullProduct: prod,
          },
        },
        category: {
          cellData: category,
        },
        uc: {
          cellData: boxingUnit,
        },
        weight: {
          cellData: weight,
        },
        stock: {
          cellData: stock,
        },
        price: {
          cellData: {
            price,
            discount,
          },
        },
        quantity: {
          cellData: {
            id,
          },
        },
        __actions: {
          cellData: {
            id: prod._id,
            product: prod,
          },
        },
      };
    });
  }

  function getProcessedProductFunction(data, category) {
    return data?.map((prod, index) => {
      const pictureKey =
        prod?.pictures?.length > 0 ? prod?.pictures[0]?.key : '';

      const id = prod?._id || index;
      const favorite = prod?.favorite || false;

      const pictureUrl = !!pictureKey ? getImgCdn(pictureKey, 64) : NoPhoto;
      const code = prod?.code || '';
      const name = toTitleCase(prod?.name || '');
      const isNew = prod?.new || false;
      const boxingUnit = prod?.boxingUnit || 0;
      const weight = prod?.weight?.toFixed(2);
      const stock = prod?.stock || 0;
      const discount = prod?.discount || 0;
      const price = prod?.boxPrice;

      return {
        id: prod._id,
        __favoriteIcon: {
          cellData: { favorite, id },
        },
        __flagIcon: {
          orderFrequency: prod?.orderFrequency,
        },
        product: {
          cellData: {
            id,
            pictureUrl,
            code,
            isNew,
            name,
            fullProduct: prod,
          },
        },
        category: {
          cellData: category,
        },
        uc: {
          cellData: boxingUnit,
        },
        weight: {
          cellData: weight,
        },
        stock: {
          cellData: stock,
        },
        price: {
          cellData: {
            price,
            discount,
          },
        },
        quantity: {
          cellData: {
            id,
          },
        },
        __actions: {
          cellData: {
            id: prod._id,
            product: prod,
          },
        },
      };
    });
  }

  const getFormattedSelectedProducts = useCallback(
    (
      selected: string[],
      allProducts: { _id: string; boxPrice: number | null }[],
    ): OrderProduct[] => {
      const fullProducts = allProducts.filter((prod) =>
        selected.includes(prod?._id),
      );

      return fullProducts.map((prod) => ({
        _product: prod?._id,
        quantity: 1,
        discount: 0,
        salePrice: prod?.boxPrice || 0,
        amount: prod?.boxPrice || 0,
      }));
    },
    [],
  );

  const handleAddProductToOrder = useCallback(
    async (orderId: string, orderCode: string, products: OrderProduct[]) => {
      await callApi(
        async () => {
          return await addProductsToOrder({
            orderId: orderId,
            products: products,
          }).unwrap();
        },
        {
          onSuccess: () =>
            toast(
              3000,
              'success',
              t('catalog.items_added_to_order', {
                number: products.length,
                orderCode: orderCode,
              }),
            ),
          onError: () => {},
        },
      );
    },
    [callApi, addProductsToOrder, toast, t],
  );

  return {
    getProcessedCatalogFunction,
    getProcessedProductFunction,
    handleAddProductToOrder,
    getFormattedSelectedProducts,
  };
};
