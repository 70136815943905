import { Box, Chip, Radio, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

/**
 * @deprecated this component is deprecated and should not be used, use web\src\app\components\Form\ChipRadio instead
 */
export const ChipWithRadio = ({
  value,
  label,
  selectedValue,
  disabled = false,
}) => {
  const checked = selectedValue === value;
  const theme = useSelector(selectTheme);
  return (
    <Chip
      label={
        <Box display="flex" alignItems="center">
          <Radio
            value={value}
            checked={checked}
            size="small"
            sx={{
              p: 0,
              mr: '4px',
              color: checked
                ? theme?.primaryActiveColor
                : theme?.chipsBorderGrey,
              '&.Mui-checked': {
                color: checked
                  ? theme?.primaryActiveColor
                  : theme?.chipsBorderGrey,
              },
            }}
            disabled={disabled}
          />
          <Typography fontWeight={checked ? '500' : '400'} fontSize="0.8125rem">
            {label}
          </Typography>
        </Box>
      }
      variant="outlined"
      sx={{
        backgroundColor: checked ? theme?.primaryLight1 : 'white',
        borderColor: checked ? theme?.strokePrimary : theme?.chipsBorderGrey,
        color: checked ? theme?.primaryActiveColor : theme?.textBlack,
        marginLeft: '12px',
        height: '28px',
        '.MuiChip-label': {
          padding: '0px 12px 0px 8px',
        },
      }}
      size="medium"
    />
  );
};
