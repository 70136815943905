import {
  Grid,
  Box,
  Autocomplete,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FieldComponent } from 'app/components/FieldComponent';
import {
  useCreateVisitorMutation,
  usePatchUpdateVisitorMutation,
  useGetOrganizationVisitorAgentsQuery,
} from 'common/services/visitors';
import { useToaster } from 'hooks/useToaster';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import Email from 'assets/img/visitors/email.svg';
import dayjs from 'dayjs';
import { useDebounce } from '@uidotdev/usehooks';
import { isEmpty } from 'lodash';
import { themes } from 'styles/theme/themes';

interface IVisitorsForm {
  organization: any;
  visit?: any;
}

export const VisitorsForm = ({ organization, visit }: IVisitorsForm) => {
  const toast = useToaster();

  const [createVisitor] = useCreateVisitorMutation();
  const [patchUpdateVisitor] = usePatchUpdateVisitorMutation();

  const [agentsSearch, setAgentsSearch] = useState<string>('');
  const debouncedAgentsSearch = useDebounce(agentsSearch, 200);

  const isEdit: boolean = !!visit;

  const { data: agents } = useGetOrganizationVisitorAgentsQuery(
    {
      organizationId: organization?.id,
      text: debouncedAgentsSearch,
    },
    {
      skip: isEmpty(debouncedAgentsSearch),
    },
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: visit || {
      lastName: '',
      firstName: '',
      company: '',
      email: '',
      reason: '',
      _relatedAgent: '',
      comment: '',
    },
  });

  const {
    control,
    formState: { isValid },
    reset,
  } = methods;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
    visit?.plannedAt ? dayjs(visit.plannedAt) : null,
  );
  const [selectedReason, setSelectedReason] = useState(visit?.reason || null);
  const [relatedAgent, setRelatedAgent] = useState(
    visit?._relatedAgent || null,
  );
  const [phone, setPhone] = useState<string>(visit?.phone || '');

  const isFormCompleted =
    isValid &&
    phone !== '' &&
    selectedReason !== null &&
    relatedAgent !== null &&
    selectedDate !== null;

  const handlePhoneChange = (prevValue) => {
    setPhone(prevValue);
  };

  function handleSuccess() {
    reset();
    setPhone('');
    setSelectedReason(null);
    setIsFormSubmitted(true);
    setSelectedDate(dayjs());
    toast(3000, 'success', t('visitors.post-success'));
  }

  function handleCreateVisitor(payload) {
    createVisitor({ body: payload, organizationId: organization?.id })
      .unwrap()
      .then(() => {
        handleSuccess();
      })
      .catch(() => {
        toast(3000, 'error', t('visitors.post-error'));
      });
  }

  function handleEditVisitor(payload) {
    patchUpdateVisitor(payload)
      .unwrap()
      .then(() => {
        handleSuccess();
      })
      .catch(() => {
        toast(3000, 'error', t('visitors.post-error'));
      });
  }

  function handleSubmitForm() {
    const payload = {
      ...control._formValues,
      phone,
      reason: selectedReason,
      plannedAt: selectedDate,
      _relatedAgent: relatedAgent?.id,
      uuid: visit?.uuid,
      organizationId: organization?.id,
    };

    if (isEdit) {
      handleEditVisitor(payload);
    } else {
      handleCreateVisitor(payload);
    }
  }

  // Populate options with selected agent if no search
  const agentOptions = useMemo(() => {
    const baseOptions =
      agentsSearch !== '' ? agents?.docs || [] : [visit?.cache?._relatedAgent];

    return baseOptions.map((agent) => ({
      ...agent,
      id: agent?._id,
    }));
  }, [agentsSearch, agents, visit?.cache?._relatedAgent]);

  useEffect(() => {
    if (visit?.plannedAt) {
      setSelectedDate(dayjs(visit.plannedAt));
    }
  }, [visit]);

  return (
    <Box
      sx={{
        backgroundColor: themes.default.accordionWhiteBg,
        width: '100%',
        maxWidth: '563px',
        height: '100%',
        maxHeight: '589px',
        borderRadius: '12px',
        padding: '40px 20px 27px 20px',
        boxShadow: `0px 5px 30px ${themes.default.greyTint25}`,
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      {!isFormSubmitted ? (
        <Grid container rowGap={0.5} columnSpacing={2} sx={{ width: '100%' }}>
          <Grid item xs={12} sm={6} md={6}>
            <FieldComponent
              name="lastName"
              control={control}
              required
              label={t('common.lastName').toString()}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FieldComponent
              name="firstName"
              control={control}
              required
              label={t('common.firstName').toString()}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <PhoneInput
              country="fr"
              specialLabel={t('mobile_phone').toString()}
              value={phone}
              onChange={handlePhoneChange}
              inputStyle={{ width: '100%', height: '37.12px' }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FieldComponent
              name="email"
              control={control}
              required
              label={t('visitor.email').toString()}
              placeholder={t('Add_Client_Enter_email_address').toString()}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FieldComponent
              name="company"
              control={control}
              required
              label="Entreprise"
              placeholder={t(
                'settings.companyProfile.detailsTextfield.companyName',
              ).toString()}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="reason"
              options={organization?.reasons}
              getOptionLabel={(option: any) => t(option)}
              value={selectedReason}
              onChange={(event, newValue) => {
                setSelectedReason(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  name="reason"
                  label={t('visitor.reason').toString()}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="personToMeet"
              options={agentOptions}
              getOptionLabel={(option: any) =>
                option?.firstName && option?.lastName
                  ? `${option.firstName} ${option.lastName}`
                  : ''
              }
              value={relatedAgent}
              onChange={(event, newValue: any) => {
                setRelatedAgent(newValue);
                //methods.setValue('_relatedAgent', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="personToMeet"
                  label={t('visitor.person-to-meet').toString()}
                  variant="outlined"
                  onChange={(e) => setAgentsSearch(e.target.value)}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              )}
              isOptionEqualToValue={(option, value) => {
                return option._id === value._id;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                onChange={(newDate) => setSelectedDate(newDate)}
                value={selectedDate}
                renderInput={(params) => (
                  <TextField sx={{ width: '100%' }} {...params} error={false} />
                )}
                label={t('visitor.date-hour').toString()}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FieldComponent
              name="comment"
              control={control}
              label={t('comments.comment').toString()}
              placeholder={t('addAComment')}
              multiline
              rows={4}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={handleSubmitForm}
              sx={{
                width: '168px',
                height: '45px',
                borderRadius: '12px !important',
                background: `${themes.default.azurePurple}`,
                color: themes.default.accordionWhiteBg,
                '&.Mui-disabled': {
                  background: themes.default.lightGrey3,
                  color: themes.default.grayShadow,
                },
              }}
              disabled={!isFormCompleted}
            >
              {t('common.buttons.send').toString()}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{
            height: '589px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Email}
            alt="Confirmation"
            style={{
              marginBottom: '8px',
              maxWidth: '93px',
              height: '100px',
            }}
          />
          <Typography
            fontWeight={500}
            sx={{
              fontSize: '12px',
            }}
          >
            {t('visitors.confirmation-email').toString()}
          </Typography>
        </Grid>
      )}
    </Box>
  );
};
