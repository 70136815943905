import { CheckCircle } from '@phosphor-icons/react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { themes } from 'styles/theme/themes';
import { IconButton } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { useRestrictionsPermission } from 'app/components/Restrictions/hooks/useRestrictionsPermission';
import { useHelpers } from '../useHelpers';

export const ApproveCell = (params: CustomCellRendererProps) => {
  const rowData = params?.value?.cellData;
  const isAdmin = useRestrictionsPermission();
  const { approveProductDiscount } = useHelpers();

  return (
    <>
      {rowData.status === 'pending_approval' && isAdmin ? (
        <IconButton
          disabled={rowData.disabled}
          sx={{
            padding: '2px',
          }}
          onClick={() => approveProductDiscount([rowData._id])}
        >
          <Icon
            icon={<CheckCircle weight={rowData.disabled ? 'thin' : 'fill'} />}
            size={24}
            color={rowData.disabled ? '' : themes.default.green800}
          />
        </IconButton>
      ) : (
        '-'
      )}
    </>
  );
};
