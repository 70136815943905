import { useModal } from 'app/components/Dialog/hooks';
import { useTranslation } from 'react-i18next';
import { ImportTransactionFilesContent } from './component/ImportTransactionFIlesContent';

interface OpenImportTransactionFilesProps {
  onImport: (file: File) => void;
  type: 'bills' | 'refund';
}

export const useImportTransactionFiles = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();

  const open = ({ onImport, type }: OpenImportTransactionFilesProps) => {
    openModal({
      title: t('add-by-file.dialog.title'),
      content: (
        <ImportTransactionFilesContent onImport={onImport} type={type} />
      ),
      cancel: true,
      action: {
        actionText: t('common.buttons.proceed'),
        actionCallback() {},
        buttonProps: { disabled: true },
      },
    });
  };

  return { open };
};
