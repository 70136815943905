import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { IconButton, Stack, Tooltip } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { themes } from 'styles/theme/themes';
import { Check } from '@phosphor-icons/react';

import {
  EXCEL,
  TRACK,
  GRID,
  LIST,
  CARD,
  MAP,
  REOPTIMIZE,
  REOPTIMIZEMAP,
  BOARD,
} from 'app/components/TableComponent/constants';
import If from 'app/components/If';
import { Icon } from 'app/components/Icon';

export interface ViewProps {
  type: string;
  icon: JSX.Element | null;
  label: string;
}
interface Props {
  views: ViewProps[];
  handleAction: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: string,
  ) => void;
  tableType:
    | typeof GRID
    | typeof LIST
    | typeof CARD
    | typeof TRACK
    | typeof EXCEL
    | typeof MAP
    | typeof REOPTIMIZE
    | typeof REOPTIMIZEMAP
    | typeof BOARD
    | string;
  buttonStyling?: any;
  disabled?: boolean;
  tooltip?: string;
}

export const TableViewPopover: React.FC<Props> = ({
  handleAction,
  tableType,
  views,
  buttonStyling,
  disabled = false,
  tooltip,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const theme = useSelector(selectTheme);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const currentView = React.useMemo(() => {
    for (let i = 0; i < views.length; i++) {
      if (views[i].type === tableType) return views[i];
    }
  }, [views, tableType]);

  return (
    <If condition={!!views?.length}>
      <IconButton
        disabled={disabled}
        onClick={handleClick}
        sx={{
          backgroundColor: 'transparent',
          '& svg': {
            color: `${
              disabled ? themes.default.nobel : theme.iconColor
            } !important`,
          },
          cursor: disabled ? 'default' : 'pointer',
          ...buttonStyling,
        }}
        disableRipple={true}
      >
        <Tooltip title={tooltip} placement="top" arrow>
          <Stack direction="row" gap="4px" alignItems="center">
            {!!currentView?.icon && <Icon icon={currentView.icon} size={16} />}
            <Typography
              fontSize="0.75rem"
              lineHeight="normal"
              color={disabled ? themes.default.nobel : theme.textColorPrimary}
            >
              {currentView?.label}
            </Typography>
            {anchorEl ? (
              <ArrowDropUpIcon
                sx={{ color: disabled ? themes.default.nobel : theme.icon }}
              />
            ) : (
              <ArrowDropDownIcon
                sx={{ color: disabled ? themes.default.nobel : theme.icon }}
              />
            )}
          </Stack>
        </Tooltip>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography>
          <PopoverWrapper>
            {views.map((view, index) => {
              return (
                <IconWrapper
                  key={index}
                  isActive={tableType === view.type}
                  onClick={(e) => {
                    handleClose(e);
                    handleAction(e, view.type);
                  }}
                  className="popover-el"
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    {view.icon}
                    <div>{view.label}</div>
                  </div>
                  {tableType === view.type && (
                    <Check
                      size={10}
                      color={themes?.default?.primaryActiveColor}
                    />
                  )}
                </IconWrapper>
              );
            })}
          </PopoverWrapper>
        </Typography>
      </Popover>
    </If>
  );
};

const PopoverWrapper = styled.div((props) => ({
  fontSize: '0.8125rem',
  padding: '8px 0px',
  '& .popover-el': {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    columnGap: 8,
    height: 28,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: props.theme.grey,
    },
  },
}));

interface IconWrapperProps {
  isActive?: boolean;
}

export const IconWrapper = styled.div<IconWrapperProps>((props) => ({
  // background: props.isActive ? props.theme.solitude : '',
  justifyContent: 'space-between',
  paddingRight: '16px',
  color: props.isActive ? props.theme.primaryActiveColor : '',
  '& svg': {
    fill: props.isActive ? props.theme.primaryActiveColor : '',
  },
}));
