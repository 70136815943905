/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';

import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { isEmpty } from 'lodash';
import FontFaceObserver from 'fontfaceobserver';
import { LicenseInfo } from '@mui/x-license-pro';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// Use consistent styling
import 'sanitize.css/sanitize.css';

import { App } from 'app';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { HelmetProvider } from 'react-helmet-async';

import store from 'store/configureStore';

import { ThemeProvider } from 'styles/theme/ThemeProvider';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';
import './assets/css/app.css';

import { persistStore } from 'redux-persist';
import { useAuthSlice } from './app/slices/auth';
import { LoadingAlertProvider } from 'app/components/LoadingAlert/context';
import { capitalizeMonth } from 'plugins/dayjs/capitalizeMonth';
import { NavLockProvider } from 'hooks/useNavLock';
import ApplicationLoader from 'app/components/ApplicationLoader';
import If from 'app/components/If';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectLoadingApp } from 'common/store/app/selectors';
import { appActions } from 'common/store/app';

declare global {
  interface Window {
    dayjs: any;
    cio_analytics: any;
  }
}

// @ts-ignore
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(capitalizeMonth);

window.dayjs = dayjs;
window.dayjs.locale('fr');

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Inter', {});

// When Inter is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const htmlStyles = {
  fontSize: '14px',
};
function SliceProvider(props) {
  useAuthSlice();

  return <>{props.children}</>;
}
const htmlElement = document.querySelector('html');
if (htmlElement !== null) {
  Object.assign(htmlElement.style, htmlStyles);
}

if (!isEmpty(process.env.REACT_APP_SENTRY_DSN)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // @ts-ignore
        tracePropagationTargets: [process.env.REACT_APP_API_URL],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const AppWrapper = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoadingApp);

  React.useEffect(() => {
    dispatch(appActions.setLoadingApp(true));
  }, [dispatch]);

  React.useEffect(() => {
    if (isLoading) {
      //display app loader for 2 seconds
      setTimeout(() => {
        dispatch(appActions.setLoadingApp(false));
      }, 2000);
    }
  }, [dispatch, isLoading]);

  return (
    <>
      <ApplicationLoader />

      <If condition={!isLoading}>
        <App />
      </If>
    </>
  );
};

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <LoadingAlertProvider>
        <SliceProvider>
          <HelmetProvider>
            <DndProvider options={HTML5toTouch}>
              <NavLockProvider>
                <AppWrapper />
              </NavLockProvider>
            </DndProvider>
          </HelmetProvider>
        </SliceProvider>
      </LoadingAlertProvider>
    </ThemeProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: any) => {
        if (event?.target?.state === 'activated') {
          /*
          if (
            window.confirm(
              'There is a new version of the app ready. Please reload to update.',
            )
          ) {
            window.location.reload();
          }
          */
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
