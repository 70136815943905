import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Typography } from '@mui/material';
import If from 'app/components/If';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { themes } from 'styles/theme/themes';
import { ORDERS_ACTIONS } from 'utils/routes';

function OrderCell(params: CustomCellRendererProps) {
  const navigate = useNavigate();

  return (
    <If condition={!isEmpty(params.value.orderCodeData)} otherwise="_">
      <Typography
        fontSize="0.875rem"
        onClick={() =>
          navigate(`${ORDERS_ACTIONS}/${params.value.orderIdData}`)
        }
        sx={{
          '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: themes?.ordoria?.primary,
          },
        }}
      >
        #{params.value.orderCodeData}
      </Typography>
    </If>
  );
}

export default OrderCell;
