import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import { FilterSection } from 'app/components/FilterComponents/styles';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { Chip, FormControlLabel, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import { BILLS_STATUS_CHIPS_COLORS, BillsStatus } from '../../config';
import CancelIcon from '@mui/icons-material/Cancel';
import If from 'app/components/If';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import {
  useGetCustomerBranchesQuery,
  useGetCustomersBasicQuery,
} from 'common/services/customerApi';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

export function InvoiceFilter() {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext>('grid');
  const [searchCustomer, setSearchCustomer] = useState<string>('');

  const { data: customers } = useGetCustomersBasicQuery(
    `text=${searchCustomer}`,
  );

  const { data: customerDeck } = useGetCustomerBranchesQuery(
    {
      customerId: gridContext?.queryParams?.filters?.customerId?.[0]?.value,
      urlQuery: '',
    },
    { skip: isEmpty(gridContext?.queryParams?.filters?.customerId) },
  );

  const { isCustomer } = useGetUserAcl();

  const handleClearAll = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters = {
        archived: false,
        status: [],
        customerId: [],
        customerDeckId: [],
      };
    });
  };

  const handleOptionsChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters[filterKey] = options;
    });
  };

  useEffect(() => {
    if (isEmpty(gridContext.queryParams.filters?.customerId)) {
      gridContext.updateDataWithFunction((prev) => {
        prev.queryParams.filters.customerDeckId = [];
      });
    }
  }, [gridContext.queryParams.filters.customerId]);

  return (
    <>
      <FilterHeader onClearAll={handleClearAll} />
      <FilterSection>
        <If condition={!isCustomer()}>
          <MultiSelect
            filterName={t('clients.client')}
            selectedOptions={gridContext.queryParams.filters.customerId || []}
            handleFilterChange={(options: any) => {
              const lastOption = options.at(-1);
              handleOptionsChange('customerId', lastOption ? [lastOption] : []);
            }}
            filterItems={customers?.docs?.map((deck) => ({
              value: deck?._id,
              label: deck?.publicName,
            }))}
            onSearch={(search: string) => {
              setSearchCustomer(search);
            }}
            autoCompleteProps={{
              renderTags: (selectedOptions) => {
                return selectedOptions.map((option) => (
                  <Typography
                    key={option?.value}
                    sx={{
                      maxWidth: '250px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {option?.label}
                  </Typography>
                ));
              },
            }}
          />
          <If condition={!isEmpty(gridContext.queryParams.filters?.customerId)}>
            <MultiSelect
              filterName={t('common.branch')}
              selectedOptions={
                gridContext.queryParams.filters.customerDeckId || []
              }
              handleFilterChange={(options: any) => {
                const lastOption = options.at(-1);
                handleOptionsChange(
                  'customerDeckId',
                  lastOption ? [lastOption] : [],
                );
              }}
              filterItems={customerDeck?.docs?.map((deck) => ({
                value: deck?._id,
                label: deck?.code + ' - ' + deck?.location?.shortAddress,
              }))}
              autoCompleteProps={{
                renderTags: (selectedOptions) => {
                  return selectedOptions.map((option) => (
                    <Typography
                      key={option?.value}
                      sx={{
                        maxWidth: '250px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {option?.label}
                    </Typography>
                  ));
                },
              }}
            />
          </If>
        </If>
        <MultiSelect
          filterName={t('payments.status')}
          selectedOptions={gridContext.queryParams.filters?.status}
          handleFilterChange={(options: any) => {
            handleOptionsChange('status', options);
          }}
          filterItems={Object.entries(BillsStatus).map(([key, value]) => ({
            value: value,
            label: t(`events.status.${key.toLowerCase()}`),
          }))}
          autoCompleteProps={{
            renderTags: (selectedOptions) => {
              return selectedOptions.map((option) => {
                const normalizedValue = option?.value
                  ?.replace(/-/g, '')
                  .toUpperCase();
                return (
                  <Chip
                    size="small"
                    label={option.label}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={() => {
                      handleOptionsChange(
                        'status',
                        selectedOptions.filter((o) => o.value !== option.value),
                      );
                    }}
                    sx={{
                      background:
                        BILLS_STATUS_CHIPS_COLORS[normalizedValue]
                          ?.backgroundColor,
                      color: BILLS_STATUS_CHIPS_COLORS[normalizedValue]?.color,
                      mr: '4px',
                    }}
                  />
                );
              });
            },
          }}
        />
        <If condition={!isCustomer()}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                size="small"
                checked={gridContext.queryParams.filters.archived}
                onChange={(e) => {
                  gridContext.updateDataWithFunction((prev) => {
                    prev.queryParams.filters.archived = e.target.checked;
                  });
                }}
              />
            }
            label={t('transactions.bills.show-archived-filter')}
          />
        </If>
      </FilterSection>
    </>
  );
}
