import { Stack, Typography } from '@mui/material';
import { Prohibit } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import If from 'app/components/If';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

export const ContractNameCell = (params) => {
  const { name, disabled } = params.value || {};
  const theme = useSelector(selectTheme);

  return (
    <Stack flexDirection="row" alignItems="center" gap="5px">
      <Typography
        fontSize="0.75rem"
        fontWeight="400"
        letterSpacing="0.17px"
        color={disabled ? theme.clientMarkerBg : theme.black2}
      >
        {name}
      </Typography>
      <If condition={disabled}>
        <Icon
          icon={<Prohibit weight="bold" />}
          size={12}
          color={theme.red800}
        />
      </If>
    </Stack>
  );
};
