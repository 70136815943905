import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useCallback, useMemo } from 'react';
import { GetSet, IRestrictionsContext } from '../restrictions.consts';

// extends cannot be a
type TKey<T extends keyof IRestrictionsContext> = T;
type TValue<T extends keyof IRestrictionsContext> =
  IRestrictionsContext[TKey<T>];
type TReturn<T extends keyof IRestrictionsContext> = GetSet<
  TValue<T> | undefined
>;

export const useRestrictionsContextOf = <T extends keyof IRestrictionsContext>(
  key: TKey<T>,
  defaultValue?: TValue<T>,
): TReturn<T> => {
  const grid = useManagedContext<IGridContext>('grid');

  const value = useMemo<TReturn<T>['value']>(
    () => grid.extraData.restrictionsContext?.[key],
    [grid.extraData.restrictionsContext, key],
  );

  const set = useCallback<TReturn<T>['set']>(
    (value) => {
      grid.updateDataWithFunction((g) => {
        const rContext = (g.extraData.restrictionsContext ??= {});
        const deleting = value === undefined && Object.hasOwn(rContext, key);
        if (deleting) delete rContext[key];
        else rContext[key] = value;
      });
    },
    [key],
  );

  const setCustom = useCallback<TReturn<T>['setCustom']>(
    (updater) => set(updater(value)), //
    [set, value],
  );

  return useMemo<TReturn<T>>(
    () => ({
      value: value === undefined ? defaultValue : value,
      set,
      setCustom,
    }),
    [value, defaultValue, setCustom],
  );
};
