import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IOrderDetailsContext } from '../..';
import styled from 'styled-components';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { InfoCard } from 'app/components/InfoCard';
import {
  CalendarBlank,
  CaretDoubleUp,
  Check,
  Checks,
  User,
  X,
  CaretDoubleDown,
} from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { usePreferredAgent } from 'hooks/PreferredAgent/usePreferredAgent';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { isEmpty } from 'lodash';
import If from 'app/components/If';
import { SupportUnitsTable } from 'app/components/OrderDetail/tabs/OrderInformation/components/SupportUnitsTable';
import { SupportUnitsPopover } from 'app/components/SupportUnitsPopover';
import { updateOrderFunction } from 'app/components/RoundTrips/function/ApiFunctions';
import { useUpdateOrderMutation } from 'common/services/orderApi';
import { useToaster } from 'hooks/useToaster';
import { OutlinedChip } from 'app/components/OutlinedChip';
import useFormatCurrency from 'common/hooks/useFormatCurrency';
import { OrderStatus } from 'types';
import { dateFormat } from 'common/utils/dates';
import { useGetUserQuery } from 'common/services/userApi';

export const OrderInfo = () => {
  const { t } = useTranslation();
  const toast = useToaster();
  const { order, canEdit, refetch, height } =
    useManagedContext<IOrderDetailsContext>('orderDetails');
  const [updateOrder] = useUpdateOrderMutation();
  const { preferredAgent } = usePreferredAgent({ user: order?._createdBy });
  const [toCollectValue, setToCollectValue] = useState(
    order?.amountRequested || 0,
  );
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const validatedById = order?._validatedBy?._id || order?._validatedBy;
  const { data: user } = useGetUserQuery(validatedById, {
    skip: isEmpty(validatedById),
  });
  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [toCollectValue, isFocused]);

  const formatCurrency = useFormatCurrency();

  const handlePaymentCollectionChange = async (newValue) => {
    if (order) {
      try {
        const resp = await updateOrder({
          id: order?._id,
          amountRequested: newValue,
        }).unwrap();

        if (resp) {
          toast(
            5000,
            'success',
            'clients.orderDetails.paymentCollectionSuccess',
          );
          refetch(order?._id);
        }
      } catch (e: any) {
        if (e.status === 401) {
          console.log(e);
        }
      }
    }
  };

  function handleSaveNewAmout() {
    const newValue = parseFloat(toCollectValue);
    handlePaymentCollectionChange(newValue);
  }

  const DeliveryRow = ({
    label,
    value,
    marginButton = 'auto',
  }: {
    label: string;
    value: React.ReactNode;
    marginButton?: number | string;
  }) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={marginButton}
        gap="8px"
        sx={{
          backgroundColor: themes?.default?.accordionWhiteBg,
          padding: '6px 12px',
          borderRadius: '4px',
          minHeight: '31px',
          maxHeight: '31px',
          '&.MuiStack-root': {
            borderRadius: '4px !important',
          },
        }}
      >
        <Typography
          fontSize="0.75rem"
          color={themes.default.nobel}
          width="50%"
          lineHeight="normal"
        >
          {t(label)}
        </Typography>
        <Typography fontSize="0.75rem" lineHeight="normal">
          {value}
        </Typography>
      </Stack>
    );
  };

  const rountripPosition = useMemo(() => {
    if (isEmpty(order?.roundtripCode)) {
      return 'N/A';
    }
    return `${order?.roundtripCode} / ${
      order?.roundtripPosition ? order?.roundtripPosition : 'No Position'
    }`;
  }, [order?.roundtripCode, order?.roundtripPosition]);

  const showSupportUnits = useMemo(() => {
    return (
      order?.supportUnitsList &&
      order?.status !== OrderStatus.Validated &&
      order?.status !== OrderStatus.Confirmed &&
      order?.status !== OrderStatus.Prepared
    );
  }, [order?.status, order?.supportUnitsList]);

  return (
    <Stack
      height="100%"
      maxHeight={`calc(${height}px - 102px)`}
      sx={{ backgroundColor: themes.default.lightGrey, paddingBottom: '15px' }}
    >
      <Box position="sticky" top="0" zIndex={2}>
        <Stack
          direction="row"
          gap="8px"
          padding="8px 24px"
          borderBottom={`1px solid ${themes.default.gainsboro2}`}
          height="40px"
          alignItems="center"
          sx={{
            background: `${themes.default.accordionWhiteBg}`,
          }}
        >
          <Typography>{t('order')}</Typography>
          <Typography fontWeight="600">#{order?.code}</Typography>

          {order?.status === OrderStatus.Delivered && (
            <OutlinedChip
              content={
                <>
                  {order?.paymentCollection >= order?.amountRequested ? (
                    <Icon
                      icon={<Checks />}
                      tooltip={t('tooltip.payment_fully_collected')}
                      color={themes?.default?.fruitSaladGreen}
                    />
                  ) : order?.paymentCollection < order?.amountRequested &&
                    order?.paymentCollection !== 0 ? (
                    <Icon
                      icon={<Check />}
                      tooltip={t('tooltip.payment_partially_collected')}
                      color={themes?.default?.fruitSaladGreen}
                    />
                  ) : (
                    <Icon
                      icon={<X />}
                      tooltip={t('tooltip.payment_not_collected')}
                      color={themes?.default?.redA700}
                    />
                  )}
                </>
              }
            />
          )}
          {order?.internalOrderCode && (
            <Typography sx={{ color: themes.default.clientMarkerBg }}>
              #{order?.internalOrderCode}
            </Typography>
          )}
        </Stack>
      </Box>

      <Stack
        overflow="auto"
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box flex="1">
          <Stack
            gap="12px"
            position="static"
            top="12px"
            padding="12px 12px"
            sx={{ background: '#FAFAFA' }}
          >
            <Stack direction="row" gap="12px">
              <InfoCard
                icon={
                  <CalendarBlank
                    weight="bold"
                    color={themes?.default?.deepPurple800}
                  />
                }
                iconBg={themes?.default?.purple50}
                title={t('common.created_on')}
                customBackground={themes?.default?.accordionWhiteBg}
                customBorder="none"
                value={
                  <Typography
                    noWrap
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontSize: '0.75rem',
                      fontWeight: 500,
                    }}
                  >
                    {dateFormat(order?.createdAt, true)}
                  </Typography>
                }
              />

              <InfoCard
                icon={
                  <CalendarBlank
                    weight="bold"
                    color={themes?.default?.amber1000}
                  />
                }
                iconBg={themes?.default?.amber100}
                title={t('clients.orderDetails.deliveryDate')}
                customBackground={themes?.default?.accordionWhiteBg}
                customBorder="none"
                value={
                  <Typography
                    noWrap
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontSize: '0.75rem',
                      fontWeight: 500,
                    }}
                  >
                    {dateFormat(order?.deliveryDate, true)}
                  </Typography>
                }
              />
            </Stack>
            <Stack direction="row" gap="12px">
              <InfoCard
                title={t('created_by')}
                customBackground={themes?.default?.accordionWhiteBg}
                customBorder="none"
                value={preferredAgent?.fullName || '-'}
                icon={<Icon icon={<User />} color={themes.default.blue700} />}
                iconBg={themes.default.blue50}
              />
              <InfoCard
                title={t('clients.orderDetails.validatedBy')}
                customBackground={themes?.default?.accordionWhiteBg}
                customBorder="none"
                value={user?.fullName || '-'}
                icon={<Icon icon={<User />} color={themes.default.teal900} />}
                iconBg={themes.default.teal50}
              />
            </Stack>
          </Stack>
        </Box>

        <Box>
          <Stack gap="24px" padding="12px 12px">
            <Stack gap="12px">
              <Typography fontWeight="600" fontSize="0.875rem">
                {t('delivery')}
              </Typography>
              <DeliveryRow
                label="clients.orderDetails.numberLines"
                value={order?.countLines}
              />
              <DeliveryRow
                label="clients.orderDetails.numberPackages"
                value={order?.countPackages}
              />
              <DeliveryRow
                label="clients.orderDetails.weightOnVolume"
                value={`${order?.totalWeight?.toFixed(2)}kg / 0m³`}
              />
              <DeliveryRow
                label="ca_kg"
                value={order?.cache?.stats?.amountPerWeight?.toFixed(2)}
              />
              <DeliveryRow
                label="clients.orderDetails.RoundtripOnPosition"
                value={rountripPosition}
              />
              <If condition={!!order?.deliveryComment}>
                <DeliveryRow
                  label="clients.orderDetails.deliveryComment"
                  value={order?.deliveryComment}
                />
              </If>
            </Stack>

            <Stack gap="8px">
              <Typography fontWeight="600" fontSize="0.875rem">
                {t('clients.orderDetails.supportUnit')}
              </Typography>

              <If
                condition={showSupportUnits}
                otherwise={
                  <If condition={!!order?.supportUnitsList}>
                    <DeliveryRow
                      label="storage.capacity"
                      value={
                        <Stack
                          direction="row-reverse"
                          alignItems="center"
                          gap={0.5}
                        >
                          {order?.supportUnitsRequestedCapacity}
                          <SupportUnitsPopover
                            order={order}
                            updateOrderFunction={updateOrderFunction}
                            canEdit={canEdit}
                            widthButton={31}
                            supportUnits={{
                              ...order?.supportUnitsList,
                              loaded: order?.supportUnitListLocations,
                            }}
                            refreshData={() => {
                              refetch(order?._id);
                            }}
                          />
                        </Stack>
                      }
                    />
                  </If>
                }
              >
                <SupportUnitsTable
                  supportUnits={{
                    ...order?.supportUnitsList,
                    delivered:
                      order?.status === OrderStatus.Delivered
                        ? order?.supportUnitsList?.delivered
                        : [],
                    loaded: order?.supportUnitListLocations,
                  }}
                />
              </If>

              <DeliveryRow
                label="order.itemsList.subtotal"
                value={formatCurrency((order?.totalAmount || 0)?.toFixed(2))}
              />
              <DeliveryRow
                label="order.itemsList.shipping"
                value={formatCurrency(order?.deliveryCost || 0)}
              />
              <DeliveryRow
                label={`${t('order.itemsList.tax')} 5%`}
                value={`${formatCurrency(
                  (order?.totalAmountTax || 0).toFixed(2) -
                    (order?.totalAmount || 0).toFixed(2),
                )}`}
              />
            </Stack>
            <Stack gap="12px">
              <Typography fontWeight="600" fontSize="0.875rem">
                {t('common.collection')}
              </Typography>
              <DeliveryRow
                label="payment_to_collect"
                value={
                  order?.status !== OrderStatus.Delivered && !!canEdit ? (
                    <TextField
                      inputRef={inputRef}
                      variant="outlined"
                      size="small"
                      value={toCollectValue}
                      sx={{
                        width: '64px',
                        maxWidth: '64px',
                        margin: '0 !important',
                        '& input': {
                          padding: '0 !important',
                          height: '23px',
                          fontSize: '0.625rem',
                          letterSpacing: '0.15',
                          color: themes.default.black2,
                        },
                        '& fieldset': {
                          border: `1px solid ${themes?.default?.grey400}`,
                          transitionDuration: '0.15s',
                        },
                        '& fieldset:hover': {
                          border: `1px solid ${themes?.default?.iconColor} !important`,
                        },
                      }}
                      onKeyDown={(e) => {
                        const isNumberKey = e.key.match(/[0-9.,€$]/);
                        const isNavigationKey = [
                          'Backspace',
                          'Delete',
                          'ArrowLeft',
                          'ArrowRight',
                          'Tab',
                        ].includes(e.key);
                        if (!isNumberKey && !isNavigationKey) {
                          if (!e?.ctrlKey) {
                            e?.preventDefault();
                          }
                        }
                      }}
                      onChange={(e) => {
                        const value = e?.target?.value;
                        setToCollectValue(value);
                      }}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => {
                        setIsFocused(false);
                        handleSaveNewAmout();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              marginRight: '2px',
                              marginLeft: '4px',
                              marginTop: '1px',
                              fontSize: '0.625rem',
                              display: 'flex',
                              alignItems: 'center',
                              height: '100%',
                              '& p': {
                                fontSize: '0.625rem',
                                color: themes.default.black2,
                              },
                            }}
                          >
                            €
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Typography fontSize="0.75rem">
                      {formatCurrency(toCollectValue || 0)}
                    </Typography>
                  )
                }
              />
              <DeliveryRow
                label="amount_collected"
                value={formatCurrency(order?.paymentCollection || 0)}
              />
            </Stack>
          </Stack>
        </Box>

        <Box position="sticky" bottom="0px">
          <StyledAccordion onChange={(_, expanded) => setIsExpanded(expanded)}>
            <StyledAccordionSummary
              expandIcon={null}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                component="span"
                sx={{ fontWeight: '400', fontSize: '13px' }}
              >
                {isExpanded ? 'View Less Details' : 'View More Details'}
              </Typography>
              {isExpanded ? (
                <CaretDoubleDown size={16} />
              ) : (
                <CaretDoubleUp size={16} />
              )}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Stack>
                <StyledStack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ borderTop: `1px solid ${themes.default.silver}` }}
                >
                  <Typography
                    fontWeight="600"
                    sx={{ color: themes.default.clientMarkerBg }}
                  >
                    {t('total_ttc')}
                  </Typography>
                  <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
                    {formatCurrency(
                      (order?.totalAmountTax || 0).toFixed(2) || 0,
                    )}
                  </Typography>
                </StyledStack>
                <StyledStack direction="row" justifyContent="space-between">
                  <Typography
                    fontWeight="400"
                    sx={{ color: themes.default.clientMarkerBg }}
                  >
                    {t('order.itemsList.subtotal')}
                  </Typography>
                  <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
                    {formatCurrency((order?.totalAmount || 0)?.toFixed(2))}
                  </Typography>
                </StyledStack>
                <StyledStack direction="row" justifyContent="space-between">
                  <Typography
                    fontWeight="400"
                    sx={{ color: themes.default.clientMarkerBg }}
                  >
                    {t('order.itemsList.shipping')}
                  </Typography>
                  <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
                    {formatCurrency(order?.deliveryCost || 0)}
                  </Typography>
                </StyledStack>
                <StyledStack direction="row" justifyContent="space-between">
                  <Typography
                    fontWeight="400"
                    sx={{ color: themes.default.clientMarkerBg }}
                  >
                    {`${t('order.itemsList.tax')} `}
                    <strong style={{ color: 'black', marginLeft: '6px' }}>
                      5%
                    </strong>
                  </Typography>
                  <Typography
                    sx={{ color: 'black', fontWeight: 'bold' }}
                  >{`${formatCurrency(
                    (order?.salesTax || 0).toFixed(2),
                  )}`}</Typography>
                </StyledStack>
              </Stack>
            </StyledAccordionDetails>
          </StyledAccordion>
        </Box>
      </Stack>
    </Stack>
  );
};

const StyledStack = styled(Stack)`
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  border-bottom: 1px solid ${themes.default.silver};
  border-radius: 0 !important;

  &.MuiStack-root {
    border-radius: 0 !important;
  }
`;
const StyledAccordion = styled(Accordion)`
  &::before {
    display: none;
  }
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none;
  margin: 0 !important;

  & .MuiAccordionSummary-root {
    height: 40px !important;
    min-height: 40px !important;
    max-height: 40px !important;
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & .MuiPaper-root {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & .MuiAccordionDetails-root {
    padding: 16px;
    margin: 0;
  }

  & .MuiCollapse-root {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &.MuiAccordion-root {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${themes.default.silver};
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: white;

  & .MuiAccordionSummary-content {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  width: 100%;
  border-radius: 0 !important;
  padding: 0 !important;

  & .MuiStack-root {
    width: 100%;
  }
`;
