import { IColDef } from 'app/components/ManagedGrid/types';
import AmountCell from '../Invoices/cells/AmountCell';
import BranchCell from '../Invoices/cells/BranchCell';
import DateCell from '../Invoices/cells/DateCell';
import NameCell from '../Invoices/cells/NameCell';
import StatusCell from './cells/StatusCell';
import TypeCell from './cells/TypeCell';
import ArchivedCell from '../Invoices/cells/ArchivedCell';
import ActionsMenuCell from './cells/ActionsMenuCell';
import ClientCell from '../Invoices/cells/ClientCell';
import NoteCell from './cells/NoteCell';

export enum TransactionsTab {
  BILLS = 'bills',
  REFUND = 'refund',
  PAYMENTS = 'payments',
}

export enum RefundType {
  RETURN = 'return',
  CREDIT = 'credit',
}

export const getCustomRefundsColumnConfig = (
  t: (key: string) => string,
  isUserCustomer: boolean,
): IColDef[] =>
  isUserCustomer
    ? [
        {
          cellRenderer: ArchivedCell,
          width: 52,
          lockPinned: true,
          pinned: 'left',
          hide: true,
        },
        {
          cellRenderer: TypeCell,
          width: 20,
          resizable: false,
          pinned: 'left',
          lockPinned: true,
        },
        {
          cellRenderer: NameCell,
          minWidth: 382,
          resizable: true,
          headerName: t('warehouse.heads.name'),
          flex: 1,
        },
        {
          cellRenderer: BranchCell,
          minWidth: 140,
          resizable: true,
          headerName: t('orders.branch'),
          flex: 1,
        },
        {
          cellRenderer: DateCell,
          minWidth: 80,
          resizable: true,
          headerName: t('common.date'),
          flex: 1,
        },
        {
          cellRenderer: AmountCell,
          width: 80,
          resizable: true,
          headerName: t('roundtrips.roundtripDetails.amount'),
          flex: 1,
        },
        {
          cellRenderer: StatusCell,
          width: 160,
          resizable: true,
          headerName: t('payments.status'),
          flex: 1,
          pinned: 'right',
          lockPinned: false,
        },
        {
          cellRenderer: ActionsMenuCell,
          width: 70,
          resizable: false,
          lockPinned: true,
          pinned: 'right',
        },
      ]
    : [
        {
          cellRenderer: ArchivedCell,
          width: 52,
          lockPinned: true,
          pinned: 'left',
          hide: true,
        },
        {
          cellRenderer: TypeCell,
          width: 20,
          resizable: false,
          pinned: 'left',
          lockPinned: true,
        },
        {
          cellRenderer: NameCell,
          minWidth: 382,
          resizable: true,
          headerName: t('warehouse.heads.name'),
          flex: 1,
        },
        {
          cellRenderer: ClientCell,
          minWidth: 140,
          resizable: true,
          headerName: 'Client',
          flex: 1,
        },
        {
          cellRenderer: BranchCell,
          minWidth: 140,
          resizable: true,
          headerName: t('orders.branch'),
          flex: 1,
        },
        {
          cellRenderer: DateCell,
          minWidth: 80,
          resizable: true,
          headerName: t('common.date'),
          flex: 1,
        },
        {
          cellRenderer: AmountCell,
          width: 80,
          resizable: true,
          headerName: t('roundtrips.roundtripDetails.amount'),
          flex: 1,
        },
        {
          cellRenderer: StatusCell,
          width: 160,
          resizable: true,
          headerName: t('payments.status'),
          flex: 1,
          pinned: 'right',
          lockPinned: false,
        },
        {
          cellRenderer: NoteCell,
          minWidth: 70,
          resizable: true,
          pinned: 'right',
          lockPinned: true,
        },
        {
          cellRenderer: ActionsMenuCell,
          width: 70,
          resizable: false,
          lockPinned: true,
          pinned: 'right',
        },
      ];
