import { Flag } from '../../../Products/Components/Shared/Flag';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const MiddleProductRowComponent = ({ prod }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Flag orderFreq={prod.orderFrequency} id={prod._id} />
      <Title>{prod.boxingUnit}</Title>
      <Label>{t('product.item_per_box')}</Label>
      <Title>{prod.weight}</Title>
      <Label>kg</Label>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: unset !important;
`;

const Title = styled.span`
  color: ${(props) => props.theme.textColorDashboard};
  padding-left: 5px;
  font-size: 12px;
  font-weight: 700;
`;

const Label = styled.span`
  color: ${(props) => props.theme.textColorDashboard};
  padding-left: 3px;
  font-weight: 400;
  font-size: 12px;
`;
