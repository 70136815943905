import { useTranslation } from 'react-i18next';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import If from 'app/components/If';
import { ICategoryOption } from 'common/types/Categories';
import { useCallback } from 'react';
import { useFetchCategoriesLevels } from 'hooks/useFetchCategoriesLevels';

export const ProductCategoriesFilter = () => {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext>('grid');
  const { categories, subCategories, childCategories } =
    useFetchCategoriesLevels();

  const handleSelectFilterChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters[filterKey] = options;
    });
  };

  const filterValidCategories = (items?: ICategoryOption[]) =>
    items?.filter((item) => item._id && item?.name) || [];

  const mapCategory = useCallback(
    (category: ICategoryOption) => ({
      value: category?._id,
      label: category?.name,
      count: category?.stats?.productsCount || 0,
      _parentCategory:
        category?.level === 2
          ? category?._parentCategory?._parentCategory?.name +
            ' - ' +
            category?._parentCategory?.name
          : category?._parentCategory?.name || '',
    }),
    [],
  );

  return (
    <>
      <If condition={!!categories?.length}>
        <MultiSelect
          filterName={t('category')}
          selectedOptions={
            gridContext.queryParams?.filters?.parentCategories || []
          }
          handleFilterChange={(options) => {
            handleSelectFilterChange('parentCategories', options);
          }}
          filterItems={filterValidCategories(categories)?.map((category) =>
            mapCategory(category),
          )}
        />
      </If>

      <If condition={!!subCategories?.length}>
        <MultiSelect
          autoCompleteProps={{
            groupBy: (option) => {
              return option?._parentCategory;
            },
          }}
          filterName={t('common.sub_category')}
          selectedOptions={
            gridContext.queryParams?.filters?.subCategories || []
          }
          handleFilterChange={(options) => {
            handleSelectFilterChange('subCategories', options);
          }}
          filterItems={filterValidCategories(subCategories)?.map((category) =>
            mapCategory(category),
          )}
        />
      </If>

      <If condition={!!childCategories?.length}>
        <MultiSelect
          autoCompleteProps={{
            groupBy: (option) => {
              return option?._parentCategory;
            },
          }}
          filterName={t('child_category')}
          selectedOptions={
            gridContext.queryParams?.filters?.childCategories || []
          }
          handleFilterChange={(options) => {
            handleSelectFilterChange('childCategories', options);
          }}
          filterItems={filterValidCategories(childCategories)?.map((category) =>
            mapCategory(category),
          )}
        />
      </If>
    </>
  );
};
