import { AgGridReact } from '@ag-grid-community/react';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';

interface ListPaymentContextType {
  selectedPaymentsIds: any;
  setSelectedPaymentsIds: (_selectedPaymentsId: any) => void;
  selectedPeriod: any;
  setSelectedPeriod: (_selectedPeriod: any) => void;
  rowDataList: any;
  setRowDataList: (_rowDataList: any) => void;
  refs: AgGridReact[];
  setRefs: (_refs: AgGridReact[]) => void;
  isUserCustomer: boolean;
}

export const ListPaymentContext = createContext<ListPaymentContextType>({
  selectedPaymentsIds: [],
  setSelectedPaymentsIds: () => {},
  selectedPeriod: [],
  setSelectedPeriod: () => {},
  rowDataList: [],
  setRowDataList: () => {},
  refs: [],
  setRefs: () => {},
  isUserCustomer: false,
});

export const ListPaymentProvider = ({ children }) => {
  const [selectedPaymentsIds, setSelectedPaymentsIds] = useState<string[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');
  const [rowDataList, setRowDataList] = useState<any>([]);
  const [refs, setRefs] = useState<AgGridReact[]>([]);

  const authUser = useSelector(selectAuthUser);
  const isUserCustomer = authUser?.currentAgent?._role?.key === 'customer';

  return (
    <ListPaymentContext.Provider
      value={{
        selectedPaymentsIds,
        setSelectedPaymentsIds,
        selectedPeriod,
        setSelectedPeriod,
        rowDataList,
        setRowDataList,
        refs,
        setRefs,
        isUserCustomer,
      }}
    >
      {children}
    </ListPaymentContext.Provider>
  );
};
