import { ItemStatusComponent } from 'app/pages/AddRoundTrips/data/RoundTripData/components/components/ItemStatusComponent';
import {
  CLAIMS_STATS_STATUSES,
  CLAIMS_STATUS_CHIPS_COLORS,
} from '../../config';

export function populateClaimStatusActions({
  changeClaimStatus,
  currentStatus,
  t,
}: {
  changeClaimStatus: (status: string) => void;
  currentStatus: string;
  t;
}) {
  const statuses = Object.values(CLAIMS_STATS_STATUSES);

  const currentAvailableStatuses = statuses.filter(
    (status) => status?.toLowerCase() !== currentStatus?.toLowerCase(),
  );

  return currentAvailableStatuses.map((status) => {
    const sanitizedStatus = status?.replace(/-/g, '').toUpperCase();
    const statusInfo = CLAIMS_STATUS_CHIPS_COLORS[sanitizedStatus];

    return (
      statusInfo && {
        action: statusInfo.key,
        element: (
          <ItemStatusComponent
            action={async () => changeClaimStatus(statusInfo.key)}
            label={t(statusInfo.label)}
            color={statusInfo.dotColor}
          />
        ),
      }
    );
  });
}
