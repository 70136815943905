import React from 'react';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import EditDocument from './components/EditDocument';
import AddDocument from './components/AddDocument';

const DocumentDrawer = () => {
  const gridContext = useManagedContext('grid');

  return gridContext?.selectedRow ? <EditDocument /> : <AddDocument />;
};

export default React.memo(DocumentDrawer);
