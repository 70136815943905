import { Typography } from '@mui/material';
import React from 'react';
const FooterButton = React.memo(
  ({
    label,
    color,
    onClick,
    disabled = false,
  }: {
    label: string;
    color: string;
    onClick: () => void;
    disabled?: boolean;
  }) => (
    <Typography
      sx={{
        color,
        padding: '10px 15px',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={!disabled ? onClick : undefined}
      fontWeight={500}
    >
      {label}
    </Typography>
  ),
);

export default FooterButton;
