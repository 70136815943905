import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { TwoViewModal } from 'app/components/TwoViewModal';
import styled from 'styled-components';
import { useGetFacetsDocumentQuery } from 'common/services/userApi';
import { useUploadMutation } from 'common/services/files';
import { useToaster } from 'hooks/useToaster';
import DocumentTypeSelector from './components/DocumentTypeSelector';
import DocumentAttachments from './components/DocumentAttachments';
import FooterButtons from './components/FooterButtons';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { UsersAutoComplete } from 'app/components/UsersAutoComplete';
import If from 'app/components/If';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useCreateDocumentMutation } from 'common/services/userDocumentsApi';
import FormTextField from 'app/components/Form/TextField';
import { DocumentsKeys } from 'common/utils/enum';

const AddDocument = () => {
  const gridContext = useManagedContext<IGridContext>('grid');
  const [selectedType, setSelectedType] = useState('');
  const [otherType, setOtherType] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<any[]>([]);
  const [postDocuments, { isLoading: isAdding }] = useCreateDocumentMutation();
  const [uploadFile, { isLoading: isUploading }] = useUploadMutation();
  const params = useParams();
  const { setValue, getValues, control, watch } = useForm();
  const id = params.id || '';
  const authUser = useSelector(selectAuthUser);

  const checkModuleScope = useCallback(
    (application, module, scope) => {
      return authUser?.currentAgent?.privileges?.scopes.includes(
        `${application}.${module}.${scope}`,
      );
    },
    [authUser?.currentAgent?.privileges?.scopes],
  );

  const toast = useToaster();
  const userId =
    checkModuleScope('iam', 'documents', 'canedit') &&
    (checkModuleScope('iam', 'documents', 'manageteam') ||
      checkModuleScope('iam', 'documents', 'manageall')) &&
    gridContext.resubmitType === ''
      ? id || watch('user') || ''
      : authUser?._id;
  const { data: facets, refetch: refetchFacets } =
    useGetFacetsDocumentQuery<any>(userId, { skip: !userId });

  const showUserAutoComplete =
    checkModuleScope('iam', 'documents', 'manageteam') ||
    checkModuleScope('iam', 'documents', 'manageall');

  useEffect(() => {
    if (userId) {
      refetchFacets();
    }
  }, [userId, refetchFacets]);

  const onSubmit = async (_values) => {
    await upload(uploadedDocuments, selectedType);
    toast(3000, 'success', 'messages.document.created', {
      type: t(selectedType),
    });
  };

  const upload = async (documents: File[] | null, name: string) => {
    const urls: { url: string; size: string; name: string; isNew: boolean }[] =
      [];

    if (documents?.length) {
      try {
        const uploadPromises = documents.map(async (document) => {
          const formData = new FormData();
          formData.append('file', document);
          const { size, url } = await uploadFile({
            formData,
            preserveName: false,
            persist: false,
            objectType: 'user',
            objectId: id,
          }).unwrap();
          if (url) {
            urls.push({
              url,
              size: size || 0,
              name: document.name,
              isNew: true,
            });
          }
        });

        await Promise.all(uploadPromises);

        if (urls.length) {
          const payload = {
            id: `${id || userId}?iam-checker=true`,
            newDocument: {
              type: name,
              attachment: [...urls],
              specifyOtherType: otherType,
            },
          };
          const res: any = await postDocuments(payload);
          setUploadedDocuments([]);
          if (!res?.error) {
            handleCloseDrawer();
            setUploadedDocuments([]);
          }
        }
      } catch (e) {
        console.error('Error uploading documents:', e);
      }
    }
  };

  const handleCloseDrawer = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.openDrawer = false;
      prev.selectedRow = null;
      prev.handleReject = false;
      prev.resubmitType = '';
    });
  };

  const otherTypeValue = watch('type');

  useEffect(() => {
    if (otherTypeValue !== undefined) {
      setOtherType(otherTypeValue);
    }
  }, [otherTypeValue]);

  useEffect(() => {
    if (selectedType === DocumentsKeys.OTHER && !otherType) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [otherType, selectedType]);

  return (
    <TwoViewModal
      onClose={handleCloseDrawer}
      title="Add Document"
      width="473px"
      onOpen={gridContext.openDrawer}
    >
      <Wrapper>
        <form onSubmit={onSubmit}>
          <Typography fontSize={14} fontWeight={600} marginBottom={2}>
            Details
          </Typography>
          <If
            condition={
              showUserAutoComplete &&
              !params.id &&
              gridContext.resubmitType === ''
            }
          >
            <UsersAutoComplete
              control={control}
              name="Employee"
              fieldName="Employee"
              disabled={false}
              hasProfile={true}
              onChange={(_e, value) => {
                setValue('user', value?.value);
              }}
              useWebQuery={true}
            />
          </If>
          <DocumentTypeSelector
            setSelectedType={setSelectedType}
            setValue={setValue}
            resubmitType={gridContext.resubmitType}
            getValues={getValues}
            facets={facets}
            disabled={!userId}
            userId={userId}
          />
          <If condition={selectedType === DocumentsKeys.OTHER}>
            <FormTextField
              name="type"
              label={t('specify_type')}
              control={control}
              rules={{ required: true }}
              required
            />
          </If>
          <DocumentAttachments setUploadedDocuments={setUploadedDocuments} />
        </form>
      </Wrapper>
      <FooterWrapper>
        <FooterButtons
          handleCloseDrawer={handleCloseDrawer}
          handleSubmit={onSubmit}
          selectedType={selectedType}
          uploadedDocuments={uploadedDocuments}
          isLoading={isAdding || isUploading}
          isUploadingForCurrentUser={userId === authUser?._id}
          isDisabled={isDisabled}
        />
      </FooterWrapper>
    </TwoViewModal>
  );
};

const Wrapper = styled.div`
  margin: 25px 20px 0;
  display: flex;
  flex-direction: column;
`;

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  background: #fff;
`;

export default React.memo(AddDocument);
