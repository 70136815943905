import { CustomCellRendererProps } from '@ag-grid-community/react';
import If from 'app/components/If';
import { isEmpty } from 'lodash';

function ClientCell(params: CustomCellRendererProps) {
  return (
    <If condition={!isEmpty(params.value.clientCodeData)} otherwise="-">
      <span>
        {params.value.clientCodeData} - {params.value.clientNameData}
      </span>
    </If>
  );
}

export default ClientCell;
