import { Icon } from 'app/components/Icon';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

export const CategoryIcon = ({
  icon,
  selected = false,
}: {
  icon: JSX.Element;
  selected?: boolean;
}) => {
  const theme = useSelector(selectTheme);
  return (
    <Icon
      icon={icon}
      size={17}
      color={selected ? theme.primary : theme.iconColor}
    />
  );
};
