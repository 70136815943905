import { CustomCellRendererProps } from '@ag-grid-community/react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { AssigneePopover } from '../components/AssigneePopover';
import { useQueryParams } from 'hooks/useQueryParams';
import {
  useAddAssigneeMutation,
  useRemoveAssigneeMutation,
} from 'common/services/conversationApi';
import { AssigneesAvatar } from '../components/AssigneesAvatar';
import { useGetBasicAgentsListQuery } from 'common/services/agents';
import { useDebounce } from 'common/hooks/useDebounce';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';

function AssigneeCell(params: CustomCellRendererProps) {
  const gridContext = useManagedContext<IGridContext>('grid');

  const [openPopup, setOpenPopup] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [limit, setLimit] = useState(20);
  const [selectedEmployees, setSelectedEmployees] = useState(() => {
    return (params.value?.assigneeData || []).map((employee) => employee._id);
  });

  const [agentsSearch, setAgentsSearch] = useState<string>('');
  const debouncedAgentsSearch = useDebounce(agentsSearch, 200);

  const fetchQueryParams = useQueryParams({
    limit: limit,
    text: debouncedAgentsSearch,
    excludeRoleId: '65004500f91988461dbfc398',
    permissionEditSection: 'claims',
  });

  const [addAssignee] = useAddAssigneeMutation();
  const [removeAssignee] = useRemoveAssigneeMutation();

  const { data: agents } = useGetBasicAgentsListQuery(fetchQueryParams);

  useEffect(() => {
    if (params?.data?.id && openPopup) {
      addAssignee({
        conversationId: params.data.id,
        body: {
          assigneeAgent: selectedEmployees,
        },
      });
    }
  }, [selectedEmployees]);

  function handleClearClaim(currentAgentId) {
    if (gridContext.canEdit) {
      setSelectedEmployees((prev) =>
        prev.filter((id) => id !== currentAgentId),
      );

      removeAssignee({
        conversationId: params.data.id,
        body: {
          assignedAgentId: currentAgentId,
        },
      });
    }
  }

  return (
    <>
      <Wrapper
        ref={wrapperRef}
        openPopup={openPopup}
        onClick={() => {
          if (gridContext.canEdit) {
            setOpenPopup(true);
          }
        }}
      >
        <AssigneesAvatar
          assignees={params.value.assigneeData}
          handleClearClaim={handleClearClaim}
        />
      </Wrapper>
      <AssigneePopover
        popoverId={params.value.id}
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        wrapperRef={wrapperRef.current}
        data={agents?.docs}
        setLimit={setLimit}
        setSelectedEmployees={setSelectedEmployees}
        search={agentsSearch}
        setSearch={setAgentsSearch}
        selectedEmployees={selectedEmployees}
      />
    </>
  );
}

const Wrapper = styled.div<{ openPopup: boolean }>`
  width: 100%;
  height: 100%;
  align-content: center;
  cursor: pointer;
  background-color: ${({ openPopup }) =>
    openPopup ? themes?.default?.lightGrey : 'transparent'};
`;

export default AssigneeCell;
