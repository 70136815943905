import { CustomCellRendererProps } from '@ag-grid-community/react';
import { useTranslation } from 'react-i18next';
import { ActionComponent } from 'app/components/ManagedGrid/components/ActionComponent';
import { useMemo } from 'react';
import { ActionProps } from 'app/components/CustomMenu/types';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { useHelpers } from '../useHelpers';

export const ActionsCell = (params: CustomCellRendererProps) => {
  const { t } = useTranslation();

  const can = usePermission('customers');
  const canEdit = can(Permission.EDIT);
  const { deleteDiscount, enableDisableDiscount, reset } = useHelpers();

  const actions = useMemo(() => {
    const actionsList: ActionProps[] = [];
    if (canEdit) {
      actionsList.push({
        element: t('common.reset_all'),
        onClick: () => {
          reset(params.value.cellData?._id, 'all');
        },
      });
      actionsList.push({
        element: params.value?.isActive
          ? t('common.disable')
          : t('common.enable'),
        onClick: () =>
          enableDisableDiscount(
            [params.value.cellData?._id],
            params.value?.isActive,
          ),
      });
      actionsList.push({
        element: t('common.remove'),
        onClick: () => {
          deleteDiscount(params.value.cellData?._id);
        },
        withSeparator: true,
        danger: true,
      });
    }
    return actionsList;
  }, [params]);

  if (!canEdit) return null;

  return <ActionComponent actions={actions} />;
};
