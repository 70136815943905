import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  DateRangePicker,
  DateRangePickerProps,
} from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import i18next from 'i18next';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { themes } from 'styles/theme/themes';
import { CustomDatePicker } from './styles';
import { useHighlightedDay } from './hooks/useHighlightDay';
import dayjs from 'dayjs';

interface CustomDateRangePickerProps {
  dateValue: { begin: string | null; end: string | null };
  setDateValue: (date: {
    begin: string | null;
    end: string | null;
  }) => void | React.Dispatch<
    React.SetStateAction<{ begin: string | null; end: string | null }>
  >;
  textFieldProps?: TextFieldProps;
  width?: string;
  showDots?: boolean;
  dateRangePickerProps?: Partial<
    DateRangePickerProps<dayjs.Dayjs, dayjs.Dayjs>
  >;
}

export const CustomDateRangePicker = ({
  dateValue,
  setDateValue,
  textFieldProps,
  width,
  showDots = false,
  dateRangePickerProps,
}: CustomDateRangePickerProps) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const { HightLightedDay, onMonthChange } = useHighlightedDay({
    dateValue,
    showDots,
    isRange: true,
  });

  const formatRange = (range) => {
    if (range?.begin && range?.end) {
      const dateBegin = dayjs(range?.begin)
        .locale(i18next.language || 'en')
        .format('D MMM YYYY');
      const dateEnd = dayjs(range?.end)
        .locale(i18next.language || 'en')
        .format('D MMM YYYY');

      if (dateBegin === dateEnd) {
        return dateBegin;
      }
      return `${dateBegin} - ${dateEnd}`;
    }
    return '';
  };

  const onChange = (newValue) => {
    if (!newValue[0]) {
      setDateValue({ begin: newValue[1], end: newValue[1] });
      return;
    } else if (!newValue[1]) {
      setDateValue({ begin: newValue[0], end: newValue[0] });
      return;
    }
    setDateValue({ begin: newValue[0], end: newValue[1] });
  };

  return (
    <CustomDatePicker width={width}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18next.language}
      >
        <DateRangePicker
          value={[dayjs(dateValue.begin), dayjs(dateValue.end)]}
          inputFormat="D MMM YYYY"
          {...dateRangePickerProps}
          onChange={(newValue: any) => {
            onChange(newValue);
          }}
          open={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          onOpen={() => setDatePickerOpen(true)}
          renderInput={() => (
            <TextField
              {...textFieldProps}
              onClick={() => {
                setDatePickerOpen(true);
              }}
              autoComplete="off"
              data-form-type="other"
              size="small"
              value={formatRange(dateValue)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setDateValue({ begin: null, end: null });
                    }}
                    size="small"
                    sx={{
                      visibility: !!dateValue?.begin ? 'visible' : 'hidden',
                    }}
                  >
                    <CloseIcon
                      sx={{
                        height: themes.default?.iconSize,
                        width: themes.default?.iconSize,
                      }}
                    />
                  </IconButton>
                ),
                ...textFieldProps?.InputProps,
              }}
              InputLabelProps={{
                shrink: false,
                ...textFieldProps?.InputLabelProps,
              }}
              sx={{
                marginBottom: '0 !important',
                ...textFieldProps?.sx,
              }}
            />
          )}
          className="custom-date-picker"
          renderDay={(date, dateRangePickerDayProps) =>
            HightLightedDay({
              date,
              dateRangePickerDayProps,
            })
          }
          onMonthChange={onMonthChange}
        />
      </LocalizationProvider>
    </CustomDatePicker>
  );
};
