import * as React from 'react';
import { Chip, Tooltip } from '@mui/material';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { clientStatusColor } from 'common/utils/clientStatusColor';
import { dateFormat } from 'common/utils/dates';
interface Props {
  date: Date | string | undefined;
  displayTooltip?: boolean;
}

export const StatusLastOrder: React.FC<Props> = ({
  date,
  displayTooltip = false,
}) => {
  const theme = useSelector(selectTheme);

  if (!date) {
    return null;
  }

  const lastOrderDate = window.dayjs(date);
  const diff = window.dayjs().diff(lastOrderDate, 'days');

  let status = 'stopped';

  if (diff <= 31) {
    status = 'active';
  } else if (diff > 31 && diff <= 92) {
    status = 'pending';
  }

  const statusChip = (
    <Chip
      label={`${lastOrderDate.fromNow(true)}`}
      className="status"
      sx={{
        color:
          clientStatusColor[status || 'other']?.color ||
          clientStatusColor['other']?.color,
        backgroundColor:
          clientStatusColor[status || 'other']?.background ||
          clientStatusColor['other']?.background,
      }}
    />
  );

  if (displayTooltip) {
    return (
      <StatusWrapper theme={theme}>
        <Tooltip arrow placement="top" title={dateFormat(lastOrderDate, false)}>
          {statusChip}
        </Tooltip>
      </StatusWrapper>
    );
  }

  return <StatusWrapper theme={theme}>{statusChip}</StatusWrapper>;
};

const StatusStyle = css`
  align-items: center;
  // max-width: fit-content;
  height: 22px;
`;
const StatusWrapper = styled.div<{ theme: any }>`
  align-self: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 1.0125rem */
  letter-spacing: 0.025rem;
  .status {
    ${StatusStyle}
  }
`;
