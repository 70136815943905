import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { ValueInput } from 'app/components/Form/ValueInput';

interface IButtonsOptions {
  label: string;
  key: string;
}

interface Props {
  buttonsOptions?: IButtonsOptions[];
  disabled?: boolean;
  defaultSelected: string;
  onChange: (key: string) => void;
  name?: string;
  onQuantityChange: (value: number) => void;
  defaultValue?: number;
  maxValue?: number;
}

export const InputWithSelectedBtns = ({
  buttonsOptions,
  disabled,
  defaultSelected,
  onChange,
  name,
  onQuantityChange,
  defaultValue,
  maxValue,
}: Props) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  return (
    <InputWrapper>
      <ValueInput
        onChange={onQuantityChange}
        loading={false}
        btnIconWidth="28px"
        height="37px"
        width="45px"
        qunatityIconSize={16}
        hasQuantity={false}
        name={name}
        disabled={disabled}
        initialValue={defaultValue || 0}
        maxValue={maxValue}
      />
      {buttonsOptions && (
        <ButtonsWrapper>
          {buttonsOptions?.map((buttonOption, index) => {
            const isActive =
              defaultSelected === buttonOption.key ||
              defaultSelected === buttonOption.key;
            return (
              <Chip
                disabled={disabled}
                label={t(buttonOption.label)}
                sx={{
                  fontWeight: isActive ? '500' : '400',
                  fontSize: '11px',
                  background: `${
                    isActive ? theme.primaryLight1 : theme.baseWhite
                  }`,
                  color: `${
                    isActive ? theme.primaryActiveColor : theme.clientMarkerBg
                  }`,
                  border: `.5px solid ${theme.silver}`,
                  borderLeft: `${
                    index !== 0 ? `none` : `1px solid ${theme.silver}`
                  }`,

                  borderRadius: `${
                    buttonsOptions.length === 1
                      ? '4px'
                      : index === 0 || index === buttonsOptions.length
                        ? '4px 0px 0px 4px !important'
                        : '0px 4px 4px 0px !important'
                  } `,
                  minWidth: '34px',
                  maxWidth: 'fit-content',
                  maxHeight: '38px',
                  minHeight: '38px',
                  '&:hover': {
                    backgroundColor: isActive ? theme.primaryLight1 : 'white',
                  },
                }}
                size="small"
                onClick={() => {
                  onChange(buttonOption.key);
                }}
              />
            );
          })}
        </ButtonsWrapper>
      )}
    </InputWrapper>
  );
};

export const InputWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 0px !important;
`;
