import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

type NavLockContextType = {
  navLocked: boolean;
  lockNav: () => void;
  unlockNav: () => void;
  toggleNavLock: () => void;
  setNavLock: (isLocked: boolean) => void;
};

const NavLockContext = createContext<NavLockContextType | undefined>(undefined);

export const NavLockProvider = ({ children }: { children: ReactNode }) => {
  const [navLocked, setNavLocked] = useState(false);

  const lockNav = useCallback(() => setNavLocked(true), []);
  const unlockNav = useCallback(() => setNavLocked(false), []);
  const toggleNavLock = useCallback(() => setNavLocked((prev) => !prev), []);
  const setNavLock = useCallback(
    (isLocked: boolean) => setNavLocked(isLocked),
    [],
  );

  return (
    <NavLockContext.Provider
      value={{ navLocked, lockNav, unlockNav, toggleNavLock, setNavLock }}
    >
      {children}
    </NavLockContext.Provider>
  );
};

export const useNavLock = () => {
  const context = useContext(NavLockContext);
  if (!context) {
    throw new Error('useNavLock must be used inside NavLockProvider');
  }
  return context;
};
