import { ButtonBase, SxProps } from '@mui/material';

interface Props {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  children: JSX.Element | string;
  onClick: () => void;
  disabled?: boolean;
  style?: SxProps;
}

export const TextIconButton = ({
  leftIcon,
  rightIcon,
  children,
  onClick,
  disabled,
  style,
}: Props) => {
  return (
    <ButtonBase
      sx={{
        ...(style || ButtonSx),
        opacity: disabled ? '0.5' : '1',
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {leftIcon && leftIcon}
      {children}
      {rightIcon && rightIcon}
    </ButtonBase>
  );
};

const ButtonSx: SxProps = {
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  borderRadius: '4px',
  padding: '4px 5px',
};
