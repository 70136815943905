import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

interface AnimationFileComponentProps {
  animationData: object;
  stopped?: boolean;
  reverseAfter?: number;
}

const AnimationFileComponent: React.FC<AnimationFileComponentProps> = ({
  animationData,
  stopped = false,
  reverseAfter,
}) => {
  const [direction, setDirection] = useState(1);

  const defaultOptions = {
    loop: 1,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    if (!reverseAfter) return;
    const checkAnimation = setInterval(() => {
      setDirection((prev) => (prev === 1 ? -1 : 1));
    }, reverseAfter);

    return () => clearInterval(checkAnimation);
  }, [reverseAfter]);

  return (
    <div>
      <Lottie
        options={defaultOptions}
        isStopped={stopped}
        direction={direction}
      />
    </div>
  );
};

export default AnimationFileComponent;
