import { CustomCellRendererProps } from '@ag-grid-community/react';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';
import { themes } from '../../../../../styles/theme/themes';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { PopoverEl } from 'app/components/Popover';
import { CLAIMS_STATS_STATUSES } from '../config';
import {
  useChangeStatusMutation,
  usePatchConversationMutation,
} from 'common/services/conversationApi';
import { useContext, useMemo } from 'react';
import { ClaimsContext } from '../ClaimsProvider';
import { ActionComponent } from 'app/components/ManagedGrid/components/ActionComponent';
import { Check } from '@phosphor-icons/react';
import { useModal } from 'app/components/Dialog/hooks';
import { useToaster } from 'hooks/useToaster';
import If from 'app/components/If';
import { Tooltip } from '@mui/material';
import { populateClaimStatusActions } from '../components/Shared/populateClaimsStatusAction';

const ActionsCell = (params: CustomCellRendererProps) => {
  const gridContext = useManagedContext<IGridContext>('grid');
  const { t } = useTranslation();

  const claimId = params?.value?.cellData?._id;
  const claimCode = params?.value?.cellData?.code;
  const status = params?.value?.cellData?.status || '';
  const clientEmail = params?.value?.cellData?._customer?.email;
  const createdByEmail = params?.value?.cellData?._createdByAgent?.email;

  const { setOpenDetail, setSelectedClaimId, setSelectedRow } =
    useContext(ClaimsContext);

  const isArchivedFilter = gridContext.queryParams.filters.archived;

  const [patchConversation] = usePatchConversationMutation();
  const [changeStatus] = useChangeStatusMutation();

  const { openModal, closeModal } = useModal();
  const toast = useToaster();

  function changeClaimStatus(status: string) {
    changeStatus({
      conversationId: claimId,
      body: {
        status: status,
      },
    });
  }

  function handleShowArchiveModal() {
    openModal({
      title: t('claims.archive.title'),
      content: t('claims.archive.body', {
        claimCode: claimCode,
      }),
      action: {
        actionText: t('messages.archive'),
        actionCallback: () => archiveClaim(),
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }

  function handleShowUnarchiveModal() {
    openModal({
      title: t('claims.unarchive.title'),
      content: t('claims.unarchive.body', {
        claimCode: claimCode,
      }),
      action: {
        actionText: t('messages.unarchive'),
        actionCallback: () => unArchiveClaim(),
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }

  function archiveClaim() {
    patchConversation({
      conversationId: claimId,
      body: {
        isArchived: true,
      },
    }).then(() => {
      closeModal();
      toast(5000, 'success', t('claim.archive.success'));
    });
  }

  function unArchiveClaim() {
    patchConversation({
      conversationId: claimId,
      body: {
        isArchived: false,
      },
    }).then(() => {
      closeModal();
    });
  }

  const statusActionsPopover = useMemo(() => {
    const statuses = populateClaimStatusActions({
      changeClaimStatus,
      currentStatus: status,
      t,
    });
    return statuses?.map((status) => ({
      element: status?.element,
      nested: false,
    }));
  }, [status]);

  const allowedActions: any = [];

  if (
    gridContext.canEdit &&
    params.value.cellData?.status === CLAIMS_STATS_STATUSES.PENDING &&
    clientEmail !== createdByEmail
  ) {
    allowedActions.push({
      action: 'edit',
      onClick: () => {
        setSelectedRow(params.value.cellData);
        gridContext.updateData('openDrawer', true);
      },
      element: (
        <PopoverEl redStatus="" style={{ width: '100%' }}>
          <div>{t('edit')}</div>
        </PopoverEl>
      ),
    });
  }

  if (gridContext.canEdit) {
    allowedActions.push({
      nested: true,
      actions: statusActionsPopover,
      element: t('common.change_status'),
    });
  }

  allowedActions.push({
    action: 'view',
    onClick: () => {
      setSelectedRow(null);
      setOpenDetail(true);
      setSelectedClaimId(claimId);
    },
    element: (
      <PopoverEl redStatus="" style={{ width: '100%' }}>
        <div>{t('common.button.view_details')}</div>
      </PopoverEl>
    ),
  });

  if (
    gridContext.canEdit &&
    !isArchivedFilter &&
    params.value.cellData?.status === CLAIMS_STATS_STATUSES.RESOLVED
  ) {
    allowedActions.push({
      action: 'archive',
      onClick: () => handleShowArchiveModal(),
      element: (
        <PopoverEl
          redStatus={themes.default.redStatus}
          style={{ width: '100%' }}
        >
          <div className="delete">{t('buttons.archive')}</div>
        </PopoverEl>
      ),
    });
  }

  if (gridContext.canEdit && isArchivedFilter) {
    allowedActions.push({
      action: 'unarchived',
      onClick: () => handleShowUnarchiveModal(),
      element: (
        <PopoverEl
          redStatus={themes.default.redStatus}
          style={{ width: '100%' }}
        >
          <div className="delete">{t('messages.unarchive')}</div>
        </PopoverEl>
      ),
    });
  }

  return (
    <If
      condition={
        params.value.cellData?.status !== CLAIMS_STATS_STATUSES.RESOLVED &&
        gridContext.canEdit
      }
      otherwise={
        <div
          style={{
            width: '22px',
          }}
        >
          <ActionComponent actions={allowedActions} quickActions={[]} />
        </div>
      }
    >
      <ActionComponent
        actions={allowedActions}
        quickActions={[
          {
            icon: (
              <Tooltip title={t('claims.resolve.action')}>
                <Check />
              </Tooltip>
            ),
            onClick: async () =>
              changeClaimStatus(CLAIMS_STATS_STATUSES.RESOLVED),
          },
        ]}
      />
    </If>
  );
};
export default ActionsCell;
