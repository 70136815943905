import {
  Alert,
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import {
  useGetConfigurationQuery,
  useGetWarehousesQuery,
} from 'common/services/organizationApi';
import { ROUNDTRIP_ADD } from 'utils/routes';
import {
  checkExistingRoundtrips,
  triggerClusterByMode,
} from 'app/components/RoundTrips/function';
import { AlertContainer, LoadingContainer } from '../Existing';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { useCheckWarehouses } from 'hooks/RoundTrips/useCheckWarehouses';
import {
  useAddRoundTripsMutation,
  useLazyGetRoundTripsClusterQuery,
} from 'common/services/roundtripApi';
import { useModel } from '../useModel';
import { useCountRoundtrip } from '../../../hooks/useCountRoundtrip';
import { TabsContext } from 'app/pages/RoundtripsTabs';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useSubscribeObject } from 'common/hooks/ws';
import { useSelector } from 'react-redux';
import { selectOrganization } from 'common/store/organization/selectors';
import { dateFormat } from 'common/utils/dates';

export const Manual = ({
  dateValue,
  setWarehouse,
  warehouse,
  begin,
  end,
  warehouseMainId,
  warehouseId,
  roundTripCopy,
  allRoundtrip,
  open,
  setOpen,
}) => {
  const [addRoundtrip] = useAddRoundTripsMutation();

  const [countRoundtrip, setCountRoundtrip] = useState(0);

  const { t } = useTranslation();
  const { data: warehouses } = useGetConfigurationQuery();
  const { data: warehouseDataFetched } = useGetWarehousesQuery('');
  const organization = useSelector(selectOrganization);

  const subscribedOrganization = organization?.id;

  const rawMessage = useSubscribeObject(
    'organization-decks',
    subscribedOrganization,
  );
  const wsDecksMessage = rawMessage ? JSON.parse(rawMessage) : null;

  const [warehouseData, setWarehouseData] = useState({
    warehouses: Array.isArray(warehouses) ? warehouses : [],
  });

  useEffect(() => {
    if (
      warehouseDataFetched &&
      Array.isArray(warehouseDataFetched.warehouses)
    ) {
      setWarehouseData(warehouseDataFetched);
    } else {
      console.error(
        'Invalid warehouseDataFetched structure:',
        warehouseDataFetched,
      );
    }
  }, [warehouseDataFetched]);
  useEffect(() => {
    if (warehouseDataFetched) {
      setWarehouseData(warehouseDataFetched);
    }
  }, [warehouseDataFetched]);

  useEffect(() => {
    if (
      wsDecksMessage?.objectType === 'organization-decks' &&
      wsDecksMessage?.data?.deckId
    ) {
      const { deckId, status } = wsDecksMessage.data;

      setWarehouseData((prev) => {
        const updatedWarehouses = prev.warehouses.map((item) =>
          item.id === deckId ? { ...item, status } : item,
        );
        return { ...prev, warehouses: updatedWarehouses };
      });
    }
  }, [rawMessage]);

  const [trigger, { data: manualRoundtrips, isFetching }] =
    useLazyGetRoundTripsClusterQuery();
  const {
    disabledProceed,
    setDisabledProceed,
    alreadyExists,
    setAlreadyExists,
    isLoading,
    defaultWarehouse,
    setIsLoading,
    setDefaultWarehouse,
    handleCloseActivate,
    setSelectedIndex,
  } = useModel(setOpen, warehouses);

  const wareHousesProps = {
    options: Array.isArray(warehouseData?.warehouses)
      ? warehouseData.warehouses.filter((item) => item.status !== 'inactive')
      : [],
    getOptionLabel: (option) => option.name,
  };
  const defaultWarehouseExists = wareHousesProps.options.some(
    (warehouse) => warehouse._id === defaultWarehouse?._id,
  );
  const updatedDefaultWarehouse = defaultWarehouseExists
    ? defaultWarehouse
    : null;
  const result = warehouseMainId
    ? wareHousesProps?.options?.find((item) => item._id === warehouseMainId)
    : updatedDefaultWarehouse;
  const [warehouseValue, setWarehouseValue] = useState(result || null);

  useEffect(() => {
    setWarehouseValue(result || null);
  }, [result]);

  const tabsContext = useContext(TabsContext);
  const userContext = tabsContext?.userContext;
  const typeProps = {
    options: [
      AgentCategories.Sales,
      AgentCategories.Logistic,
      AgentCategories.Carrier,
    ],
  };

  const [agentCategory, setAgentCategory] = useState<AgentCategories>(
    userContext || AgentCategories.Logistic,
  );

  useEffect(() => {
    if (userContext) setAgentCategory(userContext);
  }, [userContext]);

  const handleProceedClick = async () => {
    try {
      setDisabledProceed(true);
      const resp = await addRoundtrip({
        _deck: warehouse?._id,
        date: dateValue?.format('YYYY-MM-DD'),
        name: warehouse.name + ' - Manual',
        mode: 'manual',
        agentCategory: agentCategory || AgentCategories.Logistic,
        roundtrips: [],
      }).unwrap();

      if (resp) {
        const url = new URL(ROUNDTRIP_ADD, window.location.origin);
        url.searchParams.append('cluster', JSON.stringify(resp.cluster?._id));
        url.searchParams.append('warehouse', JSON.stringify(warehouse));
        url.searchParams.append('dateValue', JSON.stringify(dateValue));
        url.searchParams.append('type', 'api');
        url.searchParams.append('mode', 'manual');
        url.searchParams.append('resultNbr', countRoundtrip + '');
        url.searchParams.append(
          'userContext',
          agentCategory || AgentCategories.Logistic,
        );

        window.open(url.toString(), '_blank');
        handleCloseActivate();
      }
    } catch (e: any) {
      if (e.status !== 400) {
        handleCloseActivate();
      }
    } finally {
      setDisabledProceed(false);
    }
  };

  useCheckWarehouses({
    triggerByMode: trigger,
    begin,
    end,
    roundTripsByMode: manualRoundtrips,
    openActivate: open,
    warehouse,
    setDisabledProceed,
    setAlreadyExists,
    mode: 'manual',
    setIsLoading,
    warehouses,
    setDefaultWarehouse,
    setWarehouse,
    warehouseId,
    isFetching,
    userContext: agentCategory,
  });
  useCountRoundtrip({
    roundTripCopy,
    warehouse,
    allRoundtrip,
    setCountRoundtrip,
  });

  return (
    <TwoViewModal
      onOpen={!!open}
      onClose={handleCloseActivate}
      title={
        t('drawer.modal.Create_roundtrips_by_manual') +
        ' ' +
        `( ${dateFormat(dateValue, false)} )`
      }
      width="550px"
    >
      <DialogContent>
        <AlertContainer>
          {alreadyExists && (
            <Alert variant="standard" severity="error" sx={{ width: '100%' }}>
              {t('drawer.modal.roundtrips_already_exist')}
            </Alert>
          )}
          {!warehouses?.decks?.length && (
            <Alert variant="standard" severity="error" sx={{ width: '100%' }}>
              {t('drawer.modal.roundtrip_no_warehouses')}
            </Alert>
          )}
        </AlertContainer>
        <DialogContentText id="alert-dialog-description">
          <Autocomplete
            disabled={!warehouses?.decks?.length}
            defaultValue={warehouseValue}
            style={{ flex: 1, marginTop: '25px' }}
            {...wareHousesProps}
            autoComplete={false}
            onChange={async (_e, value) => {
              setAlreadyExists(false);
              setDisabledProceed(true);
              setCountRoundtrip(0);
              setWarehouse(value || '');

              if (value && value.name) {
                const selectedIndex = warehouses?.decks.findIndex(
                  (deck) => deck._id === value._id,
                );
                setSelectedIndex(selectedIndex);
                setDisabledProceed(true);
                setIsLoading(true);
                await triggerClusterByMode(
                  trigger,
                  'manual',
                  value?._id,
                  begin,
                  end,
                  agentCategory,
                );
                if (
                  manualRoundtrips &&
                  manualRoundtrips?.docs?.[0]?._deck?._id === value?._id &&
                  !warehouse?._id
                ) {
                  checkExistingRoundtrips({
                    value,
                    setDisabledProceed,
                    setAlreadyExists,
                    mode: 'manual',
                    data: manualRoundtrips,
                    setIsLoading,
                  });
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="Warehouse"
                size="small"
                label={t('select_warehouse')}
                variant="outlined"
                aria-label="autocomplete"
                required
              />
            )}
          />
          <Autocomplete
            style={{ flex: 1, marginTop: '25px' }}
            {...typeProps}
            disableClearable
            autoComplete={false}
            onChange={async (_e, value) => {
              setAlreadyExists(false);
              setDisabledProceed(true);
              setAgentCategory(value);

              if (value && warehouse?._id) {
                setDisabledProceed(true);
                setIsLoading(true);
                await triggerClusterByMode(
                  trigger,
                  'manual',
                  warehouse?._id,
                  begin,
                  end,
                  value,
                );
              }
            }}
            defaultValue={agentCategory}
            renderInput={(params) => (
              <TextField
                {...params}
                name="Type"
                size="small"
                label="Type"
                variant="outlined"
                aria-label="autocomplete"
                required
              />
            )}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'right',
          width: '100%',
        }}
        sx={{ padding: 0 }}
      >
        <Button onClick={handleCloseActivate} color="inherit">
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={handleProceedClick}
          disabled={disabledProceed || !warehouses?.decks?.length}
          variant="contained"
          autoFocus
          sx={{ height: '33px', width: '88px' }}
        >
          {isLoading ? (
            <LoadingContainer>
              <LoadingPulse />
            </LoadingContainer>
          ) : (
            t('common.buttons.proceed')
          )}
        </Button>
      </DialogActions>
    </TwoViewModal>
  );
};
