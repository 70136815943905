import api from './api';

export const aiApi = api.injectEndpoints({
  endpoints: builder => ({
    getRoundtrips: builder.query<any, any>({
      query: urlParams => ({
        url: `ai/classification-data${urlParams}`,
      }),
      providesTags: ['AiTriggers'],
    }),
    postRoundtrips: builder.mutation<any, { text: string }>({
      query: ({ ...body }) => ({
        url: `ai/chat`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Airoundtrip'],
    }),
    getTriggers: builder.query<any, any>({
      query: urlParams => ({
        url: `ai/triggers?${urlParams}`,
      }),
      providesTags: ['AiTriggers'],
    }),
    getPipelines: builder.query<any, any>({
      query: urlParams => ({
        url: `ai/trigger-pipelines?${urlParams}`,
      }),
      providesTags: ['AiPipelines'],
    }),
    postTriggerHookAction: builder.mutation<
      any,
      {
        pipelineId: string;
        actionId: string;
      }
    >({
      query: ({ actionId, pipelineId }) => ({
        url: `ai/pipeline/${pipelineId}/trigger-hook`,
        method: 'POST',
        body: { actionId },
      }),
      invalidatesTags: ['AiPipelines'],
    }),
  }),
});

export const {
  //Roundtrips today
  usePostRoundtripsMutation,
  useLazyGetRoundtripsQuery,
  useGetPipelinesQuery,
  useGetTriggersQuery,
  usePostTriggerHookActionMutation,
} = aiApi;

export default aiApi;
