import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const DocumentNotification = ({ content }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  if (!content) {
    return null;
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Stack
      direction="row"
      gap="5px"
      fontSize="0.75rem"
      sx={{ cursor: 'pointer' }}
    >
      <Typography fontWeight={700} fontSize="0.75rem">
        {capitalizeFirstLetter(content.i18n?.[locale]?.body)}
      </Typography>
    </Stack>
  );
};
