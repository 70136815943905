import React from 'react';
import { Stack } from '@mui/material';
import { themes } from 'styles/theme/themes';
import FooterButton from '../../../FooterButton';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { SubmitButton } from 'app/components/SubmitButton';

const FooterButtons = ({
  handleCloseDrawer,
  handleSubmit,
  selectedType,
  uploadedDocuments,
  employeeId = '',
  isLoading = false,
  isUploadingForCurrentUser = false,
  isDisabled = false,
}) => {
  const gridContext = useManagedContext<IGridContext>('grid');
  const idParams = useParams();
  const id = idParams.id || employeeId || '';
  const authUser = useSelector(selectAuthUser);
  const isAdmin = gridContext.canEdit && authUser?._id !== id;
  return (
    <Stack direction="row" width="100%">
      <Stack
        direction="row"
        spacing={1}
        flex={1}
        justifyContent="flex-end"
        alignItems="center"
      >
        <FooterButton
          label="Cancel"
          color={themes.default.documentDrawerText}
          onClick={handleCloseDrawer}
        />
        <SubmitButton
          loading={isLoading}
          onSubmit={handleSubmit}
          disabled={
            !selectedType || uploadedDocuments.length === 0 || isDisabled
          }
          isEdit={isAdmin}
          isUploadingForCurrentUser={isUploadingForCurrentUser}
        />
      </Stack>
    </Stack>
  );
};

export default FooterButtons;
