import Each from 'common/UtilityComponents/Each';
import styled from 'styled-components';
import { ListCollapsablePayment } from './components/ListCollapsablePayment';
import { useContext, useEffect, useState } from 'react';
import { ListPaymentContext } from './ListPaymentProvider';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext, ITabsContext } from 'app/components/ManagedGrid/types';
import { TransactionsTab } from '../config';
import { useTranslation } from 'react-i18next';
import { useGetInvoicesCountByListQuery } from 'common/services/transactions';
import If from 'app/components/If';
import { LIST } from 'app/components/TableComponent/constants';

export function ListComponent() {
  const [expandedIndices, setExpandedIndices] = useState<boolean[]>([]);
  const tabsContext = useManagedContext<ITabsContext>('tabs');
  const gridContext = useManagedContext<IGridContext>('grid');

  const { t } = useTranslation();

  const { data: invoices } = useGetInvoicesCountByListQuery('');

  const { setRowDataList } = useContext(ListPaymentContext);

  useEffect(() => {
    if (invoices) {
      const allInvoices = Object.values(invoices).flat();
      setRowDataList(allInvoices);

      setExpandedIndices(Object.keys(invoices).map((_, index) => index < 5));
    }
  }, [invoices]);

  useEffect(() => {
    if (gridContext.viewMode === LIST) {
      gridContext.updateDataWithFunction((prev) => {
        prev.withStatsCards = false;
      });
      tabsContext.updateDataWithFunction((prev) => {
        prev.selectedTab = TransactionsTab.BILLS;
        prev.visibleTabs = [
          { label: t('common.bills'), value: TransactionsTab.BILLS },
        ];
      });
    }
  }, [gridContext.viewMode]);

  return (
    <Wrapper>
      <If condition={invoices}>
        <Each
          of={invoices?.filter((period) => period.uploadDateKey !== null)}
          render={(period, index) => (
            <ListCollapsablePayment
              key={index}
              period={period}
              expanded={expandedIndices[index]}
              onToggle={() => {
                setExpandedIndices((prev) =>
                  prev.map((state, i) => (i === index ? !state : state)),
                );
              }}
            />
          )}
        />
      </If>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 16px 0px 16px 20px;
  padding: 0px 20px 0px 0px;
  gap: 20px;
  overflow-y: auto;
  height: fit-content;
`;
