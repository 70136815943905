import { CustomCellRendererProps } from '@ag-grid-community/react';
import { FilePaymentStatus } from 'app/pages/CustomerPublic/components/Documents/components/DocumentRow';

function StatusCell(params: CustomCellRendererProps) {
  return (
    <div>
      <FilePaymentStatus file={params?.value?.statusDataObj} />
    </div>
  );
}

export default StatusCell;
