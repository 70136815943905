import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Tooltip, Typography } from '@mui/material';

function BranchCell(params: CustomCellRendererProps) {
  const { branchCodeData, branchData } = params?.value || {};

  if (branchCodeData) {
    return (
      <Tooltip title={branchData || ''}>
        <Typography fontSize="0.85rem">{branchCodeData}</Typography>
      </Tooltip>
    );
  }

  return <Typography fontSize="0.85rem">{branchData || '-'}</Typography>;
}

export default BranchCell;
