import GridContext from 'app/components/ManagedGrid/GridContext';
import { useState, createContext, useEffect } from 'react';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { StateSetter } from 'types';
import { Offer } from 'common/types/Customer';
import { IOfferObj } from '../../types';
import { Contract } from '../../../../types';
import { OffersListGrid } from './components/OffersListGrid';

export interface Category {
  name: string;
  _id: string;
  products: Offer[];
  key: string;
}
interface ContextProps {
  selectedOffer: IOfferObj | null;
  setSelectedOffer: StateSetter<IOfferObj | null>;
  openOfferModal: boolean;
  setOpenOfferModal: StateSetter<boolean>;
}
export const OfferContext = createContext<ContextProps>({
  selectedOffer: null,
  setSelectedOffer: () => {},
  openOfferModal: false,
  setOpenOfferModal: () => {},
});

export const OffersList = ({ selectedTab }: { selectedTab: Contract }) => {
  const [selectedOffer, setSelectedOffer] = useState<IOfferObj | null>(null);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const can = usePermission('customers');

  useEffect(() => {
    if (selectedOffer?._id) {
      setOpenOfferModal(true);
    }
  }, [selectedOffer]);

  return (
    <OfferContext.Provider
      value={{
        selectedOffer,
        setSelectedOffer,
        openOfferModal,
        setOpenOfferModal,
      }}
    >
      <Wrapper>
        <GridContext
          data={[]}
          title="offersGrid"
          rowHeight={52}
          canEdit={can(Permission.EDIT)}
          suppressToolbar={true}
          suppressPagination={true}
          suppressCheckbox={true}
        >
          <OffersListGrid selectedTab={selectedTab} />
        </GridContext>
      </Wrapper>
    </OfferContext.Provider>
  );
};

const Wrapper = styled.div`
  flex: 1;
  .disabled-row {
    background: ${themes.default.lightGrey};
    color: ${themes.default.clientMarkerBg} !important;
  }
`;
