import { VisitorsCancelVisitInfo } from './components/VisitorsCancelVisitorInfo';
import { VisitorsCancelVisitDetails } from './components/VisitorsCancelVisitDetails';
import { Box, Grid } from '@mui/material';
import { VisitorsFooter } from '../VisitorsPublic/components/VisitorsFooter';
import { useParams } from 'react-router-dom';
import {
  useGetOrganizationVisitorsQuery,
  useGetOrganizationVisitQuery,
  usePatchCancelVisitorMutation,
} from 'common/services/visitors';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const VisitorsCancelVisit = () => {
  const [patchCancelVisitor] = usePatchCancelVisitorMutation();

  const params = useParams();

  const { organizationId, uuid } = params;
  const { data: visit, refetch } = useGetOrganizationVisitQuery(params, {
    skip: !organizationId,
  });

  const isCanceled = visit?.status === 'canceled';

  const { data: organization } = useGetOrganizationVisitorsQuery(
    organizationId,
    { skip: !organizationId },
  );

  const handleCancel = async () => {
    try {
      await patchCancelVisitor({
        visitorId: visit._id,
        uuid: uuid,
        organizationId: organizationId,
        status: 'canceled',
        reason: 'visitor.cancel.reason.client-request',
      }).unwrap();

      refetch();
    } catch (error) {
      console.error("Erreur lors de l'annulation :", error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        width: '100%',
      }}
    >
      <GradientTopRight />
      <Box sx={{ padding: { xs: 2, md: 3 } }}>
        <Grid container spacing={4}>
          <Grid
            item
            lg={6}
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              minWidth: '300px',
            }}
          >
            <VisitorsCancelVisitInfo
              organization={organization}
              isCanceled={isCanceled}
            />
          </Grid>

          <Grid
            item
            lg={6}
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              minWidth: '300px',
            }}
          >
            <VisitorsCancelVisitDetails
              organization={organization}
              visit={visit}
              onCancel={handleCancel}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: '100%', marginTop: 'auto' }}>
        <VisitorsFooter />
      </Box>
    </Box>
  );
};

const GradientTopRight = styled.div`
  width: 741px;
  height: 600px;
  position: absolute;
  top: -100px;
  right: 140px;
  z-index: -2;
  transform: rotate(-20.57deg);
  border-radius: 50%;
  filter: blur(150px);
  background: ${themes.default.pastelGlow};
`;
