import { Grid, TextField, MenuItem } from '@mui/material';
import FormSelectField from 'app/components/Form/SelectField';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import FormTextField from 'app/components/Form/TextField';
import { useGetCustomerTypesQuery } from 'common/services/customerApi';
import { useCheckCode } from 'hooks/Customers/useCheckCode';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import { useContext, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SupplierFormcontext } from '../context';
import { useSelector } from 'react-redux';
import { selectTags } from 'common/store/organization/selectors';
import MultiSelectAutocomplete from 'app/components/FilterComponents/components/MultiSelect/components/Autocomplete';
import { professionType } from 'common/types/Supplier';

export const SupplierInformation = ({ control, errors, setValue }) => {
  const { t } = useTranslation();
  const { supplier, canEdit, isSupplier } = useContext(SupplierFormcontext);
  const { data: clientGroups } = useGetCustomerTypesQuery({});
  const { validate } = useCheckCode();
  const { formatError } = useErrorFormatter();

  const disabled = isSupplier || !canEdit;

  const groups = useMemo(() => {
    return (
      clientGroups?.map((group) => {
        return {
          label: group.label,
          value: group._id,
        };
      }) || []
    );
  }, [clientGroups]);

  const watchedTags = useWatch({
    control,
    name: 'tags',
    defaultValue: [],
  });

  const tags = useSelector(selectTags);

  const tagsItems = useMemo(() => {
    if (!tags) {
      return [];
    }
    return tags?.map((tag) => {
      return {
        value: tag?._id,
        label: tag?.label,
      };
    });
  }, [tags]);

  const getProfessionTypes = useMemo(() => {
    return Object.values(professionType).map((category) => {
      return {
        label: t(`${category}`),
        value: category,
      };
    });
  }, [t]);

  return (
    <FormSection>
      <SectionTitle>{t('supplier_information')}</SectionTitle>
      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <FormTextField
            name="publicName"
            label={t('public_name')}
            control={control}
            disabled={disabled}
            rules={{
              required: {
                value: true,
                message: `${t('supplier.publicName')} ${t('is_required')}`,
              },
            }}
            inputProps={{
              placeholder: t('enter_name'),
            }}
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name="code"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('common.code')} ${t('is_required')}`,
              },
              validate(value) {
                return validate({
                  code: value?.toString() || '',
                  id: supplier?._id,
                });
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                type="text"
                label={`${t('common.code')}`}
                variant="outlined"
                error={!!errors?.code}
                helperText={formatError(errors?.code, t('common.code'))}
                fullWidth
                disabled={disabled}
                onChange={(value) => field.onChange(value)}
                required
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <Controller
            name="professionType"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t('profession_type')}
                variant="outlined"
                fullWidth
                disabled={disabled}
              >
                {getProfessionTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <FormSelectField
            name="_supplierType"
            label={t('supplier.category')}
            control={control}
            data={groups}
            checkboxes={false}
            multiple={false}
            disabled={true}
          />
        </Grid>
      </Grid>

      <Grid container spacing="12px">
        <Grid item xs={12}>
          <MultiSelectAutocomplete
            placeholder={t('info-client-Tags')}
            selectedOptions={tagsItems?.filter((t) =>
              watchedTags?.includes(t.value),
            )}
            handleFilterChange={(options) => {
              setValue(
                'tags',
                options?.map((o) => o.value),
              );
            }}
            filterItems={tagsItems}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
