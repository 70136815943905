export enum RoundtripStatus {
  PENDING = 'pending',
  PLANNED = 'planned',
  CANCELED = 'canceled',
  ON_WAY = 'on-way',
  PAUSED = 'paused',
  COMPLETED = 'completed',
  PREPARED = 'prepared',
  PARKED = 'parked',
  UNLOADED = 'unloaded',
  LOADED = 'loaded',
  LOADING = 'loading',
  ON_DOCK = 'on-dock',
  UNLOADING = 'unloading',
}

export enum RoundtripStopType {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
  FUEL = 'fuel',
  MAINTENANCE = 'maintenance',
  EMERGENCY = 'emergency',
  VISIT = 'visit',
  EXPRESS = 'express',
  REPAIR = 'repair',
}

export enum RoundtripStopStatus {
  TO_BE_PREPARED = 'to-be-prepared',
  PREPARED = 'prepared',
  PLANNED = 'planned',
  LOADED = 'loaded',
  PENDING = 'pending',
  CANCELED = 'canceled',
  DELIVERED = 'delivered',
  COMPLETED = 'completed',
  UNLOADING = 'unloading',
  UNLOADED = 'unloaded',
}

export enum OrderStatus {
  INITIATED = 'initiated',
  INITIATED_CUSTOMER = 'initiated-customer',
  PENDING = 'pending', // pending (bianta) et To be reviewed (ordoria)
  PENDING_CUSTOMER = 'pending-customer', // pending update from client
  VALIDATED = 'validated', // validated by sales
  CONFIRMED = 'confirmed', // confirmed by logistic - to be prepared
  PREPARED = 'prepared',
  LOADED = 'loaded',
  UNLOADED = 'unloaded',
  READY_FOR_DELIVERY = 'ready-for-delivery', // When roundtrip is planned and cluster is active
  ON_THE_ROAD = 'on-the-road',
  DELIVERED = 'delivered',
  CANCELED = 'canceled',
  BLOCKED = 'blocked',
  UNLOADING = 'unloading',
}

export enum OrderType {
  COLLECTION = 'collection',
  DELIVERY = 'delivery',
  EXPRESS = 'express',
  URGENT = 'urgent',
  REPAIR = 'repair',
}

export enum OrderSource {
  PUBLIC_API = 'PUBLIC_API',
  ORDORIA = 'ORDORIA',
  GESCOM = 'GESCOM',
}

export enum OrderPriority {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum LeaveRequestCategory {
  TEAM_REQUEST = 'Team Request',
  MY_REQUEST = 'My requests',
}

export enum LeaveRequestStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
}

export enum TruckStatus {
  ACTIVE = 'active',
  OFFLINE = 'offline',
  AVAILABLE = 'available',
  EMERGENCY = 'emergency',
  RETURNING = 'returning',
}

export enum ToolsStatus {
  INUSE = 'in use',
  AVAILABLE = 'available',
}

export enum VehiclesStatus {
  AVAILABLE = 'available',
  INUSE = 'in-use',
  OUTOFSERVICE = 'out-of-service',
}

export enum SupportUnitType {
  SUPPORTUNIT = 'supportunit',
  SUPPORTUNITLOCATION = 'supportunitlocation',
}

export enum NotificationType {
  NEW_LEAVE_REQUEST = 'new-leave-request',
  REQUEST_TO_UNLOAD = 'request-to-unload',
  COLLECTED_AMOUNT_ORDER = 'collect_amount_delivery',
  LEAVE_REQUEST_UPDATE = 'leave-request-update',
  ROUNDTRIP_STATUS_UPDATED = 'roundtrip-status',
  ORDER_STATUS_UPDATED = 'order-status-updated',
  DOCUMENTS_NOTIFICATION = 'documents_notification',
}

export enum DocumentsKeys {
  NATIONALIDS = 'nationalIds',
  EMPLOYMENTCONTRACT = 'employmentContracts',
  DRIVERLICENSES = 'driverLicenses',
  RESUME = 'resume',
  EMPLOYMENTHISTORY = 'employmentHistory',
  MEDICALEXAMINATIONREPORTS = 'medicalExaminationReports',
  CERTIFICATES = 'certificates',
  DRIVERRECORDABSTRACT = 'driverRecordAbstract',
  SOCIALSECURITY = 'socialSecurity',
  BANKIDENTITYSTATEMENT = 'bankIdentityStatement',
  PROOFOFEDUCATION = 'proofOfEducation',
  PROOFOFADDRESS = 'proofOfAddress',
  OTHER = 'otherDocuments',
}

export enum DocumentStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum ChatStatusEnum {
  REJECTED = 'rejected',
}

export enum UploadFIleStatus {
  FAILED = 'failed',
  SUCCESS = 'success',
}

export enum UserStatus {
  INVITED = 'invited',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum UserOnlineStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum EcmrTypes {
  IMPORT = 'import',
  MANUAL = 'manual',
}

export enum EcmrFields {
  TYPE = 'type',
  ITEMS = 'items',
  CARRIER = 'carrier',
  DRIVER = 'driver',
  SENDER = 'sender',
  RECEIVER = 'receiver',
  PICKUP_DATE = 'pickupDate',
  PICKUP_LOCATION = 'pickupLocation',
  LOADING_LOCATION = 'loadingLocation',
  UNLOADING_LOCATION = 'unloadingLocation',
  TEMPERATURE = 'temperature',
  COMMENTS = 'comments',
  SUPPORT_UNIT_DELIVERED = 'supportUnitDelivered',
  SUPPORT_UNIT_COLLECTED = 'supportUnitCollected',
  FILE = 'file',
}

export enum EcmrSenderType {
  SENDING = 'sending',
  RECEIVING = 'receiving',
}

export enum CalendarType {
  RANGE = 'range',
  SINGLE = 'single',
}

export enum professionType {
  MANUFACTURING = 'manufacturing',
  TECHNOLOGY = 'technology',
  AGRICULTURE = 'agriculture',
  CHEMICAL = 'chemical',
  PHARMACEUTICAL = 'pharmaceutical',
  CONSTRUCTION = 'construction',
  AUTOMOTIVE = 'automotive',
  RETAIL = 'retail',
  FOOD_BEVERAGE = 'food&beverage',
}
