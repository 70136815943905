import {
  RoadHorizon,
  UsersThree,
  IdentificationBadge,
  Truck,
  PresentationChart,
} from '@phosphor-icons/react';
import { Section, SECTION_TYPES } from './types';
import { useTranslation } from 'react-i18next';

export const usePredefinedSections = (): Section[] => {
  const { t } = useTranslation();
  return [
    {
      id: `section-${SECTION_TYPES.ROUNDTRIPS}`,
      title: t('Ask_roundtrips'),
      icon: RoadHorizon,
      questions: [
        {
          id: 'question-1-1',
          question: t('roundtrips_today'),
          answer: { text: '', tables: [] },
        },
        {
          id: 'question-1-2',
          question: t('roundtrips_tomorrow'),
          answer: { text: '' },
        },
        {
          id: 'question-1-3',
          question: t('Show_all_roundtrips'),
          answer: { text: '' },
        },
      ],
    },
    {
      id: `section-${SECTION_TYPES.CLIENTS}`,
      title: t('Ask_clients'),
      icon: UsersThree,
      questions: [
        {
          id: 'question-2-1',
          question: t('Update_information_client'),
          answer: { text: '' },
        },
        {
          id: 'question-2-2',
          question: t('Show_pending_orders'),
          answer: { text: '' },
        },
        {
          id: 'question-2-3',
          question: t('Show_clients_timeframe'),
          answer: { text: '' },
        },
      ],
    },
    {
      id: `section-${SECTION_TYPES.EMPLOYEES}`,
      title: t('Ask_employees'),
      icon: IdentificationBadge,
      questions: [
        {
          id: 'question-3-1',
          question: t('List_all_employees'),
          answer: { text: '' },
        },
        {
          id: 'question-3-2',
          question: t('See_details_employee'),
          answer: { text: '' },
        },
        {
          id: 'question-3-3',
          question: t('deliveries_driver_today'),
          answer: { text: '' },
        },
      ],
    },
    {
      id: `section-${SECTION_TYPES.VEHICLES}`,
      title: t('Ask_vehicles'),
      icon: Truck,
      questions: [
        {
          id: 'question-4-1',
          question: t('trucks_available_today'),
          answer: { text: '' },
        },
        {
          id: 'question-4-2',
          question: t('details_truck'),
          answer: { text: '' },
        },
        {
          id: 'question-4-3',
          question: t('Track_location_truck'),
          answer: { text: '' },
        },
      ],
    },
    {
      id: `section-${SECTION_TYPES.KPIS}`,
      title: t('Get_KPI_metric'),
      icon: PresentationChart,
      questions: [
        {
          id: 'question-5-1',
          question: t('Show_sales_report_today'),
          answer: { text: '' },
        },
        {
          id: 'question-5-2',
          question: t('report_past_week'),
          answer: { text: '' },
        },
        {
          id: 'question-5-3',
          question: t('report_sales_employee_today'),
          answer: { text: '' },
        },
        {
          id: 'question-5-4',
          question: t('deliveries_by_region'),
          answer: { text: '' },
        },
      ],
    },
  ];
};
