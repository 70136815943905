import { CustomCellRendererProps } from '@ag-grid-community/react';
import useFormatCurrency from 'common/hooks/useFormatCurrency';

function RemainingCell(params: CustomCellRendererProps) {
  const formatCurrency = useFormatCurrency();

  return (
    <div>
      <span>{formatCurrency(params?.value?.remainingData)}</span>
    </div>
  );
}

export default RemainingCell;
