import { Address } from 'types';

export interface CustomerDocs {
  _id?: string;
  id?: string;
  code: string;
  createdAt: string;
  email: string;
  invoicePeriod: string;
  paymentPeriod: number;
  legalId: string;
  legalName: string;
  phone: string;
  publicName: string;
  updatedAt: string;
  vatNumber: string;
  mainLocation?: { geometry: { coordinates: number[] } };
  _organizationDeck?: { _id: string; nextDeliveryDate: string };
}

export interface ContactData {
  email: string;
  subject: string;
  message: string;
  token: string;
  password: string;
}

export enum CustomerStatus {
  IN_REVIEW = 'in-review',
  ACTIVE = 'active',
  ON_WATCH = 'on-watch',
  BLOCKED = 'blocked',
  ARCHIVED = 'archived',
}

export enum CustomerActions {
  CUSTOMER_EDIT = 'customer-edit',
  CUSTOMER_ADD = 'customer-add',
  CUSTOMER_EDIT_DECK = 'customer-edit-deck',
  CUSTOMER_ADD_DECK = 'customer-add-deck',
  CUSTOMER_CANCEL_SEPA = 'customer-cancel-sepa',
  CUSTOMER_VALIDATE_SEPA = 'customer-validate-sepa',
  CUSTOMER_DELETE_DECK = 'customer-delete-deck',
  CUSTOMER_STATUS_UPDATED = 'customer-status-updated',
}

export interface Contact {
  name: string;
  phone: string;
  role: string;
  jobType: string;
  main: boolean;
  email?: string;
  _id: string;
}

export enum CustomerType {
  PROSPECT = 'prospect',
  Client = 'client',
}

export type CustomerFields = {
  type: CustomerType;
  code: string;
  publicName: string;
  legalName: string;
  email: string;
  otherEmails: string;
  vatNumber: string;
  invoicePeriod: string;
  mobilePhone: string;
  companyEmail: string;
  _customerType: string;
  _salesman: {
    value: string;
    label: string;
    phone: string;
  };
  status: CustomerStatus;
  mainLocation: Address;
  switchState: string;
  legalIdAlt: string;
  salePhone: string;
  emailInput: string;
  activityCode: string;
  tags: string[];
  contacts: Contact[];
  notes?: string;
  mainEmailAddress?: string;
};
export type ContractsFacetsRes = {
  message: string;
  stats: {
    category: {
      pending_approval: number;
      approved: number;
      total: number;
    };
    subcategory: {
      pending_approval: number;
      approved: number;
      total: number;
    };
    childcategory: {
      pending_approval: number;
      approved: number;
      total: number;
    };
  };
};

export type Offer = {
  _id: string;
  _customer: {
    _id: string;
    _customerType: string;
  };
  mechanism: string;
  period: {
    type: string;
    _id: string;
  };
  offeredProduct: {
    _product: {
      sellingPrice: number | null;
    };
  };
  offeredQuantity: {
    type: string;
    quantity: number;
    _id: string;
  };
  purchaseCondition: {
    type: string;
    value: number;
    valueType: string;
    _id: string;
    _items: [
      {
        _id: string;
        name: string;
        level: number;
      },
    ];
  };
  status: string;
  isActive: boolean;
  cache: {
    _productItems: string[];
    _categoryItems: [
      {
        name: string;
      },
    ];
    _id: string;
  };
  _createdBy: string;
  customerOfferId: string;
  updatedAt: string;
  totalOffer: number | null;
};

export type CustomerOffer = {
  _id: {
    mechanism: string;
    type: string;
  };
  offers: Offer[];
};

export type CustomerOfferBody = {
  mechanism: string;
  product: string | null | undefined;
  conditionType: string;
  periodType: string;
  conditionItems?: string[];
  offeredQuantity: number;
  offeredQuantityType: string | undefined;
  customer?: string;
  startDate?: string | undefined;
  endDate?: string | undefined;
  conditionValueType?: string;
  conditionValue?: number;
};

export interface Door {
  name: string;
  code: string;
}

export interface Branch {
  id: string;
  shortName: string;
  code: string;
  location: Address;
  status: CustomerStatus;
  siretNumber: string;
  vatNumber: string;
  apeCode: string;
  contacts: Contact[];
  deliveryTypes: any[];
  thirdPartyCollector: boolean;
  carrier: string;
  deliveryDays: {
    day: string;
    value: string;
  }[];
  requestedSupport: string[];
  vehicleCondition: string[];
  roundtripDays: any;
  deliveryDaysHours: any;
  customerId: string;
  index?: number;
  new?: boolean;
  availableTimeSlotFrom: string;
  availableTimeSlotTo: string;
  preferredTimeSlotFrom: string;
  preferredTimeSlotTo: string;
  minimumWeight: number;
  minOrderPrice: number;
  minOrderBoxes: number;
  doors: Door[];
  tags: string[];
}
