/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, {
  isValidElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { GlobalStyle } from 'styles/global-styles';

import LogRocket from 'logrocket';
import { Crisp } from 'crisp-sdk-web';
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import { LoginPage } from './pages/Login/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { AccessDeniedPage } from './pages/AccessDenied/loadable';
import { useTranslation } from 'react-i18next';
import PrivateRoute from './components/PrivateRoute';
import { DashboardPage } from './pages/Dashboard/Loadable';
import { MainNav } from './components/MainNav';
import LogoutPage from './pages/Logout';
import styled, { keyframes } from 'styled-components';
import { AppHeader } from './components/AppHeader';
import { AuditPage } from './pages/Audit/Loadable';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAuthToken,
  selectAuthUser,
  selectMainMenuSize,
} from './slices/auth/selectors';
import { RoundTrips } from './pages/RoundTrips/Loadable';
import { SigninPage } from './pages/Signin/Loadable';
import { selectIsZoomedIn } from './slices/tools/selectors';
import { SignUpNamePage } from './pages/SignupName/Loadable';
import { SignUpEmailPage } from './pages/SignUpEmail/Loadable';
import { VerificationLink } from './pages/VerificationLink';
import { SignUpDetailsPage } from './pages/SignUpDetails/Loadable';

import { JoinWorkspace } from './pages/JoinWorkspace/Loadable';
import { ResetPasswordPage } from './pages/ResetPassword/Loadable';
import { ResetPasswordOtpPage } from './pages/ResetPasswordOTP/Loadable';
import { VehiclesPage } from './pages/Vehicles/Loadable';
import { CustomersPage } from './pages/Customers/Loadable';
import { CustomerPublic } from './pages/CustomerPublic';
import { OrdersPage } from './pages/Orders/Loadable';
import { InboxPage } from './pages/Inbox/Loadable';
import { StorageLocationPage } from './pages/StorageLocations/Loadable';
import { StorageLoadingInfoPage } from './pages/StorageLocations/Components/StoragLocationInfo/Loadable';
import { ClientGroupsPage } from './pages/ClientGroups/Loadable';
import { PricingGroupsPage } from './pages/PricingGroups/Loadable';
import { DiscountGroupsPage } from './pages/DiscountGroups/Loadable';
import { CategoriesPage } from './pages/Categories/Loadable';
import { SuppliersPage } from './pages/Suppliers/Loadable';

import {
  ANALYTICS,
  AUDIT,
  CUSTOMER,
  CUSTOMER_PUBLIC,
  DASHBOARD,
  VISITORS,
  LOGIN,
  LOGOUT,
  NOT_FOUND,
  ORDERS,
  OTP,
  RESET,
  ROUNDTRIP_ADD,
  SIGNIN,
  SIGNUP,
  SIGNUP_DESCRIPTION,
  SIGNUP_DESCRIPTION_DETAILS,
  SIGNUP_NAME,
  UPLOAD_FILES,
  SUPPORTUNIT,
  VEHICLES,
  VERIFICATION_LINK,
  CHECK_DOCUMENTS,
  COMPANYPROFIL,
  INBOX,
  ROUNDTRIP,
  SCHEDULED_ROUNDTRIPS,
  ALL_ROUNDTRIPS,
  ORDORIA,
  STORAGELOCATIONS,
  ORDERS_ACTIONS,
  LANGUAGE,
  SETTINGS_USERS,
  ACCESSORIES,
  ACCESSORYINFO,
  TOOLS,
  DASHBOARD_ALL,
  PAYMENTCARDS,
  VIEWCUSTOMER,
  ORDORIA_VIEWCUSTOMER,
  ROLES_PERMISSIONS,
  SETTINGS_CUSTOMIZATION,
  REPORTS,
  ORDORIA_CUSTOMER,
  STOCKEE_CUSTOMER,
  STOCKEE_CLIENT_GROUP,
  STOCKEE_PRICING_GROUP,
  STOCKEE_DISCOUNT_GROUP,
  STOCKEE_PRODUCTS,
  STOCKEE_PRODUCTS_INFO,
  STOCKEE_CATEGORIES,
  REPORTS_ALL,
  PRIVACY_POLICY,
  CONTACT_US,
  USERINFO,
  EMPLOYEEINFO,
  CUSTOMERBRANCH,
  USERPAGE,
  SETTINGS,
  STORAGELOCATIONINFO,
  ACCESS_DENIED,
  IAM,
  STOCKEE_VIEWCUSTOMER,
  SETTINGS_ROUNDTRIP_MAPPING,
  VISITORS_REGISTRATION,
  VISITORS_QR_CODE,
  ORDORIA_CLAIMS,
  ORDORIA_TRANSACTIONS_ACCOUNTING,
  DOCUMENTS,
  ORDORIA_BALANCE,
  PAYMENTS,
  ORDORIA_INVOICES,
  ORDORIA_REFUNDS,
  ORDORIA_PAYMENTS,
  STOCKEE_SUPPLIERS,
  VIEWSUPPLIER,
  VISITORS_ORGANIZATION_VISIT,
} from 'utils/routes';
import { UploadFiles } from './pages/UploadFilesPage/Loadable';
import { AddRoundTrips } from './pages/AddRoundTrips';
import { AnalyticsPage } from './pages/Analytics/Loadable';
import { SupportUnits } from './pages/SupportUnits/Loadable';
import { CheckDocuments } from './pages/CheckDocuments';
import { AllRoundTrips } from './pages/AllRoundtrip/Loadable';
import {
  selectLastToasterNotification,
  selectLastUserNotification,
} from '../common/store/app/selectors';
import { appActions } from '../common/store/app';
import { CompanyProfile } from './pages/Settings/CompanyProfile';
import { Alert, Fade, Portal } from '@mui/material';
import { RoundtripsTabs } from './pages/RoundtripsTabs/Loadable';
import { OrdersActionPage } from './pages/Ordoria/Orders/Loadable';
import {
  ORDORIA_CREATE_ORDERS,
  ORDORIA_VIEW_ODER_DETAIL,
  ORDORIA_SALES_VIEW_ORDER,
  ORDORIA_CALL_PLAN,
  ORDORIA_CATALOG,
} from './pages/Ordoria/routes';
import { Settings } from './pages/Settings';
import { LanguageCurrency } from './pages/Settings/LanguageCurrency/Loadable';
import { OrdoriaDetailPage } from './pages/Ordoria/OrderDetail/Loadable';
import { UsersPage as UsersPageSettings } from './pages/Settings/Users/Loadable';
import { CustomizationPage } from './pages/Settings/Customization/Loadable';
import { UsersPage } from './pages/Users/Loadable';
import { SalesOrderDetails } from './pages/Ordoria/SalesOrderDetail';
import { OrdoriaCallPlan } from './pages/Ordoria/CallPlan/Loadable';
import { CatalogPage } from './pages/Ordoria/Catalog/Loadable';
import { OrdoriaPortalDahsboardPage } from './pages/Ordoria/Portal/dashboard/Loadable';
import { Accessories } from './pages/Accessories/Loadable';
import { AccessoryInfo } from './pages/AccessoryInfo/Loadable';
import { Tools } from './pages/Tools/Loadable';
import { PaymentCards } from './pages/PaymentCards/Loadable';
import { ViewCustomer } from './pages/Customers/components/ViewCustomer';
import { ViewSupplier } from './pages/Suppliers/components/ViewSupplier';
import { BalancePage } from './pages/Ordoria/Balance/Loadable';
import { GridProvider } from './components/Grid/context';
import { RolesPermissions } from './pages/Settings/RolesPermissions/Loadable';
import { RoleProvider } from './pages/Settings/RolesPermissions/context';

import { ReportsPage } from './pages/Reports/Loadable';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { ContactUs } from './pages/ContactUs';
import { UserProvider } from './pages/Settings/Users/context';
import { UserInfo } from './pages/Settings/UserInfo/Loadable';
import { AiControlCenterPage } from './pages/AiControlCenter/Loadable';
import { useGetConfigurationQuery } from 'common/services/organizationApi';
import { CustomerBranch } from './pages/Customers/components/CustomerBranch';
import { useInvalidateUserData } from 'hooks/Users/useInvalidateUser';
import { AbilityProvider } from 'hooks/Abilities/context';
import { ApplicationRoute } from './components/ApplicationRoute';
import api from 'common/services/api';
import { selectOrganization } from 'common/store/organization/selectors';
import VoiceCall from './components/VoiceCall';

import BellNotificationSound from 'assets/sounds/chat/BellNotificationSound.webm';
import { Notification } from './components/Notification';
import { useUserInteracted } from 'hooks/userInteracted';
import { useUserNotifications } from 'hooks/Users/useUserNotification';
import { ModalProvider } from './components/Dialog/context';
import RoundtripMapping from './pages/Settings/RoundtripMapping';
import { OrderDetails } from './components/OrderDetails';
import If from './components/If';
import { themes } from 'styles/theme/themes';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Application } from 'common/store/app/types';
import { Cart } from './pages/Ordoria/cart';
import { ClaimsPage } from './pages/Ordoria/Claims/Loadable';
import { useSubscribeObject } from 'common/hooks/ws';
import { Transactions } from './pages/Ordoria/Transactions';
import { ManagedCreateOrderDrawer } from './pages/Ordoria/Orders/components/ManagedCreateOrderDrawer';
import { ProductsPage } from './pages/Products/Loadable';
import { ProductsDetailsPage } from './pages/Products/ProductsInfo/Loadable';
import { ViewEditOrderPage } from './pages/Ordoria/ViewEditOrder/Loadable';
import { StatusToasterNotification } from './components/StatusToasterNotification';
import { useActivitiesReadMutation } from 'common/services/activityApi';
import { Payments } from './pages/Ordoria/Payments';
import { FeaturesPopup } from './components/FeaturesPopup';
import Documents from './pages/Documents';
import { VisitorsPage } from './pages/VisitorsManagement/Loadable';
import { VisitorsCode } from './pages/VisitorsPublic/components/VisitorsCode';
import { VisitorsPublic } from './pages/VisitorsPublic';
import { Invoices } from './pages/Ordoria/Invoices';
import { Refunds } from './pages/Ordoria/Refunds';
import { GuardedActionProvider } from 'hooks/useGuardedAction';
import { VisitorsCancelVisit } from './pages/VisitorsCancelVisit';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

const {
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_CRISP_ID,
  REACT_APP_LOG_ROCKET_ID,
  REACT_APP_CUSTOMERIO_SITE_ID,
  REACT_APP_CUSTOMERIO_API_KEY,
} = process.env;

const IS_PROD =
  NODE_ENV === 'production' &&
  REACT_APP_API_URL === 'https://api-internal.bamptee.com';

if (IS_PROD) {
  if (REACT_APP_LOG_ROCKET_ID) {
    LogRocket.init(REACT_APP_LOG_ROCKET_ID);
  }

  if (REACT_APP_CRISP_ID) {
    Crisp.configure(REACT_APP_CRISP_ID);
  }

  if (REACT_APP_CUSTOMERIO_SITE_ID && REACT_APP_CUSTOMERIO_API_KEY) {
    window.cio_analytics = AnalyticsBrowser.load(
      {
        cdnURL: 'https://cdp-eu.customer.io',
        writeKey: REACT_APP_CUSTOMERIO_API_KEY,
      },
      // Optional: Add in-app messaging support
      {
        integrations: {
          'Customer.io In-App Plugin': {
            siteId: REACT_APP_CUSTOMERIO_SITE_ID,
          },
        },
      },
    );
  }
}

export function App() {
  const { i18n } = useTranslation();
  const authToken = useSelector(selectAuthToken);
  const authUser = useSelector(selectAuthUser);
  const zoomedIn = useSelector(selectIsZoomedIn);
  const lastToasterNotification = useSelector(selectLastToasterNotification);
  const dispatch = useDispatch();
  const i18nLanguage = i18n.language;
  const mainMenuSize = useSelector(selectMainMenuSize);
  const isMinify = mainMenuSize === 'minify';
  const organization = useSelector(selectOrganization);
  const lastUserNotification = useSelector(selectLastUserNotification);
  const notificationAudioRef: React.LegacyRef<HTMLAudioElement> = useRef(null);
  const [objectId, setObjectId] = useState('');
  const [notificationId, setNotificationId] = useState('');
  const theme = useSelector(selectTheme);
  const [openOrderDetailsDrawer, setOpenOrderDetailsDrawer] =
    useState<boolean>(false);
  const [openCreateOrderDrawer, setOpenCreateOrderDrawer] = useState(false);
  const [cartselectedProducts, setCartSelectedProducts] = useState(false);

  const authCustomer = authUser?.currentAgent?._customer;

  const { refetch } = useGetConfigurationQuery();
  useUserNotifications();
  useInvalidateUserData();

  const organizationMessage = useSubscribeObject(
    'organization',
    organization?._id,
  );

  useEffect(() => {
    if (organizationMessage) {
      refetch();
    }
  }, [organizationMessage, refetch]);

  const userInteracted = useUserInteracted();
  const [readActivities] = useActivitiesReadMutation();

  const handleCloseNotification = () => {
    dispatch(appActions.cleanLastToasterNotification());
  };
  const handleCloseUserNotification = () => {
    dispatch(appActions.cleanLastUserNotification());

    if (!!notificationAudioRef?.current && userInteracted) {
      if (notificationAudioRef?.current?.currentTime > 1) {
        notificationAudioRef?.current?.pause();
      }
      notificationAudioRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    const audio = notificationAudioRef?.current;

    if (!audio || !lastUserNotification || !userInteracted) return;

    try {
      if (!audio.paused && !audio.ended) return;

      audio.currentTime = 0;
      audio.play();
    } catch (e) {
      console.error('Error playing notification sound:', e);
    }
  }, [lastUserNotification, userInteracted]);

  useEffect(() => {
    if (authUser?.language && authUser.language !== i18nLanguage) {
      i18n.changeLanguage(authUser?.language);
    }

    if (authUser?.language) {
      window.dayjs.locale(authUser?.language);
    }
  }, [authUser?.language]);

  useEffect(() => {
    if (
      !organization?._id ||
      !authUser?._currentOrganization?._id ||
      authUser?._currentOrganization._id === organization._id
    ) {
      return;
    }
    dispatch(api.util.invalidateTags(['OrganizationConfiguration']));
    dispatch(
      appActions.setLastToasterNotification({
        duration: 5000,
        severity: 'info',
        message: i18n.t('auth.organizationSwitch.success', {
          organizationName: authUser?._currentOrganization.name,
        }),
      }),
    );
  }, [authUser?._currentOrganization, dispatch, i18n, organization?._id]);

  useEffect(() => {
    if (IS_PROD && authUser?._id && authToken) {
      const logRocketTrait = {
        name: authUser.fullName,
        // @ts-ignore
        email: authUser.companyEmail,
        isCustomer: !!authCustomer?._id,
        customerCode: authCustomer?.code || null,
        organizationName: authUser?._currentOrganization?.name || null,
        organizationShortName:
          authUser?._currentOrganization?.shortname || null,
      };

      LogRocket.identify(authUser._id, logRocketTrait);

      Crisp.setTokenId(authToken);
      Crisp.user.setEmail(authUser.companyEmail);
      Crisp.user.setNickname(authUser.fullName);
      Crisp.user.setCompany(logRocketTrait.organizationName, {});
      Crisp.session.setSegments(
        [logRocketTrait.isCustomer ? 'customer' : 'employee'],
        true,
      );

      window.cio_analytics?.identify(authUser._id, {
        email: authUser.companyEmail,
        first_name: authUser.firstName,
        last_name: authUser.lastName,
      });
    }
  }, [authUser?._id]);

  const inSettings = window.location.pathname?.includes(SETTINGS);
  const inJoinWorkspace = window.location.pathname?.includes('/join');

  const lastUserNotificationRef = useRef<any>(null);
  useEffect(() => {
    if (lastUserNotification && lastUserNotification !== null) {
      lastUserNotificationRef.current = lastUserNotification;
      setObjectId(lastUserNotificationRef?.current?.objectId);
      setNotificationId(lastUserNotificationRef?.current?._id);
    }
  }, [lastUserNotification]);
  // const dispatch = useDispatch();

  // To dispatch the action:
  const openOrderDetail = () => {
    setOpenOrderDetailsDrawer(true);
    readActivities({ activityIds: [notificationId] });
    handleCloseUserNotification();
    dispatch(appActions.setdecreaseImportantActivities());
  };
  const currentPath = window.location.pathname;
  const excludePaths = useMemo(() => [ROUNDTRIP_ADD], []);
  const showNavMenu = useMemo(() => {
    return (
      authToken &&
      !zoomedIn &&
      !inJoinWorkspace &&
      !excludePaths.includes(currentPath)
    );
  }, [authToken, zoomedIn, inJoinWorkspace, currentPath, excludePaths]);

  const showAppHeader = useMemo(() => {
    return (
      authToken &&
      (!zoomedIn || !!inSettings) &&
      !inJoinWorkspace &&
      !excludePaths.includes(currentPath)
    );
  }, [
    authToken,
    zoomedIn,
    inJoinWorkspace,
    currentPath,
    excludePaths,
    inSettings,
  ]);

  const [prevApplication, setPrevApplication] = useState<Application | null>(
    null,
  );
  const [prevAppColor, setPrevAppColor] = useState<string>('');
  const [showProgress, setShowProgress] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (prevApplication?.id) {
      setPrevAppColor(themes.default[`${prevApplication.id}Primary`]);

      setShowProgress(false);
      setAnimationKey((prev) => prev + 1);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      setShowProgress(true);
      timeoutRef.current = window.setTimeout(() => {
        setShowProgress(false);
      }, 4000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [prevApplication]);

  const isOrderRelatedNotification =
    lastUserNotification?.activityName === 'order-status-updated' ||
    lastUserNotification?.activityName === 'collect_amount_delivery';

  // @ts-ignore
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Bamptee"
        defaultTitle="Bamptee"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>
      <AbilityProvider>
        <ModalProvider>
          <GuardedActionProvider>
            <Fade in timeout={500}>
              <Wrapper>
                {showNavMenu ? (
                  <MainNav setPrevApplication={setPrevApplication} />
                ) : null}
                <If condition={showProgress}>
                  <Portal>
                    <ProgressBarContainer>
                      <CustomProgressBar
                        className={`${showProgress ? 'active' : ''}`}
                        key={animationKey}
                        leftColor={prevAppColor}
                        rightColor={theme.primary}
                      />
                    </ProgressBarContainer>
                  </Portal>
                </If>
                <AppWrapper>
                  {showAppHeader ? <AppHeader /> : null}
                  <AppContent showNav={!zoomedIn}>
                    <Routes>
                      <Route path={LOGIN} element={<LoginPage />} />
                      <Route path={SIGNIN} element={<SigninPage />} />
                      <Route path={SIGNUP} element={<SignUpEmailPage />} />
                      <Route
                        path={VERIFICATION_LINK}
                        element={<VerificationLink />}
                      />
                      <Route path={SIGNUP_NAME} element={<SignUpNamePage />} />
                      <Route
                        path={SIGNUP_DESCRIPTION}
                        element={<JoinWorkspace />}
                      />
                      <Route
                        path={SIGNUP_DESCRIPTION_DETAILS}
                        element={<SignUpDetailsPage />}
                      />
                      <Route path={OTP} element={<ResetPasswordOtpPage />} />
                      <Route path={RESET} element={<ResetPasswordPage />} />
                      <Route path={LOGOUT} element={<LogoutPage />} />
                      <Route
                        path={VISITORS_REGISTRATION}
                        element={<VisitorsPublic />}
                      />
                      <Route path={VISITORS_QR_CODE} element={<VisitorsCode />} />

                      <Route
                        path={VISITORS_ORGANIZATION_VISIT}
                        element={<VisitorsCancelVisit />}
                      />

                      <Route
                        path="ai-control-center"
                        element={
                          <PrivateRoute>
                            <AiControlCenterPage />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path={CUSTOMER_PUBLIC}
                        element={<CustomerPublic defaultToken={null} />}
                      />

                      <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
                      <Route path={CONTACT_US} element={<ContactUs />} />

                      {/* IDM Routes*/}
                      <Route element={<ApplicationRoute application="idm" />}>
                        <Route path="settings" element={<Settings />}>
                          <Route
                            index
                            path={COMPANYPROFIL}
                            element={
                              <PrivateRoute>
                                <CompanyProfile />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path={LANGUAGE}
                            element={
                              <PrivateRoute>
                                <LanguageCurrency />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path={SETTINGS_USERS}
                            element={
                              <PrivateRoute>
                                <UserProvider>
                                  <UsersPageSettings />
                                </UserProvider>
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path={USERINFO}
                            element={
                              <PrivateRoute>
                                <UserInfo />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path={ROLES_PERMISSIONS}
                            element={
                              <PrivateRoute>
                                <GridProvider>
                                  <RoleProvider>
                                    <RolesPermissions />
                                  </RoleProvider>
                                </GridProvider>
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={SETTINGS_CUSTOMIZATION}
                            element={
                              <PrivateRoute>
                                <CustomizationPage />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={SETTINGS_ROUNDTRIP_MAPPING}
                            element={
                              <PrivateRoute>
                                <RoundtripMapping />
                              </PrivateRoute>
                            }
                          />
                        </Route>

                        <Route
                          path={ACCESS_DENIED}
                          element={<AccessDeniedPage />}
                        />

                        <Route path={NOT_FOUND} element={<NotFoundPage />} />
                      </Route>

                      {/* Bianta Routes */}
                      <Route element={<ApplicationRoute application="bianta" />}>
                        <Route
                          path={UPLOAD_FILES}
                          element={
                            <PrivateRoute>
                              <UploadFiles />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={CHECK_DOCUMENTS}
                          element={
                            <PrivateRoute>
                              <CheckDocuments />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={PAYMENTS}
                          element={
                            <PrivateRoute>
                              <Transactions />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={DASHBOARD_ALL}
                          element={
                            <PrivateRoute>
                              <DashboardPage allOrganizations />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={AUDIT}
                          element={
                            <PrivateRoute>
                              <AuditPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={ROUNDTRIP}
                          element={
                            <PrivateRoute>
                              <RoundtripsTabs />
                            </PrivateRoute>
                          }
                        >
                          <Route
                            path={SCHEDULED_ROUNDTRIPS}
                            element={
                              <PrivateRoute>
                                <RoundTrips />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={ALL_ROUNDTRIPS}
                            element={
                              <PrivateRoute>
                                <AllRoundTrips />
                              </PrivateRoute>
                            }
                          />
                        </Route>
                        <Route
                          path={SUPPORTUNIT}
                          element={
                            <PrivateRoute>
                              <SupportUnits />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={ANALYTICS}
                          element={
                            <PrivateRoute>
                              <AnalyticsPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={INBOX}
                          element={
                            <PrivateRoute>
                              <InboxPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={ACCESSORIES}
                          element={
                            <PrivateRoute>
                              <Accessories />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={`${ACCESSORYINFO}`}
                          element={
                            <PrivateRoute>
                              <AccessoryInfo />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={TOOLS}
                          element={
                            <PrivateRoute>
                              <Tools />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={PAYMENTCARDS}
                          element={
                            <PrivateRoute>
                              <PaymentCards />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={VIEWCUSTOMER}
                          element={
                            <PrivateRoute>
                              <ViewCustomer />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={REPORTS}
                          element={
                            <PrivateRoute>
                              <ReportsPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={REPORTS_ALL}
                          element={
                            <PrivateRoute>
                              <ReportsPage allOrganizations />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={ROUNDTRIP_ADD}
                          element={
                            <PrivateRoute>
                              <AddRoundTrips />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={USERPAGE}
                          element={
                            <PrivateRoute>
                              <UsersPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={`/${EMPLOYEEINFO}`}
                          element={
                            <PrivateRoute>
                              <UserInfo />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={`${VEHICLES}/logistic`}
                          element={
                            <PrivateRoute>
                              <VehiclesPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={`${VEHICLES}/sales`}
                          element={
                            <PrivateRoute>
                              <VehiclesPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={ORDERS}
                          element={
                            <PrivateRoute>
                              <OrdersPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={CUSTOMER}
                          element={
                            <PrivateRoute>
                              <CustomersPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={DASHBOARD}
                          element={
                            <PrivateRoute>
                              <DashboardPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={CUSTOMERBRANCH}
                          element={
                            <PrivateRoute>
                              <CustomerBranch />
                            </PrivateRoute>
                          }
                        />

                        <Route path={NOT_FOUND} element={<NotFoundPage />} />

                        <Route
                          path={ACCESS_DENIED}
                          element={<AccessDeniedPage />}
                        />
                      </Route>

                      {/* Ordoria Routes */}
                      <Route element={<ApplicationRoute application="ordoria" />}>
                        <Route path={ORDORIA}>
                          <Route
                            index
                            element={
                              <PrivateRoute>
                                {authCustomer ? (
                                  <OrdoriaPortalDahsboardPage />
                                ) : (
                                  <DashboardPage />
                                )}
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="portal"
                            element={
                              <PrivateRoute>
                                {authCustomer ? (
                                  <CustomerPublic
                                    defaultToken={authCustomer?.token}
                                  />
                                ) : null}
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path={ORDORIA_CALL_PLAN}
                            element={
                              <PrivateRoute>
                                <OrdoriaCallPlan />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={ORDORIA_CATALOG}
                            element={
                              <PrivateRoute>
                                <CatalogPage />
                              </PrivateRoute>
                            }
                          />
                        </Route>
                        <Route
                          path={ORDERS_ACTIONS}
                          element={
                            <PrivateRoute>
                              <OrdersActionPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={ORDORIA_BALANCE}
                          element={
                            <PrivateRoute>
                              <BalancePage />
                            </PrivateRoute>
                          }
                        />
                        <Route index={false} path="ordoria/orders">
                          <Route
                            path={ORDORIA_CREATE_ORDERS}
                            element={
                              <PrivateRoute>
                                <ViewEditOrderPage />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={ORDORIA_VIEW_ODER_DETAIL}
                            element={
                              <PrivateRoute>
                                <OrdoriaDetailPage />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={ORDORIA_SALES_VIEW_ORDER}
                            element={
                              <PrivateRoute>
                                <SalesOrderDetails />
                              </PrivateRoute>
                            }
                          />
                        </Route>

                        <Route
                          path={`${ORDORIA}${DASHBOARD_ALL}`}
                          element={
                            <PrivateRoute>
                              <DashboardPage allOrganizations />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={ORDORIA_VIEWCUSTOMER}
                          element={
                            <PrivateRoute>
                              <ViewCustomer />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={ORDORIA_CUSTOMER}
                          element={
                            <PrivateRoute>
                              <CustomersPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={`/ordoria${REPORTS}`}
                          element={
                            <PrivateRoute>
                              <ReportsPage />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={`/ordoria${REPORTS_ALL}`}
                          element={
                            <PrivateRoute>
                              <ReportsPage allOrganizations />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={ORDORIA_CLAIMS}
                          element={
                            <PrivateRoute>
                              <ClaimsPage />
                            </PrivateRoute>
                          }
                        />

                        {/**
                       * <Route
                        path={ORDORIA_TRANSACTIONS}
                        element={
                          <PrivateRoute>
                            <MultiplePayments />
                          </PrivateRoute>
                        }
                      />
                      */}

                        <Route
                          path={ORDORIA_INVOICES}
                          element={
                            <PrivateRoute>
                              <Invoices />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={ORDORIA_REFUNDS}
                          element={
                            <PrivateRoute>
                              <Refunds />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={ORDORIA_PAYMENTS}
                          element={
                            <PrivateRoute>
                              <Payments />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={ORDORIA_TRANSACTIONS_ACCOUNTING}
                          element={
                            <PrivateRoute>
                              <Transactions />
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={ACCESS_DENIED}
                          element={<AccessDeniedPage />}
                        />

                        <Route path={NOT_FOUND} element={<NotFoundPage />} />
                      </Route>
                      {/* STOCKEE Routes */}
                      <Route element={<ApplicationRoute application="stockee" />}>
                        <Route
                          path={STORAGELOCATIONS}
                          element={
                            <PrivateRoute>
                              <GridProvider>
                                <StorageLocationPage />
                              </GridProvider>
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={STORAGELOCATIONINFO}
                          element={
                            <PrivateRoute>
                              <GridProvider>
                                <StorageLoadingInfoPage />
                              </GridProvider>
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={STOCKEE_CUSTOMER}
                          element={
                            <PrivateRoute>
                              <CustomersPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={STOCKEE_CLIENT_GROUP}
                          element={
                            <PrivateRoute>
                              <ClientGroupsPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={STOCKEE_PRICING_GROUP}
                          element={
                            <PrivateRoute>
                              <PricingGroupsPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={STOCKEE_DISCOUNT_GROUP}
                          element={
                            <PrivateRoute>
                              <DiscountGroupsPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={STOCKEE_CATEGORIES}
                          element={
                            <PrivateRoute>
                              <CategoriesPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={STOCKEE_VIEWCUSTOMER}
                          element={
                            <PrivateRoute>
                              <ViewCustomer />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path={STOCKEE_PRODUCTS}
                          element={
                            <PrivateRoute>
                              <GridProvider>
                                <ProductsPage />
                              </GridProvider>
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={STOCKEE_PRODUCTS_INFO}
                          element={
                            <PrivateRoute>
                              <GridProvider>
                                <ProductsDetailsPage />
                              </GridProvider>
                            </PrivateRoute>
                          }
                        />

                        <Route
                          path={ACCESS_DENIED}
                          element={<AccessDeniedPage />}
                        />
                        <Route
                          path={STOCKEE_SUPPLIERS}
                          element={<SuppliersPage />}
                        />

                        <Route
                          path={VIEWSUPPLIER}
                          element={
                            <PrivateRoute>
                              <ViewSupplier />
                            </PrivateRoute>
                          }
                        />
                      </Route>

                      {/* IAM Routes */}
                      <Route element={<ApplicationRoute application="iam" />}>
                        <Route path={IAM}>
                          <Route
                            path={VISITORS}
                            element={
                              <PrivateRoute>
                                <VisitorsPage />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path={VISITORS_QR_CODE}
                            element={
                              <PrivateRoute>
                                <VisitorsCode />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path={USERPAGE}
                            element={
                              <PrivateRoute>
                                <UsersPage />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path={EMPLOYEEINFO}
                            element={
                              <PrivateRoute>
                                <UserInfo />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path={DOCUMENTS}
                            element={
                              <PrivateRoute>
                                <Documents />
                              </PrivateRoute>
                            }
                          />
                        </Route>
                        <Route
                          path={ACCESS_DENIED}
                          element={<AccessDeniedPage />}
                        />
                      </Route>
                    </Routes>

                    {authUser && isEmpty(authUser?.currentAgent?._customer) && (
                      <VoiceCall authUser={authUser} />
                    )}

                    {lastToasterNotification ? (
                      <Portal>
                        <CustomSnackBar
                          open
                          autoHideDuration={lastToasterNotification.duration}
                          onClose={handleCloseNotification}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          TransitionComponent={SlideTransition}
                          message={lastToasterNotification.message}
                          showNav={!zoomedIn}
                          isMinify={isMinify}
                        >
                          <Alert
                            variant="standard"
                            onClose={handleCloseNotification}
                            severity={lastToasterNotification.severity}
                          >
                            {(!!lastToasterNotification.content &&
                              isValidElement(lastToasterNotification.content) &&
                              lastToasterNotification.content) ||
                              lastToasterNotification.message}
                          </Alert>
                        </CustomSnackBar>
                      </Portal>
                    ) : null}

                    {lastUserNotification?.content ? (
                      <Portal>
                        <CustomSnackBar
                          open
                          onClose={handleCloseUserNotification}
                          autoHideDuration={lastUserNotification.duration}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          TransitionComponent={SlideTransition}
                          showNav={!zoomedIn}
                          isMinify={isMinify}
                          sx={{ position: 'absolute' }}
                        >
                          <div>
                            {isOrderRelatedNotification ? (
                              <Notification
                                onClose={handleCloseUserNotification}
                                content={lastUserNotification.content}
                                url={lastUserNotification.url}
                                activityName={lastUserNotification.activityName}
                                openOrderDetail={openOrderDetail}
                                activityKey={lastUserNotification?.activityKey}
                              />
                            ) : (
                              <Notification
                                onClose={handleCloseUserNotification}
                                content={lastUserNotification.content}
                                url={lastUserNotification.url}
                                activityName={lastUserNotification.activityName}
                                activityKey={lastUserNotification.activityKey}
                              />
                            )}
                          </div>
                        </CustomSnackBar>
                      </Portal>
                    ) : null}

                    <StatusToasterNotification />

                    <OrderDetails
                      open={openOrderDetailsDrawer}
                      onClose={() => {
                        setOpenOrderDetailsDrawer(false);
                        setObjectId('');
                        setNotificationId('');
                      }}
                      orderId={objectId}
                    />
                    <Cart
                      setOpenCreateOrderDrawer={setOpenCreateOrderDrawer}
                      setCartSelectedProducts={setCartSelectedProducts}
                    />
                    <ManagedCreateOrderDrawer
                      open={openCreateOrderDrawer}
                      onClose={() => setOpenCreateOrderDrawer(false)}
                      selectedProducts={cartselectedProducts}
                    />
                    <audio
                      src={BellNotificationSound}
                      ref={notificationAudioRef}
                    />

                    <If condition={authUser && authUser?.currentAgent?._customer}>
                      <Portal>
                        <FeaturesPopup />
                      </Portal>
                    </If>
                  </AppContent>
                </AppWrapper>
              </Wrapper>
            </Fade>
          </GuardedActionProvider>
        </ModalProvider>
      </AbilityProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  overflow: auto;
  background: ${(props) => props.theme.roundtripGreyBg};
`;

const AppWrapper = styled.div`
  height: 100vh;
  width: 100%;
  z-index: 0;
  overflow: hidden;
`;

interface AppContentProps {
  showNav: boolean;
  isMinify?: boolean;
}
const AppContent = styled.div<AppContentProps>`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  padding-top: ${(p) => (p.showNav ? p.theme.topBarHeight : 'unset')};
  position: relative;
`;

const CustomSnackBar = styled(Snackbar)<AppContentProps>((props) => ({
  top: props.showNav
    ? `calc(${props.theme.topBarHeight} + 2px) !important`
    : `calc(${props.theme.tabsBarHeight} + 2px) !important`,
  marginRight: '-6px',
}));

const gradient = keyframes`
  0% { 
    width: 0;
   }
  50% {
    width: 100%;
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const ProgressBarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 5px;
  overflow: hidden;
  .active {
    animation: ${gradient} 2s forwards ease-in-out;
  }
`;

const CustomProgressBar = styled.div<{ leftColor: string; rightColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    to right,
    ${(props) => props.leftColor} 0%,
    ${(props) => props.rightColor} 100%
  );
`;
