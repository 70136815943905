import { styled } from '@material-ui/core';
import NoResultsIcon from 'assets/img/no-results.svg';
import NoResultsIconOrdoria from 'assets/img/ORDORIA/no-results.svg';
import { useApplication } from 'hooks/useApplication';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  icon?: string;
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  titleStyle?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
}

const Title = styled('p')({
  fontWeight: 500,
  fontSize: '1.25rem',
  lineHeight: '160%',
  textAlign: 'center',
  letterSpacing: '0.15px',
});

const SubTitle = styled('p')({
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '143%',
  textAlign: 'center',
  letterSpacing: '0.17px',
});

export const NoResults: React.FC<Props> = ({
  icon,
  title = 'no_results',
  subTitle = 'try_adjusting_filters',
  children,
  titleStyle,
  imgStyle,
}) => {
  const currentApplication = useApplication();

  const { t } = useTranslation();
  title = t(title);
  subTitle = t(subTitle);

  const ApplicationIcon = useMemo(() => {
    if (icon) {
      return icon;
    }
    return currentApplication?.id === 'ordoria'
      ? NoResultsIconOrdoria
      : NoResultsIcon;
  }, [currentApplication, icon]);

  return (
    <>
      <img style={{ ...imgStyle }} src={ApplicationIcon} alt="no-results" />
      <Title style={{ ...titleStyle }}>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      {children}
    </>
  );
};

export default NoResults;
