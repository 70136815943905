import { FormWrapper } from 'app/components/Form/styles';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRestrictionsHelpers } from './hooks/useRestrictionsHelpers';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import {
  validateInputNumberPaste,
  validateInputNumberPostive,
} from 'app/helpers/helpers';
import { useRestrictionsSingle } from './hooks/useRestrictionsSingle';
import { useRestrictionsContextOf } from './hooks/useRestrictionsContextOf';
import { OrderAlert } from 'app/pages/Ordoria/ViewEditOrder/components/OrderAlert';
import { useSelector } from 'react-redux';
import { useModal } from '../Dialog/hooks';
import { selectTheme } from 'styles/theme/slice/selectors';
import { IRestrictionsContext } from './restrictions.consts';
import { DiscountIndex } from './types/discounts.types';

const initialInfo = Object.freeze({
  show: false,
  i: 1,
} as IRestrictionsContext['maxModal']);

interface RestrictMaxModalProps {
  title?: string;
  hasLimit?: boolean;
  checkMaxExceeded?: (max: number, i: DiscountIndex) => boolean;
}

export const RestrictMaxModal = ({
  title = 'DiscountGroups.max_discount_title',
  hasLimit = false,
  checkMaxExceeded,
}: RestrictMaxModalProps) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const { openModal, closeModal } = useModal();

  const { value: modalInfo, set: setModalInfo } = useRestrictionsContextOf(
    'maxModal',
    initialInfo,
  );
  const info = useMemo(() => modalInfo!, [modalInfo]);

  const restrictionsHelpers = useRestrictionsHelpers(info.i);
  const restrictions = useRestrictionsSingle(info.i);

  const [busy, setBusy] = useState(false);
  const [maxValue, setMaxValue] = useState(0);
  const [limitValue, setLimitValue] = useState(0);
  const [isMaxInvalid, setIsMaxInvalid] = useState(false);

  const updateMaxValue = useCallback(
    (value: number) => {
      const newValue = Math.max(0, Math.min(100, value));
      setMaxValue(newValue);
      setIsMaxInvalid(hasLimit && newValue < limitValue);
    },
    [hasLimit, limitValue],
  );

  const updateLimitValue = useCallback(
    (value: number) => {
      const newLimit = Math.max(0, Math.min(maxValue, value));
      setLimitValue(newLimit);
      setIsMaxInvalid(hasLimit && maxValue < newLimit);
    },
    [maxValue, hasLimit],
  );

  const isEditMode = useMemo(
    () => !info.isUnlocking || restrictions.max < 1,
    [info.isUnlocking, restrictions.max],
  );
  const canCancel = useMemo(
    () => !info.isUnlocking && !busy,
    [info.isUnlocking, busy],
  );

  useEffect(() => {
    updateMaxValue((restrictions.max || 1) * 100);
    updateLimitValue(Math.min(restrictions.max, restrictions.limit || 1) * 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.i, restrictions.max, restrictions.limit]);

  const handleScroll = useCallback((ev, updateValue) => {
    const step = ev.deltaY < 0 ? 1 : -1;
    updateValue(step);
  }, []);

  const handleClose = useCallback(
    (force?: boolean) => {
      if (force || canCancel) setModalInfo(initialInfo);
    },
    [canCancel, setModalInfo],
  );

  const formatValue = (value) => parseFloat(value.replace(/\.$/, '') || '0');

  const handleChange = (ev, updateValue) => {
    const value = formatValue(ev.target.value);
    if (isNaN(value)) return ev.preventDefault();
    updateValue(value);
  };

  const handleSubmit = useCallback(async () => {
    closeModal();

    if (!restrictionsHelpers) {
      throw new Error(
        "No permission to edit discounts. If you see this then it's a bug " +
          '(You will never see this normally since the button is only visible if you have permission)',
      );
    }
    setBusy(true);
    const { setMaxAndLimit, max: setMax } = restrictionsHelpers!;

    if (hasLimit) {
      await setMaxAndLimit(maxValue / 100, limitValue / 100);
    } else {
      await setMax(maxValue / 100);
    }

    handleClose(true);
    setBusy(false);
  }, [
    closeModal,
    restrictionsHelpers,
    hasLimit,
    handleClose,
    maxValue,
    limitValue,
  ]);

  const showMaxExceededModal = useCallback(() => {
    openModal({
      title: t('title.reset_discount_values'),
      content: (
        <Stack gap="12px">
          <OrderAlert
            severity="error"
            content={t('col_values.exceed_max', {
              ri: t('discount_abbrev') + info.i,
            })}
            sx={AlertSx}
          />
          <Typography
            fontWeight="400"
            fontSize="1rem"
            lineHeight="24px"
            letterSpacing="0.15px"
            color={theme.textBlack}
          >
            {t('max_value_exceeded.message', {
              ri: t('discount_abbrev') + info.i,
              max: maxValue,
            })}
          </Typography>
        </Stack>
      ),
      action: {
        actionText: t('common.reset'),
        actionCallback: () => handleSubmit(),
        buttonProps: {
          sx: { background: theme.Cinnabar },
          color: 'error',
        },
      },
      hasCloseIcon: true,
      cancel: true,
    });
  }, [
    handleSubmit,
    info.i,
    maxValue,
    openModal,
    t,
    theme.Cinnabar,
    theme.textBlack,
  ]);

  const handleClickSubmit = useCallback(() => {
    let newMaxExceeded = false;

    if (checkMaxExceeded) {
      newMaxExceeded = checkMaxExceeded(maxValue, info.i);
    }

    if (newMaxExceeded) {
      showMaxExceededModal();
    } else {
      handleSubmit();
    }
  }, [checkMaxExceeded, handleSubmit, info.i, maxValue, showMaxExceededModal]);

  return (
    <TwoViewModal
      title={t(title, {
        action: isEditMode ? t('common.edit') : t('common.add'),
      })}
      onOpen={info.show}
      onClose={() => handleClose()}
      defaultMode="floated"
      autoHeight
    >
      <Stack
        justifyContent="stretch"
        component={FormWrapper}
        padding="24px 20px 0px 20px"
        gap="16px"
      >
        {hasLimit ? (
          <OrderAlert
            severity="info"
            content={t('contracts.edit_max_limit_info_message')}
            sx={AlertSx}
          />
        ) : !!info.isUnlocking ? (
          <OrderAlert
            severity="warning"
            content={t('DiscountGroups.max_discount_warning')}
            sx={AlertSx}
          />
        ) : null}

        <Stack gap="16px">
          <FormControlLabel
            sx={{ flexGrow: 1, margin: '0px !important' }}
            value="selling_price"
            label=""
            control={
              <TextField
                fullWidth
                onChange={(ev) => {
                  handleChange(ev, updateMaxValue);
                  ev.stopPropagation();
                }}
                onWheel={(ev) => {
                  handleScroll(ev, updateMaxValue);
                  ev.stopPropagation();
                }}
                label={t('DiscountGroups.max_discount')}
                value={maxValue.toString()}
                disabled={busy}
                type="number"
                onKeyDown={(ev) => {
                  validateInputNumberPostive(ev);
                  ev.stopPropagation();
                }}
                onPaste={(ev) => {
                  validateInputNumberPaste(ev);
                  ev.stopPropagation();
                }}
                InputProps={{
                  inputProps: { min: limitValue, max: 100 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                error={isMaxInvalid}
                helperText={
                  isMaxInvalid
                    ? t('max_discount.max_less_than_limit', {
                        value: limitValue,
                      })
                    : ''
                }
                sx={{ margin: '0 !important' }}
              />
            }
          />
          {hasLimit && (
            <FormControlLabel
              sx={{ flexGrow: 1, margin: '0px !important' }}
              label=""
              control={
                <TextField
                  fullWidth
                  onChange={(ev) => {
                    handleChange(ev, updateLimitValue);
                    ev.stopPropagation();
                  }}
                  onWheel={(ev) => {
                    handleScroll(ev, updateLimitValue);
                    ev.stopPropagation();
                  }}
                  label={t('contracts.limit_to_validate')}
                  value={limitValue.toString()}
                  disabled={busy}
                  type="number"
                  onKeyDown={(ev) => {
                    validateInputNumberPostive(ev);
                    ev.stopPropagation();
                  }}
                  onPaste={(ev) => {
                    validateInputNumberPaste(ev);
                    ev.stopPropagation();
                  }}
                  InputProps={{
                    inputProps: { min: 0, max: 100 },
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  sx={{ margin: '0 !important' }}
                />
              }
            />
          )}
        </Stack>
      </Stack>

      <Stack spacing={1} direction="row">
        <Button
          color="inherit"
          disabled={!canCancel}
          onClick={() => handleClose()}
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={handleClickSubmit}
          disabled={isMaxInvalid || busy}
          autoFocus
        >
          <Box>
            <Collapse in={!busy}>
              <Typography variant="body2">
                {t('common.' + (isEditMode ? 'buttons.save' : 'add'))}
              </Typography>
            </Collapse>
            <Collapse in={busy}>
              <LinearProgress />
            </Collapse>
          </Box>
        </Button>
      </Stack>
    </TwoViewModal>
  );
};

const AlertSx: SxProps = {
  padding: '14px 16px',
  borderRadius: '4px',
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '20px',
  letterSpacing: '0.15px',
};
