import { CustomCellRendererProps } from '@ag-grid-community/react';
import If from 'app/components/If';
import { selectConfiguration } from 'common/store/organization/selectors';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function ClaimReasonCell(params: CustomCellRendererProps) {
  const { i18n } = useTranslation();

  const configuration = useSelector(selectConfiguration);
  const claimReasons = configuration?.claimReasons || [];
  const reasonDetail = params.value.claimReasonDetailData;
  const locale = i18n.language;

  return (
    <If
      condition={reasonDetail}
      otherwise={
        <If condition={!isEmpty(params.value.claimReasonData)} otherwise="_">
          {
            claimReasons.find(
              (reason) => reason.key === params.value.claimReasonData,
            )?.i18n[locale]
          }
        </If>
      }
    >
      {reasonDetail}
    </If>
  );
}

export default ClaimReasonCell;
