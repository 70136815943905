import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  SquaresFour,
  HandCoins,
  PresentationChart,
  Hamburger,
  MapPinLine,
  Package,
  UsersFour,
  UsersThree,
  CurrencyEur,
  Percent,
  ChartPieSlice,
  ListMagnifyingGlass,
} from '@phosphor-icons/react';
import { Can } from 'hooks/Abilities/context';
import { MainNavItem, ScrollableSection, OptionsWrapper } from '../helpers';
import {
  CUSTOMER,
  DASHBOARD_ALL,
  STOCKEE,
  REPORTS,
  REPORTS_ALL,
  STOCKEE_CUSTOMER,
  STOCKEE_CLIENT_GROUP,
  STOCKEE_PRICING_GROUP,
  STOCKEE_DISCOUNT_GROUP,
  STOCKEE_CATEGORIES,
} from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { isActiveRoute } from '../index';
import { IconWrapper } from '../../../../styles/components/muitable/TableViewPopover';
import { MenuTitleWrapper } from '../Components';
import { selectNavMenuPreferences } from 'common/store/app/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, defaultMenuNavItems } from 'common/store/app';
import If from 'app/components/If';
import { MenuNavItem } from '../types';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { sortNavMenuItems } from 'app/helpers/helpers';
const dashboardAll = `${STOCKEE}${DASHBOARD_ALL}`;
const reportAll = `${STOCKEE}${REPORTS_ALL}`;

export default function StockeeMenu({ theme, mode }) {
  const { t } = useTranslation();
  const location = useLocation();
  const isDashboardAll = location.pathname === dashboardAll;
  const isReportAll = location.pathname === reportAll;
  const isMinify = mode === 'minify';
  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>([0]);
  const dispatch = useDispatch();
  const navMenuPreferences = useSelector(selectNavMenuPreferences);

  const canViewDashboard = usePermission('dashboard')(Permission.VIEW);
  const canViewReports = usePermission('reports')(Permission.VIEW);

  const canViewPricing = usePermission('pricing')(Permission.VIEW);
  const canViewDiscount = usePermission('discountgrid')(Permission.VIEW);

  const canViewCategory = usePermission('categories')(Permission.VIEW);
  const canViewProducts = usePermission('products')(Permission.VIEW);
  const canViewStorage = usePermission('storageloction')(Permission.VIEW);
  const canViewStock = usePermission('stock')(Permission.VIEW);

  const canViewCustomers = usePermission('customers')(Permission.VIEW);
  const canViewSuppliers = usePermission('suppliers')(Permission.VIEW);
  const canViewClientGroups = usePermission('clientgroup')(Permission.VIEW);

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [index];
      }
    });
  };

  useEffect(() => {
    if (!navMenuPreferences?.stockee) {
      dispatch(
        appActions.setNavMenuPreferences({
          ...navMenuPreferences,
          stockee: defaultMenuNavItems.stockee,
        }),
      );
    }
  }, [dispatch, navMenuPreferences]);

  const menuMapping = useMemo(() => {
    const stockeePreferences =
      navMenuPreferences?.stockee || defaultMenuNavItems.stockee;

    const updateStore = (key, value) => {
      dispatch(
        appActions.setNavMenuPreferences({
          ...navMenuPreferences,
          stockee: {
            ...navMenuPreferences.stockee,
            [key]: value,
          },
        }),
      );
    };

    const sortedMenuItems = sortNavMenuItems(
      defaultMenuNavItems.stockee.menuItems,
      stockeePreferences.menuItems || [],
      (sorted) => updateStore('menuItems', sorted),
    );

    const sortedMenuAllItems = sortNavMenuItems(
      defaultMenuNavItems.stockee.menuAllItems,
      stockeePreferences.menuAllItems || [],
      (sorted) => updateStore('menuAllItems', sorted),
    );

    return {
      main:
        isDashboardAll || isReportAll ? sortedMenuAllItems : sortedMenuItems,
      stock: sortNavMenuItems(
        defaultMenuNavItems.stockee.stockItems,
        stockeePreferences.stockItems || [],
        (sorted) => updateStore('stockItems', sorted),
      ),
      relations: sortNavMenuItems(
        defaultMenuNavItems.stockee.relationsItems,
        stockeePreferences.relationsItems || [],
        (sorted) => updateStore('relationsItems', sorted),
      ),
      pricing: sortNavMenuItems(
        defaultMenuNavItems.stockee.pricingItems,
        stockeePreferences.pricingItems || [],
        (sorted) => updateStore('pricingItems', sorted),
      ),
    };
  }, [dispatch, navMenuPreferences, isDashboardAll, isReportAll]);

  const MenuNavItems = useMemo(() => {
    const items: MenuNavItem[] = [
      {
        id: 'stockeeMenu1',
        title: t('nav.mainNav.overview'),
        onClick: () => handleRowToggle(0),
        items: menuMapping.main,
      },
    ];

    if (!(isDashboardAll || isReportAll)) {
      items.push(
        {
          id: 'stockeeMenu2',
          title: t('nav.mainNav.stock'),
          onClick: () => handleRowToggle(1),
          items: menuMapping.stock,
        },
        {
          id: 'stockeeMenu3',
          title: t('nav.mainNav.relations'),
          onClick: () => handleRowToggle(2),
          items: menuMapping.relations,
        },
        {
          id: 'stockeeMenu4',
          title: t('nav.main.pricing'),
          onClick: () => handleRowToggle(3),
          items: menuMapping.pricing,
        },
      );
    }

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, menuMapping, isDashboardAll, isReportAll]);

  const hasMainViewPermission = (id: string) => {
    let hasViewPermission = false;

    switch (id) {
      case 'stockeeMenu1':
        hasViewPermission = canViewDashboard || canViewReports;
        break;
      case 'stockeeMenu2':
        hasViewPermission =
          canViewProducts || canViewStorage || canViewStock || canViewCategory;
        break;
      case 'stockeeMenu3':
        hasViewPermission =
          canViewCustomers || canViewSuppliers || canViewClientGroups;
        break;
      case 'stockeeMenu4':
        hasViewPermission = canViewPricing || canViewDiscount;
        break;
    }
    return hasViewPermission;
  };

  const getToById = (id: string): string => {
    console.log('getToById', id);
    switch (id) {
      case 'dashboardAll':
        return dashboardAll;

      case 'reportAll':
        return reportAll;

      case 'dashboard':
        return STOCKEE;

      case 'reports':
        return `/stockee${REPORTS}`;

      case 'categories':
        return STOCKEE_CATEGORIES;
      case 'products':
        return '/stockee/products';

      case 'storagelocation':
        return '/stockee/storage';

      case 'stock':
        return '/stockee/stock';

      case 'suppliers':
        return '/stockee/suppliers';

      case 'clientgroup':
        return STOCKEE_CLIENT_GROUP;

      case 'customers':
        return STOCKEE_CUSTOMER;

      case 'pricing':
        return STOCKEE_PRICING_GROUP;

      case 'discountgrid':
        return STOCKEE_DISCOUNT_GROUP;

      default:
        return STOCKEE;
    }
  };

  const getTitleById = (id: string): string => {
    switch (id) {
      case 'dashboardAll':
        return t('nav.mainNav.dashboard');

      case 'reportAll':
        return t('nav.mainNav.reports');

      case 'dashboard':
        return t('nav.mainNav.dashboard');

      case 'reports':
        return t('nav.mainNav.reports');

      case 'categories':
        return t('nav.mainNav.categories');

      case 'products':
        return t('nav.mainNav.products');

      case 'storagelocation':
        return t('nav.mainNav.storage_location');

      case 'stock':
        return t('nav.mainNav.stock');

      case 'suppliers':
        return t('nav.mainNav.suppliers');

      case 'clientgroup':
        return t('nav.mainNav.client_group');

      case 'customers':
        return t('nav.mainNav.clients');

      case 'pricing':
        return t('nav.mainNav.pricing');

      case 'discountgrid':
        return t('nav.mainNav.discount_grid');

      default:
        return t('nav.mainNav.dashboard');
    }
  };

  const getIconById = (id: string): JSX.Element => {
    switch (id) {
      case 'dashboardAll':
        return (
          <Icon
            icon={<SquaresFour />}
            color={
              location.pathname === dashboardAll
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'reportAll':
        return (
          <IconWrapper isActive={isActiveRoute(location, reportAll)}>
            <PresentationChart />
          </IconWrapper>
        );

      case 'dashboard':
        return (
          <Icon
            icon={<ChartPieSlice />}
            color={
              location.pathname === '/stockee'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'reports':
        return (
          <IconWrapper isActive={isActiveRoute(location, REPORTS)}>
            <PresentationChart
              color={
                location.pathname === '/stockee/reports'
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
              }
            />
          </IconWrapper>
        );
      case 'categories':
        return (
          <Icon
            icon={<ListMagnifyingGlass />}
            color={
              location.pathname === STOCKEE_CATEGORIES
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'products':
        return (
          <IconWrapper isActive={isActiveRoute(location, CUSTOMER)}>
            <Hamburger
              color={
                location.pathname === '/stockee/products'
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
              }
            />
          </IconWrapper>
        );

      case 'storagelocation':
        return (
          <MapPinLine
            color={
              location.pathname === '/stockee/storage'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
          />
        );

      case 'stock':
        return (
          <Package
            color={
              location.pathname === '/stockee/stock'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
          />
        );

      case 'suppliers':
        return (
          <Icon
            icon={<HandCoins />}
            color={
              location.pathname === '/stockee/suppliers'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'clientgroup':
        return (
          <Icon
            icon={<UsersFour />}
            color={
              location.pathname === STOCKEE_CLIENT_GROUP
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'customers':
        return (
          <Icon
            icon={<UsersThree />}
            color={
              location.pathname === '/stockee/clients'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'pricing':
        return (
          <Icon
            icon={<CurrencyEur />}
            color={
              location.pathname === STOCKEE_PRICING_GROUP
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'discountgrid':
        return (
          <Icon
            icon={<Percent />}
            color={
              location.pathname === STOCKEE_DISCOUNT_GROUP
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      default:
        return (
          <Icon
            icon={<ChartPieSlice />}
            color={
              location.pathname === '/stockee'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );
    }
  };

  const handleDragEnd = (result) => {
    if (
      !result?.destination ||
      result.source.index === result.destination.index ||
      result.source.droppableId !== result.destination.droppableId
    )
      return;

    const menuMappingById = {
      'droppable-nav-menu-0':
        isDashboardAll || isReportAll ? 'menuAllItems' : 'menuItems',
      'droppable-nav-menu-1': 'stockItems',
      'droppable-nav-menu-2': 'relationsItems',
      'droppable-nav-menu-3': 'pricingItems',
    };

    const menuType = menuMappingById[result.destination.droppableId];

    const currentArrangement = [...navMenuPreferences.stockee[menuType]];
    const [movedItem] = currentArrangement.splice(result.source.index, 1);
    currentArrangement.splice(result.destination.index, 0, movedItem);

    dispatch(
      appActions.setNavMenuPreferences({
        ...navMenuPreferences,
        stockee: {
          ...navMenuPreferences.stockee,
          [menuType]: currentArrangement,
        },
      }),
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <ScrollableSection>
        {MenuNavItems.map(
          (menu, menuIndex) =>
            hasMainViewPermission(menu.id) && (
              <Droppable
                droppableId={`droppable-nav-menu-${menuIndex}`}
                key={`menu-${menuIndex}`}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <OptionsWrapper
                      withoutBorder={menuIndex >= MenuNavItems.length - 1}
                    >
                      <MenuTitleWrapper
                        isMinify={isMinify}
                        onClick={menu.onClick}
                        openSectionIndex={openSectionIndex}
                        theme={theme}
                        title={menu.title}
                        index={menuIndex}
                      >
                        {menu?.items?.map((item, itemIndex) => (
                          <Can I="canview" a={item}>
                            <If
                              condition={
                                item === 'customers' || item === 'reports'
                              }
                              otherwise={
                                <Draggable
                                  draggableId={`${item}-${menuIndex}-${itemIndex}`}
                                  index={itemIndex}
                                  key={`${item}-${menuIndex}-${itemIndex}`}
                                >
                                  {(draggableProvided) => (
                                    <div
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                      style={{
                                        ...draggableProvided.draggableProps
                                          .style,
                                        top: 'auto !important',
                                        left: 'auto !important',
                                      }}
                                    >
                                      <MainNavItem
                                        to={getToById(item)}
                                        text={getTitleById(item)}
                                        mode={mode}
                                        icon={getIconById(item)}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              }
                            >
                              <Can I="canview" a={item}>
                                <Draggable
                                  draggableId={`${item}-${menuIndex}-${itemIndex}`}
                                  index={itemIndex}
                                  key={`${item}-${menuIndex}-${itemIndex}`}
                                >
                                  {(draggableProvided) => (
                                    <div
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                      {...draggableProvided.dragHandleProps}
                                      style={{
                                        ...draggableProvided.draggableProps
                                          .style,
                                        top: 'auto !important',
                                        left: 'auto !important',
                                      }}
                                    >
                                      <MainNavItem
                                        to={getToById(item)}
                                        text={getTitleById(item)}
                                        mode={mode}
                                        icon={getIconById(item)}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              </Can>
                            </If>
                          </Can>
                        ))}
                        {provided.placeholder}
                      </MenuTitleWrapper>
                    </OptionsWrapper>
                  </div>
                )}
              </Droppable>
            ),
        )}
      </ScrollableSection>
    </DragDropContext>
  );
}
