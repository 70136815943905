import React, { useEffect, useState, useRef } from 'react';
import DOMPurify from 'dompurify';
import TypingIndicator from './TypingIndicator';
import Typewriter, { TypewriterClass } from 'typewriter-effect';

import { ChatbotContainer, ChatbotMessage } from 'styles/aiaStyles';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import { AIATable, ScrollableSection } from './AIATable';
import { keyframes } from 'styled-components';
import { Typography } from '@mui/material';

interface ChatbotResponseProps {
  message: { text?: string; tables?: { title?: string; data: any[] }[] };
  isTyping: boolean;
  isLoading: boolean;
  stopChatBtn: () => void;
}

const ChatbotResponse: React.FC<ChatbotResponseProps> = ({
  message,
  isTyping,
  isLoading,
  stopChatBtn,
}) => {
  const [isTextComplete, setIsTextComplete] = useState(false);
  const typewriterRef = useRef<TypewriterClass | null>(null);
  const [localMessage, setLocalMessage] = useState(message);

  useEffect(() => {
    setLocalMessage(message);
    setIsTextComplete(false);
  }, [message]);

  const formatMessage = (text: string) => {
    const listPattern = /(\n)/;
    if (listPattern.test(text)) {
      const items = text
        .split(listPattern)
        .filter((line) => line.trim() !== '')
        .map((line, index) => {
          if (line.trim() === '') return null;
          const sentence = line.trim();
          const words = sentence.split(' ');
          const wareHouseName = words.slice(0, 2).join(' ');
          const remainingWords = words.slice(2).join(' ');
          const content =
            index === 0 ? (
              <Typography
                variant="h6"
                component="strong"
                sx={{
                  lineHeight: '18.59px',
                  letterSpacing: '0.17px',
                  textAlign: 'left',
                  fontSmooth: 'always',
                }}
              >
                {sentence}
              </Typography>
            ) : (
              <>
                <StyledSpan key={`span-${index}`}>
                  <strong>{wareHouseName}</strong>
                </StyledSpan>
                {remainingWords}
              </>
            );
          return <LI key={index}>{content}</LI>;
        })
        .filter(Boolean);
      const ulElement = <UL>{items}</UL>;
      return ReactDOMServer.renderToStaticMarkup(ulElement);
    }
    return text;
  };

  useEffect(() => {
    if (!isTyping && typewriterRef.current) {
      typewriterRef.current.stop();
    }

    if (!isTyping && !typewriterRef.current) {
      setIsTextComplete(true);
    }
  }, [message, isTyping]);

  const headers = (tableData: any[]) =>
    tableData.length > 0 ? Object.keys(tableData[0]) : [];

  return (
    <ChatbotContainer>
      <ChatbotMessage style={{ minHeight: '2rem' }}>
        {isLoading ? (
          <TypingIndicator />
        ) : (
          <>
            {localMessage.text && (
              <Typewriter
                options={{ delay: 30, cursor: '' }}
                onInit={(typewriter) => {
                  typewriterRef.current = typewriter;
                  typewriter
                    .typeString(
                      DOMPurify.sanitize(
                        formatMessage(localMessage.text || ''),
                      ),
                    )
                    .callFunction(() => {
                      setIsTextComplete(true);
                      stopChatBtn();
                    })
                    .start();
                }}
              />
            )}
          </>
        )}
      </ChatbotMessage>
      {isTextComplete && localMessage.tables && (
        <TableContainer>
          {localMessage.tables.map((table, index) => (
            <TableContainer key={index}>
              {table.title && (
                <Typography
                  variant="h6"
                  component="strong"
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    lineHeight: '18.59px',
                    letterSpacing: '0.17px',
                    textAlign: 'left',
                  }}
                >
                  {table.title}
                </Typography>
              )}
              <ScrollableSection>
                <AIATable headers={headers(table.data)} data={table.data} />
              </ScrollableSection>
            </TableContainer>
          ))}
        </TableContainer>
      )}
    </ChatbotContainer>
  );
};

export default ChatbotResponse;

export const StyledSpan = styled.span`
  background: var(--Colors-Grey-100, #f5f5f5);
  width: 20px;
  height: 16px;
  padding: 4px 6px 4px 4px;
  border-radius: 4px;
  font-weight: 500;
  line-height: 18.59px;
  letter-spacing: 0.17px;
`;

export const LI = styled.li`
  margin-bottom: 0.3rem;
`;
export const UL = styled.ul`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 18.59px;
  letter-spacing: 0.17px;
  text-align: left;
  margin-bottom: -1rem;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  animation: ${fadeIn} 1.5s ease-in-out;
  margin-bottom: 0.4rem;
  padding: 0 0.3125rem 0 0.3125rem;
`;

// const ScrollableTableWrapper = styled.div`
//   overflow-x: auto;
// `;
