import styled from 'styled-components';

// SIde Bar
export const Sidebar = styled.div`
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  min-width: 31rem;
  width: 28vw;
  height: 95vh;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.baseWhite};
  border: 0.25rem solid transparent;
  border-radius: 1.25rem;
  box-shadow: -1.5rem 1.5rem 3rem -0.75rem #674daf57;
  z-index: 10;
  background-image: ${({ theme }) => theme.borderSidedBar};
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-image-slice: 1;
  overflow: hidden;
`;
/* Side Bar Header Section */

export const SidebarHeader = styled.div`
  width: 100%;
  height: 3.2rem;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.lightGrey3};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  position: absolute;
  border-radius: 1.25rem 1.25rem 0 0;
  top: 0;
  left: 0;
  z-index: 10;
  background: ${({ theme }) => theme.baseWhite};
`;

export const HeaderContent = styled.div`
  max-height: 1.5rem;
  width: 5.875rem;
  gap: 0.3rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition:
    max-height 0.3s ease-in-out,
    width 0.3s ease-in-out;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
`;

export const CustomIcon = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  top: 0.2rem;
  left: 0.1rem;
  transform: translateY(-30%);
  transition: margin-left 0.3s ease-in-out;
  &.with-margin {
    margin-left: 0.8rem;
  }
`;

export const HeaderContentText = styled.p`
  font-size: 1.14rem;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.baseBlack};
  white-space: nowrap;
  padding: 2px 0 0 0;
  &.with-margin {
    margin-left: 0.5rem;
  }
`;

export const CloseButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  width: 0.8rem;
  height: 0.8rem;
`;

//Main Section

export const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HeroSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4.5rem 0 1.5rem 0;
  height: 6rem;
  padding: 0 1.25rem;
  gap: 0.75rem;
`;

export const HeroSectionText = styled.p`
  width: 80%;
  font-size: 1.14rem;
  font-weight: 400;
  letter-spacing: 0.015rem;
  word-spacing: 0.015rem;
  line-height: 1.25rem;
  text-align: center;
  color: ${({ theme }) => theme.baseBlack};
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
`;

/* Questions  Section*/

export const QuestionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0.8rem;
  width: 100%;
  min-height: 43rem;
`;

export const QuestionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  width: 100%;
`;

/* Section Header: Align icon & title properly */

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const SectionHeaderTitle = styled.h5`
  gap: 0.375rem;
  color: ${({ theme }) => theme.nobel};

  height: 1.25rem;
  width: 31.1875rem;

  fontSize:1rem,
  font-weight: 500;
  line-height: 1.25rem;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

/* Selected Question Section */

export const QAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  position: relative;
  top: 5.625rem;
`;

export const SelectedQuestionContainer = styled.div`
  padding: 0.25rem 0.375rem;
  background: ${({ theme }) => theme.primaryLight1};
  border-radius: 0.375rem;
  max-width: 25rem;
  width: fit-content;
  height: 2.25rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 0.625rem;
  margin-left: auto;
`;

export const SelectedQuestionText = styled.p<{ clicked: boolean }>`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.blackPrimary};
  line-height: 1.25rem;
  text-align: left;
  overflow: hidden;
  cursor: not-allowed;

  &:first-child {
    cursor: ${({ clicked }) => (clicked ? 'default' : 'pointer')};
  }
`;

/* Answer Section used in chatbotresponse */
export const ChatbotContainer = styled.div`
  position: relative;
  padding: 0.25rem 0.375rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  // height-min: 3rem;
  height: fit-content;
  overflow: hidden;
  border-radius: 0.375rem;
  background: ${({ theme }) => theme.botResponseBackground};
  transition: all 0.3s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.375rem;
    padding: 0.5px;
    background: ${({ theme }) =>
      `linear-gradient(180deg, ${theme.gradientStart}, ${theme.gradientEnd})`};
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    box-shadow:
      0 0 0 1px ${({ theme }) => theme.gradientStart},
      0 0 0 1px ${({ theme }) => theme.gradientEnd};
  }
`;

export const ChatbotMessage = styled.p`
  font-size: 0.75rem;
  white-space: normal;
  overflow: hidden;
  display: block;
  width: 100%;
  padding: 0.3125rem;
  color: ${({ theme }) => theme.mediumDarkGrey};
`;
