import { useMemo } from 'react';
import { useRestrictionsContextOf } from './useRestrictionsContextOf';
import { DiscountIndex, DiscountName } from '../types/discounts.types';
import { IRestrictionsSingle } from '../types/restrictions.types';

/**
 * get restrictions for a specific discount index
 *
 * ⚠️ Before using this, do the following:
 * - `const { setValue } =`{@link useRestrictionsContextOf}`('restrictions')`
 * - `setValue(restrictions as `{@link IRestrictionsSingle}`)`
 *
 * otherwise {@link useRestrictionsSingle} will always return default values
 */
export const useRestrictionsSingle = (
  i: DiscountIndex,
): IRestrictionsSingle => {
  const ri = useMemo<DiscountName>(() => `r${i}`, [i]);

  const { value: restrictions } = useRestrictionsContextOf('restrictions');

  return useMemo<IRestrictionsSingle>(() => {
    const res: IRestrictionsSingle = { lock: false, max: 1, limit: 1 };
    if (!restrictions) return res;
    if (restrictions.lock) {
      res.lock = restrictions.lock[ri];
    }
    if (restrictions.max) {
      res.max = restrictions.max[ri];
    }
    if (restrictions.limit) {
      res.limit = restrictions.limit[ri];
    }
    return res;
  }, [restrictions, ri]);
};
