import { getAgent } from 'common/helpers/agent';
import { selectOrganization } from 'common/store/organization/selectors';
import { useSelector } from 'react-redux';

export const useDocumentHelpers = () => {
  const authOrganization = useSelector(selectOrganization);

  function getProcessedDocumentsFunction(response) {
    const { docs = [] } = response;

    return docs?.reduce((acc, item) => {
      const submittedBy = getAgent(item?._submittedBy, authOrganization?._id);

      const updatedBy = getAgent(item?._updatedBy, authOrganization?._id);

      const employee = getAgent(item?._userAgent, authOrganization?._id);

      acc.push({
        id: item?._id,
        type: item?.type,
        submission_date: item?.createdAt,
        user: {
          submitted_by: {
            pictureUrl: submittedBy?.pictureUrl || '',
            fullName:
              submittedBy?.firstName + ' ' + submittedBy?.lastName || '',
          },
          updated_by: {
            pictureUrl: updatedBy?.pictureUrl || '',
            fullName: updatedBy?.firstName + ' ' + updatedBy?.lastName || '',
          },
        },
        employee: {
          pictureUrl: employee?.pictureUrl || '',
          fullName: employee?.firstName + ' ' + employee?.lastName || '',
        },
        updated_date: item?.updatedAt,
        status: {
          status: item?.status,
          reason: item?.reason || '',
          archived: item?.isArchived || false,
        },
        attachments: item?.attachment || [],
      });
      console.log('acc', acc);
      return acc;
    }, []);
  }

  return {
    getProcessedDocumentsFunction,
  };
};
