import { useState, useMemo } from 'react';
import { getAgent, getAgentFullName } from 'common/helpers/agent';
import { selectOrganization } from 'common/store/organization/selectors';
import { useSelector } from 'react-redux';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { useDebounce } from 'common/hooks/useDebounce';

export const useFetchUsers = () => {
  const [search, setSearch] = useState('');
  const authOrganization = useSelector(selectOrganization);
  const debouncedUserSearch = useDebounce(search, 300);

  const queryParams = useQueryParams({
    page: 1,
    limit: 50,
    isActive: true,
    isLocked: false,
    isArchived: false,
    text: debouncedUserSearch,
  });

  const {
    data: usersData,
    isFetching,
    refetch: triggerUsers,
  } = useGetUsersBasicQuery(queryParams);

  const users = useMemo(() => {
    return (
      usersData?.docs?.map((u) => {
        const preferredAgent = getAgent(u, authOrganization);
        return {
          color: '',
          value: u._id,
          label: getAgentFullName(preferredAgent),
          pictureUrl: preferredAgent?.pictureUrl,
        };
      }) || []
    );
  }, [usersData, authOrganization]);

  const searchUsers = (searchTerm) => {
    setSearch(searchTerm);
    triggerUsers();
  };

  return {
    isFetching,
    setSearch,
    users,
    searchUsers,
  };
};
