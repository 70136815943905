import { Box, Collapse, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useAsyncData } from 'hooks/useAsyncData';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import {
  ActivityDot,
  ContentPaper,
  LoaderText,
  TimelineDot,
  TimelineItemContainer,
} from '../../styles';
import If from 'app/components/If';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { Record } from '../..';
import { dateFormat } from 'common/utils/dates';

interface TimeLineItemProps {
  date: string;
  items: Record[];
  hasMoreRecords: boolean;
  totalMoreRecords: number;
  getActivities: Function;
  titlesMap: (item: Record) => React.ReactNode;
  index: number;
}

export const TimelineItem = ({
  date,
  items,
  hasMoreRecords,
  totalMoreRecords,
  getActivities,
  titlesMap,
  index,
}: TimeLineItemProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [additionalItems, setAdditionalItems] = useState<any>([]);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const theme = useSelector(selectTheme);

  const visibleItems = items.slice(0, 2);

  const { callApi, loading } = useAsyncData(
    async () => await getActivities(date, totalMoreRecords),
    {
      onSuccess: (resp: any) => {
        setAdditionalItems(resp?.docs);
        setLoadedOnce(true);
        setExpanded(true);
      },
    },
  );

  const getActivityTitle = (item) => {
    const fields = item.data?.changedFields;
    if (fields?.length) {
      const fieldLabels = fields?.map((field) => t(`field.${field}`));
      if (fields?.length > 1) {
        return t('fields_were updated', { fields: fieldLabels?.join(', ') });
      }

      return t('field_was_updated', { field: fieldLabels[0] });
    }
    return titlesMap(item) || t('action_performed');
  };

  const formatDate = (date: string) => {
    const today = dayjs().format('YYYY-MM-DD');

    if (date === today) {
      return t('calendar.today');
    }
    return dateFormat(date, false);
  };

  const handleToggle = async () => {
    if (hasMoreRecords && !loadedOnce) {
      await callApi();
    } else {
      setExpanded(!expanded);
    }
  };

  const ActivityRow = ({ item }: { item: Record }) => {
    return (
      <Stack direction="row" alignItems="center">
        <Typography fontSize="0.625rem" color={theme.nobel}>
          {dayjs(item.createdAt).format('hh:mm a')}
        </Typography>
        <ActivityDot />
        <Typography fontSize="0.75rem">
          <Stack direction="row" gap="4px" alignItems="center">
            <Box sx={{ fontWeight: 700 }}>{getActivityTitle(item)}</Box>
            {item.data?.userFullName && (
              <Box>
                {' - '}
                {item.data?.userFullName}
              </Box>
            )}
          </Stack>
        </Typography>
      </Stack>
    );
  };

  return (
    <TimelineItemContainer>
      <TimelineDot color={index === 0 ? theme.primaryActiveColor : ''} />
      <ContentPaper elevation={0}>
        <Stack gap="8px" alignItems="flex-start">
          <Typography
            fontSize="0.875rem"
            fontWeight="700"
            textTransform="capitalize"
          >
            {formatDate(date)}
          </Typography>

          <Stack gap="8px" alignItems="flex-start">
            {visibleItems.map((item, index) => (
              <ActivityRow item={item} key={index} />
            ))}
          </Stack>

          <If condition={!!hasMoreRecords}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Stack gap="8px" alignItems="flex-start">
                {additionalItems?.map((item, index) => (
                  <ActivityRow item={item} key={index} />
                ))}
              </Stack>
            </Collapse>

            <Stack
              onClick={handleToggle}
              direction="row"
              gap="4px"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <If
                condition={!loadedOnce}
                otherwise={
                  <Typography fontSize="0.625rem" color={theme.nobel}>
                    <If condition={!expanded} otherwise={t('view_less')}>
                      {t('view_count_more', {
                        count: additionalItems.length,
                      })}
                    </If>
                  </Typography>
                }
              >
                <Typography fontSize="0.625rem" color={theme.nobel}>
                  <If
                    condition={!loading}
                    otherwise={
                      <LoaderText>
                        <LoadingPulse />
                      </LoaderText>
                    }
                  >
                    {t('view_count_more', { count: totalMoreRecords })}
                  </If>
                </Typography>
              </If>
            </Stack>
          </If>
        </Stack>
      </ContentPaper>
    </TimelineItemContainer>
  );
};
