import { useNavigate } from 'react-router-dom';
import { STOCKEE_SUPPLIERS } from 'utils/routes';

export const useNavigateToSuppliers = () => {
  const navigate = useNavigate();

  const navigateToCustomers = (currentUrl: string) => {
    const basePath = currentUrl.split(STOCKEE_SUPPLIERS)[0];
    navigate(`${basePath}${STOCKEE_SUPPLIERS}`);
  };

  return navigateToCustomers;
};
