import { useState } from 'react';
import { useModal } from 'app/components/Dialog/hooks';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ButtonProps } from '@mui/material';
// import { useStatusToaster } from './useStatusToaster';
// import { StatusToastVariant } from 'common/types/StatusToasterNotification';
import { useToaster } from './useToaster';

export type SubmitOptions = {
  valid?: boolean;
  color?: string;
  successMessage?: string;
  confirmationProps?: {
    title: string;
    message: string;
  };
  actionText?: string;
  actionTooltip?: string;
  buttonProps?: ButtonProps;
  onClose?: () => void;
  onSuccess?: (response) => void;
  onError?: () => void;
};

export function useAsyncDataV2() {
  const [loading, setLoading] = useState(false);
  const [error, setError]: any = useState(null);
  const toaster = useToaster();
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  // const statusToaster = useStatusToaster();

  function getErrors() {
    return error;
  }
  type Return<T> = T | (T & { error: string });
  type ReturnOrPromise<T> = Return<T> | Promise<Return<T>>;

  async function callApi<T extends object | void | null>(
    action: () => ReturnOrPromise<T>,
    options?: SubmitOptions,
  ) {
    const valid = options?.valid === undefined ? true : options.valid;

    if (options && !valid) {
      return console.error('form not valid');
    }
    try {
      if (options?.confirmationProps) {
        openModal({
          title: t(options.confirmationProps.title),
          content: t(options.confirmationProps.message),
          action: {
            tooltip: options.actionTooltip,
            actionText: options.actionText || 'ok',
            async actionCallback() {
              setLoading(true);
              await action();
              if (options?.successMessage) {
                toaster(3000, 'success', t(options.successMessage));
              }
              closeModal();
              setLoading(false);
            },
            buttonProps: {
              color: options.buttonProps?.color || 'primary',
              disabled: options.buttonProps?.disabled || false,
            },
          },
          cancel: true,
        });
      } else {
        setLoading(true);
        // statusToaster({
        //   variant: StatusToastVariant.Loading,
        // });
        try {
          const response = await action();
          if (options?.successMessage) {
            // statusToaster({
            //   variant: StatusToastVariant.Success,
            //   message: options?.successMessage,
            // });
            toaster(3000, 'success', t(options.successMessage));
            options.onClose && options.onClose();
          }
          if (
            response &&
            Object.hasOwn(response, 'error') &&
            response['error']
          ) {
            throw new Error(response['error']);
          }
          options?.onSuccess && options.onSuccess(response);
          setError(null);
          return response;
        } catch (e: any) {
          if (e.status === 400) {
            if (!!e.data?.validation?.length) {
              e.data?.validation.forEach((errorItem) => {
                setError({
                  ...error,
                  [errorItem.context.key]: t(errorItem.message),
                });
              });
            } else {
              setError(e.data);
            }
          }
          options?.onError && options.onError();
        }
      }
    } catch (e: any) {
      options?.onError && options.onError();
    } finally {
      setLoading(false);
    }
  }
  return { loading, error, callApi, getErrors };
}
