import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, TextField, Typography } from '@mui/material';
import { FormActions } from 'app/components/Form/styles';
import FormTextField from 'app/components/Form/TextField';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useDebounce } from 'common/hooks/useDebounce';
import {
  useGetCustomersBasicQuery,
  useLazyGetCustomersBasicsBranchesQuery,
} from 'common/services/customerApi';
import { usePatchRefundMutation } from 'common/services/transactions';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useQueryParams } from 'hooks/useQueryParams';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export function RefundForm({ handleClose }) {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext>('grid');

  const row = gridContext.selectedRow;

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      customer: {
        _id: row?.customer?.id || '',
        legalName: row?.customer?.publicName || '',
      },
      branchLocation: {
        name: row?.branchLocation?.name || '',
      },
      claimId: '',
      notes: row?.notes || '',
    },
  });

  const { control, watch } = methods;

  /** CUSTOMERS FIELD QUERY */
  const [customersSearch, setCustomersSearch] = useState<string>(
    row?.customer?.name || '',
  );
  const debouncedCustomersSearch = useDebounce(customersSearch, 200);
  const { data: customers } = useGetCustomersBasicQuery(
    `text=${debouncedCustomersSearch}`,
  );

  /** BRANCHES FIELD QUERY */
  const [branchesSearch, setBranchesSearch] = useState<string>('');
  const debouncedBranchesSearch = useDebounce(branchesSearch, 200);
  const branchesQueryParams = useQueryParams({ text: debouncedBranchesSearch });
  const [trigger, { data: branches }] =
    useLazyGetCustomersBasicsBranchesQuery();

  const customerId = watch('customer')?._id;

  useEffect(() => {
    if (customerId) {
      trigger({ customerId: customerId, queryParams: branchesQueryParams });
    }
  }, [branchesSearch, customerId]);

  const [patchRefund] = usePatchRefundMutation();

  function handleEditRefund() {
    patchRefund({
      refundId: row?.fileId,
      body: {
        notes: control?._formValues?.notes,
        customerId: control?._formValues?.customer?._id,
        customerDeckId: selectedDeck?._id,
      },
    });
    handleClose();
  }

  const [selectedDeck, setSelectedDeck] = useState<any>(
    branches?.find(
      (branch) => branch._id === row?.branchLocation?.customerDeckId,
    ) || null,
  );

  useEffect(() => {
    branches?.map((br) => {
      if (br._id === row?.branchLocation?.customerDeckId) {
        setSelectedDeck(br);
      }
    });
  }, [row, branches]);

  useEffect(() => {
    setSelectedDeck(null);
  }, [customerId]);

  return (
    <Wrapper>
      <Typography fontWeight={500} marginBottom="16px">
        {t('common.details')}
      </Typography>

      <Controller
        name="customer"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={customers?.docs || []}
            defaultValue={
              branches?.find(
                (branch) => branch._id === row?.branchLocation?.customerDeckId,
              ) || null
            }
            getOptionLabel={(option: any) => option?.legalName || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('common.customer')}
                required={true}
                onChange={(e) => {
                  setCustomersSearch(e?.target?.value);
                }}
              />
            )}
            renderOption={(props, option: any) => (
              <li {...props}>
                <Typography>{option.legalName}</Typography>
              </li>
            )}
            onChange={(e, value) => {
              field.onChange(value);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
        )}
      />
      <Autocomplete
        options={branches || []}
        getOptionLabel={(option: any) =>
          option?.location?.shortAddress || option?.location?.name || ''
        }
        value={selectedDeck ?? null}
        defaultValue={row?.branchLocation ?? null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('claims.field.client.branch')}
            required={true}
            onChange={(e) => {
              setBranchesSearch(e?.target?.value);
            }}
          />
        )}
        renderOption={(props, option: any) => (
          <li {...props}>
            <Typography>
              {option.location.shortAddress || option.location.name}
            </Typography>
          </li>
        )}
        onChange={(event, value: any) => {
          setSelectedDeck(value);
        }}
        isOptionEqualToValue={(option, value) => option._id === value._id}
      />
      {/** HIDE FOR NOW
       * <Controller
        name="claimId"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={[]}
            renderInput={(params) => (
              <TextField {...params} label={t('claimd.field.claimId')} />
            )}
          />
        )}
      />
       */}
      <FormTextField
        control={control}
        name="notes"
        label="Note"
        multiline
        rows={6}
      />

      <FormActions>
        <Button
          disableElevation
          sx={{ color: themes.default.black60, mr: '16px' }}
          onClick={handleClose}
        >
          {t('common.buttons.cancel')}
        </Button>

        <LoadingButton
          variant="contained"
          onClick={handleEditRefund}
          disabled={!customerId || !selectedDeck}
        >
          {t('common.buttons.save')}
        </LoadingButton>
      </FormActions>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 24px 20px;
`;
