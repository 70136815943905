import { InvoicesData } from './data';

export function getProcessedInvoices(
  invoicesData: InvoicesData[],
  paymentEnable: boolean = true,
  isUserCustomer: boolean = false,
) {
  return invoicesData.map((invoiceObj, index) => {
    const invoice = {
      id: invoiceObj?._id || index,
      __archived: {
        archivedData: invoiceObj?.isArchived || false,
      },
      type: {
        typeData: invoiceObj?.metadata?.type || '',
      },
      invoiceCode: {
        invoiceCodeData: invoiceObj?.metadata?.invoiceCode || '',
        initialUrlData: invoiceObj?.initialUrl || '',
      },
      name: {
        nameData: invoiceObj?.name || '',
        initialUrlData: invoiceObj?.initialUrl || '',
      },
      client: {
        clientIdData: invoiceObj?.metadata?.objectId || '',
        clientCodeData: invoiceObj?.cache?.customerCode || '',
        clientNameData: invoiceObj?.cache?.customerName || '',
      },
      branch: {
        branchData: invoiceObj?.metadata?.structured?.delivery?.address || '',
        branchCodeData: invoiceObj?.metadata?.structured?.delivery?.code || '',
        customerId: invoiceObj?.metadata?.objectId || '',
        fileId: invoiceObj?._id || '',
      },
      date: {
        dateData: invoiceObj?.metadata?.date || '',
      },
      amount: {
        amountData: invoiceObj?.metadata?.structured?.totalAmountTax || invoiceObj?.accountingAmount || 0,
      },
      accountingDueDate: {
        dueDateData: invoiceObj?.accountingDueDate || '',
        accountingPaymentStatus: invoiceObj?.accountingPaymentStatus || '',
      },
      accountingPayedAmount: {
        payedAmountData: invoiceObj?.accountingPayedAmount || 0,
      },
      lateDays: {
        dueDateData: invoiceObj?.accountingDueDate || '',
        isPaid: invoiceObj?.accountingPaymentStatus === 'paid',
      },
      remaining: {
        remainingData:
          invoiceObj?.accountingAmount - invoiceObj?.accountingPayedAmount || 0,
      },
      status: {
        statusDataObj: invoiceObj || {},
      },
      __action: {
        paymentEnable,
        document: invoiceObj || {},
      },
      __actionMenu: {
        actionMenuData: invoiceObj || '',
      },
    };

    if (isUserCustomer) {
      delete (invoice as any).client;
      delete (invoice as any).remaining;
      delete (invoice as any).accountingPayedAmount;
    }

    return invoice;
  });
}
