import { AgGridReact } from '@ag-grid-community/react';
import { Collapse } from '@mui/material';
import { IGridContext } from 'app/components/ManagedGrid/types';
import ManagedGridList from 'app/components/ManagedGridList';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ListPaymentContext } from '../../ListPaymentProvider';
import { useLazyGetInvoicesByListQuery } from 'common/services/transactions';
import { useQueryParams } from 'hooks/useQueryParams';
import { GroupHeader } from 'app/components/ManagedGridList/components/GroupHeader';
import { getProcessedInvoices } from '../../../helpers';

export function ListCollapsablePayment({ period, expanded, onToggle }) {
  const [processedPayments, setProcessedPayments] = useState<any[]>([]);
  const [processedRow, setProcessedRow] = useState<any[]>([]);

  const gridRef = useRef<AgGridReact>(null);
  const gridContext = useManagedContext<IGridContext>('grid');
  const { columnDefs } = gridContext;

  const { rowDataList, setSelectedPaymentsIds, refs, setRefs, setRowDataList } =
    useContext(ListPaymentContext);

  const [trigger, { data: invoicePerDate, isLoading }] =
    useLazyGetInvoicesByListQuery();

  const commonQueryParams = {
    uploadDateKey: period.uploadDateKey,
  };

  const queryParams = useQueryParams(commonQueryParams);

  useEffect(() => {
    if (period?.uploadDateKey && expanded) {
      trigger(queryParams);
    }
  }, [period, expanded]);

  useEffect(() => {
    if (invoicePerDate) {
      setProcessedPayments(getProcessedInvoices(invoicePerDate?.docs) || []);
      setRowDataList((prev) => {
        const existingIds = new Set(prev.map((item) => item._id));
        const newDocs = invoicePerDate.docs.filter(
          (doc) => !existingIds.has(doc._id),
        );
        return [...prev, ...newDocs];
      });
    }
  }, [invoicePerDate]);

  useEffect(() => {
    if (isLoading) {
      gridContext.updateDataWithFunction((prev) => {
        prev.loading = isLoading;
      });
      return;
    }

    gridContext.updateDataWithFunction((prev) => {
      prev.responseData = processedPayments;
      prev.loading = false;
      prev.rowData = processedRow!;
    });
  }, [isLoading, processedPayments]);

  useEffect(() => {
    if (!rowDataList) return;
    setProcessedRow(getProcessedInvoices(rowDataList) || []);
  }, [rowDataList]);

  const gridsIds = invoicePerDate?.docs.map((doc) => doc._id) || [];

  useEffect(() => {
    if (!gridContext.selectedRows) return;

    const selectedRowIds = gridContext.selectedRows;

    setSelectedPaymentsIds((prev) => {
      if (selectedRowIds.length === 0) {
        const filtered = prev.filter((id) => !gridsIds.includes(id));
        if (filtered.length !== prev.length) {
          gridRef.current?.api?.deselectAll();
          return filtered;
        }
        return prev;
      }

      const newIds = selectedRowIds.filter((id) => !prev.includes(id));
      if (newIds.length > 0) {
        return [...prev, ...newIds];
      }

      return prev;
    });
  }, [gridContext.selectedRows, gridsIds]);

  useEffect(() => {
    if (gridRef?.current) {
      const allRefs = refs || [];
      allRefs.push(gridRef.current);
      setRefs(allRefs);
    }
  }, [gridRef?.current]);

  return (
    <div>
      <TitleContainer headerBackground={themes?.default?.roundtripGreyBg}>
        <GroupHeader
          onClick={onToggle}
          title={period.uploadDateKey}
          expanded={expanded}
        />
      </TitleContainer>
      <Collapse in={expanded}>
        <ManagedGridList
          refs={refs}
          gridRef={gridRef}
          rowData={processedPayments}
          columnDefs={columnDefs}
          loading={isLoading}
          filtered={false}
        />
      </Collapse>
    </div>
  );
}

const TitleContainer = styled.div<{ headerBackground }>`
  background: ${(props) => props.headerBackground};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  margin-bottom: 10px;
`;
