import { Prohibit } from '@phosphor-icons/react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { themes } from 'styles/theme/themes';
import { Typography } from '@mui/material';
import styled from 'styled-components';

export const OfferIdCell = (params: CustomCellRendererProps) => {
  const rowData = params?.value?.cellData;

  return (
    <>
      <Typography>{rowData.code}</Typography>
      {!rowData.isActive && (
        <StyledIcon size={12} weight="bold" color={themes.default.red800} />
      )}
    </>
  );
};

const StyledIcon = styled(Prohibit)`
  margin-left: 2px;
`;
