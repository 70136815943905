import { useRestrictionsSingle } from './useRestrictionsSingle';
import { useCallback, useMemo } from 'react';
import { useRestrictionsContextOf } from './useRestrictionsContextOf';
import { DiscountIndex } from '../types/discounts.types';

export const useRestrictMaxModal = (i: DiscountIndex) => {
  const restrictions = useRestrictionsSingle(i);

  const { set: setInfo } = useRestrictionsContextOf('maxModal');

  const toggle = useCallback(
    (show: boolean, isUnlocking?: boolean) => {
      setInfo({ show, i, isUnlocking });
    },
    [setInfo, i],
  );

  const show = useCallback(
    (isUnlocking?: boolean) => {
      if (restrictions.lock && !isUnlocking) return;
      toggle(true, isUnlocking);
    },
    [restrictions.lock, toggle],
  );
  const hide = useCallback(() => toggle(false), [toggle]);

  return useMemo(() => ({ show, hide }), [show, hide]);
};
