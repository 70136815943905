import { Box, CircularProgress, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { themes } from 'styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { Minus, Plus, Package } from '@phosphor-icons/react';
import If from 'app/components/If';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import {
  validateInputNumberPostive,
  validateInputNumberPaste,
} from 'app/helpers/helpers';
import {
  IconButtonSx,
  QuantityInput,
  UnitButtonSx,
  QuantityInputLoader,
} from './styles';

interface Props {
  initialValue?: number;
  onChange: (value: number) => void;
  onAdd?: () => void;
  loading?: boolean;
  width?: string;
  height?: string;
  btnIconWidth?: string;
  qunatityIconSize?: number;
  hasQuantity?: boolean;
  name?: string;
  disabled?: boolean;
  maxValue?: number;
}

export const ValueInput = ({
  initialValue = 0,
  onChange,
  onAdd = () => {},
  loading = false,
  width,
  height,
  btnIconWidth,
  qunatityIconSize,
  hasQuantity = true,
  name,
  disabled = false,
  maxValue,
}: Props) => {
  const theme = useSelector(selectTheme);
  const [value, setValue] = useState<number>(initialValue);

  const handleChangeValue = useCallback(
    (newValue) => {
      if (loading) return;
      if (maxValue && newValue > maxValue) {
        setValue(maxValue);
        onChange(maxValue);
        return;
      }
      if (!newValue) {
        setValue(0);
        onChange(0);
        return;
      }
      if (value === 0 && newValue > value) {
        onAdd();
      }
      setValue(newValue);
      onChange(newValue);
    },
    [loading, onAdd, onChange, value, maxValue],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, maxValue]);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="2px"
      sx={{ transition: 'all 0.15s', opacity: loading ? 0.5 : 1 }}
    >
      <IconButton
        disabled={value < 1 || loading || disabled}
        sx={IconButtonSx(height, btnIconWidth)}
        onClick={() => handleChangeValue(value - 1)}
        disableRipple={false}
        centerRipple={false}
      >
        <Icon icon={<Minus />} />
      </IconButton>

      <If
        condition={!loading}
        otherwise={
          <QuantityInputLoader>
            <CircularProgress size={14} sx={{ colot: theme.primary }} />
          </QuantityInputLoader>
        }
      >
        <QuantityInput
          disabled={disabled}
          onKeyDown={(e) => {
            validateInputNumberPostive(e);
          }}
          onPaste={validateInputNumberPaste}
          key={name}
          name={name}
          width={width}
          height={height}
          type="number"
          value={value}
          onChange={(e) => {
            handleChangeValue(parseInt(e.target.value));
          }}
        />
      </If>

      <IconButton
        sx={IconButtonSx(height, btnIconWidth)}
        onClick={() => handleChangeValue(value + 1)}
        disableRipple={false}
        centerRipple={false}
        disabled={loading || disabled}
      >
        <Icon icon={<Plus />} />
      </IconButton>
      {hasQuantity && (
        <IconButton
          sx={UnitButtonSx(width, height)}
          disableRipple={false}
          centerRipple={false}
        >
          <Icon
            icon={<Package />}
            size={qunatityIconSize || 13}
            color={themes.default.nobel}
          />
        </IconButton>
      )}
    </Box>
  );
};
