import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  SquaresFour,
  ImagesSquare,
  ShoppingBagOpen,
  ClipboardText,
  CurrencyCircleDollar,
  PhoneCall,
  PresentationChart,
  Star,
  Invoice,
  HandCoins,
} from '@phosphor-icons/react';

import GridViewIcon from '@mui/icons-material/GridView';

import { MainNavItem, ScrollableSection, OptionsWrapper } from '../helpers';
import {
  CATALOG,
  CUSTOMER,
  DASHBOARD_ALL,
  ORDERS_ACTIONS,
  ORDORIA,
  ORDORIA_BALANCE,
  ORDORIA_CUSTOMER,
  ORDORIA_INVOICES,
  ORDORIA_TRANSACTIONS,
  REPORTS,
  REPORTS_ALL,
} from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { isActiveRoute } from '../index';
import { IconWrapper } from '../../../../styles/components/muitable/TableViewPopover';
import { MenuTitleWrapper } from '../Components';
import { ORDORIA_PORTAL_DASHBOARD } from '../../../pages/Ordoria/routes';
import { useSelector } from 'react-redux';
import { selectNavMenuPreferences } from 'common/store/app/selectors';
import { useDispatch } from 'react-redux';
import { appActions, defaultMenuNavItems } from 'common/store/app';
import { MenuNavItem } from '../types';
import { Can } from 'hooks/Abilities/context';
import { sortNavMenuItems } from 'app/helpers/helpers';

const dashboardAll = `${ORDORIA}${DASHBOARD_ALL}`;
const reportAll = `${ORDORIA}${REPORTS_ALL}`;

export default function OrdoriaMenu({ theme, mode, authUser }) {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navMenuPreferences = useSelector(selectNavMenuPreferences);

  const isDashboardAll = location.pathname === dashboardAll;
  const isReportAll = location.pathname === reportAll;
  const isMinify = mode === 'minify';
  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>([0]);

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [index];
      }
    });
  };

  useEffect(() => {
    if (!navMenuPreferences?.ordoria) {
      dispatch(
        appActions.setNavMenuPreferences({
          ...navMenuPreferences,
          ordoria: defaultMenuNavItems.ordoria,
        }),
      );
    }
  }, [dispatch, navMenuPreferences]);

  const menuMapping = useMemo(() => {
    const ordoriaPreferences =
      navMenuPreferences?.ordoria || defaultMenuNavItems.ordoria;

    const updateStore = (key, value) => {
      dispatch(
        appActions.setNavMenuPreferences({
          ...navMenuPreferences,
          ordoria: {
            ...navMenuPreferences.ordoria,
            [key]: value,
          },
        }),
      );
    };

    const sortedMenuItems = sortNavMenuItems(
      defaultMenuNavItems.ordoria.menuItems,
      ordoriaPreferences.menuItems || [],
      (sorted) => updateStore('menuItems', sorted),
    );

    const sortedMenuAllItems = sortNavMenuItems(
      defaultMenuNavItems.ordoria.menuAllItems,
      ordoriaPreferences.menuAllItems || [],
      (sorted) => updateStore('menuAllItems', sorted),
    );

    const sortedMenuClientItems = sortNavMenuItems(
      defaultMenuNavItems.ordoria.menuClientItems,
      ordoriaPreferences.menuClientItems || [],
      (sorted) => updateStore('menuClientItems', sorted),
    );

    return {
      main: authUser?.currentAgent?._customer
        ? sortedMenuClientItems
        : isDashboardAll || isReportAll
          ? sortedMenuAllItems
          : sortedMenuItems,
      general: sortNavMenuItems(
        defaultMenuNavItems.ordoria.generalItems,
        ordoriaPreferences.generalItems || [],
        (sorted) => updateStore('generalItems', sorted),
      ),
      docs: sortNavMenuItems(
        defaultMenuNavItems.ordoria.docsItems,
        ordoriaPreferences.docsItems || [],
        (sorted) => updateStore('docsItems', sorted),
      ),
    };
  }, [dispatch, navMenuPreferences, authUser, isDashboardAll, isReportAll]);

  const MenuNavItems = useMemo(() => {
    const items: MenuNavItem[] = [
      {
        id: 'ordoriaMenu1',
        title: t('nav.mainNav.title1'),
        onClick: () => handleRowToggle(0),
        items: menuMapping.main,
      },
    ];

    if (!(isDashboardAll || isReportAll || authUser?.currentAgent?._customer)) {
      items.push(
        {
          id: 'ordoriaMenu2',
          title: t('nav.mainNav.title2'),
          onClick: () => handleRowToggle(1),
          items: menuMapping.general,
        },
        {
          id: 'ordoriaMenu3',
          title: t('nav.mainNav.checkDocuments'),
          onClick: () => handleRowToggle(2),
          items: menuMapping.docs,
        },
      );
    }

    return items;
  }, [t, menuMapping, isDashboardAll, isReportAll, authUser]);

  const getToById = (id: string): string => {
    switch (id) {
      case 'dashboard':
        return ORDORIA;

      case 'portal':
        return `${ORDORIA}/${ORDORIA_PORTAL_DASHBOARD}`;

      case 'transactions':
        return ORDORIA_INVOICES;

      case 'catalog':
        return CATALOG;

      case 'orders':
        return ORDERS_ACTIONS;

      case 'dashboardAll':
        return dashboardAll;

      case 'reportAll':
        return reportAll;

      case 'balance-accounting':
        return ORDORIA_BALANCE;

      case 'reports':
        return `/ordoria${REPORTS}`;

      case 'customers':
        return ORDORIA_CUSTOMER;

      case 'calllist':
        return '/ordoria/callplan';

      case 'claims':
        return '/ordoria/claims';

      case 'quotations-accounting':
        return '/ordoria/quotations';

      case 'transactions-accounting':
        return '/ordoria/transactions/accounting/imported';
      default:
        return ORDORIA;
    }
  };

  const getTitleById = (id: string): string => {
    switch (id) {
      case 'dashboard':
        return t('nav.mainNav.ordoria.dashboard');

      case 'portal':
        return t('nav.mainNav.ordoria.portal');

      case 'transactions':
        return t('nav.mainNav.ordoria.transactions');

      case 'catalog':
        return t('nav.mainNav.ordoria.catalog');

      case 'orders':
        return t('nav.mainNav.ordoria.orders');

      case 'dashboardAll':
        return t('nav.mainNav.ordoria.dashboard');

      case 'reportAll':
        return t('nav.mainNav.reports');

      case 'balance-accounting':
        return t('common.balance');

      case 'reports':
        return t('nav.mainNav.reports');

      case 'customers':
        return t('nav.mainNav.customers');

      case 'calllist':
        return t('nav.mainNav.ordoria.callplan');

      case 'claims':
        return t('nav.mainNav.ordoria.claims');

      case 'quotations-accounting':
        return t('nav.mainNav.ordoria.quotations');

      case 'transactions-accounting':
        return t('transaction.accounting');

      default:
        return t('nav.mainNav.ordoria.dashboard');
    }
  };

  const getIconById = (id: string): JSX.Element => {
    switch (id) {
      case 'dashboard':
        return (
          <Icon
            icon={<SquaresFour />}
            color={
              location.pathname === '/ordoria'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'portal':
        return (
          <GridViewIcon
            sx={{
              color:
                location.pathname === `${ORDORIA}/${ORDORIA_PORTAL_DASHBOARD}`
                  ? theme.primaryActiveColor
                  : themes?.default?.black54,
            }}
          />
        );

      case 'transactions':
        return (
          <IconWrapper
            isActive={location?.pathname?.includes(ORDORIA_TRANSACTIONS)}
          >
            <Icon
              icon={<Invoice />}
              color={
                location?.pathname?.includes(ORDORIA_TRANSACTIONS)
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
              }
              size={20}
            />
          </IconWrapper>
        );

      case 'catalog':
        return (
          <Icon
            icon={<ImagesSquare />}
            color={
              location.pathname === CATALOG
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'orders':
        return (
          <Icon
            icon={<ShoppingBagOpen />}
            color={
              location.pathname === ORDERS_ACTIONS
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'dashboardAll':
        return (
          <Icon
            icon={<SquaresFour />}
            color={
              location.pathname === dashboardAll
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'reportAll':
        return (
          <IconWrapper isActive={isActiveRoute(location, reportAll)}>
            <PresentationChart />
          </IconWrapper>
        );

      case 'balance-accounting':
        return (
          <Icon
            icon={<HandCoins />}
            color={
              location.pathname === '/ordoria/balance'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'reports':
        return (
          <IconWrapper isActive={isActiveRoute(location, REPORTS)}>
            <PresentationChart
              color={
                location.pathname === '/ordoria/reports'
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
              }
            />
          </IconWrapper>
        );

      case 'customers':
        return (
          <IconWrapper isActive={isActiveRoute(location, CUSTOMER)}>
            <Star
              color={
                location.pathname === '/ordoria/customers'
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
              }
            />
          </IconWrapper>
        );

      case 'calllist':
        return (
          <PhoneCall
            color={
              location.pathname === '/ordoria/callplan'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
          />
        );

      case 'claims':
        return (
          <Icon
            icon={<ClipboardText />}
            color={
              location.pathname === '/ordoria/claims'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'quotations-accounting':
        return (
          <Icon
            icon={<CurrencyCircleDollar />}
            color={
              location.pathname === '/ordoria/quotations'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'transations-invoices':
        return (
          <Icon
            icon={<Invoice />}
            color={
              location.pathname === '/ordoria/transations'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      case 'transactions-accounting':
        return (
          <Icon
            icon={<Invoice />}
            color={
              location.pathname === '/ordoria/transations'
                ? theme.primaryActiveColor
                : themes?.default?.black54
            }
            size={20}
          />
        );

      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  };

  const handleDragEnd = (result) => {
    if (
      !result?.destination ||
      result.source.index === result.destination.index ||
      result.source.droppableId !== result.destination.droppableId
    )
      return;

    const menuMappingById = {
      'droppable-nav-menu-0': authUser?.currentAgent?._customer
        ? 'menuClientItems'
        : isDashboardAll || isReportAll
          ? 'menuAllItems'
          : 'menuItems',
      'droppable-nav-menu-1': 'generalItems',
      'droppable-nav-menu-2': 'docsItems',
    };

    const menuType = menuMappingById[result.destination.droppableId];

    const currentArrangement = [...navMenuPreferences.ordoria[menuType]];
    const [movedItem] = currentArrangement.splice(result.source.index, 1);
    currentArrangement.splice(result.destination.index, 0, movedItem);

    dispatch(
      appActions.setNavMenuPreferences({
        ...navMenuPreferences,
        ordoria: {
          ...navMenuPreferences.ordoria,
          [menuType]: currentArrangement,
        },
      }),
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <ScrollableSection>
        {MenuNavItems.map((menu, menuIndex) => (
          <Droppable
            droppableId={`droppable-nav-menu-${menuIndex}`}
            key={`menu-${menuIndex}`}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <OptionsWrapper
                  withoutBorder={menuIndex >= MenuNavItems.length - 1}
                >
                  <MenuTitleWrapper
                    isMinify={isMinify}
                    onClick={menu.onClick}
                    openSectionIndex={openSectionIndex}
                    theme={theme}
                    title={menu.title}
                    index={menuIndex}
                  >
                    {menu?.items?.map((item, itemIndex) => (
                      <Can
                        I="canview"
                        a={
                          item.indexOf('-accounting') !== -1
                            ? 'accounting'
                            : item
                        }
                      >
                        <Draggable
                          draggableId={`${item}-${menuIndex}-${itemIndex}`}
                          index={itemIndex}
                          key={`${item}-${menuIndex}-${itemIndex}`}
                        >
                          {(draggableProvided) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              style={{
                                ...draggableProvided.draggableProps.style,
                                top: 'auto !important',
                                left: 'auto !important',
                              }}
                            >
                              <MainNavItem
                                to={getToById(item)}
                                text={getTitleById(item)}
                                mode={mode}
                                icon={getIconById(item)}
                              />
                            </div>
                          )}
                        </Draggable>
                      </Can>
                    ))}
                    {provided.placeholder}
                  </MenuTitleWrapper>
                </OptionsWrapper>
              </div>
            )}
          </Droppable>
        ))}
      </ScrollableSection>
    </DragDropContext>
  );
}
