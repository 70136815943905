import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  TextField,
  ListSubheader,
  SxProps,
  Typography,
  Chip,
} from '@mui/material';
import styled from 'styled-components';
import { Icon } from '../Icon';
import { CheckSquare, MinusSquare, Square } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
export interface ICheckboxAutocompleteOption {
  _id: string;
  name?: string;
  label?: string;
  labelHeader?: string;
  disabled?: boolean;
  _parentCategory?: {
    _id: string;
    name: string;
  };
}

interface CheckboxAutocompleteProps {
  options: ICheckboxAutocompleteOption[];
  label: string;
  placeholder?: string;
  onChange: (options: ICheckboxAutocompleteOption[]) => void;
  onSearchChanged?: (text: string) => void;
  required?: boolean;
  defaultSelected?: ICheckboxAutocompleteOption[];
  isLoading?: boolean;
  maxTags?: number;
}

export const CheckboxAutocomplete: React.FC<CheckboxAutocompleteProps> = ({
  options,
  label,
  placeholder,
  onChange,
  onSearchChanged = () => {},
  required = false,
  defaultSelected,
  isLoading,
  maxTags = 8,
}) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState<
    ICheckboxAutocompleteOption[]
  >(defaultSelected || []);
  const [localOptions, setLocalOptions] = useState<
    ICheckboxAutocompleteOption[]
  >([]);

  useEffect(() => {
    setLocalOptions([]);

    const timer = setTimeout(() => {
      setLocalOptions(options);
    }, 0);

    return () => clearTimeout(timer);
  }, [options]);

  const allSelected = useMemo(() => {
    return (
      localOptions.filter((option) => !option.disabled).length ===
      selectedOptions.length
    );
  }, [localOptions, selectedOptions.length]);

  const handleToggleSelectAll = useCallback(() => {
    if (allSelected) {
      setSelectedOptions([]);
      onChange([]);
    } else {
      const fiteredOptions = localOptions.filter((option) => !option.disabled);
      setSelectedOptions(fiteredOptions);
      onChange(fiteredOptions);
    }
  }, [allSelected, onChange, localOptions]);

  const handleChange = useCallback(
    (e, value: ICheckboxAutocompleteOption[]) => {
      if (e.target.id === 'all') {
        handleToggleSelectAll();
      } else {
        setSelectedOptions(value);
        onChange(value);
      }
    },
    [handleToggleSelectAll, onChange],
  );

  const groupedOptions = useMemo(() => {
    const categories = localOptions.reduce(
      (acc, option) => {
        const category = option._parentCategory?.name || 'Uncategorized';
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(option);
        return acc;
      },
      {} as Record<string, ICheckboxAutocompleteOption[]>,
    );

    return Object.entries(categories).flatMap(([category, options]) => {
      if (category === 'Uncategorized') {
        return options;
      }
      return [
        { _id: `header-${category}`, name: category, disabled: true },
        ...options,
      ];
    });
  }, [localOptions]);

  return (
    <Autocomplete
      multiple
      options={[{ _id: 'all', name: t('common.all') }, ...groupedOptions]}
      disableCloseOnSelect
      getOptionLabel={(option) => option?.name || option?.label || ''}
      isOptionEqualToValue={(option, value) =>
        option._id === value._id || (option._id === 'all' && allSelected)
      }
      ListboxProps={{ style: { maxHeight: '250px' } }}
      limitTags={maxTags}
      renderTags={(value, getTagProps) =>
        value.slice(0, maxTags).map((option, index) => (
          <>
            <Chip
              {...getTagProps({ index })}
              size="small"
              key={option._id}
              label={option?.name || option?.label || ''}
            />
            {value.length > maxTags && index === maxTags - 1 && (
              <span className="MuiAutocomplete-tag MuiAutocomplete-tagSizeSmall">
                +{value.length - maxTags}
              </span>
            )}
          </>
        ))
      }
      renderOption={(props, option, { selected }) =>
        option._id.startsWith('header-') ? (
          <ListSubheader
            key={option._id}
            sx={{ ...ListSubheaderSx, color: theme.textBlack }}
          >
            {option?.labelHeader && (
              <Typography
                fontSize="10px"
                color={themes.default.lightGrayLabel}
                fontWeight="400"
              >
                {option.labelHeader}
              </Typography>
            )}
            {option.name}
          </ListSubheader>
        ) : (
          <StyledListItem {...props} id={option._id}>
            <Checkbox
              id={option._id}
              icon={<Icon icon={<Square weight="thin" />} size={24} />}
              indeterminateIcon={
                <Icon
                  icon={<MinusSquare weight="fill" />}
                  size={24}
                  color={theme.primary}
                />
              }
              checkedIcon={
                <Icon
                  icon={<CheckSquare weight="fill" />}
                  size={24}
                  color={theme.primary}
                />
              }
              style={{ padding: 0 }}
              checked={selected || (option._id === 'all' && allSelected)}
              indeterminate={
                option._id === 'all' &&
                selectedOptions.length > 0 &&
                !allSelected
              }
              size="small"
            />
            <Typography
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="16px"
              letterSpacing="0.17px"
              color={theme.textBlack}
            >
              {option?.labelHeader && (
                <Typography
                  fontSize="10px"
                  color={themes.default.lightGrayLabel}
                  fontWeight="400"
                >
                  {option.labelHeader}
                </Typography>
              )}
              {option?.name || option?.label || ''}
            </Typography>
          </StyledListItem>
        )
      }
      getOptionDisabled={(option) => !!option?.disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => onSearchChanged(e.target.value)}
          label={label}
          required={required}
          placeholder={selectedOptions.length > 0 ? '' : placeholder}
          InputLabelProps={
            !!placeholder
              ? {
                  shrink: true,
                }
              : {}
          }
        />
      )}
      value={selectedOptions}
      onChange={handleChange}
      sx={{
        '.MuiFormControl-root': {
          margin: '0px !important',
        },
        '& .MuiAutocomplete-inputRoot': {
          minHeight: '40px',
        },
        '& .MuiFormLabel-asterisk': {
          color: theme.progressRed,
        },
        minHeight: '40px',
      }}
      size="small"
      loading={isLoading}
      disabled={isLoading}
    />
  );
};

const StyledListItem = styled.li`
  height: 36px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ListSubheaderSx: SxProps = {
  padding: '4px 20px',
  fontSize: '0.875rem',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '0.17px',
};
