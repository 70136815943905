import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Typography } from '@mui/material';
import { themes } from 'styles/theme/themes';

function NameCell(params: CustomCellRendererProps) {
  const handleOpenFile = () => {
    if (params.value?.initialUrlData) {
      window.open(params.value.initialUrlData, '_blank');
    }
  };

  return (
    <Typography
      fontSize="0.9rem"
      onClick={handleOpenFile}
      sx={{
        cursor: 'pointer',
        ':hover': {
          color: themes.ordoria.primary,
        },
      }}
    >
      {params?.value?.nameData}
    </Typography>
  );
}

export default NameCell;
