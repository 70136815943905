import { Autocomplete, TextField, Typography, Paper } from '@mui/material';
import { InputBlur } from 'app/components/AccessBlur/InputBlur';
import { AssigneeType } from 'app/pages/Accessories/Types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { StateSetter } from 'types';
import React from 'react';
import { Check } from '@phosphor-icons/react';

interface Props {
  options: any[];
  isFetching: boolean;
  setSearch: StateSetter<string>;
  control?: any;
  disabled?: boolean;
  title: string;
  type?: AssigneeType;
  defaultValue?: {
    value: string;
    label: string;
    subLabel: string;
  };
  value?: {
    value: any;
    label: string;
    subLabel: string;
  } | null;
  required?: boolean;
  name?: string;
  width?: string;
}

export const AssigneeAutoComplete = ({
  options,
  isFetching,
  setSearch,
  control,
  disabled,
  title,
  type,
  defaultValue,
  value,
  name,
  required,
  width,
}: Props) => {
  const { t } = useTranslation();

  const renderOption = (props, option) => {
    return (
      <OptionWrapper {...props} key={option.value._id}>
        <OptionContent>
          <div>
            <Typography
              fontSize="0.75rem"
              color={
                props['aria-selected'] ? themes.default.primary : 'inherit'
              }
            >
              {option.label}
            </Typography>
            <Typography
              fontSize="0.625rem"
              color={themes.default.grey2}
              sx={{ marginTop: '4px' }}
            >
              {option.subLabel}
            </Typography>
          </div>
          {props['aria-selected'] && (
            <CheckIcon>
              {' '}
              <Check />{' '}
            </CheckIcon>
          )}
        </OptionContent>
      </OptionWrapper>
    );
  };

  return (
    <InputBlur subject={type === AssigneeType.vehicle ? 'vehicles' : 'users'}>
      <Controller
        name={type || name || ''}
        control={control}
        render={({ field }) => (
          <Autocomplete
            key={`autocomplete-${field.value || 'empty'}`}
            size="small"
            sx={{
              marginTop: 0,
              paddingTop: 0,
              maxWidth: width || 'auto',
              width: width || 'auto',
              '& .MuiAutocomplete-listbox': {
                maxHeight: '170px !important',
                height: 'fit-content !important',
              },
              '& .MuiAutocomplete-paper': {
                maxHeight: '170px !important',
                height: 'fit-content !important',
              },
              '& .MuiPaper-root': {
                maxHeight: '170px !important',
                height: 'fit-content !important',
              },
            }}
            ListboxProps={{
              style: {
                maxHeight: '170px',
                height: 'fit-content',
              },
            }}
            options={options}
            filterOptions={(options) => options}
            getOptionLabel={(option) => option.label}
            onChange={(e, option) => {
              field.onChange(option?.value);
            }}
            onBlur={() => setSearch('')}
            loading={isFetching}
            value={
              value !== undefined
                ? value
                : field.value && field.value !== null
                  ? options.find((opt) => opt.value === field.value)
                  : null
            }
            defaultValue={defaultValue}
            renderInput={(params) => {
              if (
                params.inputProps?.value === 'undefined' ||
                !params.inputProps?.value
              ) {
                params.inputProps.value = '';
              }
              return (
                <TextField
                  {...params}
                  {...field}
                  placeholder={
                    type === AssigneeType.vehicle
                      ? t('modules.vehicles')
                      : type === AssigneeType.employee
                        ? t('search.employees')
                        : name
                  }
                  sx={{
                    marginTop: 0,
                    '& .MuiFormControl-root': { marginTop: 0 },
                  }}
                  size="small"
                  variant="outlined"
                  label={t(title)}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  disabled={disabled}
                  required={required}
                />
              );
            }}
            renderOption={renderOption}
            disabled={disabled}
            PaperComponent={({ children, ...props }) => (
              <Paper
                {...props}
                sx={{
                  backgroundColor: 'white',
                  maxHeight: '170px !important',
                  height: 'fit-content !important',
                }}
              >
                {children}
              </Paper>
            )}
          />
        )}
      />
    </InputBlur>
  );
};

const OptionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const CheckIcon = styled.span`
  color: ${themes.default.primary};
  font-size: 12px;
  margin-left: 8px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;
  padding: 8px !important;
`;
