import { Collapse } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { themes } from 'styles/theme/themes';
import styled from 'styled-components';
import { AgGridReact } from '@ag-grid-community/react';
import ManagedGridList from 'app/components/ManagedGridList';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useHelpers } from '../../../../useHelpers';
import { GroupHeader } from 'app/components/ManagedGridList/components/GroupHeader';
import { useTranslation } from 'react-i18next';
import { getColumnDefs } from 'app/components/ManagedGrid/helpers';
import { getColumnConfig } from '../../../../config';
import { RowClassRules } from '@ag-grid-community/core';
import { useRestrictionsPermission } from 'app/components/Restrictions/hooks/useRestrictionsPermission';

export const CustomEmptyElement = () => {
  const { t } = useTranslation();
  return <EmptyText>{t('no_results')}</EmptyText>;
};
export const ListCollapsableCardOffer = ({
  categoryName,
  products,
  index,
  categoryKey,
  totalData,
}: {
  categoryName: string;
  products: any;
  index: number;
  categoryKey: string;
  totalData: number;
}) => {
  const { t } = useTranslation();
  const [processedProducts, setProcessedProducts] = useState([]);
  const [expanded, setExpanded] = useState(index === 0);
  const gridRef = useRef<AgGridReact>(null);
  const gridContext = useManagedContext<IGridContext>('grid');
  const isAdmin = useRestrictionsPermission();

  const rowClassRules: RowClassRules = useMemo(
    () => ({
      'disabled-row': (params) => {
        return !params.data?.__actions.isActive;
      },
    }),
    [],
  );

  const { getProcessedProductsFunction } = useHelpers();

  const columnDefs = useMemo(() => {
    if (!processedProducts) return;
    const columnConfig = getColumnConfig(t, categoryKey, isAdmin);
    const defs = getColumnDefs(processedProducts, columnConfig);

    return defs;
  }, [processedProducts, categoryKey]);

  useEffect(() => {
    if (products) {
      setProcessedProducts(getProcessedProductsFunction(products));
    }
  }, [products]);

  useEffect(() => {
    if (!products) return;
    gridContext.updateDataWithFunction((prev) => {
      prev.responseData = products;
      prev.loading = false;
      prev.rowData = processedProducts;
      prev.totalRows = totalData;
    });
  }, [processedProducts, gridContext.columnDefs?.length, totalData, isAdmin]);

  return (
    <GridWrapper>
      {totalData !== 0 && (
        <TitleContainer headerBackground={themes?.default?.roundtripGreyBg}>
          <GroupHeader
            onClick={() => setExpanded((prev) => !prev)}
            title={t(categoryName)}
            expanded={expanded}
            itemsCount={products.length || undefined}
          />
        </TitleContainer>
      )}
      <Collapse in={expanded}>
        <BodyContainer spacingTop={14}>
          <ManagedGridList
            gridRef={gridRef}
            rowData={processedProducts}
            columnDefs={columnDefs}
            loading={false}
            filtered={true}
            rowClassRules={rowClassRules}
            customEmptyElement={<CustomEmptyElement />}
          />
        </BodyContainer>
      </Collapse>
    </GridWrapper>
  );
};

export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
`;

export const TitleContainer = styled.div<{ headerBackground }>`
  background: ${(props) => props.headerBackground};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const BodyContainer = styled.div<{ spacingTop: number }>`
  margin-top: ${(props) => props.spacingTop}px;
`;
export const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
`;
