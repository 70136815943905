import { Alert, Avatar, IconButton, Stack, Typography } from '@mui/material';
import { BellRinging, CurrencyDollar, X } from '@phosphor-icons/react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { themes } from 'styles/theme/themes';
import { Icon } from '../Icon';
import styled from 'styled-components';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { MAILER_EVENTS } from './action';
import { OrderStatus, RoundtripStatus } from 'common/utils/enum';
import If from '../If';
import { ActivityStatusUpdated } from './components/ActivityStatusUpdated';
// import { NewLeaveRequest } from './components/NewLeaveRequest';
import { UpdatedLeaveRequest } from './components/UpdatedLeaveRequest';
import { NewLeaveRequest } from './components/NewLeaveRequest';
import { CollectorNotification } from './components/CollectorNotification';
import UserAvatar from '../UserAvatar';
import { DocumentNotification } from './components/DocumentNotification';

interface Props {
  onClose: () => void;
  content: React.ReactNode | any;
  url?: { path: string; options?: NavigateOptions };
  activityName?: string;
  openOrderDetail?: () => void;
  activityKey?: string;
}

export const Notification = ({
  onClose,
  content,
  url,
  activityName,
  openOrderDetail = () => {},
  activityKey,
}: Props) => {
  const navigate = useNavigate();
  const time = useRef(dayjs().format('HH:mm'));
  const status = content?.status || null;

  const handleIconColor = () => {
    const colorMap = {
      [MAILER_EVENTS.ORDER_STATUS_UPDATED]: {
        [OrderStatus.CANCELED]: themes?.default?.chipRedBg,
        [OrderStatus.DELIVERED]: themes?.default?.fruitSaladGreen,
      },
      [MAILER_EVENTS.ROUNDTRIP_STATUS_UPDATED]: {
        [RoundtripStatus.CANCELED]: themes?.default?.chipRedBg,
        [RoundtripStatus.COMPLETED]: themes?.default?.fruitSaladGreen,
      },
    };
    return activityName
      ? colorMap?.[activityName]?.[status] || themes?.default?.primary
      : themes?.default?.primary;
  };

  return (
    <Wrapper>
      <Alert
        variant="outlined"
        onClose={onClose}
        icon={
          activityKey === 'collect_amount_delivery' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                width: '36px',
                height: '36px',
                backgroundColor: themes?.default?.green,
                borderRadius: '50%',
              }}
            >
              <CurrencyDollar
                size={18}
                color={themes?.default?.accordionWhiteBg}
              />
              <div
                style={{ position: 'absolute', bottom: '9px', left: '44px' }}
              >
                {content?.avatar ? (
                  <Avatar
                    src={content?.avatar}
                    sx={{
                      width: '18px',
                      height: '18px',
                      border: `1px solid ${themes?.default?.accordionWhiteBg}`,
                    }}
                  />
                ) : (
                  <UserAvatar
                    name={getTextBetweenStrongTags(content?.content) ?? ''}
                    size={18}
                    border={`1px solid ${themes?.default?.accordionWhiteBg}`}
                    fontSize="0.5rem"
                  />
                )}
              </div>
            </div>
          ) : (
            <BellRinging size={28} color={handleIconColor()} />
          )
        }
        action={
          <>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onClose}
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
              }}
            >
              <Icon icon={<X />} />
            </IconButton>
            <If
              condition={
                activityName !== MAILER_EVENTS.ROUNDTRIP_STATUS_UPDATED &&
                activityName !== MAILER_EVENTS.ORDER_STATUS_UPDATED
              }
            >
              <Typography
                fontSize="0.85rem"
                color={themes?.default?.textColorSecondary}
              >
                {time.current}
              </Typography>
            </If>
          </>
        }
        onClick={() => {
          if (!url) {
            return;
          }
          onClose();
          navigate(url.path, { ...url.options });
        }}
        sx={{
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '4px',
          boxShadow: `0px 24px 48px -12px ${themes.default.notificationBoxShadow}`,
          alignItems: 'center',
          cursor: 'pointer',
          paddingRight: '40px',
          minWidth: '352px',
          height: '54px',
          background: themes?.default?.lightBlueBg,
          flex: 1,
        }}
      >
        <Stack
          onClick={() => openOrderDetail()}
          direction="row"
          justifyContent="space-between"
          flex={1}
        >
          <If condition={activityKey === 'status-updated'}>
            <ActivityStatusUpdated content={content?.content} status={status} />
          </If>
          <If condition={activityKey === 'new-leave-request'}>
            <NewLeaveRequest
              userName={content.userName}
              leaveRequestId={content.leaveRequestId}
            />
          </If>
          <If condition={activityKey === 'manager-updated-leave-request'}>
            <UpdatedLeaveRequest
              userName={content?.username}
              action={content?.action}
              byManager={content?.byManager}
            />
          </If>
          <If condition={activityKey === 'employee-updated-leave-request'}>
            <UpdatedLeaveRequest
              userName={content?.username}
              action={content?.action}
              byManager={content?.byManager}
            />
          </If>
          <If condition={activityKey === 'collect_amount_delivery'}>
            <CollectorNotification content={content?.content} />
          </If>
          <If condition={activityKey === 'documents_notification'}>
            <DocumentNotification content={content?.content} />
          </If>
        </Stack>
      </Alert>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${(props) => props.theme.gradiantBlueBg};
  padding: 1px;
  border-radius: 4px;
`;

export const getTextBetweenStrongTags = (
  content: string | null,
): string | null => {
  if (!content) return null;

  const match = content.match(/<strong>(.*?)<\/strong>/);
  return match ? match[1] : null;
};
