import { usePermission } from 'hooks/Abilities/usePermission';
import { CUSTOMERS, DISCOUNT_GROUP_SECTION } from '../consts';
import { useMatch } from 'react-router-dom';

const useDiscountPermission = () => {
  const canEditDiscountGroup = usePermission(DISCOUNT_GROUP_SECTION);
  const canEditCustomersGroup = usePermission(CUSTOMERS);

  const match = useMatch(`/${CUSTOMERS}/*`);
  const matchAppCustomers = useMatch(`/:app/${CUSTOMERS}/*`);

  return (action) => {
    if (match || matchAppCustomers) return canEditCustomersGroup(action);
    else return canEditDiscountGroup(action);
  };
};
export default useDiscountPermission;
