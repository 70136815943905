import { useEffect, useState } from 'react';

import { useMapsLibrary } from '@vis.gl/react-google-maps';

export default function RoundtripDirections({ roundtrip, map }) {
  const routesLibrary = useMapsLibrary('routes');

  // Init direction service
  const [directionsService, setDirectionsService] =
    useState<google.maps.DirectionsService>();
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer>();

  useEffect(() => {
    if (!map || !routesLibrary) return;

    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(
      new routesLibrary.DirectionsRenderer({
        map,
        suppressMarkers: true,
        suppressInfoWindows: true,
      }),
    );
  }, [map, routesLibrary]);

  useEffect(() => {
    if (!roundtrip || !directionsService || !directionsRenderer) return;

    const startPlace = roundtrip._startPlace;
    const lastPlace = roundtrip?.stops[roundtrip.stops.length - 1]?._place;

    const waypoints = roundtrip.stops.map((stop) => {
      return {
        location: new google.maps.LatLng(
          stop._place.location.geometry.coordinates[1],
          stop._place.location.geometry.coordinates[0],
        ),
        stopover: true,
      };
    });

    // remove last stop if it's the same as the start place
    if (lastPlace?._id === startPlace._id) {
      waypoints.pop();
    }

    if (lastPlace) {
      const directions = {
        origin: new google.maps.LatLng(
          startPlace.location.geometry.coordinates[1],
          startPlace.location.geometry.coordinates[0],
        ),
        destination: new google.maps.LatLng(
          lastPlace.location.geometry.coordinates[1],
          lastPlace.location.geometry.coordinates[0],
        ),
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints,
      };

      // TODO : Try to cache directions here
      directionsService.route(directions).then((response) => {
        console.log(response);
        directionsRenderer.setDirections(response);
      });
    }

    return () => directionsRenderer.setMap(null);
  }, [roundtrip, directionsService, directionsRenderer]);

  return null;
}
