import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const Form = styled.form`
  margin-bottom: 5vh;
  flex-direction: column;
`;
// height: 100vh !important;
export const FormWrapper = styled.div`
  padding: 16px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormSection = styled.div`
  position: relative;
`;

export const FormActions = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  z-index: 2;
  border-top: ${(props) => `1px solid ${props.theme.lightFill}`};
`;

export const SectionTitle = styled.div`
  font-size: 0.9375rem;
  font-weight: 500;
  padding-bottom: 16px;
  color: ${(props) => props.theme.black};
`;

export const ValidationAdornmentWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 14px;
  padding-left: 14px;
  background: white;
  height: 100%;
`;

export const IconButtonSx = (height?: string, btnIconWidth?: string) => {
  return {
    width: btnIconWidth || '24px',
    height: height || '24px',
    borderRadius: '4px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${themes.default.silver}`,
  };
};

export const QuantityInput = styled.input<{
  btnIconWidth?: string;
  height?: string;
}>`
  height: ${(props) => props.height || '24px'};
  width: ${(props) => props.width || '28px'};
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.174px;
  text-align: center;
  border: 1px solid ${themes.default.silver};
  background-color: ${themes.default.ordoriaBg};
  margin: 0;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
    border: 1px solid ${themes.default.primaryActiveColor};
  }
`;

export const UnitButtonSx = (width?: string, height?: string) => {
  return {
    ...IconButtonSx(height),
    width: width || '28px',
    background: themes.default.grey,
  };
};

export const QuantityInputLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 28px;
  border-radius: 4px;
  border: 1px solid ${themes.default.silver};
`;
