/*eslint-disable */
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Tooltip } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { isEmpty } from 'lodash';
import { GetCustomerFrequency } from 'app/pages/Customers/components/CustomerFrequencyIcons';
// import { useGetCustomerFinancialsQuery } from 'common/services/customerApi';
// useUpdateCustomerMutation,
// 'common/services/customerApi';
import { useGetSupplierQuery } from 'common/services/supplierApi';
import { CreditScoreBadge } from 'app/components/CreditScoreBadge';
import If from 'app/components/If';
import { GridProvider } from 'app/components/Grid/context';
import { SupplierInfoCard } from './components/SupplierInfoCard';
import { SupplierTabs } from './components/Tabs';
import { PlaceProvider } from './context';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { SupplierStatus } from 'common/types/Supplier';
import { InviteEmails } from './components/SupplierInfoCard/components/InviteEmails';
import { GridActionMenu } from 'app/components/Grid/components/GridActionMenu';
import { ActionsWrapper } from 'app/styledComponents/DetailsSection.styled';
import { SupplierFormcontext } from '../Form/context';
// import { ManagedCreateOrderDrawer } from 'app/pages/Ordoria/Orders/components/ManagedCreateOrderDrawer';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useSelector } from 'react-redux';
import { MenuOption } from 'app/components/CustomMenu/types';
import CallButton from '../../../Ordoria/CallPlan/components/ActionBar/components/CallButton';
import { usePhoneCallHook } from '../../../../../hooks/VoiceCall/useVoiceCallHook';
//import { ORDORIA_supplier, CUSTOMER, STOCKEE_supplier } from 'utils/routes';
import { useNavigateToSuppliers } from './hooks/useNavigateToSuppliers';

interface ContextProps {
  supplier: any;
  canEdit?: boolean;
  canExport?: boolean;
}

export const ViewSuppliercontext = createContext<ContextProps>({
  supplier: null,
  canEdit: false,
  canExport: false,
});

export const ViewSupplier = () => {
  const navigateToSuppliers = useNavigateToSuppliers();

  const { t } = useTranslation();
  const { id } = useParams();

  const can = usePermission('customers');
  const canAccounting = usePermission('accounting');
  const navigate = useNavigate();

  const canCall =
    canAccounting(Permission.MANAGE_PERSONAL) &&
    window.location.pathname?.includes('/ordoria/');
  const canEdit = can(Permission.EDIT);
  const canExport = can(Permission.EXPORT);

  const authUser = useSelector(selectAuthUser);
  const location = useLocation();

  const [openCreateOrderDrawer, setOpenCreateOrderDrawer] = useState(false);
  const [customer, setCustomer] = useState<any>(null);
  const [openInviteEmails, setOpenInviteEmails] = useState<boolean>(false);

  const { data: supplierDetail, isLoading } = useGetSupplierQuery(id, {
    skip: !id,
  });

  const {
    status: callStatus,
    handleCall,
    handleHangup,
    loading: voiceIsLoading,
  } = usePhoneCallHook(supplierDetail?._id, supplierDetail?.phone);

  // function handleChangeStatus(id: string) {
  //   updateCustomer({ id, status: CustomerStatus.ARCHIVED }).unwrap();
  // }

  // function handleCreateOrder(customer) {
  //   setCustomer(customer);
  //   setOpenCreateOrderDrawer(true);
  // }

  const handleBack = () => {
    const currentUrl = window.location.pathname;
    navigateToSuppliers(currentUrl);
  };

  const isSupplier = !isEmpty(authUser?.currentAgent?._supplier);

  useEffect(() => {
    const oldId = id;
    const newId = authUser?.currentAgent?._supplier?._id;
    if (oldId && newId && newId !== oldId) {
      navigate(
        `${location.pathname.replace(id, authUser.currentAgent._supplier._id)}`,
      );
    }
  }, [authUser?.currentAgent?._supplier?._id, navigate]);

  // const menuOptions = useMemo(() => {
  //   const options: MenuOption[] = [
  //     {
  //       label: 'orders.create_orders',
  //       action: () => handleCreateOrder(customerDetail),
  //     },
  //     {
  //       label: 'delete',
  //       action: () => handleChangeStatus(customerDetail?._id),
  //       isDelete: true,
  //     },
  //   ];

  //   return options;
  // }, [customerDetail?._id, handleChangeStatus]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <StyledForm>
      <TopSection>
        <Stack direction="row" gap="8px" alignItems="center">
          <If condition={!isSupplier}>
            <IconButton onClick={() => handleBack()}>
              <Icon icon={<ArrowLeft />} size="24px" />
            </IconButton>
          </If>
          <Avatar
            src={supplierDetail?.supplier?.logo?.[0]?.url}
            sx={{ width: '36px', height: '36px' }}
          />
          <Typography fontWeight={600}>
            {supplierDetail?.supplier?.publicName}
          </Typography>
          <If condition={!isSupplier}>
            <If condition={supplierDetail?.supplier?.creditScore}>
              <Tooltip title={t('supplier.creditScore')}>
                <div>
                  <CreditScoreBadge
                    score={supplierDetail?.supplier?.creditScore?.commonValue}
                    size="small"
                  />
                </div>
              </Tooltip>
            </If>
            {supplierDetail?.supplier?.cache?.financials?.frequencyStatus ===
            'noOrder' ? (
              <Tooltip title={t('supplier-info-no-orders-yet')}>
                <div>
                  {supplierDetail?.supplier?.cache?.financials
                    ?.frequencyStatus && (
                    <GetCustomerFrequency
                      size={24}
                      frequency={
                        supplierDetail?.supplier?.cache?.financials
                          ?.frequencyStatus
                      }
                    />
                  )}
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={t('supplier-order-frequency')}>
                <div>
                  {supplierDetail?.supplier?.cache?.financials
                    ?.frequencyStatus && (
                    <GetCustomerFrequency
                      size={24}
                      frequency={
                        supplierDetail?.supplier?.cache?.financials
                          ?.frequencyStatus
                      }
                    />
                  )}
                </div>
              </Tooltip>
            )}
          </If>

          {canCall && !isEmpty(supplierDetail?.supplier?.phone) ? (
            <CallButton
              onCall={handleCall}
              onHangup={handleHangup}
              isLoading={voiceIsLoading}
              status={callStatus}
              disabled={!supplierDetail?.phone}
            />
          ) : null}

          {/* <If condition={canEdit && !isSupplier}>
            <ActionsWrapper>
              <GridActionMenu options={menuOptions} />
            </ActionsWrapper>
          </If> */}
        </Stack>

        <SupplierInfoCard
          supplierDetail={supplierDetail}
          // customerFinancials={customerFinancials}
          // setInviteEmails={setOpenInviteEmails}
          isSupplier={isSupplier}
        />

        <InviteEmails
          customerDetail={supplierDetail}
          open={openInviteEmails}
          onClose={() => setOpenInviteEmails(false)}
        />
      </TopSection>

      <GridProvider>
        <PlaceProvider>
          <SupplierFormcontext.Provider
            value={{
              supplier: supplierDetail,
              canEdit:
                canEdit ||
                authUser?.currentAgent?._supplier?._id === supplierDetail?._id,
              canExport,
              isSupplier,
            }}
          >
            <SupplierTabs supplierDetail={supplierDetail} />
          </SupplierFormcontext.Provider>
        </PlaceProvider>
      </GridProvider>
    </StyledForm>
  );
};

const TopSection = styled.div`
  background-color: white;
  padding: 8px 20px 5px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledForm = styled.form`
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  background-color: ${themes?.default?.pageGreyBg};
`;
