import { Stack, Typography } from '@mui/material';
import { Circle, Check } from '@phosphor-icons/react';
import If from 'app/components/If';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Icon } from 'app/components/Icon';
import styled from 'styled-components';

interface Props {
  value: number;
  total: number;
}

export const CategoryValue = ({ value, total }: Props) => {
  const theme = useSelector(selectTheme);
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography
        fontSize="0.8125rem"
        fontWeight="400"
        lineHeight="20px"
        letterSpacing="0.1px"
        color={theme.clientMarkerBg}
      >
        {value}/{total}
      </Typography>
      <IconContainer>
        <If
          condition={value === total}
          otherwise={
            <Icon
              icon={<Circle weight="fill" />}
              size={8}
              color={theme.orange400}
            />
          }
        >
          <Icon icon={<Check />} color={theme.green800} />
        </If>
      </IconContainer>
    </Stack>
  );
};

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
`;
