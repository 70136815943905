import { useNavigate } from 'react-router-dom';
import { CUSTOMER } from 'utils/routes';

export const useNavigateToCustomers = () => {
  const navigate = useNavigate();

  const navigateToCustomers = (
    currentUrl: string,
    fromUrl: string | undefined,
  ) => {
    const basePath = currentUrl.split(CUSTOMER)[0];
    navigate(fromUrl ?? `${basePath}${CUSTOMER}`);
  };

  return navigateToCustomers;
};
