import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import _ from 'lodash';
import { useState } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'app/components/CustomCheckbox';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';
import { Check } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';

type DataItem = {
  value: string | number;
  label: string;
  color?: string;
};

export type SelectFieldProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string;
  data: DataItem[] | string[];
  checkboxes?: boolean;
  renderValue?: any;
  multiple?: boolean;
  values?: any;
  required?: boolean;
  disabled?: boolean;
  renderItem?: (option: any, isSelected: boolean) => React.ReactNode;
  style?: React.CSSProperties;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 290,
    },
  },
};

export const FormSelectField = <T extends FieldValues>(
  props: SelectFieldProps<T>,
) => {
  const controllerProps = _.omit(props, ['label', 'data']);
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController(controllerProps);
  const { formatError } = useErrorFormatter();
  const { t } = useTranslation();
  const label = `${t(props.label)} ${!!props.required ? '*' : ''}`;

  const transformedData: DataItem[] =
    Array.isArray(props.data) && typeof props.data[0] === 'string'
      ? props.data.map((item) => ({ value: item, label: item }))
      : (props.data as DataItem[]);

  const [selected, setSelected] = useState<DataItem[]>(
    props.multiple ? (Array.isArray(value) ? value : value ? [value] : []) : [],
  );

  const handleSelect = (selectedValue: DataItem) => {
    const newSelected = selected.includes(selectedValue)
      ? selected.filter((v) => v !== selectedValue)
      : [...selected, selectedValue];

    setSelected(newSelected);
    onChange(newSelected);
  };

  const getItemLabel = (item: DataItem | string | number) => {
    return typeof item === 'object' ? item.label : String(item);
  };

  return (
    <ManagedTooltip
      disabled={!props.disabled}
      title={selected?.map((d) => getItemLabel(d))?.join(', ')}
      style={{ flex: 1 }}
    >
      <FormControl
        fullWidth
        error={!!error}
        disabled={!!props.disabled}
        style={props.style}
      >
        <InputLabel id={props.name} size="small">
          {label}
        </InputLabel>

        <Select
          size="small"
          name={props.name}
          required={props.required}
          onChange={(e) => {
            if (props.checkboxes) {
              return;
            }
            const newValue = props.multiple
              ? Array.isArray(e.target.value)
                ? e.target.value
                : [e.target.value]
              : e.target.value;
            onChange(newValue);
          }}
          onBlur={onBlur}
          label={label}
          value={
            props.multiple
              ? Array.isArray(value)
                ? value
                : value
                ? [value]
                : []
              : value
          }
          renderValue={
            props.renderValue ||
            ((selected) => {
              if (props.multiple) {
                return selected?.map(getItemLabel).join(', ');
              }
              const option = transformedData.find(
                (d: any) => d.value === selected,
              );
              return option ? getItemLabel(option) : '';
            })
          }
          multiple={props.multiple}
          disabled={!!props.disabled}
          MenuProps={MenuProps}
        >
          {props.checkboxes
            ? transformedData.map((d, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handleSelect(d)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox checked={selected?.includes(d)} />
                  <ListItemText primary={getItemLabel(d)} />
                </MenuItem>
              ))
            : transformedData.map((d: any, i) => {
                const isSelected = d.value === value;
                return (
                  <MenuItem
                    key={`${props.name}-${i}`}
                    value={d.value}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      justifyContent: 'space-between',
                    }}
                  >
                    {props.renderItem ? (
                      props.renderItem(d, isSelected)
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <span
                          style={{
                            color: isSelected
                              ? themes?.default?.primaryActiveColor
                              : 'inherit',
                          }}
                        >
                          {d.label}
                        </span>
                        {isSelected && (
                          <span
                            style={{
                              color: themes?.default?.primaryActiveColor,
                              fontSize: '12px',
                            }}
                          >
                            <Check weight="bold" />
                          </span>
                        )}
                      </div>
                    )}
                  </MenuItem>
                );
              })}
        </Select>
        {error && <FormHelperText>{formatError(error)}</FormHelperText>}
      </FormControl>
    </ManagedTooltip>
  );
};

export default FormSelectField;
