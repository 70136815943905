import { TwoViewModal } from 'app/components/TwoViewModal';
import { useTranslation } from 'react-i18next';
import {
  Filteration,
  ProductsCategories,
  ProductRow,
} from '../../../../../../../../../components/ProductByTopCategory';
import styled from 'styled-components';
import { FormActions } from 'app/components/Form/styles';
import { Button } from '@mui/material';
import { useHelpers } from '../../useHelpers';
import { useState, useEffect, useMemo, createContext } from 'react';
import { useQueryArray } from 'common/hooks/useQueryArray';
import { IOrderFilter } from '../../../../../../../../../../Ordoria/CreateOrders/components/Filter';
import { useParams } from 'react-router-dom';
import { useFetchTopProductsCategory } from 'hooks/useFetchTopProductsCategory';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { isEqual } from 'lodash';
import { StateSetter } from 'types';

interface ContextProps {
  selectedRemovedIds: string[];
  setSelectedRemovedIds: StateSetter<string[]>;
}

export const AddProductDiscountContext = createContext<ContextProps>({
  selectedRemovedIds: [],
  setSelectedRemovedIds: () => {},
});

export const AddDiscountProduct = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { handleAddProductDiscount, deleteDiscount } = useHelpers();
  const [searchText, setSearchText] = useState<string>('');
  const [selectedRemovedIds, setSelectedRemovedIds] = useState<string[]>([]);

  const [categories, setCategories] = useState<
    { value: string; label: string }[]
  >([]);

  const initFilters = useMemo(() => {
    return {
      favorite: false,
      hasDiscount: false,
      new: false,
      frequentlyOrdered: false,
      categories: [],
      price: { minPrice: 0, maxPrice: 0 },
    };
  }, []);

  const [filters, setFilters] = useState<IOrderFilter>(initFilters);

  const hasFilters = useMemo(() => {
    return !isEqual(filters, initFilters) || !!searchText;
  }, [filters, searchText]);

  const { id: customerId } = useParams();
  const handleSearchTextChange = (newValue) => {
    setSearchText(newValue);
  };
  const urlParams = useQueryArray({
    _customer: customerId,
    search: searchText,
    isFavorite: filters.favorite,
    isNew: filters.new,
    orderFrequency: filters.frequentlyOrdered ? 'high' : false,
    hasDiscount: filters.hasDiscount,
    _category: !!filters?.categories.length
      ? filters?.categories?.map((cat) => cat?.value)
      : undefined,
    price:
      filters.price && filters?.price?.minPrice && filters?.price?.maxPrice
        ? JSON.stringify({
            min: filters.price.minPrice,
            max: filters.price.maxPrice,
          })
        : undefined,
  });
  const { productsData, isLoading, refetch, isFetching } =
    useFetchTopProductsCategory({
      urlParams: urlParams,
    });

  useEffect(() => {
    if (open && !!productsData?.length) {
      setFilters(initFilters);
      refetch();
    }
  }, [open]);

  return (
    <AddProductDiscountContext.Provider
      value={{
        selectedRemovedIds,
        setSelectedRemovedIds,
      }}
    >
      <TwoViewModal
        width="420px"
        title={t('add_products')}
        onOpen={open}
        onClose={onClose}
      >
        <Wrapper>
          {(isLoading || isFetching) && !hasFilters && (
            <EmptyCenter>
              <LoadingPulse />
            </EmptyCenter>
          )}
          {((!isLoading && !isFetching) || hasFilters) && (
            <>
              <FilterSection>
                <Filteration
                  searchText={searchText}
                  handleSearchTextChange={handleSearchTextChange}
                  filters={filters}
                  setFilters={setFilters}
                  categoriesData={categories}
                />
              </FilterSection>
              <CategoriesSection>
                <ProductsCategories
                  productsCategories={productsData}
                  onAddAction={handleAddProductDiscount}
                  onRemoveAction={deleteDiscount}
                  ProductRow={ProductRow}
                  urlParams={urlParams}
                  setCategories={setCategories}
                />
              </CategoriesSection>
            </>
          )}
        </Wrapper>
        <FormActions>
          <Button variant="contained" onClick={onClose} color="primary">
            {t('common.buttons.done')}
          </Button>
        </FormActions>
      </TwoViewModal>
    </AddProductDiscountContext.Provider>
  );
};
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const EmptyCenter = styled.div`
  position: absolute;
  top: 250px;
  right: 50%;
`;

const FilterSection = styled.div`
  display: flex;
  position: sticky;
  flex-direction: column;
  top: 0;
`;

const CategoriesSection = styled.div`
  display: flex;
  flex-direction: column;
`;
