import { CustomCellRendererProps } from '@ag-grid-community/react';

function DocumentIdCell(params: CustomCellRendererProps) {
  return (
    <div>
      <span>{params?.value?.documentIdData}</span>
    </div>
  );
}

export default DocumentIdCell;
