import { Button } from '@mui/material';
import { ArrowBendUpLeft, X } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Application } from 'common/store/app/types';
import { useApplication } from 'hooks/useApplication';

interface Props {
  customerId: string;
  onClose?: () => void;
}

export const ReturnButton = ({ customerId, onClose = () => {} }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentApplication: Application = useApplication();

  const handleReturn = () => {
    if (currentApplication.id === 'bianta') {
      navigate(`/customers/${customerId}/info?tab=branches`);
    } else {
      navigate(
        `/${currentApplication.id}/customers/${customerId}/details?tab=branches`,
      );
    }
  };

  const returnButtonLink = useMemo<string>(() => {
    if (currentApplication.id === 'bianta')
      return `/customers/${customerId}/info?tab=branches`;
    else
      return `/${currentApplication.id}/customers/${customerId}/details?tab=branches`;
  }, [currentApplication.id, customerId]);

  const [showButton, setShowButton] = useState(true);

  const handleClose = useCallback(() => {
    const { pathname } = location;

    navigate(pathname);
    onClose();
    setShowButton(false);
  }, [location, navigate, onClose]);

  return (
    <div style={{ display: showButton ? 'flex' : 'none' }}>
      <NavLink to={returnButtonLink}>
        <Button
          variant="contained"
          sx={{
            marginLeft: '16px',
            borderRadius: '4px',
            backgroundColor: '#37474F',
            '&:hover': {
              backgroundColor: '#415158',
            },
          }}
          onClick={() => handleReturn()}
          startIcon={<ArrowBendUpLeft />}
        >
          {t('common-buttons-return')}
        </Button>
      </NavLink>
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: '#37474F',
          '&:hover': {
            backgroundColor: '#415158',
          },
          minWidth: 0,
          borderRadius: '0 4px 4px 0',
          borderLeft: `0.5px solid ${themes.default.whiteStickyBackGround}`,
          padding: 0,
        }}
      >
        <IconWrapper>
          <Icon icon={<X />} color="white" onClick={handleClose} />
        </IconWrapper>
      </Button>
    </div>
  );
};

const IconWrapper = styled.div`
  padding: 4px 10px;
`;
