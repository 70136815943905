import { IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import { useState } from 'react';
import { Filter } from '../../../Ordoria/CreateOrders/components/Filter';
import _without from 'lodash/without';
import SearchField from 'app/components/Search/SearchField';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Icon } from 'app/components/Icon';
import { FunnelSimple } from '@phosphor-icons/react';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
import { FilterChips } from 'app/components/FilterChips';
import If from 'app/components/If';

export const Filteration = ({
  searchText,
  handleSearchTextChange,
  filters,
  setFilters,
  categoriesData,
}) => {
  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const { filtersCount } = useFilterState({ filters });
  const theme = useSelector(selectTheme);

  return (
    <>
      <FilterWrapper>
        <StyledSearch>
          <SearchField
            value={searchText}
            suppressMarginBottom
            setValue={handleSearchTextChange}
            height={28}
          />
        </StyledSearch>
        <StyledFilter>
          <IconButton onClick={(e) => setFilterAnchorEl(e.currentTarget)}>
            <Icon
              icon={<FunnelSimple />}
              color={filtersCount > 0 ? theme.primary : ''}
            />
            <If condition={filtersCount > 0}>
              <Typography
                fontSize="0.625rem"
                lineHeight="0.875rem"
                fontWeight="500"
                color={theme.primary}
              >
                ({filtersCount})
              </Typography>
            </If>
          </IconButton>
        </StyledFilter>
      </FilterWrapper>
      <FilterChipsContainer>
        <FilterChips filters={filters} setFilters={setFilters} />
      </FilterChipsContainer>

      <Filter
        anchorEl={filterAnchorEl}
        closeModal={() => setFilterAnchorEl(null)}
        filters={filters}
        setFilters={setFilters}
        categoriesData={categoriesData || []}
        categories={[]}
      />
    </>
  );
};

const FilterChipsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSearch = styled.div`
  width: 85%;
  margin-left: 10px;
`;
const StyledFilter = styled.div`
  margin-top: 10px;
`;
