import { getImgCdn } from 'common/helpers/cdn';
import NoPhoto from 'assets/img/ORDORIA/Catalog/NoPhoto.svg';
import { t } from 'i18next';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';
import { useStatusToaster } from 'hooks/useStatusToaster';
import { useHelpers as useContractGridHelper } from '../../../ContractsTab/useHelpers';
import { useHelpers as useProductsHelpers } from 'app/pages/Products/hooks/useHelpers';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { discountIndices } from 'app/components/Restrictions/types/discounts.types';
import { DiscountName } from 'app/components/Restrictions/types/discounts.types';
import { useCallback } from 'react';
import { StatusToastVariant } from 'common/types/StatusToasterNotification';
import { useParams } from 'react-router-dom';
import { formatNumber } from 'utils/helpers';
import {
  useRemoveDiscountMutation,
  useToggleActiveDiscountMutation,
  useToggleStatusDiscountMutation,
  useAddProducDiscountNoteMutation,
  useDeleteProductDiscountNoteMutation,
  useEditProductDiscountNoteMutation,
  useResetContractProductDiscountsMutation,
  useAddProductDiscountMutation,
} from 'common/services/productsDiscountsApi';

export const useHelpers = () => {
  const { id: customerId } = useParams();
  const can = usePermission('customers');
  const { callApi } = useAsyncDataV2();
  const [toggleStatusDiscount] = useToggleStatusDiscountMutation();
  const [toggleActiveDiscount] = useToggleActiveDiscountMutation();
  const [removeProductDiscount] = useRemoveDiscountMutation();

  const [resetContractProductDiscount] =
    useResetContractProductDiscountsMutation();

  const [addProductDiscountNote] = useAddProducDiscountNoteMutation();
  const [deleteProductDiscountNote] = useDeleteProductDiscountNoteMutation();
  const [editProductDiscountNote] = useEditProductDiscountNoteMutation();
  const { isAdmin, handleUpdateProductsDiscounts } = useContractGridHelper();
  const { getCategoryHierarchy } = useProductsHelpers();
  const [addProductDiscount] = useAddProductDiscountMutation();

  const statusToaster = useStatusToaster();

  function getProcessedProductsFunction({
    data,
    rowsEditedBySales,
    setRowsEditedBySales,
  }) {
    return data?.map((prod) => {
      const categoryHierarchy = getCategoryHierarchy(prod.cache?._category);

      const pictureKey =
        prod?._product?.pictures?.length > 0
          ? prod?._product?.pictures[0]?.key
          : '';

      const pictureUrl = !!pictureKey ? getImgCdn(pictureKey, 64) : NoPhoto;

      return {
        id: prod._id || prod._product,

        product: {
          cellData: {
            ...prod,
            ...prod._product,
            pictureUrl,
            productId: prod._product?._id,
          },
          boxingUnits: prod._product?.boxingUnit,
          hasDisableIcon: prod.disabled,
        },
        category: {
          cellData: categoryHierarchy[0].name,
        },
        subcategory: {
          cellData: categoryHierarchy[1]?.name || '-',
        },
        childcategory: {
          cellData: categoryHierarchy?.[2]?.name || '-',
        },
        selling_price: {
          cellData: `€ ${
            prod?.sellingPrice ? formatNumber(prod.sellingPrice) : 0
          }`,
        },
        ...discountIndices.reduce((all, i) => {
          const ri: DiscountName = `r${i}`;
          const value = formatDiscountValue(
            rowsEditedBySales.find(
              (rowSales) => rowSales._product === prod._product._id,
            )?.discounts?.[ri] ||
              prod?.discounts?.[ri] ||
              0,
          );

          const v = {
            cellData: value,
            hasReset: value !== 0 && can(Permission.EDIT),
            id: prod._id,
            onReset: () => resetAction(prod._id, ri),
            field: ri,
            onAction: async (discount) => {
              if (isAdmin()) {
                await handleUpdateProductsDiscounts([
                  {
                    _product: prod._product._id,
                    discounts: { [ri]: discount / 100 },
                  },
                ]);
              } else if (setRowsEditedBySales) {
                setRowsEditedBySales((prev) => {
                  if (
                    prev.some(
                      (editedProduct) =>
                        editedProduct._product === prod._product._id,
                    )
                  ) {
                    return prev.map((editedProduct) => {
                      if (editedProduct._product === prod._product._id) {
                        return {
                          ...editedProduct,
                          discounts: {
                            ...editedProduct.discounts,
                            [ri]: discount / 100,
                          },
                        };
                      } else {
                        return editedProduct;
                      }
                    });
                  } else {
                    return [
                      ...prev,
                      {
                        _product: prod._product._id,
                        discounts: { [ri]: discount / 100 },
                      },
                    ];
                  }
                });
              }
            },
            discountIndex: i,
            canEdit: can(Permission.EDIT),
            disabled: prod.disabled,
          };
          all[ri] = v;
          return all;
        }, {}),
        net_ht: {
          cellData: `${
            prod?.discountedPrice
              ? '€ ' + formatNumber(prod.discountedPrice)
              : '-'
          }`,
        },
        profit: {
          cellData: `€ ${prod?.profit ? formatNumber(prod.profit) : 0}`,
        },
        margin: {
          cellData: `${prod?.margin ? formatNumber(prod.margin * 100) : 0}%`,
          margin: prod.margin,
        },
        status: {
          cellData: prod?.status,
        },
        approve: {
          cellData: prod,
        },
        notes: {
          _id: prod._id,
          name: prod._product.name,
          notes: prod.notes || [],
          handleAddNote: handleAddNote,
          handleEditNote: handleEditNote,
          handleDeleteNote: handleDeleteNote,
          getIsLoadingNotes: () => {
            return false;
          },
          subTitle: t('productDiscounts.add_note'),
        },
        __actions: {
          cellData: prod,
          isActive: !prod.disabled,
        },
      };
    });
  }

  const approveProductDiscount = useCallback(
    async (ids: string[]) => {
      await callApi(
        async () => {
          statusToaster({
            variant: StatusToastVariant.Loading,
            spacingRight: '0px',
          });
          return await toggleStatusDiscount({
            body: { status: 'approved', ids: ids, _customer: customerId || '' },
          }).unwrap();
        },
        {
          onSuccess: () =>
            statusToaster({
              variant: StatusToastVariant.Success,
              message: t('approved'),
              spacingRight: '0px',
            }),
          onError: () =>
            statusToaster({
              variant: StatusToastVariant.Error,
              spacingRight: '0px',
            }),
        },
      );
    },
    [callApi],
  );

  const handleAddNote = useCallback(
    async (_id: string, content: string) => {
      await callApi(async () => {
        return await addProductDiscountNote({
          productId: _id,
          body: { content: content, _customer: customerId || '' },
        }).unwrap();
      });
    },
    [callApi],
  );

  const handleEditNote = useCallback(
    async (_id: string, note: string, content: string) => {
      await callApi(async () => {
        return await editProductDiscountNote({
          productId: _id,
          body: {
            content: content,
            _customer: customerId || '',
            _note: note,
          },
        }).unwrap();
      });
    },
    [callApi],
  );

  const handleDeleteNote = useCallback(
    async (_id: string, note: string) => {
      await callApi(async () => {
        return await deleteProductDiscountNote({
          productId: _id,
          body: {
            _customer: customerId || '',
            _note: note,
          },
        }).unwrap();
      });
    },
    [callApi],
  );

  const formatDiscountValue = useCallback((discount: number): number => {
    const value = (discount * 100).toFixed(2);
    const formattedValue = value.endsWith('.00')
      ? parseInt(value, 10)
      : parseFloat(value);
    return formattedValue;
  }, []);

  const deleteDiscount = useCallback(
    async (id: string, type = '', hasConfirmation = true) => {
      let params = '';
      if (type === 'products') {
        params = `?_customer=${customerId}&productIds[]=${id}`;
      } else {
        params = `?_customer=${customerId}&ids[]=${id}`;
      }

      callApi(
        () => {
          removeProductDiscount(params).unwrap();
        },
        {
          confirmationProps: hasConfirmation
            ? {
                title: t('product_dicounts.remove_title'),
                message: t('product_discounts.remove_subtitle'),
              }
            : undefined,
          actionText: t('common.remove'),
          successMessage: t('product_discounts.delete_success'),
          buttonProps: { color: 'error' },
        },
      );
    },
    [callApi],
  );

  const enableDisableDiscount = useCallback(
    async (ids: string[], isActive: boolean) => {
      callApi(
        async () => {
          await toggleActiveDiscount({
            body: {
              ids: ids,
              disabled: isActive,
              _customer: customerId || '',
            },
          }).unwrap();
        },
        {
          confirmationProps: {
            title: isActive
              ? t('product_discounts.disable_title')
              : t('product_discounts.enable_title'),
            message: isActive
              ? t('product_discounts.disable_body')
              : t('product_discounts.enable_body'),
          },
          actionText: isActive ? t('common.disable') : t('common.enable'),
          successMessage: isActive
            ? t('product_discounts.disable_success')
            : t('product_discounts.enable_success'),
          buttonProps: { color: isActive ? 'error' : 'primary' },
        },
      );
    },
    [callApi],
  );

  const resetAction = async (id: string, field: string) => {
    return await resetContractProductDiscount({
      body: {
        _customer: customerId || '',
        discounts: [
          {
            _id: id,
            discountNames: field === 'all' ? ['r1', 'r2', 'r3'] : [field],
          },
        ],
      },
    }).unwrap();
  };

  const reset = useCallback(
    async (id: string, field: string) => {
      callApi(
        async () => {
          await resetAction(id, field);
        },
        {
          confirmationProps: {
            title: t('productDiscounts.reset_all_title'),
            message: t('productDiscounts.reset_all_body'),
          },
          actionText: t('common.reset'),
          successMessage: t('productDiscounts.reset_success'),
          buttonProps: { color: 'error' },
        },
      );
    },
    [callApi],
  );

  const handleAddProductDiscount = useCallback(
    async (productId: string, name?: string) => {
      await callApi(
        async () => {
          return await addProductDiscount({
            body: {
              _customer: customerId || '',
              _product: productId,
              // products: [
              //   {
              //     _product: productId,
              //   },
              // ],
            },
          }).unwrap();
        },
        {
          successMessage: t('common.add_success', { name: name }),
        },
      );
    },
    [callApi],
  );

  return {
    getProcessedProductsFunction,
    approveProductDiscount,
    deleteDiscount,
    enableDisableDiscount,
    reset,
    handleAddProductDiscount,
  };
};
