import {
  Button,
  CircularProgress,
  Collapse,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { DiscountInput } from 'app/pages/DiscountGroups/Components/BulkEdit/Components/DiscountField';
import { ButtonLoadingContainer } from 'app/pages/Ordoria/Orders/components/CreateOrderDrawerSales';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';
import { DrawerActinosWrapper } from '../AddContractsModal';
import { useRestrictionsContextOf } from 'app/components/Restrictions/hooks/useRestrictionsContextOf';
import { useCallback, useState, useMemo } from 'react';
import { isNumber } from 'lodash';
import {
  FormData,
  FormDataValue,
} from 'app/pages/DiscountGroups/Components/types';
import { useHelpers } from '../../useHelpers';
import { OrderAlert } from 'app/pages/Ordoria/ViewEditOrder/components/OrderAlert';
import { Icon } from 'app/components/Icon';
import { Lock } from '@phosphor-icons/react';
import If from 'app/components/If';
import {
  discountIndices,
  DiscountIndex,
  DiscountName,
} from 'app/components/Restrictions/types/discounts.types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (discounts: FormData) => void;
  showLimitAlert?: boolean;
  canEditLockedFields?: boolean;
}

export const EditDiscountPopover = ({
  open,
  onClose,
  onSubmit,
  showLimitAlert = false,
  canEditLockedFields = false,
}: Props) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const { getEmptyDiscounts } = useHelpers();

  const { value: restrictions } = useRestrictionsContextOf('restrictions');

  const [discounts, setDiscounts] = useState<FormData>(
    getEmptyDiscounts(restrictions, true),
  );

  const getDicscountValueDetails = useCallback(
    (i: DiscountIndex) => {
      let result: string = '';
      const max = restrictions?.max?.[`r${i}`];
      const limit = restrictions?.limit?.[`r${i}`];

      if (isNumber(max)) {
        result = `Max. ${max * 100}%`;
      }
      if (isNumber(limit)) {
        result = result + ` Lim. ${limit * 100}%`;
      }
      return result;
    },
    [restrictions?.limit, restrictions?.max],
  );

  const onFieldChanged = useCallback(
    (field: DiscountName, value: FormDataValue) => {
      if (isNumber(value)) {
        setDiscounts((prevDiscounts) => ({
          ...prevDiscounts,
          [field]: value / 100,
        }));
      } else {
        setDiscounts((prevDiscounts) => {
          const { [field]: _, ...rest } = prevDiscounts;
          return rest as FormData;
        });
      }
    },
    [],
  );

  const exceededDiscounts = useMemo(() => {
    return discountIndices.filter((i: DiscountIndex) => {
      const limit = restrictions?.limit?.[`r${i}`];
      return isNumber(limit) && (discounts?.[`r${i}`] || 0) > limit;
    });
  }, [discounts, restrictions?.limit]);

  const exceededDiscountsString = useMemo(() => {
    return exceededDiscounts.map((i) => t('discount_abbrev') + i).join(', ');
  }, [exceededDiscounts, t]);

  const handleSubmit = useCallback(() => {
    onSubmit(discounts);
    onClose();
  }, [discounts, onClose, onSubmit]);

  return (
    <TwoViewModal
      onOpen={open}
      onClose={onClose}
      defaultMode="floated"
      autoHeight
      headerStyle={{ padding: '10px 6px 10px 20px' }}
      title={t('DiscountGroups.bulk_edit_title')}
    >
      <Stack padding="24px 20px 0px 20px">
        <Collapse in={showLimitAlert && exceededDiscounts.length > 0}>
          <OrderAlert
            severity="info"
            content={t('contracts.edit_discount_info_message_sales', {
              name: exceededDiscountsString,
            })}
            sx={AlertSx}
          />
        </Collapse>
        <Typography
          fontWeight="400"
          fontSize="12px"
          lineHeight="20px"
          letterSpacing="0.15px"
          color={theme.black2}
        >
          {t('contracts.edit_discount_effect')}
        </Typography>
        <Table
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0px 24px',
            tableLayout: 'auto',
          }}
        >
          <TableBody>
            {discountIndices.map((i: DiscountIndex) => (
              <TableRow key={i}>
                <TableCell sx={{ padding: '0px 12px 0px 0px', border: 'none' }}>
                  <DiscountInput
                    i={i}
                    initialValue={0}
                    onChanged={(field, value) => onFieldChanged(field, value)}
                    hideLockIcon
                    canEditLockedFields={canEditLockedFields}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    padding: '0px',
                    border: 'none',
                    width: '1%',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <If
                    condition={!restrictions?.lock?.[`r${i}`]}
                    otherwise={<Icon icon={<Lock />} size={16} />}
                  >
                    <Typography
                      fontSize="0.75rem"
                      lineHeight="20px"
                      fontWeight="500"
                      letterSpacing="0.15px"
                      color={theme.black2}
                      width="fit-content"
                    >
                      {getDicscountValueDetails(i)}
                    </Typography>
                  </If>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
      <DrawerActinosWrapper>
        <Button onClick={onClose} color="inherit">
          {t('common.buttons.cancel')}
        </Button>
        <Button onClick={handleSubmit} variant="contained" autoFocus>
          {false && (
            <ButtonLoadingContainer>
              <CircularProgress
                size="16px"
                sx={{ color: themes.default.accordionWhiteBg }}
              />
            </ButtonLoadingContainer>
          )}
          <span style={{ opacity: false ? 0 : 1 }}>{t('apply')}</span>
        </Button>
      </DrawerActinosWrapper>
    </TwoViewModal>
  );
};

const AlertSx: SxProps = {
  padding: '14px 16px',
  marginBottom: '12px',
  borderRadius: '4px',
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '20px',
  letterSpacing: '0.15px',
};
