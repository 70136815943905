import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import { FilterSection } from 'app/components/FilterComponents/styles';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import If from 'app/components/If';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import { useEffect, useState } from 'react';
import {
  useGetCustomerBranchesQuery,
  useGetCustomersBasicQuery,
} from 'common/services/customerApi';
import { isEmpty } from 'lodash';
import { RefundType } from '../../config';
import CheckboxGrid from 'app/components/FilterComponents/components/CheckboxGrid';
import { useCheckValues } from 'app/components/FilterComponents/hooks/useCheckValues';

export function RefundsFilter() {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext>('grid');
  const {
    queryParams: { filters },
  } = useManagedContext<IGridContext>('grid');
  const { checkedValues, setCheckedValues } = useCheckValues(filters);

  const [searchCustomer, setSearchCustomer] = useState<string>('');

  const { data: customers } = useGetCustomersBasicQuery(
    `text=${searchCustomer}`,
  );

  const { data: customerDeck } = useGetCustomerBranchesQuery(
    {
      customerId: gridContext?.queryParams?.filters?.customerId?.[0]?.value,
      urlQuery: '',
    },
    { skip: isEmpty(gridContext?.queryParams?.filters?.customerId) },
  );

  const { isCustomer } = useGetUserAcl();

  const handleClearAll = () => {
    setCheckedValues({});

    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters = {
        archived: false,
        customerId: [],
        customerDeckId: [],
        type: [],
      };
    });
  };

  const handleOptionsChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters[filterKey] = options;
    });
  };

  useEffect(() => {
    if (isEmpty(gridContext.queryParams.filters?.customerId)) {
      gridContext.updateDataWithFunction((prev) => {
        prev.queryParams.filters.customerDeckId = [];
      });
    }
  }, [gridContext.queryParams.filters.customerId]);

  const refundsType: RefundType[] = [RefundType.CREDIT, RefundType.RETURN];

  return (
    <>
      <FilterHeader onClearAll={handleClearAll} />
      <FilterSection>
        <CheckboxGrid
          filterName={t('common.type')}
          checkedValues={checkedValues}
          handleFilterChange={(event, label) => {
            const value = event.target.value;
            if (event.target.checked) {
              gridContext.updateDataWithFunction((prev) => {
                prev.queryParams.filters.type = [
                  ...(prev.queryParams.filters.type || []),
                  { value, label },
                ];
              });

              setCheckedValues({ ...checkedValues, [label]: true });
            } else {
              gridContext.updateDataWithFunction((prev) => {
                prev.queryParams.filters.type =
                  prev.queryParams.filters.type.filter(
                    (item) => item.value !== value,
                  );
              });
              setCheckedValues({ ...checkedValues, [label]: false });
            }
          }}
          filterItems={refundsType?.map((type) => {
            return {
              value: type,
              label: t(`customer.documentTypes.${type}`),
            };
          })}
        />
        <If condition={!isCustomer()}>
          <MultiSelect
            filterName={t('clients.client')}
            selectedOptions={gridContext.queryParams.filters.customerId || []}
            handleFilterChange={(options: any) => {
              const lastOption = options.at(-1);
              handleOptionsChange('customerId', lastOption ? [lastOption] : []);
            }}
            filterItems={customers?.docs?.map((deck) => ({
              value: deck?._id,
              label: deck?.publicName,
            }))}
            onSearch={(search: string) => {
              setSearchCustomer(search);
            }}
            autoCompleteProps={{
              renderTags: (selectedOptions) => {
                return selectedOptions.map((option) => (
                  <Typography
                    key={option?.value}
                    sx={{
                      maxWidth: '250px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {option?.label}
                  </Typography>
                ));
              },
            }}
          />
          <If condition={!isEmpty(gridContext.queryParams.filters?.customerId)}>
            <MultiSelect
              filterName={t('common.branch')}
              selectedOptions={
                gridContext.queryParams.filters.customerDeckId || []
              }
              handleFilterChange={(options: any) => {
                const lastOption = options.at(-1);
                handleOptionsChange(
                  'customerDeckId',
                  lastOption ? [lastOption] : [],
                );
              }}
              filterItems={customerDeck?.docs?.map((deck) => ({
                value: deck?._id,
                label: deck?.code + ' - ' + deck?.location?.shortAddress,
              }))}
              autoCompleteProps={{
                renderTags: (selectedOptions) => {
                  return selectedOptions.map((option) => (
                    <Typography
                      key={option?.value}
                      sx={{
                        maxWidth: '250px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {option?.label}
                    </Typography>
                  ));
                },
              }}
            />
          </If>
        </If>
        <If condition={!isCustomer()}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                size="small"
                checked={gridContext.queryParams.filters.archived}
                onChange={(e) => {
                  gridContext.updateDataWithFunction((prev) => {
                    prev.queryParams.filters.archived = e.target.checked;
                  });
                }}
              />
            }
            label={t('transaction.filter.archived.refunds')}
          />
        </If>
      </FilterSection>
    </>
  );
}
