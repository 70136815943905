import { CustomCellRendererProps } from '@ag-grid-community/react';
import If from 'app/components/If';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

function CreatedAtCell(params: CustomCellRendererProps) {
  return (
    <If condition={!isEmpty(params?.value?.dateData)} otherwise="-">
      <span>{dayjs(params?.value?.dateData).format('DD MMMM, YYYY')}</span>
    </If>
  );
}

export default CreatedAtCell;
