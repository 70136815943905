import { Stack, SxProps } from '@mui/material';
import { AddNoteButton } from '../AddNoteButton';
import If from 'app/components/If';
import { NoteTextInput } from '../NoteTextInput';
import { StateSetter } from 'types';
import { Note } from '../../../../../types';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { CurrentUserNote, OtherUserNote } from '../Note';

interface Props {
  notes: Note[];
  addNoteOpen: boolean;
  setAddNoteOpen: StateSetter<boolean>;
  onCancel: () => void;
  onAdd: (content: string) => void;
  newNote: string;
  onNoteChange: (value: string) => void;
  onDelete: (noteId: string) => void;
  onEdit: (noteId: string, content: string) => void;
  editingNoteId: string;
  sx?: SxProps;
  canEdit: boolean;
}

export const ViewAddNotes = ({
  notes,
  addNoteOpen,
  setAddNoteOpen,
  onCancel,
  onAdd = () => {},
  newNote,
  onNoteChange,
  onDelete,
  onEdit,
  editingNoteId,
  sx,
  canEdit,
}: Props) => {
  const authUser = useSelector(selectAuthUser);

  return (
    <Stack gap="12px" maxHeight="550px" sx={{ padding: '0px 24px', ...sx }}>
      <Stack gap="8px">
        {notes?.map((note, index) => {
          if (note._user === authUser?._id) {
            return (
              <CurrentUserNote
                key={index}
                note={note}
                onDelete={onDelete}
                onEdit={onEdit}
                editingNoteId={editingNoteId}
                canEdit={canEdit}
              />
            );
          } else {
            return <OtherUserNote key={index} note={note} />;
          }
        })}
        {canEdit && (
          <If
            condition={addNoteOpen}
            otherwise={<AddNoteButton onClick={() => setAddNoteOpen(true)} />}
          >
            <NoteTextInput
              value={newNote}
              onChange={(value) => onNoteChange(value)}
              onCancel={onCancel}
              onAdd={onAdd}
              editMode={!!editingNoteId}
            />
          </If>
        )}
      </Stack>
    </Stack>
  );
};
