import { IGridContext, ITabsContext } from 'app/components/ManagedGrid/types';
import { useDebounce } from 'common/hooks/useDebounce';
import { useGetTransactionsPaymentsQuery } from 'common/services/transactions';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useQueryParams } from 'hooks/useQueryParams';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentsEmptyState from 'assets/img/ORDORIA/Transactions/payments.svg';
import ManagedGrid from 'app/components/ManagedGrid';
import styled from 'styled-components';
import { BulkActionsToolbar } from './BulkActionsToolbar';
import { getProcessedPayments } from './helpers';
import { TransactionsTab } from './config';
import { PaymentDrawer } from './components';

export function PaymentsGrid() {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext>('grid');
  const tabsContext = useManagedContext<ITabsContext>('tabs');

  const {
    queryParams: { page, rowsPerPage, search },
  } = gridContext;

  const commonQueryParams = {
    page,
    limit: rowsPerPage,
    text: search || '',
  };

  const queryParams = useQueryParams(commonQueryParams);

  const debouncedQueryParams = useDebounce(queryParams, 200);

  const {
    data: payments,
    isLoading: isLoading,
    isFetching,
  } = useGetTransactionsPaymentsQuery(debouncedQueryParams);

  const noDataConfig = useMemo(
    () => ({
      icon: PaymentsEmptyState,
      text: t('transaction.payment.empty-state.text'),
      subText: t('transaction.payment.empty-state.subText'),
      textButton: t('transaction.payment.add.title'),
      onClick: () => gridContext.updateData('openDrawer', true),
    }),
    [gridContext.openDrawer, payments?.docs],
  );

  const toolbarConfig = useMemo(
    () => ({
      disabled: gridContext.disableToolBar,
      BulkActionsComponent: BulkActionsToolbar,
      hasSearchField: false,
      onAddClick: () => {
        gridContext.updateData('openDrawer', true);
      },
    }),
    [gridContext.disableToolBar],
  );

  useEffect(() => {
    if (!payments) return;

    const processedData = getProcessedPayments(payments?.docs);

    gridContext.updateDataWithFunction((prev) => {
      prev.totalRows = payments?.totalDocs || 0;
      prev.responseData = payments?.docs;
      prev.loading = isLoading;
      prev.rowData = processedData!;
    });
  }, [payments?.docs, isLoading, isFetching]);

  useEffect(() => {
    tabsContext.updateDataWithFunction((prev) => {
      prev.selectedTab = TransactionsTab.PAYMENTS;
      prev.visibleTabs = [
        { label: t('common.bills'), value: TransactionsTab.BILLS },
        { label: t('common.refund'), value: TransactionsTab.REFUND },
        { label: t('common.payments'), value: TransactionsTab.PAYMENTS },
      ];
    });
  }, [queryParams, gridContext.viewMode, payments?.docs]);

  return (
    <Wrapper>
      <ManagedGrid toolbarConfig={toolbarConfig} noDataConfig={noDataConfig} />
      <PaymentDrawer
        open={gridContext.openDrawer}
        handleClose={() => {
          gridContext.updateDataWithFunction((prev) => {
            prev.openDrawer = false;
            prev.selectedRow = null;
            prev.extraData.currentRow = null;
          });
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 1;
`;
