import GridContext from 'app/components/ManagedGrid/GridContext';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { StateSetter } from 'types';
import { Contract, UpdateCategoryDiscounts } from '../../../ContractsTab/types';
import { ProductsDiscountsGrid } from './Components/ProductsDiscountsGrid';

interface Props {
  selectedTab: Contract;
  rowsEditedBySales: UpdateCategoryDiscounts[];
  setRowsEditedBySales: StateSetter<UpdateCategoryDiscounts[]>;
  minMargin?: number;
}

export const ProductsDiscounts = ({
  selectedTab,
  rowsEditedBySales,
  setRowsEditedBySales,
  minMargin,
}: Props) => {
  const can = usePermission('customers');

  return (
    <GridContext
      data={[]}
      title="productDiscounts"
      rowHeight={52}
      canEdit={can(Permission.EDIT)}
      suppressToolbar={true}
      suppressPagination={true}
      suppressCheckbox={true}
    >
      <ProductsDiscountsGrid
        selectedTab={selectedTab}
        rowsEditedBySales={rowsEditedBySales}
        setRowsEditedBySales={setRowsEditedBySales}
        minMargin={minMargin}
      />
    </GridContext>
  );
};
