import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import If from 'app/components/If';
import { FilterName, ToggleIcon } from '../../styles';
import { useState } from 'react';
import {
  Autocomplete,
  TextField,
  Chip,
  Box,
  AutocompleteRenderGetTagProps,
} from '@mui/material';
import { Avatar } from '@mui/material';
import UserAvatar from 'app/components/UserAvatar';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFetchUsers } from 'hooks/useFetchUsers';
import { AutocompleteProps } from './interfaces';
import { Cancel } from '@mui/icons-material';
import { stringAvatar } from 'app/helpers/helpers';

export const NoteAvatar = ({
  pictureUrl,
  fullName,
  size = '40px',
  fontSize = '0.875rem',
}: {
  pictureUrl?: string;
  fullName?: string;
  size?: string;
  fontSize?: string;
}) => {
  return (
    <If
      condition={!!pictureUrl}
      otherwise={
        <Avatar sx={{ width: size, height: size }}>
          <span style={{ fontSize: fontSize, lineHeight: size }}>
            {stringAvatar(fullName ?? '')}
          </span>
        </Avatar>
      }
    >
      <Avatar src={pictureUrl} sx={{ width: size, height: size }} />
    </If>
  );
};

export const UsersMultiSelect = ({
  onChange,
  disabled,
  name,
  filterName,
  openByDefault = false,
  selectedOptions,
}: AutocompleteProps) => {
  const isOpen = !!openByDefault;
  const [open, setOpen] = useState<boolean>(isOpen || false);
  const { t } = useTranslation();
  const { users, isFetching, searchUsers, setSearch } = useFetchUsers();

  const handleOnChange = (e, options) => {
    onChange(e, options);
  };

  const renderUsersTags = (
    selectedOptions: any[],
    getTagProps: AutocompleteRenderGetTagProps,
  ) => {
    return selectedOptions.map((option, index) => (
      <Chip
        size="small"
        label={option.label}
        {...getTagProps({ index })}
        deleteIcon={
          <Cancel
            sx={{
              color: `${option?.color} !important`,
            }}
          />
        }
        sx={{
          background: option?.backgroundColor
            ? option?.backgroundColor
            : `${option?.color}20`,
          color: option?.color,
        }}
        avatar={
          <Box>
            <NoteAvatar
              pictureUrl={option.pictureUrl}
              fullName={option.label}
              size="18px"
              fontSize="0.625rem"
            />
          </Box>
        }
      />
    ));
  };

  return (
    <>
      <div className="title_action" onClick={() => setOpen(!open)}>
        <FilterName>{filterName}</FilterName>
        <ToggleIcon disableRipple>
          {open ? <RemoveIcon /> : <AddIcon />}
        </ToggleIcon>
      </div>
      <If condition={open}>
        <Autocomplete
          value={selectedOptions}
          onChange={handleOnChange}
          multiple={true}
          ListboxProps={{ style: { maxHeight: '250px' } }}
          size="small"
          onBlur={() => setSearch('')}
          loading={isFetching}
          id="tags-standard"
          options={users || []}
          renderTags={renderUsersTags}
          getOptionLabel={(option) => option?.label || ''}
          renderOption={(props, option) => (
            <StyledListItem {...props}>
              <div
                style={{
                  display: 'flex',
                }}
              >
                {option?.pictureUrl ? (
                  <Avatar
                    src={option?.pictureUrl}
                    sx={{ width: '28px', height: '28px' }}
                  />
                ) : (
                  <UserAvatar
                    name={option?.label || ''}
                    size={28}
                    fontSize="12px"
                  />
                )}
              </div>
              <div>{option?.label}</div>
            </StyledListItem>
          )}
          renderInput={(params) => (
            <div>
              <TextField
                {...params}
                fullWidth
                size="small"
                label={t(name)}
                variant="outlined"
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  searchUsers(e?.target?.value);
                }}
                disabled={disabled}
                required
              />
            </div>
          )}
        />
      </If>
    </>
  );
};

export const DropDownTags = styled.div<{ color: string }>`
  fontsize: 0.85rem;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const StyledListItem = styled.li`
  height: 36px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 6px;
`;
