import { CustomCellRendererProps } from '@ag-grid-community/react';
import { useTranslation } from 'react-i18next';

function PaidByCell(params: CustomCellRendererProps) {
  const { t } = useTranslation();

  return (
    <div>
      <span>{t(`paymentTypes.${params?.value?.paidSourceData}`)}</span>
    </div>
  );
}

export default PaidByCell;
