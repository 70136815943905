import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';

import {
  RadioGroup,
  FormControlLabel,
  Stack,
  Grid,
  Checkbox,
  TextField,
  Box,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { AutoCompleteSearch } from 'app/components/Form/AutoCompleteSearch';

import {
  useAddOfferMutation,
  useUpdateOfferMutation,
} from 'common/services/customerOffersApi';
import FormSelectField from 'app/components/Form/SelectField';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';
import { ChipRadio } from 'app/components/Form/ChipRadio';
import { InputWithSelectedBtns } from 'app/components/Form/InputWithSelectedBtns';
import { FormActions, FormWrapper } from 'app/components/Form/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import i18next from 'i18next';
import { CategoriesAutocomplete } from '../../../CategoriesAutocomplete';
import { IAddOfferForm, Props, QuantityProps } from './interfaces';
import { ValueInput } from 'app/components/Form/ValueInput';
import moment, { Moment } from 'moment';
import { OfferContext } from '../../Components/OffersList';
import { useHelpers as useOfferHelper } from '../../useHelpers';
import { GridWrapper } from './Components/GridWrapper';
import { useApiCalls } from './hooks/useApiCalls';
import {
  ICheckboxAutocompleteOption,
  CheckboxAutocomplete,
} from 'app/components/CheckboxAutocomplete';

import {
  CustomerOfferPurchaseConditionType,
  CustomerOfferMechanism,
  CustomerOfferPurchaseConditionValueType,
  CustomerOfferedQuantityType,
  CustomerOfferPurchaseConditionOptions,
  CustomerOfferedQuantityOptions,
  renderOption,
} from './consts';

export const AddOffer = ({ open, onClose, customerId }: Props) => {
  const { t } = useTranslation();
  const { getTitleByLevel, periods, purchaseItems } = useOfferHelper();
  const { callApi, loading } = useAsyncDataV2();
  const [addOffer] = useAddOfferMutation();
  const [updateOffer] = useUpdateOfferMutation();
  const {
    setOptionsSearch,
    setProductsSearch,
    productsOptions,
    products,
    isFetching,
    isFetchingProducts,
  } = useApiCalls();

  const { selectedOffer } = useContext(OfferContext);
  const isEdit = !!selectedOffer;
  const [disabled, setDisabled] = useState<boolean>(!isEdit);
  const [startDate, setStartDate] = useState<string | Moment>(
    selectedOffer?.period?.type === 'custom' && selectedOffer.period?.startDate
      ? moment(selectedOffer.period?.startDate).format('YYYY-MM-DD')
      : moment(),
  );
  const [quantityProduct, setQuantityProducts] = useState<QuantityProps | null>(
    isEdit && selectedOffer?.mechanism === CustomerOfferMechanism.FREE
      ? {
          code: selectedOffer?.offeredProduct?._product?.code || '',
          label: selectedOffer?.offeredProduct?._product?.name || '',
          value: selectedOffer?.offeredProduct?._product?._id || '',
        }
      : null,
  );

  const [minDate, setMinDate] = useState<string | Moment>(
    moment().add(1, 'days').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState<string | Moment>(
    selectedOffer?.period?.type === 'custom' && selectedOffer?.period?.endDate
      ? moment(selectedOffer?.period?.endDate).format('YYYY-MM-DD')
      : moment().add(1, 'days').format('YYYY-MM-DD'),
  );
  const [showDate, setShowDate] = useState<boolean>(true);
  const [hasValue, setHasValue] = useState<boolean>(
    (isEdit && !!selectedOffer?.purchaseCondition?.value) || false,
  );
  const [customerOfferedType, setCustomerOfferedType] = useState<string>(
    isEdit
      ? selectedOffer.offeredQuantity.type
      : CustomerOfferedQuantityType.PERCENTAGE,
  );
  const [customerPurchaseType, setCustomerPurchaseType] = useState<string>(
    isEdit && selectedOffer?.purchaseCondition?.value
      ? selectedOffer?.purchaseCondition.valueType
      : !hasValue
        ? ''
        : CustomerOfferPurchaseConditionValueType.BOX,
  );
  const [customerOfferedValue, setCustomerOfferedValue] = useState<number>(
    isEdit ? selectedOffer?.offeredQuantity?.quantity : 0,
  );
  const [customerPurchaseValue, setCustomerPurchaseValue] = useState<number>(
    isEdit ? selectedOffer?.purchaseCondition?.value : 0,
  );

  const [productVal, setProduct] = useState<string | null>(
    isEdit ? selectedOffer?.offeredProduct?._product?._id : null,
  );

  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    isEdit && selectedOffer?.purchaseCondition?.type === 'product'
      ? selectedOffer?.purchaseCondition?._items?.map((section) => section._id)
      : [],
  );

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
    if (newValue.isAfter(endDate)) {
      setMinDate(newValue.add(1, 'days').format('YYYY-MM-DD'));
      setEndDate(newValue.add(1, 'days').format('YYYY-MM-DD'));
    }
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const productsProps = useMemo(() => {
    return {
      options:
        productsOptions?.docs?.map((section) => {
          return {
            value: section._id,
            label: section.name,
            code: section.code,
          };
        }) || [],
      getOptionLabel: (option) => option.label,
    };
  }, [productsOptions]);

  const productsListProps = useMemo(() => {
    return (
      products?.docs?.map((section) => {
        return {
          _id: section._id,
          name: section.name,
          label: section.code,
          labelHeader: `#${section.code}`,
          category: section._category.name,
          _parentCategory: {
            _id: section._category._id,
            name: section._category.name,
          },
        };
      }) || []
    );
  }, [products]);

  const methods = useForm<IAddOfferForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      mechanism: isEdit
        ? selectedOffer?.mechanism
        : CustomerOfferMechanism.FREE,
      conditionType: isEdit
        ? selectedOffer?.purchaseCondition?.type === 'category'
          ? getTitleByLevel(
              selectedOffer?.purchaseCondition?._items?.[0].level || 0,
            )
          : selectedOffer?.purchaseCondition?.type
        : '',
      periodType: isEdit ? selectedOffer?.period?.type : undefined,
    },
  });

  const defaultSelectedCategory =
    isEdit && selectedOffer?.purchaseCondition?.type === 'category'
      ? selectedOffer?.purchaseCondition?._items?.map((section) => {
          return {
            _id: section._id,
            value: section._id,
            label: section.name,
            code: section.code,
          };
        })
      : undefined;

  const [selectedOptions, setSelectedOptions] = useState<
    ICheckboxAutocompleteOption[]
  >(defaultSelectedCategory || []);

  const { control, watch, reset } = methods;

  const selectedPeriod = watch('periodType');
  const mechanism = watch('mechanism');
  const conditionType = watch('conditionType');

  const isRefund = useMemo(() => {
    return mechanism === 'refund';
  }, [mechanism]);

  useEffect(() => {
    if (selectedPeriod === 'custom') {
      setShowDate(true);
    } else {
      setShowDate(false);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (!isEdit) {
      if (mechanism === CustomerOfferMechanism.FREE) {
        setCustomerOfferedValue(0);
        setCustomerOfferedType('');
      } else {
        setCustomerOfferedValue(0);
        setCustomerOfferedType(CustomerOfferedQuantityType.PERCENTAGE);
        setProduct(null);
        setQuantityProducts(null);
      }
    }
  }, [mechanism, isEdit]);

  const handleCheckVal = () => {
    setHasValue(!hasValue);
    setCustomerPurchaseType(CustomerOfferPurchaseConditionValueType.BOX);
  };

  const handleOfferedQuantityValue = (value: number) => {
    setCustomerOfferedValue(value);
  };

  const handleOfferedQuantityType = (type: string) => {
    setCustomerOfferedType(type);
    if (
      type === CustomerOfferedQuantityType.PERCENTAGE &&
      customerOfferedValue > 100
    ) {
      handleOfferedQuantityValue(100);
    }
  };

  const handlePurchaseQuantityValue = (value: number) => {
    setCustomerPurchaseValue(value);
  };

  const handlePurchaseQuantityType = (type: string) => {
    setCustomerPurchaseType(type);
  };

  const body = useMemo(() => {
    return {
      mechanism,
      product:
        mechanism === CustomerOfferMechanism.REFUND ? undefined : productVal,
      conditionType:
        conditionType === CustomerOfferPurchaseConditionType.SUBCATEGORY ||
        conditionType === CustomerOfferPurchaseConditionType.ChildCATEGORY
          ? 'category'
          : conditionType,
      periodType: selectedPeriod,
      conditionItems:
        conditionType === 'product'
          ? !!selectedProducts.length
            ? selectedProducts
            : undefined
          : !!selectedOptions.length
            ? selectedOptions.map((item) => item._id)
            : undefined,
      offeredQuantity: customerOfferedValue,
      offeredQuantityType: isRefund ? customerOfferedType : undefined,
      customer: customerId,
      startDate:
        selectedPeriod === 'custom' && startDate
          ? moment(startDate).format('YYYY-MM-DD')
          : undefined,
      endDate:
        selectedPeriod === 'custom' && endDate
          ? moment(endDate).format('YYYY-MM-DD')
          : undefined,
      conditionValueType: hasValue ? customerPurchaseType : undefined,
      conditionValue: hasValue ? customerPurchaseValue : undefined,
    };
  }, [
    productVal,
    mechanism,
    conditionType,
    selectedPeriod,
    selectedProducts,
    selectedOptions,
    customerOfferedValue,
    customerOfferedType,
    customerPurchaseType,
    customerPurchaseValue,
    hasValue,
    isRefund,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    validate();
  }, [body]);

  const validate = useCallback(async () => {
    let hasError = false;

    if (mechanism === CustomerOfferMechanism.FREE) {
      if (customerOfferedValue === 0 || !productVal || productVal === '') {
        hasError = true;
      }
    } else if (
      mechanism === CustomerOfferMechanism.REFUND &&
      customerOfferedValue === 0
    ) {
      hasError = true;
    }

    if (
      (conditionType === CustomerOfferPurchaseConditionType.SUBCATEGORY ||
        conditionType === CustomerOfferPurchaseConditionType.CATEGORY ||
        conditionType === CustomerOfferPurchaseConditionType.ChildCATEGORY) &&
      selectedOptions.length === 0
    ) {
      hasError = true;
    }
    if (
      conditionType === CustomerOfferPurchaseConditionType.PRODUCT &&
      selectedProducts.length === 0
    ) {
      hasError = true;
    }
    if (hasValue) {
      if (
        hasValue &&
        (customerPurchaseValue === 0 || customerPurchaseType === '')
      ) {
        hasError = true;
      }
    }

    if (selectedPeriod) {
      if (selectedPeriod === 'custom' && (!startDate || !endDate)) {
        hasError = true;
      }
    } else {
      hasError = true;
    }
    setDisabled(hasError);
  }, [body]);

  const handleSubmit = useCallback(async () => {
    await callApi(
      async () => {
        isEdit
          ? updateOffer({ body: body, id: selectedOffer._id })
          : addOffer({
              body: body,
            }).unwrap();
      },
      {
        onSuccess: () => {
          reset();
          onClose();
        },
        successMessage: isEdit
          ? t('offers.update_success')
          : t('offers.add_success'),
      },
    );
  }, [body]);

  const handleOptionsChange = useCallback(
    (options: ICheckboxAutocompleteOption[]) => {
      setSelectedOptions(options);
    },
    [],
  );

  const handleProductChange = (event: React.SyntheticEvent | Event, item) => {
    setProduct(item?.value);
    setQuantityProducts(item);
  };

  const handleProductsChange = useCallback(
    (options: ICheckboxAutocompleteOption[]) => {
      const values = options.map(function (item) {
        return item._id;
      });
      setSelectedProducts(values);
    },
    [],
  );

  return (
    <TwoViewModal
      title={isEdit ? t('offers.edit_title') : t('contracts.offers.add_title')}
      onOpen={open}
      onClose={onClose}
      defaultMode="floated"
      autoHeight
    >
      <FormWrapper>
        <GridWrapper label="mechanism">
          <Controller
            name="mechanism"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row" gap="10px">
                  <ChipRadio
                    {...field}
                    value={CustomerOfferMechanism.FREE}
                    disabled={false}
                    label="common.free"
                  />
                  <ChipRadio
                    {...field}
                    value={CustomerOfferMechanism.REFUND}
                    disabled={false}
                    label="common.refund"
                  />
                </Stack>
              </RadioGroup>
            )}
          />
        </GridWrapper>
        <GridWrapper label="contracts.offers.customer_is_offered">
          <Box
            sx={{
              marginTop: `${isRefund ? '0px' : '.5px'}`,
              display: 'flex',
              gap: '20px',
            }}
          >
            <Grid item xs={4}>
              {!isRefund && (
                <ValueInput
                  initialValue={
                    isEdit ? selectedOffer.offeredProduct?.quantity : 0
                  }
                  onChange={(value) => handleOfferedQuantityValue(value)}
                  loading={false}
                  btnIconWidth="28px"
                  height="37px"
                  width="45px"
                  qunatityIconSize={16}
                  name="customer_is_offered"
                />
              )}

              {isRefund && (
                <InputWithSelectedBtns
                  buttonsOptions={CustomerOfferedQuantityOptions}
                  onChange={(type) => handleOfferedQuantityType(type)}
                  defaultSelected={customerOfferedType}
                  name="customer_is_offered"
                  onQuantityChange={(value) =>
                    handleOfferedQuantityValue(value)
                  }
                  maxValue={
                    customerOfferedType ===
                    CustomerOfferedQuantityType.PERCENTAGE
                      ? 100
                      : undefined
                  }
                />
              )}
            </Grid>
            {!isRefund && (
              <Grid item xs={8}>
                <AutoCompleteSearch
                  multiple={false}
                  control={control}
                  propsVal={productsProps}
                  onChange={handleProductChange}
                  disabled={false}
                  isFetching={isFetching}
                  fieldName="product"
                  name={t('common.product')}
                  setSearch={setOptionsSearch}
                  renderOption={renderOption}
                  value={quantityProduct}
                />
              </Grid>
            )}
          </Box>
        </GridWrapper>

        <GridWrapper
          customMargin={isRefund ? '10px' : '-5px'}
          label="contracts.offers.on_purshase_of"
        >
          <>
            <Grid item xs={12}>
              {!!purchaseItems.length && (
                <FormSelectField
                  required={true}
                  name="conditionType"
                  label={t('common.select')}
                  control={control}
                  rules={{ required: true }}
                  checkboxes={false}
                  data={purchaseItems}
                />
              )}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '3px' }}>
              {conditionType === 'category' && (
                <CategoriesAutocomplete
                  level={0}
                  label="Category"
                  onChange={handleOptionsChange}
                  required={true}
                  defaultSelected={defaultSelectedCategory}
                />
              )}
              {conditionType === 'subcategory' && (
                <CategoriesAutocomplete
                  level={1}
                  label="subCategory"
                  onChange={handleOptionsChange}
                  required={true}
                  defaultSelected={defaultSelectedCategory}
                />
              )}
              {conditionType === 'childcategory' && (
                <CategoriesAutocomplete
                  level={2}
                  label="childCategory"
                  onChange={handleOptionsChange}
                  required={true}
                  defaultSelected={defaultSelectedCategory}
                />
              )}
              {conditionType === 'product' && (
                <CheckboxAutocomplete
                  options={productsListProps}
                  label={t('common.products')}
                  onChange={handleProductsChange}
                  onSearchChanged={(value) => setProductsSearch(value)}
                  required={true}
                  defaultSelected={
                    isEdit &&
                    selectedOffer?.purchaseCondition?.type === 'product'
                      ? selectedOffer?.purchaseCondition?._items?.map(
                          (section) => {
                            return {
                              _id: section._id,
                              label: section.name,
                              name: section.name,
                            };
                          },
                        )
                      : []
                  }
                  isLoading={isFetchingProducts}
                />
                // <AutoCompleteSearch
                //   multiple={true}
                //   control={control}
                //   propsVal={productsListProps}
                //   onChange={handleProductsChange}
                //   disabled={false}
                //   isFetching={isFetchingProducts}
                //   fieldName="products"
                //   name={t('common.products')}
                //   setSearch={setProductsSearch}
                //   renderOption={renderOption}
                // />
              )}
            </Grid>
          </>
        </GridWrapper>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={hasValue} disabled={false} />}
              label={t('of_value')}
              onChange={handleCheckVal}
              sx={{
                marginTop:
                  conditionType === 'anything' || !conditionType
                    ? '-25px'
                    : '-2px',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputWithSelectedBtns
              defaultValue={
                isEdit && selectedOffer?.purchaseCondition?.value
                  ? selectedOffer?.purchaseCondition.value
                  : 0
              }
              disabled={!hasValue}
              buttonsOptions={CustomerOfferPurchaseConditionOptions}
              onChange={(type) => handlePurchaseQuantityType(type)}
              defaultSelected={customerPurchaseType}
              name="hasValue"
              onQuantityChange={(value) => handlePurchaseQuantityValue(value)}
            />
          </Grid>
        </Grid>
        <GridWrapper label="when">
          <FormSelectField
            name="periodType"
            label={t('common.select_period')}
            control={control}
            rules={{ required: true }}
            checkboxes={false}
            data={periods}
          />
        </GridWrapper>
        {showDate && (
          <Grid container spacing={0.5}>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18next.language}
              >
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      value={startDate}
                      label={t('date.start_date')}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      onChange={handleStartDateChange}
                      disabled={false}
                      inputFormat="D MMM. YYYY"
                      minDate={moment()}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18next.language}
              >
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      value={endDate}
                      label={t('date.end_date')}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      onChange={handleEndDateChange}
                      disabled={false}
                      inputFormat="D MMM. YYYY"
                      minDate={minDate}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}
      </FormWrapper>
      <FormActions>
        <Button onClick={onClose} color="inherit">
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={disabled || loading}
          variant="contained"
          autoFocus
        >
          {isEdit ? t('actions.save_changes') : t('common.buttons.add')}
        </Button>
      </FormActions>
    </TwoViewModal>
  );
};

export const requiredWrapper = styled.span`
  color: ${(props) => props.theme.progressRed};
`;
