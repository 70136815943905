import React, { useCallback } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import {
  Popover,
  ButtonBase,
  Typography,
  Stack,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { If } from 'app/components/If';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import { useTranslation } from 'react-i18next';
import NoOrdersIcon from 'assets/img/ORDORIA/Catalog/NoOrdersIcon.svg';
import { Icon } from 'app/components/Icon';
import { Plus } from '@phosphor-icons/react';
import { useGetGroupedByDateOrdersQuery } from 'common/services/orderApi';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import moment from 'moment';

interface CustomerOrdersPopoverProps {
  anchorEl: any;
  handleClose: () => void;
  onCreateClick: () => void;
  onOrderClick: (orderId: string, orderCode: string) => void;
}

export const CustomerOrdersPopover: React.FC<CustomerOrdersPopoverProps> = ({
  anchorEl,
  handleClose,
  onCreateClick,
  onOrderClick,
}) => {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: -13,
        horizontal: 0,
      }}
    >
      <CustomerOrders
        onCreateClick={onCreateClick}
        onOrderClick={onOrderClick}
      />
    </Popover>
  );
};

interface CustomerOrdersProps {
  onCreateClick: () => void;
  onOrderClick: (orderId: string, orderCode: string) => void;
}

export const CustomerOrders: React.FC<CustomerOrdersProps> = ({
  onCreateClick,
  onOrderClick,
}) => {
  const theme = useSelector(selectTheme);
  const { t, i18n } = useTranslation();

  const { data: groupedOrders, isLoading } = useGetGroupedByDateOrdersQuery('');

  const formatDate = useCallback(
    (dateString: string): string => {
      const date = moment(dateString).utc().locale(i18n.language);
      const formattedDate = date.format('MMMM DD YYYY').toUpperCase();

      if (date.isSame(moment().utc().startOf('day'))) {
        return `${t('calendar.today').toUpperCase()}, ${formattedDate}`;
      }
      return formattedDate;
    },
    [t, i18n?.language],
  );

  return (
    <Wrapper>
      <If
        condition={!isLoading}
        otherwise={
          <EmptyCenter>
            <CircularProgress size={24} />
          </EmptyCenter>
        }
      >
        <If
          condition={!!groupedOrders?.docs && groupedOrders?.docs?.length > 0}
          otherwise={
            <EmptyCenter>
              <NoDataComponents
                viewMode={true}
                Icon={NoOrdersIcon}
                text={t('no_orders_yet_')}
                IconButton={null}
                textButton=""
              />
            </EmptyCenter>
          }
        >
          <OrdersWrapper>
            {groupedOrders?.docs?.map((group, index) => (
              <OrdersGroup
                key={index}
                style={{ borderColor: theme.gainsboro2 }}
              >
                <StickyTitle style={{ background: theme.accordionWhiteBg }}>
                  <Typography
                    fontWeight="400"
                    fontSize="0.75rem"
                    lineHeight="24px"
                    letterSpacing="1px"
                    color={theme.black + 61}
                  >
                    {formatDate(group.date)}
                  </Typography>
                </StickyTitle>
                <Stack gap="4px">
                  {group.orders.map((order, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        padding: '4px 16px',
                      }}
                      onClick={() => onOrderClick(order._id, order.code)}
                    >
                      <Stack>
                        <Typography
                          fontWeight="400"
                          fontSize="0.8125rem"
                          lineHeight="13px"
                          letterSpacing="0.15px"
                          color={theme.textBlack}
                        >
                          {order?.code}
                        </Typography>
                        <Typography
                          fontWeight="400"
                          fontSize="0.8125rem"
                          lineHeight="18px"
                          letterSpacing="0.17px"
                          color={theme.textColorDashboard}
                        >
                          {order?._customer?.publicName}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Stack>
              </OrdersGroup>
            ))}
          </OrdersWrapper>
        </If>
        <PopoverActionsWrapper
          style={{ background: theme.ordoriaLightActionsBg }}
        >
          <ButtonBase onClick={onCreateClick}>
            <ActionButtonWrapper>
              <Icon
                icon={<Plus />}
                color={themes?.ordoria?.primary}
                size={12}
              />
              <Typography
                fontSize="0.75rem"
                fontWeight="500"
                lineHeight="10px"
                letterSpacing="0.14px"
                color={themes?.ordoria?.primary}
                sx={{
                  padding: '2px 0 0 0',
                }}
              >
                {t('orders.create_orders')}
              </Typography>
            </ActionButtonWrapper>
          </ButtonBase>
        </PopoverActionsWrapper>
      </If>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 251px;
  min-height: 133px;
  max-height: 273px;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OrdersWrapper = styled.div`
  max-height: 250px;
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  overflow-y: auto;
`;

const OrdersGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 6px 0px;
  border-bottom: 1px solid transparent;
  position: relative;
`;

const StickyTitle = styled.div`
  padding: 0px 16px;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

const EmptyCenter = styled.div`
  flex: 1;
  min-height: 134px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  p {
    margin: 0 !important;
    font-size: 0.8125rem;
    line-height: 13px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
    color: ${themes?.default?.textBlack};
  }
`;

const PopoverActionsWrapper = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 10px;
`;

const ActionButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 4px;
`;
