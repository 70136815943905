import { Button, ButtonBase, Grid, Stack, Typography } from '@mui/material';
import {
  CalendarBlank,
  CalendarCheck,
  CaretLeft,
  CaretRight,
  ChatDots,
  Crosshair,
  Phone,
  RoadHorizon,
  ShoppingBagOpen,
  Timer,
} from '@phosphor-icons/react';
import { Marker } from '@vis.gl/react-google-maps';
import { Chat } from 'app/components/Chat';
import { UserAvatar } from 'app/components/Chat/components/UserAvatar';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import If from 'app/components/If';
import { InfoCard } from 'app/components/InfoCard';
import MapPopover from 'app/components/MapPopover';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { StatusOrder } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import { useGetCustomerQuery } from 'common/services/customerApi';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {
  usePhoneCallHook,
  useVoiceCallHook,
} from 'hooks/VoiceCall/useVoiceCallHook';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import moment from 'moment';

dayjs.extend(duration);

const VisitStopDrawer = ({
  selectedOrder,
  handleCloseOrderDrawer,
  matchingStop,
  salesAgent,
  roleData,
  user,
  selectedStopDetails,
  isFirstStop,
  isLastStop,
  handleNextStop,
  handlePreviousStop,

  handleDisplayMap,
}: {
  selectedOrder: any;
  handleCloseOrderDrawer: () => void;
  matchingStop: any;
  salesAgent: any;
  roleData: any;
  user: any;
  selectedStopDetails: any;
  isFirstStop: boolean;
  isLastStop: boolean;
  handleNextStop: () => void;
  handlePreviousStop: () => void;
  setType?: (type: string) => void;
  setClusterId: (id: string) => void;
  handleDisplayMap?: (roundtripId: string) => void;
}) => {
  // Function logic goes here
  const { t } = useTranslation();
  const [chatOpen, setChatOpen] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const [visibleProducts, setVisibleProducts] = useState<number | null>(null);
  const [isProductsExpanded, setIsProductsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const productsRef = useRef<(HTMLElement | null)[]>([]);
  const [mapPopoverOpen, setMapPopoverOpen] = useState(false);
  const handleChatClick = () => {
    if (salesAgent?.[0]?._user) {
      setChatOpen(true);
    }
  };
  const defaultUser = {
    ...salesAgent?.[0]?._user,
    _id: salesAgent?.[0]?._user?._id,
    fullName: salesAgent?.[0]?._user?.fullName,
    role: {
      ...salesAgent?.[0]?._role,
      localeName: roleData?.data?.localeName,
    },
    pictureUrl: salesAgent?.[0]?._user?.pictureUrl,
  };

  useEffect(() => {
    setTimeout(() => {
      if (!containerRef.current || !productsRef.current.length) return;

      const CONTAINER_MAX_WIDTH = 521;
      const MORE_BUTTON_WIDTH = 70;
      const AVAILABLE_WIDTH = CONTAINER_MAX_WIDTH - MORE_BUTTON_WIDTH;
      let currentLineWidth = 0;
      let visibleCount = 0;

      productsRef.current.forEach((product, index) => {
        if (!product) return;

        const productWidth = product.offsetWidth + 8;

        if (currentLineWidth + productWidth <= AVAILABLE_WIDTH) {
          currentLineWidth += productWidth;
          visibleCount = index + 1;
        }
      });

      setVisibleProducts(visibleCount || null);
    }, 0);
  }, [matchingStop?.visitStopDetails?.productsDiscussed]);

  const renderProducts = (products: string[]) => {
    if (!products?.length) return null;

    // Show all products if visibleProducts is null (initial state)
    const displayedProducts =
      isProductsExpanded || visibleProducts === null
        ? products
        : products.slice(0, visibleProducts);
    const remainingCount =
      visibleProducts === null ? 0 : products.length - visibleProducts;

    return (
      <Grid
        container
        ref={containerRef}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          flexWrap: 'wrap',
          maxHeight: '80px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
          },
        }}
        alignItems="start"
        gap="8px"
      >
        {displayedProducts.map((product, index) => (
          <Typography
            key={index}
            ref={(el) => (productsRef.current[index] = el)}
            fontWeight={400}
            fontSize="0.625rem"
            color={themes?.default?.black}
            sx={{
              background: themes?.default?.whisper,
              padding: '3px 10px',
              borderRadius: '100px',
              marginRight: '4px',
            }}
          >
            {product.charAt(0).toUpperCase() + product.slice(1).toLowerCase()}
          </Typography>
        ))}
        {!isProductsExpanded && remainingCount > 0 && (
          <Typography
            onClick={() => setIsProductsExpanded(true)}
            sx={{
              cursor: 'pointer',
              color: themes?.default?.black2,
              border: '1px solid black',
              borderColor: themes?.default?.clientMarkerBg,
              padding: '3px 10px',
              borderRadius: '100px',
              fontSize: '0.625rem',
              fontWeight: 500,
            }}
          >
            +{remainingCount} more
          </Typography>
        )}
        {isProductsExpanded && (
          <Typography
            onClick={() => setIsProductsExpanded(false)}
            sx={{
              cursor: 'pointer',
              color: themes?.default?.black2,
              border: '1px solid black',
              borderColor: themes?.default?.clientMarkerBg,
              padding: '3px 10px',
              borderRadius: '100px',
              fontSize: '0.625rem',
              fontWeight: 500,
            }}
          >
            Show less
          </Typography>
        )}
      </Grid>
    );
  };
  const coordinates = {
    lng: matchingStop?._place?.location?.geometry?.coordinates[0] || 0,
    lat: matchingStop?._place?.location?.geometry?.coordinates[1] || 0,
  };
  const customMarkers = <Marker position={coordinates} />;
  const { handleCall: voiceCall } = useVoiceCallHook(
    salesAgent?.[0]?._user?._id,
  );
  const { handleCall: phoneCall } = usePhoneCallHook(
    null,
    user?.currentAgent?.phone,
  );

  const handleCall = () => {
    if (salesAgent?.[0]?._user?.voiceStatus === 'online') {
      voiceCall();
    } else if (user.agents[0].phone) {
      phoneCall();
    }
  };
  const customerId =
    matchingStop?.visitStopDetails?._customer?._id ||
    matchingStop?.visitStopDetails?._customer;
  const { data: customer } = useGetCustomerQuery(customerId, {
    skip: !customerId || typeof customerId !== 'string',
  });
  const handleRoadIconClick = () => {
    handleDisplayMap?.(selectedStopDetails?._id);
  };

  const formatDuration = (minutes: number): string => {
    if (!minutes) return '-';

    const duration = dayjs.duration(minutes, 'minutes');
    const hours = Math.floor(duration.asHours());
    const remainingMinutes = duration.minutes();

    if (hours === 0) {
      return `${remainingMinutes} min`;
    }

    return remainingMinutes > 0
      ? `${hours}h ${remainingMinutes} min`
      : `${hours}h`;
  };

  return (
    <>
      <TwoViewModal
        onOpen={!isEmpty(selectedOrder)}
        onClose={() => {
          handleCloseOrderDrawer();
          setChatOpen(false);
        }}
        headerContent={
          <Stack direction="row" alignItems="center" gap="8px">
            <Crosshair
              weight="bold"
              size={16}
              color={themes?.default?.primaryActiveColor}
              onClick={() => setMapPopoverOpen(true)}
            />
            <MapPopover
              longitude={coordinates.lng}
              latitude={coordinates.lat}
              open={mapPopoverOpen}
              handleClose={() => setMapPopoverOpen(false)}
              customMarkers={customMarkers}
            />
            {customer?.code && (
              <Typography fontSize="1rem">
                <span style={{ fontWeight: 700 }}>{customer?.code}</span>
                {' - '}
                {customer?.publicName}
              </Typography>
            )}
            <VerticalDivider />
            <Stack
              direction="row"
              gap="4px"
              width="3.875rem"
              alignItems="center"
              justifyContent="center"
              sx={{
                border: '1px solid',
                borderRadius: '3px !important',
                borderColor: themes?.default?.silver,
                padding: '1px 2px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '&.MuiStack-root': {
                  borderRadius: '3px !important',
                },
              }}
              onClick={handleRoadIconClick}
            >
              <RoadHorizon
                weight="bold"
                size={18}
                color={themes?.default?.primaryActiveColor}
                style={{ marginBottom: '2px', marginRight: '4px' }}
              />
              <Typography
                fontSize="0.75rem"
                color={themes?.default?.clientMarkerBg}
              >
                {selectedStopDetails?.code}
              </Typography>
            </Stack>
            <VerticalDivider />
            <StatusOrder
              status={matchingStop?.status}
              date={
                matchingStop?._order?.updatedStatusAt ||
                (matchingStop?.visitStopDetails?.time
                  ? moment(matchingStop.visitStopDetails.time, 'h:mm a').format(
                      'YYYY-MM-DD HH:mm:ss',
                    )
                  : matchingStop?.updatedStatusAt)
              }
            />
            <Stack
              direction="row"
              alignItems="center"
              gap="12px"
              marginLeft="auto"
            >
              <NavigationButton
                disabled={isFirstStop}
                onClick={!isFirstStop ? handlePreviousStop : undefined}
              >
                <CaretLeft size={16} color={themes.default.black} />
              </NavigationButton>
              <NavigationButton
                disabled={isLastStop}
                onClick={!isLastStop ? handleNextStop : undefined}
              >
                <CaretRight size={16} color={themes.default.black} />
              </NavigationButton>
            </Stack>
          </Stack>
        }
        width="740px"
      >
        <DrawerContent>
          <Wrapper>
            <Stack direction="row" gap="12px">
              <InfoCard
                icon={
                  <CalendarBlank
                    weight="bold"
                    color={themes?.default?.deepPurple800}
                  />
                }
                iconBg={themes?.default?.purple50}
                title={t('common.created_on')}
                value={
                  <Typography
                    noWrap
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontSize: '0.75rem',
                      fontWeight: 500,
                    }}
                  >
                    {matchingStop?.createdAt
                      ? dayjs(matchingStop?.createdAt)
                          .format('DD MMM, YYYY HH:mm')
                          .replace(/\./g, '')
                      : '-'}
                  </Typography>
                }
              />
              <InfoCard
                icon={
                  <CalendarCheck
                    weight="bold"
                    color={themes?.default?.amber1000}
                  />
                }
                iconBg={themes?.default?.amber100}
                title={t('visit-stop-date')}
                value={
                  <Typography
                    noWrap
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontSize: '0.75rem',
                      fontWeight: 500,
                    }}
                  >
                    {matchingStop?.visitStopDetails?.time || '-'}
                  </Typography>
                }
              />
              <InfoCard
                icon={<Timer weight="bold" color={themes?.default?.blue500} />}
                iconBg={themes?.default?.blue50}
                title={t('visit-stop-duration')}
                value={
                  <Typography
                    noWrap
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontSize: '0.75rem',
                      fontWeight: 500,
                    }}
                  >
                    {formatDuration(matchingStop?.visitStopDetails?.duration)}
                  </Typography>
                }
              />
              <InfoCard
                icon={
                  <ShoppingBagOpen
                    weight="bold"
                    color={themes?.default?.pink400}
                  />
                }
                iconBg={themes?.default?.pink50}
                title={t('stops.outcome')}
                value={
                  <Typography
                    noWrap
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      fontSize: '0.75rem',
                      fontWeight: 500,
                    }}
                  >
                    {matchingStop?.visitStopDetails?.outcome &&
                    matchingStop.status !== 'planned'
                      ? t(matchingStop?.visitStopDetails?.outcome)
                      : '-'}
                  </Typography>
                }
              />
            </Stack>
            <Typography fontWeight={700} fontSize="0.875rem">
              Visit Details
            </Typography>
            <ContentContainer>
              <ContentTitle width="200px" fontSize="0.75rem">
                Sales
              </ContentTitle>
              <If
                condition={Boolean(salesAgent?.[0]?._user?.fullName)}
                otherwise={
                  <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                    -
                  </Typography>
                }
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  justifyContent="flex-end"
                  width="100%"
                >
                  <UserAvatar
                    size={24}
                    userImagePath={
                      selectedStopDetails?.agents?.[1]?._user?.pictureUrl
                    }
                    fullName={salesAgent?.[0]?._user?.fullName}
                  />
                  <Typography fontWeight={400} fontSize="0.75rem">
                    {salesAgent?.[0]?._user?.fullName}
                  </Typography>
                  <Stack direction="row" spacing={0.5}>
                    <SmallIconsConatiner style={{ cursor: 'pointer' }}>
                      <ButtonBase
                        onClick={() => {
                          if (salesAgent?.[0]?._user) {
                            handleChatClick();
                          }
                        }}
                      >
                        <ChatDots
                          size={10}
                          color={themes.default.primaryActiveColor}
                        />
                      </ButtonBase>
                    </SmallIconsConatiner>
                    <SmallIconsConatiner>
                      <ButtonBase
                        onClick={handleCall}
                        sx={{
                          width: '18px',
                          height: '18px',
                        }}
                      >
                        <Phone
                          size={10}
                          color={themes.default.primaryActiveColor}
                        />
                      </ButtonBase>
                    </SmallIconsConatiner>
                  </Stack>
                </Stack>
              </If>
            </ContentContainer>
            <ContentContainer>
              <ContentTitle width="200px" fontSize="0.75rem">
                Purpose
              </ContentTitle>
              <If
                condition={Boolean(matchingStop?.visitStopDetails?.type)}
                otherwise={
                  <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                    -
                  </Typography>
                }
              >
                <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                  {t(matchingStop?.visitStopDetails?.type)}
                </Typography>
              </If>
            </ContentContainer>
            <ContentContainer>
              <ContentTitle width="200px" fontSize="0.75rem">
                Duration
              </ContentTitle>
              <If
                condition={Boolean(matchingStop?.visitStopDetails?.duration)}
                otherwise={
                  <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                    -
                  </Typography>
                }
              >
                <Grid container alignItems="center" gap="8px">
                  <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                    {formatDuration(matchingStop?.visitStopDetails?.duration)}
                  </Typography>
                </Grid>
              </If>
            </ContentContainer>
            <ContentContainer>
              <ContentTitle width="200px" fontSize="0.75rem">
                Description
              </ContentTitle>
              <If
                condition={Boolean(matchingStop?.visitStopDetails?.description)}
                otherwise={
                  <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                    -
                  </Typography>
                }
              >
                <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                  {matchingStop?.visitStopDetails?.description}
                </Typography>
              </If>
            </ContentContainer>
          </Wrapper>
          <Wrapper>
            <Typography fontWeight={700} fontSize="0.875rem">
              Visit Outcome
            </Typography>
            {matchingStop?.status !== 'planned' && (
              <ContentContainer>
                <ContentTitle width="200px" fontSize="0.75rem">
                  Outcome
                </ContentTitle>
                <If
                  condition={Boolean(
                    matchingStop?.visitStopDetails?.outcome &&
                      matchingStop.status !== 'planned',
                  )}
                  otherwise={
                    <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                      -
                    </Typography>
                  }
                >
                  <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                    {t(matchingStop?.visitStopDetails?.outcome)}
                  </Typography>
                </If>
              </ContentContainer>
            )}

            {matchingStop?.visitStopDetails?.type !== 'delivery' && (
              <ContentContainer style={{ minHeight: '40px', height: 'auto' }}>
                <ContentTitle width="200px" fontSize="0.75rem">
                  Products Discussed
                </ContentTitle>
                <If
                  condition={Boolean(
                    matchingStop?.visitStopDetails?.productsDiscussed?.length,
                  )}
                  otherwise={
                    <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                      -
                    </Typography>
                  }
                >
                  {renderProducts(
                    matchingStop?.visitStopDetails?.productsDiscussed,
                  )}
                </If>
              </ContentContainer>
            )}
          </Wrapper>
          <Wrapper>
            <Typography fontWeight={700} fontSize="0.875rem">
              Additional Details{' '}
            </Typography>
            {matchingStop?.visitStopDetails?.customerType === 'potential' && (
              <ContentContainer>
                <ContentTitle width="200px" fontSize="0.75rem">
                  Next Steps
                </ContentTitle>
                <If
                  condition={Boolean(
                    matchingStop?.visitStopDetails?.nextSteps?.length,
                  )}
                  otherwise={
                    <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                      -
                    </Typography>
                  }
                >
                  <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                    {matchingStop?.visitStopDetails?.nextSteps?.join(', ')}
                  </Typography>
                </If>
              </ContentContainer>
            )}
            <ContentContainer>
              <ContentTitle width="200px" fontSize="0.75rem">
                Customer Comments{' '}
              </ContentTitle>
              <If
                condition={Boolean(
                  matchingStop?.visitStopDetails?.customerComment,
                )}
                otherwise={
                  <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                    -
                  </Typography>
                }
              >
                <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                  {matchingStop?.visitStopDetails?.customerComment}
                </Typography>
              </If>
            </ContentContainer>
            <ContentContainer>
              <ContentTitle width="200px" fontSize="0.75rem">
                Issues and Challenges{' '}
              </ContentTitle>
              <If
                condition={Boolean(
                  matchingStop?.visitStopDetails?.issuesAndChallenges,
                )}
                otherwise={
                  <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                    -
                  </Typography>
                }
              >
                <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                  {matchingStop?.visitStopDetails?.issuesAndChallenges}
                </Typography>
              </If>
            </ContentContainer>
          </Wrapper>
        </DrawerContent>
        <div>
          <div>
            <Button
              variant="contained"
              onClick={() => {
                handleCloseOrderDrawer();
              }}
            >
              {t('common.buttons.done')}
            </Button>
          </div>
        </div>
      </TwoViewModal>
      {chatOpen && (
        <ChatOverlay>
          <Chat
            open={chatOpen}
            setChatOpen={setChatOpen}
            unreadMessagesCount={unreadMessagesCount}
            setUnreadMessagesCount={setUnreadMessagesCount}
            defaultUser={defaultUser}
            zIndex={1601}
          />
        </ChatOverlay>
      )}
    </>
  );
};

export default VisitStopDrawer;

const DrawerContent = styled.div`
  max-width: 100%;
  margin: auto;
  overflow-y: scroll;
`;
export const Wrapper = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
`;

export const ContentContainer = styled.div`
  width: 100%;
  min-height: 40px;
  background: ${themes?.default?.lightGrey};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
`;
export const SmallIconsConatiner = styled.div`
  height: 18px;
  width: 18px;
  background-color: ${themes?.default?.primaryLight1};
  border-radius: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;
export const ProductsContainer = styled.div`
  width: 100%;
  height: 40px;
  background: ${themes?.default?.lightGrey};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
`;
export const ContentTitle = styled(Typography)`
  color: ${themes?.default?.clientMarkerBg};
`;

const NavigationButton = styled.span<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border: 1px solid ${themes.default.silver};
  border-radius: 3px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${themes.default.lightGrey};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;
const ChatOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 1501;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  pointer-events: auto;
  width: 400px;
`;
