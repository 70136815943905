import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Typography } from '@mui/material';
import If from 'app/components/If';
import { useRef } from 'react';
import styled from 'styled-components';

function BranchCell(params: CustomCellRendererProps) {
  const { branchCodeData, branchData } = params.value;

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Wrapper ref={wrapperRef}>
        <If condition={branchCodeData}>
          <Typography fontSize="0.85rem">
            {branchCodeData + ' - ' + branchData}
          </Typography>
        </If>
        <If condition={!branchCodeData && branchData}>
          <Typography fontSize="0.85rem">{branchData || '-'}</Typography>
        </If>
        <If condition={!branchCodeData && !branchData}>
          <Typography fontSize="0.85rem">-</Typography>
        </If>
      </Wrapper>
      {/**
       * <BranchesPopover
        open={open}
        setOpen={setOpen}
        wrapperRef={wrapperRef.current}
        customerId={customerId}
        branches={branches}
        search={search}
        setSearch={setSearch}
        setLimit={setLimit}
        setSelectedBranch={setSelectedBranch}
        selectedBranch={selectedBranch}
      />
       */}
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  align-content: center;
`;

export default BranchCell;
