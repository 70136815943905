import { useGetProductsByTopCategoryQuery } from 'common/services/productApi';

export const useFetchTopProductsCategory = ({ urlParams }) => {
  const {
    data: productsData,
    refetch,
    isLoading,
    isFetching,
  } = useGetProductsByTopCategoryQuery({
    urlQuery: urlParams || '',
  });

  return {
    productsData,
    refetch,
    isLoading,
    isFetching,
  };
};
