import api from './api';
import { CustomerOffer, CustomerOfferBody } from '../types/Customer';
import {
  AddOfferNotePayload,
  DeleteOfferNotePayload,
  EditOfferNotePayload,
  DeleteOfferNoteRes,
  EditOfferNoteRes,
  AddOfferNoteRes,
} from 'common/types/customerOffers';

export const customerOffersApis = api.injectEndpoints({
  endpoints: builder => ({
    addOffer: builder.mutation<void, { body: CustomerOfferBody }>({
      query: body => ({
        url: '/customer-offers',
        method: 'POST',
        body: body.body,
      }),
      invalidatesTags: ['Offers', 'CustomerContractStats'],
    }),
    updateOffer: builder.mutation<
      void,
      { id: string; body: CustomerOfferBody }
    >({
      query: ({ id, body }) => ({
        url: `/customer-offers/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['Offers'],
    }),
    getOffers: builder.query<CustomerOffer[], { urlParams: string }>({
      query: ({ urlParams }) => ({
        url: `/customer-offers${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Offers'],
    }),
    removeOffer: builder.mutation<void, { body: { offerIds: string[] } }>({
      query: ({ body }) => ({
        url: `/customer-offers/delete`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Offers', 'CustomerContractStats'],
    }),
    toggleActiveOffer: builder.mutation<
      void,
      { body: { offerIds: string[]; isActive: boolean } }
    >({
      query: ({ body }) => ({
        url: `/customer-offers/toggle-active`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Offers', 'CustomerContractStats'],
    }),
    toggleStatusOffer: builder.mutation<
      void,
      { body: { offerIds: string[]; status: string } }
    >({
      query: ({ body }) => ({
        url: `/customer-offers/change-status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Offers', 'CustomerContractStats'],
    }),
    addOfferNote: builder.mutation<AddOfferNoteRes, AddOfferNotePayload>({
      query: ({ offerId, body }) => ({
        url: `/customer-offers/${offerId}/notes/`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Offers'],
    }),
    deleteOfferNote: builder.mutation<
      DeleteOfferNoteRes,
      DeleteOfferNotePayload
    >({
      query: ({ offerId, noteId }) => ({
        url: `/customer-offers/${offerId}/notes/${noteId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Offers'],
    }),
    editOfferNote: builder.mutation<EditOfferNoteRes, EditOfferNotePayload>({
      query: ({ offerId, body, noteId }) => ({
        url: `customer-offers/${offerId}/notes/${noteId}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['Offers'],
    }),
  }),
});

export const {
  useAddOfferMutation,
  useLazyGetOffersQuery,
  useUpdateOfferMutation,
  useRemoveOfferMutation,
  useToggleActiveOfferMutation,
  useToggleStatusOfferMutation,
  useAddOfferNoteMutation,
  useEditOfferNoteMutation,
  useDeleteOfferNoteMutation,
} = customerOffersApis;

export default customerOffersApis;
