import { useState } from 'react';
import { useModal } from 'app/components/Dialog/hooks';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useToaster } from './useToaster';

export type AsyncDataOptions = {
  valid?: boolean;
  color?: string;
  successMessage?: string;
  confirmationProps?: {
    title: string;
    message: string;
  };
  onSuccess?: (data: unknown) => void | Promise<void>;
  onError?: (data: unknown) => void | Promise<void>;
};

export function useAsyncData(
  request: () => Promise<unknown>,
  options?: AsyncDataOptions,
) {
  const [loading, setLoading] = useState(false);
  const [error, setError]: any = useState(null);
  const valid = options?.valid === undefined ? true : options.valid;
  const toaster = useToaster();
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();

  async function callApi() {
    if (options && !valid) {
      return console.error('form not valid');
    }
    try {
      if (options?.confirmationProps) {
        openModal({
          title: t(options.confirmationProps.title),
          content: t(options.confirmationProps.message),
          action: {
            actionText: 'ok',
            async actionCallback() {
              setLoading(true);
              const data = await request();
              if (options?.onSuccess) {
                await options.onSuccess(data);
              }
              if (options?.successMessage) {
                toaster(3000, 'success', t(options.successMessage));
              }
              closeModal();
              setLoading(false);
            },
          },
          cancel: true,
        });
      } else {
        setLoading(true);
        const data = await request();
        if (options?.onSuccess) {
          await options.onSuccess(data);
        }
        if (options?.successMessage) {
          toaster(3000, 'success', t(options.successMessage));
        }
        return data;
      }
    } catch (e: any) {
      if (e.status === 400 && e.data?.validation) {
        e.data?.validation.forEach((errorItem) => {
          setError({
            ...error,
            [errorItem.context?.key]: {
              type: 'custom',
              message: t(errorItem.message),
            },
          });
        });
      } else {
        const err =
          e.response?.message || e.response?.data?.message || e.message;
        setError(err);
      }
      if (options?.onError) {
        await options.onError(e);
      }
    } finally {
      setLoading(false);
    }
  }

  return { loading, error, valid, callApi };
}
