import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import {
  useGetAdminRefundsQuery,
  useGetRefundsQuery,
} from 'common/services/transactions';

const useRefunds = (queryParams: any) => {
  const authUser = useSelector(selectAuthUser);
  const isUserCustomer = authUser?.currentAgent?._role?.key === 'customer';

  const {
    data: customerRefunds,
    isLoading: isLoadingCustomer,
    isFetching: isFetchingCustomer,
    refetch: refetchCustomer,
  } = useGetRefundsQuery(queryParams, { skip: !isUserCustomer });

  const {
    data: refunds,
    isLoading,
    isFetching,
    refetch,
  } = useGetAdminRefundsQuery(queryParams, { skip: isUserCustomer });

  return {
    refunds: isUserCustomer ? customerRefunds : refunds,
    isLoading: isUserCustomer ? isLoadingCustomer : isLoading,
    isFetching: isUserCustomer ? isFetchingCustomer : isFetching,
    refetch: isUserCustomer ? refetchCustomer : refetch,
  };
};

export default useRefunds;
