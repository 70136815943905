import { Typography } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useMemo, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import If from 'app/components/If';
import { themes } from 'styles/theme/themes';
import { useGetProductNotesQuery } from 'common/services/orderApi';

export function DeliveryInformationTab({ order }) {
  const { t } = useTranslation();

  const damagedItemsReasons = useMemo(() => {
    if (order?.deliveryInfo?.damagedItems) {
      if (order.deliveryInfo.damagedItems.length > 0) {
        return '';
      }
      return order.deliveryInfo.damagedItems
        .map((item) => item.comment)
        .join(', ');
    } else {
      return '';
    }
  }, [order]);
  function formatDate(dateInput: string): string {
    const date = new Date(dateInput);
    // Extract each individual component
    const weekday = date.toLocaleDateString(undefined, { weekday: 'long' });
    const month = date.toLocaleDateString(undefined, { month: 'long' });
    const day = date.toLocaleDateString(undefined, { day: 'numeric' });
    const year = date.toLocaleDateString(undefined, { year: 'numeric' });

    return `${weekday} ${month} ${day} ${year}`;
  }
  const productId = '';
  const { data } = useGetProductNotesQuery({ orderId: order?.id, productId });

  const notes = useMemo(() => {
    if (!data?.docs) return [];
    return data.docs.map((doc) => doc.content);
  }, [data]);
  return (
    <DeliveryInformationWrapper>
      <Typography
        fontWeight={700}
        fontSize="0.875rem"
        sx={{ marginBottom: '10px', marginTop: '20px' }}
      >
        Visit Details
      </Typography>

      <ContentContainer>
        <ContentTitle width="200px" fontSize="0.75rem">
          {t('orders.items')}
        </ContentTitle>
        <If
          condition={Boolean(order?.products?.length)}
          otherwise={
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              -
            </Typography>
          }
        >
          <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
            {order?.products?.length} {t('orders.items')}
          </Typography>
        </If>
      </ContentContainer>
      <ContentContainer>
        <ContentTitle width="200px" fontSize="0.75rem">
          {t('common.address')}
        </ContentTitle>
        <If
          condition={Boolean(order?.cache?._deck?.location?.name)}
          otherwise={
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              -
            </Typography>
          }
        >
          <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
            {order?.cache?._deck?.location?.name}
          </Typography>
        </If>
      </ContentContainer>

      {['pending', 'pending-updates', 'pending-customer'].includes(
        order?.status,
      ) && (
        <ContentContainer>
          <ContentTitle width="200px" fontSize="0.75rem">
            {t('order_details.delivery_information.submitted_on')}
          </ContentTitle>
          <If
            condition={Boolean(order?.updatedAt)}
            otherwise={
              <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                n/a
              </Typography>
            }
          >
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              {formatDate(order?.updatedAt)}
            </Typography>
          </If>
        </ContentContainer>
      )}

      {!['canceled'].includes(order?.status) && (
        <ContentContainer>
          <ContentTitle width="200px" fontSize="0.75rem">
            {t('order_details.delivery_information.delivery_date')}{' '}
          </ContentTitle>
          <If
            condition={Boolean(order?.deliveryDate)}
            otherwise={
              <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                n/a
              </Typography>
            }
          >
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              {formatDate(order?.deliveryDate)}
            </Typography>
          </If>
        </ContentContainer>
      )}

      {['canceled'].includes(order?.status) && (
        <ContentContainer>
          <ContentTitle width="200px" fontSize="0.75rem">
            {t('order_details.delivery_information.canceled_on')}{' '}
          </ContentTitle>
          <If
            condition={Boolean(order?.updatedAt)}
            otherwise={
              <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                -
              </Typography>
            }
          >
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              {formatDate(order?.updatedAt)}
            </Typography>
          </If>
        </ContentContainer>
      )}
      {order?.confirmationCode && ['delivered'].includes(order?.status) && (
        <ContentContainer>
          <ContentTitle width="200px" fontSize="0.75rem">
            Confirmation Code Status{' '}
          </ContentTitle>
          <If
            condition={Boolean(order?.confirmationCode)}
            otherwise={
              <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                Not Entered
              </Typography>
            }
          >
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              <HideableNumber number={order?.confirmationCode} />
            </Typography>
          </If>
        </ContentContainer>
      )}
      {['delivered'].includes(order?.status) && (
        <ContentContainer>
          <ContentTitle width="200px" fontSize="0.75rem">
            {t('order_details.delivery_information.signed_by')}{' '}
          </ContentTitle>
          <If
            condition={Boolean(order?.deliveryInfo?.handlerFirstName)}
            otherwise={
              <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                -
              </Typography>
            }
          >
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              {order?.deliveryInfo?.handlerFirstName}{' '}
              {order?.deliveryInfo?.handlerLastName}{' '}
            </Typography>
          </If>
        </ContentContainer>
      )}
      {order?.deliveryInfo?.confirmationSignatureUrl &&
        ['delivered'].includes(order?.status) && (
          <ContentContainer>
            <ContentTitle width="200px" fontSize="0.75rem">
              {t('order_details.delivery_information.signature')}{' '}
            </ContentTitle>
            <If
              condition={Boolean(order?.deliveryInfo?.confirmationSignatureUrl)}
              otherwise={
                <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
                  -
                </Typography>
              }
            >
              <img
                style={{
                  border: `dashed thin ${themes.default?.borderGrey}`,
                  maxHeight: '40px',
                  maxWidth: '100px',
                }}
                src={order?.deliveryInfo?.confirmationSignatureUrl}
                alt={t('order_details.delivery_information.signature')}
              />
            </If>
          </ContentContainer>
        )}

      <ContentContainer>
        <ContentTitle width="200px" fontSize="0.75rem">
          Supports Delivered vs Collected{' '}
        </ContentTitle>
        <If
          condition={Boolean(
            order?.deliveryInfo?.packagesCount ||
              order?.cache?.stats?.totalPackages,
          )}
          otherwise={
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              -
            </Typography>
          }
        >
          <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
            {order?.deliveryInfo?.packagesCount || 0} {t('orders.items')}
            {damagedItemsReasons !== '' && (
              <Tooltip title={damagedItemsReasons} arrow>
                <InfoOutlinedIcon
                  sx={{ marginLeft: '15px', color: 'rgba(129, 145, 152, 1)' }}
                />
              </Tooltip>
            )}{' '}
            - {order?.cache?.stats?.totalPackages || 0} {t('orders.items')}
          </Typography>
        </If>
      </ContentContainer>
      <Typography
        fontWeight={700}
        fontSize="0.875rem"
        sx={{ marginBottom: '10px', marginTop: '20px' }}
      >
        Additional Details{' '}
      </Typography>
      <ContentContainer>
        <ContentTitle width="200px" fontSize="0.75rem">
          {t('common.note')}
        </ContentTitle>
        <If
          condition={Boolean(notes.length)}
          otherwise={
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              -
            </Typography>
          }
        >
          <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
            {notes.join(', ')}
          </Typography>
        </If>
      </ContentContainer>
      <Typography
        fontWeight={700}
        fontSize="0.875rem"
        sx={{ marginBottom: '10px', marginTop: '20px' }}
      >
        Accounting Information{' '}
      </Typography>
      <ContentContainer>
        <ContentTitle width="200px" fontSize="0.75rem">
          {t('payment_to_collect')}
        </ContentTitle>
        <If
          condition={Boolean(order?.totalAmountTax)}
          otherwise={
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              -
            </Typography>
          }
        >
          <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
            {`€${order?.totalAmountTax?.toFixed(2)}`}
          </Typography>
        </If>
      </ContentContainer>
      <ContentContainer>
        <ContentTitle width="200px" fontSize="0.75rem">
          {t('accounting.amount_received')}
        </ContentTitle>
        <If
          condition={order?.paymentCollection !== undefined}
          otherwise={
            <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
              {order?.paymentCollection === 0 ? '€0' : '-'}
            </Typography>
          }
        >
          <Typography fontWeight={400} fontSize="0.75rem" ml="auto">
            {order?.paymentCollection === 0
              ? '€0'
              : `€${order?.paymentCollection?.toFixed(2)}`}
          </Typography>
        </If>
      </ContentContainer>
    </DeliveryInformationWrapper>
  );
}

function HideableNumber({ number }) {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body1">
        {isVisible ? number : '*'.repeat(number.toString().length)}
      </Typography>
      <IconButton
        style={{ marginLeft: '10px', padding: 0 }}
        onClick={toggleVisibility}
      >
        {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </div>
  );
}

const DeliveryInformationWrapper = styled.div`
  height: 100%;
  overflow: scroll;
  padding-right: 24px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  min-height: 40px;
  background: ${themes?.default?.lightGrey};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 10px;
`;
export const ContentTitle = styled(Typography)`
  color: ${themes?.default?.clientMarkerBg};
`;
