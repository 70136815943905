import React from 'react';
import { Alert, Button, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IRoundtripMappingsContext } from '../index';
import { useTranslation } from 'react-i18next';

function AddRoundtripsWithTemplate({ warehouseId }) {
  const RoundtripMappingsContext = useManagedContext<IRoundtripMappingsContext>(
    'RoundtripMappingsContext',
  );
  const [numberOfRoundtrips, setNumberOfRoundtrips] =
    React.useState<string>('0');

  const { t } = useTranslation();
  const onAddNewRountripsTemplate = async () => {
    RoundtripMappingsContext.updateDataWithFunction((prev) => {
      const newRoundtrips = Array.from(
        { length: parseInt(numberOfRoundtrips) || 0 },
        (_, i) => ({
          id: `New ${i}`,
          area: '',
          roundtripCode: '',
          driver: '',
          vehicle: '',
        }),
      );

      prev.roundtripMappings = prev.roundtripMappings.map((mapping) => {
        if (mapping.id === warehouseId) {
          mapping.roundtrips = [...mapping.roundtrips, ...newRoundtrips];
          mapping.nbOfRoundtrips += parseInt(numberOfRoundtrips) || 0;
        }
        return mapping;
      });
    });
  };
  return (
    <CardContent sx={{ '&:last-child': { paddingBottom: 0, paddingTop: 0 } }}>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Alert severity="info">
          <Typography fontSize="14px" fontWeight={400} lineHeight="20px">
            {t('roundtrip.mapping.empty.state')}
          </Typography>
        </Alert>

        <Stack
          direction="row"
          gap="8px"
          justifyContent="center"
          alignItems="baseline"
          height="fit-content"
        >
          <Typography
            fontSize="14px"
            fontWeight={400}
            lineHeight="17px"
            display="flex"
            alignItems="center"
          >
            Add
          </Typography>
          <TextField
            sx={{
              maxWidth: '48px',
              padding: 0,
              '& .MuiOutlinedInput-root': {
                padding: 0,
                paddingX: '2px',
                minHeight: '30px',
                '& input': {
                  textAlign: 'center',
                  padding: 0,
                  paddingX: '2px',
                  minHeight: '30px',
                },
                '& fieldset': {
                  borderRadius: '8px',
                  minHeight: '30px',
                },
              },
            }}
            type="number"
            id="outlined-basic"
            variant="outlined"
            inputProps={{
              min: 0,
            }}
            onChange={(e) => {
              const value =
                e.target.value.replace(/[^0-9]/g, '').replace(/^0+/, '') || '';
              setNumberOfRoundtrips(value);
            }}
            onKeyDown={(e) => {
              if (['e', 'E', '+', '-', '.'].includes(e.key)) {
                e.preventDefault();
              }
            }}
            value={numberOfRoundtrips}
            onClick={(e) => e.stopPropagation()}
          />

          <Typography
            fontSize="14px"
            fontWeight={400}
            lineHeight="17px"
            display="flex"
            alignItems="center"
          >
            roundtrips
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: '140px',
              height: '30px',
              py: 1.3,
              px: 1.3,
              gap: 1.3,
              borderRadius: '4px',
              marginTop: '20px',
            }}
            onClick={() => {
              onAddNewRountripsTemplate();
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </CardContent>
  );
}

export default AddRoundtripsWithTemplate;
