import { CustomCellRendererProps } from '@ag-grid-community/react';
import { useTranslation } from 'react-i18next';

function TypeCell(params: CustomCellRendererProps) {
  const type = params?.value?.typeData;
  const { t } = useTranslation();

  return (
    <div>
      <span>{t(`common.${type}`)}</span>
    </div>
  );
}

export default TypeCell;
