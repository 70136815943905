import { Badge, IconButton } from '@mui/material';
import { Note } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { NotesModal } from './components/NotesModal';
import { useCallback, useMemo, useState } from 'react';
import { useNavLock } from 'hooks/useNavLock';

export const NotesCell = (params) => {
  const {
    _id,
    notes,
    handleAddNote,
    handleEditNote,
    handleDeleteNote,
    getIsLoadingNotes,
    subTitle,
    name,
  } = params.value;

  const { navLocked } = useNavLock();

  const [modalOpen, setModalOpen] = useState(false);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [editingNoteId, setEditingNoteId] = useState<string>('');
  const [newNote, setNewNote] = useState('');

  const handleCancelAddNote = useCallback(() => {
    setAddNoteOpen(false);
    setNewNote('');
    setEditingNoteId('');
  }, []);

  const handleToggleModalOpen = useCallback(() => {
    setModalOpen(false);
    handleCancelAddNote();
  }, [handleCancelAddNote]);

  const handleAddNoteClick = useCallback(
    async (content: string) => {
      if (!!editingNoteId) {
        await handleEditNote(_id, editingNoteId, content);
      } else {
        await handleAddNote(_id, content);
      }
      handleCancelAddNote();
    },
    [_id, editingNoteId, handleAddNote, handleCancelAddNote, handleEditNote],
  );

  const handleDeleteNoteClick = useCallback(
    async (noteId: string) => {
      await handleDeleteNote(_id, noteId);
    },
    [_id, handleDeleteNote],
  );

  const handleEditNoteClick = useCallback(
    async (noteId: string, content: string) => {
      // await handleEditNote(_id, noteId, content);
      setAddNoteOpen(true);
      setNewNote(content);
      setEditingNoteId(noteId);
    },
    [],
  );

  const isLoading = useMemo(() => {
    if (getIsLoadingNotes) {
      return getIsLoadingNotes();
    } else return false;
  }, [getIsLoadingNotes]);

  return (
    <>
      <IconButton
        disableRipple={false}
        sx={{ padding: '4px', cursor: navLocked ? 'not-allowed' : '' }}
        onClick={() => setModalOpen(true)}
        disabled={navLocked}
      >
        <Badge
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          variant="dot"
          overlap="circular"
          invisible={!notes?.length}
        >
          <Icon icon={<Note />} />
        </Badge>
      </IconButton>
      <NotesModal
        open={modalOpen}
        onClose={handleToggleModalOpen}
        subtitle={
          <>
            {subTitle} <span>{name}</span>
          </>
        }
        notes={notes}
        addNoteOpen={addNoteOpen}
        setAddNoteOpen={setAddNoteOpen}
        onCancel={handleCancelAddNote}
        onAddNote={handleAddNoteClick}
        newNote={newNote}
        onNoteChange={(value) => setNewNote(value)}
        onDelete={handleDeleteNoteClick}
        onEdit={handleEditNoteClick}
        editingNoteId={editingNoteId}
        isLoading={isLoading}
      />
    </>
  );
};
