import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getInitials } from 'common/helpers/organization';
import { isEmpty } from 'lodash';
import { indigo } from '@mui/material/colors';
import If from 'app/components/If';
import { UploadComponent, UploadedFile } from 'app/components/UploadComponent';
import { FileRejection } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { UploadZone } from 'app/components/UploadFiles';
import { themes } from 'styles/theme/themes';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { email_expression } from 'app/components/SignUp/variables';
import MapPopover from 'app/components/MapPopover';
import { handleCloseStopMap } from 'app/pages/AddRoundTrips/functions';

export function Details() {
  const { t, i18n } = useTranslation();
  const authUser = useSelector(selectAuthUser);
  const [textFields, setTextFields] = useState<number[]>([]);
  const [phones, setPhones] = useState<number[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [filesRejected, setFilesRejected] = useState<FileRejection[]>([]);
  const [dateValue, setDateValue] = useState<any>();
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [selectedStop, setSelectedStop] = useState<null | any>();
  const [selectedStopCopy, setSelectedStopCopy] = useState<null | any>();

  const [hideProgressBar, setHideProgressBar] = useState<{
    [key: string]: boolean;
  }>({});
  const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>(
    {},
  );
  const [statusFile, setStatusFile] = useState<{ [key: string]: string }>({});

  const testDropdown = [
    {
      value: 'un',
      label: 'TEST1',
    },
    {
      value: 'deux',
      label: 'TEST2',
    },
  ];

  const handleAddTextField = () => {
    const newTextFieldId = textFields.length;
    setTextFields([...textFields, newTextFieldId]);
  };

  const handleDeleteTextField = (id: number) => {
    const updatedTextFields = textFields.filter(
      (textFieldId) => textFieldId !== id,
    );
    setTextFields(updatedTextFields);
  };

  const handleAddPhone = () => {
    const newPhoneIndex = phones.length;
    setPhones([...phones, newPhoneIndex]);
  };

  const handleDeletePhone = (id: number) => {
    const updatedPhones = phones.filter((phoneId) => phoneId !== id);
    setPhones(updatedPhones);
  };

  const handleChange = (event) => {
    const result = event.target.value.replace(/[^a-z]/gi, '');
    setMessage(result);
  };

  ///On click Delete a single file
  function handleDeleteFile(fileName) {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  }

  ///On click Delete a single file
  function handleDeleteRejectedFiles(fileName) {
    setFilesRejected((prevFiles) =>
      prevFiles.filter((file) => file.file.name !== fileName),
    );
  }

  const onUpload = async (files: UploadedFile[]) => {
    const file = files[0];
    setFiles((prevFiles) => [...prevFiles, file]);
    setFileProgress((prevProgress) => ({ ...prevProgress, [file.name]: 0 }));
    setStatusFile((prevStatus) => ({ ...prevStatus, [file.name]: 'loading' }));
    setHideProgressBar((prevState) => ({ ...prevState, [file.name]: false }));
    await simulateFileUpload(file);
  };

  const onNotUpload = (file: FileRejection) => {
    setFilesRejected((prevFilesRejected) => [...prevFilesRejected, file]);
  };
  const currentDate = dayjs().startOf('day');

  const simulateFileUpload = (file: File): Promise<void> => {
    return new Promise((resolve) => {
      const progressInterval = setInterval(() => {
        setFileProgress((prevProgress) => {
          const newProgress = prevProgress[file.name] + 1;
          if (newProgress === 100) {
            setStatusFile((prevStatus) => ({
              ...prevStatus,
              [file.name]: 'complete',
            }));
            setHideProgressBar((prevState) => ({
              ...prevState,
              [file.name]: true,
            }));
            clearInterval(progressInterval);
            resolve();
          }
          return { ...prevProgress, [file.name]: newProgress };
        });
      });
    });
  };

  const filesList = files.map((file) => (
    <FilesListWrapper>
      <UploadFileIcon
        color="primary"
        sx={{
          width: '28px',
          height: '28px',
          mr: '16px',
        }}
      />
      <div
        className="container"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Typography fontSize="1rem" fontWeight="400">
          {file.name}
        </Typography>
        <div
          className="detailWrapper"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography>{(file.size / 1024).toFixed(2)}kb</Typography>
          <Typography sx={{ ml: '8px' }}>•</Typography>
          <Typography sx={{ ml: '8px' }}>{statusFile[file.name]}</Typography>
        </div>
        <If condition={!hideProgressBar[file.name]} otherwise={null}>
          <LinearProgress
            variant="determinate"
            value={fileProgress[file.name]}
            sx={{ width: '200px', mt: '4px' }}
          />
        </If>
      </div>
      <IconButton
        sx={{ ml: 'auto' }}
        onClick={() => handleDeleteFile(file.name)}
      >
        <CloseIcon color="action" />
      </IconButton>
    </FilesListWrapper>
  ));

  const rejectedList = filesRejected.map((file) => (
    <FilesListWrapper>
      <UploadFileIcon
        color="error"
        sx={{ width: '28px', height: '28px', mr: '16px' }}
      />
      <div
        className="container"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Typography fontSize="1rem" fontWeight="400" color="error">
          {t('settings.companyProfile.errors.failed')}
        </Typography>
        <div
          className="detailWrapper"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {file.errors[0].code === 'file-invalid-type' ? (
            <Typography fontSize="1rem" fontWeight="400" color="error">
              {t('settings.companyProfile.errors.typeFile')}
            </Typography>
          ) : file.file.size > 3000000 ? (
            <Typography fontSize="1rem" fontWeight="400" color="error">
              {t('settings.companyProfile.errors.size')}
            </Typography>
          ) : (
            <Typography fontSize="1rem" fontWeight="400" color="error">
              {t('settings.companyProfile.errors.numberFile')}
            </Typography>
          )}
          <Typography sx={{ ml: '8px' }} color="error">
            •
          </Typography>
          <Typography sx={{ ml: '8px' }} color="error">
            Failed
          </Typography>
        </div>
      </div>
      <IconButton
        sx={{ ml: 'auto' }}
        onClick={() => handleDeleteRejectedFiles(file.file.name)}
      >
        <CloseIcon color="action" />
      </IconButton>
    </FilesListWrapper>
  ));

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const {
    control,
    formState: { errors },
  } = methods;
  return (
    <Wrapper>
      <div className="detailsSection">
        <Typography
          fontSize="1rem"
          fontWeight="400"
          sx={{ mt: '22px', mb: '20px' }}
        >
          {t('settings.companyProfile.tab.details')}
        </Typography>
        <div className="logoSection">
          <Typography fontSize="1rem" fontWeight="400" sx={{ mb: '16px' }}>
            {t('settings.companyProfile.logo')}
          </Typography>
          <div
            className="logoWrappe"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Avatar
              sx={{
                mb: '16px',
                border: `1px solid ${themes?.default?.lightFill}`,
                bgcolor: !isEmpty(authUser?._currentOrganization.pictureUrl)
                  ? themes.default.accordionWhiteBg
                  : indigo['A200'],
              }}
              imgProps={{ sx: { objectFit: 'contain' } }}
              src={authUser?._currentOrganization.pictureUrl}
            >
              {authUser?._currentOrganization.pictureUrl
                ? authUser?._currentOrganization.pictureUrl
                : getInitials(authUser?._currentOrganization.shortname)}
            </Avatar>
            <UploadComponent
              openModal={openModal}
              onClose={() => setOpenModal(false)}
              title="Upload your logo"
              type="button"
              description=""
            />
          </div>
        </div>
        <FirstLineTextFieldWrapper>
          <TextField
            label={t('settings.companyProfile.detailsTextfield.companyName')}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '335px',
              marginRight: '16px',
            }}
          />
          <TextField
            label={t('settings.companyProfile.detailsTextfield.initials')}
            type="text"
            size="small"
            value={message}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '62px',
              marginRight: '16px',
            }}
          />
          <TextField
            label={t('settings.companyProfile.detailsTextfield.website')}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '282px',
            }}
          />
        </FirstLineTextFieldWrapper>
        <TextField
          label={t('settings.companyProfile.detailsTextfield.slogan')}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
        />
        <TextField
          label={t('settings.companyProfile.detailsTextfield.overview')}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
          multiline
          rows={4}
        />
        <SecondLineTextFieldWrapper>
          <TextField
            id="outlined-select-currency"
            select
            label={t('settings.companyProfile.detailsTextfield.industry')}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '226px',
              marginRight: '16px',
            }}
          >
            {testDropdown.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="outlined-select-currency"
            select
            label={t('settings.companyProfile.detailsTextfield.companySize')}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '226px',
              marginRight: '16px',
            }}
          >
            {testDropdown.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {/* <TextField
            label={t('settings.companyProfile.detailsTextfield.foundingDate')}
            size="small"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '226px',
            }}
          /> */}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n.language}
          >
            <DatePicker
              inputFormat="YYYY"
              value={dateValue}
              open={datePickerOpen}
              onClose={() => setDatePickerOpen(false)}
              onOpen={() => setDatePickerOpen(true)}
              onChange={(newValue) => {
                setDateValue(newValue);
              }}
              InputAdornmentProps={{ position: 'start' }}
              components={{
                OpenPickerIcon: DateRangeIcon,
              }}
              maxDate={currentDate} // Set the maxDate prop to the current date
              className="custom-date-picker"
              renderInput={(params) => (
                <TextField
                  hiddenLabel
                  size="small"
                  variant="filled"
                  onClick={() => setDatePickerOpen(true)}
                  sx={{
                    marginBottom: 'unset',
                    maxWidth: '220px',
                  }}
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {datePickerOpen ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </SecondLineTextFieldWrapper>
        <TextField
          label={t('settings.companyProfile.detailsTextfield.headquarter')}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
          value={
            selectedStopCopy
              ? `${selectedStopCopy?.[1]?.toFixed(
                  5,
                )},${selectedStopCopy?.[0]?.toFixed(5)}`
              : ''
          }
          InputProps={{
            endAdornment: (
              <LocationSearchingIcon
                sx={{ cursor: 'pointer' }}
                color="primary"
                onClick={() => {
                  if (selectedStopCopy) {
                    setSelectedStop([
                      selectedStopCopy?.[1],
                      selectedStopCopy?.[0],
                    ]);
                  } else setSelectedStop([2.2137, 46.2276]);
                }}
              />
            ),
          }}
        />
      </div>

      <div className="contactSection">
        <Typography
          fontSize="1rem"
          fontWeight="400"
          sx={{ mt: '48px', mb: '20px' }}
        >
          {t('settings.companyProfile.titleContact')}
        </Typography>
        <ContactTextFieldWrapper>
          <ContactLineWrapper>
            <Controller
              name="username"
              control={control}
              rules={{
                required: true,
                minLength: 2,
                pattern: {
                  value: email_expression,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('settings.companyProfile.contactTextfield.email')}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: '374px',
                  }}
                />
              )}
            />
            <Button
              sx={{
                height: '37px',
                ml: '16px',
              }}
              onClick={handleAddTextField}
            >
              <AddIcon
                sx={{
                  cursor: 'pointer',
                }}
              />
            </Button>
          </ContactLineWrapper>
          <div style={{ height: '45px', color: themes.default.errorMain }}>
            {errors?.username && 'wrong email format'}
          </div>

          {textFields.map((textfield) => (
            <ContactLineWrapper key={textfield}>
              <TextField
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '374px',
                }}
              />
              <Button
                sx={{ height: '37px', ml: '16px' }}
                color="error"
                onClick={() => handleDeleteTextField(textfield)}
              >
                <RemoveCircleOutlineIcon color="error" />
              </Button>
            </ContactLineWrapper>
          ))}
          <ContactLineWrapper>
            <TextField
              label={t('settings.companyProfile.contactTextfield.phoneNumber')}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: '374px',
              }}
            />
            <Button
              sx={{ height: '37px', ml: '16px' }}
              onClick={handleAddPhone}
            >
              <AddIcon />
            </Button>
          </ContactLineWrapper>
          {phones.map((phone) => (
            <ContactLineWrapper key={phone}>
              <TextField
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '374px',
                }}
              />
              <Button
                sx={{ height: '37px', ml: '16px' }}
                color="error"
                onClick={() => {
                  handleDeletePhone(phone);
                }}
              >
                <RemoveCircleOutlineIcon color="error" />
              </Button>
            </ContactLineWrapper>
          ))}
        </ContactTextFieldWrapper>
      </div>

      <div className="documentSection" style={{ marginTop: '48px' }}>
        <Typography fontSize="1rem" fontWeight="400" sx={{ mb: '20px' }}>
          Documents
        </Typography>
        <UploadZone
          maxFiles={20}
          type=""
          title=""
          description="PDF, DOC, PNG, JPG or GIF (max. 3MB)"
          onOk={onUpload}
          onUnOk={onNotUpload}
          acceptFile={{
            'image/png': [],
            'image/jpg': ['.jpeg', '.jpg'],
            'image/gif': [],
            'application/pdf': [],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              ['.docx'],
          }}
        />
        {filesList}
        {rejectedList}
      </div>

      <div className="additionalDetailSection" style={{ marginTop: '48px' }}>
        <div className="headerSectionContainer" style={{ display: 'flex' }}>
          <Typography
            fontSize="1rem"
            fontWeight="400"
            sx={{ marginRight: '4px' }}
          >
            {t('settings.companyProfile.additionalDetails.title')}
          </Typography>
          <Typography
            color={themes?.default?.grey2}
            fontSize="1rem"
            fontWeight="400"
          >
            ({t('settings.companyProfile.additionalDetails.optional')})
          </Typography>
        </div>
        <TextField
          label="Mission"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
            mt: '20px',
          }}
          multiline
          rows={4}
        />
        <TextField
          label={t('product_service_offering')}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
          multiline
          rows={4}
        />
        <DropDownTextfieldWrapper>
          <TextField
            id="outlined-select-currency"
            select
            label="Target Audience"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '347px',
              marginRight: '16px',
            }}
          >
            {testDropdown.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="outlined-select-currency"
            select
            label="Market Position"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '347px',
            }}
          >
            {testDropdown.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DropDownTextfieldWrapper>
        <TextField
          label={t('social_responsability')}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
          multiline
          rows={4}
        />
      </div>
      <Button
        variant="contained"
        sx={{
          mt: '48px',
          mb: '24px',
        }}
      >
        {t('common.buttons.save')}
      </Button>
      <MapPopover
        open={selectedStop}
        handleClose={() => {
          handleCloseStopMap(setSelectedStop);
        }}
        latitude={selectedStop?.[1]}
        longitude={selectedStop?.[0]}
        editMap={true}
        setSelectedStopCopy={setSelectedStopCopy}
      />
    </Wrapper>
  );
}

const FirstLineTextFieldWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

const Wrapper = styled.div`
  height: 78vh;
  width: 50vw;
  min-width: 720px;
  margin-left: 23px;
  padding-bottom: 24px;
`;

const SecondLineTextFieldWrapper = styled.div`
  display: flex;
`;

const ContactTextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactLineWrapper = styled.div`
  display: flex;
`;

const FilesListWrapper = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px 16px 0 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const DropDownTextfieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;
