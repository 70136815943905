// for docs
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IColDef } from 'app/components/ManagedGrid/types';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { CustomInputCell } from 'app/components/ManagedGrid/components/Cells';
import { useRestrictionsSingle } from '../hooks/useRestrictionsSingle';
import { ArrowClockwise } from '@phosphor-icons/react';
import { ActionIconButton } from 'app/components/ActionIconButton';
import styled from 'styled-components';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { ROLE_KEYS } from 'types';
import { checkUserRole } from 'common/helpers/acl';

/** - ⚠️ WARNING: you must set `discountIndex` in {@link IColDef} */
export const DiscountInputCell = (params: CustomCellRendererProps) => {
  const { t } = useTranslation();
  const restrictions = useRestrictionsSingle(params.value.discountIndex);
  const gridContext = useManagedContext<IGridContext>('grid');
  const authUser = useSelector(selectAuthUser);

  const isAdmin = useMemo(() => {
    return checkUserRole(authUser, [
      ROLE_KEYS.ADMIN,
      ROLE_KEYS.SALES_ADMINISTRATION,
    ]);
  }, [authUser?.currentAgent?._role?.key]);

  // canEdit (and max i guess) is readonly so this is a workaround
  return (
    <Wrapper>
      <CustomInputCellWrapper>
        <CustomInputCell
          {...params}
          value={{
            ...params.value,
            max: restrictions.max * 100,
            canEdit:
              !params.value.disabled &&
              ((!restrictions.lock && params.value?.canEdit) || isAdmin),
          }}
        />
      </CustomInputCellWrapper>
      <CustomIconCellWrapper>
        {params.value.hasReset && !gridContext.extraData.onBlur && (
          <ActionIconButton
            title={t('common.reset')}
            onClick={() => params.value?.onReset?.()}
            icon={<ArrowClockwise size={13} />}
          />
        )}
      </CustomIconCellWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;
const CustomInputCellWrapper = styled.div`
  flex: 1;
`;
const CustomIconCellWrapper = styled.div`
  margin-left: -8px;
  margin-top: 1px;
`;
