import api from './api';

export const customerPortalApi = api.injectEndpoints({
  endpoints: builder => ({
    getCustomerPortalStats: builder.query<any, string>({
      query: () => ({
        url: `/customer-portal/stats`,
      }),
      providesTags: ['CustomerPortalStats'],
    }),
    getCustomerPortalInvoices: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customer-portal/invoices${urlQuery}`,
      }),
      providesTags: ['CustomerPortalInvoices'],
    }),
    getCustomerPortalInvoicesByList: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customer-portal/invoices/by-list${urlQuery}`,
      }),
      providesTags: ['CustomerPortalInvoices'],
    }),
    getCustomerPortalOrdersId: builder.query<any, string>({
      query: id => ({
        url: `/customer-portal/orders/${id}`,
      }),
      providesTags: ['CustomerPortalOrdersId'],
    }),
    getCustomerPortalConversationsFacets: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customer-portal/conversations/facets${urlQuery}`,
      }),
      providesTags: ['CustomerPortalConversationsFacets'],
    }),
    getCustomerPortalConversations: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customer-portal/conversations${urlQuery}`,
      }),
      providesTags: ['CustomerPortalConversations'],
    }),
    getCustomerPortalClaims: builder.query<any, any>({
      query: urlQuery => ({
        url: `/customer-portal/conversations${urlQuery}`,
        method: 'GET',
      }),
      providesTags: ['Conversations'],
    }),
    postCustomerPortalClaims: builder.mutation<any, any>({
      query: body => ({
        url: '/customer-portal/conversations',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Conversations'],
    }),
    getCustomerPortalClaimsFacets: builder.query<any, any>({
      query: urlQuery => ({
        url: `/customer-portal/conversations/facets${urlQuery}`,
        method: 'GET',
      }),
    }),
    getCustomerStatusCount: builder.query<any, any>({
      query: () => ({
        url: '/customer-portal/conversations/count-status',
        method: 'GET',
      }),
    }),
    getCustomerPortalConversation: builder.query<any, string>({
      query: id => ({
        url: `/customer-portal/conversations/${id}`,
      }),
      providesTags: ['CustomerPortalConversation'],
    }),
    deleteCustomerPortalDocument: builder.mutation<any, any>({
      query: ({ documentId, documentType }) => ({
        url: `/customer-portal/documents/${documentId}?documentType=${documentType || ''}`,
        method: 'DELETE',
      }),
    }),
    getCustomerPortalConversationFiles: builder.query<any, any>({
      query: id => ({
        url: `/customer-portal/conversations/${id}/files`,
      }),
    }),
  }),
});

export const {
  useGetCustomerPortalStatsQuery,
  useGetCustomerPortalInvoicesQuery,
  useGetCustomerPortalInvoicesByListQuery,
  useGetCustomerPortalOrdersIdQuery,
  useGetCustomerPortalConversationsFacetsQuery,
  useGetCustomerPortalConversationsQuery,
  useGetCustomerPortalClaimsQuery,
  useLazyGetCustomerPortalClaimsQuery,
  usePostCustomerPortalClaimsMutation,
  useGetCustomerPortalClaimsFacetsQuery,
  useLazyGetCustomerPortalClaimsFacetsQuery,
  useGetCustomerStatusCountQuery,
  useLazyGetCustomerStatusCountQuery,
  useLazyGetCustomerPortalInvoicesQuery,
  useGetCustomerPortalConversationQuery,
  useDeleteCustomerPortalDocumentMutation,
  useGetCustomerPortalConversationFilesQuery,
} = customerPortalApi;

export default customerPortalApi;
