import { useTranslation } from 'react-i18next';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCreatePaymentMutation } from 'common/services/payments';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';
import dayjs from 'dayjs';

function ActionCell(params: CustomCellRendererProps) {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext>('grid');
  const [createPayment, { isLoading: paymentIsLoading }] =
    useCreatePaymentMutation();

  const paymentEnable = params?.value?.paymentEnable;
  const document = params?.value?.document;

  let payment = document?._payment;

  if (payment?.deleted || payment?.status === 'draft') {
    payment = null;
  }

  const hasSelectedRows = gridContext?.selectedRows?.length > 0;

  const handlePay = async () => {
    const res = await createPayment({
      okUrl: `${window.location.origin}/ordoria/transactions/invoices?payment=ok`,
      koUrl: `${window.location.origin}/ordoria/transactions/invoices?payment=ko`,
      fileId: document._id,
    }).unwrap();

    if (res?.paymentUrl) {
      window.open(res.paymentUrl, '_blank');
    }
  };

  const displayPaymentButton =
    paymentEnable &&
    document?.metadata?.type === 'invoice' &&
    document.accountingPaymentStatus !== 'paid' &&
    ((payment?.status !== 'success' && payment?.status !== 'initiated') ||
      !payment?.status);

  const getDateToDisplay = (file) => {
    if (file?.accountingPaymentStatus === 'paid') {
      return file?._payment?.status === 'success'
        ? file._payment.createdAt
        : file.accountingStatusCheckAt;
    }
    return '-';
  };

  return (
    <div>
      {displayPaymentButton && (
        <>
          {paymentIsLoading ? (
            <LoadingButton
              disabled={hasSelectedRows}
              variant="contained"
              size="small"
            >
              {t('customerPortal.documents.payOnlineInProgress')}
            </LoadingButton>
          ) : (
            <Button
              disabled={hasSelectedRows}
              variant="contained"
              onClick={handlePay}
              size="small"
              sx={{
                minWidth: 'fit-content',
                height: '20px',
                background: !hasSelectedRows
                  ? 'linear-gradient(to bottom, #5B26EA, #AD87F7)'
                  : 'transparent',
                fontWeight: 400,
              }}
            >
              {t('customerPortal.documents.payOnline')}
            </Button>
          )}
        </>
      )}
      {document?.accountingPaymentStatus === 'paid' && (
        <span>{dayjs(getDateToDisplay(document)).format('DD MMM YYYY')}</span>
      )}
    </div>
  );
}

export default ActionCell;
