import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { Archive, DownloadSimple } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { downloadFile } from 'app/helpers/helpers';
import If from 'app/components/If';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import { useModal } from 'app/components/Dialog/hooks';
import { useUpdateArchiveRefundsMutation } from 'common/services/transactions';

export const BulkActionsToolbar = () => {
  const { t } = useTranslation();
  const { isCustomer } = useGetUserAcl();
  const gridContext = useManagedContext<IGridContext>('grid');

  const {
    queryParams: { filters },
  } = gridContext;

  const selectedRows: any = gridContext.selectedRows;
  const rowDataList = gridContext.rowData;

  function handleDownloadFiles() {
    const selectedFiles = rowDataList.filter((row) =>
      selectedRows?.includes(row?.id),
    );
    selectedFiles?.map((file) => {
      const fileUrl = file?.__download?.downloadData;
      const fileName = fileUrl.split('/').pop()?.split('_')[2];
      downloadFile(fileUrl, fileName);
    });
  }

  const { openModal, closeModal } = useModal();
  const [updateArchive] = useUpdateArchiveRefundsMutation();

  function bulkArchiveBills() {
    const isSingle: boolean = selectedRows.length === 1;

    openModal({
      title: isSingle
        ? t('refunds.archive.title')
        : t('refunds.archive.title-bulk'),
      content: isSingle
        ? t('refunds.archive.content.single')
        : t('refunds.archive.content.bulk'),
      action: {
        actionText: t('messages.archive'),
        actionCallback: () => {
          updateArchive({ refundIds: selectedRows, isArchived: true });
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }

  function bulkUnarchiveBills() {
    const isSingle: boolean = selectedRows.length === 1;

    openModal({
      title: isSingle
        ? t('refunds.unarchive.title')
        : t('refunds.unarchive.title-bulk'),
      content: isSingle
        ? t('refunds.unarchive.content.single')
        : t('refunds.unarchive.content.bulk'),
      action: {
        actionText: t('messages.unarchive'),
        actionCallback: () => {
          updateArchive({ refundIds: selectedRows, isArchived: false });
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }

  return (
    <Wrapper>
      <Tooltip title={t('common.download')}>
        <IconButton
          onClick={handleDownloadFiles}
          sx={{
            ':hover': {
              background: themes?.default?.rippleGrey,
            },
            padding: '4px',
            width: '28px',
            height: '28px',
          }}
        >
          <DownloadSimple />
        </IconButton>
      </Tooltip>

      <If condition={!isCustomer() && !filters.archived}>
        <Tooltip title={t('messages.archive')} onClick={bulkArchiveBills}>
          <IconButton
            sx={{
              padding: '4px',
              width: '24px',
              height: '24px',
            }}
          >
            <Archive color={themes?.default?.chipRedBg} />
          </IconButton>
        </Tooltip>
      </If>

      <If condition={!isCustomer() && filters.archived}>
        <Tooltip title={t('messages.unarchive')} onClick={bulkUnarchiveBills}>
          <IconButton
            sx={{
              padding: '4px',
              width: '24px',
              height: '24px',
            }}
          >
            <Archive color={themes?.default?.chipRedBg} />
          </IconButton>
        </Tooltip>
      </If>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
