import { Box, Button, List, ListItem, Stack, Typography } from '@mui/material';
import {
  LeftSection,
  MainSection,
  RightSection,
  TabCard,
} from 'app/styledComponents/DetailsSection.styled';
import { useTranslation } from 'react-i18next';
import EmptyState from 'assets/img/customers/EmptyStateBranches.svg';
import { useParams } from 'react-router-dom';
import Each from 'common/UtilityComponents/Each';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'common/hooks/useTheme';
import SearchField from 'app/components/Search/SearchField';
import { Plus } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import If from 'app/components/If';
import { BranchDetails } from './components/BranchDetails';
import { Branch } from '../../../../../../../../common/types/Supplier';
import { useQueryArray } from 'common/hooks/useQueryArray';
import { useDebounce } from 'common/hooks/useDebounce';
import {
  useGetSupplierBranchesQuery,
  useLazyGetSupplierBranchQuery,
  useReorderSupplierDecksMutation,
} from 'common/services/supplierApi';
import NoResults from 'app/components/NoData/NoResults';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import BranchTabSkeleton from './components/Skeleton';
import { BranchRow } from './components/BranchRow';

export const BranchTab = () => {
  const { t } = useTranslation();
  const { id } = useParams<Record<string, string>>();
  const theme = useTheme();

  const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<Partial<Branch> | null>(
    null,
  );
  const [search, setSearch] = useState('');
  // const [branches, setBranches] = useState<Branch[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [reorderBranches] = useReorderSupplierDecksMutation();

  const debouncedSearchTerm = useDebounce(search, 300);

  const queryParams = useQueryArray({
    page: 1,
    limit: 300,
    sort: '+index',
    lean: true,
  });

  const { data: branchDocs, isLoading } = useGetSupplierBranchesQuery({
    supplierId: id,
    urlQuery: queryParams,
  });

  const [fetchBranch, { isFetching }] = useLazyGetSupplierBranchQuery();

  const debouncedIsFetching = useDebounce(isFetching, 300);

  const mapBranch = (b, index) => ({
    ...b,
    id: b._id,
    index: b.index || index,
    location: b.location
      ? {
          ...b.location,
          formattedAddress: b.location?.formattedAddress || b.location?.name,
        }
      : undefined,
    hide: false,
  });

  const getBranchDetails = useCallback(
    async (branchId: string) => {
      setSelectedBranch(null);
      setSelectedBranchId(branchId);
      const resp = await fetchBranch({
        supplierId: id,
        branchId: branchId,
      }).unwrap();
      const branchData = resp.supplierDeck;
      setSelectedBranch(branchData);

      setBranches((prev) =>
        prev?.map((b) => {
          if (b.id === branchId) {
            return mapBranch(branchData, b.index);
          }
          return b;
        }),
      );
    },
    [fetchBranch, id],
  );

  useEffect(() => {
    if (branches?.length === 0) {
      setSelectedBranch(null);
      setSelectedBranchId(null);
    }
  }, [branches]);

  useEffect(() => {
    if (!branchDocs?.totalDocs) {
      setBranches([]);
      return;
    }

    const updatedBranches =
      branchDocs?.docs?.map(mapBranch).sort((a, b) => a.index - b.index) || [];

    setBranches(updatedBranches);
  }, [branchDocs?.docs, branchDocs?.totalDocs]);

  useEffect(() => {
    if (!branches?.length) return;

    if (!debouncedSearchTerm) {
      setBranches((prev) =>
        prev?.map((p) => {
          return {
            ...p,
            hide: false,
          };
        }),
      );
    }

    const searchTerm = debouncedSearchTerm?.toLowerCase();

    const filteredBranches = [...branches];
    setBranches(
      filteredBranches?.map((b) => {
        const hide =
          !b.code?.toLowerCase()?.includes(searchTerm) &&
          !b.shortName?.toLowerCase()?.includes(searchTerm);

        if (selectedBranch?.id === b.id && hide) {
          setSelectedBranch(null);
          setSelectedBranchId(null);
        }
        return {
          ...b,
          hide,
        };
      }),
    );
  }, [debouncedSearchTerm]);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (result) => {
    setIsDragging(false);
    if (!result.destination) return;

    const reorderedBranches = [...branches];
    const [reorderedItem] = reorderedBranches.splice(result.source.index, 1);
    reorderedBranches.splice(result.destination.index, 0, reorderedItem);

    const updatedBranches = reorderedBranches.map((branch, index) => ({
      ...branch,
      index,
    }));

    setBranches(updatedBranches);

    reorderBranches({
      supplierId: id,
      body: {
        decks: updatedBranches?.map((b, index) => {
          return {
            id: b.id,
            index,
          };
        }),
      },
    });
  };

  const scrollableRef = useRef<HTMLDivElement>(null);

  const addBranch = () => {
    const newBranch = {
      id: `${branches?.length}`,
      code: '',
      shortName: `Untitled ${branches?.length}`,
      index: branches?.length,
      new: true,
    };
    setBranches([...branches, newBranch]);
    setSelectedBranch(newBranch);
    setSelectedBranchId(newBranch.id);

    scrollableRef.current?.scrollTo({
      top: scrollableRef?.current?.scrollHeight,
      behavior: 'smooth',
    });
  };

  const showNoResults = useMemo(() => {
    const allHidden = branches.every((b) => b.hide === true);
    const noBranches = branches.length === 0;

    return !!debouncedSearchTerm && allHidden && !noBranches;
  }, [branches, debouncedSearchTerm]);

  return (
    <MainSection sx={{ gap: '10px' }}>
      <LeftSection>
        <Stack
          paddingRight="10px"
          sx={{
            overflowY: 'auto',
            borderRadius: 'initial !important',
            overflowX: 'hidden',
          }}
          ref={scrollableRef}
        >
          <Stack
            gap="8px"
            sx={{
              position: 'sticky',
              top: 0,
              background: theme.pageGreyBg,
              zIndex: 2,
              borderRadius: 'initial !important',
              paddingBottom: '8px',
            }}
          >
            <Typography fontSize="0.875rem" fontWeight={500}>
              {t('common.branches')}
            </Typography>

            <SearchField
              value={search || ''}
              setValue={(value) => setSearch(value)}
              height={36}
              moduleName="common.branches"
              textFieldProps={{
                margin: 'none',
              }}
              disabled={!branches?.length && !showNoResults}
            />
          </Stack>

          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Droppable droppableId="droppable">
              {(provided) => (
                <Stack
                  gap="8px"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  borderRadius="initial !important"
                >
                  <Each
                    of={branches}
                    render={(branch) => (
                      <If condition={!branch.hide}>
                        <BranchRow
                          branch={branch}
                          setSelectedBranchId={setSelectedBranchId}
                          setSelectedBranch={setSelectedBranch}
                          getBranchDetails={getBranchDetails}
                          supplierId={id}
                          setBranches={setBranches}
                          isSelected={selectedBranchId === branch.id}
                        />
                      </If>
                    )}
                  />
                </Stack>
              )}
            </Droppable>
          </DragDropContext>

          <If condition={showNoResults}>
            <Stack width="100%" alignItems="center" paddingTop="20px">
              <NoResults
                subTitle=""
                imgStyle={{ width: '80px' }}
                titleStyle={{ fontSize: '0.75rem' }}
              />
            </Stack>
          </If>

          <If condition={!debouncedSearchTerm && !isLoading}>
            <Box
              sx={{
                marginRight: 'auto',
                transition: 'opacity 0.3s',
                opacity: isDragging ? 0 : 1,
                position: 'sticky',
                bottom: 0,
                width: '100%',
                zIndex: 2,
                background: theme.pageGreyBg,
                paddingTop: '8px',
              }}
            >
              <Button onClick={() => addBranch()}>
                <Stack direction="row" gap="8px" alignItems="center">
                  <Icon
                    icon={<Plus />}
                    color={theme.primaryActiveColor}
                    weight="bold"
                  />
                  <Typography color={theme.primaryActiveColor}>
                    {t('common.add')}
                  </Typography>
                </Stack>
              </Button>
            </Box>
          </If>
        </Stack>
      </LeftSection>

      <RightSection>
        <If
          condition={!selectedBranchId && !debouncedIsFetching && !isFetching}
          otherwise={
            <If
              condition={
                !!isFetching || !!debouncedIsFetching || !selectedBranch?.id
              }
              otherwise={
                <BranchDetails
                  branch={selectedBranch}
                  onChange={(values, newId = undefined) => {
                    setBranches((prev) =>
                      prev?.map((b) => {
                        let branch = b;
                        if (b.id === values.id) {
                          if (newId) {
                            branch = {
                              ...b,
                              ...values,
                              id: newId,
                              new: false,
                            };
                          } else {
                            branch = { ...b, ...values };
                          }

                          if (selectedBranch?.id === values.id) {
                            setSelectedBranch(branch);
                            setSelectedBranchId(branch.id);
                          }
                        }
                        return branch;
                      }),
                    );
                  }}
                />
              }
            >
              <BranchTabSkeleton />
            </If>
          }
        >
          <TabCard>
            <Stack gap="16px" alignItems="center">
              <div>
                <img src={EmptyState} alt="Empty State" />
              </div>
              <Typography fontWeight="700" fontSize="0.9375rem">
                {t('manage_client_branches')}
              </Typography>
              <List
                sx={{
                  fontSize: '0.9375rem',
                  listStyleType: 'disc',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                    padding: 0,
                  },
                }}
              >
                <ListItem>
                  {t('Add branch locations with address and contact details.')}
                </ListItem>
                <ListItem>
                  {t('Keep track of legal and registration information.')}
                </ListItem>
                <ListItem>
                  {t('Easily update branch details as they change.')}
                </ListItem>
                <ListItem>
                  {t('View all branches in one organized list.')}
                </ListItem>
              </List>
            </Stack>
          </TabCard>
        </If>
      </RightSection>
    </MainSection>
  );
};
