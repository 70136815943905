export type Question = {
  id: string;
  question: string;
  answer: AnswerData;
};

export type Section = {
  id: string;
  title: string;
  icon: React.ComponentType;
  questions: Question[];
};

export type AnswerData = {
  text?: string;
  tableData?: any[];
  tables?: TableData[];
};

export type TableData = {
  title?: string;
  data: any[];
};

export const SECTION_TYPES = {
  ROUNDTRIPS: 'roundtrips',
  CLIENTS: 'clients',
  EMPLOYEES: 'employees',
  VEHICLES: 'vehicles',
  KPIS: 'kpis',
};
