import { Roundtrip } from 'common/types/Aia';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const calculateWarehouseStats = (trips: Roundtrip[]) => {
  const stats: Record<string, Record<string, number>> = {};
  trips.forEach((trip) => {
    const warehouse = trip.deck || 'Unknown Warehouse';
    if (!stats[warehouse]) {
      stats[warehouse] = {};
    }
    if (!stats[warehouse][trip.agentCategory]) {
      stats[warehouse][trip.agentCategory] = 0;
    }
    stats[warehouse][trip.agentCategory] += 1;
  });

  return stats;
};

export const useRoundtripProcessor = () => {
  const { t } = useTranslation();

  const processRoundtripsData = (trips: Roundtrip[] | { message?: string }) => {
    const defaultMessage = `${t('you_have')} 0 ${t('roundtrips_for')} 0 ${t(
      'warehouses_today',
    )}`;
    if (!Array.isArray(trips)) {
      const message = trips.message || defaultMessage;
      return { text: message, tables: [] };
    }

    const warehouseStats = calculateWarehouseStats(trips);

    const totalRoundtrips = trips.length || 0;
    const totalWarehouses = Object.keys(warehouseStats).length || 0;
    let dynamicAnswer;
    if (totalRoundtrips === 0 && totalWarehouses === 0) {
      dynamicAnswer = defaultMessage;
    } else {
      dynamicAnswer = `${t('you_have')} ${totalRoundtrips} ${t(
        'roundtrips_for',
      )} ${totalWarehouses} ${t('warehouses_today')} :`;
      Object.entries(warehouseStats).forEach(([warehouse, stats]) => {
        dynamicAnswer += `\n ${warehouse} `;
        Object.entries(stats).forEach(([category, count]) => {
          dynamicAnswer += ` ${category} - ${count} ${t('roundtrips')} |`;
        });
        dynamicAnswer = dynamicAnswer.slice(0, -2);
      });
    }

    const tables = Object.entries(warehouseStats).flatMap(
      ([warehouse, stats]) =>
        Object.keys(stats).map((category) => ({
          title: `${warehouse} - ${category}`,
          data: trips
            .filter(
              (trip) =>
                trip.deck === warehouse && trip.agentCategory === category,
            )
            .map((trip) => {
              const stopStats = trip.stopStats;
              return {
                Roundtrip: `${trip.code} ~ ${trip.internalCode}`,
                Vehicle: trip.vehicle,
                Driver: trip.agent,
                Stops: (
                  <>
                    {Object.keys(stopStats).map((key) => (
                      <Box
                        key={key}
                        component="div"
                        sx={{ display: 'inline', marginRight: '8px' }}
                      >
                        {key}: {stopStats[key].completed}/{stopStats[key].total}
                      </Box>
                    ))}
                  </>
                ),
                Status: trip.status,
              };
            }),
        })),
    );

    return { text: dynamicAnswer, tables };
  };
  return { processRoundtripsData };
};
