import { Autocomplete, Chip, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'common/hooks/useDebounce';
import { CountChip } from '../../../CountChip';
import CloseIcon from '@mui/icons-material/Close';
import {
  DisabledChip,
  SelectOptionWrapper,
} from 'app/components/FilterComponents/styles';

type filterItem = {
  value: string;
  label: string;
  count?: number;
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
  icon?: any;
};

interface CheckboxGrid {
  filterItems: filterItem[];
  selectedOptions: { value: string; label: string }[];
  placeholder?: string;
  handleFilterChange: (options: { value: string; label: string }[]) => void;
  onSearch?: (search: string) => void;
  checkboxesOptions?: boolean;
  autoCompleteProps?: any;
  textFieldProps?: TextFieldProps;
}

const MultiSelectAutocomplete = ({
  filterItems,
  selectedOptions,
  placeholder,
  handleFilterChange,
  onSearch,
  checkboxesOptions,
  autoCompleteProps,
  textFieldProps,
}: CheckboxGrid) => {
  const [search, setSearch] = useState('');

  const handleOptionSelected = useCallback(
    (
      _event: any,
      newSelectedOptions: {
        value: string;
        label: string;
        color?: string;
        backgroundColor?: string;
      }[],
    ) => {
      setSearch('');

      const filteredOptions = newSelectedOptions.map((option) => ({
        label: option.label,
        value: option.value,
        color: option.color,
        backgroundColor: option.backgroundColor,
      }));

      handleFilterChange(filteredOptions);
    },
    [handleFilterChange],
  );

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const handleBlur = () => {
    setSearch('');
  };

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    if (onSearch) onSearch(debouncedSearch);
  }, [debouncedSearch, onSearch]);

  const renderTags = useCallback(
    (selectedOptions, getTagProps) => {
      return selectedOptions.map((option, index) => {
        const tagProps = getTagProps({ index });
        return (
          <Chip
            size="small"
            key={option.value}
            label={option.label}
            onDelete={tagProps.onDelete}
            deleteIcon={
              <CloseIcon
                style={{
                  fontSize: '14px',
                  padding: '1.5px',
                  backgroundColor: option?.color || '',
                  color: option?.backgroundColor || '',
                  borderRadius: '50%',
                }}
              />
            }
            icon={filterItems.find((item) => item.value === option.value)?.icon}
            sx={{
              backgroundColor: option?.backgroundColor || '',
              color: option?.color || 'inherit',
            }}
          />
        );
      });
    },
    [filterItems],
  );

  const filterOptions = useCallback(
    (options) => {
      if (!checkboxesOptions) {
        return options?.filter(
          (option) =>
            !selectedOptions?.some(
              (selectedOption) => selectedOption.value === option.value,
            ),
        );
      } else {
        return options;
      }
    },
    [checkboxesOptions, selectedOptions],
  );

  const renderOption = useCallback(({ key, ...rest }, option) => {
    return (
      <div key={key} {...rest}>
        <SelectOptionWrapper>
          <div
            style={{
              backgroundColor: option?.backgroundColor || '',
              color: option?.color || 'inherit',
              padding: '3px 10px',
              borderRadius: '50px',
              fontSize: 12,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {option.icon && <span>{option.icon}</span>}
            {option.label}
          </div>
          {option.disabled ? (
            <DisabledChip label="disabled" size="small" />
          ) : (
            option?.count > -1 && <CountChip count={option?.count} />
          )}
        </SelectOptionWrapper>
      </div>
    );
  }, []);

  const autocompleteOptions = useMemo(() => {
    if (onSearch) {
      return filterItems;
    }
    return filterItems?.filter((item) =>
      item?.label?.toLowerCase()?.includes(search?.toLowerCase()),
    );
  }, [search, filterItems, onSearch]);

  return (
    <Autocomplete
      multiple
      value={selectedOptions}
      options={autocompleteOptions}
      getOptionLabel={(option: filterItem) => option.label}
      onChange={handleOptionSelected}
      isOptionEqualToValue={(option: filterItem, value: filterItem) =>
        option.value === value.value
      }
      renderTags={renderTags}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          label={placeholder}
          onChange={handleSearch}
          onBlur={handleBlur}
          sx={{
            fontSize: '0.766rem',
            '& .MuiAutocomplete-endAdornment': {
              display: 'flex',
              justifyContent: 'center',
            },
            '& button': {
              width: 'fit-content',
            },
          }}
          {...textFieldProps}
        />
      )}
      renderOption={renderOption}
      {...autoCompleteProps}
    />
  );
};

export default MultiSelectAutocomplete;
