import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';

// Import necessary types and services
import { CustomerFormcontext } from 'app/pages/Customers/components/Form/context';
import {
  useAddCustomerBranchMutation,
  usePatchCustomerDeckMutation,
} from 'common/services/customerApi';
import { Branch, CustomerStatus } from 'common/types/Customer';
import { useDebounce } from 'common/hooks/useDebounce';
import { hasUpdatedValues } from 'app/helpers/helpers';
import { useAsyncData } from 'hooks/useAsyncData';
import { Address } from 'types';
import { TabCard } from 'app/styledComponents/DetailsSection.styled';
import { OverView } from 'app/pages/Customers/components/BranchForm/components/Overview';
import { SectionTitle } from 'app/components/Form/styles';
import LocationPicker from 'app/components/LocationPicker';
import { BusinessInfo } from 'app/pages/Customers/components/BranchForm/components/BusinessInfo';
import { DeliverySpecifications } from 'app/pages/Customers/components/BranchForm/components/DeliverySpecifications';
import { PhoneBook } from 'app/pages/Customers/components/Form/components/PhoneBook';
import If from 'app/components/If';
import Doors from 'app/pages/Customers/components/BranchForm/components/Doors';
import MultiSelectAutocomplete from 'app/components/FilterComponents/components/MultiSelect/components/Autocomplete';
import { useSelector } from 'react-redux';
import { selectTags } from 'common/store/organization/selectors';

export const BranchDetails = ({
  branch,
  onChange,
}: {
  branch?: Partial<Branch> | null;
  onChange: (branch: Branch, newId?: string) => void;
}) => {
  const { t } = useTranslation();
  const { customer, canEdit } = useContext(CustomerFormcontext);
  const [patch] = usePatchCustomerDeckMutation();
  const [add, { isLoading: isAdding }] = useAddCustomerBranchMutation();

  // Address state
  const [address, setAddress] = useState<Address | null>(
    branch?.location || null,
  );

  const defaultValues = useMemo(() => {
    return {
      shortName: branch?.shortName || '',
      code: branch?.code || '',
      status: branch?.status || CustomerStatus.ACTIVE,
      vatNumber: branch?.vatNumber || '',
      apeCode: branch?.apeCode || '',
      deliveryTypes: branch?.deliveryTypes || [],
      thirdPartyCollector: branch?.thirdPartyCollector || false,
      carrier: branch?.carrier || '',
      deliveryDays: branch?.deliveryDays || [],
      siretNumber: branch?.siretNumber || '',
      requestedSupport: branch?.requestedSupport || [],
      vehicleCondition: branch?.vehicleCondition || [],
      roundtripDays: branch?.roundtripDays,
      deliveryDaysHours: branch?.deliveryDaysHours,
      availableTimeSlotFrom: branch?.availableTimeSlotFrom || '',
      availableTimeSlotTo: branch?.availableTimeSlotTo || '',
      preferredTimeSlotFrom: branch?.preferredTimeSlotFrom || '',
      preferredTimeSlotTo: branch?.preferredTimeSlotTo || '',
      minimumWeight: branch?.minimumWeight || 0,
      minOrderPrice: branch?.minOrderPrice || 0,
      minOrderBoxes: branch?.minOrderBoxes || 0,
      contacts: branch?.contacts || [],
      tags: branch?.tags || [],
    };
  }, [branch]);

  // Form methods with Zod resolver
  const methods = useForm({
    // resolver: zodResolver(branchSchema),
    mode: 'onChange', // Validate on every change
    reValidateMode: 'onBlur',
    defaultValues,
    shouldFocusError: true,
  });

  const {
    control,
    reset,
    trigger,
    setValue,
    formState: { errors, isValid },
  } = methods;

  // Watch all form values
  const watchedFields = useWatch({ control });

  // Create payload
  const payload = useMemo(() => {
    const formatTime = (time) => time;
    const availableTimeSlotFrom = formatTime(
      watchedFields.availableTimeSlotFrom,
    );
    const availableTimeSlotTo = formatTime(watchedFields.availableTimeSlotTo);
    const preferredTimeSlotFrom = formatTime(
      watchedFields.preferredTimeSlotFrom,
    );
    const preferredTimeSlotTo = formatTime(watchedFields.preferredTimeSlotTo);

    return {
      id: branch?.id,
      siretNumber: watchedFields.siretNumber,
      shortName: watchedFields.shortName,
      status: watchedFields.status,
      location: address?.name ? address : undefined,
      vatNumber: watchedFields.vatNumber,
      apeCode: watchedFields.apeCode,
      code: watchedFields.code,
      deliveryTypes: watchedFields.deliveryTypes,
      thirdPartyCollector: watchedFields.thirdPartyCollector,
      deliveryDays: watchedFields.deliveryDays,
      //@ts-ignore
      carrier: watchedFields.carrier?.value,
      requestedSupport: watchedFields.requestedSupport,
      vehicleCondition: watchedFields.vehicleCondition,
      roundtripDays: watchedFields.roundtripDays,
      deliveryDaysHours: watchedFields.deliveryDaysHours,
      minOrderPrice: watchedFields.minOrderPrice,
      minOrderBoxes: watchedFields.minOrderBoxes,
      minimumWeight: watchedFields.minimumWeight,
      availableTimeSlotFrom: availableTimeSlotFrom,
      availableTimeSlotTo: availableTimeSlotTo,
      preferredTimeSlotFrom: preferredTimeSlotFrom,
      preferredTimeSlotTo: preferredTimeSlotTo,
      contacts: watchedFields.contacts,
      tags: watchedFields.tags,
    };
  }, [
    watchedFields.availableTimeSlotFrom,
    watchedFields.availableTimeSlotTo,
    watchedFields.preferredTimeSlotFrom,
    watchedFields.preferredTimeSlotTo,
    watchedFields.siretNumber,
    watchedFields.shortName,
    watchedFields.status,
    watchedFields.vatNumber,
    watchedFields.apeCode,
    watchedFields.code,
    watchedFields.deliveryTypes,
    watchedFields.thirdPartyCollector,
    watchedFields.deliveryDays,
    //@ts-ignore
    watchedFields.carrier?.value,
    watchedFields.requestedSupport,
    watchedFields.vehicleCondition,
    watchedFields.roundtripDays,
    watchedFields.deliveryDaysHours,
    watchedFields.minOrderPrice,
    watchedFields.minOrderBoxes,
    watchedFields.minimumWeight,
    branch?.id,
    address,
    watchedFields.contacts,
    watchedFields.tags,
  ]);

  // Debounce payload to prevent too frequent updates
  const debouncedPayload = useDebounce(payload, 300);

  // Mutation hook for updating branch
  const { callApi: updateBranch } = useAsyncData(async () => {
    return await patch({
      customerId: customer?._id,
      deckId: branch?.id,
      body: omit(debouncedPayload, 'id'),
    }).unwrap();
  });

  const { callApi: addBranch } = useAsyncData(
    async () => {
      return await add({
        body: { ...omit(debouncedPayload, 'id'), customerId: customer?._id },
      }).unwrap();
    },
    {
      onSuccess: (data: any) => {
        onChange(
          {
            id: branch?.id,
            ...debouncedPayload,
            deliveryDays: watchedFields.deliveryDays,
          },
          data?.id,
        );
      },
    },
  );

  // Effect to handle form submission on change
  useEffect(() => {
    if (branch?.id !== debouncedPayload?.id) {
      return;
    }

    // Determine if there are actual changes
    const updatedValues = omit(payload, ['location']);
    const branchValues = omit(branch, ['location']);

    const hasChanges =
      hasUpdatedValues(
        { ...updatedValues },
        {
          ...branchValues,
          deliveryDays: branchValues.deliveryDays?.map((day: any) => day.value),
        },
      ) ||
      hasUpdatedValues(
        omit(payload?.location, ['geometry']),
        omit(branch?.location, ['geometry']),
      );

    if (branch?.new) {
      onChange({
        id: branch?.id,
        ...debouncedPayload,
      });
    }

    if (isValid) {
      if (branch?.new) {
        !isAdding && addBranch();
      } else if (hasChanges) {
        onChange({
          id: branch?.id,
          ...debouncedPayload,
          deliveryDays: watchedFields.deliveryDays,
        });
        updateBranch();
      }
    }
  }, [debouncedPayload, isValid]);

  // Reset effect when branch changes
  useEffect(() => {
    reset({
      shortName: branch?.shortName || '',
      code: branch?.code || '',
      status: branch?.status || CustomerStatus.ACTIVE,
      vatNumber: branch?.vatNumber || '',
      apeCode: branch?.apeCode || '',
      deliveryTypes: branch?.deliveryTypes || [],
      thirdPartyCollector: branch?.thirdPartyCollector || false,
      carrier: branch?.carrier || '',
      deliveryDays: branch?.deliveryDays || [],
      siretNumber: branch?.siretNumber || '',
      requestedSupport: branch?.requestedSupport || [],
      vehicleCondition: branch?.vehicleCondition || [],
      roundtripDays: branch?.roundtripDays,
      deliveryDaysHours: branch?.deliveryDaysHours,
      availableTimeSlotFrom: branch?.availableTimeSlotFrom || '',
      availableTimeSlotTo: branch?.availableTimeSlotTo || '',
      preferredTimeSlotFrom: branch?.preferredTimeSlotFrom || '',
      preferredTimeSlotTo: branch?.preferredTimeSlotTo || '',
      minimumWeight: branch?.minimumWeight || 0,
      minOrderPrice: branch?.minOrderPrice || 0,
      minOrderBoxes: branch?.minOrderBoxes || 0,
      contacts: branch?.contacts || [],
      tags: branch?.tags || [],
    });

    setAddress({
      name: branch?.location?.name || '',
      city: branch?.location?.city || '',
      department: branch?.location?.department || '',
      region: branch?.location?.region || '',
      continent: branch?.location?.continent || '',
      zipCode: branch?.location?.zipCode || '',
      formattedAddress: branch?.location?.formattedAddress || '',
      shortAddress: branch?.location?.shortAddress || '',
      countryCode: branch?.location?.countryCode || '',
      geometry: {
        type: 'Point',
        coordinates: branch?.location?.geometry?.coordinates || [],
      },
      markAsMain: branch?.location?.markAsMain || false,
    });

    trigger();
  }, [branch?.id, branch?.status, reset]);

  useEffect(() => {
    trigger();
  }, [trigger, errors]);

  const tags = useSelector(selectTags);

  const tagsItems = useMemo(() => {
    if (!tags) {
      return [];
    }
    return tags?.map((tag) => {
      return {
        value: tag?._id,
        label: tag?.label,
      };
    });
  }, [tags]);

  return (
    <>
      <TabCard height="auto">
        <OverView control={control} />
      </TabCard>

      <TabCard height="auto">
        <SectionTitle>{t('common.address')}</SectionTitle>
        <LocationPicker
          //@ts-ignore
          defaultAddress={address}
          onChange={(address) => {
            !!address && setAddress(address);
          }}
          mapContainerSx={{ height: '150px' }}
          toggleMain
          disabled={!canEdit}
        />
      </TabCard>

      <TabCard height="auto">
        <SectionTitle>{t('info-client-Tags')}</SectionTitle>

        <MultiSelectAutocomplete
          placeholder={t('info-client-Tags')}
          selectedOptions={tagsItems?.filter((t) =>
            //@ts-ignore
            watchedFields?.tags?.includes(t.value),
          )}
          handleFilterChange={(options) => {
            setValue(
              'tags',
              options?.map((o) => o.value),
            );
          }}
          filterItems={tagsItems}
          autoCompleteProps={{
            disabled: !canEdit,
          }}
        />
      </TabCard>

      <TabCard height="auto">
        <BusinessInfo control={control} />
      </TabCard>

      <TabCard height="auto">
        <PhoneBook control={control} setValue={setValue} />
      </TabCard>

      <TabCard height="auto">
        <DeliverySpecifications control={control} setValue={setValue} />
      </TabCard>

      <If condition={!!branch?.doors?.length}>
        <TabCard height="auto">
          <Doors doors={branch?.doors || []} />
        </TabCard>
      </If>
    </>
  );
};
