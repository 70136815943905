import { Address } from 'types';

export interface SupplierDocs {
  _id?: string;
  id?: string;
  supplierID: string;
  supplierName: string;
  createdAt: string;
  Category: string;
  email: string;
  invoicePeriod: string;
  paymentPeriod: number;
  legalId: string;
  phone: string;
  updatedAt: string;
  vatNumber: string;
  mainLocation?: { geometry: { coordinates: number[] } };
  _organizationDeck?: { _id: string; nextDeliveryDate: string };
}

export interface ContactData {
  email: string;
  subject: string;
  message: string;
  token: string;
  password: string;
}

export enum SupplierStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  BLOCKED = 'blocked',
  ON_WATCH = 'on-watch',
  PRELIMINARY = 'preliminary',
  ARCHIVED = 'archived',
}

export enum SupplierActions {
  SUPPLIER_EDIT = 'supplier-edit',
  SUPPLIER_ADD = 'supplier-add',
  SUPPLIER_EDIT_DECK = 'supplier-edit-deck',
  SUPPLIER_ADD_DECK = 'supplier-add-deck',
  SUPPLIER_CANCEL_SEPA = 'supplier-cancel-sepa',
  SUPPLIER_VALIDATE_SEPA = 'supplier-validate-sepa',
  SUPPLIER_DELETE_DECK = 'supplier-delete-deck',
  SUPPLIER_STATUS_UPDATED = 'supplier-status-updated',
}

export interface Contact {
  name: string;
  phone: string;
  role: string;
  jobType: string;
  main: boolean;
  email?: string;
  _id: string;
}

export enum SupplierType {
  PROSPECT = 'prospect',
  Client = 'client',
}

export type SupplierFields = {
  type: SupplierType;
  code: string;
  publicName: string;
  legalName: string;
  vatNumber: string;
  mobilePhone: string;
  companyEmail: string;
  professionType: string;
  _supplierType: string;
  status: SupplierStatus;
  mainLocation: Address;
  switchState: string;
  legalIdAlt: string;
  emailInput: string;
  activityCode: string;
  isFavorite: boolean;
  tags: string[];
  contacts: Contact[];
  notes?: string;
  mainEmailAddress?: string;
};

export type SupplierSettingsFields = {
  showPricesDeliveryNote: boolean;
  showInternalCodeInOrders: boolean;
  email: boolean;
  postalEmail: boolean;
  chorusPro: boolean;
  serviceCode: string;
};

export interface Door {
  name: string;
  code: string;
}

export interface Branch {
  id: string;
  shortName: string;
  code: string;
  location: Address;
  status: SupplierStatus;
  siretNumber: string;
  vatNumber: string;
  apeCode: string;
  contacts: Contact[];
  deliveryTypes: any[];
  thirdPartyCollector: boolean;
  carrier: string;
  deliveryDays: {
    day: string;
    value: string;
  }[];
  requestedSupport: string[];
  vehicleCondition: string[];
  roundtripDays: any;
  roundtripCodes: string[];
  deliveryDaysHours: any;
  callEnable: boolean;
  supplierId: string;
  index?: number;
  new?: boolean;
  availableTimeSlotFrom: string;
  availableTimeSlotTo: string;
  preferredTimeSlotFrom: string;
  preferredTimeSlotTo: string;
  minOrderWeight: number;
  minOrderPrice: number;
  minOrderBoxes: number;
  doors: Door[];
  tags: string[];
}

export enum professionType {
  MANUFACTURING = 'manufacturing',
  TECHNOLOGY = 'technology',
  AGRICULTURE = 'agriculture',
  CHEMICAL = 'chemical',
  PHARMACEUTICAL = 'pharmaceutical',
  CONSTRUCTION = 'construction',
  AUTOMOTIVE = 'automotive',
  RETAIL = 'retail',
  FOOD_BEVERAGE = 'food&beverage',
}
