import { DrawerComponent } from 'app/components/DrawerComponent';
import { ClaimForm } from './components/ClaimForm';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ClaimsContext } from '../../ClaimsProvider';

interface ClaimDrawerProps {
  open: boolean;
  handleClose: () => void;
  customer?: any;
  order?: any;
}

export function ClaimDrawer({
  open,
  handleClose,
  customer,
  order,
}: ClaimDrawerProps) {
  const { t } = useTranslation();

  const { isUserCustomer, selectedRow } = useContext(ClaimsContext);
  const isEdit = !!selectedRow;

  return (
    <DrawerComponent
      title={isEdit ? t('claims.edit.title') : t('claims.emptyState.button')}
      open={open}
      handleClose={handleClose}
    >
      <ClaimForm
        handleClose={handleClose}
        customer={customer}
        order={order}
        isUserCustomer={isUserCustomer}
        isEdit={isEdit}
        row={selectedRow}
      />
    </DrawerComponent>
  );
}
