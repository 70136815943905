import React from 'react';
import GridContext from 'app/components/ManagedGrid/GridContext';
import {
  Permission,
  usePermission,
} from '../../../hooks/Abilities/usePermission';
import {
  CUSTOM_DOCUMENTS_COLUMN_CONFIG,
  DOCUMENTS_STATS_COLORS,
} from './config';
import FunctionalDocuments from './components/FunctionalDocuments';
import { DOCUMENT_STATUSES } from './documentStatuses';
export interface IDocumentsContext {
  openDrawer: boolean;
  canEdit: boolean;
  editMode?: boolean;
}

function DocumentTab() {
  const can = usePermission('documents');

  const calculateStatusMetrics = (status: string, facet) => {
    const statusTotal = facet?.[0]?.status?.[status] || 0;
    return {
      total: statusTotal,
      percentage: +(
        (statusTotal / (facet?.[0]?.total?.totalCount || 1)) *
        100
      )?.toFixed(2),
    };
  };
  const calculateGrandTotal = (facet) => facet?.[0]?.total?.totalCount || 0;

  return (
    <GridContext
      data={[]}
      customColumnConfig={CUSTOM_DOCUMENTS_COLUMN_CONFIG}
      title=""
      canEdit={can(Permission.VIEW)}
      canExport={can(Permission.EXPORT)}
      suppressCheckbox
      statsConfig={{
        colors: DOCUMENTS_STATS_COLORS,
        statuses: DOCUMENT_STATUSES,
        title: 'documents.status',
        calculateStatusMetrics,
        calculateGrandTotal,
      }}
    >
      <FunctionalDocuments />
    </GridContext>
  );
}
export default DocumentTab;
