import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';

interface props {
  options: {
    value: string;
    label: string;
    code: string;
    category?: string;
  }[];
  getOptionLabel: (option) => string;
}

export const AutoCompleteSearch = ({
  control,
  propsVal,
  onChange,
  disabled,
  setSearch,
  isFetching,
  name,
  fieldName,
  renderOption,
  multiple,
  value,
}: {
  control: Control<any>;
  propsVal: props;
  onChange: (event: React.SyntheticEvent | Event, value: unknown) => void;
  setSearch: (val: string) => void;
  isFetching: boolean;
  disabled: boolean;
  name: string;
  fieldName: string;
  renderOption: any;
  multiple: boolean;
  value?: null | {
    value: string;
    label: string;
    code: string;
    category?: string;
  };
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <Autocomplete
          multiple={multiple}
          {...field}
          {...propsVal}
          groupBy={(option) => option?.category || ''}
          onChange={onChange}
          value={value}
          sx={{ width: '100%' }}
          onBlur={() => setSearch('')}
          loading={isFetching}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label={t(name)}
              variant="outlined"
              onChange={(e) => {
                setSearch(e?.target?.value);
              }}
              disabled={disabled}
              required
            />
          )}
          disabled={disabled}
          renderOption={renderOption}
        />
      )}
    />
  );
};
