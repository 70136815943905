import * as React from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

export function FooterLogin() {
  const { t } = useTranslation();
  return (
    <FooterWraper>
      <p>
        ©{dayjs().format('YYYY')} Bamptee
        <a
          href="https://www.bamptee.com/en/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('auth.footer_login.your_privacy')}
        </a>
        <a
          href="https://www.bamptee.com/en/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('auth.footer_login.terms')}
        </a>
      </p>
    </FooterWraper>
  );
}

const FooterWraper = styled.footer`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 24px;
  color: ${themes?.default?.textColorPrimary};
  margin-top: 200px;
  text-align: center;
  a {
    margin-left: 23px;
    color: ${themes?.default?.textColorPrimary};
  }
  p {
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media only screen and (max-width: 600px) {
    p {
      position: relative;
      bottom: 156px;
    }
  }
`;
