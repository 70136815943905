import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

import { useState, useEffect } from 'react';

import { OrderDetails } from 'app/components/OrderDetails';
import { RoundtripStopType } from 'common/utils/enum';

import { useGetUserQuery } from 'common/services/userApi';
import { useGetRoleQuery } from 'common/services/roleApi';
import VisitStopDrawer from './StopsDrawers/VisitStopDrawer';
import FuelStopDetails from './StopsDrawers/FuelStopDetails';

interface SwipeableDrowerOrderProps {
  selectedOrder: any;
  handleCloseOrderDrawer: () => void;
  onUpdate?: () => void;
  orderChangeStatus?: (status: string) => void;
  triggerRoundtripDetails?: Function;
  viewMode?: boolean;
  handleDisplayMap?: (orderId: string) => void;
  defaultTab?: number;
  selectedStopDetails?: any;
  salesAgent?: any;
  setType?: any;
  setClusterId?: any;
}

export function SwipeableDrowerOrder({
  selectedOrder,
  handleCloseOrderDrawer,
  selectedStopDetails,
  salesAgent,
  onUpdate,
  triggerRoundtripDetails,
  handleDisplayMap = () => {},
  defaultTab = 0,
  setType,
  setClusterId,
}: SwipeableDrowerOrderProps) {
  // hooks
  const [currentStopIndex, setCurrentStopIndex] = useState(0);
  const [matchingStop, setMatchingStop] = useState<any>(null);
  const currentStopType = matchingStop?.type || selectedOrder?.type;

  const { data: user } = useGetUserQuery(salesAgent?.[1]?._user?._id, {
    skip: !salesAgent?.[1]?._user?._id,
  });
  console.log('usdfdffder', user);
  const { data: roleData } = useGetRoleQuery(
    `${salesAgent?.[1]?._role?._id}?idm-checker=true`,
    {
      skip: !salesAgent?.[1]?._role?._id,
    },
  );

  useEffect(() => {
    const index = selectedStopDetails?.stops?.findIndex(
      (stop) => stop._id === selectedOrder?._id,
    );
    if (index !== -1) {
      setCurrentStopIndex(index);
      setMatchingStop(selectedStopDetails?.stops?.[index]);
    }
  }, [selectedOrder?._id, selectedStopDetails?.stops]);

  function handleNextStop() {
    const nextIndex = currentStopIndex + 1;
    setCurrentStopIndex(nextIndex);
    setMatchingStop(selectedStopDetails?.stops?.[nextIndex]);
  }

  function handlePreviousStop() {
    const prevIndex = currentStopIndex - 1;
    setCurrentStopIndex(prevIndex);
    setMatchingStop(selectedStopDetails?.stops?.[prevIndex]);
  }

  const handleClose = () => {
    handleCloseOrderDrawer();
    setMatchingStop(null);
  };

  const isFirstStop = currentStopIndex === 0;
  const isLastStop =
    currentStopIndex === selectedStopDetails?.stops?.length - 1;

  if (currentStopType === RoundtripStopType.VISIT) {
    return (
      <VisitStopDrawer
        selectedOrder={selectedOrder}
        handleCloseOrderDrawer={handleClose}
        matchingStop={matchingStop}
        salesAgent={salesAgent}
        roleData={roleData}
        user={user}
        selectedStopDetails={selectedStopDetails}
        isFirstStop={isFirstStop}
        isLastStop={isLastStop}
        handleNextStop={handleNextStop}
        handlePreviousStop={handlePreviousStop}
        setType={setType}
        setClusterId={setClusterId}
        handleDisplayMap={handleDisplayMap}
      />
    );
  }

  if (currentStopType === RoundtripStopType.FUEL) {
    return (
      <FuelStopDetails
        selectedOrder={matchingStop || selectedOrder}
        handleCloseOrderDrawer={handleClose}
        matchingStop={matchingStop}
        selectedStopDetails={selectedStopDetails}
        isFirstStop={isFirstStop}
        isLastStop={isLastStop}
        handleNextStop={handleNextStop}
        handlePreviousStop={handlePreviousStop}
        handleDisplayMap={handleDisplayMap}
      />
      // <TwoViewModal
      //   onOpen={!isEmpty(selectedOrder)}
      //   onClose={() => handleCloseOrderDrawer()}
      //   title=" "
      //   width="auto"
      //   scrollY={false}
      // >
      //   <DrawerContent>
      //     <FuelDetails
      //       order={selectedOrder}
      //       onCloseDrawer={handleCloseOrderDrawer}
      //     />
      //   </DrawerContent>
      // </TwoViewModal>
    );
  }

  return (
    <OrderDetails
      open={!isEmpty(selectedOrder)}
      onClose={handleClose}
      orderId={
        matchingStop?._order?._id || selectedOrder?._id || selectedOrder?.id
      }
      refreshRoundtrip={(roundtripId) => {
        if (!!roundtripId) {
          triggerRoundtripDetails?.({ roundtripId });
        }
        onUpdate?.();
      }}
      defaultTabIndex={defaultTab}
      handleDisplayMap={handleDisplayMap}
    />
  );
}

// const DrawerContent = styled.div`
//   max-width: 100%;
//   margin: auto;
//   overflow-y: scroll;
// `;
export const Wrapper = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
`;

export const ContentContainer = styled.div`
  width: 100%;
  min-height: 40px;
  background: ${themes?.default?.lightGrey};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
`;
export const SmallIconsConatiner = styled.div`
  height: 18px;
  width: 18px;
  background-color: ${themes?.default?.primaryLight1};
  border-radius: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;
export const ProductsContainer = styled.div`
  width: 100%;
  height: 40px;
  background: ${themes?.default?.lightGrey};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
`;
export const ContentTitle = styled(Typography)`
  color: ${themes?.default?.clientMarkerBg};
`;
