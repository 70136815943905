import { IColDef } from 'app/components/ManagedGrid/types';
import IconTypeCell from './cells/IconTypeCell';
import PaymentCodeCell from './cells/PaymentCodeCell';
import TypeCell from './cells/TypeCell';
import DocumentIdCell from './cells/DocumentIdCell';
import BranchCell from './cells/BranchCell';
import AmountCell from './cells/AmountCell';
import PaidByCell from './cells/PaidByCell';
import SourceCell from './cells/SourceCell';
import PaidOnCell from './cells/PaidOnCell';
import NoteCell from './cells/NoteCell';
import DownloadCell from './cells/DownloadCell';
import AttachmentCell from './cells/AttachmentCell';

export enum TransactionsTab {
  BILLS = 'bills',
  REFUND = 'refund',
  PAYMENTS = 'payments',
}

export const getCustomPaymentsColumnConfig = (
  t: (key: string) => string,
): IColDef[] => [
  {
    cellRenderer: IconTypeCell,
    width: 70,
    resizable: false,
    pinned: 'left',
    lockPinned: true,
  },
  {
    cellRenderer: PaymentCodeCell,
    width: 80,
    resizable: true,
    headerName: t('transaction.payment.code'),
    pinned: 'left',
    lockPinned: false,
  },
  {
    cellRenderer: TypeCell,
    width: 60,
    resizable: true,
  },
  {
    cellRenderer: DocumentIdCell,
    width: 80,
    resizable: true,
    headerName: t('transaction.document.id'),
  },
  {
    cellRenderer: BranchCell,
    width: 140,
    resizable: true,
    headerName: t('orders.branch'),
  },
  {
    cellRenderer: AmountCell,
    width: 80,
    resizable: true,
    headerName: t('roundtrips.roundtripDetails.amount'),
  },
  {
    cellRenderer: PaidByCell,
    width: 100,
    resizable: true,
    headerName: t('transaction.paid.by'),
  },
  {
    cellRenderer: SourceCell,
    width: 100,
    resizable: true,
    headerName: t('transaction.source'),
  },
  {
    cellRenderer: PaidOnCell,
    width: 80,
    resizable: true,
    headerName: t('transaction.paid.on'),
  },
  {
    cellRenderer: AttachmentCell,
    width: 70,
    resizable: false,
    lockPinned: true,
    pinned: 'right',
  },
  {
    cellRenderer: NoteCell,
    width: 48,
    resizable: true,
    pinned: 'right',
    lockPinned: true,
  },
  {
    cellRenderer: DownloadCell,
    width: 48,
    resizable: false,
    lockPinned: true,
    pinned: 'right',
    cellStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
];
