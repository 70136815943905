import { DocumentsKeys } from './enum';
import {
  IdentificationCard,
  NotePencil,
  CarProfile,
  IdentificationBadge,
  SuitcaseSimple,
  Stethoscope,
  Certificate,
  PoliceCar,
  Bank,
  MapTrifold,
  SealCheck,
  GraduationCap,
  NoteBlank,
} from '@phosphor-icons/react';

type DocumentColor = {
  background: string;
  color: string;
  icon?: any;
};

type DocumentTypes = {
  [key in DocumentsKeys]: DocumentColor;
} & {
  archived: DocumentColor;
};

export const documentTypes: DocumentTypes = {
  [DocumentsKeys.NATIONALIDS]: {
    background: '#F0F8F9',
    color: '#439BA7',
    icon: IdentificationCard,
  },
  [DocumentsKeys.EMPLOYMENTCONTRACT]: {
    background: '#FFFDE7',
    color: '#FBC02D',
    icon: NotePencil,
  },
  [DocumentsKeys.DRIVERLICENSES]: {
    background: '#E3F2FD',
    color: '#1E88E5',
    icon: CarProfile,
  },
  [DocumentsKeys.RESUME]: {
    background: '#FFF3E0',
    color: '#FB8C00',
    icon: IdentificationBadge,
  },
  [DocumentsKeys.EMPLOYMENTHISTORY]: {
    background: '#E0F7FA',
    color: '#00ACC1',
    icon: SuitcaseSimple,
  },
  [DocumentsKeys.MEDICALEXAMINATIONREPORTS]: {
    background: '#E8EAF6',
    color: '#3949AB',
    icon: Stethoscope,
  },
  [DocumentsKeys.CERTIFICATES]: {
    background: '#E0F2F1',
    color: '#00897B',
    icon: Certificate,
  },
  [DocumentsKeys.DRIVERRECORDABSTRACT]: {
    background: '#EDE7F6',
    color: '#5E35B1',
    icon: PoliceCar,
  },
  [DocumentsKeys.SOCIALSECURITY]: {
    background: '#F6F8FF',
    color: '#2A59FF',
    icon: SealCheck,
  },
  [DocumentsKeys.PROOFOFEDUCATION]: {
    background: '#F1F8E9',
    color: '#7CB342',
    icon: GraduationCap,
  },
  [DocumentsKeys.BANKIDENTITYSTATEMENT]: {
    background: '#FCE4EC',
    color: '#D81B60',
    icon: Bank,
  },
  [DocumentsKeys.PROOFOFADDRESS]: {
    background: '#FFF2D8',
    color: '#D89100',
    icon: MapTrifold,
  },
  [DocumentsKeys.OTHER]: {
    background: '#EEEEEE',
    color: '#616161',
    icon: NoteBlank,
  },
  archived: {
    background: '#E0E0E0',
    color: '#616161',
  },
};
