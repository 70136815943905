import GridContext from 'app/components/ManagedGrid/GridContext';
import { useTranslation } from 'react-i18next';
import { getCustomPaymentsColumnConfig, TransactionsTab } from './config';
import { PaymentsGrid } from './PaymentsGrid';
import { generatePath, useNavigate } from 'react-router-dom';
import { ORDORIA_TRANSACTIONS } from 'utils/routes';

export function Payments() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = getCustomPaymentsColumnConfig(t);

  return (
    <GridContext
      data={[]}
      customColumnConfig={columns}
      title="nav.mainNav.ordoria.transactions"
      canExport={true}
      canEdit={false} ///since admin flow is isn't implemented yet
      tabs={[
        {
          label: t('common.bills'),
          value: TransactionsTab.BILLS,
        },
        {
          label: t('common.refund'),
          value: TransactionsTab.REFUND,
        },
        {
          label: t('common.payments'),
          value: TransactionsTab.PAYMENTS,
        },
      ]}
      onTabChange={(tab) => {
        const path = generatePath(ORDORIA_TRANSACTIONS + `/${tab}`);
        navigate(path);
      }}
      selectedTab={TransactionsTab.PAYMENTS}
    >
      <PaymentsGrid />
    </GridContext>
  );
}
