import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFetchUsers } from 'hooks/useFetchUsers';
import { useGetUsersWebQuery } from 'common/services/userApi';
import { selectOrganization } from 'common/store/organization/selectors';
import { getAgent, getAgentFullName } from 'common/helpers/agent';

export const useUsersAutoComplete = (useWebQuery = false) => {
  const authOrganization = useSelector(selectOrganization);
  const [_search, setSearch] = useState('');

  const fetchUsers = useFetchUsers();

  const {
    data: usersData,
    isFetching,
    refetch: triggerUsers,
  } = useGetUsersWebQuery(`?text=${_search}&limit=50&isLocked=false`);

  const users = useMemo(() => {
    return (
      usersData?.docs?.map((u) => {
        const preferredAgent = getAgent(u, authOrganization);
        return {
          value: u._id,
          label: getAgentFullName(preferredAgent),
          pictureUrl: preferredAgent?.pictureUrl,
        };
      }) || []
    );
  }, [usersData, authOrganization]);

  const searchUsers = (searchTerm) => {
    setSearch(searchTerm);
    triggerUsers();
  };

  return useWebQuery
    ? { users, isFetching, searchUsers, setSearch }
    : fetchUsers;
};
