import GridContext from 'app/components/ManagedGrid/GridContext';
import { useTranslation } from 'react-i18next';
import { getCustomRefundsColumnConfig, TransactionsTab } from './config';
import { RefundsGrid } from './RefundsGrid';
import { generatePath, useNavigate } from 'react-router-dom';
import { ORDORIA_TRANSACTIONS } from 'utils/routes';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';

export function Refunds() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isCustomer } = useGetUserAcl();

  const columns = getCustomRefundsColumnConfig(t, isCustomer());

  return (
    <GridContext
      data={[]}
      customColumnConfig={columns}
      title="nav.mainNav.ordoria.transactions"
      canExport={true}
      canEdit={!isCustomer()} ///since admin flow is isn't implemented yet
      tabs={[
        {
          label: t('common.bills'),
          value: TransactionsTab.BILLS,
        },
        {
          label: t('common.refund'),
          value: TransactionsTab.REFUND,
        },
        {
          label: t('common.payments'),
          value: TransactionsTab.PAYMENTS,
        },
      ]}
      onTabChange={(tab) => {
        const path = generatePath(ORDORIA_TRANSACTIONS + `/${tab}`);
        navigate(path);
      }}
      selectedTab={TransactionsTab.REFUND}
    >
      <RefundsGrid />
    </GridContext>
  );
}
