import { CustomCellRendererProps } from '@ag-grid-community/react';
import dayjs from 'dayjs';

function PaidOnCell(params: CustomCellRendererProps) {
  const paidOn = params?.value?.paidOnData
    ? dayjs(params.value.paidOnData)
    : null;

  return (
    <div>
      <span>{paidOn?.format('DD MMM YYYY')}</span>
    </div>
  );
}

export default PaidOnCell;
