import React, { useState, useMemo } from 'react';
import { styled as muiStyles } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Stack } from '@mui/material';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { ICustomProductCategories, IProductCategory } from './interfacs';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { NoResults } from 'app/components/NoData/NoResults';
import { useEffect } from 'react';

export interface ProductCategories {
  products: ICustomProductCategories[];
}

export const ProductsCategories = ({
  onAddAction,
  onRemoveAction,
  ProductRow,
  urlParams,
  setCategories,
  productsCategories = [],
}: {
  productsCategories?: IProductCategory[];
  onAddAction: (id: string, name?: string) => void;
  onRemoveAction: (id: string, type: string, hasConfirmation?: boolean) => void;
  ProductRow: React.FC<{
    prod: ICustomProductCategories;
    onAddAction: (id: string, name?: string) => void;
    onRemoveAction: (
      id: string,
      type: string,
      hasConfirmation?: boolean,
    ) => void;
    addedProducts: string[];
    setAddedProducts: (ids: string[]) => void;
  }>;
  urlParams?: string;
  setCategories: (values: { value: string; label: string }[]) => void;
}) => {
  const theme = useSelector(selectTheme);
  const [addedProduct, setAddedProducts] = useState<string[]>([]);

  const [productsCat, setProductCat] = useState<{
    [key: string]: ProductCategories;
  }>({});

  const products = useMemo(() => {
    if (!!productsCategories?.length) {
      return productsCategories;
    }
    return [];
  }, [productsCategories]);

  const addedProducts = useMemo(() => {
    if (!!addedProduct?.length) {
      return addedProduct;
    }
    return [];
  }, [addedProduct]);

  useEffect(() => {
    const categoriesProducts: { value: string; label: string }[] = [];
    if (!!products?.length) {
      products.forEach((product) => {
        categoriesProducts.push({
          label: product._category.name,
          value: product._category._id,
        });
      });
      setCategories(categoriesProducts);
    }
  }, [products]);

  const Accordion = muiStyles((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

  const AccordionSummary = muiStyles((props: AccordionSummaryProps) => (
    <MuiAccordionSummary {...props} />
  ))(({ theme }) => ({
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
      {
        transform: 'rotate(90deg)',
      },
    [`& .${accordionSummaryClasses.content}`]: {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = muiStyles(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
  }));
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      const currentProduct = products?.find((res) => res?._id === panel);
      if (currentProduct) {
        setProductCat((prevProducts) => ({
          ...prevProducts,
          [panel]: currentProduct,
        }));
      }
    };

  return (
    <>
      {products?.length === 0 && !!urlParams && (
        <EmptyCenter>
          <NoResults />
        </EmptyCenter>
      )}
      <Wrapper>
        {!!products?.length &&
          products.map((res) => (
            <Accordion
              expanded={expanded === res._category._id}
              onChange={handleChange(res._category._id)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={
                  <ArrowForwardIosSharpIcon
                    sx={{
                      color:
                        expanded === res._category._id
                          ? theme.primaryActiveColor
                          : theme.baseBlack,
                      fontSize: '0.9rem',
                    }}
                  />
                }
              >
                <Stack
                  direction="row"
                  spacing={1}
                  flex={1}
                  sx={{
                    color:
                      expanded === res._category._id
                        ? theme.primaryActiveColor
                        : theme.baseBlack,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: '600 !important' }}
                    component="span"
                  >
                    {res._category.name}
                  </Typography>
                  <ProductCount isExpanded={expanded === res._category._id}>
                    {res.products.length}
                  </ProductCount>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  {!productsCat?.[res._category._id] ? (
                    <CircularProgress size={24} />
                  ) : (
                    productsCat?.[res._category._id]?.products.map((prod) => (
                      <ProductRow
                        prod={prod}
                        onAddAction={onAddAction}
                        onRemoveAction={onRemoveAction}
                        addedProducts={addedProducts}
                        setAddedProducts={setAddedProducts}
                      />
                    ))
                  )}
                </>
              </AccordionDetails>
            </Accordion>
          ))}
      </Wrapper>
    </>
  );
};

const ProductCount = styled.span<{ isExpanded: boolean }>`
  color: ${(props) =>
    props.isExpanded
      ? props.theme.primaryActiveColor
      : props.theme.itemLightGrey};
  font-weight: 400;
  padding-left: 8px;
`;

const EmptyCenter = styled.div`
  position: absolute;
  top: calc(100vh - 400px);
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Wrapper = styled.div`
  position: relative;
  max-height: 624px !important;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;
