import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Grid, Typography } from '@mui/material';
import { Paperclip } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';

function AttachmentCell(params: CustomCellRendererProps) {
  const filesCount = params.value.attachmentsData?.length || 0;

  return (
    <Grid
      container
      alignItems="center"
      gap="4px"
      sx={{
        width: 'fit-content',
        padding: '4px',
        ':hover': {
          cursor: filesCount > 0 ? 'pointer' : 'default',
          background:
            filesCount > 0 ? themes?.default?.rippleGrey : 'transparent',
        },
      }}
    >
      <Paperclip
        color={themes?.default?.grey2}
        style={{
          width: '20px',
          height: '20px',
        }}
      />
      <Typography color={themes?.default?.grey2} fontWeight="0.75rem">
        {filesCount}
      </Typography>
    </Grid>
  );
}

export default AttachmentCell;
