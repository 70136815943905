import {
  IGridContext,
  IStatsConfig,
  ITabsContext,
} from 'app/components/ManagedGrid/types';
import {
  selectAuthCustomer,
  selectAuthUser,
} from 'common/store/auth/selectors';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQueryParams } from 'hooks/useQueryParams';
import { useDebounce } from 'common/hooks/useDebounce';
import { selectConnectors } from 'common/store/organization/selectors';
import BillsEmptyState from 'assets/img/ORDORIA/Transactions/bills.svg';
import { BulkActionsToolbar } from './BulkActionsToolbar';
import { getProcessedInvoices } from './helpers';
import { TransactionsTab } from './config';
import ManagedGrid from 'app/components/ManagedGrid';
import styled from 'styled-components';
import { GRID } from 'app/components/TableComponent/constants';
import { InvoiceDrawer } from './components/InvoiceDrawer';
import useInvoices from './hook/useInvoicesFromApi';
import { InvoiceFilter } from './components/InvoiceFiter';
import _ from 'lodash';
import {
  useGetInvoiceFacetsQuery,
  useGetInvoicesAdminFacetsQuery,
  useUploadInvoicesMutation,
} from 'common/services/transactions';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import { useImportTransactionFiles } from './components/ImportTransactionFiles';

export function InvoicesGrid() {
  const { t } = useTranslation();
  const location = useLocation();
  const gridContext = useManagedContext<IGridContext>('grid');
  const authCustomer = useSelector(selectAuthCustomer);
  const tabsContext = useManagedContext<ITabsContext>('tabs');
  const statsConfig = useManagedContext<IStatsConfig>('statsConfig');

  const { isCustomer } = useGetUserAcl();

  const {
    queryParams: { page, rowsPerPage, search, filters, status },
  } = gridContext;

  const commonQueryParams = {
    page,
    limit: rowsPerPage,
    text: search || '',
    isArchived: filters?.archived || false,
    paymentStatus:
      status || filters?.status?.map((status) => status.value) || '',
    customerId: filters?.customerId?.map((customer) => customer?.value) || '',
    customerDeckId: filters?.customerDeckId?.map((deck) => deck?.value) || '',
  };

  const commonClientQueryParams = {
    page,
    limit: rowsPerPage,
    text: search || '',
    isArchived: filters?.archived || false,
    paymentStatus:
      status || filters?.status?.map((status) => status.value) || '',
  };

  const facetsQueryParams = useQueryParams(
    _.omit(commonQueryParams, [
      'page',
      'limit',
      'text',
      'isArchived',
      'paymentStatus',
      'customerId',
      'customerDeckId',
    ]),
  );

  const { data: facets } = useGetInvoicesAdminFacetsQuery(facetsQueryParams, {
    skip: isCustomer(),
  });
  const { data: facetsCustomer } = useGetInvoiceFacetsQuery(facetsQueryParams, {
    skip: !isCustomer(),
  });

  const [uploadInvoice] = useUploadInvoicesMutation();

  const urlParams = new URLSearchParams(location.search);
  const queryParams = useQueryParams(
    isCustomer() ? commonClientQueryParams : commonQueryParams,
  );

  const debouncedQueryParams = useDebounce(queryParams, 200);

  const connectors = useSelector(selectConnectors);
  const paynumConnector = connectors?.find(
    (connector) => connector.type === 'paynum' && connector.enable,
  );

  const paymentEnable = authCustomer && paynumConnector?.config?.paymentEnable;

  const { invoices, isLoading, isFetching, refetch } =
    useInvoices(debouncedQueryParams);

  const authUser = useSelector(selectAuthUser);
  const isUserCustomer = authUser?.currentAgent?._role?.key === 'customer';

  const { open } = useImportTransactionFiles();

  const noDataConfig = useMemo(
    () => ({
      icon: BillsEmptyState,
      text: t('transactions.empty-state.bills.text'),
      subText: t('transactions.empty-state.bills'),
      textButton: t('transaction.invoice.add.title'),
      onClick: () => gridContext.updateData('openDrawer', true),
    }),
    [gridContext.openDrawer, invoices],
  );

  const toolbarConfig = useMemo(
    () => ({
      disabled: gridContext.disableToolBar,
      BulkActionsComponent: BulkActionsToolbar,
      FilterComponent: InvoiceFilter,
      onAddClick: (e) => {
        gridContext.api.openToolbarMenu(e);
      },
      MenuOptions: [
        {
          label: 'Import',
          action: () =>
            open({
              onImport: (file) => {
                const formData = new FormData();
                formData.append('file', file);
                return uploadInvoice({ file: formData }).unwrap();
              },
              type: 'bills',
            }),
        },
        /** HIDE FOR NOW
         * {
          label: 'Manual',
          action: () => {
            gridContext.updateData('openDrawer', true);
          },
        },
         */
      ],
    }),
    [gridContext.disableToolBar],
  );

  useEffect(() => {
    if (!invoices) return;

    const processedData = getProcessedInvoices(
      invoices?.docs,
      paymentEnable,
      isUserCustomer,
    );

    gridContext.updateDataWithFunction((prev) => {
      prev.totalRows = invoices?.totalDocs || 0;
      prev.responseData = invoices?.docs;
      prev.loading = isLoading;
      prev.rowData = processedData!;
    });

    statsConfig.updateData('facet', facets ?? facetsCustomer);
  }, [
    gridContext?.viewMode,
    invoices?.docs,
    isLoading,
    isFetching,
    facets,
    facetsCustomer,
  ]);

  useEffect(() => {
    if (gridContext.viewMode === GRID) {
      tabsContext.updateDataWithFunction((prev) => {
        prev.selectedTab = TransactionsTab.BILLS;
        prev.visibleTabs = [
          { label: t('common.bills'), value: TransactionsTab.BILLS },
          { label: t('common.refund'), value: TransactionsTab.REFUND },
          { label: t('common.payments'), value: TransactionsTab.PAYMENTS },
        ];
      });
    }
  }, [gridContext.viewMode, queryParams, invoices?.docs]);

  useEffect(() => {
    if (
      !isFetching &&
      (authCustomer?._id || urlParams?.get('payments') === 'ok')
    ) {
      refetch();
    }
  }, [authCustomer?._id, urlParams?.get('payments')]);

  useEffect(() => {
    gridContext.updateDataWithFunction((prev) => {
      if (prev.columnDefs?.length) {
        const archivedCol: any = prev.columnDefs.find(
          (column) => column.field === '__archived',
        );

        if (archivedCol && prev.viewMode === GRID) {
          archivedCol.hide = !filters.archived;
          prev.withStatsCards = !filters.archived;
        }
      }
    });
  }, [gridContext, filters]);

  useEffect(() => {
    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters = {
        archived: false,
        status: [],
        customerId: [],
        customerDeckId: [],
      };
    });
  }, []);

  return (
    <Wrapper>
      <ManagedGrid toolbarConfig={toolbarConfig} noDataConfig={noDataConfig} />
      <InvoiceDrawer
        open={gridContext.openDrawer}
        handleClose={() => {
          gridContext.updateDataWithFunction((prev) => {
            prev.openDrawer = false;
            prev.selectedRow = null;
            prev.extraData.currentRow = null;
          });
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 1;

  ag-pinned-left-cols-container .ag-row [col-id='__actionMenu'],
  .ag-center-cols-container .ag-row [col-id='__actionMenu'],
  .ag-pinned-right-cols-container .ag-row [col-id='__actionMenu'] {
    display: flex;
    justify-content: end;
    padding-left: 0px;
  }
`;
