import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';

interface props {
  options: {
    value: string;
    label: string;
  }[];
  getOptionLabel: (option) => string;
  renderOption?: (option) => React.ReactNode;
}

export const AutoCompleteSearch = ({
  control,
  propsVal,
  onChange,
  disabled,
  setSearch,
  isFetching,
  name,
  fieldName,
  renderOption,
}: {
  control: Control<any>;
  propsVal: props;
  onChange: (event: React.SyntheticEvent | Event, value: unknown) => void;
  setSearch: (val: string) => void;
  isFetching: boolean;
  disabled: boolean;
  name: string;
  fieldName: string;
  hasProfile?: boolean;
  renderOption?: (option) => React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          {...propsVal}
          autoComplete={false}
          onChange={(e, value) => {
            onChange(e, value);
          }}
          onBlur={() => setSearch('')}
          loading={isFetching}
          renderOption={(props, option) => (
            <li {...props}>
              {renderOption && renderOption(option)}
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label={t(name)}
              variant="outlined"
              onChange={(e) => {
                setSearch(e?.target?.value);
              }}
              disabled={disabled}
              required
            />
          )}
          disabled={disabled}
        />
      )}
    />
  );
};
