import moment from 'moment';
import dayjs from 'dayjs';

export function formatDate(date: Date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}
export function timeZoneDate(date: Date) {
  const adjustedDate = moment(date);
  return adjustedDate.format('YYYY-MM-DD');
}

export function formatTime24hr(date: Date) {
  const adjustedTime = moment(date).format('HH:mm');
  return adjustedTime;
}
export function formatTime(date: Date) {
  const adjustedTime = moment(date).format('HH:mm a');
  return adjustedTime;
}
export function formatTimeAmPm(timeString: string) {
  return moment(timeString, 'HH:mm').format('h:mm A');
}
export function formatDateLocalTime(date: Date) {
  const adjustedDate = moment(date);
  return adjustedDate.format('DD-MM-YYYY h:mma');
}
export const formatDateName = (dateString: string | Date) => {
  return moment(dateString).format('D MMM YYYY');
};

export function formatDuration(duration: number) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.round((duration % 3600) / 60);

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

export const formatCreatedAt = (createdAt: Date) => {
  const createdDate = moment(createdAt);
  const now = moment();

  if (createdDate.isSame(now, 'day')) {
    return createdDate.format('h:mm A');
  } else if (createdDate.isSame(now.subtract(1, 'day'), 'day')) {
    return 'yesterday';
  } else {
    return createdDate.format('D MMMM, YYYY');
  }
};

export function dateFormat(date: string | Date, time: boolean = false) {
  const parsedDate = dayjs(date);

  if (time) {
    return parsedDate.format('D MMM. YYYY, hh:mm:ss A');
  } else {
    return parsedDate.format('D MMM. YYYY');
  }
}

export function formatCustomDate(date: Date) {
  return dayjs(date).format('D MMM. YYYY hh:mm A');
}

export const weekday = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
