import React from 'react';
import { Stack, Typography } from '@mui/material';
import {
  CalendarBlank,
  User,
  FileText,
  UserCircle,
} from '@phosphor-icons/react';
import { dateFormat } from 'common/utils/dates';
import { DOCUMENT_STATUSES } from 'app/pages/Settings/UserInfo/components/UserTabs/DocumentTab/documentStatuses';
import DetailRow from '../../../DetailRow';
import { getIconForType } from 'app/pages/Settings/UserInfo/components/UserTabs/DocumentTab/components/Cells';
import { t } from 'i18next';
import { InfoCard } from 'app/components/InfoCard';
import { useTheme } from 'common/hooks/useTheme';
import If from 'app/components/If';
import { DocumentsKeys } from 'common/utils/enum';

const DocumentInfo = ({
  submission_date,
  updated_date,
  user,
  type,
  status,
  authUser,
  document,
}) => {
  const theme = useTheme();
  return (
    <>
      <Stack direction="row" spacing={2} width="100%">
        <InfoCard
          icon={<CalendarBlank size={16} color={theme.primary} />}
          title={t('order_details.delivery_information.submitted_on')}
          value={dateFormat(new Date(submission_date))}
          style={{ backgroundColor: 'white' }}
          fontSize="12px"
          iconBg={theme.infoCardbg}
        />
        <InfoCard
          icon={<User size={16} color={theme.primary} />}
          title={t('document.heads.submitted_by')}
          value={
            authUser?.fullName !== user?.submitted_by?.fullName
              ? user?.submitted_by?.fullName
              : 'You'
          }
          style={{ backgroundColor: 'white' }}
          fontSize="12px"
          iconBg={theme.infoCardbg}
        />
      </Stack>
      <If condition={status?.status !== DOCUMENT_STATUSES.PENDING}>
        <Stack direction="row" spacing={2} width="100%" marginTop={2}>
          <InfoCard
            icon={<FileText size={16} color={theme.primary} />}
            title={t('last_updated_on')}
            value={dateFormat(new Date(updated_date))}
            style={{ backgroundColor: 'white' }}
            fontSize="12px"
            iconBg={theme.infoCardbg}
          />
          <InfoCard
            icon={<UserCircle size={16} color={theme.primary} />}
            title={t('last_updated_by')}
            value={
              authUser?.fullName !== user?.updated_by?.fullName
                ? user?.updated_by?.fullName
                : 'You'
            }
            style={{ backgroundColor: 'white' }}
            fontSize="12px"
            iconBg={theme.infoCardbg}
          />
        </Stack>
      </If>
      <Stack spacing={2} width="100%" sx={{ paddingTop: 3 }}>
        <Typography fontSize={14} fontWeight={400}>
          {t('common.details').toString()}
        </Typography>
        <DetailRow
          label={t('common.type').toString()}
          value={
            type !== DocumentsKeys.OTHER
              ? t(type)
              : document?.specifyOtherType || t(document?.type)
          }
          icon={getIconForType(type, 12, false)}
        />
        <DetailRow
          label="Status"
          value={status.status}
          padding="3px 10px"
          statusColor={
            status.status === DOCUMENT_STATUSES.PENDING
              ? theme.statusPendingColor
              : status.status === DOCUMENT_STATUSES.APPROVED
                ? theme.statusApprovedColor
                : theme.statusRejectedColor
          }
        />
      </Stack>
    </>
  );
};

export default DocumentInfo;
