import { Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

interface Props {
  status: 'online' | 'offline' | 'other';
  style?: any;
  size?: number;
}

export const UserStatusDot: React.FC<Props> = ({
  status,
  size = 4,
  style = null,
}) => {
  const color = useMemo(() => {
    if (status === 'online') {
      return themes?.default?.greenLaurel;
    } else if (status === 'other') {
      return themes?.default?.graphGrey;
    } else {
      return themes?.default?.badgeRed;
    }
  }, [status]);

  const { t } = useTranslation();

  return (
    <Tooltip title={t(`legalid.${status}`)}>
      <Wrapper size={size} color={color} style={style} />
    </Tooltip>
  );
};

const Wrapper = styled.div<{ size: number; color: string; style: any }>`
  height: ${(props) => props.size}px !important;
  width: ${(props) => props.size}px !important;
  border-radius: ${(props) => props.size}px !important;
  outline: 1px solid ${themes?.default?.grey};
  background: ${(props) => props.color};
  position: ${(props) => props.style?.position || 'absolute'};
  bottom: 2px;
  right: 0;
`;
