import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { t } from 'i18next';
import styled from 'styled-components';
import { Alert, Box, Button, Stack } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useGetStatusesUserDocumentsQuery } from 'common/services/userDocumentsApi';
import { useToaster } from 'hooks/useToaster';
import { useModal } from 'app/components/Dialog/hooks';
import { useUploadMutation } from 'common/services/files';
import { DOCUMENT_STATUSES } from 'app/pages/Settings/UserInfo/components/UserTabs/DocumentTab/documentStatuses';
import DocumentInfo from './components/DocumentInfo';
import DocumentAttachments from './components/DocumentAttachments';
import RejectReason from './components/RejectReason';
import FooterButton from '../FooterButton';
import { Check, X } from '@phosphor-icons/react';
import { DocumentRejectReason } from 'common/utils/documentRejectReasons';
import { useForm } from 'react-hook-form';
import If from 'app/components/If';
import { SubmitButton } from 'app/components/SubmitButton';
import {
  useDeleteDocumentsMutation,
  useUpdateDocumentMutation,
  useChangeDocumentStatusMutation,
  useArchiveDocumentsMutation,
} from 'common/services/userDocumentsApi';
import { DocumentsKeys } from 'common/utils/enum';

const EditDocument = () => {
  const gridContext = useManagedContext<IGridContext>('grid');
  const [uploadedDocuments, setUploadedDocuments] = useState<any[]>([]);
  const [deletedDocuments, setDeletedDocuments] = useState<any[]>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [doc, setDoc] = useState<any>({});
  const idParams = useParams();
  const authUser = useSelector(selectAuthUser);
  const id = idParams.id || '';

  const checkModuleScope = useCallback(
    (application, module, scope) => {
      return authUser?.currentAgent?.privileges?.scopes.includes(
        `${application}.${module}.${scope}`,
      );
    },
    [authUser?.currentAgent?.privileges?.scopes],
  );

  const CanChangeStatus =
    gridContext.canEdit &&
    (authUser?._id !== id || authUser?._id !== doc?._userAgent?._id) &&
    checkModuleScope('iam', 'documents', 'canedit');

  const cantEdit =
    authUser?._id !== id && !checkModuleScope('iam', 'documents', 'canview');

  const toast = useToaster();
  const { openModal, closeModal } = useModal();
  const [uploadFile, { isLoading: isUploading }] = useUploadMutation();
  const [deleteDocumentsByIds, { isLoading: isDeleting }] =
    useDeleteDocumentsMutation();
  const [updateDocument, { isLoading: isUpdating }] =
    useUpdateDocumentMutation();
  const [archiveDocument] = useArchiveDocumentsMutation();
  const [customReason, setCustomReason] = useState('');
  const [hoveredOption, setHoveredOption] = useState<string | null>(null);
  const [selectedReason, setSelectedReason] = useState<{
    value: string;
    label: string;
  }>({
    value: '',
    label: '',
  });
  const { data: facets } = useGetStatusesUserDocumentsQuery(`${authUser?._id}`);
  // const facetsData = useMemo(() => {
  //   if (facets) {
  //     return Object.entries(facets?.statuses)?.map(([type, status]) => ({
  //       type,
  //       status: type === DocumentsKeys.OTHER ? '' : status,
  //     }));
  //   }
  //   return [];
  // }, [facets]);

  const [updateStatus] = useChangeDocumentStatusMutation();
  const { selectedRow, handleReject = false } = gridContext;
  const {
    status,
    submission_date,
    updated_date,
    user,
    type,
    attachments,
    id: documentId,
  } = selectedRow || {};

  useEffect(() => {
    setDocuments(gridContext?.data?.responseData[0]?.docs);
  }, [gridContext]);

  useEffect(() => {
    const document = documents?.find((data) => data._id === documentId);
    setDoc(document);
  }, [documents, documentId]);

  const isCurrentUser = useMemo(() => {
    return authUser?._id !== doc?._userAgent?._id;
  }, [authUser, doc]);

  const upload = async (documents: File[] | null, name: string) => {
    const urls: { url: string; size: string; name: string; isNew: boolean }[] =
      [];

    if (documents?.length) {
      try {
        const uploadPromises = documents.map(async (document) => {
          const formData = new FormData();
          formData.append('file', document);
          const { size, url } = await uploadFile({
            formData,
            preserveName: false,
            persist: false,
            objectType: 'user',
            objectId: id,
          }).unwrap();
          if (url) {
            urls.push({
              url,
              size: size || 0,
              name: document.name,
              isNew: true,
            });
          }
        });

        await Promise.all(uploadPromises);

        if (urls.length) {
          const updateObj: any = {
            id: `${documentId}?iam-checker=true`,
            body: {
              attachment: [...urls],
              type: name,
            },
          };
          const res: any = await updateDocument(updateObj);
          setUploadedDocuments([]);
          if (!res?.error) {
            setUploadedDocuments([]);
            handleCloseDrawer();
          }
        }
      } catch (e) {
        console.error('Error uploading documents:', e);
      }
    }
  };

  const { handleSubmit } = useForm();

  const onSubmit = async (_values) => {
    await handleSave();
  };

  const handleApproveFunction = () => {
    updateStatus({
      id: `${documentId}?iam-checker=true`,
      body: { status: DOCUMENT_STATUSES.APPROVED, reason: ' ' },
    });
    toast(3000, 'success', 'messages.document.approved', { type: t(type) });
    handleCloseDrawer();
  };

  const handleRejectFunction = () => {
    updateStatus({
      id: `${documentId}?iam-checker=true&authApplication=iam`,
      body: {
        status: DOCUMENT_STATUSES.REJECTED,
        reason:
          selectedReason?.value === 'document.other'
            ? customReason
            : selectedReason?.value,
      },
    });
    handleCloseDrawer();
    toast(3000, 'success', 'messages.document.rejected', { type: t(type) });
  };

  const handleSave = async () => {
    if (deletedDocuments.length > 0) {
      const res = await deleteDocumentsByIds({
        id: documentId,
        body: { attachmentIds: deletedDocuments },
      });
      if ('error' in res) {
        console.error('Error deleting documents:', res.error);
      } else {
        handleCloseDrawer();
      }
    }
    if (uploadedDocuments.length > 0) {
      upload(uploadedDocuments, type);
    }
    toast(3000, 'success', 'messages.document.updated', { type: t(type) });
  };

  const handleCloseDrawer = useCallback(() => {
    gridContext.updateDataWithFunction((prev) => {
      prev.openDrawer = false;
      prev.selectedRow = null;
      prev.handleReject = false;
      prev.resubmitType = '';
    });
  }, [gridContext]);

  const handleDelete = () => {
    openModal({
      title: t(`delete_document_${type}`),
      content: t(`delete_document_confirmation_${type}`).toString(),
      action: {
        actionText: t('delete'),
        actionCallback: () => {
          updateDocument({
            id: `${documentId}?iam-checker=true`,
            body: { deleted: true },
          });
          closeModal();
          handleCloseDrawer();
          toast(3000, 'success', 'messages.document.deleted', {
            type: t(type),
          });
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  };

  const handleArchive = () => {
    openModal({
      title: `Archive ${t(`${type}`)} Document`,
      content: `${t('archive_document_confirmation_1')} "${t(`${type}`)}"? ${t(
        'archive_document_confirmation_2',
      )}`,
      action: {
        actionText: t('archive'),
        actionCallback: () => {
          archiveDocument({
            id: `${documentId}?iam-checker=true`,
            body: { isArchived: true },
          }).unwrap();
          closeModal();
          toast(3000, 'success', 'messages.document.archived', {
            type: t(type),
          });
          handleCloseDrawer();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  };

  const canResubmit =
    (gridContext.canEdit || checkModuleScope('iam', 'documents', 'canview')) &&
    status.status === DOCUMENT_STATUSES.REJECTED &&
    (authUser?._id === doc?._userAgent?._id || authUser?._id === id) &&
    !facets?.some(
      (item) =>
        item.type === type &&
        (item.type !== DocumentsKeys.OTHER
          ? item.status === DOCUMENT_STATUSES.PENDING ||
            item.status === DOCUMENT_STATUSES.APPROVED
          : false),
    ) &&
    !gridContext.queryParams?.filters?.isArchived;

  const canArchive = gridContext.canEdit && !status?.archived;

  const renderFooterButtons = () => {
    if (gridContext.canEdit && !cantEdit)
      switch (status?.status) {
        case DOCUMENT_STATUSES.APPROVED:
          return (
            <If
              condition={!isCurrentUser}
              otherwise={
                <Stack direction="row" width="100%">
                  <Stack direction="row" spacing={1} flex={1}>
                    <If condition={!handleReject}>
                      <FooterButton
                        label={t('reject')}
                        color={themes.default.statusRejectedColor.color}
                        onClick={() => {
                          gridContext.updateData('handleReject', true);
                        }}
                      />
                    </If>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    flex={1}
                  >
                    <FooterButton
                      label={t('common.cancel')}
                      color={themes.default.documentDrawerText}
                      onClick={handleCloseDrawer}
                    />
                    <If
                      condition={!handleReject}
                      otherwise={
                        <div style={{ position: 'relative', top: '4px' }}>
                          <SubmitButton
                            loading={isUpdating || isUploading || isDeleting}
                            onSubmit={handleRejectFunction}
                            disabled={
                              selectedReason?.value ===
                              DocumentRejectReason.Other
                                ? !customReason
                                : !selectedReason?.value
                            }
                            isEdit={CanChangeStatus}
                            title={t('Confirm').toString()}
                          />
                        </div>
                      }
                    >
                      <SubmitButton
                        loading={isUpdating || isUploading || isDeleting}
                        onSubmit={onSubmit}
                        disabled={
                          (!uploadedDocuments.length &&
                            !deletedDocuments.length) ||
                          (attachments.length === deletedDocuments.length &&
                            !uploadedDocuments.length)
                        }
                        isEdit={CanChangeStatus}
                        title="common.buttons.save"
                      />
                    </If>
                  </Stack>
                </Stack>
              }
            >
              <Stack direction="row" width="100%">
                <Stack
                  direction="row"
                  spacing={1}
                  flex={1}
                  justifyContent="flex-end"
                >
                  <FooterButton
                    label={t('common.cancel')}
                    color={themes.default.documentDrawerText}
                    onClick={handleCloseDrawer}
                  />
                  <SubmitButton
                    loading={isUpdating || isUploading || isDeleting}
                    onSubmit={onSubmit}
                    disabled={
                      (!uploadedDocuments.length && !deletedDocuments.length) ||
                      (attachments.length === deletedDocuments.length &&
                        !uploadedDocuments.length)
                    }
                    isEdit={CanChangeStatus}
                    title="common.buttons.save"
                  />
                </Stack>
              </Stack>
            </If>
          );
        case DOCUMENT_STATUSES.REJECTED:
          return (
            <If
              condition={CanChangeStatus}
              otherwise={
                <>
                  <FooterButton
                    label={t('common.cancel')}
                    color={themes.default.documentDrawerText}
                    onClick={handleCloseDrawer}
                  />
                  <If condition={canArchive}>
                    <FooterButton
                      label={t('archive')}
                      color={themes.default.statusRejectedColor.color}
                      onClick={handleArchive}
                    />
                  </If>
                  <If condition={canResubmit}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        textTransform: 'none',
                        display: 'flex',
                        gap: '5px',
                      }}
                      onClick={() =>
                        gridContext.updateDataWithFunction((prev) => {
                          prev.openDrawer = true;
                          prev.selectedRow = null;
                          prev.resubmitType = type;
                        })
                      }
                    >
                      {t('resubmit').toString()}
                    </Button>
                  </If>
                </>
              }
            >
              <>
                <FooterButton
                  label={t('common.cancel')}
                  color={themes.default.documentDrawerText}
                  onClick={handleCloseDrawer}
                />
                <If condition={canArchive}>
                  <FooterButton
                    label={t('archive')}
                    color={themes.default.statusRejectedColor.color}
                    onClick={handleArchive}
                  />
                </If>
                <If condition={canResubmit}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      textTransform: 'none',
                      display: 'flex',
                      gap: '5px',
                    }}
                    onClick={() =>
                      gridContext.updateDataWithFunction((prev) => {
                        prev.openDrawer = true;
                        prev.selectedRow = null;
                        prev.resubmitType = type;
                      })
                    }
                  >
                    {t('resubmit').toString()}
                  </Button>
                </If>
              </>
              {/* <Stack direction="row" width="100%">
                <Stack direction="row" spacing={1} flex={1}>
                  <FooterButton
                    label={t('common.cancel')}
                    color={themes.default.documentDrawerText}
                    onClick={handleCloseDrawer}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  flex={1}
                  justifyContent="flex-end"
                >
                  <If condition={canResubmit}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        textTransform: 'none',
                        display: 'flex',
                        gap: '5px',
                      }}
                      onClick={() =>
                        gridContext.updateDataWithFunction((prev) => {
                          prev.openDrawer = true;
                          prev.selectedRow = null;
                          prev.resubmitType = type;
                        })
                      }
                    >
                      {t('resubmit').toString()}
                    </Button>
                  </If>
                  <If condition={!status?.archived}>
                    <FooterButton
                      label={t('archive')}
                      color={themes.default.statusRejectedColor.color}
                      onClick={handleArchive}
                    />
                  </If>
                </Stack>
              </Stack> */}
            </If>
          );
        case DOCUMENT_STATUSES.PENDING:
          return (
            <If
              condition={isCurrentUser}
              otherwise={
                <Stack direction="row" width="100%">
                  <FooterButton
                    label={t('delete')}
                    color={themes.default.statusRejectedColor.color}
                    onClick={handleDelete}
                  />
                  <Stack
                    direction="row"
                    spacing={1}
                    flex={1}
                    justifyContent="flex-end"
                  >
                    <FooterButton
                      label={t('common.cancel')}
                      color={themes.default.documentDrawerText}
                      onClick={handleCloseDrawer}
                    />
                    <SubmitButton
                      loading={isUpdating || isUploading || isDeleting}
                      onSubmit={onSubmit}
                      disabled={
                        (!uploadedDocuments.length &&
                          !deletedDocuments.length) ||
                        (attachments.length === deletedDocuments.length &&
                          !uploadedDocuments.length)
                      }
                      isEdit={CanChangeStatus}
                      title="common.buttons.save"
                    />
                  </Stack>
                </Stack>
              }
            >
              <If
                condition={handleReject || !(CanChangeStatus && isCurrentUser)}
                otherwise={
                  <Stack direction="row" width="100%">
                    <Stack
                      direction="row"
                      spacing={1}
                      flex={1}
                      alignContent="center"
                    >
                      <FooterButton
                        label={t('common.cancel')}
                        color={themes.default.documentDrawerText}
                        onClick={handleCloseDrawer}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      flex={1}
                      justifyContent="flex-end"
                    >
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          textTransform: 'none',
                          display: 'flex',
                          gap: '5px',
                          height: '36px',
                        }}
                        onClick={() =>
                          gridContext.updateData('handleReject', true)
                        }
                      >
                        <X
                          size={12}
                          style={{ color: themes.default.approveColor.color }}
                        />
                        {t('reject').toString()}
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{
                          textTransform: 'none',
                          display: 'flex',
                          gap: '5px',
                          height: '36px',
                        }}
                        onClick={handleApproveFunction}
                      >
                        <Check
                          size={12}
                          style={{ color: themes.default.approveColor.color }}
                        />
                        {t('approve').toString()}
                      </Button>
                    </Stack>
                  </Stack>
                }
              >
                <Stack direction="row" width="100%">
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    flex={1}
                  >
                    <FooterButton
                      label={t('common.cancel')}
                      color={themes.default.documentDrawerText}
                      onClick={handleCloseDrawer}
                    />
                    <div style={{ position: 'relative', top: '4px' }}>
                      <SubmitButton
                        loading={isUpdating || isUploading || isDeleting}
                        onSubmit={handleRejectFunction}
                        disabled={
                          selectedReason?.value === DocumentRejectReason.Other
                            ? !customReason
                            : !selectedReason?.value
                        }
                        isEdit={CanChangeStatus}
                        title={t('Confirm').toString()}
                      />
                    </div>
                  </Stack>
                </Stack>
              </If>
            </If>
          );
        default:
          return (
            <Stack direction="row" width="100%">
              <Stack direction="row" spacing={1} flex={1}>
                <FooterButton
                  label={t('common.cancel')}
                  color={themes.default.documentDrawerText}
                  onClick={handleCloseDrawer}
                />
              </Stack>
            </Stack>
          );
      }
    else
      return (
        <Stack direction="row" width="100%">
          <Stack direction="row" spacing={1} flex={1} justifyContent="flex-end">
            <FooterButton
              label={t('common.cancel')}
              color={themes.default.documentDrawerText}
              onClick={handleCloseDrawer}
            />
          </Stack>
          <If condition={canResubmit}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: 'none',
                display: 'flex',
                gap: '5px',
              }}
              onClick={() =>
                gridContext.updateDataWithFunction((prev) => {
                  prev.openDrawer = true;
                  prev.selectedRow = null;
                  prev.resubmitType = type;
                })
              }
            >
              {t('resubmit').toString()}
            </Button>
          </If>
        </Stack>
      );
  };

  const title = handleReject
    ? t('document.title.rejection')
    : t(
        !gridContext.canEdit
          ? 'view_document'
          : status?.status === DOCUMENT_STATUSES.APPROVED ||
            (status?.status === DOCUMENT_STATUSES.PENDING && !isCurrentUser)
          ? 'edit_document'
          : 'view_document',
      );

  return (
    <TwoViewModal
      onClose={handleCloseDrawer}
      title={title}
      width="473px"
      onOpen={gridContext.openDrawer}
    >
      <Wrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <If
            condition={handleReject}
            otherwise={
              <>
                <If condition={status?.status === DOCUMENT_STATUSES.REJECTED}>
                  <Box sx={{ marginBottom: 2 }}>
                    <Alert
                      severity="error"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 'fit-content',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      {String(t(status.reason))}
                    </Alert>
                  </Box>
                </If>
                <DocumentInfo
                  submission_date={submission_date}
                  updated_date={updated_date}
                  user={user}
                  type={type}
                  status={status}
                  authUser={authUser}
                  document={doc}
                />
                <DocumentAttachments
                  canEdit={!cantEdit}
                  attachments={attachments}
                  status={status}
                  gridContext={gridContext}
                  setUploadedDocuments={setUploadedDocuments}
                  setDeletedDocuments={setDeletedDocuments}
                  isCurrentUser={isCurrentUser}
                />
              </>
            }
          >
            <RejectReason
              selectedReason={selectedReason}
              setSelectedReason={setSelectedReason}
              customReason={customReason}
              setCustomReason={setCustomReason}
              hoveredOption={hoveredOption}
              setHoveredOption={setHoveredOption}
              type={type}
            />
          </If>
        </form>
      </Wrapper>
      <FooterWrapper>{renderFooterButtons()}</FooterWrapper>
    </TwoViewModal>
  );
};

const Wrapper = styled.div`
  margin: 25px 20px 0;
  display: flex;
  flex-direction: column;
`;

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  background: #fff;
`;

export default React.memo(EditDocument);
