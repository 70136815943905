import {
  ICheckboxAutocompleteOption,
  CheckboxAutocomplete,
} from 'app/components/CheckboxAutocomplete';
import { useMemo, useState } from 'react';
import { isNumber } from 'lodash';
import { useGetCategoryDiscountsOptionsQuery } from 'common/services/categoriesApi';
import { useDebounce } from 'common/hooks/useDebounce';
import { useParams } from 'react-router-dom';

interface Props {
  level?: number;
  label: string;
  placeholder?: string;
  onChange: (options: ICheckboxAutocompleteOption[]) => void;
  required?: boolean;
  disabledOptions?: string[];
  defaultSelected?: ICheckboxAutocompleteOption[] | [];
  maxTags?: number;
}

export const CategoriesAutocomplete = ({
  level,
  label,
  placeholder,
  onChange,
  required,
  disabledOptions,
  defaultSelected,
  maxTags,
}: Props) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const { id: customerId } = useParams();

  const { data: categories, isLoading } = useGetCategoryDiscountsOptionsQuery(
    {
      _customer: customerId || '',
      level: level || 0,
      search: debouncedSearch,
    },
    { skip: !isNumber(level) || !customerId },
  );

  const addListOptions = useMemo<ICheckboxAutocompleteOption[]>(() => {
    if (!categories?.length) return [];
    const formattedCategories = categories.filter(
      (category) => category?._id && category?.name,
    ); // some categories have null as id and name

    return formattedCategories.map((category) => ({
      ...category,
      disabled:
        category?.disabled ||
        disabledOptions?.some(
          (disabledOption) => disabledOption === category?._id,
        ),
    }));
  }, [categories, disabledOptions]);

  return (
    <CheckboxAutocomplete
      options={addListOptions}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      onSearchChanged={(value) => setSearch(value)}
      required={required}
      defaultSelected={defaultSelected}
      isLoading={isLoading}
      maxTags={maxTags}
    />
  );
};
