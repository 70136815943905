import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormTextField from 'app/components/Form/TextField';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import i18next from 'i18next';
import { UploadZone } from 'app/components/UploadFiles';
import { useCallback, useEffect, useState } from 'react';
import { UploadedFile } from 'app/components/UploadComponent';
import { FileRejection } from 'react-dropzone';
import { useToaster } from 'hooks/useToaster';
import { AcceptedFiles } from 'app/components/AcceptedFiles/Index';
import { FormActions } from 'app/components/Form/styles';
import { themes } from 'styles/theme/themes';
import { LoadingButton } from '@mui/lab';
import {
  useGetCustomersBasicQuery,
  useLazyGetCustomersOrdersBasicsQuery,
} from 'common/services/customerApi';
import { useDebounce } from 'common/hooks/useDebounce';
import { useQueryParams } from 'hooks/useQueryParams';

export function InvoiceForm({ handleClose }) {
  const { t } = useTranslation();
  const toast = useToaster();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      customer: {
        _id: '',
        legalName: '',
      },
      orderId: '',
      amount: '',
      note: '',
      dueDate: '',
    },
  });

  const { control, watch } = methods;

  const [files, setFiles] = useState<any[]>([]);

  const onFileChange = useCallback(async (newFiles: UploadedFile[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const handleFileDelete = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  /** CUSTOMERS FIELD QUERY */
  const [customersSearch, setCustomersSearch] = useState<string>('');
  const debouncedCustomersSearch = useDebounce(customersSearch, 200);
  const { data: customers } = useGetCustomersBasicQuery(
    `text=${debouncedCustomersSearch}`,
  );
  const customerId = watch('customer')?._id;

  /** ORDERS FIELD QUERY */
  const [ordersSearch, setOrdersSearch] = useState<string>('');
  const debouncedOrdersSearch = useDebounce(ordersSearch, 200) || '';
  const queryParams = useQueryParams({ text: debouncedOrdersSearch });
  const [triggerOrder, { data: orders }] =
    useLazyGetCustomersOrdersBasicsQuery();

  useEffect(() => {
    if (customerId) {
      triggerOrder({ customerId: customerId, queryParams });
    }
  }, [customerId]);

  return (
    <Wrapper>
      <Typography fontWeight={500} marginBottom="16px">
        {t('common.details')}
      </Typography>

      <Controller
        name="customer"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={customers?.docs || []}
            getOptionLabel={(option: any) => option?.legalName || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => {
                  setCustomersSearch(e?.target?.value);
                }}
                label={t('common.customer')}
                required={true}
              />
            )}
            renderOption={(props, option: any) => (
              <li {...props}>
                <Typography>{option.legalName}</Typography>
              </li>
            )}
            onChange={(e, value) => {
              field.onChange(value);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
        )}
      />
      <Controller
        name="orderId"
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={orders?.docs || []}
            getOptionLabel={(option: any) => option.code || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => {
                  setOrdersSearch(e?.target?.value);
                }}
                label={t('autocomplete.title.orders')}
              />
            )}
            onChange={(event, value) => {
              field.onChange(value);
            }}
            renderOption={(props, option: any) => (
              <li {...props}>
                <Typography>{option.code}</Typography>
              </li>
            )}
          />
        )}
      />
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <FormTextField
            control={control}
            name="amount"
            label="Amount"
            num={true}
            type="number"
            inputProps={{
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18next.language}
          >
            <Controller
              name="dueDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Due Date"
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  inputFormat="D MMM. YYYY"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <FormTextField
        control={control}
        name="note"
        label="Note"
        multiline
        rows={4}
      />

      <Typography fontWeight={500} marginBottom="16px">
        {t('common.attachments')}
      </Typography>
      <UploadZone
        onOk={onFileChange}
        onUnOk={(rejection: FileRejection) => {
          toast(5000, 'error', rejection?.errors?.[0]?.message);
        }}
        description="JPEG, TIFF, or PNG (max. 3MB)"
        acceptFile={{
          'image/png': [],
          'image/jpg': ['.jpeg', '.jpg'],
          'image/jpeg': [],
          'image/tiff': ['.tiff'],
        }}
      />
      <div style={{ marginBottom: '20px' }}>
        {files?.map((file) => (
          <AcceptedFiles
            key={file.name}
            file={file}
            statusFile={{ [file.name]: true }}
            hideProgressBar={{ [file.name]: true }}
            fileProgress={{ [file.name]: true }}
            deleteFile={() => handleFileDelete(file.name)}
            canView={true}
            newFile={file instanceof File}
          />
        ))}
      </div>
      <FormActions>
        <Button
          disableElevation
          sx={{ color: themes.default.black60, mr: '16px' }}
          onClick={handleClose}
        >
          {t('common.buttons.cancel')}
        </Button>

        <LoadingButton variant="contained" onClick={() => {}}>
          {t('add')}
        </LoadingButton>
      </FormActions>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 24px 20px;
`;
