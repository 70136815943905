import { PaymentsData } from './data';
import { IPaymentsData } from './types';

export function getProcessedPayments(paymentsData: PaymentsData[]) {
  return paymentsData.map((paymentsObj, index) => {
    return {
      id: paymentsObj?.paymentId || index,
      __type: {
        typeData: paymentsObj?.type || '',
      },
      paymentCode: {
        paymentCodeData: paymentsObj?.paymentId || '',
      },
      type: {
        typeData: paymentsObj?.type || '',
      },
      documentId: {
        documentIdData: paymentsObj?.documentId || '',
      },
      branch: {
        branchData: paymentsObj?.branchLocation?.address || '',
        branchCodeData: paymentsObj?.branchLocation?.code || '',
      },
      amount: {
        amountData: paymentsObj?.amount || 0,
      },
      paidVia: {
        paidSourceData: paymentsObj?.paidVia || '',
      },
      source: {
        sourceData: paymentsObj?.source || '',
      },
      paidOn: {
        paidOnData: paymentsObj?.paidOn || '',
      },
      __attachments: {
        attachmentData: [],
      },
      __notes: {
        notesData: paymentsObj?.notes || '',
      },
      __download: {
        downloadData: paymentsObj?.downloadUrl || '',
      },
    } as IPaymentsData;
  });
}
