import { themes } from 'styles/theme/themes';
import { IColDef } from '../../../components/ManagedGrid/types';
import ReportedDateCell from './cells/ReportedDateCell';
import ClientCell from './cells/clientCell';
import ClaimIdCell from './cells/claimIdCell';
import TypeCell from './cells/typeCell';
import StatusCell from './cells/StatusCell';
import ActionsCell from './cells/ActionCell';
import ClaimReasonCell from './cells/ClaimReasonCell';
import AttachmentsCell from './cells/AttachmentsCell';
import SalesCell from './cells/SalesCell';
import ReportedByCell from './cells/ReportedByCell';
import AssigneeCell from './cells/AssigneeCell';
import ArchivedCell from './cells/ArchivedCell';
import OrderCell from './cells/OrderCell';
import StatusDotCell from './cells/StatusDotCell';
import {
  CirclesFour,
  CurrencyCircleDollar,
  Package,
  Receipt,
  Star,
} from '@phosphor-icons/react';
import BranchCell from './cells/BranchCell';

export enum CLAIMS_STATS_STATUSES {
  PENDING = 'pending',
  INPROGRESS = 'in-progress',
  RESOLVED = 'resolved',
}

export const CLAIMS_TYPES = {
  BILLING: {
    backgroundColor: themes?.default?.primaryBackgroundColor,
    color: themes?.default?.blue900,
    type: 'billing',
    label: 'claims.type.billing',
    icon: CurrencyCircleDollar,
  },
  ACCOUNTING: {
    backgroundColor: themes?.default?.pink50,
    color: themes?.default?.plannedStatusColor,
    type: 'accounting',
    label: 'claims.type.accounting',
    icon: Receipt,
  },
  DELIVERY: {
    backgroundColor: themes?.default?.amber100,
    color: themes?.default?.amber1000,
    type: 'delivery',
    label: 'claims.type.delivery',
    icon: Package,
  },
  QUALITY: {
    backgroundColor: themes?.default?.teal50,
    color: themes?.default?.teal800,
    type: 'quality',
    label: 'claims.type.quality',
    icon: Star,
  },
  OTHER: {
    backgroundColor: themes?.default?.whisper,
    color: themes?.default?.buttonGreyText,
    type: 'other',
    label: 'claims.type.other',
    icon: CirclesFour,
  },
};

export const CLAIMS_STATUS_CHIPS_COLORS = {
  PENDING: {
    backgroundColor: themes.default?.orange50,
    color: themes.default?.orange1000,
    dotColor: themes.default?.textPendingCustomer,
    label: 'events.status.pending',
    key: 'pending',
  },
  INPROGRESS: {
    backgroundColor: themes.default?.blue50,
    color: themes.default?.blue700,
    dotColor: themes.default?.blue600,
    label: 'events.status.inprogress',
    key: 'in-progress',
  },
  RESOLVED: {
    backgroundColor: themes.default?.chipGreenFont,
    color: themes.default?.greenMain,
    dotColor: themes.default?.greenLaurel,
    label: 'claims.status.resolved',
    key: 'resolved',
  },
};

export const CLAIMS_STATS_COLORS = {
  PENDING: {
    thumbColor: themes.default?.progressOrange,
    backgroundColor: themes.default?.orange50,
    railColor: themes.default?.orange100,
  },
  INPROGRESS: {
    thumbColor: themes.default?.blue600,
    backgroundColor: themes.default?.blue50,
    railColor: themes.default?.blue200,
  },
  RESOLVED: {
    thumbColor: themes.default?.fruitSaladGreen,
    backgroundColor: themes.default?.chipGreenFont,
    railColor: themes.default?.green200,
  },
};

export const getCustomClaimsColumnConfig = (
  t: (key: string) => string,
): IColDef[] => [
  {
    cellRenderer: ArchivedCell,
    width: 10,
    resizable: true,
    lockPinned: true,
    pinned: 'left',
    hide: true,
  },
  {
    cellRenderer: StatusDotCell,
    width: 10,
    resizable: true,
    lockPinned: true,
    pinned: 'left',
    hide: true,
  },
  {
    cellRenderer: TypeCell,
    width: 56,
    resizable: true,
    lockPinned: false,
    pinned: 'left',
    headerName: t('field.type'),
  },
  {
    cellRenderer: ClaimIdCell,
    minWidth: 94,
    resizable: true,
    lockPinned: false,
    headerName: t('claimd.field.claimId'),
    flex: 1,
  },
  {
    cellRenderer: ClientCell,
    minWidth: 198,
    resizable: true,
    lockPinned: false,
    headerName: t('common.customer'),
    flex: 1,
  },
  {
    cellRenderer: BranchCell,
    minWidth: 145,
    resizable: true,
    lockPinned: false,
    headerName: t('orders.branch'),
    flex: 1,
  },
  {
    cellRenderer: OrderCell,
    minWidth: 137,
    resizable: true,
    lockPinned: false,
    headerName: t('common.order'),
    flex: 1,
  },
  {
    cellRenderer: ClaimReasonCell,
    minWidth: 198,
    resizable: true,
    lockPinned: false,
    headerName: t('claim.field.reason'),
    flex: 1,
  },
  {
    cellRenderer: SalesCell,
    minWidth: 59,
    resizable: true,
    lockPinned: false,
    headerName: t('user.role.sales.singular'),
    flex: 1,
  },
  {
    cellRenderer: ReportedByCell,
    minWidth: 90,
    resizable: true,
    lockPinned: false,
    headerName: t('claims.reported-by'),
    flex: 1,
  },
  {
    cellRenderer: ReportedDateCell,
    minWidth: 178,
    resizable: true,
    lockPinned: false,
    headerName: t('claim.field.reported-date'),
  },
  {
    cellRenderer: AttachmentsCell,
    minWidth: 93,
    resizable: true,
    lockPinned: false,
    headerName: t('attachments'),
    flex: 1,
  },
  {
    cellRenderer: AssigneeCell,
    minWidth: 76,
    resizable: true,
    lockPinned: false,
    headerName: t('accessories.assignee'),
    flex: 1,
  },
  {
    cellRenderer: StatusCell,
    minWidth: 105,
    resizable: true,
    headerName: t('payments.status'),
    lockPinned: false,
    pinned: 'right',
  },
  {
    cellRenderer: ActionsCell,
    width: 70,
    resizable: false,
    lockPinned: true,
    pinned: 'right',
  },
];

export const getCustomClientClaimsColumnConfig = (
  t: (key: string) => string,
): IColDef[] => [
  //{
  //  cellRenderer: ArchivedCell,
  //  width: 10,
  //  resizable: true,
  //  hide: true,
  // },
  // {
  //   cellRenderer: StatusDotCell,
  //   width: 10,
  //   resizable: true,
  //   hide: true,
  // },
  {
    cellRenderer: TypeCell,
    width: 56,
    resizable: true,
    lockPinned: false,
    pinned: 'left',
    headerName: t('field.type'),
    hide: false,
  },
  {
    cellRenderer: ClaimIdCell,
    minWidth: 94,
    resizable: true,
    lockPinned: false,
    headerName: t('claimd.field.claimId'),
    pinned: 'left',
  },
  {
    cellRenderer: OrderCell,
    minWidth: 90,
    resizable: true,
    lockPinned: false,
    headerName: t('common.order'),
  },
  {
    cellRenderer: ClaimReasonCell,
    minWidth: 450,
    resizable: true,
    lockPinned: false,
    headerName: t('claim.field.reason'),
  },
  {
    cellRenderer: ReportedDateCell,
    minWidth: 300,
    resizable: true,
    lockPinned: false,
    headerName: t('claim.field.reported-date'),
  },
  {
    cellRenderer: AttachmentsCell,
    minWidth: 93,
    resizable: true,
    lockPinned: false,
    headerName: t('attachments'),
  },
  {
    cellRenderer: StatusCell,
    minWidth: 105,
    headerName: t('payments.status'),
    pinned: 'right',
  },
  {
    cellRenderer: ReportedByCell,
    minWidth: 90,
    resizable: true,
    lockPinned: false,
    headerName: t('claims.reported-by'),
    pinned: 'right',
  },
];
