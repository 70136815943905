import { Sparkle, CaretLeft, X } from 'phosphor-react';
import { Icon } from 'app/components/Icon';
import PaperPlane from 'assets/img/chat/PaperPlane.svg';
import square from 'assets/img/chat/square.svg';
import { useState, useRef, useEffect } from 'react';
import {
  Grow,
  Box,
  TextField,
  InputAdornment,
  ButtonBase,
  Button,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import ChatbotResponse from './components/ChatbotResponse';
import { usePredefinedSections } from './config';
import { RoundtripsData, PostRoundtripsData } from './components/RoundTrips';

import {
  Sidebar,
  SidebarHeader,
  IconContainer,
  HeaderContent,
  HeaderContentText,
  CloseButton,
  ScrollableContent,
  HeroSection,
  HeroSectionText,
  QuestionList,
  QuestionSection,
  SectionHeader,
  SectionHeaderTitle,
  QAContainer,
  SelectedQuestionContainer,
  SelectedQuestionText,
  CustomIcon,
} from 'styles/aiaStyles';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'common/hooks/useTheme';
import { useDebounce } from 'common/hooks/useDebounce';

interface AskAIAProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const GradientIcon = () => {
  const theme = useTheme();
  return (
    <svg width="0" height="0">
      <defs>
        <linearGradient id="gradient1" x1="0%" y1="100%" x2="100%" y2="0%">
          {theme.gradientStops?.map((stop, index) => (
            <stop
              key={index}
              offset={stop.offset}
              style={{
                stopColor: stop.stopColor,
                stopOpacity: stop.stopOpacity,
              }}
            />
          ))}
        </linearGradient>
      </defs>
    </svg>
  );
};
const AskAIA: React.FC<AskAIAProps> = ({ isOpen, toggleSidebar }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  // ---------------------------Focus _ Icon Color control State------------------------------------------------
  const [isInputFocused, setIsInputFocused] = useState(false);

  //----------------------------------Section Scroll Control------------------------------------------------
  const scrollableContentRef = useRef<HTMLDivElement>(null);

  //----------------------------------Retrieve AIA Input Value------------------------------------------------
  const AIAref = useRef<HTMLTextAreaElement>(null);

  // -------------------------clean section and retrieve data selected (pre defined questions)------------------
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const { processRoundtripsDataBtn: processRoundtripsData } = RoundtripsData();
  const { processRoundtripsDataBtn: processPostRoundtripsData } =
    PostRoundtripsData();
  const [isLoading, setIsLoading] = useState(false);
  const stopClickedRef = useRef(false);

  // ----------------------------------------Handel QA Section -----------------------------------------------------
  const [qaList, setQaList] = useState<
    {
      question: string;
      response: { text?: string; tableData?: any[] };
    }[]
  >([]);
  const debouncedIsTyping = useDebounce(isTyping, 500);
  // ---------------------------------------------Scroll effect ----------------------------------------------
  useEffect(() => {
    const scrollToBottom = () => {
      if (scrollableContentRef.current) {
        const lastChild = scrollableContentRef.current.lastElementChild;
        if (lastChild) {
          lastChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }
    };

    scrollToBottom();
  }, [debouncedIsTyping]);

  // ---------------------------------------------Stop Btn ----------------------------------------------------------

  const handleStopBtn = () => {
    stopClickedRef.current = true;
    setIsTyping(false);
    setIsLoading(false);
  };
  //-------------------Handle AIA Input Data (ps:also prevent side bar from closing)--------------------

  const handleClickBtn = () => {
    if (!AIAref.current) return;
    const inputValue = AIAref.current.value.trim();
    if (!inputValue) return;
    handleInputQuestion(inputValue);
    // clear input value
    AIAref.current.value = '';
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isTyping) {
        handleStopBtn();
      } else {
        handleClickBtn();
      }
    }
  };

  //---------------------------------retrieve Question data------------------------------------------------
  const handleInputQuestion = async (text) => {
    if (
      text.toLowerCase().includes('hi') ||
      text.toLowerCase().includes('hello')
    ) {
      handleAnswer(text, { text: 'Hello, how can I assist you today?' });
      setSelectedQuestion(text);
      setIsTyping(true);
      return;
    }

    setQaList((prev) => [...prev, { question: text, response: {} }]);
    setSelectedQuestion(text);
    setIsLoading(true);
    stopClickedRef.current = false;

    try {
      const processedData = await processPostRoundtripsData(text);
      if (!stopClickedRef.current) {
        handleAnswer(text, processedData);
        setIsTyping(true);
      }
    } catch (error) {
      handleAnswer(text, {
        text: 'Sorry, there was an error processing your request',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const sections = usePredefinedSections();
  const [clicked, setClicked] = useState(false);

  const handleQuestionSelect = async (id) => {
    const question = sections
      .flatMap((section) => section.questions)
      .find((q) => q?.id === id);
    if (question) {
      setSelectedQuestion(question.question);
      setIsLoading(true);
      stopClickedRef.current = false;

      try {
        let processedData;
        if (id === 'question-1-1') {
          processedData = await processRoundtripsData();
        } else {
          processedData = question.answer;
        }
        if (!stopClickedRef.current) {
          handleAnswer(question.question, processedData);
          setClicked(true);

          setIsTyping(true);
        }
      } catch (error) {
        handleAnswer(question.question, {
          text: 'Sorry, there was an error processing your request',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAnswer = (
    question: string,
    answer: { text?: string; tableData?: any[] },
  ) => {
    setQaList((prev) => {
      const newList = [...prev];
      const lastIndex = newList.length - 1;
      if (lastIndex >= 0) {
        newList[lastIndex] = { question, response: answer };
      } else {
        newList.push({ question, response: answer });
      }
      return newList;
    });
  };

  const handleQAListRest = () => {
    handleStopBtn();
    setSelectedQuestion('');
    setQaList([]);
  };

  //disable btn
  const isQuestionDisabled = (id: string | undefined): boolean =>
    id !== 'question-1-1';

  return (
    <>
      {/* <GlobalStyle /> */}
      <Grow in={isOpen} timeout={{ enter: 300, exit: 600 }}>
        <Sidebar className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
          <GradientIcon />
          {/* Header Section */}
          <SidebarHeader>
            <HeaderContent>
              {selectedQuestion && (
                <IconContainer>
                  <Icon
                    icon={<CaretLeft weight="bold" />}
                    size={14}
                    onClick={handleQAListRest}
                  />
                </IconContainer>
              )}
              <CustomIcon
                className={`custom-icon ${
                  selectedQuestion ? 'with-margin' : ''
                }`}
              >
                <Icon
                  icon={<Sparkle weight="fill" />}
                  size={20}
                  color="url(#gradient1)"
                />
              </CustomIcon>
              <HeaderContentText
                className={`custom-icon ${
                  selectedQuestion ? 'with-margin' : ''
                }`}
              >
                {t('ask.aia')}
              </HeaderContentText>
            </HeaderContent>

            <div className="btn-header">
              <CloseButton onClick={toggleSidebar}>
                <Icon
                  icon={<X weight="fill" />}
                  size={14}
                  color={theme.mediumDarkGrey}
                />
              </CloseButton>
            </div>
          </SidebarHeader>

          {/* Main Section */}
          <ScrollableContent>
            {!selectedQuestion && (
              <HeroSection>
                <Icon
                  icon={<Sparkle weight="fill" />}
                  size={35}
                  color="url(#gradient1)"
                />
                <HeroSectionText>{t('ask_AIA_text')}</HeroSectionText>
              </HeroSection>
            )}
            {/* --------------------Select a question Other wise return all sections-------------------- */}
            <QuestionList>
              {selectedQuestion ? (
                <ScrollableContent ref={scrollableContentRef}>
                  <QAContainer>
                    {qaList.map((qa, index) => (
                      <Box key={index}>
                        <SelectedQuestionContainer>
                          <SelectedQuestionText clicked={clicked}>
                            {qa.question}
                          </SelectedQuestionText>
                        </SelectedQuestionContainer>

                        <ChatbotResponse
                          message={qa.response}
                          isTyping={isTyping && index === qaList.length - 1}
                          isLoading={isLoading && index === qaList.length - 1}
                          stopChatBtn={handleStopBtn}
                        />
                      </Box>
                    ))}
                  </QAContainer>
                </ScrollableContent>
              ) : (
                sections.map((section) => (
                  <QuestionSection key={section?.id}>
                    <SectionHeader>
                      {section.icon && (
                        <Icon
                          icon={<section.icon />}
                          size={18}
                          color={theme.nobel}
                        />
                      )}
                      <SectionHeaderTitle>{section.title}</SectionHeaderTitle>
                    </SectionHeader>
                    {section?.questions.map((q) => (
                      <Button
                        key={q.id}
                        onClick={() => handleQuestionSelect(q.id)}
                        disabled={isQuestionDisabled(q.id)}
                        onKeyDown={handleKeyDown}
                        sx={{
                          width: '100%',
                          padding: '0.25rem 0.5rem',
                          gap: '0.81rem',
                          borderRadius: '0.1rem !important',
                          backgroundColor: isQuestionDisabled(q.id)
                            ? theme.QuestionBtnDisable
                            : theme.QuestionBtn,
                          '&:hover': {
                            backgroundColor: isQuestionDisabled(q.id)
                              ? theme.QuestionBtnDisable
                              : theme.QuestionBtnHover,
                          },
                          '&.Mui-disabled': {
                            cursor: 'not-allowed !important',
                            pointerEvents: 'auto',
                          },
                          cursor: isQuestionDisabled(q.id)
                            ? 'not-allowed'
                            : 'pointer',
                          textTransform: 'none',
                          overflow: 'hidden',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            width: '100%',
                            fontSize: '1rem',
                            fontWeight: '400',
                            lineHeight: '1.25rem',
                            textAlign: 'left',
                            color: isQuestionDisabled(q.id)
                              ? theme.QuestionBtnDisableText
                              : theme.grayA700,
                            textTransform: 'none',
                            whiteSpace: 'normal',
                            // WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {q.question}
                        </Typography>
                      </Button>
                    ))}
                  </QuestionSection>
                ))
              )}
            </QuestionList>
          </ScrollableContent>

          {/* ----------------------------------AIA Input Section ----------------------------------------*/}
          <MessageInputContainer className={isInputFocused ? 'focused' : ''}>
            <TextField
              fullWidth
              size="small"
              placeholder={t('ask_AIA_desc')}
              inputRef={AIAref}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isTyping || isLoading ? (
                      <ButtonBase
                        onClick={handleStopBtn}
                        sx={{
                          width: '2.9rem',
                          height: '2rem',
                          padding: '0.5rem 0.5625rem 0.5rem 0.4375rem',
                          borderRadius: '0.5rem',
                          background: themes?.default?.backgroundAIABtn,
                        }}
                      >
                        <Icon
                          icon={<img src={square} alt="Pause" />}
                          size={20}
                        />
                      </ButtonBase>
                    ) : (
                      <ButtonBase
                        onClick={handleClickBtn}
                        sx={{
                          width: '2.9rem',
                          height: '2rem',
                          padding: '0.5rem 0.5625rem 0.5rem 0.4375rem',
                          borderRadius: '0.4rem',
                          background: isInputFocused
                            ? themes?.default?.backgroundAIABtn
                            : themes?.default?.greyDisabled,
                        }}
                      >
                        <Icon
                          icon={<img src={PaperPlane} alt="Send" />}
                          size={20}
                        />
                      </ButtonBase>
                    )}
                  </InputAdornment>
                ),
                sx: { height: '3rem', padding: '0 0.8rem 0 0.4rem' },
              }}
              sx={{
                '&.MuiFormControl-root': {
                  margin: '0 !important',
                },
                fieldset: {
                  border: `1px solid ${theme.gainsboro2}`,
                  borderRadius: '0.4rem',
                },
                input: {
                  fontFamily: 'Roboto',
                  fontSize: '0.81rem',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: theme.primaryDark,
                },
                'input::placeholder': {
                  fontFamily: 'Roboto',
                  fontSize: '0.81rem',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: theme.textColorDashboard,
                },
              }}
            />
          </MessageInputContainer>
        </Sidebar>
      </Grow>
    </>
  );
};
export default AskAIA;

const MessageInputContainer = styled.div`
  background: ${themes?.default?.accordionWhiteBg};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  height: 4rem;
  padding: 0.8rem 0.8rem;
  border-top: 1px solid ${themes?.default?.gainsboro2};
  position: relative;
  z-index: 1;
  overflow: hidden;
`;
