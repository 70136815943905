import { useGetOrganizationVisitorsQuery } from 'common/services/visitors';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Grid } from '@mui/material';
import { VisitorsFooter } from './components/VisitorsFooter';
import { VisitorsForm } from './components/VisitorsForm';
import { VisitorsInfo } from './components/VisitorsInfo';
import { themes } from 'styles/theme/themes';

export const VisitorsPublic = () => {
  const { organizationId } = useParams();
  const { data: organization } = useGetOrganizationVisitorsQuery(
    organizationId,
    { skip: !organizationId },
  );

  return (
    <>
      <GradientTopRight />
      <Box sx={{ padding: { xs: 2, md: 3 } }}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <VisitorsInfo organization={organization} />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <VisitorsForm organization={organization} />
          </Grid>

          <Grid item xs={12}>
            <VisitorsFooter />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const GradientTopRight = styled.div`
  width: 741px;
  height: 600px;
  position: absolute;
  top: -100px;
  right: 140px;
  z-index: -2;
  transform: rotate(-20.57deg);
  border-radius: 50%;
  filter: blur(150px);
  background: ${themes.default.pastelGlow};
`;
