import { Typography } from '@mui/material';
import { TextIconButton } from '../TextIconButton';
import { Icon } from 'app/components/Icon';
import { Plus } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

interface Props {
  onClick: () => void;
}

export const AddNoteButton = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <TextIconButton
      style={{
        marginTop: '16px',
        fontSize: '0.75rem',
        fontWeight: '400',
        padding: '2px 8px 2px 6px',
        color: themes.default.clientMarkerBg,
        borderRadius: '4px',
        background: themes.default.lightGrey,
        textTransform: 'none !important',
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.16)',
        '&:hover': {
          background: themes.default.lightGrey,
        },
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
      }}
      leftIcon={
        <Icon
          icon={<Plus />}
          size={12}
          style={{ marginRight: '6px' }}
          color={themes.default.clientMarkerBg}
        />
      }
      onClick={onClick}
    >
      <Typography
        fontWeight="400"
        fontSize="0.75rem"
        letterSpacing="0.46px"
        color={themes.default.clientMarkerBg}
        sx={{ marginTop: '2px', textTransform: 'none' }}
      >
        {t('order_details.order_notes.new_note')}
      </Typography>
    </TextIconButton>
  );
};
