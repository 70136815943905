import * as React from 'react';
import { Backdrop, Typography } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { useSelector } from 'react-redux';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';
import { LoadingPulse } from '../LoadingPulse';

export const LoadingIndicator = ({ text }: { text?: string }) => {
  const zoomedIn = useSelector(selectIsZoomedIn);

  return (
    <Backdrop
      invisible
      sx={{
        backgroundColor: `${
          text ? 'transparent' : themes.default.accordionWhiteBg
        } !important`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute',
        top: zoomedIn ? 0 : themes.default.topBarHeight,
      }}
      open
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <LoadingPulse color="primary" />
        {text && (
          <Typography
            sx={{ marginLeft: '-120px', marginTop: '10px' }}
            color="textSecondary"
          >
            {text}
          </Typography>
        )}
      </div>
    </Backdrop>
  );
};
