import React, { useState /*, useEffect*/ } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { Sparkle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@mui/material';
import AskAIA from '../../../AskAia';

interface Props {}

export const AskAIButton: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <ButtonBase
        // ref={buttonRef}
        onClick={toggleSidebar}
        sx={{
          borderRadius: '4px',
        }}
      >
        <Wrapper>
          <Icon
            className="sparkle"
            icon={<Sparkle weight="fill" />}
            color={themes?.default?.accordionWhiteBg}
            size={12}
          />
          <p>{t('ask_AI')}</p>
        </Wrapper>
      </ButtonBase>
      <AskAIA isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 72px;
  height: 30px;
  padding: 3px 6px;
  gap: 4px;
  border-radius: 4px;
  background: linear-gradient(
    55deg,
    #ff00ff -14.9%,
    #b01eff 10.07%,
    #1159ff 58.54%,
    #02afbb 92.41%
  );
  transition-duration: 0.3s;
  position: relative;
  user-select: none;
  p {
    margin: 0;
    font-size: 10px;
    font-weight: 500;
    line-height: 0.625rem;
    color: ${themes?.default?.accordionWhiteBg};
  }

  &:hover {
    cursor: pointer;

    // .sparkle {
    //   animation: 0.8s scaleUp forwards;
    // }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: radial-gradient(
      65% 65% at 50% 86.67%,
      rgba(255, 255, 255, 0.496) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover::before {
    opacity: 1;
  }

  // @keyframes scaleUp {
  //   0% {
  //     scale: 1;
  //     transform: translate(0, 0);
  //   }
  //   30% {
  //     scale: 1.08;
  //     transform: translate(-2px, 0);
  //   }
  //   70% {
  //     scale: 1.08;
  //     transform: translate(-2px, 0);
  //   }
  //   100% {
  //     scale: 1;
  //     transform: translate(0, 0);
  //   }
  // }
`;
