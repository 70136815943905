import { Alert, Grid, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { AcceptedFiles } from 'app/components/AcceptedFiles/Index';
import { useModal } from 'app/components/Dialog/hooks';
import If from 'app/components/If';
import { UploadedFile } from 'app/components/UploadComponent';
import { UploadZone } from 'app/components/UploadFiles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export function ImportTransactionFilesContent({ onImport, type }) {
  const [step, setStep] = useState<number>(1);
  const [files, setFiles] = useState<Array<File>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [responseValidation, setResponseValidation] = useState<any>([]);

  const { t } = useTranslation();

  const onFileChange = useCallback(async (newFiles: UploadedFile[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const handleFileDelete = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const { setBackAction, setActionButton, closeModal, setActionDisabled } =
    useModal();

  useEffect(() => {
    if (step === 1) {
      setActionButton({
        actionText: t('common.buttons.proceed'),
        buttonProps: { disabled: files.length < 1 },
        actionCallback() {
          setIsLoading(true);
          setActionDisabled(true);

          const importPromises = files.map((file) =>
            onImport(file)
              .then((res) => {
                setResponseValidation((prev) => [...prev, { success: res }]);
              })
              .catch((err) => {
                const validation = err?.data?.validation ?? err?.data?.message;
                setResponseValidation((prev) => [
                  ...prev,
                  { error: validation },
                ]);
              }),
          );

          Promise.all(importPromises)
            .then(() => {
              setIsLoading(false);
              setStep((prev) => prev + 1);
            })
            .catch(() => {
              setIsLoading(false);
            });
        },
      });
      setBackAction(undefined);
      setResponseValidation([]);
    } else {
      setActionButton({
        actionText: t('common.buttons.proceed'),
        actionCallback() {
          closeModal();
        },
      });
      setBackAction({
        actionCallback: () => {
          setResponseValidation([]);
          setStep((prev) => prev - 1);
        },
      });
    }
  }, [step, files, onImport]);

  const wrongFormatNameCount: number = useMemo(() => {
    return responseValidation?.reduce((count, res) => {
      return (
        count +
        (Array.isArray(res.error)
          ? res.error.filter((err) => err.message === 'file_name_not_allowed')
              .length
          : 0)
      );
    }, 0);
  }, [responseValidation]);

  const errorFileExistingCount: number = useMemo(() => {
    return responseValidation?.reduce((count, res) => {
      return count + (res.error === 'file_existing' ? 1 : 0);
    }, 0);
  }, [responseValidation]);

  const successCount: number = useMemo(() => {
    return responseValidation?.reduce((count, res) => {
      return count + (res.success ? 1 : 0);
    }, 0);
  }, [responseValidation]);

  return (
    <>
      <Stepper activeStep={step - 1}>
        <Step completed={step > 1}>
          <StepLabel>{t('transaction.upload.files')}</StepLabel>
        </Step>
        <Step completed={step > 2}>
          <StepLabel>{t('import.file.validation')}</StepLabel>
        </Step>
      </Stepper>

      {step === 1 && (
        <>
          <Stack mt="24px">
            <Alert severity="warning">
              {t('transactions.import.warning.alert')}
            </Alert>
          </Stack>

          <Stack mt="24px">
            <UploadZone
              type=""
              title=""
              description={t('transactions.import.alert')}
              onOk={onFileChange}
              onUnOk={() => {}}
              acceptFile={{
                'image/png': [],
                'image/jpg': ['.jpeg', '.jpg'],
                'application/pdf': ['.pdf'],
              }}
              isUploading={isLoading}
            />
            {files &&
              files?.map((file) => (
                <AcceptedFiles
                  file={file}
                  statusFile={{ [file.name]: true }}
                  hideProgressBar={{ [file.name]: true }}
                  fileProgress={{ [file.name]: true }}
                  deleteFile={() => handleFileDelete(file.name)}
                />
              ))}
          </Stack>
        </>
      )}

      {step === 2 && (
        <Stack mt="24px" gap="8px">
          <If condition={errorFileExistingCount > 0}>
            <Alert severity="warning">
              <Grid container alignItems="center" gap="4px">
                <Grid item>
                  <strong>
                    {errorFileExistingCount + ' ' + t('uploadFiles.files')}
                  </strong>
                </Grid>
                <Grid item>{t('transaction.upload.files.error')}</Grid>
              </Grid>
              <strong>
                <StyledSpan>
                  {t('transaction.import.upload.error-file-existing')}
                </StyledSpan>
              </strong>
            </Alert>
          </If>
          <If condition={successCount > 0}>
            <Alert severity="success">
              <Grid container alignItems="center" gap="4px">
                <Grid item>
                  <strong>{successCount + ' ' + t(`common.${type}`)}</strong>
                </Grid>
                <Grid item>{t('transaction.upload.files.success')}</Grid>
              </Grid>
            </Alert>
          </If>
          <If condition={wrongFormatNameCount > 0}>
            <Alert severity="warning">
              <Grid container alignItems="center" gap="4px">
                <Grid item>
                  <strong>
                    {wrongFormatNameCount + ' ' + t('uploadFiles.files')}
                  </strong>
                </Grid>
                <Grid item>{t('transaction.upload.files.error')}</Grid>
              </Grid>
              <strong>
                <StyledSpan>
                  {t('transaction.import.upload.wrong-format-name')}
                </StyledSpan>
              </strong>
            </Alert>
          </If>
        </Stack>
      )}
    </>
  );
}

const StyledSpan = styled.span`
  color: ${themes?.default?.alertBlackLabel};
`;
