import { ContractRowData } from 'app/pages/Customers/components/ViewCustomer/components/Tabs/ContractsTab/types';
import api from './api';
import {
  AddCategoriersPayload,
  AddCategoriersRes,
  UpdateContractDiscountPayload,
  DeleteContractPayload,
  EnableDisableContractPayload,
  ApproveContractPayload,
  AddDiscountNotePayload,
  DeleteDiscountNotePayload,
  EditDiscountNotePayload,
  UpdateContractDiscountRes,
  DeleteContractDiscountRes,
  EnableDisableContractRes,
  ApproveContractRes,
  DeleteDiscountNoteRes,
  EditDiscountNoteRes,
  AddDiscountNoteRes,
} from 'common/types/customerCategoryDiscounts';

export const customerCategoryDiscountApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllCustomerCategoryDiscounts: builder.query<
      { docs: ContractRowData[]; message: string },
      string
    >({
      query: urlParams => ({
        url: `/customer-category-discount${urlParams}`,
      }),
      providesTags: ['CategoryDiscounts'],
    }),

    addContracts: builder.mutation<AddCategoriersRes, AddCategoriersPayload>({
      query: ({ body }) => ({
        url: `/customer-category-discount`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [
        'CategoryDiscounts',
        'CustomerContractStats',
        'CategoryDiscountOptions',
      ],
    }),
    updateContractDiscount: builder.mutation<
      UpdateContractDiscountRes,
      UpdateContractDiscountPayload
    >({
      query: ({ body }) => ({
        url: `/customer-category-discount/discounts`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['CategoryDiscounts', 'CustomerContractStats'],
    }),
    deleteContract: builder.mutation<
      DeleteContractDiscountRes,
      DeleteContractPayload
    >({
      query: ({ _customer, ids }) => ({
        url: `/customer-category-discount/delete?_customer=${_customer}&${ids}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CategoryDiscounts', 'CustomerContractStats'],
    }),
    enableDisableContract: builder.mutation<
      EnableDisableContractRes,
      EnableDisableContractPayload
    >({
      query: ({ body }) => ({
        url: `/customer-category-discount/disable`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['CategoryDiscounts'],
    }),
    approveContract: builder.mutation<
      ApproveContractRes,
      ApproveContractPayload
    >({
      query: ({ body }) => ({
        url: `/customer-category-discount/approve`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['CategoryDiscounts', 'CustomerContractStats'],
    }),
    addDiscountNote: builder.mutation<
      AddDiscountNoteRes,
      AddDiscountNotePayload
    >({
      query: ({ discountId, body }) => ({
        url: `/customer-category-discount/${discountId}/notes/add`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['CategoryDiscounts'],
    }),
    deleteDiscountNote: builder.mutation<
      DeleteDiscountNoteRes,
      DeleteDiscountNotePayload
    >({
      query: ({ discountId, _customer, noteId }) => ({
        url: `/customer-category-discount/${discountId}/notes/delete?_customer=${_customer}&_note=${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CategoryDiscounts'],
    }),
    editDiscountNote: builder.mutation<
      EditDiscountNoteRes,
      EditDiscountNotePayload
    >({
      query: ({ discountId, body }) => ({
        url: `/customer-category-discount/${discountId}/notes/edit`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['CategoryDiscounts'],
    }),
  }),
});

export const {
  useGetAllCustomerCategoryDiscountsQuery,
  useAddContractsMutation,
  useUpdateContractDiscountMutation,
  useDeleteContractMutation,
  useEnableDisableContractMutation,
  useApproveContractMutation,
  useAddDiscountNoteMutation,
  useDeleteDiscountNoteMutation,
  useEditDiscountNoteMutation,
} = customerCategoryDiscountApi;

export default customerCategoryDiscountApi;
