import { Grid, Box, Typography, Chip } from '@mui/material';
import { selectAuthToken } from 'app/slices/auth/selectors';
import { useGetOrganizationVisitorsQuery } from 'common/services/visitors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export function VisitorsCode() {
  const [qrCode, setQrCode] = useState<string>('');

  const { organizationId } = useParams();
  const { data: organization } =
    useGetOrganizationVisitorsQuery(organizationId);

  const baseUrl =
    /* process.env.REACT_APP_API_URL || */ 'http://localhost:4444';

  const authToken = useSelector(selectAuthToken);

  const handleFetchQrCode = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/visitors/form/generateCode/${organizationId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      const blob = response.blob();
      const url = URL.createObjectURL(await blob);
      setQrCode(url);
    } catch (error) {
      console.error('Error fetching QR code:', error);
    }
  };

  useEffect(() => {
    handleFetchQrCode();
  }, []);

  return (
    <Wrapper>
      <GradientTopRight />
      <Grid
        container
        justifyContent="center"
        sx={{
          width: '100%',
          margin: { xs: '16px', sm: '24px', md: '32px' },
        }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              maxWidth: '563px',
              margin: 'auto',
              backgroundColor: themes.default.accordionWhiteBg,
              borderRadius: '12px',
              boxShadow: '0px 5px 30px 0px #8b8b8b40',
              padding: {
                xs: '21px 37px 33px 37px',
                sm: '21px 37px 33px 37px',
                md: '21px 37px 33px 37px',
              },
            }}
          >
            <Grid sx={{ marginBottom: '12px' }}>
              <Typography
                fontWeight={700}
                sx={{
                  textAlign: 'center',
                  fontSize: { xs: '28px', sm: '32px', md: '32px' },
                }}
              >
                Welcome to{' '}
                <span style={{ color: themes?.ordoria?.primary }}>
                  {organization?.name}
                </span>
              </Typography>
              <Typography
                fontWeight={400}
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: { xs: '14px', sm: '16px', md: '16px' },
                }}
              >
                To check-in or check-out, please scan the QR code{' '}
                <span style={{ display: 'block' }}>sent to your email.</span>
              </Typography>
            </Grid>

            <Grid
              justifyContent="center"
              sx={{
                width: '100%',
                maxWidth: { sm: '489px' },
                height: { xs: 'auto', sm: '401px', md: '401px' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '100%',
                  backgroundColor: 'grey.100',
                  borderRadius: '4px',
                  padding: { xs: '16px', sm: '20px', md: '20px' },
                }}
              >
                <Chip
                  label="Place the QR code in front of the camera"
                  sx={{
                    background: themes?.ordoria?.purpleBlueOverlay,
                    color: themes.default.accordionWhiteBg,
                    borderRadius: '8px',
                    height: '34px',
                    width: '258px',
                    fontWeight: 400,
                    fontSize: { xs: '11px', sm: '13px' },
                    textAlign: 'center',
                    marginBottom: { xs: '20px', sm: '20px', md: '20px' },
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {qrCode !== '' && (
                    <img
                      src={qrCode}
                      alt="Visitors QR Code"
                      style={{
                        width: '200px',
                        height: '200px',
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: calc(100% + ${(p) => p.theme.topBarHeight});
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: -${(p) => p.theme.topBarHeight};
  padding: 0 !important;
`;

const GradientTopRight = styled.div`
  width: 741px;
  height: 600px;
  position: absolute;
  top: -100px;
  right: 140px;
  z-index: -2;
  transform: rotate(-20.57deg);
  border-radius: 50%;
  filter: blur(150px);
  background: ${themes.default.pastelGlow};
`;
