import { Tooltip, IconButton, Stack, SxProps } from '@mui/material';
import {
  PencilSimple,
  CheckCircle,
  Eye,
  EyeSlash,
  Trash,
} from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { ContractRowStatus } from '../../types';
import { useHelpers } from '../../useHelpers';
import { useModal } from 'app/components/Dialog/hooks';
import { themes } from 'styles/theme/themes';
import { EditDiscountPopover } from '../EditDiscountPopover';

export const ContractsBulkActions = () => {
  /* ------------------------------------ Contexts ------------------------------------- */
  const { selectedRows, rowData, api } =
    useManagedContext<IGridContext>('grid');

  // const { customer } = useContext(ViewCustomerContext);

  /* ------------------------------------- Hooks --------------------------------------- */
  const { t } = useTranslation();
  const {
    handleApproveRows,
    handleContractToggle,
    handleDeleteContract,
    handleUpdateRowDiscounts,
    isAdmin,
  } = useHelpers();
  const { openModal, closeModal } = useModal();

  /* ----------------------------------- Use States ------------------------------------ */
  const [editDiscountsOpen, setEditDiscountsOpen] = useState(false);

  /* ------------------------------------ Use Memos ------------------------------------ */
  const fullSelectedRows = useMemo(() => {
    return rowData.filter((row) =>
      selectedRows.some((selectedRow) => selectedRow === row.id),
    );
  }, [rowData, selectedRows]);

  const approveDisabled = useMemo(() => {
    return fullSelectedRows.some(
      (selectedRow) => selectedRow.status === ContractRowStatus.APPROVED,
    );
  }, [fullSelectedRows]);

  const enableDisabled = useMemo(() => {
    return fullSelectedRows.some((selectedRow) => !selectedRow.name.disabled);
  }, [fullSelectedRows]);

  const disableDisabled = useMemo(() => {
    return fullSelectedRows.some((selectedRow) => selectedRow.name.disabled);
  }, [fullSelectedRows]);

  /* ------------------------------------ Functions ------------------------------------ */
  const handleConfirmToggleClick = useCallback(
    (disabled) => {
      closeModal();
      handleContractToggle({ disabled, selectedRows });
      api.clearSelection();
    },
    [closeModal, handleContractToggle, api, selectedRows],
  );

  const handleDisableClick = useCallback(() => {
    if (disableDisabled) return;
    openModal({
      title: t('title.action_discount', { action: t('buttons.disable') }),
      content: (
        <>
          {t('description.action_discount', {
            action: t('buttons.disable').toLowerCase(),
          })}
        </>
      ),
      action: {
        actionText: t('buttons.disable'),
        actionCallback: () => handleConfirmToggleClick(true),
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      hasCloseIcon: true,
      cancel: true,
    });
  }, [disableDisabled, handleConfirmToggleClick, openModal, t]);

  const handleEnableClick = useCallback(() => {
    if (enableDisabled) return;
    openModal({
      title: t('title.action_discount', { action: t('buttons.enable') }),
      content: (
        <>
          {t('description.action_discount', {
            action: t('buttons.enable').toLowerCase(),
          })}
        </>
      ),
      action: {
        actionText: t('buttons.enable'),
        actionCallback: () => handleConfirmToggleClick(false),
        buttonProps: {
          sx: { background: themes?.default?.primary },
        },
      },
      hasCloseIcon: true,
      cancel: true,
    });
  }, [enableDisabled, handleConfirmToggleClick, openModal, t]);

  const handleDeleteConfirm = useCallback(
    (selectedRows: string[]) => {
      closeModal();
      handleDeleteContract(selectedRows);
      api.clearSelection();
    },
    [closeModal, handleDeleteContract, api],
  );

  const handleDeleteClick = useCallback(() => {
    openModal({
      title: t('title.action_discount', { action: t('common.remove') }),
      content: <>{t('message.remove_discount')}</>,
      action: {
        actionText: t('common.remove'),
        actionCallback: () => handleDeleteConfirm(selectedRows),
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      hasCloseIcon: true,
      cancel: true,
    });
  }, [handleDeleteConfirm, openModal, selectedRows, t]);

  const handleEditDiscountModalClick = useCallback(() => {
    setEditDiscountsOpen(true);
  }, []);

  const handleCloseEditDiscountModal = useCallback(() => {
    setEditDiscountsOpen(false);
  }, []);

  const handleSubmitUpdateDiscounts = useCallback(
    async (discounts) => {
      await handleUpdateRowDiscounts(
        fullSelectedRows?.map((fullSelectedRow) => ({
          _category: fullSelectedRow?.name?._category?._id,
          discounts: discounts,
        })),
      );
      api.clearSelection();
    },
    [api, handleUpdateRowDiscounts, fullSelectedRows],
  );

  /* ----------------------------------------------------------------------------------- */

  return (
    <>
      <Stack flexDirection="row" gap="16px">
        <ActionButton
          title={t('edit')}
          icon={<PencilSimple />}
          onClick={() => handleEditDiscountModalClick()}
        />
        {isAdmin() && (
          <ActionButton
            title={t('approve')}
            icon={<CheckCircle />}
            onClick={() => handleApproveRows(selectedRows)}
            disabled={approveDisabled}
          />
        )}
        <ActionButton
          title={t('common.enable')}
          icon={<Eye />}
          onClick={() => handleEnableClick()}
          disabled={enableDisabled}
        />
        <ActionButton
          title={t('common.disable')}
          icon={<EyeSlash />}
          onClick={() => handleDisableClick()}
          disabled={disableDisabled}
        />
        <ActionButton
          title={t('common.remove')}
          icon={<Trash />}
          onClick={() => handleDeleteClick()}
        />
      </Stack>
      <EditDiscountPopover
        open={editDiscountsOpen}
        onClose={handleCloseEditDiscountModal}
        onSubmit={handleSubmitUpdateDiscounts}
        showLimitAlert={!isAdmin()}
        canEditLockedFields={isAdmin()}
      />
    </>
  );
};

const IconButtonSx: SxProps = {
  padding: '4px',
  transitionDuration: '0.15s',
  ':active': {
    opacity: '0.7',
  },
};

interface ActionButtonProps {
  title: string;
  icon: JSX.Element;
  onClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
}

const ActionButton = ({
  title,
  icon,
  onClick,
  disabled,
}: ActionButtonProps) => {
  const handleClick = useCallback(
    (e) => {
      if (disabled) return;
      onClick(e);
    },
    [disabled, onClick],
  );

  return (
    <Tooltip title={title}>
      <IconButton
        onClick={handleClick}
        sx={{ ...IconButtonSx, opacity: disabled ? 0.5 : 1 }}
        disabled={disabled}
      >
        <Icon icon={icon} />
      </IconButton>
    </Tooltip>
  );
};
