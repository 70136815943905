import { Box, Button, Typography, Collapse, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import If from 'app/components/If';
import styled from 'styled-components';
import { IconButton } from '../../../components/Dialog/styles/index';
import { X } from '@phosphor-icons/react';
import { Selector } from '../Catalog/components/Category/Selector';
import { CartItem } from './components/CartItem';
import { themes } from 'styles/theme/themes';
import { DeleteFromCartDialog } from './components/DeleteBtn';
import { EmptyCartLayout } from './components/EmptyCartLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeAllProductSelection,
  changeCartVisibilityState,
  setNewSelectedProduct,
} from '../../../../common/store/cart';
import { useTranslation } from 'react-i18next';
import { useGetCartQuery } from 'common/services/cartApi';
import { hashObject } from 'common/utils/hashObject';
import {
  getCartVisibility,
  getCountOfSelectedProducts,
  getProductSelectionState,
} from 'common/store/cart/selectors';
import { Icon } from 'app/components/Icon';
import useFormatCurrency from 'common/hooks/useFormatCurrency';
import { ORDORIA_CATALOG } from '../routes';
import { useLocation } from 'react-router-dom';
import { AppDrawer } from 'app/components/AppDrawer';
import { useDebounce } from 'common/hooks/useDebounce';

export const Cart = ({ setOpenCreateOrderDrawer, setCartSelectedProducts }) => {
  const cartIsVisible = useSelector(getCartVisibility);
  const debouncedCartIsVisible = useDebounce(cartIsVisible, 300);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const location = useLocation();

  const isDrawer = useMemo(() => {
    dispatch(changeCartVisibilityState(false));
    return !location.pathname.includes(ORDORIA_CATALOG);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <If
      condition={isDrawer && (debouncedCartIsVisible || cartIsVisible)}
      otherwise={
        <Collapse in={cartIsVisible} orientation="horizontal" timeout={300}>
          <CartContent
            setOpenCreateOrderDrawer={setOpenCreateOrderDrawer}
            setCartSelectedProducts={setCartSelectedProducts}
          />
        </Collapse>
      }
    >
      <AppDrawer
        name="cart"
        open={cartIsVisible}
        onClose={() => dispatch(changeCartVisibilityState(false))}
        toolbarTitle={t('cart.text.title')}
        sx={{ maxWidth: 'fit-content' }}
      >
        <CartContent
          noHeader
          setOpenCreateOrderDrawer={setOpenCreateOrderDrawer}
          setCartSelectedProducts={setCartSelectedProducts}
        />
      </AppDrawer>
    </If>
  );
};

interface Props {
  noHeader?: boolean;
  setOpenCreateOrderDrawer: (value) => void;
  setCartSelectedProducts: (value) => void;
}

export const CartContent = ({
  noHeader = false,
  setOpenCreateOrderDrawer = () => {},
  setCartSelectedProducts = () => {},
}: Props) => {
  const { data: cart } = useGetCartQuery();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productSelectionState = useSelector(getProductSelectionState);
  const depsOfCartObj = hashObject(cart || {});
  const countOfSelectedProducts = useSelector(getCountOfSelectedProducts);

  const cartTotalPrice = useMemo(() => {
    if (!cart) return 0;
    if (productSelectionState.allSelected) {
      return cart.products.reduce((total, item) => {
        // TODO: remove bkData from here @abd
        const boxPrice = item?._id?.bkData?.box_price || 0;
        const quantity = item?.quantity || 0;
        const discount = item?._id?.discount || 0;
        const discountedPrice = boxPrice * (1 - discount / 100);
        return total + discountedPrice * quantity;
      }, 0);
    } else {
      const productKeysArray = Object.entries(productSelectionState.products)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([key, value]) => !!value === true)
        .map(([key]) => key);

      const products = cart.products.filter((prod) =>
        productKeysArray.some((id) => id === prod._id._id),
      );
      return products.reduce((total, item) => {
        // TODO: remove bkData from here @abd
        const boxPrice = item?._id?.bkData?.box_price || 0;
        const quantity = item?.quantity || 0;
        const discount = item?._id?.discount || 0;
        const discountedPrice = boxPrice * (1 - discount / 100);
        return total + discountedPrice * quantity;
      }, 0);
    }
  }, [depsOfCartObj, productSelectionState]);

  useEffect(() => {
    if (cart && productSelectionState.allSelected) {
      cart.products.forEach((product) => {
        dispatch(setNewSelectedProduct({ productId: product._id._id }));
      });
    }
  }, [
    cart,
    cart?.products,
    depsOfCartObj,
    dispatch,
    productSelectionState.allSelected,
  ]);

  useEffect(() => {
    if (cart?.products) {
      const productKeysArray = Object.entries(productSelectionState.products)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([key, value]) => !!value === true)
        .map(([key]) => key);

      const selectedProducts = cart.products.filter((prod) =>
        productKeysArray.some((id) => id === prod._id._id),
      );

      const products = selectedProducts.map((product) => ({
        _product: product._id?._id,
        quantity: product.quantity,
        discount: product._id?.discount,
      }));

      setCartSelectedProducts(products);
    }
  }, [cart, productSelectionState.products]);

  const handleOpenCreateOrder = () => {
    setOpenCreateOrderDrawer(true);
    if (noHeader) {
      /**
       * if noHeader === true then cart is a drawer so we close the it
       * otherwise we are in catalog so no need to close the cart drawer
       */
      dispatch(changeCartVisibilityState(false));
    }
  };

  return (
    <Wrapper>
      <If
        condition={!!cart?.products?.length || false}
        otherwise={<EmptyCartLayout />}
      >
        <CartHeader>
          <If condition={!noHeader}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              height="20px"
              alignItems="center"
            >
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: '500',
                }}
              >
                {t('cart.text.title')}
              </Typography>
              <IconButton
                onClick={() => dispatch(changeCartVisibilityState(false))}
                sx={{
                  padding: '0',
                }}
              >
                <Icon icon={<X />} size={16} />
              </IconButton>
            </Stack>
          </If>

          <BulkActionsWrapper>
            <Stack
              flexDirection="row"
              alignItems="center"
              height="24px"
              gap="8px"
            >
              <Selector
                parent="item"
                action={() => dispatch(changeAllProductSelection())}
                active={productSelectionState.allSelected}
                totalItems={cart?.products?.length || 0}
                selectedItems={countOfSelectedProducts}
                styles={{
                  border:
                    countOfSelectedProducts === 0
                      ? `1px solid ${themes?.default?.silver}`
                      : '',
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: themes?.default?.textBlack,
                  fontSize: '0.75rem',
                  fontWeight: '500',
                  textTransform: 'uppercase',
                }}
              >
                <If
                  condition={
                    productSelectionState.allSelected ||
                    cart?.products?.length === countOfSelectedProducts
                  }
                  otherwise={
                    <>
                      {t('cart.text.selected', {
                        count: countOfSelectedProducts,
                      })}
                    </>
                  }
                >
                  <>{t('common.all_selected')}</>
                </If>
              </Typography>
            </Stack>
            <DeleteFromCartDialog />
          </BulkActionsWrapper>
        </CartHeader>

        <CartBody>
          {cart?.products.map((product) => (
            <CartItem content={product} key={product?._id?._id} />
          ))}
        </CartBody>

        <CartFooter>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            minHeight="36px"
          >
            <Typography
              fontSize="0.875rem"
              fontWeight="500"
              color={themes?.default?.textColorPrimary}
            >
              {t('common.total')}
            </Typography>
            <Typography
              fontSize="1rem"
              fontWeight="500"
              color={themes?.default?.textColorPrimary}
            >
              {formatCurrency(cartTotalPrice)}
            </Typography>
          </Stack>
          <Button
            sx={{
              width: '100%',
              padding: '6px 16px',
              border: `1px solid ${themes.ordoria.primary}`,
              color: themes?.default?.baseWhite,
            }}
            variant="contained"
            onClick={() => handleOpenCreateOrder()}
          >
            {t('cart.button.create_order')}
          </Button>
        </CartFooter>
      </If>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  position: relative;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 100;
  border-left: solid 1px ${themes.ordoria.lightGrey3};
  padding: 12px 0px;
  gap: 12px;
`;

const CartHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 20px;
`;

const BulkActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
`;

const CartBody = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 20px;
  overflow-y: auto;
`;

const CartFooter = styled(Box)`
  position: sticky;
  width: 100%;
  bottom: 0;
  border-top: 1px solid ${themes.ordoria.lightGrey3};
  background-color: white;
  padding: 0px 16px 4px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
