import { CustomCellRendererProps } from '@ag-grid-community/react';
import { useTranslation } from 'react-i18next';
import { ActionComponent } from 'app/components/ManagedGrid/components/ActionComponent';
import { useMemo, useContext } from 'react';
import { ActionProps } from 'app/components/CustomMenu/types';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { OfferContext } from '../../OffersGrid/Components/OffersList';
import { useHelpers } from '../useHelpers';

export const ActionsCell = (params: CustomCellRendererProps) => {
  const { t } = useTranslation();

  const can = usePermission('customers');
  const canEdit = can(Permission.EDIT);
  const { setSelectedOffer } = useContext(OfferContext);
  const { deleteOffer, enableDisableOffer } = useHelpers();

  const actions = useMemo(() => {
    const actionsList: ActionProps[] = [];
    if (canEdit) {
      actionsList.push({
        element: t('common.edit'),
        onClick: async () => {
          await setSelectedOffer(params.value.cellData);
        },
      });
      actionsList.push({
        element: params.value.cellData?.isActive
          ? t('common.disable')
          : t('common.enable'),
        onClick: () =>
          enableDisableOffer(
            [params.value.cellData?._id],
            params.value.cellData?.isActive,
          ),
      });
      actionsList.push({
        element: t('common.remove'),
        onClick: () => {
          deleteOffer([params.value.cellData?._id]);
        },
        withSeparator: true,
        danger: true,
      });
    }
    return actionsList;
  }, [params]);

  if (!canEdit) return null;

  return <ActionComponent actions={actions} />;
};
