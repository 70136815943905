import { DrawerComponent } from 'app/components/DrawerComponent';
import { useTranslation } from 'react-i18next';
import { RefundForm } from './RefundForm';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from 'app/components/ManagedGrid/types';

interface RefundDrawerProps {
  open: boolean;
  handleClose: () => void;
  customer?: any;
  order?: any;
}

export function RefundDrawer({ open, handleClose }: RefundDrawerProps) {
  const { t } = useTranslation();

  const gridContext = useManagedContext<IGridContext>('grid');
  const isEdit = !!gridContext.selectedRow;

  return (
    <DrawerComponent
      title={
        isEdit
          ? t('transaction.refund.edit.title')
          : t('transaction.refund.add.title')
      }
      open={open}
      handleClose={handleClose}
    >
      <RefundForm handleClose={handleClose} />
    </DrawerComponent>
  );
}
