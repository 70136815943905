import { CustomCellRendererProps } from '@ag-grid-community/react';
import { themes } from 'styles/theme/themes';
import { MorePopup } from 'app/components/MorePopup';
import { useMemo } from 'react';
import { Stack, Chip } from '@mui/material';
import EllipsisText from 'app/components/EllipsisText';
import { useTranslation } from 'react-i18next';

export const AppliedOnCell = (params: CustomCellRendererProps) => {
  const conditions = params?.value?.cellData;
  const { t } = useTranslation();

  const [firstItem, secondItem, ...otherItems] = conditions._items;
  const displayItems = [firstItem, secondItem];
  const remainingItems = otherItems; // Remaining items
  const moreItems = useMemo(() => {
    return remainingItems.map((item) => {
      return item?.name;
    });
  }, [remainingItems]);

  return (
    <Stack direction="row" gap="3px">
      {conditions.type !== 'anything' ? (
        <>
          {displayItems.map((item) => (
            <>
              {item?.name && (
                <Chip
                  label={<EllipsisText width={90} text={item?.name} />}
                  size="small"
                  sx={{ marginTop: '8px' }}
                />
              )}
            </>
          ))}

          <MorePopup
            more=""
            list={moreItems}
            chipProps={{
              sx: {
                color: themes.default?.primaryActiveColor,
                background: 'transparent',
                '&:hover': {
                  background: 'transparent',
                },
              },
            }}
          />
        </>
      ) : (
        t('common.anything')
      )}
    </Stack>
  );
};
