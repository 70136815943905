import { AgGridReact } from '@ag-grid-community/react';
import { selectAuthUser } from 'app/slices/auth/selectors';
import {
  useChangeStatusMutation,
  usePatchConversationMutation,
} from 'common/services/conversationApi';
import { useUploadMutation } from 'common/services/files';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';

export const ClaimsContext = createContext({
  openDetail: false,
  setOpenDetail: (_openDetail: boolean) => {},
  selectedClaimId: '',
  setSelectedClaimId: (_selectedClaimId: string) => {},
  handleUploadFile: async (
    _file: File,
    _objectType: string,
    _objectId: string,
  ): Promise<string> => {
    return '';
  },
  changeClaimStatus: (_status: string, _claimId: string) => {},
  updateArchiveClaim: (_archiveState: boolean, _claimId: string) => {},
  isUserCustomer: false,
  selectedRow: null,
  setSelectedRow: (_selectedRow: any) => {},
  refs: [] as AgGridReact[],
  setRefs: (_refs: AgGridReact[]) => {},
});

export const ClaimsProvider = ({ children }) => {
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [selectedClaimId, setSelectedClaimId] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [refs, setRefs] = useState<AgGridReact[]>([]);

  const [uploadFiles] = useUploadMutation();
  const [patchConversation] = usePatchConversationMutation();
  const [changeStatus] = useChangeStatusMutation();

  const authUser = useSelector(selectAuthUser);
  const isUserCustomer = authUser?.currentAgent?._role?.key === 'customer';

  async function handleUploadFile(
    file: File,
    objectType: string,
    _objectId: string,
  ): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await uploadFiles({
      objectType: objectType,
      objectId: _objectId,
      formData: formData,
      persist: true,
      preserveName: false,
    }).unwrap();
    return response?._id;
  }

  function changeClaimStatus(status: string, claimId: string) {
    changeStatus({
      conversationId: claimId,
      body: {
        status: status,
      },
    });
  }

  function updateArchiveClaim(archiveState: boolean, claimId: string) {
    patchConversation({
      conversationId: claimId,
      body: {
        isArchived: archiveState,
      },
    });
  }

  return (
    <ClaimsContext.Provider
      value={{
        openDetail,
        setOpenDetail,
        selectedClaimId,
        setSelectedClaimId,
        handleUploadFile,
        changeClaimStatus,
        updateArchiveClaim,
        isUserCustomer,
        selectedRow,
        setSelectedRow,
        refs,
        setRefs,
      }}
    >
      {children}
    </ClaimsContext.Provider>
  );
};
