import React, { useState, useEffect } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import { useTheme } from 'common/hooks/useTheme';
import { useAsyncData } from 'hooks/useAsyncData';
import If from '../If';
import { Tag } from 'common/types/Tag';

interface DynamicAutocompleteProps {
  disabled?: boolean;
  label: string;
  value?: string;
  onChange?: (option: string | null) => void;
  addOption: (label: string, color?: string) => void;
  defaultOptions?: (string | Tag)[];
  mapOption: (doc: any) => string;
  textFieldProps?: TextFieldProps;
  autocompleteProps?: AutocompleteProps<
    any,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >;
}
const DynamicAutocomplete: React.FC<DynamicAutocompleteProps> = ({
  label,
  value,
  onChange,
  addOption,
  defaultOptions = [],
  mapOption,
  textFieldProps,
  autocompleteProps,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [options, setOptions] = useState<(string | Tag)[]>(defaultOptions);
  const [inputValue, setInputValue] = useState(value || '');

  const { callApi: addNew, loading } = useAsyncData(
    async () => await addOption(inputValue?.trim()),
    {
      onSuccess: (response: any) => {
        const mappedOption = mapOption(response);
        setOptions([...options, mappedOption]);
        onChange?.(mappedOption);
      },
    },
  );

  useEffect(() => {
    if (value) {
      setInputValue(value);
    } else {
      setInputValue('');
    }
  }, [value]);

  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string,
  ) => {
    setInputValue(newInputValue);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string | null,
  ) => {
    onChange?.(newValue);
  };

  return (
    <Autocomplete
      {...autocompleteProps}
      disabled={disabled}
      value={value || ''}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(label)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          {...textFieldProps}
        />
      )}
      noOptionsText={
        <>
          <Typography fontSize="0.625rem">
            {t('autocomplete.no_results')}
          </Typography>
          <If condition={!!inputValue?.trim()}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              paddingTop="16px"
            >
              <Button
                size="small"
                onClick={addNew}
                variant="contained"
                color="secondary"
                sx={{
                  backgroundColor: grey[600],
                  padding: '2px 6px',
                  borderRadius: '4px',
                  textTransform: 'none',
                  color: 'white',
                }}
                disabled={!!loading || !inputValue?.trim()}
              >
                {`+ ${inputValue}`}
              </Button>

              <Typography fontSize="0.625rem" color={theme.clientMarkerBg}>
                {t('add_new_subject', { subject: t('role') })}
              </Typography>
            </Stack>
          </If>
        </>
      }
    />
  );
};

export default DynamicAutocomplete;
