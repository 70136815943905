import React, { useCallback } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';

// import { useNavigate } from 'react-router-dom';
// import { ORDERS } from 'utils/routes';
import { ActionProps } from 'app/components/CustomMenu/types';
import { useTranslation } from 'react-i18next';
import { Branch } from 'common/types/Supplier';
import { useTheme } from 'common/hooks/useTheme';
import { useDeleteSupplierBranchMutation } from 'common/services/supplierApi';
import { useModal } from 'app/components/Dialog/hooks';
import If from 'app/components/If';
import styled from 'styled-components';
import { Icon } from 'app/components/Icon';
import {
  CurrencyCircleDollar,
  DotsSixVertical,
  Note,
  ShoppingBagOpen,
  WarningCircle,
} from '@phosphor-icons/react';
import { ActionIconButton } from 'app/components/ActionIconButton';
import { NestedMenu } from 'app/components/CustomMenu/NestedMenu';

interface BranchRowProps {
  branch: Branch;
  setSelectedBranchId: (id: string | null) => void;
  setSelectedBranch: (branch: Branch | null) => void;
  getBranchDetails: (id: string) => void;
  supplierId?: string;
  setBranches: (branches: any) => void;
  isSelected: boolean;
}

export const BranchRow: React.FC<BranchRowProps> = ({
  branch,
  setSelectedBranchId,
  setSelectedBranch,
  getBranchDetails,
  supplierId,
  setBranches,
  isSelected,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  // const navigate = useNavigate();
  const { openModal, closeModal, setActionDisabled } = useModal();

  const [deleteBranch] = useDeleteSupplierBranchMutation();

  // const handlViewBranchOrders = (branchId: string) => {
  //   navigate(`${ORDERS}?branchFilter=${branchId}&supplierId=${supplierId}`);
  // };

  const handleDelete = useCallback(
    (supplierId: string, branchId: string) => {
      openModal({
        title: t(`client.deleteBranch`, {
          count: 1,
        }),
        content: t(`branch_delete_confirmation`),
        action: {
          actionText: t('delete'),
          actionCallback: async () => {
            try {
              setActionDisabled(true);
              await deleteBranch({
                supplierId: supplierId,
                branchId: branchId,
              }).unwrap();
              setActionDisabled(false);
              setBranches((prev) => prev.filter((b) => b.id !== branchId));
              if (isSelected) {
                setSelectedBranch(null);
                setSelectedBranchId(null);
              }
            } catch (e) {}
            closeModal();
          },
          buttonProps: {
            sx: { background: theme?.Cinnabar },
            color: 'error',
          },
        },
        cancel: true,
      });
    },
    [
      openModal,
      t,
      theme?.Cinnabar,
      isSelected,
      setBranches,
      closeModal,
      setActionDisabled,
      deleteBranch,
      setSelectedBranch,
      setSelectedBranchId,
    ],
  );

  const menuActions = useCallback(
    (branch: Branch) => {
      const actions: ActionProps[] = [
        {
          element: t('delete'),
          onClick: () => {
            if (!branch?.new) {
              if (!supplierId) return;
              handleDelete(supplierId, branch.id);
            } else {
              if (isSelected) {
                setSelectedBranch(null);
                setSelectedBranchId(null);
              }
              setBranches((prev) => prev.filter((b) => b.id !== branch.id));
            }
          },
          withSeparator: true,
          danger: true,
        },
      ];

      return actions;
    },
    [
      supplierId,
      handleDelete,
      isSelected,
      setBranches,
      setSelectedBranch,
      setSelectedBranchId,
      t,
    ],
  );

  return (
    <If
      condition={!branch.new}
      otherwise={
        <Row
          direction="row"
          key={branch.index}
          selected={isSelected}
          isNew={true}
          onClick={() => {
            setSelectedBranchId(branch?.id);
            setSelectedBranch(branch);
          }}
          sx={{
            borderRadius: '4px !important',
          }}
        >
          <Typography color={theme.clientMarkerBg} sx={{ textWrap: 'noWrap' }}>
            {branch.shortName}
          </Typography>

          <Icon icon={<WarningCircle />} color={theme.redStatus} />

          <Stack
            direction="row"
            alignItems="center"
            gap="0px"
            className="row-actions"
            sx={{ marginLeft: 'auto' }}
          >
            <Box onClick={(e) => e.stopPropagation()}>
              <NestedMenu
                actions={[
                  {
                    element: 'Delete',
                    onClick: () => {
                      if (isSelected) {
                        setSelectedBranch(null);
                        setSelectedBranchId(null);
                      }
                      setBranches((prev) =>
                        prev.filter((b) => b.id !== branch.id),
                      );
                    },
                    withSeparator: true,
                    danger: true,
                  },
                ]}
                variant="rounded"
                menuProps={{
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              />
            </Box>
          </Stack>
        </Row>
      }
    >
      <Draggable
        key={branch.id}
        draggableId={`draggable-branch-${branch.id}`}
        index={branch.index}
        isDragDisabled={!branch?.id}
      >
        {(provided) => (
          <Row
            direction="row"
            key={branch.id}
            selected={isSelected}
            onClick={() => {
              getBranchDetails(branch?.id);
            }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
            }}
            sx={{
              borderRadius: '4px !important',
            }}
          >
            <Box className="drag-handle" {...provided.dragHandleProps}>
              <Icon icon={<DotsSixVertical />} />
            </Box>

            <Stack direction="row" className="row-text" gap="8px">
              <Typography
                fontWeight={isSelected ? 900 : 500}
                sx={{ textWrap: 'noWrap' }}
              >
                {branch.shortName}
              </Typography>
              <Typography
                color={
                  isSelected ? theme.strokePrimary : theme.textColorSecondary
                }
                sx={{ textWrap: 'noWrap' }}
              >
                {branch.code}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              gap="0px"
              className="row-actions"
              sx={{ marginLeft: 'auto' }}
            >
              <ActionIconButton
                title="view_branch_orders"
                onClick={() => {}}
                // onClick={() => handlViewBranchOrders(branch.id)}
                icon={<ShoppingBagOpen />}
                buttonProps={{
                  disableRipple: true,
                  sx: { cursor: 'not-allowed' },
                }}
              />

              <ActionIconButton
                title=""
                onClick={(e) => e.stopPropagation()}
                icon={<Note />}
                buttonProps={{
                  disableRipple: true,
                  sx: { cursor: 'not-allowed' },
                }}
              />

              <ActionIconButton
                title=""
                onClick={(e) => e.stopPropagation()}
                icon={<CurrencyCircleDollar />}
                buttonProps={{
                  disableRipple: true,
                  sx: { cursor: 'not-allowed' },
                }}
              />

              <Box onClick={(e) => e.stopPropagation()}>
                <NestedMenu
                  actions={menuActions(branch)}
                  variant="rounded"
                  menuProps={{
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                />
              </Box>
            </Stack>
          </Row>
        )}
      </Draggable>
    </If>
  );
};

const Row = styled(Stack)<{ selected?: boolean; isNew?: boolean }>`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: ${({ theme, selected }) => (selected ? 'white' : theme.grey)};
  padding: 8px 12px;
  border: 1px solid
    ${({ theme, selected, isNew }) =>
      isNew && !selected
        ? theme.redStatus
        : selected
          ? theme.primaryActiveColor
          : theme.grey400};
  font-size: 0.75rem;
  color: ${({ theme, selected }) =>
    selected ? theme.primaryActiveColor : theme.textColorPrimary};
  cursor: pointer;
  height: 36px;
  position: relative;

  .drag-handle,
  .row-actions {
    position: absolute;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  .drag-handle {
    left: 12px;
  }

  .row-actions {
    right: 12px;
  }

  .row-text {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    .drag-handle,
    .row-actions {
      opacity: 1;
    }

    .row-text {
      transform: translateX(24px);
    }
  }
`;
