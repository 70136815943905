import {
  Alert,
  Autocomplete,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Check } from '@phosphor-icons/react';
import If from 'app/components/If';
import { useTheme } from 'common/hooks/useTheme';
import {
  DOCUMENT_REJECT_REASONS,
  DocumentRejectReason,
} from 'common/utils/documentRejectReasons';
import { t } from 'i18next';
import styled from 'styled-components';

const RejectReason = ({
  selectedReason,
  setSelectedReason,
  customReason,
  setCustomReason,
  hoveredOption,
  setHoveredOption,
  type,
}) => {
  const theme = useTheme();
  return (
    <>
      <Alert
        severity="warning"
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 'fit-content',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      >
        {t('rejected_documents_are_final').toString()}
      </Alert>
      <Stack spacing={2} sx={{ marginTop: 2 }}>
        <Typography fontSize={14} fontWeight={400}>
          {t('reject_reason').toString() + ' ' + t(type) + ' document.'}
        </Typography>
        <Autocomplete
          options={DOCUMENT_REJECT_REASONS}
          getOptionLabel={(option) => t(option.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('common.reason').toString()}
              variant="outlined"
              placeholder="no option selected"
              required
            />
          )}
          renderOption={(props, option) => {
            const isHovered = hoveredOption === option.value;
            return (
              <OptionItem
                {...props}
                isOther={option.value === DocumentRejectReason.Other}
                selected={option.value === selectedReason.value}
                isHovered={isHovered}
                theme={theme}
                onMouseEnter={() => setHoveredOption(option.value)}
                onMouseLeave={() => setHoveredOption(null)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: 40,
                    minWidth: 0,
                  }}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginRight: 8,
                    }}
                  >
                    {t(option.value).toString()}
                  </div>
                  <If
                    condition={
                      option.value === selectedReason.value || isHovered
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 24,
                        width: 24,
                        flexShrink: 0,
                        backgroundColor: theme.primaryLight1,
                        borderRadius: '50%',
                      }}
                    >
                      <Check size={12} color={theme.primary} />
                    </div>
                  </If>
                </div>
              </OptionItem>
            );
          }}
          onChange={(event, value) => {
            setSelectedReason(value || { value: '', label: '' });
            if (value?.value !== DocumentRejectReason.Other) {
              setCustomReason('');
            }
          }}
          value={selectedReason}
          fullWidth
        />
        <If condition={selectedReason?.value === DocumentRejectReason.Other}>
          <TextField
            label={t(DocumentRejectReason.Other).toString()}
            variant="outlined"
            fullWidth
            multiline
            value={customReason}
            onChange={(event) => setCustomReason(event.target.value)}
            required
          />
        </If>
      </Stack>
    </>
  );
};

interface OptionItemProps {
  isOther: boolean;
  selected: boolean;
  isHovered: boolean;
}

const OptionItem = styled.li<OptionItemProps>`
  border-top: ${({ isOther }) => (isOther ? '1px solid #ccc' : 'none')};
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  justify-content: center;
  padding: 8px 16px;
  color: ${({ selected, theme }) => (selected ? theme.primary : '')};
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export default RejectReason;
