import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Stack, Pagination, Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import { useState } from 'react';
import React from 'react';
import {
  useGetPaymentsQuery,
  usePatchPaymentAccountingStatusMutation,
} from 'common/services/payments';
import { EnhancedTableToolbar } from '../../../components/EnhancedTableToolbar';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
import { TablePayments } from '../../Payments/components/TablePayments';
import { selectOrganization } from 'common/store/organization/selectors';

export function Transactions() {
  const navigate = useNavigate();
  const { status } = useParams<{ status: string }>();
  const authOrganization = useSelector(selectOrganization);

  // removed the setPage since it is never used
  // to resolve ES/TS error.
  const [page, setPage] = useState<number>(1);

  const [search, setSearch] = useState<string>('');
  const { data, refetch, isLoading, isFetching } = useGetPaymentsQuery(
    `?accountingStatus=${status}&page=${page}&limit=100`,
  );
  const [patchAccountingStatus] = usePatchPaymentAccountingStatusMutation();
  const isBianta = status === 'unprocessed' || status === 'processed';

  const tabs = isBianta
    ? ['unprocessed', 'processed']
    : ['imported', 'imported-processed'];
  const currentTabIndex = tabs.indexOf(status || 'imported');

  const handleTabChange = (e, tabIndex) => {
    const newStatus = tabs[tabIndex];

    if (isBianta) {
      if (newStatus) {
        navigate(`/customers/payments/${newStatus}`);
      } else {
        navigate(`/customers/payments/accounting/imported`);
      }
    } else if (newStatus) {
      navigate(`/ordoria/transactions/accounting/${newStatus}`);
    } else {
      navigate(`/ordoria/transactions/accounting/imported`);
    }
  };

  ///Toolbar Filtration states
  const [filters /*setFilters*/] = useState<{
    salesman: { value: string; label: string }[];
  }>({
    salesman: [],
  });

  const payments = data?.docs || [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { filterOn, handleOpenFilter, filtersCount, handleCloseFilter } =
    useFilterState({
      filters,
    });

  const handleSwitchStatus = async (payment) => {
    if (
      payment.accountingStatus === 'imported' ||
      payment.accountingStatus === 'imported-processed'
    ) {
      await patchAccountingStatus({
        id: payment._id,
        status:
          payment.accountingStatus === 'imported-processed'
            ? 'imported'
            : 'imported-processed',
      }).unwrap();
    } else {
      await patchAccountingStatus({
        id: payment._id,
        status:
          payment.accountingStatus === 'processed'
            ? 'unprocessed'
            : 'processed',
      }).unwrap();
    }

    refetch();
  };

  useEffect(() => {
    if (authOrganization?._id) {
      refetch();
    }
  }, [authOrganization?._id]);

  return (
    <MainWrapper>
      <ToolbarWrapper>
        <EnhancedTableToolbar
          title="nav.mainNav.payments"
          disabled={false}
          numSelected={0}
          search={search}
          setSearch={setSearch}
          tabMenuCenter={
            <Tabs
              sx={{ minHeight: '44px' }}
              value={currentTabIndex}
              onChange={handleTabChange}
              centered
            >
              <Tab label="A Traiter" sx={{ p: 0, minHeight: '44px' }} />
              <Tab label="Traités" sx={{ p: 0, minHeight: '44px' }} />
            </Tabs>
          }
        />
      </ToolbarWrapper>

      <Wrapper>
        <TablePayments
          isLoading={isLoading || isFetching}
          payments={payments}
          onSwitchStatus={handleSwitchStatus}
          status={status || 'imported'}
        />

        <Stack spacing={2}>
          <Pagination
            count={data?.totalPages || 0}
            onChange={(e, page) => setPage(page)}
          />
        </Stack>
      </Wrapper>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  width: 100%;
  display: block;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  max-width: 1400px;
`;

const ToolbarWrapper = styled.div`
  width: 100%;
  display: block;
`;
