import { Stack } from '@mui/material';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Grid3x3Outlined } from '@mui/icons-material';
import { Icon } from 'app/components/Icon';
import { Star } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import EllipsisText from 'app/components/EllipsisText';
import If from 'app/components/If';
import NoPhoto from 'assets/img/ORDORIA/Catalog/NoPhoto.svg';
import { Prohibit } from '@phosphor-icons/react';

export interface INameGridCell {
  name: string;
  onClick: () => void;
  isNew: boolean;
  pictureUrl: string;
  code: string;
  imageSize?: string;
  boxingUnits?: number;
  hasDisableIcon?: boolean;
  middleComonent?: JSX.Element;
  ellipsisWidth?: number;
}

export const NameGridCell = ({
  onClick,
  pictureUrl,
  code,
  name,
  isNew,
  imageSize = '35px',
  boxingUnits,
  hasDisableIcon,
  middleComonent,
  ellipsisWidth = 250,
}: INameGridCell) => {
  const { t } = useTranslation();
  return (
    <StyledStack onClick={onClick}>
      <ProductIconContainer hasItems={!!boxingUnits} onClick={onClick}>
        <img
          src={pictureUrl || NoPhoto}
          alt=""
          style={{
            height: imageSize,
            width: imageSize,
            objectFit: 'cover',
          }}
        />
      </ProductIconContainer>

      <Stack gap="2px">
        <Stack flexDirection="row" gap="8px" alignItems="center">
          <ItemCode>
            <span>
              <Icon
                icon={
                  <Grid3x3Outlined
                    sx={{
                      width: '16px',
                      color: `${themes?.default?.black}8A`,
                    }}
                  />
                }
              />
            </span>
            {code}
          </ItemCode>
          <If condition={isNew}>
            <NewChip>
              <Icon
                icon={<Star weight="fill" />}
                size={10}
                color={themes?.default?.accordionWhiteBg}
              />
              {t('new')}
            </NewChip>
          </If>
        </Stack>
        {middleComonent && middleComonent}
        <Stack sx={{ dislay: 'flex', flexDirection: 'row' }}>
          <ProdName>
            <EllipsisText width={ellipsisWidth} text={name} />
          </ProdName>
          {hasDisableIcon && (
            <StyledIcon size={12} weight="bold" color={themes.default.red800} />
          )}
        </Stack>
        {!!boxingUnits && (
          <ItemCode>{boxingUnits + ' ' + t('product.item_per_box')}`</ItemCode>
        )}
      </Stack>
    </StyledStack>
  );
};

const ProductIconContainer = styled.div<{ hasItems: boolean }>`
  margin-top: ${(props) => (props.hasItems ? '4px' : 'unset')};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  gap: 5px;
  img {
    height: 35px;
    width: 35px;
    object-fit: cover;
    border-radius: 6px;
    margin-top: -2px;
  }
`;

const StyledStack = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
`;

const ItemCode = styled.p`
  margin: 0;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 0.75rem;
  color: ${themes?.default?.textColorDashboard};
`;

const NewChip = styled.div`
  margin-left: 2px;
  height: 16px;
  padding: 2px 11px;
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 5px;
  background-color: ${themes?.default.ordoriaLight};
  font-size: 0.625rem;
  line-height: 10px;
  color: ${themes?.default?.accordionWhiteBg};
`;

const ProdName = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  color: ${themes?.default?.textBlack};
`;

const StyledIcon = styled(Prohibit)`
  margin-left: 2px;
`;
