import api from './api';
import { ValidationResponse } from '../types/User';
import {
  ICategoryRowDetails,
  ICategoryOption,
  ICategoryReorder,
} from 'common/types/Categories';

export const categoriesApi = api.injectEndpoints({
  endpoints: builder => ({
    getCategoryList: builder.query<ICategoryRowDetails[], string>({
      query: urlParams => ({
        url: `/product-categories${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Categories'],
    }),
    createCategory: builder.mutation<
      void,
      { name: string; _parentCategory: string | null }
    >({
      query: ({ name, _parentCategory }) => ({
        url: `/product-categories`,
        method: 'POST',
        body: { name: name, _parentCategory: _parentCategory },
      }),
      invalidatesTags: ['Categories'],
    }),
    renameCategory: builder.mutation<void, { id: string; newName: string }>({
      query: ({ id, newName }) => ({
        url: `/product-categories/${id}/rename`,
        method: 'PATCH',
        body: { name: newName },
      }),
    }),
    updateCategory: builder.mutation<void, { body; id }>({
      query: ({ body, id }) => ({
        url: `/product-categories/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Categories'],
    }),
    importCategoryValidate: builder.mutation<ValidationResponse, FormData>({
      query: body => ({
        url: '/product-categories/validate',
        method: 'PUT',
        body,
      }),
    }),
    categoryImport: builder.mutation<unknown, FormData>({
      query: body => ({
        url: '/product-categories/import',
        method: 'PUT',
        body,
      }),
    }),
    deleteCategory: builder.mutation<void, { id: string | null }>({
      query: ({ id }) => ({
        url: `/product-categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Categories'],
    }),
    getCategoryOptions: builder.query<
      ICategoryOption[],
      {
        _parentCategory?: string | null;
        level?: number;
        sort?: string;
        text?: string;
      } | void
    >({
      query: ({ _parentCategory, level, sort = '', text = '' } = {}) => ({
        url: `/product-categories/options?_parentCategory=${_parentCategory}&level=${level}&sort=${sort}&text=${text}`,
        method: 'GET',
      }),
      providesTags: ['Categories'],
    }),
    getMaxLevel: builder.query({
      query: () => ({
        url: '/product-categories/max-level',
        method: 'GET',
      }),
      providesTags: ['MaxLevel'],
    }),
    reorderCategory: builder.mutation<void, { body: ICategoryReorder[] }>({
      query: ({ body }) => ({
        url: `/product-categories/reorder`,
        method: 'PATCH',
        body: body,
      }),
    }),
    getCategoryDiscountsOptions: builder.query<
      ICategoryOption[],
      {
        _customer: string;
        level: number;
        search?: string;
      }
    >({
      query: ({ _customer, level, search = '' }) => ({
        url: `/product-categories/options/category-discounts?_customer=${_customer}&level=${level}&search=${search}`,
        method: 'GET',
      }),
      providesTags: ['Categories', 'CategoryDiscountOptions'],
    }),
  }),
});
export const {
  useGetCategoryListQuery,
  useCreateCategoryMutation,
  useRenameCategoryMutation,
  useImportCategoryValidateMutation,
  useUpdateCategoryMutation,
  useCategoryImportMutation,
  useDeleteCategoryMutation,
  useGetCategoryOptionsQuery,
  useGetMaxLevelQuery,
  useReorderCategoryMutation,
  useGetCategoryDiscountsOptionsQuery,
} = categoriesApi;
export default categoriesApi;
