import { IGridContext, ITabsContext } from 'app/components/ManagedGrid/types';
import { useDebounce } from 'common/hooks/useDebounce';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useQueryParams } from 'hooks/useQueryParams';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RefundsEmptyState from 'assets/img/ORDORIA/Transactions/refunds.svg';
import { BulkActionsToolbar } from './BulkActionsToolbar';
import { getProcessedRefunds } from './helpers';
import ManagedGrid from 'app/components/ManagedGrid';
import styled from 'styled-components';
import { TransactionsTab } from './config';
import { RefundDrawer } from './components/RefundDrawer';
import useRefunds from './hook/useRefunds';
import { RefundsFilter } from './components/RefundsFilter';
import { useImportTransactionFiles } from '../Invoices/components/ImportTransactionFiles';
import { useUploadRefundsMutation } from 'common/services/transactions';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';

export function RefundsGrid() {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext>('grid');
  const tabsContext = useManagedContext<ITabsContext>('tabs');

  const {
    queryParams: { page, rowsPerPage, search, filters },
  } = gridContext;

  const commonQueryParams = {
    page,
    limit: rowsPerPage,
    text: search || '',
    isArchived: filters?.archived || false,
    customerId: filters?.customerId?.map((customer) => customer?.value) || '',
    customerDeckId: filters?.customerDeckId?.map((deck) => deck?.value) || '',
    types: filters?.type?.map((type) => type?.value).join(',') || '',
  };

  const queryParams = useQueryParams(commonQueryParams);
  const { isCustomer } = useGetUserAcl();

  const debouncedQueryParams = useDebounce(queryParams, 200);

  const { refunds, isLoading, isFetching } = useRefunds(debouncedQueryParams);

  const noDataConfig = useMemo(
    () => ({
      icon: RefundsEmptyState,
      text: t('transactions.empty-state.refund.text'),
      subText: t('transactions.empty-state.refund'),
      textButton: t('transaction.refund.add.title'),
      onClick: () => gridContext.updateData('openDrawer', true),
    }),
    [gridContext.openDrawer, refunds?.docs],
  );

  const { open } = useImportTransactionFiles();
  const [uploadRefunds] = useUploadRefundsMutation();

  const toolbarConfig = useMemo(
    () => ({
      disabled: gridContext.disableToolBar,
      BulkActionsComponent: BulkActionsToolbar,
      FilterComponent: RefundsFilter,
      onAddClick: (e) => {
        gridContext.api.openToolbarMenu(e);
      },
      MenuOptions: [
        {
          label: 'Import',
          action: () =>
            open({
              onImport: (file) => {
                const formData = new FormData();
                formData.append('file', file);
                return uploadRefunds({ file: formData }).unwrap();
              },
              type: 'refund',
            }),
        },
      ],
    }),
    [gridContext],
  );

  useEffect(() => {
    if (!refunds) return;

    const processedData = getProcessedRefunds(refunds?.docs, isCustomer());

    gridContext.updateDataWithFunction((prev) => {
      prev.totalRows = refunds?.totalDocs || 0;
      prev.responseData = refunds?.docs;
      prev.loading = isLoading;
      prev.rowData = processedData!;
    });
  }, [refunds?.docs, isLoading, isFetching]);

  useEffect(() => {
    tabsContext.updateDataWithFunction((prev) => {
      prev.selectedTab = TransactionsTab.REFUND;
      prev.visibleTabs = [
        { label: t('common.bills'), value: TransactionsTab.BILLS },
        { label: t('common.refund'), value: TransactionsTab.REFUND },
        { label: t('common.payments'), value: TransactionsTab.PAYMENTS },
      ];
    });
  }, [queryParams, gridContext.viewMode]);

  useEffect(() => {
    gridContext.updateDataWithFunction((prev) => {
      if (prev.columnDefs?.length) {
        const archivedCol: any = prev.columnDefs.find(
          (column) => column.field === '__archived',
        );

        if (archivedCol) {
          archivedCol.hide = !filters.archived;
        }
      }
    });
  }, [gridContext, filters]);

  useEffect(() => {
    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters = {
        archived: false,
      };
    });
  }, []);

  return (
    <Wrapper>
      <ManagedGrid toolbarConfig={toolbarConfig} noDataConfig={noDataConfig} />
      <RefundDrawer
        open={gridContext.openDrawer}
        handleClose={() => {
          gridContext.updateDataWithFunction((prev) => {
            prev.openDrawer = false;
            prev.selectedRow = null;
            prev.extraData.currentRow = null;
          });
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 1;

  ag-pinned-left-cols-container .ag-row [col-id='__actionMenu'],
  .ag-center-cols-container .ag-row [col-id='__actionMenu'],
  .ag-pinned-right-cols-container .ag-row [col-id='__actionMenu'] {
    display: flex;
    justify-content: end;
    padding-left: 0px;
  }
`;
