import React, { useEffect, useMemo, useState } from 'react';
import emptyDocumentIcon from 'assets/img/documents/empty.svg';
import { IGridContext, IStatsConfig } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import ManagedGrid from 'app/components/ManagedGrid';
import FilterComponent from 'app/components/DocumentTabComponents/FilterComponent';
import DocumentDrawer from 'app/components/DocumentTabComponents/DocumentDrawer';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useSelector } from 'react-redux';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import {
  useGetAllDocumentsQuery,
  useGetFacetsAllDocumentsQuery,
} from 'common/services/userDocumentsApi';
import { useDocumentHelpers } from '../../helpers';

function FunctionalDocuments() {
  const gridContext = useManagedContext<IGridContext>('grid');
  const statsConfig = useManagedContext<IStatsConfig>('statsConfig');
  const authUser = useSelector(selectAuthUser);
  const can = usePermission('documents');
  const { getProcessedDocumentsFunction } = useDocumentHelpers();
  const [queryParams, setQueryParams] = useState(
    'iam-checker=true&page=1&limit=30&archived=false&text=&sort=',
  );
  const {
    data: documents,
    isFetching,
    isLoading,
  } = useGetAllDocumentsQuery(`${queryParams}`);
  const { data: facets, isFetching: fetchingFacet } =
    useGetFacetsAllDocumentsQuery(`${queryParams}`);

  const convertFiltersToQueryString = (queryParams) => {
    const query: string[] = [];
    const filters = queryParams.filters;

    query.push(`page=${queryParams.page || ''}`);
    query.push(`limit=${queryParams.rowsPerPage || 10}`);
    query.push(`sort=`);

    if (queryParams.status && queryParams.status.length > 0) {
      query.push(`status=["${queryParams.status}"]`);
    }

    if (queryParams.filters.type && queryParams.filters.type.length > 0) {
      const typeValues = filters.type.map((type) => type.value).join('","');
      query.push(`type=["${typeValues}"]`);
    }

    query.push(`isArchived=${filters.isArchived || false}`);
    query.push(`text=${queryParams.search}`);

    return query.join('&');
  };

  useEffect(() => {
    const queryString = convertFiltersToQueryString(gridContext.queryParams);
    setQueryParams(queryString);
  }, [gridContext.queryParams]);

  useEffect(() => {
    gridContext.updateData('disableToolBar', !can(Permission.VIEW));
    gridContext.updateData('canEdit', can(Permission.EDIT));
  }, [gridContext, authUser?._id]);

  useEffect(() => {
    if (isLoading || isFetching) {
      gridContext.updateDataWithFunction((prev) => {
        prev.loading = isFetching || fetchingFacet;
      });
      return;
    }
    gridContext.updateData('loadingStats', fetchingFacet);
    statsConfig.updateData('facet', facets);
  }, [fetchingFacet, facets, isLoading, isFetching, fetchingFacet]);

  useEffect(() => {
    gridContext.updateDataWithFunction((prev) => {
      prev.withStatsCards = !gridContext.queryParams?.filters?.isArchived;
    });
  }, [gridContext.queryParams?.filters?.isArchived]);

  useEffect(() => {
    if (isFetching || isLoading) {
      gridContext.updateDataWithFunction((prev) => {
        prev.loading = isFetching || isLoading;
        prev.title = 'Documents';
      });
      return;
    }

    const processedDocuments = getProcessedDocumentsFunction(documents);

    gridContext.updateDataWithFunction((prev) => {
      prev.loading = isFetching;
      prev.totalRows = documents?.totalDocs;
      prev.rowData = processedDocuments;
      prev.responseData = Array.isArray(documents) ? documents : [documents];
      prev.title = 'Documents';
    });
  }, [isFetching, documents?.docs?.length, isLoading]);

  const toolbarConfig = useMemo(
    () => ({
      disabled: gridContext.disableToolBar,
      onAddClick: () => {
        gridContext.updateDataWithFunction((prev) => {
          prev.openDrawer = true;
          prev.selectedRow = null;
          prev.handleReject = false;
        });
      },
      FilterComponent: FilterComponent,
    }),
    [gridContext],
  );

  const noDataConfig = useMemo(() => {
    return {
      icon: emptyDocumentIcon,
      onClick: () => {
        gridContext.updateData('openDrawer', true);
      },
      text: 'no_documents_added_yet',
      textButton: `add_document`,
      top: '50%',
      imgStyle: {
        marginBottom: -30,
      },
    };
  }, [gridContext.menuIsOpen]);

  return (
    <>
      <ManagedGrid toolbarConfig={toolbarConfig} noDataConfig={noDataConfig} />
      {gridContext.openDrawer && <DocumentDrawer />}
    </>
  );
}

export default FunctionalDocuments;
