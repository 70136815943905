import {
  discountIndices,
  DiscountName,
} from 'app/components/Restrictions/types/discounts.types';
import {
  ContractRowData,
  ContractTabs,
  CountAtLevels,
  levelTranslationKeys,
  UpdateCategoryDiscounts,
} from './types';
import {
  useApproveContractMutation,
  useEnableDisableContractMutation,
  useDeleteContractMutation,
  useUpdateContractDiscountMutation,
  useAddDiscountNoteMutation,
  useDeleteDiscountNoteMutation,
  useEditDiscountNoteMutation,
} from 'common/services/customerCategoryDiscountApi';
import { useGetCustomerContractFacetsQuery } from 'common/services/customerApi';

import { useStatusToaster } from 'hooks/useStatusToaster';
import { StatusToastVariant } from 'common/types/StatusToasterNotification';
import { useTranslation } from 'react-i18next';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';
import { useGetMaxLevelQuery } from 'common/services/categoriesApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useCallback } from 'react';
import { useToaster } from 'hooks/useToaster';
import { generateParamsString } from 'app/pages/Ordoria/cart/components/DeleteBtn';
import { FormData } from 'app/pages/DiscountGroups/Components/types';
import { ROLE_KEYS, StateSetter } from 'types';
import { useUpdateContractProductDiscountMutation } from 'common/services/productsDiscountsApi';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { checkUserRole } from 'common/helpers/acl';
import {
  IRestrictions,
  restrictionDefaults,
} from 'app/components/Restrictions/types/restrictions.types';

type ProcessItemsFunctionProps = {
  data: ContractRowData[];
  rowsEditedBySales: UpdateCategoryDiscounts[];
  setRowsEditedBySales: StateSetter<UpdateCategoryDiscounts[]>;
};

export const useHelpers = () => {
  const statusToaster = useStatusToaster();
  const toast = useToaster();
  const { callApi, loading: isLoading } = useAsyncDataV2();
  const { t } = useTranslation();
  const { id: customerId } = useParams();
  const authUser = useSelector(selectAuthUser);
  const can = usePermission('customers');

  const [updateContractDiscount] = useUpdateContractDiscountMutation();
  const [updateContractProductDiscount] =
    useUpdateContractProductDiscountMutation();

  function getProcessedItemsFunction({
    data,
    rowsEditedBySales,
    setRowsEditedBySales,
  }: ProcessItemsFunctionProps) {
    return data?.map((item) => {
      return {
        id: item._id,
        name: {
          name: item._category.name || '__',
          disabled: item.disabled,
          _category: item._category,
        },
        parentCategory: {
          _id: item._category?._parentCategory?._id || item._category._id,
          name: item._category?._parentCategory?.name || item._category.name,
          disabled: item.disabled,
        },
        ...discountIndices.reduce((all, i) => {
          const ri: DiscountName = `r${i}`;
          const v = {
            cellData: formatDiscountValue(
              rowsEditedBySales.find(
                (rowSales) => rowSales._category === item?._category?._id,
              )?.discounts?.[ri] ||
                item?.discounts?.[ri] ||
                0,
            ),
            id: item._id,
            field: ri,
            onAction: async (discount) => {
              if (isAdmin()) {
                await handleUpdateRowDiscounts([
                  {
                    _category: item._category._id,
                    discounts: { [ri]: discount / 100 },
                  },
                ]);
              } else if (setRowsEditedBySales) {
                setRowsEditedBySales((prev) => {
                  if (
                    prev.some(
                      (editedCategory) =>
                        editedCategory._category === item._category._id,
                    )
                  ) {
                    return prev.map((editedCategory) => {
                      if (editedCategory._category === item._category._id) {
                        return {
                          ...editedCategory,
                          discounts: {
                            ...editedCategory.discounts,
                            [ri]: discount / 100,
                          },
                        } as UpdateCategoryDiscounts;
                      } else {
                        return editedCategory;
                      }
                    });
                  } else {
                    return [
                      ...prev,
                      {
                        _category: item._category._id,
                        discounts: { [ri]: discount / 100 },
                      },
                    ];
                  }
                });
              }
            },
            discountIndex: i,
            canEdit: can(Permission.EDIT),
            disabled: item.disabled,
          };
          all[ri] = v;
          return all;
        }, {}),
        notes: {
          _id: item._id,
          notes: item.notes || [],
          handleAddNote: handleAddNote,
          handleEditNote: handleEditNote,
          handleDeleteNote: handleDeleteNote,
          getIsLoadingNotes: getIsLoadingNotes,
          subTitle: t('contracts.notes.subtitle'),
          name: item._category.name,
        },
        approve: {
          _id: item._id,
          status: item.status,
          disabled: item.disabled,
        },
        status: item.status,
        __actions: { _id: item._id, disabled: item.disabled },
      };
    });
  }

  const handleUpdateRowDiscounts = async (
    categories: UpdateCategoryDiscounts[],
  ) => {
    if (!customerId) return;

    const isEditingInBulk: boolean = categories.length > 1;

    await callApi(
      async () => {
        if (isAdmin() && !isEditingInBulk) {
          statusToaster({
            variant: StatusToastVariant.Loading,
            spacingRight: '0px',
          });
        }
        return await updateContractDiscount({
          body: {
            _customer: customerId,
            categories: categories,
          },
        }).unwrap();
      },
      {
        onSuccess: (res: { message: string }) => {
          if (isEditingInBulk) {
            toast(3000, 'success', res.message);
          } else if (isAdmin()) {
            statusToaster({
              variant: StatusToastVariant.Success,
              message: t('orders.items.statuses.updated'),
              spacingRight: '0px',
            });
          } else {
            toast(3000, 'success', t('pricingGroups.bulk_update_success'));
          }
        },
        onError: () => {
          if (isAdmin() && !isEditingInBulk) {
            statusToaster({
              variant: StatusToastVariant.Error,
              spacingRight: '0px',
            });
          }
        },
      },
    );
  };

  const { data: levelData } = useGetMaxLevelQuery({});

  const { data: contractsFacets } = useGetCustomerContractFacetsQuery(
    customerId || '',
    {
      skip: !customerId,
    },
  );

  const [approveContract] = useApproveContractMutation();
  const [enableDisableContract] = useEnableDisableContractMutation();
  const [deleteContract] = useDeleteContractMutation();
  const [addDiscountNote] = useAddDiscountNoteMutation();
  const [deleteDiscountNote] = useDeleteDiscountNoteMutation();
  const [editDiscountNote] = useEditDiscountNoteMutation();

  const checkLevel = useCallback(
    (level): boolean | undefined => {
      if (levelData?.maxLevel) return level <= levelData.maxLevel;
      else return undefined;
    },
    [levelData?.maxLevel],
  );

  const isAdmin = useCallback((): boolean => {
    return checkUserRole(authUser, [
      ROLE_KEYS.ADMIN,
      ROLE_KEYS.SALES_ADMINISTRATION,
    ]);
  }, [authUser?.currentAgent?._role?.key]);

  const formatDiscountValue = useCallback((discount: number): number => {
    const value = (discount * 100).toFixed(2);
    const formattedValue = value.endsWith('.00')
      ? parseInt(value, 10)
      : parseFloat(value);
    return formattedValue;
  }, []);

  const getContractFacets = useCallback(
    (
      tab: ContractTabs,
    ): {
      pending_approval: number;
      approved: number;
      total: number;
    } => {
      return contractsFacets?.stats?.[tab] || 0;
    },
    [contractsFacets],
  );

  const handleApproveRows = useCallback(
    async (selectedRows: string[]) => {
      if (!customerId) return;

      await callApi(
        async () => {
          statusToaster({
            variant: StatusToastVariant.Loading,
            spacingRight: '0px',
          });
          return await approveContract({
            body: { _customer: customerId, ids: selectedRows },
          }).unwrap();
        },
        {
          onSuccess: () =>
            statusToaster({
              variant: StatusToastVariant.Success,
              message: t('approved'),
              spacingRight: '0px',
            }),
          onError: () =>
            statusToaster({
              variant: StatusToastVariant.Error,
              spacingRight: '0px',
            }),
        },
      );
    },
    [customerId, callApi, statusToaster, approveContract, t],
  );

  const handleContractToggle = useCallback(
    async ({
      disabled,
      selectedRows,
    }: {
      disabled: boolean;
      selectedRows: string[];
    }) => {
      if (!customerId) return;

      await callApi(
        async () => {
          return await enableDisableContract({
            body: {
              _customer: customerId,
              ids: selectedRows,
              disabled: disabled,
            },
          }).unwrap();
        },
        {
          onSuccess: () =>
            toast(
              3000,
              'success',
              t('message.action_discount', {
                action: (disabled ? t('disabled') : t('enabled')).toLowerCase(),
              }),
            ),
        },
      );
    },
    [callApi, customerId, enableDisableContract, t, toast],
  );

  const handleDeleteContract = useCallback(
    async (selectedRows: string[]) => {
      if (!customerId) return;

      await callApi(
        async () => {
          return await deleteContract({
            _customer: customerId,
            ids: generateParamsString(selectedRows),
          }).unwrap();
        },
        {
          onSuccess: () =>
            toast(
              3000,
              'success',
              t('message.action_discount', {
                action: t('orders.items.statuses.removed').toLowerCase(),
              }),
            ),
        },
      );
    },
    [callApi, customerId, deleteContract, t, toast],
  );

  const getEmptyDiscounts = useCallback(
    (
      restrictions: IRestrictions | undefined,
      defaultToZero?: boolean,
    ): FormData => {
      const defaultDiscounts = {};

      if (restrictions?.max && restrictions?.lock) {
        discountIndices.forEach((index) => {
          const key = `r${index}`;

          if (!restrictions.lock?.[key]) {
            defaultDiscounts[key] = defaultToZero
              ? 0
              : restrictions.max?.[key] || restrictionDefaults.max;
          }
        });
      } else {
        discountIndices.forEach((index) => {
          const key = `r${index}`;
          defaultDiscounts[key] = defaultToZero ? 0 : 1;
        });
      }

      return defaultDiscounts as FormData;
    },
    [],
  );

  const handleAddNote = useCallback(
    async (_id: string, content: string) => {
      if (!customerId) return;

      await callApi(
        async () => {
          return await addDiscountNote({
            discountId: _id,
            body: { _customer: customerId, content: content },
          }).unwrap();
        },
        {
          onSuccess: () => toast(3000, 'success', t('toaster.note_added')),
        },
      );
    },
    [addDiscountNote, callApi, customerId, t, toast],
  );

  const handleEditNote = useCallback(
    async (_id: string, note: string, content: string) => {
      if (!customerId) return;

      await callApi(
        async () => {
          return await editDiscountNote({
            discountId: _id,
            body: {
              _customer: customerId,
              _note: note,
              content: content,
            },
          }).unwrap();
        },
        {
          onSuccess: () => toast(3000, 'success', t('toaster.note_edited')),
        },
      );
    },
    [editDiscountNote, callApi, customerId, t, toast],
  );

  const handleDeleteNote = useCallback(
    async (_id: string, note: string) => {
      if (!customerId) return;
      await callApi(
        async () => {
          return await deleteDiscountNote({
            discountId: _id,
            _customer: customerId,
            noteId: note,
          }).unwrap();
        },
        {
          onSuccess: () =>
            toast(3000, 'success', t('alerts.notes.delete_success')),
        },
      );
    },
    [deleteDiscountNote, callApi, customerId, t, toast],
  );

  const getIsLoadingNotes = useCallback((): boolean => {
    return isLoading;
  }, [isLoading]);

  const getAddSuccessMessage = useCallback(
    (counts: CountAtLevels) => {
      const parts = Object.entries(counts).map(([level, count]) => {
        const translationKey =
          levelTranslationKeys[+level] || `discount_level_${level}`;
        const pluralized = count === 1 ? `${translationKey}_s` : translationKey;
        const translated = t(pluralized);
        return `${count} ${translated}`;
      });

      return `${parts.join(` ${t('and')} `)} ${t('added_successfully')}`;
    },
    [t],
  );

  const handleUpdateProductsDiscounts = async (products) => {
    if (!customerId) return;

    await callApi(
      async () => {
        statusToaster({
          variant: StatusToastVariant.Loading,
          spacingRight: '0px',
        });
        return await updateContractProductDiscount({
          body: {
            _customer: customerId,
            products: products,
          },
        }).unwrap();
      },
      {
        onSuccess: () =>
          statusToaster({
            variant: StatusToastVariant.Success,
            message: t('orders.items.statuses.updated'),
            spacingRight: '0px',
          }),
        onError: () =>
          statusToaster({
            variant: StatusToastVariant.Error,
            spacingRight: '0px',
          }),
      },
    );
  };

  const hasContracts = useCallback((): boolean => {
    if (!contractsFacets?.stats) return false;

    return Object.values(contractsFacets.stats).some(
      (contractType) => (contractType?.total || 0) > 0,
    );
  }, [contractsFacets]);

  return {
    getProcessedItemsFunction,
    checkLevel,
    isAdmin,
    getContractFacets,
    handleUpdateRowDiscounts,
    formatDiscountValue,
    handleApproveRows,
    handleContractToggle,
    handleDeleteContract,
    getEmptyDiscounts,
    handleAddNote,
    handleEditNote,
    handleDeleteNote,
    getIsLoadingNotes,
    getAddSuccessMessage,
    levelData,
    handleUpdateProductsDiscounts,
    hasContracts,
  };
};
