import styled from 'styled-components';
import EllipsisText from '../EllipsisText';

const StopTextWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const ClientCode = styled.span`
  font-weight: 700;
`;

export default function StopNameText({ stop, onDisplayCustomer }) {
  const isFuelStop = stop.type === 'fuel';
  const isMaintenance = stop.type === 'maintenance';
  const clientCode =
    stop._order?._customer?.code || stop.visitStopDetails?._customer?.code;
  const clientName =
    stop._order?._customer?.publicName ||
    stop.visitStopDetails?._customer?.publicName;
  const fuelStopName = stop?._place?.name || '-';

  const handleDisplayCustomer = () => {
    if (
      (stop._order?._customer || stop.visitStopDetails?._customer) &&
      typeof onDisplayCustomer === 'function'
    ) {
      onDisplayCustomer(
        stop._order?._customer || stop.visitStopDetails?._customer,
      );
    }
  };

  return (
    <StopTextWrapper>
      {!isFuelStop && !isMaintenance ? (
        <>
          <ClientCode onClick={handleDisplayCustomer}>
            {clientCode} -{' '}
          </ClientCode>{' '}
          <EllipsisText width={100} text={clientName} />
        </>
      ) : (
        <EllipsisText width={140} text={fuelStopName} />
      )}
    </StopTextWrapper>
  );
}
