import { Alert, AlertColor, SxProps } from '@mui/material';
import { Warning, Info } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { themes } from 'styles/theme/themes';

interface Props {
  severity: AlertColor;
  content: String | JSX.Element;
  sx?: SxProps;
}

const getAlertColors = (
  severity: AlertColor,
): { fill: string; color: string; icon: string } => {
  switch (severity) {
    case 'info':
      return {
        icon: themes.default.lightInfoMain,
        fill: themes.default.backgroundBlue,
        color: themes.default.blueTitleColor,
      };
    case 'warning':
      return {
        icon: themes.default.warningMain,
        fill: themes.default.linen,
        color: themes.default.brown,
      };
    case 'error':
      return {
        icon: themes.default.errorMain,
        fill: themes.default.errorBackground,
        color: themes.default.textDarkRed,
      };
    case 'success':
      return {
        icon: themes.default.green,
        fill: themes.default.bgDelivered,
        color: themes.default.successDark,
      };
  }
};

export const OrderAlert = ({ severity, content, sx }: Props) => {
  return (
    <Alert
      severity={severity}
      sx={AlertSx({ severity, sx })}
      icon={
        <Icon
          icon={severity === 'info' ? <Info /> : <Warning />}
          size={22}
          color={getAlertColors(severity).icon}
        />
      }
    >
      {content}
    </Alert>
  );
};

const AlertSx = ({
  severity,
  sx,
}: {
  severity: AlertColor;
  sx?: SxProps;
}): SxProps => {
  return {
    minHeight: '32px',
    gap: '8px',
    background: getAlertColors(severity).fill,
    padding: '5px 21px',
    alignItems: 'center',
    borderRadius: '0px !important',
    '.MuiAlert-icon': {
      padding: '0px',
      margin: '0px',
      height: '22px',
    },
    '.MuiAlert-message': {
      padding: '0px',
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      letterSpacing: '0.15px',
      color: getAlertColors(severity).color,
    },
    ...(sx || {}),
  };
};
