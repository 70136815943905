import { FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FilterSection } from 'app/components/FilterComponents/styles';
import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import If from 'app/components/If';
import { themes } from 'styles/theme/themes';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { DOCUMENT_STATUSES } from 'app/pages/Settings/UserInfo/components/UserTabs/DocumentTab/documentStatuses';
import { DocumentsKeys } from 'common/utils/enum';
import { getIconForType } from 'app/pages/Settings/UserInfo/components/UserTabs/DocumentTab/components/Cells';
import { useCallback } from 'react';

const FilterComponent = () => {
  const { t } = useTranslation();
  const {
    queryParams: { filters },
    updateDataWithFunction,
    canEdit,
  } = useManagedContext<IGridContext>('grid');
  const idParams = useParams();
  const id = idParams.id || '';
  const authUser = useSelector(selectAuthUser);
  const checkModuleScope = useCallback(
    (application, module, scope) => {
      return authUser?.currentAgent?.privileges?.scopes.includes(
        `${application}.${module}.${scope}`,
      );
    },
    [authUser?.currentAgent?.privileges?.scopes],
  );
  const CanChangeStatus =
    canEdit &&
    authUser?._id !== id &&
    checkModuleScope('iam', 'documents', 'canedit');

  const validKeys = Object.values(DocumentsKeys);

  const statusData = [
    DOCUMENT_STATUSES.APPROVED,
    DOCUMENT_STATUSES.REJECTED,
    DOCUMENT_STATUSES.PENDING,
  ];

  const handleClearAll = () => {
    updateDataWithFunction((prev) => {
      prev.queryParams.filters = {
        status: [],
        type: [],
        isArchived: false,
      };
    });
  };

  const toggleArchived = () => {
    updateDataWithFunction((prev) => {
      prev.queryParams.filters.isArchived =
        !prev.queryParams.filters.isArchived;
    });
  };

  return (
    <>
      <FilterHeader onClearAll={handleClearAll} />
      <FilterSection>
        <MultiSelect
          filterName="Select Type"
          openByDefault={true}
          selectedOptions={filters.type}
          handleFilterChange={(options) => {
            updateDataWithFunction((prev) => {
              prev.queryParams.filters.type = options;
            });
          }}
          filterItems={validKeys.map((key) => ({
            value: key,
            label: t(key),
            backgroundColor: themes.default.whisper,
            color: themes.default.documentDrawerText,
            icon: getIconForType(key, 12, false),
          }))}
        />

        <If condition={statusData.length > 0 && !!id}>
          <MultiSelect
            filterName="Status"
            selectedOptions={filters.status}
            handleFilterChange={(options) => {
              updateDataWithFunction((prev) => {
                prev.queryParams.filters.status = options;
              });
            }}
            filterItems={statusData.map((status) => ({
              value: status,
              label: t(status),
              backgroundColor:
                status === DOCUMENT_STATUSES.PENDING
                  ? themes.default.statusPendingColor.background
                  : status === DOCUMENT_STATUSES.APPROVED
                    ? themes.default.statusApprovedColor.background
                    : themes.default.statusRejectedColor.background,
              color:
                status === DOCUMENT_STATUSES.PENDING
                  ? themes.default.statusPendingColor.color
                  : status === DOCUMENT_STATUSES.APPROVED
                    ? themes.default.statusApprovedColor.color
                    : themes.default.statusRejectedColor.color,
            }))}
          />
        </If>
        <If condition={CanChangeStatus}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={toggleArchived}
                checked={!!filters?.isArchived}
                value={filters?.isArchived}
              />
            }
            label={t(`documents.show_archived`)}
            sx={{
              marginLeft: 0,
              marginTop: '10px',
              color: !!filters?.isArchived
                ? themes.default.primaryActiveColor
                : themes?.default?.textBlack,
              letterSpacing: '0.15px',
              '& .MuiTypography-root': {
                fontSize: '0.875rem !important',
                fontWeight: 400,
                padding: '2px 0 0 0',
              },
            }}
          />
        </If>
      </FilterSection>
    </>
  );
};

export default FilterComponent;
