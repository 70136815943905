import { NameGridCell } from 'app/components/NameGridCell';
import styled from 'styled-components';
import { PlusCircle } from '@phosphor-icons/react';
import { CheckCircle } from '@phosphor-icons/react';
import { IconButton } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { themes } from 'styles/theme/themes';
import { formatNumber } from 'utils/helpers';
import { getImgCdn } from 'common/helpers/cdn';
import { MiddleProductRowComponent } from './MiddleProductRowComponent';
import { useTranslation } from 'react-i18next';
import { ICustomProductByTopCategory } from 'common/types/Product';
import { useContext } from 'react';
import { AddProductDiscountContext } from '../../../Customers/components/ViewCustomer/components/Tabs/ContractsTab/components/ProductsDiscounts/Components/AddDiscountProduct/AddDiscountProduct';

export const ProductRow = ({
  prod,
  onAddAction,
  addedProducts,
  setAddedProducts,
  onRemoveAction,
}: {
  prod: ICustomProductByTopCategory;
  onAddAction: (id: string, name?: string) => void;
  onRemoveAction: (id: string, type: string, hasConfirmation?: boolean) => void;
  addedProducts: string[];
  setAddedProducts: (ids: string[]) => void;
}) => {
  const { t } = useTranslation();

  const addProductDiscount = (id: string, name: string) => {
    setAddedProducts([...addedProducts, id]);
    onAddAction(id, name);
  };

  const { selectedRemovedIds, setSelectedRemovedIds } = useContext(
    AddProductDiscountContext,
  );

  const removeProductDiscount = (id: string) => {
    setSelectedRemovedIds([...selectedRemovedIds, id]);
    const newProducts = addedProducts?.filter(
      (addedProduct) => addedProduct !== id,
    );
    setAddedProducts(newProducts);
    onRemoveAction(id, 'products', false);
  };

  const isAdded =
    (addedProducts?.includes(prod._id) || prod._customerProductDiscount) &&
    !selectedRemovedIds.includes(prod._id);
  return (
    <StyledStack
      isAdded={isAdded || false}
      background={
        isAdded ? themes.default.chipGreenFont : themes.default.baseWhite
      }
    >
      <Wrapper>
        <div>
          {isAdded ? (
            <IconButton
              onClick={() => {
                removeProductDiscount(prod?._id || '');
              }}
              sx={{
                height: '28px',
                width: '28px',
                padding: '0px',
              }}
            >
              <Icon
                color={themes.default.greenLaurel}
                weight="fill"
                icon={<CheckCircle />}
              />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                addProductDiscount(prod._id, prod.name);
              }}
              sx={{
                height: '28px',
                width: '28px',
                padding: '0px',
              }}
            >
              <Icon icon={<PlusCircle />} tooltip={t('add_products')} />
            </IconButton>
          )}
        </div>
        <NameWrapper>
          <NameGridCell
            ellipsisWidth={180}
            isNew={false}
            name={prod.name}
            code={prod.code}
            middleComonent={<MiddleProductRowComponent prod={prod} />}
            onClick={() => {}}
            pictureUrl={
              prod.coverImage?.key ? getImgCdn(prod.coverImage?.key, 64) : ''
            }
          />
        </NameWrapper>
        <div>
          <PriceStack>{'€ ' + formatNumber(prod.sellingPrice)}</PriceStack>
        </div>
      </Wrapper>
    </StyledStack>
  );
};

const StyledStack = styled.div<{ background: string; isAdded: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.grey400};
  background: ${(props) => props.background} !important;
  margin-bottom: 5px;
  &:hover {
    background: ${(props) =>
      props.isAdded
        ? props.theme.hoverLightGreen
        : props.theme.lightGrey} !important;
  }
`;

const PriceStack = styled.p`
  padding-top: 40px;
  padding-left: 20px;
  font-weight: 600;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%;
`;
const NameWrapper = styled.div`
  width: 70%;
`;
