import React from 'react';
import { Stack, Typography } from '@mui/material';
import { DOCUMENT_STATUSES } from '../../../../../../../pages/Settings/UserInfo/components/UserTabs/DocumentTab/documentStatuses';
import { UploadDocument } from '../../../../../UploadDocument';
import { t } from 'i18next';

const DocumentAttachments = ({
  attachments,
  status,
  gridContext,
  setUploadedDocuments,
  setDeletedDocuments,
  canEdit,
  isCurrentUser,
}) => {
  return (
    <Stack marginTop={2} spacing={2}>
      <Typography fontSize={14} fontWeight={400}>
        {t('common.attachments').toString()}
      </Typography>
      <UploadDocument
        documents={attachments}
        showUploadZone={
          status?.status !== DOCUMENT_STATUSES.REJECTED &&
          !(status?.status === DOCUMENT_STATUSES.PENDING && isCurrentUser) &&
          gridContext.canEdit &&
          canEdit
        }
        disabled={
          status?.status === DOCUMENT_STATUSES.REJECTED ||
          (status?.status === DOCUMENT_STATUSES.PENDING && isCurrentUser) ||
          !gridContext.canEdit ||
          !canEdit
        }
        setUploadedDocuments={setUploadedDocuments}
        setDeletedDocuments={setDeletedDocuments}
      />
    </Stack>
  );
};

export default DocumentAttachments;
