import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsMenu } from 'app/components/TabsMenu';
import { InfoForm } from './InfoTab';
import { BranchTab } from './BranchTab';
import { useSelector } from 'react-redux';
import If from 'app/components/If';
import { useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { usePlaceContext } from '../../hooks/usePlaceContext';
import {
  bindShortcut,
  unbindShortcut,
} from 'app/pages/RoundTrips/components/KeyboardShortcuts/functions';
import { useTabs, TabType } from 'app/components/TabsMenu/hooks/useTabs';
import {
  PageWrapper,
  TabsSection,
} from 'app/styledComponents/DetailsSection.styled';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';
import { HistoryTab } from './HistoryTab';
import { NotesTab } from './NotesTab';
import { useNavigate, useLocation } from 'react-router-dom';
import { ContractsTab } from './ContractsTab';
import { CustomerFormcontext } from '../../../Form/context';
import { SettingsTab } from './SettingsTab';
import { useNavLock } from 'hooks/useNavLock';
import { useGuardedAction } from 'hooks/useGuardedAction';

export const CustomerTabs = ({ customerDetail, from }) => {
  const { t } = useTranslation();
  const zoomedIn = useSelector(selectIsZoomedIn);
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const { navLocked } = useNavLock();
  const { executeAction } = useGuardedAction();
  const { setDrawerOpen, setSelectedBranchId } = usePlaceContext();

  const { isCustomer } = useContext(CustomerFormcontext);

  const [selectedTab, setSelectedTab] = useState(
    searchParams.get('tab') || 'info',
  );

  const handleOnSwitchTab = (event, newValue) => {
    handleChangeTab(newValue);
  };

  //TODO: Fix this
  const branches: any = {};

  // useEffect(() => {
  //   if (selectedBranchId !== '') {
  //     getBranchData({
  //       customerId: id,
  //       branchId: selectedBranchId,
  //     });
  //   }
  // }, [drawerOpen, selectedBranchId]);

  const shortcuts = [
    {
      keybinds: ['ctrl+1'],
      callback: () => handleChangeTab('info'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+2'],
      callback: () => handleChangeTab('branches'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+3'],
      callback: () => handleChangeTab('accounting'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+4'],
      callback: () => handleChangeTab('financials'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+5'],
      callback: () => handleChangeTab('contracts'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+a'],
      callback: () => {
        setSelectedBranchId('');
        setDrawerOpen((drawerOpen) => !drawerOpen);
      },
      listener: 'keydown',
    },
  ];

  const handleBindShortcuts = useMemo(() => {
    return () => {
      shortcuts.map((shortcut) => {
        bindShortcut(shortcut.keybinds, shortcut.callback);
      });
    };
  }, []);

  const handleUnbindShortcuts = useMemo(() => {
    return () => {
      shortcuts.map((shortcut) => {
        unbindShortcut(shortcut.keybinds);
      });
    };
  }, []);

  const handleChangeTab = useCallback(
    (tab: string) => {
      const action = () => {
        const params = new URLSearchParams(location.search);
        params.set('tab', tab);
        navigate(`?${params.toString()}`, {
          state: { from },
        });

        setSelectedTab(tab);
      };
      executeAction(action, navLocked);
    },
    [executeAction, from, location.search, navLocked, navigate],
  );

  useEffect(() => {
    handleBindShortcuts();
    return () => {
      handleUnbindShortcuts();
    };
  }, [handleBindShortcuts, handleUnbindShortcuts]);

  const showInfoError = useMemo(() => {
    return !customerDetail?.mainLocation?.formattedAddress;
  }, [customerDetail?.mainLocation?.formattedAddress]);

  const showBranchError = useMemo(() => {
    return branches?.docs?.find((b) => !b?.location?.name);
  }, [branches?.docs]);

  const defaultTabs: TabType[] = useMemo(() => {
    if (isCustomer) {
      return [
        {
          value: 'info',
          label: t('info'),
          errorMessage: showInfoError ? t('missing_required_fields') : '',
        },
        {
          value: 'branches',
          label: t('common.branches'),
          errorMessage: showBranchError ? t('missing_required_fields') : '',
        },
        { value: 'settings', label: t('nav.appHeader.settings') },
      ];
    }
    return [
      {
        value: 'info',
        label: t('info'),
      },
      {
        value: 'branches',
        label: t('common.branches'),
      },
      {
        value: 'accounting',
        label: t('customer_Accounting'),
      },
      {
        value: 'financials',
        label: t('customer_Financials'),
      },
      {
        value: 'contracts',
        label: t('customer_Contracts'),
      },
      {
        value: 'history',
        label: t('common.history'),
      },
      {
        value: 'note',
        label: t('common.label.note'),
      },
    ];
  }, [isCustomer, showInfoError, t]);

  const [tabs, setTabs] = useTabs({
    defaultTabs: defaultTabs,
    moduleName: 'customerInfo',
  });

  const height = useMemo(() => {
    let addedHeight = '';
    if (selectedTab === 'branches') {
      addedHeight = '+ 32px';
    } else if (selectedTab === 'contracts') {
      addedHeight = '+ 24px';
    }
    if (zoomedIn) {
      return `calc(100vh - 185px ${addedHeight})`;
    }
    return `calc(100vh - 185px - ${themes.default?.topBarHeight} ${addedHeight})`;
  }, [zoomedIn]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft="20px"
        borderBottom={`1px solid ${themes.default.lightFill}`}
        sx={{
          backgroundColor: 'white',
          height: themes.default.toolBarHeight,
          borderRadius: 'unset !important',
          overflow: 'hidden',
        }}
      >
        <TabsMenu
          handleOnSwitchTab={handleOnSwitchTab}
          selectedTab={selectedTab}
          defaultTabs={defaultTabs}
          tabs={tabs}
          setTabs={setTabs}
        />

        <If condition={selectedTab === 'contracts'}>
          <div id="contracts-grid-actions" style={{ paddingRight: '20px' }} />
        </If>
      </Stack>
      <PageWrapper
        noPadding={selectedTab === 'branches' || selectedTab === 'contracts'}
      >
        <TabsSection height={height} padding="0">
          <If condition={selectedTab === 'info' && !!customerDetail}>
            <InfoForm customerDetail={customerDetail} />
          </If>
          <If condition={selectedTab === 'branches'}>
            <BranchTab customer={customerDetail} />
          </If>
          <If condition={selectedTab === 'history'}>
            <HistoryTab customerId={customerDetail?._id} />
          </If>
          <If condition={selectedTab === 'note'}>
            <NotesTab customerId={customerDetail?._id} />
          </If>
          <If condition={selectedTab === 'contracts'}>
            <ContractsTab minMargin={customerDetail?.minMargin} />
          </If>
          <If condition={selectedTab === 'settings'}>
            <SettingsTab />
          </If>
        </TabsSection>
      </PageWrapper>
    </>
  );
};
