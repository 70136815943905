import { Box, Divider, Grid, Typography } from '@mui/material';
import { themes } from 'styles/theme/themes';
import MainBampteeLogo from 'assets/img/Menu/MainBampteeLogo.svg';
import { useTranslation } from 'react-i18next';

export const VisitorsFooter = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: 'auto',
        padding: '20px 0 0 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <Box
          component="img"
          alt="MainBampteeLogo"
          src={MainBampteeLogo}
          sx={{
            width: 24,
            height: 33,
            objectFit: 'contain',
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '16px',
        }}
      >
        <Divider sx={{ width: { xs: '100%', sm: '80%', md: '617px' } }} />
      </Box>

      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: 'center',
              color: themes.default.clientMarkerBg,
            }}
          >
            If you have questions or need help, you can reply to this email or
            contact our support team!
          </Typography>
        </Grid>

        <Grid
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ marginTop: '8px' }}
        >
          <Typography
            component="a"
            fontWeight={600}
            href="https://www.bamptee.com/fr/terms-of-service"
            target="_blank"
            sx={{
              textAlign: 'center',
              color: themes.default.clientMarkerBg,
              textDecoration: 'none',
            }}
          >
            {t('common.terms_conditions')}
          </Typography>

          <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />

          <Typography
            component="a"
            fontWeight={600}
            href="https://www.bamptee.com/fr/privacy-policy"
            target="_blank"
            sx={{
              textAlign: 'center',
              color: themes.default.clientMarkerBg,
              textDecoration: 'none',
            }}
          >
            {t('common.privacy-policy')}
          </Typography>

          <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />

          <Typography
            component="a"
            fontWeight={600}
            href="https://www.bamptee.com/fr/contact-us"
            target="_blank"
            sx={{
              textAlign: 'center',
              color: themes.default.clientMarkerBg,
              textDecoration: 'none',
            }}
          >
            {t('common.contact-us')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
