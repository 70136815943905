import { ViewCustomerContext } from 'app/pages/Customers/components/ViewCustomer';
import { useRestrictionsContextOf } from 'app/components/Restrictions/hooks/useRestrictionsContextOf';
import { emptyRestrictions } from 'app/components/Restrictions/helpers';
import { useEditCustomerRestrictionsMutation } from 'common/services/customerApi';
import { useContext } from 'react';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';
import { useParams } from 'react-router-dom';

export const useRestrictions = () => {
  const { customer } = useContext(ViewCustomerContext);
  const { set: setApiCaller } = useRestrictionsContextOf('apiCaller');
  const { set: setRestrictions } = useRestrictionsContextOf('restrictions');
  const [editCustomerRestrictions] = useEditCustomerRestrictionsMutation();
  const { callApi } = useAsyncDataV2();
  const { id: customerId } = useParams();

  const initiateRestrictions = (tabKey) => {
    const customerRestrictions = customer?.restrictions?.[tabKey];

    if (customerRestrictions) {
      setRestrictions(customerRestrictions);
    } else {
      setRestrictions(emptyRestrictions());
    }

    setApiCaller(async (body, _) => {
      const res = await callApi(async () => {
        return await editCustomerRestrictions({
          customerId: customerId || '',
          body: { key: tabKey, restrictions: body },
        }).unwrap();
      });

      if (!res) return null;
      const {
        updated: { restrictions },
      } = res;

      // update UI (subscribers)
      setRestrictions(restrictions);
      return restrictions;
    });
  };
  return {
    initiateRestrictions,
  };
};
