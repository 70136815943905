import { ICheckboxAutocompleteOption } from 'app/components/CheckboxAutocomplete';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useTranslation } from 'react-i18next';
import { Contract } from '../../types';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  FormControlLabel,
  SxProps,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'app/components/Icon';
import { Square, CheckSquare } from '@phosphor-icons/react';
import { selectTheme } from 'styles/theme/slice/selectors';
import { CategoriesAutocomplete } from '../CategoriesAutocomplete';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';
import { useAddContractsMutation } from 'common/services/customerCategoryDiscountApi';
import { useParams } from 'react-router-dom';
import { useHelpers } from '../../useHelpers';
import { ButtonLoadingContainer } from 'app/pages/Ordoria/Orders/components/CreateOrderDrawerSales';
import { themes } from 'styles/theme/themes';
import { useToaster } from 'hooks/useToaster';
import { AddCategoriersRes } from 'common/types/customerCategoryDiscounts';
import { isNumber } from 'lodash';
import { discountIndices } from 'app/components/Restrictions/types/discounts.types';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedTab: Contract;
}

export const AddContractsModal = ({ open, onClose, selectedTab }: Props) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const { callApi } = useAsyncDataV2();
  const { id: customerId } = useParams();
  const { checkLevel, getAddSuccessMessage } = useHelpers();
  const toast = useToaster();

  const [addContracts, { isLoading }] = useAddContractsMutation();

  const [selectedOptions, setSelectedOptions] = useState<
    ICheckboxAutocompleteOption[]
  >([]);
  const [includedLevels, setIncludedLevels] = useState<number[]>([]);

  const handleIncludeLevels = useCallback((checked: boolean, level: number) => {
    if (checked) {
      setIncludedLevels((prev) => [...prev, level]);
    } else {
      setIncludedLevels((prev) => prev.filter((old) => old !== level));
    }
  }, []);

  const handleOptionsChange = useCallback(
    (options: ICheckboxAutocompleteOption[]) => {
      setSelectedOptions(options);
    },
    [],
  );

  const handleClose = useCallback(() => {
    onClose();
    setSelectedOptions([]);
    setIncludedLevels([]);
  }, [onClose]);

  const handleAddOptions = useCallback(async () => {
    if (selectedOptions.length === 0 || !customerId) return;

    const formattedCategories = selectedOptions.map((category) => ({
      _category: category._id,
    }));
    await callApi(
      async () => {
        return await addContracts({
          body: {
            _customer: customerId,
            categories: formattedCategories,
            includeCategoryLevels: !!includedLevels.length
              ? includedLevels
              : [0],
          },
        }).unwrap();
      },
      {
        onSuccess: (res: AddCategoriersRes) => {
          toast(5000, 'success', getAddSuccessMessage(res.countAtLevels));
          handleClose();
        },
      },
    );
  }, [
    addContracts,
    callApi,
    customerId,
    getAddSuccessMessage,
    includedLevels,
    handleClose,
    selectedOptions,
    toast,
  ]);

  return (
    <TwoViewModal
      onOpen={open}
      onClose={handleClose}
      defaultMode="floated"
      title={t('contracts.add', { type: t(`contracts.${selectedTab.key}`) })}
      headerStyle={{ padding: '16px 10px 16px 24px' }}
      hasArrowBack={false}
      autoHeight
    >
      <DrawerContentWrapper>
        <CategoriesAutocomplete
          level={selectedTab?.level}
          label={t(`contracts.${selectedTab.key}`)}
          placeholder={t('common.select_x', {
            x: t(
              isNumber(selectedTab.level)
                ? `discount_level_${selectedTab.level}`
                : `contracts.${selectedTab.key}`,
            ).toLowerCase(),
          })}
          onChange={handleOptionsChange}
          required
        />
        {discountIndices
          .filter((level) => level > (selectedTab.level || 0))
          .map(
            (level) =>
              !!checkLevel(level) && (
                <FormControlLabel
                  key={level}
                  sx={{ ...formControlLabelSx, color: theme.textBlack }}
                  control={
                    <CustomCheckbox
                      checked={includedLevels.includes(level)}
                      onChange={(e) =>
                        handleIncludeLevels(e.target.checked, level)
                      }
                    />
                  }
                  label={t(`contracts.include_level_${level}`)}
                />
              ),
          )}
      </DrawerContentWrapper>
      <DrawerActinosWrapper>
        <Button onClick={handleClose} color="inherit">
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={handleAddOptions}
          variant="contained"
          autoFocus
          disabled={selectedOptions.length === 0 || isLoading}
        >
          {isLoading && (
            <ButtonLoadingContainer>
              <CircularProgress
                size="16px"
                sx={{ color: themes.default.accordionWhiteBg }}
              />
            </ButtonLoadingContainer>
          )}
          <span style={{ opacity: isLoading ? 0 : 1 }}>{t('common.add')}</span>
        </Button>
      </DrawerActinosWrapper>
    </TwoViewModal>
  );
};

const DrawerContentWrapper = styled.div`
  padding: 30px 24px 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DrawerActinosWrapper = styled.div`
  /* padding: 30px 24px; */
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const CustomCheckbox = (props: CheckboxProps) => {
  const theme = useSelector(selectTheme);

  return (
    <Checkbox
      icon={<Icon icon={<Square weight="thin" />} size={20} />}
      checkedIcon={
        <Icon
          icon={<CheckSquare weight="fill" />}
          size={20}
          color={theme.primary}
        />
      }
      style={{ padding: 0 }}
      size="small"
      {...props}
    />
  );
};

const formControlLabelSx: SxProps = {
  gap: '6px',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  marginLeft: '0px',
  marginRight: '0px',
};
