import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import { FilterSection } from 'app/components/FilterComponents/styles';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useCallback } from 'react';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import { ProductCategoriesFilter } from './';
import { PRODUCT_STATUSES } from '../../Consts';
import { UsersMultiSelect } from 'app/components/FilterComponents/components/UsersMultiSelect';

export const ProductsDiscountsFilter = () => {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext>('grid');

  const handleOptionsChange = useCallback(
    (filterKey: string, options: { value: string; label: string }[]) => {
      gridContext.updateDataWithFunction((prev) => {
        prev.queryParams.filters[filterKey] = options;
      });
    },
    [gridContext?.queryParams?.filters],
  );

  const handleClearAll = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.queryParams.filters = {
        categories: [],
        status: [],
        lastEditedBy: [],
        hasNotes: false,
        lastEditedByOptions: [],
      };
    });
  };

  return (
    <>
      <FilterHeader onClearAll={handleClearAll} />
      <FilterSection>
        <ProductCategoriesFilter />
        <MultiSelect
          filterName={t('common.status')}
          selectedOptions={gridContext.queryParams.filters?.status}
          handleFilterChange={(options) => {
            handleOptionsChange('status', options);
          }}
          filterItems={PRODUCT_STATUSES?.map((status) => ({
            value: status.key,
            label: t(status.label),
            color: status.color,
            backgroundColor: status.background,
          }))}
        />
        <UsersMultiSelect
          selectedOptions={gridContext.queryParams.filters.lastEditedBy || []}
          name={t('filters.last_edited_by')}
          filterName={t('filters.last_edited_by')}
          disabled={false}
          onChange={(_e, options) => {
            gridContext.updateDataWithFunction((prev) => {
              prev.queryParams.filters['lastEditedBy'] = options;
            });
          }}
        />
        <Stack gap="24px">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                size="small"
                checked={gridContext.queryParams.filters.hasNotes}
                onChange={(e) => {
                  gridContext.updateDataWithFunction((prev) => {
                    prev.queryParams.filters.hasNotes = e.target.checked;
                  });
                }}
              />
            }
            label={t('filters.has_notes')}
            sx={{ marginRight: '0px', marginLeft: '0px' }}
          />
        </Stack>
      </FilterSection>
    </>
  );
};
