import { DrawerComponent } from 'app/components/DrawerComponent';
import { useTranslation } from 'react-i18next';
import { InvoiceForm } from './InvoiceForm';

interface InvoiceDrawerProps {
  open: boolean;
  handleClose: () => void;
  customer?: any;
  order?: any;
}

export function InvoiceDrawer({ open, handleClose }: InvoiceDrawerProps) {
  const { t } = useTranslation();

  const isEdit = false; ///Todo implement edit functionality

  return (
    <DrawerComponent
      title={
        isEdit
          ? t('transaction.invoice.edit.title')
          : t('transaction.invoice.add.title')
      }
      open={open}
      handleClose={handleClose}
    >
      <InvoiceForm handleClose={handleClose} />
    </DrawerComponent>
  );
}
