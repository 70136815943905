import { TwoViewModal } from '../TwoViewModal';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import {
  useActivateCustomerSepaMutation,
  useGetCustomerPublicDetailsQuery,
  useLazyGetCustomerSepaStatusQuery,
  useResetCustomerSepaMutation,
} from 'common/services/customerApi';
import If from '../If';
import DrawIcon from '@mui/icons-material/Draw';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { selectConnectors } from 'common/store/organization/selectors';
import { UploadFiles } from '../UploadFiles';
import { useQuery } from 'common/hooks/router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';
import { themes } from 'styles/theme/themes';

interface PaymentModalProps {
  open: boolean;
  onClose: () => void;
}

export const PaymentModal = ({ open, onClose }: PaymentModalProps) => {
  const { t } = useTranslation();
  const toast = useToaster();
  const authUser = useSelector(selectAuthUser);
  const authCustomer = authUser?.currentAgent?._customer;
  const { token: urlToken } = useParams();
  const urlQuery = useQuery();
  const token = authCustomer?.token || urlToken;
  const password =
    !urlQuery.get('password') || urlQuery.get('password') === 'undefined'
      ? 'fedipat'
      : urlQuery.get('password');

  const { data: customer } = useGetCustomerPublicDetailsQuery(
    { token, password },
    {
      skip: isEmpty(token) || isEmpty(password),
    },
  );

  const [
    getSepaStatus,
    { data: sepaStatus, isFetching: isFetchingSepaStatus },
  ] = useLazyGetCustomerSepaStatusQuery();

  const [activateSepa] = useActivateCustomerSepaMutation();
  const [resetSepa] = useResetCustomerSepaMutation();

  const [displayUploadMandate, setDisplayUploadMandate] =
    useState<boolean>(false);
  const [openResetModal, setOpenResetModal] = useState<boolean>(false);

  const connectors = useSelector(selectConnectors);

  const paynumConnector = connectors?.find(
    (connector) => connector.type === 'paynum' && connector.enable,
  );

  const isCustomer = !isEmpty(authUser?.currentAgent?._customer);
  const sepaEnable = isCustomer && paynumConnector && authUser?.companyEmail;

  const handleActiveSepa = async () => {
    if (sepaStatus?.activeMandate) {
      // Open confirmation modal
      setOpenResetModal(true);
    } else {
      await activateSepa({
        okUrl: `${window.location.href}?sepa=ok`,
        koUrl: `${window.location.href}?sepa=ko`,
      }).unwrap();

      window.open(sepaStatus.signUrl, '_blank');

      onClose();
    }
  };

  const handleResetSepa = async () => {
    await resetSepa({
      okUrl: `${window.location.href}?sepa=ok`,
      koUrl: `${window.location.href}?sepa=ko`,
    }).unwrap();

    window.open(sepaStatus.signUrl, '_blank');

    setOpenResetModal(false);
    onClose();
  };

  useEffect(() => {
    if (sepaEnable) {
      getSepaStatus('');
    }
  }, [token, sepaEnable]);

  useEffect(() => {
    if (urlQuery?.get('sepa') === 'ok') {
      toast(3000, 'success', 'customer.sepa.activation_success');
      getSepaStatus('');
    } else if (urlQuery?.get('sepa') === 'ko') {
      toast(3000, 'error', 'customer.sepa.activation_error');
    } else if (urlQuery?.get('payment') === 'ko') {
      toast(3000, 'error', 'customer.payment.payment_error');
    } else if (urlQuery?.get('payment') === 'ok') {
      toast(3000, 'success', 'customer.payment.payment_success');
    }
  }, []);

  return (
    <>
      <TwoViewModal
        onOpen={open}
        onClose={onClose}
        defaultMode="floated"
        width="750px"
      >
        <>
          <DialogTitle
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: themes.default.baseWhite,
              zIndex: 2,
            }}
          >
            {t('sepa_mandate')}
          </DialogTitle>
          <DialogContent>
            <Box width="100%" textAlign="center" mb="16px">
              <If condition={sepaEnable && !isFetchingSepaStatus}>
                <Button
                  variant="contained"
                  onClick={handleActiveSepa}
                  startIcon={<DrawIcon />}
                  sx={{
                    width: '300px',
                  }}
                >
                  {sepaStatus?.activeMandate
                    ? 'Modifier le mandat SEPA'
                    : 'Signer électroniquement'}
                </Button>

                <Or />

                <Button
                  variant="contained"
                  onClick={() => setDisplayUploadMandate(true)}
                  startIcon={<DrawIcon />}
                  sx={{
                    width: '300px',
                  }}
                >
                  Signer manuellement - RIB
                </Button>
              </If>
            </Box>

            {displayUploadMandate || !isCustomer ? (
              <UploadFiles
                customer={customer}
                tokenPassword={password}
                type="payment"
                onUploadSuccess={() => {
                  onClose();
                }}
              />
            ) : null}
          </DialogContent>
        </>
      </TwoViewModal>

      <TwoViewModal
        onOpen={openResetModal}
        onClose={() => setOpenResetModal(false)}
        defaultMode="floated"
        autoHeight
      >
        <DialogTitle id="alert-dialog-title">
          Avertissement modification de mandat !
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            La modification du mandat SEPA entrainera la révocation du mandat en
            cours et la signature d'un nouveau. Si vous n'allez pas au bout du
            processus vos prélèvements ne seront plus effectifs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenResetModal(false)}>Annuler</Button>
          <Button onClick={handleResetSepa} autoFocus variant="contained">
            Modifier le mandat
          </Button>
        </DialogActions>
      </TwoViewModal>
    </>
  );
};

const Or = styled.div`
  text-align: center;
  margin: 24px 0 16px;
  border-top: 1px solid #eee;

  &:after {
    content: 'ou';
    display: inline-block;
    padding: 0 8px;
    font-size: 14px;
    color: #999;
    background: #fff;
    top: -12px;
    position: relative;
  }
`;
