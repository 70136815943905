import { TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { TextIconButton } from '../TextIconButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Icon } from 'app/components/Icon';
import { Plus, Check } from '@phosphor-icons/react';
import { useCallback, useEffect, useRef } from 'react';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onCancel?: () => void;
  onAdd?: (content: string) => void;
  editMode: boolean;
}

export const NoteTextInput = ({
  value,
  onChange,
  onCancel = () => {},
  onAdd = () => {},
  editMode,
}: Props) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []); // Focus when component mounts

  const handleAddClick = useCallback(() => {
    onAdd(value);
    onCancel();
  }, [onAdd, onCancel, value]);

  return (
    <Wrapper>
      <TextField
        inputRef={inputRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          flex: 1,
          margin: '0px !important',
          '.MuiInputBase-root': {
            padding: '12px 2px 32px 12px',
          },
          fieldset: {
            borderColor: theme.strokePrimary,
            borderRadius: '8px',
          },
        }}
        multiline
        rows={3}
        onKeyDown={(e) => {
          if (!e.shiftKey && e.key === 'Enter') {
            handleAddClick();
          }
        }}
      />
      <ActionsContainer>
        <TextIconButton onClick={onCancel}>
          <Typography
            fontSize="0.75rem"
            lineHeight="20px"
            letterSpacing="0.46px"
            color={theme.clientMarkerBg}
            textTransform="uppercase"
          >
            {t('common.cancel')}
          </Typography>
        </TextIconButton>
        <TextIconButton
          onClick={handleAddClick}
          disabled={!value?.length}
          leftIcon={
            <Icon
              icon={editMode ? <Check /> : <Plus />}
              color={theme.primary}
              size={14}
              style={{ marginBottom: '2px' }}
            />
          }
        >
          <Typography
            fontWeight="500"
            fontSize="0.75rem"
            lineHeight="22px"
            letterSpacing="0.46px"
            color={theme.primary}
            textTransform="uppercase"
          >
            {t(editMode ? 'common.buttons.save' : 'common.add')}
          </Typography>
        </TextIconButton>
      </ActionsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 0px;
  right: 4px;
`;
