import React, { useState, useContext, useRef, useEffect } from 'react';
import { ChatContext } from 'app/components/Chat';
import styled from 'styled-components';
import { ChatWindowEmptyState } from './components/ChatWindowEmptyState';
import { ChatWindowTopBar } from './components/ChatWindowTopBar';
import { MessageInput } from './components/MessageInput';
import { ChatMessage } from './components/ChatMessage';
import { LastChatMessage } from './components/LastChatMessage';
import { CircularProgress, ButtonBase, Grow } from '@material-ui/core';
import { useDebounce } from 'common/hooks/useDebounce';
import { StateSetter } from 'types';
import { useDeleteChatMessageMutation } from 'common/services/chatApi';
import { useModal } from 'app/components/Dialog/hooks';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';
import { downloadFile, viewFile } from 'app/helpers/helpers';
import { FullScreenImageView } from 'app/components/Chat/components/ChatWindow/components/FullScreenImageView';
import { Icon } from 'app/components/Icon';
import { CaretDown } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { ChatSkeleton } from './components/ChatSkeleton';
import { DateButton } from './components/DateButton';
import dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
dayjs.extend(advancedFormat);
dayjs.extend(isYesterday);

export interface ActionsHandleProps {
  actionType: string;
  messageUuid: string;
  messageContent: string;
  fileName?: string;
  fileType?: string;
  canEdit?: boolean;
}

interface Props {
  messages: any;
  setMessages: StateSetter<any>;
  isFetching: boolean;
  isLoadingStartChat: boolean;
  hasMore: boolean;
  totalMessages?: number;
  visibleElements: string;
}

export const ChatWindow: React.FC<Props> = ({
  messages,
  setMessages,
  isFetching,
  isLoadingStartChat,
  hasMore,
  totalMessages,
  visibleElements,
}) => {
  /* ----------------------------------- useStates ----------------------------------- */
  const [editMode, setEditMode] = useState(false);
  const [editMessageUuid, setEditMessageUuid] = useState('');
  const [editMessageContent, setEditMessageContent] = useState('');
  const [replyMode, setReplyMode] = useState(false);
  const [replyTo, setReplyTo] = useState<{
    messageUuid: string;
    messageContent: string;
    contentType: string;
    contentName?: string;
    senderName: string;
  } | null>(null);
  const [viewImageSrc, setViewImageSrc] = useState('');
  const [highlightMessageUuid, setHighlightMessageUuid] = useState('');
  const [selectedMessagesUuid, setSelectedMessagesUuid] = useState<string[]>(
    [],
  );
  const [scrollToTopButtonVisible, setScrollToTopButtonVisible] =
    useState(false);
  const [messagesGroupedByDay, setMessagesGroupedByDay] = useState<any>([]);
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

  /* ----------------------------------- Variables ----------------------------------- */
  const {
    selectedUser,
    currentUserId,
    totalChats,
    triggerRefetchChats,
    setIsLastMessageReached,
    setChatMessagesLimit,
  } = useContext(ChatContext);
  const debouncedIsLoadingStartChat = useDebounce(isLoadingStartChat, 500);
  const { closeModal, openModal } = useModal();
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language);
  const toast = useToaster();
  const chatsBodyRef: any = useRef(null);

  /* ----------------------------------- Api Calls ----------------------------------- */
  const [deleteChatMessage] = useDeleteChatMessageMutation();

  /* ----------------------------------- Functions ----------------------------------- */
  const handleMessageActions = (action: ActionsHandleProps) => {
    if (action?.actionType === 'edit') {
      setEditMessageUuid(action?.messageUuid);
      setEditMessageContent(action?.messageContent || '');
      setEditMode(true);
    } else if (action?.actionType === 'delete') {
      openModal({
        action: {
          actionText: t('common.buttons.delete'),
          actionCallback: (checked) =>
            handleDeleteMessage(action?.messageUuid, checked),
        },
        title: t('chats.message.delete.confirm'),
        deleteModal: true,
        cancel: true,
        content: t('chats.message.delete.messsage'),
        checkboxText: action?.canEdit
          ? t('chat.message.deleteForBothMessage', {
              name: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
            })
          : '',
      });
    } else if (action?.actionType === 'download') {
      downloadFile(action?.messageContent || '', action?.fileName || '');
    } else if (action?.actionType === 'view') {
      if (action?.fileType === 'images') {
        setViewImageSrc(action?.messageContent || '');
      } else {
        viewFile(action?.messageContent || '');
      }
    } else if (action?.actionType === 'reply') {
      const senderName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
      handleStartReply(
        action?.messageUuid,
        action?.messageContent || '',
        action?.fileName,
        action?.fileType,
        senderName,
      );
    } else if (action?.actionType === 'select') {
      setSelectedMessagesUuid((prevState) => [
        ...prevState,
        action?.messageUuid,
      ]);
    } else if (action?.actionType === 'copy') {
      navigator.clipboard.writeText(action?.messageContent || '');
    }
  };

  const handleStopEditing = (newMessageData) => {
    setMessages((prevState) =>
      prevState.map((message) => {
        if (message?.messageUuid === newMessageData?.messageUuid) {
          return {
            ...message,
            content: newMessageData?.content,
            updatedAt: newMessageData?.updatedAt,
            isEdited: newMessageData?.isEdited,
          };
        } else {
          return message;
        }
      }),
    );
    triggerRefetchChats();
    setEditMode(false);
    setEditMessageUuid('');
    setEditMessageContent('');
  };

  const handleDeleteMessage = async (messageUuid, deleteForBoth) => {
    closeModal();

    function formatUuids(messageUuid) {
      if (Array.isArray(messageUuid)) {
        return messageUuid;
      } else {
        return [messageUuid];
      }
    }
    const uuids = formatUuids(messageUuid);

    const res: any = await deleteChatMessage({
      body: { uuids: uuids, deleteForBoth: deleteForBoth },
    });
    if (res?.error) {
      toast(5000, 'error', t('chats.message.delete.error'));
    }
    toast(5000, 'success', t('chats.message.deleted'));
    triggerRefetchChats();
    setMessages((prevState) =>
      prevState.filter(
        (message) => !uuids?.some((uuid) => uuid === message?.messageUuid),
      ),
    );
  };

  const handleCloseImageModal = (e) => {
    e.stopPropagation();
    setViewImageSrc('');
  };

  const handleStartReply = (
    messageUuid,
    messageContent,
    contentName,
    contentType,
    senderName,
  ) => {
    setReplyTo({
      messageUuid: messageUuid,
      messageContent: messageContent,
      contentName: contentName,
      contentType: contentType,
      senderName: senderName,
    });
    setReplyMode(true);
  };

  const handleStopReplying = () => {
    setReplyTo(null);
    setReplyMode(false);
  };

  const handleScrollToMessage = async (messageUuid) => {
    const checkForElement = async () => {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          const element = document.getElementById(messageUuid);
          if (element) {
            clearInterval(interval);
            resolve(element);
          } else {
            setIsLastMessageReached(true);
          }
        }, 100);
      });
    };

    const element: any = await checkForElement();

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setHighlightMessageUuid(messageUuid);
    } else {
      setIsLastMessageReached(true);
    }
  };

  const handleToggleSelect = (messageUuid: string, selected: boolean) => {
    if (selected) {
      setSelectedMessagesUuid((prevState) => [...prevState, messageUuid]);
    } else {
      setSelectedMessagesUuid((prevState) =>
        prevState?.filter((uuid) => uuid !== messageUuid),
      );
    }
  };

  const handleTopBarActions = (action: string) => {
    if (selectedMessagesUuid?.length === 0) return;
    if (action === 'cancel') {
      setSelectedMessagesUuid([]);
    } else if (action === 'delete') {
      handleDeleteMessage(selectedMessagesUuid, false);
    } else if (action === 'copy') {
      const messagesContentArray = messages?.map((message) => {
        if (
          message?.contentType === 'text' &&
          selectedMessagesUuid?.some((uuid) => uuid === message?.messageUuid)
        ) {
          return message?.content;
        } else {
          return null;
        }
      });
      const textToCopy = messagesContentArray
        ?.filter((content) => content !== null)
        ?.reverse()
        ?.join('\n');
      navigator.clipboard.writeText(textToCopy || '');
    } else if (action === 'edit') {
      const result = messages.filter(
        (message) => message?.messageUuid === selectedMessagesUuid[0],
      );
      if (result?.length === 0) return;
      const message = result[0];

      setEditMessageUuid(message?.messageUuid);
      setEditMessageContent(message?.content);
      setEditMode(true);
    } else if (action === 'reply') {
      const result = messages.filter(
        (message) => message?.messageUuid === selectedMessagesUuid[0],
      );
      if (result?.length === 0) return;
      const message = result[0];

      const senderName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
      handleStartReply(
        message?.messageUuid,
        message?.content,
        message?.name,
        message?.contentType,
        senderName,
      );
    }
    setSelectedMessagesUuid([]);
  };

  const handleScrollToBottom = (behavior) => {
    if (!chatsBodyRef?.current) return;
    chatsBodyRef.current.scrollTo({
      top: 0,
      behavior: behavior,
    });
  };

  const handleScrollToGroup = async (groupId) => {
    const checkForElement = async () => {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          const element = document.getElementById(groupId);
          if (!hasMore || element) {
            clearInterval(interval);
            resolve(element);
          } else {
            setIsLastMessageReached(true);
          }
        }, 100);
      });
    };

    const element: any = await checkForElement();

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      toast(5000, 'info', t('chat.no_messages_found_for_date'));
      setIsLastMessageReached(true);
    }
  };

  const handleScrollToBeginning = async () => {
    setChatMessagesLimit(totalMessages || 1000);
    setShouldScrollToTop(true);
  };

  /* ---------------------------------- Use Effects ---------------------------------- */
  useEffect(() => {
    if (!chatsBodyRef?.current) return;
    const handleScroll = () => {
      const scrollTop = -chatsBodyRef.current?.scrollTop;
      setScrollToTopButtonVisible(scrollTop > 20);
    };
    chatsBodyRef.current.addEventListener('scroll', handleScroll);

    return () => {
      chatsBodyRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [chatsBodyRef?.current]);

  useEffect(() => {
    // setMessages(null);
    // setEditMode(false);
    // setReplyMode(false);
    // setSelectedMessagesUuid([]);
    // setHighlightMessageUuid('');
    handleScrollToBottom('instant');
  }, [selectedUser]);

  useEffect(() => {
    /**
     * group messages by their day to
     * display them as date groups
     */
    const today = dayjs();

    const messagesGroupByDate = [];

    messages?.forEach((message) => {
      const dateObj = dayjs(message?.createdAt);

      let formattedDate = ''; // date formated like: today, yesterday, or Monday, Septemeber 16th

      if (today.isSame(dateObj, 'date')) {
        formattedDate = t('calendar.today');
      } else if (dateObj.isYesterday()) {
        formattedDate = t('yesterday');
      } else {
        formattedDate = dateObj.format('dddd, MMMM Do');
      }

      if (!messagesGroupByDate[formattedDate]) {
        messagesGroupByDate[formattedDate] = [];
      }

      messagesGroupByDate[formattedDate].push(message);
    });

    setMessagesGroupedByDay(messagesGroupByDate);
  }, [messages]);

  useEffect(() => {
    if (!shouldScrollToTop || !chatsBodyRef?.current) return;

    setShouldScrollToTop(false);
    chatsBodyRef.current.scrollTo({
      top: -chatsBodyRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages, shouldScrollToTop]);

  /* --------------------------------------------------------------------------------- */

  return (
    <Wrapper translate="no">
      {totalChats > 0 || selectedUser ? (
        <>
          {selectedUser ? (
            <>
              <ChatWindowTopBar
                selectedUser={selectedUser}
                isCurrentUser={currentUserId === selectedUser?._id}
                visibleElements={visibleElements}
                selectedMessagesUuid={selectedMessagesUuid}
                handleTopBarActions={handleTopBarActions}
              />
              {messages?.length === 0 &&
              (isFetching || debouncedIsLoadingStartChat) ? (
                <ChatsBody editMode={false}>
                  <ChatSkeleton />
                </ChatsBody>
              ) : (
                <ChatsBody ref={chatsBodyRef} editMode={editMode}>
                  {!debouncedIsLoadingStartChat && (
                    <>
                      <ChatSkeleton />
                      {!messages || messages?.length === 0 ? (
                        <ChatWindowEmptyState type="noMessages" />
                      ) : (
                        <>
                          <ChatSkeleton />
                          {Object.keys(messagesGroupedByDay)?.map((date) => {
                            return (
                              <ChatsGroup id={date}>
                                {messagesGroupedByDay?.[date]?.map(
                                  (message, index) => {
                                    const isByCurrentUser =
                                      message?.sender?.id === currentUserId ||
                                      message?.sender?._id === currentUserId ||
                                      message?.sender === currentUserId ||
                                      message?._id === currentUserId;

                                    const prevMessage = messages[index - 1];
                                    const prevSenderId =
                                      prevMessage?.sender?.id ||
                                      prevMessage?.sender?._id ||
                                      'prevSenderId';
                                    const currentSenderId =
                                      message?.sender?.id ||
                                      message?.sender?._id ||
                                      'currentSenderId';

                                    const similarToPrevMessage =
                                      prevSenderId === currentSenderId;

                                    if (
                                      index !==
                                      messagesGroupedByDay?.[date]?.length - 1
                                    ) {
                                      return (
                                        <ChatMessage
                                          messageUuid={message?.messageUuid}
                                          type={
                                            isByCurrentUser
                                              ? 'sent'
                                              : 'received'
                                          }
                                          similarToPrevMessage={
                                            similarToPrevMessage
                                          }
                                          content={message?.content}
                                          name={message?.name}
                                          description={message?.description}
                                          contentType={message?.contentType}
                                          createdAt={message?.createdAt}
                                          handleMessageActions={
                                            handleMessageActions
                                          }
                                          isEdited={message?.isEdited || false}
                                          reply={message?.reply || null}
                                          senderFullName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                                          handleScrollToMessage={
                                            handleScrollToMessage
                                          }
                                          highlightMessageUuid={
                                            highlightMessageUuid
                                          }
                                          setHighlightMessageUuid={
                                            setHighlightMessageUuid
                                          }
                                          selectedMessagesUuid={
                                            selectedMessagesUuid
                                          }
                                          handleToggleSelect={
                                            handleToggleSelect
                                          }
                                        />
                                      );
                                    } else {
                                      return (
                                        <>
                                          <ChatSkeleton />
                                          <LastChatMessage
                                            messageUuid={message?.messageUuid}
                                            type={
                                              isByCurrentUser
                                                ? 'sent'
                                                : 'received'
                                            }
                                            similarToPrevMessage={
                                              similarToPrevMessage
                                            }
                                            content={message?.content}
                                            name={message?.name}
                                            description={message?.description}
                                            contentType={message?.contentType}
                                            createdAt={message?.createdAt}
                                            handleMessageActions={
                                              handleMessageActions
                                            }
                                            isEdited={
                                              message?.isEdited || false
                                            }
                                            reply={message?.reply || null}
                                            senderFullName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                                            handleScrollToMessage={
                                              handleScrollToMessage
                                            }
                                            highlightMessageUuid={
                                              highlightMessageUuid
                                            }
                                            setHighlightMessageUuid={
                                              setHighlightMessageUuid
                                            }
                                            selectedMessagesUuid={
                                              selectedMessagesUuid
                                            }
                                            handleToggleSelect={
                                              handleToggleSelect
                                            }
                                          />
                                          <ChatSkeleton />
                                        </>
                                      );
                                    }
                                  },
                                )}
                                <DateButton
                                  date={date}
                                  handleScrollToGroup={handleScrollToGroup}
                                  handleScrollToBeginning={
                                    handleScrollToBeginning
                                  }
                                />
                                <HorizentalLineCentered top={true} />
                              </ChatsGroup>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                  {messages?.length > 18 &&
                    hasMore &&
                    !debouncedIsLoadingStartChat && (
                      <div
                        style={{
                          flex: '1',
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '12px',
                          minHeight: '32px',
                          maxHeight: '32px',
                          padding: '4px 0',
                        }}
                      >
                        <CircularProgress color="primary" size={24} />
                      </div>
                    )}
                  <FullScreenImageView
                    open={!!viewImageSrc}
                    handleClose={handleCloseImageModal}
                    imgSrc={viewImageSrc}
                  />
                  <Grow in={scrollToTopButtonVisible}>
                    <ScrollDownButton extraHigh={editMode || replyMode}>
                      <ButtonBase
                        style={{
                          background: themes?.default?.accordionWhiteBg,
                          borderRadius: '20px',
                          padding: '4px',
                          boxShadow: '0px 4px 10px 0px #00000033',
                        }}
                        onClick={() => handleScrollToBottom('smooth')}
                      >
                        <Icon
                          icon={<CaretDown />}
                          size={24}
                          color={themes?.default?.iconColor}
                        />
                      </ButtonBase>
                    </ScrollDownButton>
                  </Grow>
                </ChatsBody>
              )}
              <MessageInput
                isNewChat={messages?.length === 0}
                editMode={editMode}
                editMessageUuid={editMessageUuid}
                editMessageContent={editMessageContent}
                handleStopEditing={handleStopEditing}
                replyMode={replyMode}
                replyTo={replyTo}
                handleStopReplying={handleStopReplying}
              />
            </>
          ) : (
            <ChatWindowEmptyState type="regular" />
          )}
        </>
      ) : (
        <ChatWindowEmptyState type="note" />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ChatsBody = styled.div<{ editMode: boolean }>`
  flex: 1;
  margin-top: 24px;
  height: calc(
    100% - 60px - 72px ${(props) => (props.editMode ? '- 46px' : '')}
  );
  overflow-y: auto;
  position: relative;

  @supports selector(::-webkit-scrollbar) {
    scrollbar-gutter: stable;
  }
  display: flex;
  flex-direction: column-reverse;
`;

const ChatsGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
`;

const HorizentalLineCentered = styled.div<{ top?: boolean }>`
  width: 100%;
  height: 0.5px;
  background: ${themes?.default?.gainsboro2};
  position: absolute;
  bottom: ${(props) => (props.top ? '' : '-25px')};
  top: ${(props) => (props.top ? '24px' : '')};
  z-index: 1;
  box-shadow: 7px 0 0 ${themes?.default?.gainsboro2};
`;

const ScrollDownButton = styled.div<{ extraHigh: boolean }>`
  position: fixed;
  bottom: ${(props) => (props.extraHigh ? '136px' : '89px')};
  right: 22px;
  transition: all 0.3s !important;
`;
