import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import { CreateOrderDrawer } from '../CreateOrderDrawer';
import { CreateOrderDrawerSales } from '../CreateOrderDrawerSales';
import moment from 'moment';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedCustomer?: any;
  selectedProducts?: any;
  defaultDeliveryDate?: any;
  type?: string;
  callId?: string;
  statusOrderedCallId?: string;
  hideTemplates?: boolean;
}

export const ManagedCreateOrderDrawer = ({
  open,
  onClose,
  selectedCustomer,
  selectedProducts,
  defaultDeliveryDate,
  type,
  callId,
  statusOrderedCallId,
  hideTemplates = false,
}: Props) => {
  const { isCustomer } = useGetUserAcl();
  const can = usePermission('orders');

  if (!can(Permission.VIEW)) {
    return null;
  }

  return (
    <>
      {isCustomer() ? (
        <CreateOrderDrawer
          open={open}
          closeDrawer={onClose}
          selectedProducts={selectedProducts}
          hideTemplates={hideTemplates}
        />
      ) : (
        <CreateOrderDrawerSales
          open={open}
          closeDrawer={onClose}
          selectedCustomer={selectedCustomer}
          selectedProducts={selectedProducts}
          defaultDeliveryDate={moment(defaultDeliveryDate)}
          type={type}
          callId={callId}
          statusOrderedCallId={statusOrderedCallId}
          hideTemplates={hideTemplates}
        />
      )}
    </>
  );
};
