import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Check } from '@phosphor-icons/react';
import useFormatCurrency from 'common/hooks/useFormatCurrency';
import { useCreateMultiPaymentMutation } from 'common/services/payments';
import If from 'app/components/If';
import { Chip, Grid } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { TwoViewModal } from 'app/components/TwoViewModal';

function InvoicesList({ invoices }) {
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();

  const total = formatCurrency(
    invoices
      .filter((invoice) => invoice?.accountingPaymentStatus !== 'paid')
      .filter((invoice) => invoice?._payment?.status !== 'success')
      .reduce((acc, curr) => {
        const amount =
          curr?.metadata?.structured?.totalAmountTax ??
          curr?.accountingAmount ??
          0;
        return acc + amount;
      }, 0),
  );

  return (
    <Table sx={{ minWidth: '100%' }} aria-label="simple table">
      <TableBody>
        {invoices.map((row) => (
          <TableRow
            key={row?.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <a href={row?.initialUrl} target="_blank">
                #{row?.metadata?.invoiceCode || ''}
              </a>
            </TableCell>

            <TableCell align="right">
              <If
                condition={
                  row?.accountingPaymentStatus === 'paid' ||
                  row?._payment?.status === 'success'
                } //Filter out paid invoices
                otherwise={formatCurrency(
                  row?.metadata?.structured?.totalAmountTax ||
                    row?.accountingAmount ||
                    0,
                )}
              >
                <Chip
                  size="small"
                  label={t('invoice.status.paid')}
                  sx={{
                    ml: '10px',
                    backgroundColor: themes?.default?.chipGreenFont,
                    color: themes?.default?.greenMain,
                  }}
                />
              </If>
            </TableCell>
          </TableRow>
        ))}
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            <Typography fontWeight="600">Total</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography fontWeight="600">{total}</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default function ConfirmPaymentsModal({ items, open, onClose }) {
  const { t } = useTranslation();

  const [createPayment, { isLoading: paymentIsLoading }] =
    useCreateMultiPaymentMutation();

  const handleConfirmPayment = async () => {
    const invoiceIds = items
      .map((item) => item._id)
      .filter(
        (item) =>
          item?.accountingPaymentStatus !== 'paid' ||
          item?._payment?.status === 'success',
      ); //Filter out paid invoices

    const res = await createPayment({
      okUrl: `${window.location.origin}/ordoria/transactions/invoices?payment=ok`,
      koUrl: `${window.location.origin}/ordoria/transactions/invoices?payment=ko`,
      fileIds: invoiceIds,
    }).unwrap();

    if (res?.paymentUrl) {
      window.open(res.paymentUrl, '_blank');
    }
  };

  return (
    <TwoViewModal
      onOpen={open}
      onClose={onClose}
      title={`Confirmer le paiement (${items.length} factures)`}
      height={60}
      defaultMode="floated"
    >
      <DialogContent>
        <DialogContentText>
          <InvoicesList invoices={items} />
        </DialogContentText>
      </DialogContent>
      <Grid
        sx={{
          padding: '16px',
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            color: themes.default.black60,
            mr: '10px',
          }}
        >
          {t('common.cancel')}
        </Button>
        {paymentIsLoading ? (
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleConfirmPayment}
          >
            <Check
              color="white"
              style={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
            />
            <Typography color="white" fontWeight="500" fontSize="13px">
              {t('customerPortal.documents.payOnlineInProgress')}
            </Typography>
          </LoadingButton>
        ) : (
          <Button
            color="primary"
            variant="contained"
            disabled={items.length === 0}
            onClick={handleConfirmPayment}
          >
            <Check
              color="white"
              style={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
            />
            <Typography color="white" fontWeight="500" fontSize="13px">
              {t('customerPortal.documents.payOnline')}
            </Typography>
          </Button>
        )}
      </Grid>
    </TwoViewModal>
  );
}
