import { SelectOptionWrapper } from 'app/components/FilterComponents/styles';
import EllipsisText from 'app/components/EllipsisText';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { themes } from 'styles/theme/themes';

export enum CustomerOfferMechanism {
  FREE = 'free',
  REFUND = 'refund',
}

export enum CustomerOfferPurchaseConditionType {
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory',
  ChildCATEGORY = 'childcategory',
  PRODUCT = 'product',
  ANYTHING = 'anything',
}

export enum CustomerOfferPurchaseConditionValueType {
  BOX = 'box',
  AMOUNT = 'amount',
}

export enum CustomerOfferPeriodType {
  ANNUALLY = 'annually',
  QUARTERLY = 'quarterly',
  MONTHLY = 'monthly',
  CUSTOM = 'custom',
  PER_ORDER = 'per_order',
}

export enum CustomerOfferedQuantityType {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
}

export const CustomerOfferPurchaseConditionOptions = [
  {
    label: 'Box',
    key: CustomerOfferPurchaseConditionValueType.BOX,
  },
  {
    label: 'EUR',
    key: CustomerOfferPurchaseConditionValueType.AMOUNT,
  },
];

export const CustomerOfferedQuantityOptions = [
  {
    label: '%',
    key: CustomerOfferedQuantityType.PERCENTAGE,
  },
  {
    label: 'EUR',
    key: CustomerOfferedQuantityType.AMOUNT,
  },
];

export const renderOption = (props, field) => {
  return (
    <div {...props} style={{ opacity: 1 }}>
      <SelectOptionWrapper>
        <Box>
          <Typography
            fontSize="10px"
            color={themes.default.lightGrayLabel}
            fontWeight="400"
          >
            #{field.code}
          </Typography>
          <EllipsisText width={220} text={field.label} />
        </Box>
      </SelectOptionWrapper>
    </div>
  );
};
