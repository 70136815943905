import {
  configureStore,
  StoreEnhancer,
  combineReducers,
} from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { createReducer } from './reducers';
import api from '../common/services/api';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authReducer } from '../app/slices/auth';
import organizationReducer from '../common/store/organization';
import appReducer from '../common/store/app';

import { toolsReducer } from 'app/slices/tools';
import { reducer as themeReducer } from 'styles/theme/slice';
import roundtripReducer from 'common/store/roundtrips';
import filterReducer from 'common/store/filters';
import callReducer from 'common/store/call';
import pagePreferencesReducer from '../common/store/pagePreferences';
import { cartReducer } from 'common/store/cart';
import { authOrganizationMiddleware } from './middlewares/authOrganization';

const nonPersistedTransform = {
  in: (state) => {
    return {
      ...state,
      // lastToasterNotification: undefined,
      dateValue: undefined,
    };
  },
  out: (state) => {
    return {
      ...state,
      // lastToasterNotification: null,
      dateValue: null,
    };
  },
};

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'app',
    'theme',
    'auth',
    'organization',
    'roundtrips',
    'filters',
    'call',
    'pagePreferences',
  ],
  transforms: [nonPersistedTransform],
};

const reducers = combineReducers({
  api: api.reducer,
  app: appReducer,
  theme: themeReducer,
  auth: authReducer,
  call: callReducer,
  pagePreferences: pagePreferencesReducer,
  organization: organizationReducer,
  tools: toolsReducer,
  roundtrips: roundtripReducer,
  filters: filterReducer,
  cart: cartReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(api.middleware)
        .concat(authOrganizationMiddleware),
    devTools: true, // TODO: find a solution for this issue https://github.com/rt2zz/redux-persist/issues/1287
    /* istanbul ignore next line */
    //process.env.NODE_ENV !== 'production' ||
    //process.env.PUBLIC_URL.length > 0,
    enhancers,
  });

  setupListeners(store.dispatch);

  return store;
}

const store = configureAppStore();

export default store;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
